var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { ContactModel, PipelineModel } from '@apto/models';
var BasePipelineEditForm = /** @class */ /*@__PURE__*/ (function () {
    function BasePipelineEditForm(companiesService, contactsService, propertiesService, featuresService, authService, logsService) {
        var _this = this;
        this.companiesService = companiesService;
        this.contactsService = contactsService;
        this.propertiesService = propertiesService;
        this.featuresService = featuresService;
        this.authService = authService;
        this.logsService = logsService;
        this.spacesEnabled = false;
        this.datepickerOptions = {
            alignSelectorRight: true,
            showSelectorArrow: false
        };
        this.subscriptions = [];
        this.subscriptions.push(this.authService.onSetUser.subscribe(function (user) {
            if (!user) {
                return;
            }
            _this.spacesEnabled = _this.featuresService.isFeatureEnabled(_this.featuresService.FEATURES.SPACES);
        }, this.logsService.error));
    }
    BasePipelineEditForm.prototype.ngOnChanges = function (changes) {
        if (changes.pipelineObject &&
            changes.pipelineObject.previousValue &&
            changes.pipelineObject.previousValue._id !== changes.pipelineObject.currentValue._id) {
            this.setupForm();
        }
    };
    BasePipelineEditForm.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        this.onDestroy();
    };
    BasePipelineEditForm.prototype.setQuickAddRecord = function (record, autoComplete, attribute, attributeId) {
        this.pipelineObject[attribute] = record;
        if (record) {
            this.pipelineObject[attributeId] = record._id;
            if (record instanceof ContactModel) {
                autoComplete.setValue(record.fullName);
            }
            else {
                autoComplete.setValue(record.name);
            }
            autoComplete.select(record);
        }
    };
    BasePipelineEditForm.prototype.saveRelationship = function (autoComplete, attribute, attributeId) {
        if (!autoComplete.getValue()) { // if the text has been deleted, clear the related values
            this.pipelineObject[attribute] = this.pipelineObject[attributeId] = null;
        }
        else { // otherwise, set values based on the current selection
            var selection = autoComplete.getSelection();
            if (selection) {
                this.pipelineObject[attribute] = selection;
                this.pipelineObject[attributeId] = selection._id;
            }
            else {
                autoComplete.keyword = '';
            }
        }
    };
    BasePipelineEditForm.prototype.setCompany = function (autoComplete, attribute, attributeId) {
        return __awaiter(this, void 0, void 0, function () {
            var companies;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.pipelineObject[attribute] && this.pipelineObject[attributeId]))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.companiesService.in([this.pipelineObject[attributeId]])];
                    case 1:
                        companies = _a.sent();
                        this.pipelineObject[attribute] = companies.length && companies[0] || null;
                        _a.label = 2;
                    case 2:
                        if (this.pipelineObject[attribute]) {
                            autoComplete.setValue(this.pipelineObject[attribute].name);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BasePipelineEditForm.prototype.setContact = function (autoComplete, attribute, attributeId) {
        return __awaiter(this, void 0, void 0, function () {
            var contacts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.pipelineObject[attribute] && this.pipelineObject[attributeId]))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.contactsService.in([this.pipelineObject[attributeId]])];
                    case 1:
                        contacts = _a.sent();
                        this.pipelineObject[attribute] = contacts.length && contacts[0] || null;
                        _a.label = 2;
                    case 2:
                        if (this.pipelineObject[attribute]) {
                            autoComplete.setValue(this.pipelineObject[attribute].fullName);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BasePipelineEditForm.prototype.setProperty = function (autoComplete, attribute, attributeId) {
        return __awaiter(this, void 0, void 0, function () {
            var properties;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.pipelineObject[attribute] && this.pipelineObject[attributeId]))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.propertiesService.in([this.pipelineObject[attributeId]])];
                    case 1:
                        properties = _a.sent();
                        this.pipelineObject[attribute] = properties.length && properties[0] || null;
                        _a.label = 2;
                    case 2:
                        if (this.pipelineObject[attribute]) {
                            autoComplete.setValue(this.pipelineObject[attribute].name);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    BasePipelineEditForm.prototype.setSpace = function (autoComplete, attribute, attributeId) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(!this.pipelineObject[attribute] && this.pipelineObject[attributeId]))
                            return [3 /*break*/, 2];
                        _a = this.pipelineObject;
                        _b = attribute;
                        return [4 /*yield*/, this.propertiesService.byId(this.pipelineObject[attributeId])];
                    case 1:
                        _a[_b] = _c.sent();
                        _c.label = 2;
                    case 2:
                        if (this.pipelineObject[attribute]) {
                            autoComplete.setValue(this.pipelineObject[attribute].name);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return BasePipelineEditForm;
}());
export { BasePipelineEditForm };
