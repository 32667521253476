import { ChangeDetectorRef } from '@angular/core';
import { AutoCompleteComponent } from '@apto/autocomplete';
import { CallListModel, ContactListViewModel, URLModel } from '@apto/models';
import { ContactGroupsService, ContactListViewsService } from '@apto/services';
import './call-list-group-selector.scss';
var CallListGroupSelector = /** @class */ /*@__PURE__*/ (function () {
    function CallListGroupSelector(changeDetectorRef, contactGroupsService, contactListViewsService) {
        var _this = this;
        this.changeDetectorRef = changeDetectorRef;
        this.contactGroupsService = contactGroupsService;
        this.contactListViewsService = contactListViewsService;
        this.CallListModel = CallListModel;
        this.selectedGroups = {};
        this.showValidationWarning = false;
        this.URLModel = URLModel;
        this.isUrlsInitializing = true;
        this.isInitializing = true;
        this.isListView = function (group) {
            return group instanceof ContactListViewModel;
        };
        this.save = function () {
            if (_this.selectedGroupsArray.length) {
                _this.showValidationWarning = false;
                return _this.selectedGroupsArray;
            }
            else {
                _this.showValidationWarning = true;
                return null;
            }
        };
        this.toggleGroupSelection = function (group) {
            if (_this.selectedGroups[group._id]) {
                delete _this.selectedGroups[group._id];
            }
            else {
                _this.selectedGroups[group._id] = group;
            }
        };
    }
    Object.defineProperty(CallListGroupSelector.prototype, "selectedGroupsArray", {
        get: function () {
            var _this = this;
            return Object.keys(this.selectedGroups).map(function (id) {
                return _this.selectedGroups[id];
            });
        },
        enumerable: true,
        configurable: true
    });
    CallListGroupSelector.prototype.ngOnInit = function () {
        var _this = this;
        this.urlsPromise.then(function (urls) {
            _this.urls = urls[0];
            _this.isUrlsInitializing = false;
        });
    };
    CallListGroupSelector.prototype.ngAfterViewInit = function () {
        var _this = this;
        return Promise.all([this.contactGroupsPromise, this.contactListViewsPromise]).then(function (results) {
            _this.groups = results[0].concat(results[1]);
            _this.isInitializing = false;
            _this.searchbar.getItems();
            _this.changeDetectorRef.detectChanges();
            results[1].forEach(function (listView) {
                _this.contactListViewsService.info(listView._id).then(function (info) {
                    listView.createdDate = info.createdDate;
                    listView.numberOfMembers = info.numberOfMembers;
                });
            });
            if (_this.callList.configJson.contactGroupIds && _this.callList.configJson.contactGroupIds.length) {
                _this.callList.configJson.contactGroupIds.forEach(function (contactGroupId) {
                    var contactGroup = _this.groups.find(function (group) {
                        return contactGroupId === group._id;
                    });
                    if (contactGroup) {
                        _this.selectedGroups[contactGroupId] = contactGroup;
                    }
                });
            }
            if (_this.callList.configJson.contactListViewIds && _this.callList.configJson.contactListViewIds.length) {
                _this.callList.configJson.contactListViewIds.forEach(function (contactListViewId) {
                    var contactView = _this.groups.find(function (group) {
                        return contactListViewId === group._id;
                    });
                    if (contactView) {
                        _this.selectedGroups[contactListViewId] = contactView;
                    }
                });
            }
            _this.searchbar.getItems();
            _this.changeDetectorRef.detectChanges();
        });
    };
    return CallListGroupSelector;
}());
export { CallListGroupSelector };
