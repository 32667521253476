import { ElementRef, EventEmitter, Renderer2 } from '@angular/core';
import './hold-button.scss';
var HoldButton = /** @class */ /*@__PURE__*/ (function () {
    function HoldButton(element, _renderer) {
        this.element = element;
        this._renderer = _renderer;
        this.hold = new EventEmitter();
        this.timer = setTimeout(1);
        this.containerDiv = this._renderer.createElement('div');
        this.progressBarDiv = this._renderer.createElement('div');
        this._renderer.addClass(this.containerDiv, 'hold-button--progress-container');
        this._renderer.addClass(this.progressBarDiv, 'hold-button--progress-bar');
        this._renderer.appendChild(this.containerDiv, this.progressBarDiv);
    }
    HoldButton.prototype.onMouseDown = function () {
        var _this = this;
        this._renderer.addClass(this.containerDiv, 'activated');
        this.timer = setTimeout(function () {
            _this.hold.emit();
            _this.cancelTimer();
        }, 2000);
    };
    HoldButton.prototype.onMouseUp = function () {
        this.cancelTimer();
    };
    HoldButton.prototype.onLeave = function () {
        this.cancelTimer();
    };
    HoldButton.prototype.ngAfterViewInit = function () {
        var progressBarColor = window.getComputedStyle(this.element.nativeElement).color;
        this._renderer.setStyle(this.progressBarDiv, 'background-color', progressBarColor);
        this._renderer.insertBefore(this.element.nativeElement, this.containerDiv, this.element.nativeElement.lastChild);
        this.cancelTimer();
    };
    HoldButton.prototype.cancelTimer = function () {
        clearTimeout(this.timer);
        this._renderer.removeClass(this.containerDiv, 'activated');
    };
    return HoldButton;
}());
export { HoldButton };
