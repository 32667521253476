<ion-content class="page list-page properties-page">
    <ion-grid class="content-grid">
        <ion-row class="list-page--header">
            <h2>Properties</h2>

            <div class="list-page--summary">
                <apto-icon icon="property" circle inline size="2"></apto-icon>
                <div class="list-page--label">
                    <div class="list-page--line">
                        <span *ngIf="!isInitializingProperties; else totalShimmer">
                            <strong>{{items.length}}</strong> of {{totalProperties}}</span>
                        <ng-template #totalShimmer>
                            <apto-shimmer class="properties-page--total-shimmer"></apto-shimmer>
                        </ng-template>
                    </div>
                    <div class="list-page--line list-page--small">
                        Properties
                    </div>
                </div>
            </div>

            <div data-automation="property-breakdown" class="list-page--summary">
                <apto-icon icon="trendingUp" circle inline size="2"></apto-icon>
                <div class="list-page--label">
                    <div class="list-page--line">
                        <strong *ngIf="!isInitializingProperties; else breakdownShimmer">{{breakdown}}</strong>
                        <ng-template #breakdownShimmer>
                            <apto-shimmer class="properties-page--breakdown-shimmer"></apto-shimmer>
                        </ng-template>
                    </div>
                    <div class="list-page--line list-page--small">
                        Breakdown of top property types
                    </div>
                </div>
            </div>
        </ion-row>
        <ion-row class="list-page--body properties-page--body">
            <ion-col class="list-page--filters properties-page--filters"
                     col-auto>
                <ion-content>
                    <add-call-list-members-card [properties]="selectedItemsArray"
                                                (onClearAll)="clearAllItems()">
                    </add-call-list-members-card>
                    <ion-card>
                        <ion-card-header>
                            Search and filter properties
                        </ion-card-header>
                        <ion-card-content>
                            <filter-panel #searchFilterPanel></filter-panel>
                            <div class="filter-panel--component">
                                <div class="filter-panel--children">
                                    <span class="filter-panel--title">Filter by</span>
                                    <filter-panel #propertiesFilterPanel></filter-panel>
                                    <filter-panel #additionalPropertiesFilterPanel
                                                  [ngClass]="{ hidden: !additionalFiltersAreShown }"></filter-panel>
                                    <a class="toggle-filters"
                                       (click)="toggleFilters()">{{ additionalFiltersAreShown ? 'Show less filters' : 'Show more filters' }}</a>
                                </div>
                                <div *ngIf="!filtersAreEmpty()"
                                     class="filter-panel--reset-container">
                                    <button class="filter-panel--reset-button"
                                            ion-button
                                            clear
                                            color="primary-blue"
                                            (click)="resetFilters()">
                                        Reset Filters
                                    </button>
                                </div>
                            </div>
                        </ion-card-content>
                    </ion-card>
                </ion-content>
            </ion-col>

            <ion-col class="properties-page--left">
                <ion-content>
                    <ion-card *ngIf="!isInitializingProperties">
                        <ion-card-header>
                            <ion-buttons start>
                                <button ion-button
                                        clear
                                        [ngClass]="{ active: listType === 'recent' }"
                                        (click)="setListType('recent')"
                                        [disabled]="listType === 'recent'">
                                    Recent Properties
                                </button>
                                <button ion-button
                                        clear
                                        [ngClass]="{ active: listType === 'all' }"
                                        (click)="setListType('all')"
                                        [disabled]="listType === 'all'">
                                    All Properties
                                </button>
                            </ion-buttons>
                            <ion-buttons end>
                                <button class="automation--add-button"
                                        ion-button
                                        clear
                                        (click)="addProperty()">
                                    ADD PROPERTY
                                </button>
                            </ion-buttons>
                        </ion-card-header>

                        <ion-card-content *ngIf="!isInitializingProperties">
                            <div class="list-view--info-row">
                                <ion-checkbox class="small"
                                              mode="ios"
                                              color="primary-blue"
                                              [ngModel]="currentPageSelected"
                                              (ngModelChange)="selectAllChanged($event)">
                                </ion-checkbox>

                                <div class="list-page--line">
                                    <ion-label *ngIf="!isInitializing">
                                        Showing {{items.length}} of {{totalProperties}} properties
                                    </ion-label>
                                </div>

                                <ion-item class="sort-by-select">
                                    <span>Sort By</span>
                                    <apto-ionic-select [options]="sortKeys"
                                                       [value]="selectedSortKey"
                                                       (onSelectOption)="sortChanged($event.value)"></apto-ionic-select>
                                </ion-item>
                            </div>

                            <ion-list>
                                <prop-item *ngFor="let property of items"
                                           [property]="property"
                                           selectable="true"
                                           [selected]="selectedItems.hasOwnProperty(property._id)"
                                           [hoveredProperty]="hoveredProperty"
                                           [isShownOnMap]="false"
                                           (onMouseover)="hoverProperty({ property: property, isHovered: true })"
                                           (onMouseout)="hoverProperty({ property: property, isHovered: false })"
                                           (onSelectToggled)="selectChanged($event, property)"
                                           (onClick)="viewProperty(property)"></prop-item>

                                <ion-item *ngIf="items.length === LIST_VIEW_MAX_RECORDS"
                                          class="list-page--additional-items-label">
                                    Search/filter to view additonal Properties.
                                </ion-item>
                                <ion-item no-lines
                                          *ngIf="items.length === 0">
                                    There are no properties to display.
                                </ion-item>
                            </ion-list>
                        </ion-card-content>
                    </ion-card>

                    <busy-panel *ngIf="isInitializingProperties"
                                message="Loading properties..."></busy-panel>
                </ion-content>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>