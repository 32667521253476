<ion-item class="lease-item"
          [ngClass]="{ hovered: hoveredProperty && lease.property && hoveredProperty._id === lease.property._id }"
          [attr.detail-push]="onClick.observers.length > 0 ? true : null"
          (mouseover)="onMouseover.emit(lease.property || null)"
          (mouseout)="onMouseout.emit(lease.property || null)"
          (click)="onClick.emit(lease)">
    <ion-icon
        circle
        material-icons
        name="location_city"
        class="{{ leaseMarkerClass }}"
        data-automation="lease-marker"
        item-left></ion-icon>

    <ion-thumbnail
        *ngIf="lease.property && lease.property.blobImageData"
        item-right>
        <img
            [src]="lease.property.blobImageData"
            data-automation="lease-property-image"
            alt="property image">
    </ion-thumbnail>

    <div class="lease-item--header">
        <div class="lease-item--row lease-item--row-columns">
            <strong
                *ngIf="lease.comp && lease.comp.name"
                class="lease-item--overflow"
                title="{{ lease.comp.name }}"
                data-automation="lease-comp-name">{{ lease.comp.name }}</strong>
            <span
                *ngIf="isDeal"
                class="lease-item--label"
                data-automation="lease-is-deal">Deal</span>
        </div>
        <ul
            *ngIf="companyName || contactName"
            class="lease-item--row lease-item--list lease-item--company-name">
            <li
                *ngIf="companyName"
                data-automation="lease-company-name">{{ companyName }}</li>
            <li
                *ngIf="contactName"
                data-automation="lease-contact-name">{{ contactName }}</li>
        </ul>
    </div>

    <ul
        *ngIf="lease.property || suiteNumber"
        class="lease-item--row lease-item--list lease-item--address">
        <li
            *ngIf="suiteNumber"
            data-automation="lease-suite-number">{{ suiteNumber }}</li>
        <li
            *ngIf="lease.property"
            data-automation="lease-property-address">{{ lease.property.addressString }}</li>
    </ul>

    <div class="lease-item--row lease-item--row-columns">
        <ul class="lease-item--list lease-item--lease-info">
            <li
                *ngIf="leaseExpiration"
                class="lease-item--info lease-item-expiration-date {{ leaseExpirationClass }}"
                data-automation="lease-expiration-date">Lease Exp. {{ leaseExpiration }}</li>
            <li
                *ngIf="rentalRate"
                class="lease-item--info"
                data-automation="lease-rental-rate">{{ rentalRate }}</li>
            <li
                *ngIf="squareFootage"
                class="lease-item--info"
                data-automation="lease-sqft">{{ squareFootage }} sqft</li>
            <li
                *ngIf="previousLeases.length"
                (click)="onPreviousLeasesClick($event)"
                class="lease-item--info">
                <span
                    class="lease-item--previous-leases"
                    data-automation="lease-previous-leases">View all {{ previousLeases.length }}</span>
            </li>
        </ul>
    </div>
</ion-item>
