import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AlertController, ModalController, NavParams, ToastController, ViewController } from 'ionic-angular';
import { CompModel, ContactModel, ContractModel, ListingModel, PursuitModel, TaskModel } from '@apto/models';
import { AppService, ContactsService, ContactGroupMembersService, TasksService, CallListsService, OwnershipsService, DealsService, DealPartiesService, TypesService, BaseService } from '@apto/services';
import { FilterByOwnerIdPipe, FilterByTaskTypePipe, SortByListingStatus } from '@apto/ionic-lib/pipes';
import { ActivityHistoryModal, ActivityHistoryModalMode, PropertyDetailsModal, PropertyDetailsModalMode } from '@apto/pn/modals';
import { TOAST_DURATION } from '@apto/shared/constants';
import { BaseSlideModal, CallListModal, CallListModalMode, TasksModal, TasksModalMode } from '@apto/shared/modals';
import { EditContactForm } from '@apto/shared/components';
export var ContactDetailsModalMode = /*@__PURE__*/ (function (ContactDetailsModalMode) {
    ContactDetailsModalMode[ContactDetailsModalMode["LIST"] = 0] = "LIST";
    ContactDetailsModalMode[ContactDetailsModalMode["VIEW"] = 1] = "VIEW";
    ContactDetailsModalMode[ContactDetailsModalMode["EDIT"] = 2] = "EDIT";
    ContactDetailsModalMode[ContactDetailsModalMode["VIEWLINKS"] = 3] = "VIEWLINKS";
    return ContactDetailsModalMode;
})({});
import './contact-details-modal.scss';
var ContactDetailsModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ContactDetailsModal, _super);
    function ContactDetailsModal(appService, contactGroupMembersService, contactsService, modalController, navParams, tasksService, toastController, alertController, viewController, callListsService, ownershipsService, dealsService, dealPartiesService, typesService) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.appService = appService;
        _this.contactGroupMembersService = contactGroupMembersService;
        _this.contactsService = contactsService;
        _this.modalController = modalController;
        _this.tasksService = tasksService;
        _this.toastController = toastController;
        _this.alertController = alertController;
        _this.callListsService = callListsService;
        _this.ownershipsService = ownershipsService;
        _this.dealsService = dealsService;
        _this.dealPartiesService = dealPartiesService;
        _this.typesService = typesService;
        _this.modalUrls = new Map([
            [ContactDetailsModalMode.VIEW, '/:contactId']
        ]);
        _this.callLists = [];
        _this.contactId = [];
        _this.Modes = ContactDetailsModalMode;
        _this.properties = [];
        _this.records = [];
        _this.selectedRecord = new ContactModel();
        _this.tasks = [];
        _this.isLoadingLinkedItems = false;
        _this.isLoadingCallLists = false;
        _this.isLoadingProperties = false;
        _this.isLoadingTasks = false;
        _this.subscriptions = [];
        _this.removeFromList = function (callList) {
            _this.isLoadingCallLists = true;
            var membermatch = callList.members.find(function (member) {
                return member.contactId === _this.contactId[0];
            });
            return _this.contactGroupMembersService.bulkDelete([membermatch._id]).then(function () {
                _this.callLists.splice(_this.callLists.indexOf(callList), 1);
                _this.isLoadingCallLists = false;
            });
        };
        _this.callLists = _this.navParams.get('callLists') || [];
        _this.contactId = _this.navParams.get('contactId') || '';
        _this.mode = _this.navParams.get('mode') || ContactDetailsModalMode.LIST;
        _this.properties = _this.navParams.get('properties') || [];
        _this.records = _this.navParams.get('contacts') || [];
        _this.selectedRecord = _this.navParams.get('selectedContact') || new ContactModel();
        _this.shownProperties = _this.navParams.get('shownProperties') || 10;
        _this.tasks = _this.navParams.get('tasks') || [];
        _this.types = _this.navParams.get('types') || [];
        _this.callListsPromise = _this.navParams.get('callListsPromise');
        if (_this.callListsPromise) {
            _this.isLoadingCallLists = true;
            _this.callListsPromise.then(function (callList) {
                _this.allCallLists = callList.allCallLists;
                _this.callLists = callList.currentCallLists;
                _this.isLoadingCallLists = false;
            });
        }
        _this.propertiesPromise = _this.navParams.get('propertiesPromise');
        if (_this.propertiesPromise) {
            _this.isLoadingProperties = true;
            _this.propertiesPromise.then(function (properties) {
                _this.properties = new SortByListingStatus().transform(properties);
                _this.isLoadingProperties = false;
            });
        }
        _this.tasksPromise = _this.navParams.get('tasksPromise');
        if (_this.tasksPromise) {
            _this.isLoadingTasks = true;
            _this.tasksPromise.then(function (tasks) {
                _this.tasks = tasks;
                _this.isLoadingTasks = false;
                TaskModel.sortActivities(_this.tasks);
            });
        }
        _this.objectsDeletedListener = BaseService.onAnyDeletion.subscribe(function (data) {
            if ([ListingModel, PursuitModel, CompModel, ContractModel].includes(data.model)) {
                _this.linkedDealActivities = _this.linkedDealActivities.filter(function (el) { return !data.payload.includes(el._id); });
                _this.checkLinkedElems();
            }
        });
        _this.objectsUpsertedListener = BaseService.onAnyUpsertion.subscribe(function (data) {
            if ([ListingModel, PursuitModel, CompModel, ContractModel].includes(data.model)) {
                _this.linkedDealActivities = _this.linkedDealActivities.filter(function (el) { return el._id !== data.payload._id; });
                var linkedNames = _this.getRelFieldsByValue(data.payload, _this.selectedRecord._id);
                if (!!linkedNames.length) {
                    _this.linkedDealActivities.push(data.payload);
                }
                _this.checkLinkedElems();
            }
        });
        return _this;
    }
    ContactDetailsModal.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.tasksService.onUpserted.subscribe(function (data) {
            var task = data.payload;
            var unchangedTasks = _this.linkedTasks.filter(function (el) { return el._id !== task._id; });
            if (task.contactId === _this.selectedRecord._id) {
                _this.linkedTasks = unchangedTasks.concat([task]);
            }
            else {
                _this.linkedTasks = unchangedTasks.slice();
            }
            _this.linkedTasks = _this.linkedTasks.filter(function (task) { return !task.isComplete; });
            _this.checkLinkedElems();
        }), this.ownershipsService.onDeleted.subscribe(function (data) {
            var ids = data.payload;
            _this.linkedOwnerships = _this.linkedOwnerships.filter(function (el) { return !ids.includes(el._id); });
            _this.checkLinkedElems();
        }), this.callListsService.onDeleted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.callLists = this.callLists.filter(function (cl) { return !data.payload.includes(String(cl._id)); });
                    this.getLinkedCLs();
                    this.checkLinkedElems();
                    return [2 /*return*/];
                });
            });
        }), this.contactGroupMembersService.onDeleted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.getLinkedCLs();
                    this.checkLinkedElems();
                    return [2 /*return*/];
                });
            });
        }));
        if (this.selectedRecord._id) {
            this.isLoadingLinkedItems = true;
            this.getLinkedElems().then(function () {
                _this.isLoadingLinkedItems = false;
            });
        }
    };
    ContactDetailsModal.prototype.checkLinkedElems = function () {
        if (!this.hasRelations()) {
            this.setMode(ContactDetailsModalMode.EDIT);
        }
    };
    ContactDetailsModal.prototype.getLinkedCLs = function () {
        var _this = this;
        this.linkedCallLists = this.callLists.filter(function (el) { return !!el.members.find(function (mb) { return mb.contactId === _this.selectedRecord._id && el.isContactGroupList; }); });
    };
    ContactDetailsModal.prototype.getLinkedProperties = function () {
        var ids = this.linkedOwnerships.map(function (el) { return el.propertyId; });
        return this.properties.filter(function (el) { return ids.includes(el._id); });
    };
    ContactDetailsModal.prototype.getLinkedElems = function () {
        return __awaiter(this, void 0, void 0, function () {
            var openTasks, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4 /*yield*/, this.getDealActivities()];
                    case 1:
                        _c.sent();
                        return [4 /*yield*/, new FilterByTaskTypePipe().transform(this.tasks, 'open')];
                    case 2:
                        openTasks = _c.sent();
                        _a = this;
                        return [4 /*yield*/, new FilterByOwnerIdPipe().transform(openTasks, this.appService.salesforceUserId)];
                    case 3:
                        _a.linkedTasks = _c.sent();
                        this.getLinkedCLs();
                        _b = this;
                        return [4 /*yield*/, this.ownershipsService.byContact(this.selectedRecord._id)];
                    case 4:
                        _b.linkedOwnerships = _c.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactDetailsModal.prototype.ngOnDestroy = function () {
        this.objectsDeletedListener.unsubscribe();
        this.objectsUpsertedListener.unsubscribe();
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    ContactDetailsModal.prototype.save = function (contact) {
        return __awaiter(this, void 0, void 0, function () {
            var formResult, savedContact;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.mode === ContactDetailsModalMode.VIEW))
                            return [3 /*break*/, 1];
                        this.contactsService.upsert(contact);
                        return [3 /*break*/, 3];
                    case 1:
                        formResult = this.editContactForm.save();
                        if (!(formResult && formResult.contact))
                            return [3 /*break*/, 3];
                        this.scrollToTop();
                        this.editContactForm.loadingMessage = 'Saving contact...';
                        return [4 /*yield*/, this.contactsService.upsert(formResult.contact)];
                    case 2:
                        savedContact = _a.sent();
                        if (this.navParams.get('isQuickAdd')) {
                            return [2 /*return*/, this.viewController.dismiss(savedContact)];
                        }
                        if (!savedContact) {
                            return [2 /*return*/, this.dismiss()];
                        }
                        this.editContactForm.loadingMessage = null;
                        this.view(savedContact);
                        if (this.initialMode === this.Modes.EDIT) {
                            this.dismiss();
                        }
                        else {
                            this.view(savedContact);
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ContactDetailsModal.prototype.showAddActivity = function () {
        var activityHistoryModal = this.modalController.create(ActivityHistoryModal, {
            mode: ActivityHistoryModalMode.EDIT,
            selectedActivity: TaskModel.newLoggedCall(this.selectedRecord)
        });
        return activityHistoryModal.present({ updateUrl: false });
    };
    ContactDetailsModal.prototype.showAddTask = function () {
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.EDIT,
            selectedTask: new TaskModel({
                contact: this.selectedRecord,
                contactId: this.selectedRecord._id
            })
        });
        return tasksModal.present({ updateUrl: false });
    };
    ContactDetailsModal.prototype.toggleTask = function (task) {
        var _this = this;
        task.isComplete = !task.isComplete;
        this.showToast(task);
        TaskModel.sortActivities(this.tasks);
        return this.tasksService.upsert(task)
            .catch(function () {
            task.isComplete = false;
        })
            .then(function () {
            TaskModel.sortActivities(_this.tasks);
        });
    };
    ContactDetailsModal.prototype.viewCallList = function (callList) {
        var _this = this;
        var modalClass = callList.isTaskList ? TasksModal : CallListModal;
        var mode = callList.isTaskList ? TasksModalMode.LIST : CallListModalMode.VIEW;
        var modal = this.modalController.create(modalClass, {
            callList: callList,
            mode: mode,
            tasks: callList.tasks,
            title: callList.name
        });
        if (!callList.isTaskList) {
            modal.onDidDismiss(function (data) {
                if (data && data.selectedContact) {
                    _this.viewController.dismiss({ selectedContact: data.selectedContact });
                }
            });
        }
        modal.present({ updateUrl: false });
    };
    ContactDetailsModal.prototype.viewProperty = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var propertyDetailsModal;
            return __generator(this, function (_a) {
                propertyDetailsModal = this.modalController.create(PropertyDetailsModal, {
                    selectedProperty: event.property,
                    mode: PropertyDetailsModalMode.VIEW
                }, {
                    cssClass: 'large-modal'
                });
                propertyDetailsModal.present({ updateUrl: false });
                return [2 /*return*/];
            });
        });
    };
    ContactDetailsModal.prototype.viewTask = function (task) {
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.VIEW,
            selectedTask: task,
            filter: 'open'
        });
        tasksModal.present({ updateUrl: false });
    };
    ContactDetailsModal.prototype.viewAllOpenTasks = function (event) {
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.LIST,
            tasks: this.tasks,
            filter: 'open'
        });
        tasksModal.present({ updateUrl: false });
    };
    ContactDetailsModal.prototype.getRelFieldsByValue = function (obj, value) {
        var results = [];
        if (obj instanceof ListingModel || obj instanceof PursuitModel) {
            if (obj.clientContactId === value) {
                results.push('clientContactId', 'clientContact');
            }
        }
        if (obj instanceof ContractModel) {
            if (obj.buyerContactId === value) {
                results.push('buyerContactId', 'buyerContact');
            }
            if (obj.sellerContactId === value) {
                results.push('sellerContactId', 'sellerContact');
            }
            if (obj.landlordContactId === value) {
                results.push('landlordContactId', 'landlordContact');
            }
            if (obj.tenantContactId === value) {
                results.push('tenantContactId', 'tenantContact');
            }
        }
        if (obj instanceof CompModel) {
            if (obj.buyerId === value) {
                results.push('buyerId', 'buyerContact');
            }
            if (obj.sellerId === value) {
                results.push('sellerId', 'sellerContact');
            }
            if (obj.tenantContactId === value) {
                results.push('tenantContactId', 'tenantContact');
            }
            if (obj.landlordContactId === value) {
                results.push('landlordContactId', 'landlordContactId');
            }
        }
        return results;
    };
    ContactDetailsModal.prototype.delDealParties = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var linkedDealParties, toDelete, toUpdate;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.linkedEntitiesWorkingMessage = 'Removing contact relationship (Deal Parties)...';
                        return [4 /*yield*/, this.dealPartiesService.getByContact(this.selectedRecord._id)];
                    case 1:
                        linkedDealParties = _a.sent();
                        if (!linkedDealParties.length)
                            return [3 /*break*/, 5];
                        toDelete = linkedDealParties.filter(function (el) { return !el.company; });
                        if (!(toDelete.length > 0))
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.dealPartiesService.delete(toDelete.map(function (el) { return el._id; }))];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        toUpdate = linkedDealParties.filter(function (el) { return !!el.company; });
                        if (!(toUpdate > 0))
                            return [3 /*break*/, 5];
                        return [4 /*yield*/, Promise.all(toUpdate.map(function (el) {
                                return _this.dealPartiesService.upsert({
                                    el: el,
                                    contact: null,
                                    contactId: null,
                                });
                            }))];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ContactDetailsModal.prototype.deleteWithLinks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.hasLinkedTasks())
                            return [3 /*break*/, 2];
                        this.linkedEntitiesWorkingMessage = 'Removing contact relationship (Tasks)...';
                        return [4 /*yield*/, Promise.all(this.linkedTasks.map(function (task) {
                                return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        task.contactId = null;
                                        task.contact = null;
                                        return [2 /*return*/, this.tasksService.upsert(task)];
                                    });
                                });
                            }))];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.hasLinkedProperties())
                            return [3 /*break*/, 4];
                        this.linkedEntitiesWorkingMessage = 'Removing contact relationship (Properties)...';
                        return [4 /*yield*/, this.ownershipsService.delete(this.linkedOwnerships.map(function (el) { return el._id; }))];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        if (!this.hasLinkedCallLists())
                            return [3 /*break*/, 6];
                        this.linkedEntitiesWorkingMessage = 'Removing contact relationship (CallLists)...';
                        return [4 /*yield*/, Promise.all(this.linkedCallLists.map(function (cl) {
                                return __awaiter(_this, void 0, void 0, function () {
                                    var _this = this;
                                    var mbToDel;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                mbToDel = cl.members.filter(function (mb) { return mb.contactId === _this.selectedRecord._id; });
                                                if (!(cl.members.length - mbToDel.length === 0))
                                                    return [3 /*break*/, 1];
                                                return [2 /*return*/, this.callListsService.delete([String(cl._id)])];
                                            case 1: return [4 /*yield*/, this.contactGroupMembersService.delete(mbToDel.map(function (el) { return el._id; }))];
                                            case 2:
                                                _a.sent();
                                                return [2 /*return*/, this.callListsService.byId(cl._id, true)];
                                        }
                                    });
                                });
                            }))];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [4 /*yield*/, this.delDealParties()];
                    case 7:
                        _a.sent();
                        this.linkedEntitiesWorkingMessage = 'Removing contact...';
                        return [4 /*yield*/, this.contactsService.delete([this.selectedRecord._id])];
                    case 8:
                        _a.sent();
                        this.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactDetailsModal.prototype.hasRelations = function () {
        return (this.hasLinkedTasks() ||
            this.hasLinkedCallLists() ||
            this.hasLinkedProperties() ||
            this.hasLinkedActivities());
    };
    ContactDetailsModal.prototype.delete = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var httpAlert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.hasRelations())
                            return [3 /*break*/, 1];
                        httpAlert = this.alertController.create({
                            title: 'Unable to delete contact',
                            subTitle: 'This Contact is related to other records. Please remove those relationships or delete those records before deleting this contact.<br>Click “Details” for a list of those records.',
                            buttons: [
                                {
                                    role: 'showd',
                                    text: 'Details',
                                    handler: function () { return _this.setMode(ContactDetailsModalMode.VIEWLINKS); },
                                },
                                { role: 'close', text: 'Close' },
                            ],
                            cssClass: 'alert-massage-info',
                        });
                        httpAlert.present();
                        return [3 /*break*/, 4];
                    case 1:
                        this.setMode(this.Modes.VIEWLINKS);
                        return [4 /*yield*/, this.delDealParties()];
                    case 2:
                        _a.sent();
                        this.linkedEntitiesWorkingMessage = 'Removing contact...';
                        return [4 /*yield*/, this.contactsService.delete([this.selectedRecord._id])];
                    case 3:
                        _a.sent();
                        this.dismiss();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ContactDetailsModal.prototype.hasLinkedActivities = function () {
        return !!this.linkedDealActivities.length;
    };
    ContactDetailsModal.prototype.hasLinkedTasks = function () {
        return !!this.linkedTasks.length;
    };
    ContactDetailsModal.prototype.hasLinkedProperties = function () {
        return !!this.linkedOwnerships.length;
    };
    ContactDetailsModal.prototype.getDealActivities = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, listings, pursuits, closedDeals, contracts;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        _a.recordTypes = _c.sent();
                        return [4 /*yield*/, this.dealsService.getActivitiesByContact(this.selectedRecord._id)];
                    case 2:
                        _b = _c.sent(), listings = _b.listings, pursuits = _b.pursuits, closedDeals = _b.closedDeals, contracts = _b.contracts;
                        this.linkedDealActivities = listings.concat(pursuits, closedDeals, contracts);
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactDetailsModal.prototype.hasLinkedCallLists = function () {
        return !!this.linkedCallLists.length;
    };
    ContactDetailsModal.prototype.showToast = function (task) {
        var toast = this.toastController.create({
            duration: TOAST_DURATION,
            message: task.isComplete ? 'Task completed.' : 'Task opened.',
            showCloseButton: true
        });
        toast.present();
    };
    return ContactDetailsModal;
}(BaseSlideModal));
export { ContactDetailsModal };
