<form #formContent
      [formGroup]="offerForm"
      class="edit-offer-form edit-form">
    <ion-card>
        <ion-card-header>Offer Details</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-label stacked>Offer name</ion-label>
                <ion-input formControlName="name"
                           class="light-text"
                           maxlength="255"></ion-input>
            </ion-item>
            <select-field [form]="offerForm"
                          field="status"
                          label="Status"
                          [options]="status"
                          [cssClass]="'edit-form--select-popover'"></select-field>
            <date-field [defaultDate]="offerForm.controls.offerDate.value"
                        [form]="offerForm"
                        field="offerDate"
                        label="Offer date"
                        required="true"
                        [options]="datepickerOptions"></date-field>
            <div class="field-error"
                 *ngIf="offerForm.controls['offerDate'].dirty &&
                 offerForm.controls['offerDate'].errors &&
                 offerForm.controls['offerDate'].errors['required']">
                An offer date is required
            </div>
            <ion-item>
                <ion-label stacked>Offerer price</ion-label>
                <ion-input formControlName="offerPrice"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="offerForm.get('offerPrice').dirty &&
                        offerForm.get('offerPrice').errors &&
                        offerForm.get('offerPrice').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <ion-label stacked>
                Offerer
                <add-record
                    linkText="Create new"
                    objectName="Contact"
                    (onRecordCreated)="setQuickAddRecord($event, purchaserAutoComplete, 'purchaser', 'purchaserId');"></add-record>
            </ion-label>
            <ion-auto-complete #purchaser
                               [dataProvider]="contactsService"
                               [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
        </ion-card-content>
    </ion-card>
    <ion-card>
        <ion-card-header>Additional Information</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-label stacked>Feasibility period</ion-label>
                <ion-input formControlName="feasibilityPeriod"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label stacked>Financial period</ion-label>
                <ion-input formControlName="financingPeriod"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label stacked>Close date</ion-label>
                <ion-input formControlName="closeDate"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label stacked>Initial deposit</ion-label>
                <ion-input formControlName="initialDeposit"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="offerForm.get('initialDeposit').dirty &&
                        offerForm.get('initialDeposit').errors &&
                        offerForm.get('initialDeposit').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <ion-item>
                <ion-label stacked>Additional deposit</ion-label>
                <ion-input formControlName="additionalDeposit"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="offerForm.get('additionalDeposit').dirty &&
                        offerForm.get('additionalDeposit').errors &&
                        offerForm.get('additionalDeposit').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <ion-item>
                <ion-label stacked>Independent consideration</ion-label>
                <ion-input formControlName="independentConsideration"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="offerForm.get('independentConsideration').dirty &&
                        offerForm.get('independentConsideration').errors &&
                        offerForm.get('independentConsideration').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
        </ion-card-content>
    </ion-card>

    <busy-panel *ngIf="isSaving"
                message="Saving offer..."></busy-panel>
</form>