<ion-card>
    <ion-card-header>
        Unit mix
    </ion-card-header>
    <ion-card-content *ngIf="!loadingMessage"
                      no-padding>
        <ion-list>
            <ion-item *ngFor="let unitMix of unitMixes"
                      class="unit-mix-item"
                      detail-push
                      (click)="openUnitMixModal(unitMix)">
                <ion-icon name="meeting_room"
                          circle
                          material-icons
                          item-left>
                </ion-icon>

                <p>
                    <strong *ngIf="unitMix.unitType">{{ unitMix.unitType }}</strong>
                    <ng-container *ngIf="unitMix.numUnits">
                        <strong *ngIf="!unitMix.unitType">{{ unitMix.numUnits || 0 }} units</strong>
                        <ng-container *ngIf="unitMix.unitType">&bull; {{ unitMix.numUnits || 0 }} units</ng-container>
                    </ng-container>
                </p>
                <p class="unit-mix-card--info" [innerHTML]="rentAndSquareFootage(unitMix)"></p>
            </ion-item>

            <ion-item *ngIf="!unitMixes.length"
                      class="empty-card">
                <ion-icon name="checkmark"
                          circle
                          small
                          color="white"
                          class="grey-circle"
                          item-left></ion-icon>
                Add unit mixes
            </ion-item>
        </ion-list>

        <ion-row class="card-footer-buttons">
            <ion-col text-right>
                <button ion-button
                        clear
                        color="primary-blue"
                        (click)="openUnitMixModal()">
                    Add unit
                </button>
            </ion-col>
        </ion-row>
    </ion-card-content>

    <ion-card-content *ngIf="loadingMessage">
        <busy-panel [message]="loadingMessage"></busy-panel>
    </ion-card-content>
</ion-card>
