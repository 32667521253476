import { __extends } from "tslib";
import { FormControl } from '@angular/forms';
import { BaseFilter } from '../';
import './search-filter.scss';
var SearchFilter = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(SearchFilter, _super);
    function SearchFilter() {
        var _this = _super.call(this) || this;
        _this.searchTerm = '';
        _this.searchControl = new FormControl();
        _this.searchControl.valueChanges.subscribe(function (search) {
            _this.onQueryChange.emit(search);
        });
        return _this;
    }
    SearchFilter.prototype.getQuery = function () {
        var query = {};
        if (this.validate()) {
            var likeClause = this.searchTerm.trim().split(' ').join('% ');
            query.$or = [
                (_a = {}, _a[this.queryKey] = { $like: likeClause + "%" }, _a),
                (_b = {}, _b[this.queryKey] = { $like: "% " + likeClause + "%" }, _b)
            ];
        }
        return query;
        var _a, _b;
    };
    SearchFilter.prototype.validate = function () {
        return this.searchTerm.length > 1;
    };
    SearchFilter.prototype.focus = function () {
        this.myInput.setFocus();
    };
    SearchFilter.prototype.reset = function () {
        this.searchTerm = '';
    };
    SearchFilter.prototype.isEmpty = function () {
        return !this.searchTerm;
    };
    return SearchFilter;
}(BaseFilter));
export { SearchFilter };
