import { PopoverController } from 'ionic-angular';
import { HelpPopover } from '../../components/help-popover/help-popover';
import './help-button.scss';
var HelpButton = /** @class */ /*@__PURE__*/ (function () {
    function HelpButton(popoverCtrl) {
        this.popoverCtrl = popoverCtrl;
    }
    HelpButton.prototype.showHelp = function (ev) {
        var _this = this;
        ev.preventDefault();
        ev.stopPropagation();
        var helpPopover = this.popoverCtrl.create(HelpPopover, {
            helpText: this.helpText,
            showEmailLink: this.showEmailLink,
            helpArticleUrl: this.helpArticleUrl
        });
        if (!this.helpBounds) {
            this.helpBounds = ev.target.getBoundingClientRect();
        }
        ev.target.getBoundingClientRect = function () {
            return {
                top: _this.helpBounds.top,
                left: parseInt(_this.helpBounds.left, 10) + 16
            };
        };
        helpPopover.present({ ev: ev });
    };
    return HelpButton;
}());
export { HelpButton };
