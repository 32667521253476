import { __extends } from "tslib";
import { Injector } from '@angular/core';
import { DesktopPreferencesModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
import { AlertController } from 'ionic-angular';
var PreferencesService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(PreferencesService, _super);
    function PreferencesService(injector, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.alertController = alertController;
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.PREFERENCES;
        _this.model = DesktopPreferencesModel;
        _this.serviceName = SERVICE_NAMES.PREFERENCES;
        return _this;
    }
    PreferencesService.prototype.fetch = function (params) {
        var cachedPreferences = this.cacheService.getAll(this.serviceName);
        if (cachedPreferences.length) {
            return Promise.resolve(cachedPreferences[0]);
        }
        return _super.prototype.fetch.call(this, params)
            .then(function (results) {
            if (results && results.length) {
                // there should only be a single result for a given user, so return that instead of an array
                return results[0];
            }
            return null;
        });
    };
    return PreferencesService;
}(BaseService));
export { PreferencesService };
