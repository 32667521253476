<form [formGroup]="listingForm"
      novalidate
      class="edit-listing-form edit-form">
    <ion-card>
        <ion-card-header>Listing details</ion-card-header>
        <ion-card-content>
            <div data-automation="listingForm-name">
                <ion-item>
                    <ion-label stacked
                            required>Listing name</ion-label>
                    <ion-input formControlName="name"
                            required></ion-input>
                </ion-item>
                <div class="field-error"
                    *ngIf="listingForm.controls['name'].dirty &&
                            listingForm.controls['name'].errors &&
                            listingForm.controls['name'].errors['required']">
                    A name is required.
                </div>
                <div class="field-error"
                    *ngIf="listingForm.controls['name'].dirty &&
                            listingForm.controls['name'].errors &&
                            listingForm.controls['name'].errors['maxlength']">
                    Listing name must be 80 characters or less.
                </div>
            </div>

            <select-field *ngIf="!pipelineObject._id"
                          [form]="listingForm"
                          field="type"
                          label="Deal type"
                          [options]="types"
                          [required]="true"
                          [cssClass]="'edit-form--select-popover'"
                          (onChange)="chooseDealType($event)"></select-field>
            <div *ngIf="pipelineObject._id">
                <ion-label stacked>Deal type</ion-label>
                <p>{{dealType}}</p>
            </div>

            <div data-automation="client-company-search">
                <ion-label stacked>
                    Client company
                    <add-record
                            linkText="Create new"
                            objectName="Company"
                            (onRecordCreated)="setQuickAddRecord($event, companyAutoComplete, 'clientCompany', 'seller')">
                    </add-record>
                </ion-label>
                <ion-auto-complete #company
                                [dataProvider]="companiesService"
                                [options]="{ placeholder : 'Find company' }"></ion-auto-complete>
            </div>

            <div data-automation="client-contact-search">
                <ion-label stacked>
                    Client contact
                    <add-record
                            linkText="Create new"
                            objectName="Contact"
                            (onRecordCreated)="setQuickAddRecord($event, contactAutoComplete, 'clientContact', 'ownerContact')">
                    </add-record>
                </ion-label>
                <ion-auto-complete #contact
                                [dataProvider]="contactsService"
                                [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
            </div>

            <div data-automation="property-search">
                <ion-label stacked>
                    Property
                    <add-record
                            linkText="Create new"
                            objectName="Property"
                            (onRecordCreated)="setQuickAddRecord($event, propertyAutoComplete, 'tempProperty', 'tempPropertyId')">
                    </add-record>
                </ion-label>
                <ion-auto-complete
                    #property
                    (changeValue)="propertyChanged($event)"
                    (itemSelected)="propertySelected($event)"
                    [dataProvider]="propertiesService"
                    [options]="{ placeholder : 'Find property' }"></ion-auto-complete>
            </div>

            <div
                [hidden]="!spacesEnabled || !dealType || isBuyerRep || isSellerRep"
                data-automation="space-search">
                <ion-label stacked>Space</ion-label>
                <ion-auto-complete
                    #space
                    [dataProvider]="spaceAutocompleteService"
                    [options]="{ placeholder : 'Find space' }"></ion-auto-complete>
            </div>

        </ion-card-content>
    </ion-card>

    <ion-card *ngIf="dealType">
        <ion-card-header>Additional information</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-label stacked>Commission amount</ion-label>
                <ion-input formControlName="commissionAmount"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('commissionAmount').dirty &&
                        listingForm.get('commissionAmount').errors &&
                        listingForm.get('commissionAmount').errors.pattern">
                {{validationMessages.DECIMAL_REGEX}}
            </div>

            <ion-item>
                <ion-label stacked>Probability (%)</ion-label>
                <ion-input formControlName="probability"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('probability').dirty &&
                        listingForm.get('probability').errors &&
                        listingForm.get('probability').errors.pattern">
                {{validationMessages.PERCENT_INT_REGEX}}
            </div>

            <ion-item *ngIf="isSellerRep">
                <ion-label stacked>Asking price</ion-label>
                <ion-input formControlName="askingPriceActual"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('askingPriceActual').dirty &&
                        listingForm.get('askingPriceActual').errors &&
                        listingForm.get('askingPriceActual').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>

            <ion-item *ngIf="isSellerRep">
                <ion-label stacked>NOI</ion-label>
                <ion-input formControlName="noi"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('noi').dirty &&
                        listingForm.get('noi').errors &&
                        listingForm.get('noi').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>

            <ion-item *ngIf="isSellerRep">
                <ion-label stacked>CAP rate (%)</ion-label>
                <ion-input formControlName="capRate"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('capRate').dirty &&
                        listingForm.get('capRate').errors &&
                        listingForm.get('capRate').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>

            <ion-item *ngIf="isSellerRep">
                <ion-label stacked>Yield (Cash-on-cash %)</ion-label>
                <ion-input formControlName="yield"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('yield').dirty &&
                        listingForm.get('yield').errors &&
                        listingForm.get('yield').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>

            <ion-item *ngIf="isSellerRep">
                <ion-label stacked>Total return (%)</ion-label>
                <ion-input formControlName="totalReturn"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('totalReturn').dirty &&
                        listingForm.get('totalReturn').errors &&
                        listingForm.get('totalReturn').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>

            <select-map-field *ngIf="isTenantRep"
                [form]="listingForm"
                field="spaceType"
                label="Space type"
                [options]="picklistOptions.spaceType"
                [cssClass]="'edit-form--select-popover'"></select-map-field>

            <select-map-field *ngIf="isTenantRep"
                [form]="listingForm"
                field="spaceSubType"
                label="Space sub-type"
                [options]="picklistOptions.spaceSubType"
                [cssClass]="'edit-form--select-popover'"></select-map-field>

            <select-map-field *ngIf="isBuyerRep || isTenantRep"
                [form]="listingForm"
                field="market"
                label="Market"
                [options]="picklistOptions.market"
                [cssClass]="'edit-form--select-popover'"></select-map-field>

            <select-map-field *ngIf="isBuyerRep || isTenantRep"
                [form]="listingForm"
                field="subMarket"
                label="Submarket"
                [options]="picklistOptions.subMarket"
                [cssClass]="'edit-form--select-popover'"></select-map-field>

            <ion-item *ngIf="isBuyerRep">
                <ion-label stacked>Minimum purchase price</ion-label>
                <ion-input formControlName="minPrice"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('minPrice').dirty &&
                        listingForm.get('minPrice').errors &&
                        listingForm.get('minPrice').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>

            <ion-item *ngIf="isBuyerRep">
                <ion-label stacked>Maximum purchase price</ion-label>
                <ion-input formControlName="maxPrice"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('maxPrice').dirty &&
                        listingForm.get('maxPrice').errors &&
                        listingForm.get('maxPrice').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>

            <ion-item *ngIf="isTenantRep">
                <ion-label stacked>Minimum lease rate ($/sf)</ion-label>
                <ion-input formControlName="minLeaseRate"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('minLeaseRate').dirty &&
                        listingForm.get('minLeaseRate').errors &&
                        listingForm.get('minLeaseRate').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>

            <ion-item *ngIf="isTenantRep">
                <ion-label stacked>Maximum lease rate ($/sf)</ion-label>
                <ion-input formControlName="maxLeaseRate"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('maxLeaseRate').dirty &&
                        listingForm.get('maxLeaseRate').errors &&
                        listingForm.get('maxLeaseRate').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>

            <ion-item *ngIf="isBuyerRep || isTenantRep">
                    <ion-label stacked>Minimum square feet</ion-label>
                    <ion-input formControlName="minSqft"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('minSqft').dirty &&
                        listingForm.get('minSqft').errors &&
                        listingForm.get('minSqft').errors.pattern">
                {{validationMessages.DECIMAL_REGEX}}
            </div>

            <ion-item *ngIf="isBuyerRep || isTenantRep">
                <ion-label stacked>Maximum square feet</ion-label>
                <ion-input formControlName="maxSqft"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('maxSqft').dirty &&
                        listingForm.get('maxSqft').errors &&
                        listingForm.get('maxSqft').errors.pattern">
                {{validationMessages.DECIMAL_REGEX}}
            </div>

            <ion-item *ngIf="isBuyerRep">
                <ion-label stacked>Minimum CAP rate (%)</ion-label>
                <ion-input formControlName="minCapRate"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('minCapRate').dirty &&
                        listingForm.get('minCapRate').errors &&
                        listingForm.get('minCapRate').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>

            <ion-item *ngIf="isBuyerRep">
                <ion-label stacked>Minimum cash-on-cash (%)</ion-label>
                <ion-input formControlName="minCashOnCash"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('minCashOnCash').dirty &&
                        listingForm.get('minCashOnCash').errors &&
                        listingForm.get('minCashOnCash').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>

            <ion-item *ngIf="isBuyerRep">
                <ion-label stacked>Minimum IRR (%)</ion-label>
                <ion-input formControlName="minIRR"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('minIRR').dirty &&
                        listingForm.get('minIRR').errors &&
                        listingForm.get('minIRR').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>

            <ion-item *ngIf="isBuyerRep">
                <ion-label stacked>Minimum LIRR (%)</ion-label>
                <ion-input formControlName="minLIRR"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="listingForm.get('minLIRR').dirty &&
                        listingForm.get('minLIRR').errors &&
                        listingForm.get('minLIRR').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>

            <div *ngIf="isSellerRep || isLandlordRep">
                <date-field [defaultDate]="listingForm.controls.dateOnMarket.value"
                            [form]="listingForm"
                            field="dateOnMarket"
                            label="Date on market"
                            [required]="false"
                            [options]="datepickerOptions"></date-field>
            </div>

            <!--<div *ngIf="isSellerRep || isLandlordRep">
            This was commented out as part of a managed package bug that causes a filled-in value of Date off market
            to always transition the Listing status to Off-market, which makes it disappear from the Deals board.
            Uncomment the ngIf=false and reinstate the above ngIf clause when the managed package bug is fixed. -->
            <div *ngIf="false">
                <date-field [defaultDate]="listingForm.controls.dateOffMarket.value"
                            [form]="listingForm"
                            field="dateOffMarket"
                            label="Date off market"
                            [required]="false"
                            [options]="datepickerOptions"></date-field>
            </div>

            <div *ngIf="isBuyerRep || isTenantRep">
                    <date-field [defaultDate]="listingForm.controls.needDate.value"
                                [form]="listingForm"
                                field="needDate"
                                label="Need date"
                                [required]="false"
                                [options]="datepickerOptions"></date-field>
            </div>

            <div>
                <date-field [defaultDate]="listingForm.controls.repAgreementDate.value"
                            [form]="listingForm"
                            field="repAgreementDate"
                            label="Rep agreement executed date"
                            [required]="false"
                            [options]="datepickerOptions"></date-field>
            </div>

            <div>
                <date-field [defaultDate]="listingForm.controls.expirationDate.value"
                            [form]="listingForm"
                            field="expirationDate"
                            label="Rep agreement expiration date"
                            [required]="false"
                            [options]="datepickerOptions"></date-field>
            </div>
        </ion-card-content>
    </ion-card>

    <photo-scrollview *ngIf="photos.length > 0" [photoUris]="photos"></photo-scrollview>

    <busy-panel *ngIf="isSaving"
                message="Saving listing..."></busy-panel>
</form>
