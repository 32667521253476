<ion-card class="property-details-card">
    <ion-card-header>Details</ion-card-header>

    <ion-card-content>
        <div *ngIf="property.type">
            <ion-label stacked>Property type</ion-label>
            <p class="dark-text">{{property.type}}</p>
        </div>

        <div *ngIf="property.primaryUse">
            <ion-label stacked>Property use</ion-label>
            <p class="dark-text">{{property.primaryUse}}</p>
        </div>

        <div *ngIf="property.market">
            <ion-label stacked>Market</ion-label>
            <p class="dark-text">{{property.market}}</p>
        </div>

        <div *ngIf="property.submarket">
            <ion-label stacked>Submarket</ion-label>
            <p class="dark-text">{{property.submarket}}</p>
        </div>

        <div *ngIf="property.class">
            <ion-label stacked>Building class</ion-label>
            <p class="dark-text">{{property.class}}</p>
        </div>

        <div *ngIf="property.type !== 'Multifamily' && property.squareFootage">
            <ion-label stacked>Square footage</ion-label>
            <p class="dark-text">{{property.squareFootage}}</p>
        </div>

        <div *ngIf="property.type === 'Multifamily' && property.units">
            <ion-label stacked>Units</ion-label>
            <p class="dark-text">{{property.units}}</p>
        </div>

        <div *ngIf="property.landAcres">
            <ion-label stacked>Land (acres)</ion-label>
            <p class="dark-text">{{property.landAcres}}</p>
        </div>

        <div *ngIf="property.landSquareFeet">
            <ion-label stacked>Land (SF)</ion-label>
            <p class="dark-text">{{property.landSquareFeet}}</p>
        </div>

        <div *ngIf="property.stories">
            <ion-label stacked>Number of stories</ion-label>
            <p class="dark-text">{{property.stories}}</p>
        </div>

        <div *ngIf="property.yearBuilt">
            <ion-label stacked>Year built</ion-label>
            <p class="dark-text">{{property.yearBuilt}}</p>
        </div>

        <div *ngIf="property.yearRenovated">
            <ion-label stacked>Year renovated</ion-label>
            <p class="dark-text">{{property.yearRenovated}}</p>
        </div>

        <div *ngIf="property.buildingLocation">
            <ion-label stacked>Location description</ion-label>
            <p class="dark-text">{{property.buildingLocation}}</p>
        </div>
    </ion-card-content>
</ion-card>
