var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { QueryList, OnDestroy, OnInit } from '@angular/core';
import { Content, NavParams, Slides, ToastController, ViewController } from 'ionic-angular';
import * as moment from 'moment';
import { TaskModel, TaskTemplateModel } from '@apto/models';
import { CompsService, ContractsService, PlaybooksService, TasksService, TypesService } from '@apto/services';
import { TOAST_DURATION } from '@apto/shared/constants';
import { PipelineModel } from '@apto/models/pipeline/pipeline.model';
import { EditTaskForm } from '@apto/shared/components';
import './pipeline-conversion-modal.scss';
export var PipelineConversionModalMode = /*@__PURE__*/ (function (PipelineConversionModalMode) {
    PipelineConversionModalMode[PipelineConversionModalMode["View"] = 0] = "View";
    PipelineConversionModalMode[PipelineConversionModalMode["Add"] = 1] = "Add";
    PipelineConversionModalMode[PipelineConversionModalMode["Edit"] = 2] = "Edit";
    return PipelineConversionModalMode;
})({});
var PipelineConversionModal = /** @class */ /*@__PURE__*/ (function () {
    function PipelineConversionModal(navParams, viewController, tasksService, toastController, playbooksService, typesService, compsService, contractsService) {
        this.navParams = navParams;
        this.viewController = viewController;
        this.tasksService = tasksService;
        this.toastController = toastController;
        this.playbooksService = playbooksService;
        this.typesService = typesService;
        this.compsService = compsService;
        this.contractsService = contractsService;
        this.additionalTasks = [];
        this.Mode = PipelineConversionModalMode;
        this.mode = PipelineConversionModalMode.View;
        this.selectAll = false;
        this.selectedTasks = new Set();
        this.task = new TaskModel();
        this.tasksFromTemplate = [];
        this.subscriptions = [];
    }
    Object.defineProperty(PipelineConversionModal.prototype, "pipelineObject", {
        get: function () {
            return this.pipelineConversionParams && this.pipelineConversionParams.pipelineObject || null;
        },
        set: function (value) {
            this.pipelineConversionParams.pipelineObject = value;
        },
        enumerable: true,
        configurable: true
    });
    PipelineConversionModal.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, types;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.slides.onlyExternal = true;
                        this.pipelineConversionParams = this.navParams.get('pipelineConversionParams');
                        _a = this;
                        return [4 /*yield*/, this.playbooksService.fetch({ where: { targetStage: this.pipelineConversionParams.destination } })];
                    case 1:
                        _a.playbooks = _b.sent();
                        if (!this.recordTypeIsRequired)
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.typesService.fetch()];
                    case 2:
                        types = _b.sent();
                        if (this.pipelineConversionParams.destination === PipelineModel.STAGES.CONTRACT) {
                            this.recordTypeOptions = Object.keys(types.contractTypes);
                        }
                        else {
                            this.recordTypeOptions = Object.keys(types.compTypes);
                        }
                        _b.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    PipelineConversionModal.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    Object.defineProperty(PipelineConversionModal.prototype, "recordTypeIsRequired", {
        get: function () {
            return this.pipelineConversionParams.destination === PipelineModel.STAGES.CONTRACT
                || this.pipelineConversionParams.destination === PipelineModel.STAGES.CLOSED_DEAL;
        },
        enumerable: true,
        configurable: true
    });
    PipelineConversionModal.prototype.onRecordTypeChanged = function (selection) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.pipelineObject.type = selection;
                        if (!(this.pipelineConversionParams.destination === PipelineModel.STAGES.CONTRACT))
                            return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.contractsService.upsert(this.pipelineObject)];
                    case 1:
                        _a.pipelineObject = _c.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        _b = this;
                        return [4 /*yield*/, this.compsService.upsert(this.pipelineObject)];
                    case 3:
                        _b.pipelineObject = _c.sent();
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    PipelineConversionModal.prototype.onSelectAllTasks = function (isSelected) {
        var _this = this;
        if (isSelected) {
            this.tasksFromTemplate.forEach(function (task) {
                _this.selectedTasks.add(task);
            });
        }
        else {
            this.selectedTasks.clear();
        }
    };
    PipelineConversionModal.prototype.onTaskSelectionChanged = function (isSelected, task) {
        if (isSelected) {
            this.selectedTasks.add(task);
        }
        else {
            this.selectedTasks.delete(task);
        }
    };
    PipelineConversionModal.prototype.onPlaybookSelectionChanged = function (playbookName) {
        var _this = this;
        this.tasksFromTemplate = [];
        this.playbook = this.playbooks.find(function (template) { return template.name === playbookName; });
        this.playbook.taskTemplates.forEach(function (taskTemplate) {
            var newTask = new TaskModel(taskTemplate);
            newTask.dueDate = moment().add(taskTemplate.dueDateOffset, 'days').format('YYYY-MM-DD');
            _this.tasksFromTemplate.push(newTask);
        });
        this.selectAll = true;
        this.onSelectAllTasks(true);
    };
    PipelineConversionModal.prototype.addTask = function (isTemplateTask) {
        if (isTemplateTask === void 0) {
            isTemplateTask = false;
        }
        this.isTemplateTask = isTemplateTask;
        this.mode = PipelineConversionModalMode.Add;
        this.slides.slideTo(1);
    };
    PipelineConversionModal.prototype.addTasks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var tasksToAdd;
            return __generator(this, function (_a) {
                tasksToAdd = Array.from(this.selectedTasks).concat(this.additionalTasks);
                tasksToAdd.forEach(function (task) { return _this.updateTask(task); });
                this.dismiss();
                return [2 /*return*/];
            });
        });
    };
    PipelineConversionModal.prototype.back = function () {
        this.mode = PipelineConversionModalMode.View;
        this.slides.slideTo(0);
        this.task = new TaskModel();
    };
    PipelineConversionModal.prototype.scrollToTop = function () {
        this.content.forEach(function (content) {
            content.scrollToTop();
        });
    };
    PipelineConversionModal.prototype.dismiss = function () {
        this.viewController.dismiss();
    };
    PipelineConversionModal.prototype.onRemoveTask = function (task) {
        var taskTemplateIndex = this.getTemplateIndexByTask(task);
        this.tasksFromTemplate.splice(this.tasksFromTemplate.indexOf(task), 1);
        this.selectedTasks.delete(task);
        this.playbook.taskTemplates.splice(taskTemplateIndex, 1);
        this.playbooksService.upsert(this.playbook);
    };
    PipelineConversionModal.prototype.onDeleteTask = function (task) {
        if (task._id) {
            this.tasksService.delete([task._id]);
        }
        this.additionalTasks.splice(this.additionalTasks.indexOf(task), 1);
    };
    PipelineConversionModal.prototype.saveTask = function () {
        var _this = this;
        var result = this.editTaskForm.save();
        if (!result || !result.task) {
            return;
        }
        if (this.mode === PipelineConversionModalMode.Add) {
            if (this.isTemplateTask) {
                this.tasksFromTemplate.push(result.task);
                this.onTaskSelectionChanged(true, result.task);
                this.playbook.taskTemplates.push(new TaskTemplateModel(result.task));
                this.playbooksService.upsert(this.playbook);
            }
            else {
                this.additionalTasks.push(result.task);
            }
        }
        else {
            if (this.isTemplateTask) {
                this.updateTaskTemplate(this.task, result.task);
                this.updateTemplateTask(this.task, result.task);
            }
            else {
                var index = this.additionalTasks.findIndex(function (task) { return _this.task.name === task.name; });
                this.additionalTasks[index] = result.task;
            }
        }
        this.back();
    };
    PipelineConversionModal.prototype.viewTask = function (task, isTemplateTask) {
        if (isTemplateTask === void 0) {
            isTemplateTask = false;
        }
        this.isTemplateTask = isTemplateTask;
        this.mode = PipelineConversionModalMode.Edit;
        this.slides.slideTo(1);
        this.task = task;
    };
    PipelineConversionModal.prototype.updateTask = function (task) {
        task.whatId = this.pipelineObject._id;
        this.tasksService.upsert(task);
        this.showToast();
    };
    PipelineConversionModal.prototype.updateTaskTemplate = function (oldTask, newTask) {
        var templateIndex = this.getTemplateIndexByTask(oldTask);
        if (templateIndex >= 0) {
            var template = this.playbook.taskTemplates[templateIndex];
            template.name = newTask.name;
            template.description = newTask.description;
            template.type = newTask.type;
            this.playbooksService.upsert(this.playbook);
        }
    };
    PipelineConversionModal.prototype.updateTemplateTask = function (oldTask, newTask) {
        var oldTaskIndex = this.tasksFromTemplate.findIndex(function (listTask) { return oldTask === listTask; });
        this.tasksFromTemplate[oldTaskIndex] = newTask;
        this.selectedTasks.delete(oldTask);
        this.selectedTasks.add(newTask);
    };
    PipelineConversionModal.prototype.getTemplateIndexByTask = function (task) {
        return this.playbook.taskTemplates.findIndex(function (taskTemplate) { return taskTemplate.name === task.name; });
    };
    PipelineConversionModal.prototype.showToast = function () {
        if (!this.tasksFromTemplate.length) {
            return;
        }
        var toast = this.toastController.create({
            duration: TOAST_DURATION,
            message: 'Saving Tasks',
            showCloseButton: true
        });
        if (toast) {
            toast.present();
        }
    };
    return PipelineConversionModal;
}());
export { PipelineConversionModal };
