<div class="task-type-selector">
    <button [ngClass]="{'selected': selectedType === TaskModel.TYPE.CALL}"
            (click)="setTaskType(TaskModel.TYPE.CALL)"
            ion-button
            clear>
        <ion-icon circle name="call"></ion-icon>
        Call
    </button>
    <button [ngClass]="{'selected': selectedType === TaskModel.TYPE.EMAIL}"
            (click)="setTaskType(TaskModel.TYPE.EMAIL)"
            ion-button
            clear>
        <ion-icon circle name="mail"></ion-icon>
        Email
    </button>
    <button [ngClass]="{'selected': selectedType === TaskModel.TYPE.MEETING}"
            (click)="setTaskType(TaskModel.TYPE.MEETING)"
            ion-button
            clear>
        <ion-icon circle name="calendar"></ion-icon>
        Meeting
    </button>
    <button [ngClass]="{'selected': selectedType=== TaskModel.TYPE.TOUR}"
            (click)="setTaskType(TaskModel.TYPE.TOUR)"
            ion-button
            clear>
        <ion-icon circle name="home"></ion-icon>
        Tour
    </button>
    <button [ngClass]="{'selected': selectedType === TaskModel.TYPE.OTHER}"
            (click)="setTaskType(TaskModel.TYPE.OTHER)"
            ion-button
            clear>
        <ion-icon circle name="document"></ion-icon>
        Other
    </button>
</div>
