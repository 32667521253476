<ion-card class="buyer-activity-card">
    <ion-card-header>Deal activity</ion-card-header>
    <ion-card-content *ngIf="callList; else noCallList">
        <ion-row *ngFor="let countInfo of stageCountInfo">
            <ion-col col-3 class="buyer-activity-card--label-col">
                <ion-label>{{ countInfo.label }}</ion-label>
            </ion-col>
            <ion-col col-6 class="buyer-activity-card--count-col">
               <div class="buyer-activity-card--count-bar"
                     [ngStyle]="{ width: getWidth(countInfo), color: countInfo.count === 0 ? '#9e9e9e' : 'white' }">
                    {{ countInfo.count }}
                </div>
            </ion-col>
            <ion-col col-3 class="buyer-activity-card--button-col">
                <button ion-button
                        clear
                        small
                        [disabled]="countInfo.count === 0"
                        color="secondary-grey"
                        (click)="viewList(countInfo.label)">
                    View List
                </button>
                <button ion-button
                        clear
                        small
                        [disabled]="countInfo.count === 0"
                        color="primary-blue"
                        (click)="startCalling(countInfo.label)">
                    Start Calling
                </button>
            </ion-col>
        </ion-row>
        <ion-row class="card-footer-buttons">
            <ion-col text-right>
                <button ion-button
                        clear
                        (click)="exportCsv()">
                    Export Activity Report
                </button>
            </ion-col>
        </ion-row>
    </ion-card-content>
    <ng-template #noCallList>
        <ion-item class="empty-card">
            <ion-icon name="group"
                      material-icons
                      circle
                      small
                      color="white"
                      item-left></ion-icon>
            Add prospects to this deal.
        </ion-item>
        <ion-row class="card-footer-buttons">
            <ion-col text-right>
                <button ion-button
                        clear
                        (click)="build()">
                    Build List
                </button>
            </ion-col>
        </ion-row>
    </ng-template>

    <busy-panel *ngIf="callList && callList.isPending"></busy-panel>
</ion-card>
