var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { default as deepmerge } from 'deepmerge';
import { FullContactPersonModel } from '@apto/models';
import { AnalyticsService, CompsService, ContactsService, FullContactService, ListingsService, OwnershipsService, OwnershipRelationsService, PropertiesService, RescourService, TasksService } from '@apto/services';
import { MAX_PRELOAD_COUNT } from '@apto/pn/constants';
import { LogsService } from '@apto/ionic-lib/services';
import { LeasingDataService } from '@apto/pn/services/leasing-data/leasing-data-service';
var DashboardDataService = /** @class */ /*@__PURE__*/ (function () {
    function DashboardDataService(analyticsService, compsService, contactsService, fullContactService, listingsService, ownershipRelationsService, ownershipsService, propertiesService, rescourService, tasksService, leasingDataService, logsService) {
        this.analyticsService = analyticsService;
        this.compsService = compsService;
        this.contactsService = contactsService;
        this.fullContactService = fullContactService;
        this.listingsService = listingsService;
        this.ownershipRelationsService = ownershipRelationsService;
        this.ownershipsService = ownershipsService;
        this.propertiesService = propertiesService;
        this.rescourService = rescourService;
        this.tasksService = tasksService;
        this.leasingDataService = leasingDataService;
        this.logsService = logsService;
        this.nextContacts = [];
        this.soldsByPropertyIds = {};
        this.soldsAndLeasesByPropertyIds = {};
        this.listingsByPropertyIds = {};
        this.newsByPropertyIds = {};
        this.ownershipsByPropertyIds = {};
        this.propertiesByOwner = {};
        this.leaseMapByOwner = {};
        this.tasksByContact = {};
        this.newsEnabled = false;
        this.leasingEnabled = false;
        this.spacesEnabled = false;
    }
    /**
     * Sets the call list currently being used by the dashboard and resets class attributes for storing data.
     */
    DashboardDataService.prototype.setCallList = function (callList) {
        if (this.callList !== callList) {
            this.callList = callList;
            this.nextContacts = [];
            this.soldsByPropertyIds = {};
            this.soldsAndLeasesByPropertyIds = {};
            this.listingsByPropertyIds = {};
            this.newsByPropertyIds = {};
            this.ownershipsByPropertyIds = {};
            this.propertiesByOwner = {};
            this.leaseMapByOwner = {};
            this.tasksByContact = {};
        }
    };
    DashboardDataService.prototype.getRescourNews = function (properties) {
        return __awaiter(this, void 0, void 0, function () {
            var key, news, points_1, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!properties || !properties.length || !this.newsEnabled) {
                            return [2 /*return*/, []];
                        }
                        key = properties.map(function (p) { return p._id; }).join(',');
                        news = this.newsByPropertyIds[key];
                        if (!!news)
                            return [3 /*break*/, 4];
                        points_1 = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        properties.forEach(function (p) {
                            if (p.location) {
                                points_1.push(p.location.lng + "," + p.location.lat);
                            }
                        });
                        if (!points_1.length) {
                            this.logsService.info("Failed RescourNews query, properties not geocoded");
                            return [2 /*return*/, []];
                        }
                        return [4 /*yield*/, this.rescourService.byLocation(points_1)];
                    case 2:
                        news = _a.sent();
                        // cache it
                        this.newsByPropertyIds[key] = news;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        news = [];
                        this.logsService.exception('Rescour news dashboard error', err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, news];
                }
            });
        });
    };
    /**
     * Gets a contact by contactId and preloads the next contact if available.
     */
    DashboardDataService.prototype.getContact = function (contactId, loadNext) {
        if (loadNext === void 0) {
            loadNext = true;
        }
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var index, skippedContacts, contact, contacts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!contactId) {
                            return [2 /*return*/, null];
                        }
                        index = this.nextContacts.findIndex(function (contact) { return contact._id === contactId; });
                        if (!(index !== -1))
                            return [3 /*break*/, 3];
                        skippedContacts = this.nextContacts.splice(0, index);
                        skippedContacts.forEach(function (c) { return _this.clearContactData(c._id); });
                        contact = this.nextContacts.shift();
                        if (!loadNext)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.loadNextContacts(contact)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, contact];
                    case 3: return [4 /*yield*/, this.getContactsById([contactId])];
                    case 4:
                        contacts = _a.sent();
                        if (!contacts) {
                            return [2 /*return*/, null];
                        }
                        if (!loadNext)
                            return [3 /*break*/, 6];
                        return [4 /*yield*/, this.loadNextContacts(contacts[0])];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [2 /*return*/, contacts[0]];
                }
            });
        });
    };
    /**
     * Get comps associated with property ids.
     */
    DashboardDataService.prototype.getComps = function (propertyIds) {
        return __awaiter(this, void 0, void 0, function () {
            var key, comps, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!propertyIds || !propertyIds.length) {
                            return [2 /*return*/, []];
                        }
                        key = propertyIds.join(',');
                        comps = this.soldsByPropertyIds[key];
                        if (!!comps)
                            return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.compsService.bySoldOrLeasedProperties(propertyIds)];
                    case 2:
                        comps = _a.sent();
                        comps = comps.filter(function (comp) { return comp.isSale || !comp.isLease; });
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        comps = [];
                        this.logsService.exception('get comps dashboard error', err_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, comps];
                }
            });
        });
    };
    /**
     * Get sold and leased comps associated with property ids.
     */
    DashboardDataService.prototype.getSoldAndLeasedComps = function (propertyIds) {
        return __awaiter(this, void 0, void 0, function () {
            var key, comps, err_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!propertyIds || !propertyIds.length) {
                            return [2 /*return*/, []];
                        }
                        key = propertyIds.join(',');
                        comps = this.soldsAndLeasesByPropertyIds[key];
                        if (!!comps)
                            return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.compsService.bySoldOrLeasedProperties(propertyIds)];
                    case 2:
                        comps = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_3 = _a.sent();
                        comps = [];
                        this.logsService.exception('get comps dashboard error', err_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, comps];
                }
            });
        });
    };
    /**
     * Gets listings associated with property ids.
     */
    DashboardDataService.prototype.getListings = function (propertyIds) {
        return __awaiter(this, void 0, void 0, function () {
            var key, listings, err_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!propertyIds || !propertyIds.length) {
                            return [2 /*return*/, []];
                        }
                        key = propertyIds.join(',');
                        listings = this.listingsByPropertyIds[key];
                        if (!(!listings || listings.length === 0))
                            return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.listingsService.forProperties(propertyIds)];
                    case 2:
                        listings = _a.sent();
                        // cache it
                        this.listingsByPropertyIds[key] = listings;
                        return [3 /*break*/, 4];
                    case 3:
                        err_4 = _a.sent();
                        listings = [];
                        this.logsService.exception('get properties dashboard error', err_4);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, listings];
                }
            });
        });
    };
    /**
     * Get ownerships associated with property ids.
     */
    DashboardDataService.prototype.getOwnerships = function (propertyIds) {
        return __awaiter(this, void 0, void 0, function () {
            var ownerships, err_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!propertyIds || !propertyIds.length) {
                            return [2 /*return*/, []];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, this.ownershipsService.byProperties(propertyIds)];
                    case 2:
                        ownerships = _a.sent();
                        return [4 /*yield*/, Promise.all([
                                this.ownershipRelationsService.attachCompanyOwners(ownerships),
                                this.ownershipRelationsService.attachContactOwners(ownerships)
                            ])];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, ownerships];
                    case 4:
                        err_5 = _a.sent();
                        this.logsService.exception('get property ownership dashboard error', err_5);
                        return [2 /*return*/, []];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Get properties associated with a contact id.
     */
    DashboardDataService.prototype.getPropertiesByOwner = function (contactId) {
        return __awaiter(this, void 0, void 0, function () {
            var properties, err_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!contactId) {
                            throw new Error('Cannot fetch properties without a contact');
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.propertiesService.byOwner(contactId)];
                    case 2:
                        properties = _a.sent();
                        properties.sort(function (a, b) { return a.name < b.name ? -1 : (a.name > b.name ? 1 : 0); });
                        // cache it
                        // this.propertiesByOwner[contactId] = properties;
                        return [2 /*return*/, properties];
                    case 3:
                        err_6 = _a.sent();
                        this.logsService.exception('get properties by owner dashboard error', err_6);
                        throw err_6;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DashboardDataService.prototype.getChildSpaceCountsByParentPropertyIds = function (parentPropertyIds) {
        if (!this.spacesEnabled) {
            return Promise.resolve({});
        }
        try {
            return this.propertiesService.getSpaceCountForBuildingIds(parentPropertyIds);
        }
        catch (err) {
            this.logsService.exception('get child space counts by parent property ids error', err);
            throw err;
        }
    };
    DashboardDataService.prototype.getLeasesForContact = function (contact) {
        return __awaiter(this, void 0, void 0, function () {
            var contactId, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!contact || !contact._id || !this.leasingEnabled) {
                            return [2 /*return*/, []];
                        }
                        contactId = contact._id;
                        if (this.leaseMapByOwner[contactId]) {
                            return [2 /*return*/, this.leaseMapByOwner[contactId]];
                        }
                        return [4 /*yield*/, this.leasingDataService.getLeasesForContact(contact, false)];
                    case 1:
                        result = _a.sent();
                        if (!result) {
                            result = [];
                        }
                        this.leaseMapByOwner[contactId] = result;
                        return [2 /*return*/, this.leaseMapByOwner[contactId]];
                }
            });
        });
    };
    /**
     * Get tasks associated with a contact id.
     */
    DashboardDataService.prototype.getTasks = function (contactId, forceReload) {
        return __awaiter(this, void 0, void 0, function () {
            var tasks, err_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!contactId) {
                            return [2 /*return*/, []];
                        }
                        if (!(!tasks || forceReload))
                            return [3 /*break*/, 4];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.tasksService.byContact(contactId, forceReload)];
                    case 2:
                        tasks = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_7 = _a.sent();
                        tasks = [];
                        this.logsService.exception('get tasks by contact dashboard error', err_7);
                        throw err_7;
                    case 4: return [2 /*return*/, tasks];
                }
            });
        });
    };
    DashboardDataService.prototype.getFullContactInsights = function (contact) {
        var _this = this;
        var promises = [];
        if (contact.phone && contact.phone.number) {
            var promise = this.fullContactService.byPhone(contact.phone.number)
                .catch(function (e) {
                _this.handleFullContactError('phone', e);
                return null;
            });
            promises.push(promise);
        }
        if (contact.email && contact.email.address) {
            var promise = this.fullContactService.byEmail(contact.email.address)
                .catch(function (e) {
                _this.handleFullContactError('email', e);
                return null;
            });
            promises.push(promise);
        }
        return Promise.all(promises)
            .then(function (results) {
            if (results[0] && results[1]) {
                _this.analyticsService.logEvent('Successful FullContact Query Email/Phone');
                // merge backwards since phone data is more valuable than email so
                // it shows at front of arrays
                var apiResult = _this.mergeFullcontactProfiles(results[1], results[0]);
                return new FullContactPersonModel({ apiResult: apiResult });
            }
            else if (results[0]) {
                _this.analyticsService.logEvent('Successful FullContact Query Phone');
                return results[0]; // only phonenumber data came back
            }
            else if (results[1]) {
                _this.analyticsService.logEvent('Successful FullContact Query Email');
                return results[1]; // only email data came back
            }
            else {
                // this.logger.exception('FullContact query failed', e);
            }
            return null;
        });
    };
    /**
     * Merge multiple fullcontact results into one.
     * TODO figure out how to properly merge. Right now this can clobber data.
     */
    DashboardDataService.prototype.mergeFullcontactProfiles = function (profileOne, profileTwo) {
        var apiResult = deepmerge(profileOne.apiResult || {}, profileTwo.apiResult || {});
        if (apiResult.socialProfiles && apiResult.socialProfiles.length) {
            apiResult.socialProfiles = this.dedupeSocialProfiles(apiResult.socialProfiles);
            apiResult.organizations = this.dedupeOrganizations(apiResult.organizations);
            if (apiResult.digitalFootprint) {
                apiResult.digitalFootprint.topics = this.dedupeTopics(apiResult.digitalFootprint.topics);
            }
        }
        return apiResult;
    };
    /**
     * Recursively loads contacts until nextContacts reached the MAX_PRELOAD_COUNT. Used for prefetching dashboard data.
     */
    DashboardDataService.prototype.loadNextContacts = function (currentContact) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var nextGroupMember, nextContactId, nextContact, err_8, propertyIds, properties, err_9, err_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.nextContacts.length >= MAX_PRELOAD_COUNT || !this.callList) {
                            return [2 /*return*/, null];
                        }
                        nextGroupMember = this.callList.nextContact(currentContact._id);
                        if (!nextGroupMember || !nextGroupMember.contactId) {
                            // end of list
                            return [2 /*return*/, null];
                        }
                        nextContactId = nextGroupMember.contactId;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getContact(nextContactId, false)];
                    case 2:
                        // get the next contact
                        nextContact = _a.sent();
                        if (nextContact) {
                            this.nextContacts.push(nextContact);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        err_8 = _a.sent();
                        this.logsService.exception('Failed to prefetch next contact from DashboardLoader', err_8);
                        nextContact = null;
                        throw err_8;
                    case 4:
                        if (!nextContact)
                            return [3 /*break*/, 13];
                        propertyIds = [];
                        _a.label = 5;
                    case 5:
                        _a.trys.push([5, 7, , 8]);
                        return [4 /*yield*/, this.getPropertiesByOwner(nextContact._id)];
                    case 6:
                        properties = _a.sent();
                        if (properties && properties.length) {
                            propertyIds = properties.map(function (property) { return property._id; });
                        }
                        return [3 /*break*/, 8];
                    case 7:
                        err_9 = _a.sent();
                        this.logsService.exception('Failed to prefetch properties for contact', err_9);
                        throw err_9;
                    case 8:
                        try {
                            Promise.all([
                                this.getTasks(nextContactId),
                                this.getFullContactInsights(nextContact)
                            ]);
                        }
                        catch (err) {
                            this.logsService.exception('Failed to prefetch from DashBoardDataService', err);
                            throw err;
                        }
                        if (!propertyIds.length)
                            return [3 /*break*/, 13];
                        // do this one first as it will create more caches for other queries
                        return [4 /*yield*/, this.getLeasesForContact(nextContact)
                                .catch(function (err) {
                                _this.logsService.exception('Failed to prefetch leases for contact', err);
                            })];
                    case 9:
                        // do this one first as it will create more caches for other queries
                        _a.sent();
                        _a.label = 10;
                    case 10:
                        _a.trys.push([10, 12, , 13]);
                        return [4 /*yield*/, Promise.all([
                                this.getComps(propertyIds),
                                this.getListings(propertyIds),
                                this.getOwnerships(propertyIds)
                            ])];
                    case 11:
                        _a.sent();
                        return [3 /*break*/, 13];
                    case 12:
                        err_10 = _a.sent();
                        this.logsService.exception('Failed to prefetch from DashBoardDataService', err_10);
                        throw err_10;
                    case 13:
                        if (!nextContact)
                            return [3 /*break*/, 15];
                        return [4 /*yield*/, this.loadNextContacts(nextContact)];
                    case 14: return [2 /*return*/, _a.sent()];
                    case 15: return [2 /*return*/, null];
                }
            });
        });
    };
    /**
     * Remove data associated with propertyIds
     */
    DashboardDataService.prototype.clearPropertyData = function (ids) {
        var key = ids.join(',');
        delete this.soldsByPropertyIds[key];
        delete this.soldsAndLeasesByPropertyIds[key];
        delete this.listingsByPropertyIds[key];
        delete this.ownershipsByPropertyIds[key];
    };
    /**
     * Remove data associated with leases
     */
    DashboardDataService.prototype.clearLeaseData = function (contactId) {
        delete this.leaseMapByOwner[contactId];
    };
    /**
     * Removes entries from the various attribute maps related to a given contact id.
     */
    DashboardDataService.prototype.clearContactData = function (contactId) {
        delete this.tasksByContact[contactId];
        delete this.leaseMapByOwner[contactId];
        delete this.newsByPropertyIds[contactId];
        var contactProperties = this.propertiesByOwner[contactId];
        if (!contactProperties) {
            return;
        }
        delete this.propertiesByOwner[contactId];
        var propertyIds = contactProperties.map(function (property) { return property._id; });
        this.clearPropertyData(propertyIds);
    };
    DashboardDataService.prototype.dedupeSocialProfiles = function (socialProfiles) {
        var dedupedSocialProfiles = [];
        if (socialProfiles) {
            socialProfiles.forEach(function (profile) {
                var hasProfile = dedupedSocialProfiles.find(function (p) { return p.url === profile.url; });
                if (!hasProfile) {
                    dedupedSocialProfiles.push(profile);
                }
            });
        }
        return dedupedSocialProfiles;
    };
    DashboardDataService.prototype.dedupeTopics = function (topics) {
        var dedupedTopics = [];
        if (topics) {
            topics.forEach(function (topic) {
                var hasTopic = dedupedTopics.find(function (t) { return t.value === topic.value; });
                if (!hasTopic) {
                    dedupedTopics.push(topic);
                }
            });
        }
        return dedupedTopics;
    };
    DashboardDataService.prototype.dedupeOrganizations = function (organizations) {
        var dedupedOrganizations = [];
        if (organizations) {
            organizations.forEach(function (organization) {
                var hasOrganization = dedupedOrganizations.find(function (o) {
                    return o.name === organization.name
                        && o.title === organization.title
                        && o.startDate === organization.startDate
                        && o.endDate === organization.endDate;
                });
                if (!hasOrganization) {
                    dedupedOrganizations.push(organization);
                }
            });
        }
        return dedupedOrganizations;
    };
    DashboardDataService.prototype.getContactsById = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            var results, ex_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.contactsService.in(ids)];
                    case 1:
                        results = _a.sent();
                        return [2 /*return*/, results && results.length ? results : null];
                    case 2:
                        ex_1 = _a.sent();
                        this.logsService.exception('Failed to fetch contact by id from DashBoardDataService', ex_1);
                        throw ex_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    DashboardDataService.prototype.handleFullContactError = function (context, error) {
        if (error && error.status && error.status === 'failure' && error.data && error.data.statusCode) {
            var statusCode = error.data.statusCode;
            if (statusCode === 202) {
                this.analyticsService.logEvent("FullContact " + context + " query being processed", error);
                this.logsService.info("FullContact: " + context + " query accepted, need to try again later");
            }
            else if (statusCode === 403) {
                this.analyticsService.logEvent("FullContact " + context + " query forbidden", error);
                this.logsService.exception("FullContact api key invalid or rate limit exceeded", error);
            }
            else if (statusCode === 404) {
                this.analyticsService.logEvent("FullContact " + context + " query not found", error);
            }
            else if (statusCode < 500) {
                this.logsService.info("FullContact: " + context + " error - " + JSON.stringify(error, null, 2));
            }
            else if (statusCode > 500) {
                this.analyticsService.logEvent("FullContact " + context + " error", error);
                this.logsService.exception("FullContact: " + context + " query error on their end", error);
            }
            else {
                this.analyticsService.logEvent("FullContact " + context + " unknown error", error);
                this.logsService.exception("FullContact: " + context + " unknown error", error);
            }
        }
    };
    return DashboardDataService;
}());
export { DashboardDataService };
