<apto-card>
    <apto-card-header noBottomPadding>
        Property summary
        <span *ngIf="featuresService.isFeatureEnabled(attomFeature)"
              class="attom-badge property-summary-card--attom-data-points"
              (click)="attomClick($event)"
              [title]="hasLoadedAttomData ?
                'We have data points available. Click for more information.' : 'Loading insights...'">
            <span *ngIf="attomDataBadgeCount">
                {{ attomDataBadgeCount }}
            </span>
            <span *ngIf="!hasLoadedAttomData">-</span>
        </span>
    </apto-card-header>
    <apto-card-content noPadding>
        <apto-row>
            <apto-col sm="7">
                <prop-item
                    [property]="property"
                    [isShownOnMap]="isShownOnMap"
                    [isImageShown]="true"></prop-item>
            </apto-col>
            <apto-col sm="5">
                <am-apto-static-map
                    *ngIf="property && property.addressString"
                    size="355x126"
                    scale="2"
                    markers="{{property.addressString.trim()}}"
                    class="property-summary-card--static-map">
                </am-apto-static-map>
            </apto-col>
        </apto-row>
        <div *ngIf="property.saleDate && !listingDate" class="property-summary-card--sold-date-overlay">Sold &bull; {{property.saleDate | date:"MM/dd/yy"}}</div>
        <div *ngIf="listingDate" class="property-summary-card--sold-date-overlay">For Sale &bull; {{listingDate | date:"MM/dd/yy"}}</div>
    </apto-card-content>
</apto-card>
