<ion-content class="page list-page contacts-page">
    <ion-grid class="content-grid">
        <ion-row class="list-page--header">
            <h2>Contacts</h2>

            <div class="list-page--summary">
                <apto-icon icon="person" circle inline size="2"></apto-icon>
                <div class="list-page--label">
                    <div class="list-page--line">
                        <span *ngIf="!isInitializing; else totalShimmer">
                            <strong>{{items.length}}</strong> of {{totalContacts}}</span>
                        <ng-template #totalShimmer>
                            <apto-shimmer class="contacts-page--total-shimmer"></apto-shimmer>
                        </ng-template>
                    </div>
                    <div class="list-page--line list-page--small">
                        Contacts
                    </div>
                </div>
            </div>
        </ion-row>
        <ion-row class="list-page--body contacts-page--body">
            <ion-col class="list-page--filters contacts-page--body-left"
                     col-auto>
                <ion-content>
                    <add-call-list-members-card [contacts]="selectedItemsArray"
                                                [filterListing]="filterListing"
                                                (onClearAll)="clearAllItems()">
                    </add-call-list-members-card>
                    <ion-card>
                        <ion-card-header>
                            Search and filter contacts
                        </ion-card-header>
                        <ion-card-content>
                            <filter-panel #searchFilterPanel></filter-panel>
                            <div class="filter-panel--component">
                                <div class="filter-panel--children">
                                    <span class="filter-panel--title">Filter by</span>
                                    <ion-list *ngIf="featuresService.isFeatureEnabled(featuresService.FEATURES.DEALS)"
                                              id="contacts--filter-type-selector"
                                              radio-group
                                              [(ngModel)]="filterType"
                                              (ionChange)="onFilterTypeChanged()">
                                        <ion-item>
                                            <ion-label>Contacts</ion-label>
                                            <ion-radio item-left
                                                       value="contacts"
                                                       checked></ion-radio>
                                        </ion-item>

                                        <ion-item>
                                            <ion-label>Buyer's needs</ion-label>
                                            <ion-radio item-left
                                                       value="buyerNeeds"></ion-radio>
                                        </ion-item>
                                    </ion-list>

                                    <filter-panel #contactsFilterPanel
                                                  id="contacts--contact-filter-panel"
                                                  [ngClass]="{ hidden: filterType != 'contacts' }"></filter-panel>
                                    <filter-panel #buyerNeedsFilterPanel
                                                  id="contacts--buyer-needs-filter-panel"
                                                  [ngClass]="{ hidden: filterType != 'buyerNeeds' }"></filter-panel>
                                </div>
                                <div *ngIf="!filtersAreEmpty()"
                                     class="filter-panel--reset-container">
                                    <button class="filter-panel--reset-button"
                                            ion-button
                                            clear
                                            color="primary-blue"
                                            (click)="resetFilters()">
                                        Reset Filters
                                    </button>
                                </div>
                            </div>
                        </ion-card-content>
                    </ion-card>
                </ion-content>
            </ion-col>
            <ion-col class="contacts-page--body-right">
                <ion-content>
                    <ion-card *ngIf="!isInitializing">
                        <ion-card-header>
                            <ion-buttons start>
                                <button ion-button
                                        clear
                                        [ngClass]="{ active: listType === 'recent' }"
                                        (click)="setListType('recent')"
                                        [disabled]="listType === 'recent'">
                                    Recent Contacts
                                </button>
                                <button ion-button
                                        clear
                                        [ngClass]="{ active: listType === 'all' }"
                                        (click)="setListType('all')"
                                        [disabled]="listType === 'all'">
                                    All Contacts
                                </button>
                                <button ion-button
                                        clear
                                        [ngClass]="{ active: listType === 'groups' }"
                                        (click)="setListType('groups')"
                                        [disabled]="listType === 'groups'">
                                    Groups
                                </button>
                            </ion-buttons>

                            <ion-buttons end>
                                <button class="automation--add-button"
                                        ion-button
                                        clear
                                        (click)="addContact()">
                                    ADD CONTACT
                                </button>
                            </ion-buttons>
                        </ion-card-header>

                        <ion-card-content>
                            <div class="list-view--info-row">
                                <ion-checkbox class="small"
                                              mode="ios"
                                              color="primary-blue"
                                              [ngModel]="currentPageSelected"
                                              (ngModelChange)="selectAllChanged($event)">
                                </ion-checkbox>

                                <div class="list-page--line">
                                    <ion-label *ngIf="!isInitializing">
                                        Showing {{items.length}} of {{totalContacts}} contacts
                                    </ion-label>
                                </div>

                                <ion-item *ngIf="listType !== 'recent' && filterType === 'contacts'"
                                          class="sort-by-select">
                                    <span>Sort By</span>
                                    <apto-ionic-select [options]="sortKeys"
                                                       [value]="selectedSortKey"
                                                       (onSelectOption)="sortChanged($event.value)"></apto-ionic-select>
                                </ion-item>
                            </div>

                            <ion-list>
                                <contact-item *ngFor="let contact of items"
                                              [contact]="contact"
                                              selectable="true"
                                              [selected]="selectedItems.hasOwnProperty(contact._id)"
                                              (onCall)="call($event)"
                                              (onClick)="viewContact($event)"
                                              (onSelectToggled)="selectChanged($event, contact)"
                                              (onEmail)="email($event)"></contact-item>

                                <ion-item *ngIf="items.length === LIST_VIEW_MAX_RECORDS"
                                          class="list-page--additional-items-label">
                                    Search/filter to view additonal Contacts.
                                </ion-item>
                                <ion-item *ngIf="items.length === 0">
                                    There are no contacts to display.
                                </ion-item>
                            </ion-list>
                        </ion-card-content>
                    </ion-card>

                    <busy-panel *ngIf="isInitializing"
                                message="Loading contacts..."></busy-panel>
                </ion-content>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
