var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { debounceTime } from 'rxjs/operators';
import { AfterViewInit, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ModalController, ToastController } from 'ionic-angular';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { TaskModel } from '@apto/models';
import { AnalyticsService, TasksService } from '@apto/services';
import { LogsService } from '@apto/ionic-lib/services';
import { TOAST_DURATION, LIST_VIEW_MAX_RECORDS } from '@apto/shared/constants';
import { TasksModal, TasksModalMode } from '@apto/shared/modals';
import { AppcuesService, MetricsService } from '@apto/shared/services';
import { DateRangeFilter, FilterPanel, SearchFilter, SelectFilter } from '@apto/pn/components';
import './tasks.scss';
var FORM_DEBOUNCE_TIME = 1000;
var TasksPage = /** @class */ /*@__PURE__*/ (function () {
    function TasksPage(appcuesService, analyticsService, logsService, metricsService, modalController, route, tasksService, toastController) {
        this.appcuesService = appcuesService;
        this.analyticsService = analyticsService;
        this.logsService = logsService;
        this.metricsService = metricsService;
        this.modalController = modalController;
        this.route = route;
        this.tasksService = tasksService;
        this.toastController = toastController;
        this.filterChangeEvent = new EventEmitter();
        this.isInitializing = false;
        this.searchesInFlight = 0;
        this.searchStartedEvent = new EventEmitter();
        this.searchFinishedEvent = new EventEmitter();
        this.LIST_VIEW_MAX_RECORDS = LIST_VIEW_MAX_RECORDS;
        this.listType = 'open';
        this.openTasks = 0;
        this.overdueTasks = 0;
        this.sortMap = {
            'Oldest to Newest': 'dueDate lastModifiedDate',
            'Newest to Oldest': '-dueDate -lastModifiedDate'
        };
        this.tasks = [];
        this.subscriptions = [];
        this.sortKeys = Object.keys(this.sortMap);
        this.selectedSortKey = this.sortKeys[0];
    }
    TasksPage.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var taskId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.metricsService.start('Tasks Page');
                        taskId = this.route.snapshot.paramMap.get('taskId');
                        if (taskId) {
                            this.metricsService.setRouteName('/tasks/:taskId');
                        }
                        else {
                            this.metricsService.setRouteName('/tasks');
                        }
                        this.createFilterPanel();
                        this.subscriptions.push(this.tasksService.onDeleted.subscribe(function () { return _this.updateTasks(); }));
                        this.subscriptions.push(this.tasksService.onUpserted.subscribe(function () { return _this.updateTasks(); }));
                        this.subscriptions.push(this.filterChangeEvent.pipe(debounceTime(FORM_DEBOUNCE_TIME)).subscribe(function () { return _this.updateTasks(); }));
                        this.subscriptions.push(this.searchStartedEvent.subscribe(function () { _this.searchStarted(); }));
                        this.subscriptions.push(this.searchFinishedEvent.subscribe(function () { _this.searchFinished(); }));
                        return [4 /*yield*/, this.updateTasks()];
                    case 1:
                        _a.sent();
                        this.analyticsService.logEvent('Task Tab Load');
                        this.appcuesService.start();
                        this.metricsService.stop('Tasks Page');
                        return [2 /*return*/];
                }
            });
        });
    };
    TasksPage.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.taskId$ = this.route.paramMap.subscribe(function (paramMap) {
            if (!paramMap.get('taskId')) {
                return;
            }
            _this.tasksService.in([paramMap.get('taskId')])
                .then(function (tasks) {
                _this.viewTask(tasks[0]);
            });
        });
    };
    TasksPage.prototype.ngOnDestroy = function () {
        this.taskId$.unsubscribe();
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    TasksPage.prototype.searchStarted = function () {
        this.searchesInFlight++;
        this.isInitializing = true;
    };
    TasksPage.prototype.searchFinished = function () {
        this.searchesInFlight--;
        if (this.searchesInFlight <= 0) {
            this.isInitializing = false;
            this.searchesInFlight = 0;
        }
    };
    TasksPage.prototype.addTask = function () {
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.EDIT
        });
        return tasksModal.present({ updateUrl: false });
    };
    TasksPage.prototype.createFilterPanel = function () {
        var _this = this;
        this.searchFilterPanel.add(SearchFilter, {
            placeholder: 'Find a task',
            queryKey: 'subject'
        });
        var types = Object.keys(TaskModel.TYPE).map(function (key) { return TaskModel.TYPE[key]; });
        this.tasksFilterPanel.add(DateRangeFilter, {
            label: 'Due date',
            queryKey: 'dueDate'
        });
        this.tasksFilterPanel.add(SelectFilter, {
            label: 'Task type',
            queryKey: 'type',
            optionsList: types
        });
        this.subscriptions.push(this.searchFilterPanel.onQueryChange.subscribe(function (filters) {
            _this.isInitializing = true;
            _this.filterChangeEvent.emit();
        }, function (err) { return _this.logsService.error(err); }), this.tasksFilterPanel.onQueryChange.subscribe(function (filters) {
            _this.isInitializing = true;
            _this.filterChangeEvent.emit();
        }, function (err) { return _this.logsService.error(err); }));
    };
    TasksPage.prototype.filtersAreEmpty = function () {
        return this.searchFilterPanel.isEmpty() && this.tasksFilterPanel.isEmpty();
    };
    TasksPage.prototype.getCounts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var openQuery, overdueQuery;
            return __generator(this, function (_a) {
                openQuery = {
                    where: {
                        status: {
                            $ne: TaskModel.STATUS.COMPLETE
                        }
                    },
                    ownerOnly: true
                };
                this.tasksService.count(openQuery).then(function (count) {
                    _this.openTasks = count;
                });
                overdueQuery = {
                    where: {
                        dueDate: {
                            $lt: new Date().toISOString()
                        },
                        status: {
                            $ne: TaskModel.STATUS.COMPLETE
                        }
                    },
                    ownerOnly: true
                };
                this.tasksService.count(overdueQuery).then(function (count) {
                    _this.overdueTasks = count;
                });
                return [2 /*return*/];
            });
        });
    };
    TasksPage.prototype.resetFilters = function () {
        var _this = this;
        this.tasksFilterPanel.resetFilters();
        //mydatepicker using setFocus with delay 10ms when we call clearDate method. 
        //For prevent focus hooking in this fields. Set focus to the search field.
        setTimeout(function () {
            _this.searchFilterPanel.resetFilters();
            _this.searchFilterPanel.filters[0].instance.focus();
            _this.updateTasks();
        }, 15);
    };
    TasksPage.prototype.setListType = function (type) {
        this.listType = type;
        if (this.listType === 'open') {
            this.selectedSortKey = this.sortKeys[0];
        }
        else {
            this.selectedSortKey = this.sortKeys[1];
        }
        if (!this.filtersAreEmpty()) {
            this.resetFilters();
        }
        else {
            this.updateTasks();
        }
    };
    TasksPage.prototype.snoozeTask = function (task) {
        var _this = this;
        var originalDueDate = task.dueDate;
        var snoozedMoment = this.getSnoozedMoment(task);
        task.dueDate = snoozedMoment.format('YYYY-MM-DD');
        this.showToast('Snoozing task until ' + snoozedMoment.format('dddd'));
        return this.tasksService.upsert(task)
            .then(function () {
            _this.updateTasks();
        })
            .catch(function () {
            _this.showToast('Failed to snooze task');
            task.dueDate = originalDueDate;
        });
    };
    TasksPage.prototype.sortChanged = function (sortKey) {
        this.selectedSortKey = sortKey;
        this.updateTasks();
    };
    TasksPage.prototype.toggleTask = function (task) {
        var _this = this;
        this.showToast('Updating task');
        task.isComplete = !task.isComplete;
        if (task.isComplete) {
            this.analyticsService.logEvent('Task Tab Completion');
        }
        return this.tasksService.upsert(task)
            .then(function () {
            _this.updateTasks();
        })
            .catch(function () {
            _this.showToast('Failed to update task');
            task.isComplete = !task.isComplete;
        });
    };
    TasksPage.prototype.updateTasks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var params, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.searchStartedEvent.emit();
                        this.getCounts();
                        params = {
                            limit: LIST_VIEW_MAX_RECORDS,
                            ownerOnly: true,
                            where: this.getFilterQuery()
                        };
                        params.sort = this.listType === 'open' ?
                            this.sortMap[this.selectedSortKey].split(' ')[0] :
                            params.sort = this.sortMap[this.selectedSortKey].split(' ')[1];
                        _a = this;
                        if (!(this.listType === 'open'))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.tasksService.openTasks(params)];
                    case 1:
                        _b = _c.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.tasksService.completedTasks(params)];
                    case 3:
                        _b = _c.sent();
                        _c.label = 4;
                    case 4:
                        _a.tasks = _b;
                        this.searchFinishedEvent.emit();
                        return [2 /*return*/];
                }
            });
        });
    };
    TasksPage.prototype.viewTask = function (task) {
        if (!task || !task._id) {
            return;
        }
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.VIEW,
            selectedTask: task,
            url: {
                params: { taskId: task._id },
                baseHash: '#/tasks'
            }
        });
        return tasksModal.present({ updateUrl: false });
    };
    TasksPage.prototype.getFilterQuery = function () {
        return FilterPanel.getCombinedQuery(this.searchFilterPanel.getQuery(), this.tasksFilterPanel.getQuery());
    };
    TasksPage.prototype.getSnoozedMoment = function (task) {
        var snoozedMoment = task.isOverdue
            ? moment().add(1, 'day')
            : moment(task.dueDate).add(1, 'day');
        var dayOfWeek = snoozedMoment.weekday();
        // next we are checking if we land on a sunday or saturday, and adding extra days to make it a weekday instead
        if (dayOfWeek === 0) {
            return snoozedMoment.add(1, 'day');
        }
        else if (dayOfWeek === 6) {
            return snoozedMoment.add(2, 'day');
        }
        else {
            return snoozedMoment;
        }
    };
    TasksPage.prototype.showToast = function (message) {
        var toast = this.toastController.create({
            duration: TOAST_DURATION,
            message: message,
            showCloseButton: true
        });
        toast.present();
    };
    return TasksPage;
}());
export { TasksPage };
