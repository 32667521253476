// Total Time is 1500 * 15 =  (22.5 seconds). After this time then the desktop will re-submit the job to the api
// queue
export var JOB_MAX_POLLS = 15;
export var JOB_POLL_DELAY = 1500;
export var TOTAL_REQS_MAX = 1;
export var CACHE_EXPIRATION_TIME = 60 * 60 * 1000; // one hour
export var MAX_UPLOAD_FILE_COUNT = 5;
export var MAX_UPLOAD_FILE_SIZE = 25; // max file size in MB
export var MAX_UPLOAD_TOTAL_FILE_SIZE = 50;
export var LOG_LEVELS = /*@__PURE__*/ (function (LOG_LEVELS) {
    LOG_LEVELS[LOG_LEVELS["DEBUG"] = 0] = "DEBUG";
    LOG_LEVELS[LOG_LEVELS["INFO"] = 1] = "INFO";
    LOG_LEVELS[LOG_LEVELS["ERROR"] = 2] = "ERROR";
    LOG_LEVELS[LOG_LEVELS["EXCEPTION"] = 3] = "EXCEPTION";
    return LOG_LEVELS;
})({});
export var BACKGROUND_CITIES = [
    'Atlanta',
    'Austin',
    'Baltimore',
    'Chicago',
    'Dallas',
    'Denver',
    'Detroit',
    'Honolulu',
    'LasVegas',
    'LosAngeles',
    'Miami',
    'Minneapolis',
    'Montreal',
    'NewOrleans',
    'NewYork',
    'Philadelphia',
    'Pittsburgh',
    'SanFrancisco',
    'Seattle',
    'StLouis',
    'Toronto',
    'Vancouver',
    'WashingtonDC'
];
export var FEATURES = {
    LISTINGS: 'Listings',
    LISTINGS_V2: 'listingsv2',
    DEALS: 'Deals',
    LEASING: 'Leasing',
    NEWS: 'News',
    SPACES: 'Spaces',
    ATTOM_DATA_POINTS: 'attom_data_points'
};
export var API_IMPLEMENTATION_ERRORS = {
    UPSERT: 'upsert is not implemented on the API for this service!',
    DELETE: 'delete is not implemented on the API for this service!',
    RECENT: 'recent is not implemented on the API for this service!',
    COUNT: 'count is not implemented on the API for this service!',
    SEARCH: 'search is not implemented on the API for this service!',
    INFO: 'info is not implemented on the API for this service!',
    BULK_INSERT: 'bulkInsert is not implemented on the API for this service!',
    BULK_DELETE: 'bulkDelete is not implemented on the API for this service!'
};
export var END_POINTS = {
    BUYER_NEEDS: 'api/v3/buyer-needs',
    BULK_INSERT_MEMBERS: 'bulk-insert-members',
    CALL_LISTS: 'api/v3/call-list',
    COMMISSIONS: 'api/commissions',
    COMPANIES: 'api/v3/companies',
    COMPS: 'api/v3/comps',
    CONTACT_GROUP_MEMBERS: 'api/contact-group-members',
    CONTACT_GROUPS: 'api/contact-groups',
    CONTACT_LIST_VIEWS: 'api/v2/contact-list-views',
    CONTACTS: 'api/v3/contacts',
    CONTRACTS: 'api/contracts',
    DEAL_PARTIES: 'api/deal-parties',
    DEALS: 'api/v3/deals',
    DOCUMENTS: 'api/documents',
    FULL_CONTACT: 'api/identity/person',
    IMAGE: 'api/image',
    IMAGES: 'api/images',
    LISTINGS: 'api/v3/listings',
    NEWS: 'api/news',
    OFFERS: 'api/v3/offers',
    OWNERSHIPS: 'api/ownerships',
    PLAYBOOKS: 'api/task-template-collections',
    PREFERENCES: 'api/desktop/preferences',
    PROPERTIES: 'api/v3/properties',
    PROPERTY_FILTER_RANGES: 'api/filterranges/property',
    PROSPECTIVE_BUYERS: 'api/v3/prospective-buyers',
    PURSUITS: 'api/pursuits',
    TASKS: 'api/v3/tasks',
    TYPES: 'api/v3/types',
    URLS: 'api/urls',
    UNIT_MIX: 'api/v3/unit-mix',
    USERS: 'api/users'
};
export var SERVICE_NAMES = {
    BUYER_NEEDS: 'BuyerNeeds',
    CALL_LISTS: 'Call List',
    COMMISSIONS: 'Commissions',
    COMPANIES: 'Companies',
    COMPS: 'Comps',
    CONTACT_GROUP_MEMBERS: 'Contact Group Members',
    CONTACT_GROUPS: 'Contact Groups',
    CONTACT_LIST_VIEWS: 'Contact List Views',
    CONTACTS: 'Contacts',
    CONTRACTS: 'Contracts',
    DEAL_LISTINGS: 'Deals Listings',
    DEAL_PARTIES: 'Deal Parties',
    DEALS: 'Deals',
    DOCUMENTS: 'Documents',
    FULL_CONTACT: 'Full Contact',
    IMAGES: 'Images',
    LISTINGS: 'Listings',
    OFFERS: 'Offers',
    OWNERSHIPS: 'Ownerships',
    PLAYBOOKS: 'Playbooks',
    PREFERENCES: 'Preferences',
    PROPERTIES: 'Properties',
    PROSPECTIVE_BUYERS: 'Prospective Buyers',
    PURSUITS: 'Pursuits',
    RESCOUR: 'Rescour',
    TASKS: 'Tasks',
    TYPES: 'Types',
    UNIT_MIX: 'Unit Mixes',
    URLS: 'Urls',
    USERS: 'Users'
};
export var STATES = [
    { label: '', value: '' },
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' }
];
