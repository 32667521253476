import { AuthService } from '@apto/ionic-lib/services';
var LoginGuard = /** @class */ /*@__PURE__*/ (function () {
    function LoginGuard(authService) {
        this.authService = authService;
    }
    LoginGuard.prototype.canActivate = function () {
        return !this.authService.user;
    };
    return LoginGuard;
}());
export { LoginGuard };
