<div class="contact-item selectable-item"
     [ngClass]="{ clickable: onClick.observers.length > 0 && showContactInfo }">
    <ion-checkbox class="small"
                  *ngIf="selectable"
                  [ngClass]="{ checked: selected }"
                  [ngModel]="selected"
                  (ngModelChange)="itemToggled($event)"
                  color="primary-blue"
                  mode="ios">
    </ion-checkbox>
    <ion-item [attr.detail-push]="onClick.observers.length > 0 ? true : null"
              (click)="onClick.emit(contact)">
        <ion-icon class="grey-color-circle"
                  material-icons
                  name="account_circle"
                  item-left
                  large>
        </ion-icon>

        <div>
            <span class="contact-item--name">
                {{contact.fullName || contact.firstName + ' ' + contact.lastName}}
            </span>
            <span *ngIf="ownership && ownership.isPrimaryContact"
                  class="contact-item--primary-contact">
                Primary Contact
            </span>
            <span *ngIf="ownership"
                  class="contact-item--contact-role">
                {{ownership.contactRole}}
            </span>
        </div>
        <p class="contact-item--title-position">
           <span *ngIf="contact.type">{{contact.type}}</span>
           <span *ngIf="contact.position"> &bull; {{contact.position}}</span>
           <span *ngIf="contacted">{{(contact.type || contact.position) ? " &bull; " : ""}}Contacted</span>
        </p>

        <div *ngIf="showContactInfo"
             class="contact-item--contact-info"
             item-right>
            <div *ngIf="contact.phoneNumbersWithValues && contact.phoneNumbersWithValues.length">
                <span class="hover-link"
                      (click)="call($event, contact)">
                    <ion-icon class="contact-item--contact-info-icon"
                              name="call"></ion-icon>
                    {{contact.phoneNumbersWithValues[0].number}}
                </span>
                <span class="hover-link"
                *ngIf="contact.phoneNumbersWithValues.length > 1"
                      (click)="showPhoneNumbers($event, contact)">
                    <ion-icon material-icons
                              name="arrow_drop_down"></ion-icon>
                </span>
            </div>

            <div *ngIf="contact.emailsWithValues && contact.emailsWithValues.length">
                <span class="hover-link"
                      (click)="email($event, contact)">
                    <ion-icon class="contact-item--contact-info-icon"
                              name="mail"></ion-icon>
                    {{contact.emailsWithValues[0].address}}
                </span>
                <span class="hover-link"
                      *ngIf="contact.emailsWithValues.length > 1"
                      (click)="showEmails($event, contact)">
                    <ion-icon material-icons
                              name="arrow_drop_down"></ion-icon>
                </span>
            </div>
        </div>

        <button *ngFor="let button of buttons"
                ion-button
                clear
                [color]="button.color"
                item-right
                (click)="onButtonClicked.emit({ label: button.label })">
            {{ button.label }}
        </button>
    </ion-item>
</div>
