import * as moment from 'moment';
import { EventEmitter } from '@angular/core';
import { PopoverController } from 'ionic-angular';
import { PropertyModel } from '@apto/models';
import { CompsService } from '@apto/services';
import { NumberWithCommas } from '@apto/ionic-lib/pipes';
import { PreviousLeasesPopover } from '@apto/shared/components/popovers/previous-leases/previous-leases-popover';
import './lease-item.scss';
import { getLeaseDateStatus, getRentalRate } from '@apto/pn/services/leasing-data/lease-expiration-helper';
var numberWithCommasPipe = new NumberWithCommas();
var LeaseItem = /** @class */ /*@__PURE__*/ (function () {
    function LeaseItem(compsService, popoverController) {
        var _this = this;
        this.compsService = compsService;
        this.popoverController = popoverController;
        this.previousLeases = [];
        this.onClick = new EventEmitter();
        this.onMouseout = new EventEmitter();
        this.onMouseover = new EventEmitter();
        this.onPreviousLeasesClick = function (ev) {
            ev.stopPropagation();
            var popover = _this.popoverController.create(PreviousLeasesPopover, {
                previousLeases: _this.previousLeases
            }, {
                cssClass: 'previous-lease-popover-wrapper'
            });
            var subscriptions = [
                _this.compsService.onUpserted.subscribe(function (data) { return popover.dismiss(); }),
                _this.compsService.onDeleted.subscribe(function (data) { return popover.dismiss(); })
            ];
            popover.onDidDismiss(function (data) { return subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); }); });
            popover.present({
                ev: ev,
                updateUrl: false
            });
        };
    }
    Object.defineProperty(LeaseItem.prototype, "leaseMarkerClass", {
        get: function () {
            if (this.lease.comp && this.lease.comp.leaseExpirationDate) {
                if (getLeaseDateStatus(this.lease.comp) === 'danger') {
                    return 'lease-item--marker--lease-danger';
                }
                if (getLeaseDateStatus(this.lease.comp) === 'warning') {
                    return 'lease-item--marker--lease-warning';
                }
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeaseItem.prototype, "leaseExpiration", {
        get: function () {
            if (this.lease.comp && this.lease.comp.leaseExpirationDate) {
                return moment(this.lease.comp.leaseExpirationDate).format('M/D/YYYY');
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeaseItem.prototype, "leaseExpirationClass", {
        get: function () {
            if (this.lease.comp && this.lease.comp.leaseExpirationDate) {
                var cssClass = 'lease-item--expiration-date';
                if (getLeaseDateStatus(this.lease.comp) === 'danger') {
                    cssClass += ' lease-item--danger';
                }
                else if (getLeaseDateStatus(this.lease.comp) === 'warning') {
                    cssClass += ' lease-item--warning';
                }
                return cssClass;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeaseItem.prototype, "rentalRate", {
        get: function () {
            return getRentalRate(this.lease.comp);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeaseItem.prototype, "companyName", {
        get: function () {
            if (this.lease.company && this.lease.company.name) {
                return this.lease.company.name;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeaseItem.prototype, "contactName", {
        get: function () {
            if (this.lease.contact && this.lease.contact.fullName) {
                return this.lease.contact.fullName;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeaseItem.prototype, "squareFootage", {
        get: function () {
            if (this.lease.comp && this.lease.comp.squareFootage) {
                var squareFootage = numberWithCommasPipe.transform(this.lease.comp.squareFootage);
                return squareFootage;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeaseItem.prototype, "suiteNumber", {
        get: function () {
            if (this.lease.comp && this.lease.comp.suiteOrFloor) {
                return this.lease.comp.suiteOrFloor;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LeaseItem.prototype, "isDeal", {
        get: function () {
            if (this.lease.comp && this.lease.comp.recordType) {
                return this.lease.comp.recordType.toLowerCase().includes('external') ? false : true;
            }
            return false;
        },
        enumerable: true,
        configurable: true
    });
    return LeaseItem;
}());
export { LeaseItem };
