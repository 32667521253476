import { Routes } from '@angular/router';
import { CallListsPage, CompaniesPage, ContactGroupsPage, ContactsPage, DashboardPage, LoginPage, PropertiesPage, PropertyMapPage, TasksPage } from '@apto/pn/pages';
import { LoginGuard, SearchResultsPage } from '@apto/shared';
import { DealsPage } from '@apto/pn';
var appRoutes = [
    {
        path: '',
        pathMatch: 'full',
        redirectTo: '/login'
    },
    { component: CallListsPage, path: 'call-lists' },
    { component: DashboardPage, path: 'call-lists/:callListId' },
    { component: DashboardPage, path: 'call-lists/:callListId/:contactId' },
    { component: DashboardPage, path: 'contacts/:contactId' },
    { component: ContactsPage, path: 'contacts' },
    { component: ContactGroupsPage, path: 'contact-groups' },
    { component: LoginPage, path: 'login', canActivate: [LoginGuard] },
    { component: PropertiesPage, path: 'properties' },
    { component: PropertiesPage, path: 'properties/:propertyId' },
    { component: PropertyMapPage, path: 'property-map' },
    { component: PropertyMapPage, path: 'property-map/query/:query' },
    { component: PropertyMapPage, path: 'property-map/:propertyId' },
    { component: TasksPage, path: 'tasks' },
    { component: TasksPage, path: 'tasks/:taskId' },
    { component: DealsPage, path: 'deals' },
    { component: DealsPage, path: 'deals/:dealId' },
    { component: CompaniesPage, path: 'companies' },
    { component: CompaniesPage, path: 'companies/:companyId' },
    { component: SearchResultsPage, path: 'search' }
];
var AppRoutingModule = /** @class */ /*@__PURE__*/ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
