export const FOLLOWUP_DELAY = 1500;
export const HTTP_TIMEOUT = 60000;
export const TOAST_DURATION = 5000;

export const PERCENT_REGEX = /^((([1-9]\d?))|0)(\.\d{1,2})?$/;
export const PERCENT_INT_REGEX = /^(([1-9]\d?)|0)$/;
export const DECIMAL_REGEX = /^([1-9]\d{0,13}|0)(\.\d{1,2})?$/;     // restricts to two decimal places
export const POSTAL_REGEX = /^\d+$/;
export const INTEGER_REGEX = /^[1-9]\d{0,14}$|^0$/;
export const YEAR_REGEX = /^\d{4}$/;
export const PHONE_NUMBER_REGEX = /^([0-9]{1,3})?([-\s\.]{0,1})?(\+[0-9]{1,3})?[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}?( x\d{1,9})?$/; // tslint:disable-line
export const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;  // tslint:disable-line max-line-length
export const LIST_VIEW_MAX_RECORDS = 200;
export const MAX_RECORDS = 100000;

export const validationMessages = {
    PERCENT_REGEX: 'This is a percentage field (0-99) with up to two decimal points.',
    PERCENT_INT_REGEX: 'This is a percentage field (0-99).',
    DECIMAL_REGEX: 'Please enter a number with up to two decimal points.',
    INTEGER_REGEX: 'Please enter a numerical value.',
    YEAR_REGEX: 'Please enter a 4 digit year',
    POSTAL_REGEX: 'Please enter a postal code value.',
    PHONE_NUMBER_REGEX: 'Phone number format is invalid.',
    INTEGER_REGEX_MIN_MAX: 'Please enter a numerical values in MIN and MAX fields.',
    EMAIL_REGEX: 'Email format is invalid.'
}

export const DATEPICKER_OPTIONS = {
    dateFormat: 'mm/dd/yyyy',
    firstDayOfWeek: 'su',
    sunHighlight: false
};
export const DATE_FORMAT = 'YYYY-MM-DD';
