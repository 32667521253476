var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { PopoverController } from 'ionic-angular';
import { CallListModel } from '@apto/models';
import { CallListsService } from '@apto/services';
import { SelectPopover } from '@apto/ionic-lib/components';
import './call-list-card.scss';
var CallListCard = /** @class */ /*@__PURE__*/ (function () {
    function CallListCard(popoverController, callListsService) {
        this.popoverController = popoverController;
        this.callListsService = callListsService;
        this.onEdit = new EventEmitter();
        this.onStartCalling = new EventEmitter();
        this.onViewList = new EventEmitter();
        this.isDealListLoading = false;
    }
    CallListCard.prototype.ngOnChanges = function (changes) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(changes.callList && changes.callList.currentValue && changes.callList.previousValue !== changes.callList.currentValue
                            && changes.callList.currentValue.isDealList && changes.callList.currentValue.members && !this.isDealListLoading))
                            return [3 /*break*/, 2];
                        this.isDealListLoading = true;
                        return [4 /*yield*/, this.callListsService.sortMembersByMarketStatus(changes.callList.currentValue)];
                    case 1:
                        _a.sent();
                        this.isDealListLoading = false;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(CallListCard.prototype, "canStartCalling", {
        get: function () {
            return !this.isLoading && !this.isDealListLoading && !this.callList.isPending
                && (this.callList.isDealList || this.callList.contactsNotCalled() !== 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CallListCard.prototype, "canListView", {
        get: function () {
            return !this.isLoading && !this.callList.isPending
                && (this.callList.isDealList || this.callList.members.length > 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CallListCard.prototype, "contactsNotCalled", {
        get: function () {
            return this.callList.contactsNotCalled();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CallListCard.prototype, "showEditButton", {
        get: function () {
            return !this.isLoading && !this.isDealListLoading && !this.callList.isTaskList && !this.callList.isLegacy
                && !this.callList.isPending;
        },
        enumerable: true,
        configurable: true
    });
    CallListCard.prototype.edit = function () {
        this.onEdit.emit();
    };
    CallListCard.prototype.startCalling = function (ev) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var options_1, popover;
            return __generator(this, function (_a) {
                if (this.canStartCalling && this.callList.isDealList) {
                    options_1 = [];
                    Object.keys(this.callList.statusToMembers)
                        .filter(function (status) { return status !== 'undefined'; })
                        .forEach(function (status) {
                        var members = _this.callList.statusToMembers[status];
                        var count = members.length;
                        options_1.push({
                            value: "" + status,
                            displayHtml: "<span>" + status + " (" + count + ")</span>",
                            disabled: !count
                        });
                    });
                    popover = this.popoverController.create(SelectPopover, { options: options_1 });
                    popover.onDidDismiss(function (marketingStatus) {
                        if (!marketingStatus) {
                            return;
                        }
                        _this.onStartCalling.emit({ ev: ev, callList: _this.callList, marketingStatus: marketingStatus });
                    });
                    popover.present({ ev: ev });
                }
                else {
                    if (this.canStartCalling)
                        this.onStartCalling.emit({ ev: ev, callList: this.callList });
                }
                return [2 /*return*/];
            });
        });
    };
    CallListCard.prototype.viewList = function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        this.onViewList.emit(this.callList);
    };
    return CallListCard;
}());
export { CallListCard };
