<form [formGroup]="spaceForm"
      class="edit-space-form edit-form">
    <ion-card>
        <ion-card-header>Space</ion-card-header>
        <ion-card-content>
            <div data-automation="spaceForm-name">
                <ion-item>
                    <ion-label
                        stacked
                        required>Space name</ion-label>
                    <ion-input
                        formControlName="name"
                        required></ion-input>
                </ion-item>
                <div
                    class="field-error"
                    *ngIf="spaceForm.get('name').dirty && spaceForm.get('name').errors && spaceForm.get('name').errors.required">
                    A space name is required.
                </div>
                <div
                    class="field-error"
                    *ngIf="spaceForm.get('name').dirty && spaceForm.get('name').errors && spaceForm.get('name').errors.maxlength">
                    Space name must be 80 characters or less.
                </div>
            </div>
            <div [hidden]="hasParentProperty()"
                 data-automation="spaceForm-parentProperty">
                <ion-label stacked>
                    Parent Property
                    <add-record linkText="Create new"
                                objectName="Property"
                                (onRecordCreated)="setQuickAddRecord($event, parentPropertyAutoComplete, 'parentProperty', 'parentPropertyId')">
                    </add-record>
                </ion-label>
                <ion-auto-complete #parentProperty
                                   [dataProvider]="propertiesService"
                                   [options]="{ placeholder : 'Find property' }"></ion-auto-complete>
            </div>
            <div *ngIf="hasParentProperty()"
                 data-automation="spaceForm-parentProperty">
                <ion-label stacked>Parent Property</ion-label>
                <p>{{passedInParentProperty.name}}</p>
            </div>
        </ion-card-content>
    </ion-card>
    <ion-card>
        <ion-card-header>Space summary</ion-card-header>
        <ion-card-content>
            <select-map-field [form]="spaceForm"
                          data-automation="spaceForm-spaceStatus"
                          field="spaceStatus"
                          label="Space status"
                          [options]="picklistOptions.spaceStatus"
                          [cssClass]="'edit-form--select-popover'"></select-map-field>
            <select-map-field [form]="spaceForm"
                          data-automation="spaceForm-spaceType"
                          field="spaceType"
                          label="Space type"
                          [options]="picklistOptions.spaceType"
                          [cssClass]="'edit-form--select-popover'"></select-map-field>
            <select-map-field [form]="spaceForm"
                          data-automation="spaceForm-spaceSubType"
                          field="spaceSubType"
                          label="Space sub type"
                          [options]="picklistOptions.spaceSubType"
                          [cssClass]="'edit-form--select-popover'"></select-map-field>
            <div data-automation="spaceForm-floor">
                <ion-item>
                    <ion-label required
                                stacked>Floor</ion-label>
                    <ion-input formControlName="floor"
                                required></ion-input>
                </ion-item>
                <div class="field-error"
                        *ngIf="spaceForm.get('floor').dirty && spaceForm.get('floor').errors && spaceForm.get('floor').errors.required">
                    A floor is required.
                </div>
                <div class="field-error"
                        *ngIf="spaceForm.get('floor').dirty && spaceForm.get('floor').errors && spaceForm.get('floor').errors.maxlength">
                    Floor must be 25 characters or less.
                </div>
            </div>
            <div data-automation="spaceForm-suiteFloor">
                <ion-item>
                    <ion-label stacked>Suite number</ion-label>
                    <ion-input formControlName="suiteFloor"></ion-input>
                </ion-item>
                <div class="field-error"
                     *ngIf="spaceForm.get('suiteFloor').dirty && spaceForm.get('suiteFloor').errors && spaceForm.get('suiteFloor').errors.maxlength">
                    Suite number must be 20 characters or less.
                </div>
            </div>
            <div data-automation="spaceForm-squareFootage">
                <ion-item>
                    <ion-label stacked>Square footage</ion-label>
                    <ion-input formControlName="squareFootage"></ion-input>
                </ion-item>
                <div class="field-error"
                    *ngIf="spaceForm.get('squareFootage').dirty && spaceForm.get('squareFootage').errors && spaceForm.get('squareFootage').errors.maxlength">
                    Square footage must be 18 characters or less.
                </div>
                <div class="field-error"
                    *ngIf="spaceForm.get('squareFootage').dirty &&
                                            spaceForm.get('squareFootage').errors &&
                                            spaceForm.get('squareFootage').errors.pattern">
                    Please enter a numerical value.
                </div>
            </div>
            <div data-automation="spaceForm-description">
                <ion-item>
                    <ion-label stacked>Description</ion-label>
                    <ion-input formControlName="description"></ion-input>
                </ion-item>
                <div class="field-error"
                        *ngIf="spaceForm.get('description').dirty && spaceForm.get('description').errors && spaceForm.get('description').errors.maxlength">
                    Description must be 32000 characters or less.
                </div>
            </div>
        </ion-card-content>
    </ion-card>

    <busy-panel *ngIf="isSaving"
                message="Saving space..."></busy-panel>
</form>