import { EventEmitter } from '@angular/core';
var BaseFilter = /** @class */ /*@__PURE__*/ (function () {
    function BaseFilter() {
        this.onQueryChange = new EventEmitter();
    }
    BaseFilter.prototype.init = function (options) {
        var _this = this;
        Object.keys(options).forEach(function (key) {
            _this[key] = options[key];
        });
    };
    return BaseFilter;
}());
export { BaseFilter };
