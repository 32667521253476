<div class="contact-group-item selectable-item"
     [ngClass]="{ clickable: onClick.observers.length > 0 }">
    <ion-checkbox class="small"
                  *ngIf="selectable"
                  [ngClass]="{ checked: selected }"
                  [ngModel]="selected"
                  (ngModelChange)="itemToggled($event)"
                  color="primary-blue"
                  mode="ios">
    </ion-checkbox>
    <ion-item [attr.detail-push]="onClick.observers.length > 0 ? true : null"
              (click)="onClick.emit(contactGroup)">
        <ion-icon class="grey-color-circle"
                  material-icons
                  name="account_circle"
                  item-left
                  large></ion-icon>
        <h2>
            <span class="contacts-groups--name">{{ contactGroup.name }}</span>
            <span *ngIf="isListView(contactGroup)"
                  class="contacts-groups--view-label">
                View
            </span>
        </h2>
        <p class="contacts-groups--info-panel">
            <span class="contacts-groups--info-panel-member-count">
                <ion-icon name="person"
                          class="contact-groups-icon"></ion-icon>
                <span *ngIf="contactGroup.numberOfMembers >= 0; else numberOfMembersShimmer"
                      class="contacts--groups-contacts-info">{{ contactGroup.numberOfMembers }} Contacts</span>
                <ng-template #numberOfMembersShimmer>
                    <span class="placeholder-shimmer contacts-groups--member-count-shimmer"></span>
                </ng-template>
            </span>
            <span>
                <ion-icon name="calendar"
                          class="contact-groups-icon"></ion-icon>
                <span *ngIf="contactGroup.createdDate; else createdDateShimmer"
                      class="contacts--groups-created-date-info">Created on {{ contactGroup.createdDate | dateFormat }}</span>
                <ng-template #createdDateShimmer>
                    <span class="placeholder-shimmer contacts-groups--created-date-shimmer"></span>
                </ng-template>
            </span>
        </p>
    </ion-item>
</div>