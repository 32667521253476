import './error-panel.scss';
var ErrorPanel = /** @class */ /*@__PURE__*/ (function () {
    function ErrorPanel() {
    }
    ErrorPanel.prototype.refresh = function () {
        location.reload();
    };
    return ErrorPanel;
}());
export { ErrorPanel };
