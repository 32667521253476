import { SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BuyerNeedsModel } from '@apto/models';
import { DECIMAL_REGEX, INTEGER_REGEX, PERCENT_REGEX, validationMessages } from '@apto/shared/constants';
import './edit-buyer-need-form.scss';
var EditBuyerNeedForm = /** @class */ /*@__PURE__*/ (function () {
    function EditBuyerNeedForm(formBuilder) {
        this.formBuilder = formBuilder;
        this.acquisitionTypes = [];
        this.buildingTypes = [];
        this.BuyerNeedsModel = BuyerNeedsModel;
        this.loadingMessage = null;
        this.validationMessages = validationMessages;
    }
    EditBuyerNeedForm.prototype.ngOnInit = function () {
        this.setupForm();
    };
    EditBuyerNeedForm.prototype.ngOnChanges = function (changes) {
        if (changes.model &&
            changes.model.previousValue &&
            changes.model.previousValue._id !== changes.model.currentValue._id) {
            this.setupForm();
        }
    };
    Object.defineProperty(EditBuyerNeedForm.prototype, "buyerQualities", {
        get: function () {
            return [''].concat(BuyerNeedsModel.buyerQualities);
        },
        enumerable: true,
        configurable: true
    });
    EditBuyerNeedForm.prototype.setupForm = function () {
        var _this = this;
        var validateWithPattern = function (keyField, reg) {
            return [_this.buyerNeed[keyField], Validators.pattern(reg)];
        };
        this.form = this.formBuilder.group({
            buildingType: [this.buyerNeed.buildingType],
            buyerQuality: [this.buyerNeed.buyerQuality],
            isActive: [this.buyerNeed.isActive || true],
            maxPrice: validateWithPattern('maxPrice', INTEGER_REGEX),
            maxSquareFootage: validateWithPattern('maxSquareFootage', DECIMAL_REGEX),
            minCapRate: validateWithPattern('minCapRate', INTEGER_REGEX),
            minCashOnCash: validateWithPattern('minCashOnCash', PERCENT_REGEX),
            minIrr: validateWithPattern('minIrr', PERCENT_REGEX),
            minLirr: validateWithPattern('minLirr', PERCENT_REGEX),
            minPrice: validateWithPattern('minPrice', INTEGER_REGEX),
            minSquareFootage: validateWithPattern('minSquareFootage', DECIMAL_REGEX),
            name: [this.buyerNeed.name, Validators.required]
        });
        if (this.buyerNeed.acquisitionType) {
            this.acquisitionTypes = this.buyerNeed.acquisitionType.split(';');
        }
        if (this.buyerNeed.buildingType) {
            this.buildingTypes = this.buyerNeed.buildingType.split(';');
        }
    };
    EditBuyerNeedForm.prototype.save = function () {
        var _this = this;
        if (this.form.valid) {
            Object.assign(this.buyerNeed, this.form.value);
            this.buyerNeed.acquisitionType = this.acquisitionTypes.join(';');
            this.buyerNeed.buildingType = this.buildingTypes.join(';');
            return { buyerNeed: this.buyerNeed };
        }
        else {
            Object.keys(this.form.controls).forEach(function (fieldKey) {
                _this.form.controls[fieldKey].markAsDirty();
            });
        }
    };
    return EditBuyerNeedForm;
}());
export { EditBuyerNeedForm };
