import { EventEmitter } from '@angular/core';
import { AlertController, PopoverController } from 'ionic-angular';
import { PlaybookModel } from '@apto/models';
import { PlaybooksService } from '@apto/services';
import { SelectPopover } from '@apto/ionic-lib/components';
import './playbook-selector.scss';
var PlaybookSelector = /** @class */ /*@__PURE__*/ (function () {
    function PlaybookSelector(alertController, playbookService, popoverController) {
        this.alertController = alertController;
        this.playbookService = playbookService;
        this.popoverController = popoverController;
        this.onSelectOption = new EventEmitter();
    }
    PlaybookSelector.prototype.change = function (ev) {
        var _this = this;
        var newListText = 'BUILD A NEW PLAYBOOK';
        this.displayOptions = this.buildDisplayOptions();
        this.displayOptions.push({
            value: newListText
        });
        this.popover = this.popoverController.create(SelectPopover, {
            options: this.displayOptions
        }, { cssClass: 'color-last-option no-lines wide' });
        this.popover.onDidDismiss(function (value) {
            if (value) {
                if (value === newListText) {
                    _this.getName();
                }
                else {
                    _this.value = value;
                    _this.onSelectOption.emit({ value: value });
                }
            }
        });
        this.popover.present({ ev: ev });
    };
    PlaybookSelector.prototype.buildDisplayOptions = function () {
        return this.options.map(function (playbook) {
            return {
                value: playbook.name,
                displayHtml: "<span class=\"dark-text\">" + playbook.name + "</span>\n                (" + playbook.taskTemplates.length + " tasks)"
            };
        });
    };
    PlaybookSelector.prototype.getName = function () {
        var _this = this;
        var getNameAlert = this.alertController.create({
            title: 'Playbook name',
            message: '',
            inputs: [{
                    name: 'playbookName',
                    placeholder: 'Enter a name for the new playbook'
                }],
            buttons: [{
                    text: 'Save',
                    handler: function (data) {
                        if (!data.playbookName) {
                            getNameAlert.setMessage('A valid name is required.');
                            return false;
                        }
                        _this.playbookService.fetch({
                            where: {
                                name: data.playbookName,
                                targetStage: _this.targetStage
                            }
                        }).then(function (res) {
                            if (res.length > 0) {
                                getNameAlert.setMessage('Name is already taken.');
                                return;
                            }
                            var newPlaybook = new PlaybookModel({
                                name: data.playbookName,
                                taskTemplates: [],
                                targetStage: _this.targetStage
                            });
                            _this.options.push(newPlaybook);
                            _this.displayOptions = _this.buildDisplayOptions();
                            _this.value = newPlaybook.name;
                            _this.onSelectOption.emit({ value: newPlaybook.name });
                            getNameAlert.dismiss();
                        });
                        return false;
                    }
                }],
            cssClass: 'task-template-name-alert'
        });
        getNameAlert.present();
    };
    return PlaybookSelector;
}());
export { PlaybookSelector };
