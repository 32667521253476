export function countNonEmptyKeysAndSubKeys(obj: any) {
    let count = 0;
    for (const key in obj) {
        if (obj[key]) {
            if (isObject(obj[key])) {
                for (const subkey in obj[key]) {
                    if (obj[key][subkey]) {
                        count++;
                    }
                }
            } else {
                count++;
            }
        }
    }

    return count;
}

export function isObject(obj: any) {
    return obj !== null &&
        typeof obj === 'object' &&
        !Array.isArray(obj);
}

export function isEmpty(obj: any) {
    if (!isObject(obj)) {
        throw new Error(`Cannot check isEmpty on invalid object: "${obj}"`);
    }
    return Object.getOwnPropertyNames(obj).length === 0;
}
