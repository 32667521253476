import { __extends } from "tslib";
import { Injector } from '@angular/core';
import { TypesModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
import { AlertController } from 'ionic-angular';
var TypesService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(TypesService, _super);
    function TypesService(injector, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.alertController = alertController;
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.TYPES;
        _this.model = TypesModel;
        _this.serviceName = SERVICE_NAMES.TYPES;
        return _this;
    }
    TypesService.prototype.fetch = function (params) {
        var _this = this;
        var cachedTypes = this.cacheService.getAll(this.serviceName);
        if (cachedTypes.length) {
            return Promise.resolve(cachedTypes[0]);
        }
        return _super.prototype.fetch.call(this, params)
            .then(function (results) {
            // there should only be a single result for a types, so return that instead of an array.
            // cache using 'types' as the key since the TypesModel doesn't have an _id field to cache on.
            _this.cacheService.add(_this.serviceName, 'types', results[0]);
            return results[0];
        });
    };
    return TypesService;
}(BaseService));
export { TypesService };
