<div class="add-call-list-details">
    <ion-card-header>Add call list details</ion-card-header>
    <ion-card-content>
        <ion-grid>
            <ion-row>
                <ion-col col-8>
                    <form *ngIf="callListForm"
                          class="edit-form"
                          [formGroup]="callListForm"
                          novalidate>
                        <ion-item>
                            <ion-label stacked required>Contact list name</ion-label>
                            <ion-input formControlName="name"></ion-input>
                        </ion-item>
                        <div class="field-error" *ngIf="callListForm.controls['name'].dirty && callListForm.controls['name'].invalid">
                            <span *ngIf="callListForm.controls['name'].errors.required">A name is required.</span>
                            <span *ngIf="callListForm.controls['name'].errors.duplicate">A call list already exists with that name.</span>
                        </div>

                        <ion-item>
                            <ion-label stacked required>Contact list description</ion-label>
                            <ion-input formControlName="description"></ion-input>
                        </ion-item>
                        <div class="field-error" *ngIf="callListForm.controls['description'].dirty && callListForm.controls['description'].invalid">
                            A description is required.
                        </div>

                        <div class="contact-frequency-item">
                            <ion-label required>Contact Frequency</ion-label>
                            <p class="contact-frequency-description">Add the details for your call list and then set how often you want to be reminded to contact them.
                            Only the people that haven't been contacted in that timeframe will show up on the list.</p>
                            <ion-segment mode="ios"
                                         color="primary-blue"
                                         formControlName="frequency">
                                <ion-segment-button value="30">30 days</ion-segment-button>
                                <ion-segment-button value="45">45 days</ion-segment-button>
                                <ion-segment-button value="60">60 days</ion-segment-button>
                                <ion-segment-button value="90">90 days</ion-segment-button>
                                <ion-segment-button value="120">120 days</ion-segment-button>
                            </ion-segment>
                        </div>
                        <div class="field-error-contact-frequency" *ngIf="callListForm.controls['frequency'].dirty && callListForm.controls['frequency'].invalid">
                            A contact frequency is required.
                        </div>
                    </form>
                </ion-col>
                <ion-col col-4>
                    <call-list-statistics-card [groups]="groups"></call-list-statistics-card>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
</div>
