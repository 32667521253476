import { __extends } from "tslib";
import { EventEmitter } from '@angular/core';
import { PopoverController } from 'ionic-angular';
import { ContactModel, OwnershipModel } from '@apto/models';
import { EmailPopover, PhonePopover } from '../../components';
import { SelectableItem } from '../selectable-item/selectable-item';
import './contact-item.scss';
var ContactItem = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ContactItem, _super);
    function ContactItem(popoverController) {
        var _this = _super.call(this) || this;
        _this.popoverController = popoverController;
        _this.buttons = [];
        _this.showContactInfo = true;
        _this.onButtonClicked = new EventEmitter();
        _this.onCall = new EventEmitter();
        _this.onClick = new EventEmitter();
        _this.onEmail = new EventEmitter();
        return _this;
    }
    ContactItem.prototype.showEmails = function (ev, contact) {
        ev.stopPropagation();
        var popover = this.popoverController.create(EmailPopover, {
            emails: contact.emailsWithValues
        });
        popover.present({ ev: ev });
    };
    ContactItem.prototype.showPhoneNumbers = function (ev, contact) {
        ev.stopPropagation();
        var popover = this.popoverController.create(PhonePopover, {
            phoneNumbers: contact.phoneNumbersWithValues
        });
        popover.present({ ev: ev });
    };
    ContactItem.prototype.call = function (event, contact) {
        event.stopPropagation();
        this.onCall.emit(contact.phoneNumbersWithValues[0]);
    };
    ContactItem.prototype.email = function (event, contact) {
        event.stopPropagation();
        this.onEmail.emit(contact.emailsWithValues[0]);
    };
    return ContactItem;
}(SelectableItem));
export { ContactItem };
