<ion-header class="modal-header call-list-setup-modal--header">
    <ion-navbar color="white">
        <ion-title>
            Build A Call List
        </ion-title>
        <ion-buttons end>
            <button *ngIf="isEditMode"
                    ion-button
                    clear
                    color="danger"
                    hold-button
                    (hold)="delete()"
                    data-automation="modal-delete-button">
                Hold to Delete
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismiss();"
                    data-automation="modal-dismiss-button">
                <apto-icon icon="close" inline size="4"></apto-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal call-list-modal-content">
    <ion-slides #slides>
        <ion-slide>
            <ion-content>
                <ion-card>
                    <call-list-group-selector #callListGroupSelector
                                              [urlsPromise]="urlsPromise"
                                              [contactGroupsPromise]="contactGroupsPromise"
                                              [contactListViewsPromise]="contactListViewsPromise"
                                              [callList]="callList">
                    </call-list-group-selector>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide>
            <ion-content>
                <ion-card>
                    <add-call-list-details #addCallListDetails
                                           [groups]="selectedGroups"
                                           [callList]="callList"
                                           [callLists]="callLists">
                    </add-call-list-details>
                </ion-card>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>

<ion-footer class="modal-footer">
    <ion-navbar>
        <ion-buttons start>
            <button clear
                    color="danger"
                    ion-button
                    (click)="dismiss()"
                    data-automation="modalCancelButton">
                CANCEL
            </button>
        </ion-buttons>

        <ion-buttons end>
            <button ion-button
                    color="primary-blue"
                    *ngIf="slides.getActiveIndex() === 0"
                    (click)="next()"
                    data-automation="modalNextButton">
                NEXT
            </button>
            <div *ngIf="slides.getActiveIndex() > 0">
                <button clear
                        color="primary-blue"
                        ion-button
                        (click)="back()"
                        data-automation="modalBackButton">
                    BACK
                </button>
                <button class="white-text-button"
                        color="primary-blue"
                        ion-button
                        (click)="save()"
                        data-automation="modal-save-button">
                    SAVE CALL LIST
                </button>
            </div>
        </ion-buttons>
    </ion-navbar>
</ion-footer>
