<form [formGroup]="contactForm"
      novalidate
      class="edit-contact-form edit-form">
    <ion-card>
        <ion-card-header>Contact overview</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-label stacked
                           required>First name</ion-label>
                <ion-input formControlName="firstName"
                           required></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="contactForm.get('firstName').dirty &&
                        contactForm.get('firstName').errors &&
                        contactForm.get('firstName').errors['required']">
                A first name is required.
            </div>

            <ion-item>
                <ion-label stacked required>Last name</ion-label>
                <ion-input formControlName="lastName" required></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="contactForm.get('lastName').dirty &&
                        contactForm.get('lastName').errors &&
                        contactForm.get('lastName').errors['required']">
                A last name is required.
            </div>

            <select-field [form]="contactForm"
                          field="type"
                          label="Type"
                          [options]="types"
                          [required]="true"
                          [cssClass]="'edit-form--select-popover'"></select-field>

            <ion-label stacked>Company
                <add-record
                        linkText="Create new"
                        objectName="Company"
                        (onRecordCreated)="selectCompany($event);">
                </add-record>
            </ion-label>
            <ion-auto-complete [dataProvider]="companiesService"
                               [options]="{ placeholder : 'Find company' }"
                               (itemSelected)="selectCompany($event);"></ion-auto-complete>

            <ion-item>
                <ion-label stacked>Title</ion-label>
                <ion-input formControlName="title"></ion-input>
            </ion-item>

            <ion-item formGroupName="phone">
                <ion-label stacked>Phone</ion-label>
                <ion-input formControlName="number"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="contactForm.get('phone').get('number').dirty &&
                        contactForm.get('phone').get('number').errors &&
                        contactForm.get('phone').get('number').errors.pattern">
                {{validationMessages.PHONE_NUMBER_REGEX}}
            </div>

            <ion-item formGroupName="mobilePhone">
                <ion-label stacked>Mobile phone</ion-label>
                <ion-input formControlName="number"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="contactForm.get('mobilePhone').get('number').dirty &&
                        contactForm.get('mobilePhone').get('number').errors &&
                        contactForm.get('mobilePhone').get('number').errors.pattern">
                {{validationMessages.PHONE_NUMBER_REGEX}}
            </div>

            <ion-item formGroupName="homePhone">
                <ion-label stacked>Home phone</ion-label>
                <ion-input formControlName="number"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="contactForm.get('homePhone').get('number').dirty &&
                        contactForm.get('homePhone').get('number').errors &&
                        contactForm.get('homePhone').get('number').errors.pattern">
                {{validationMessages.PHONE_NUMBER_REGEX}}
            </div>

            <ion-item formGroupName="otherPhone">
                <ion-label stacked>Other phone</ion-label>
                <ion-input formControlName="number"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="contactForm.get('otherPhone').get('number').dirty &&
                        contactForm.get('otherPhone').get('number').errors &&
                        contactForm.get('otherPhone').get('number').errors.pattern">
                {{validationMessages.PHONE_NUMBER_REGEX}}
            </div>

            <ion-item formGroupName="fax">
                <ion-label stacked>Fax</ion-label>
                <ion-input formControlName="number"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="contactForm.get('fax').get('number').dirty &&
                        contactForm.get('fax').get('number').errors &&
                        contactForm.get('fax').get('number').errors.pattern">
                {{validationMessages.PHONE_NUMBER_REGEX}}
            </div>

            <ion-item formGroupName="email">
                <ion-label stacked>Email</ion-label>
                <ion-input formControlName="address"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="contactForm.get('email').get('address').dirty &&
                    contactForm.get('email').get('address').errors &&
                    contactForm.get('email').get('address').errors.pattern">
                {{validationMessages.EMAIL_REGEX}}
            </div>

            <ion-item formGroupName="email2">
                <ion-label stacked>Other email</ion-label>
                <ion-input formControlName="address"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="contactForm.get('email2').get('address').dirty &&
                    contactForm.get('email2').get('address').errors &&
                    contactForm.get('email2').get('address').errors.pattern">
                {{validationMessages.EMAIL_REGEX}}
            </div>

            <ion-item>
                <ion-label stacked>Description</ion-label>
                <ion-textarea textarea-elastic
                            class="elastic-text"
                            formControlName="description"></ion-textarea>
            </ion-item>
        </ion-card-content>
    </ion-card>

    <ion-card>
        <ion-card-header>Address information</ion-card-header>
        <ion-card-content>
            <ion-item-group formGroupName="address">
                <ion-item>
                    <ion-label stacked>Street address</ion-label>
                    <ion-input formControlName="street"></ion-input>
                </ion-item>

                <ion-item>
                    <ion-label stacked>City</ion-label>
                    <ion-input formControlName="city"></ion-input>
                </ion-item>

                <state-select [parentForm]="contactForm"
                              [groupName]="'address'"
                              [cssClass]="'edit-form--select-popover'"></state-select>

                <ion-item>
                    <ion-label stacked>Country</ion-label>
                    <ion-input formControlName="country"></ion-input>
                </ion-item>

                <ion-item>
                    <ion-label stacked>Zip/Postal code</ion-label>
                    <ion-input formControlName="postalCode"></ion-input>
                </ion-item>
                <div class="field-error"
                *ngIf="contactForm.get('address').get('postalCode').dirty &&
                    contactForm.get('address').get('postalCode').errors &&
                    contactForm.get('address').get('postalCode').errors.pattern">
                {{validationMessages.POSTAL_REGEX}}
            </div>
            </ion-item-group>
        </ion-card-content>
    </ion-card>

    <busy-panel *ngIf="loadingMessage"
                [message]="loadingMessage"></busy-panel>
</form>
