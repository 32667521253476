import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ModalController, NavParams, ToastController, ViewController } from 'ionic-angular';
import { CompModel, CompanyModel, PropertyModel, TaskModel, LEASED_SPACE_STATUS } from '@apto/models';
import { AnalyticsService, AppService, CompsService, CompaniesService, ContactsService, DealPartiesService, TasksService, TypesService, PropertiesService } from '@apto/services';
import { PropertyDetailsModal, PropertyDetailsModalMode } from '@apto/pn/modals';
import { PropertyOwnershipsCard } from '@apto/ionic-lib/cards';
import { NumberWithCommas, PricePerSqFtFormat } from '@apto/ionic-lib/pipes';
import { TOAST_DURATION } from '@apto/shared/constants';
import { BaseSlideModal, TasksModal, TasksModalMode, } from '@apto/shared/modals';
import { ActivityHistoryModal, ActivityHistoryModalMode, EditClosedDealForm } from '@apto/pn';
import { CompanyDetailsModal, CompanyDetailsModalMode } from '@apto/pn/modals/company-details-modal/company-details-modal';
export var LeaseModalMode = /*@__PURE__*/ (function (LeaseModalMode) {
    LeaseModalMode[LeaseModalMode["LIST"] = 0] = "LIST";
    LeaseModalMode[LeaseModalMode["VIEW"] = 1] = "VIEW";
    LeaseModalMode[LeaseModalMode["EDIT"] = 2] = "EDIT";
    return LeaseModalMode;
})({});
import './lease-modal.scss';
import { Router } from '@angular/router';
var LeaseModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(LeaseModal, _super);
    function LeaseModal(analyticsService, appService, compsService, companiesService, contactsService, dealPartiesService, modalController, navParams, tasksService, toastController, typesService, viewController, propertiesService, router) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.analyticsService = analyticsService;
        _this.appService = appService;
        _this.compsService = compsService;
        _this.companiesService = companiesService;
        _this.contactsService = contactsService;
        _this.dealPartiesService = dealPartiesService;
        _this.modalController = modalController;
        _this.tasksService = tasksService;
        _this.toastController = toastController;
        _this.typesService = typesService;
        _this.propertiesService = propertiesService;
        _this.router = router;
        _this.dealParties = [];
        _this.isInitializingDealParties = false;
        _this.isInitializingTasks = false;
        _this.isLoadingRelationships = true;
        _this.modalUrls = new Map([
            [LeaseModalMode.VIEW, '/:propertyId']
        ]);
        _this.mode = LeaseModalMode.LIST;
        _this.Modes = LeaseModalMode;
        _this.records = [];
        _this.rentalRateTypeMap = {
            '$/SF/Year': 'sf/yr',
            '$/SF/Month': 'sf/mo',
            'Amount/Year': 'amt/yr',
            'Amount/Month': 'amt/mo'
        };
        _this.selectedRecord = {
            comp: new CompModel(),
            company: new CompanyModel(),
            property: new PropertyModel(),
            space: new PropertyModel(),
            previousLeases: []
        };
        _this.shouldDisplayLeaseDetails = false;
        _this.tasks = [];
        _this.subscriptions = [];
        _this.mode = _this.navParams.get('mode') || _this.mode;
        _this.records = _this.navParams.get('records') || _this.records;
        _this.selectedRecord = _this.navParams.get('selectedRecord') || _this.selectedRecord;
        return _this;
    }
    LeaseModal.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.tasksService.onUpserted.subscribe(function (data) {
            if (data.isNew) {
                _this.addTask(data.payload);
            }
            else {
                _this.updateTask(data.payload);
            }
        }));
        this.subscriptions.push(this.compsService.onUpserted.subscribe(function (data) {
            _this.isInitializingDealParties = true;
            _this.loadDealParties();
        }));
        this.loadData();
    };
    LeaseModal.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    LeaseModal.prototype.ionViewWillEnter = function () {
        _super.prototype.ionViewWillEnter.call(this);
        if (this.mode === this.Modes.VIEW) {
            this.view(this.selectedRecord);
        }
    };
    LeaseModal.prototype.closeLeaseDetails = function () {
        this.shouldDisplayLeaseDetails = false;
    };
    LeaseModal.prototype.loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                Promise.all([
                    this.setCompany(),
                    this.setContact(),
                    this.setSpace(),
                    this.setProperty()
                ]).then(function () {
                    _this.isLoadingRelationships = false;
                });
                this.loadTasks();
                this.loadDealParties();
                return [2 /*return*/];
            });
        });
    };
    LeaseModal.prototype.onEditClick = function () {
        this.setMode(this.Modes.EDIT);
        this.analyticsService.logEvent('Leasing Clicked Edit Lease');
    };
    Object.defineProperty(LeaseModal.prototype, "priceAndSqft", {
        get: function () {
            var comp = this.selectedRecord.comp;
            var items = [];
            if (comp.leaseTotal) {
                var price = new NumberWithCommas().transform(comp.leaseTotal);
                items.push("<strong>$" + price + "</strong>");
            }
            if (comp.squareFootage) {
                var sqft = new NumberWithCommas().transform(comp.squareFootage);
                items.push(sqft + " sqft");
            }
            if (comp.leaseTotal && comp.squareFootage) {
                var pricePerSqft = (comp.leaseTotal / comp.squareFootage).toFixed(2).toString();
                var str = new PricePerSqFtFormat().transform(pricePerSqft);
                items.push(str);
            }
            return items.join(' &bull; ');
        },
        enumerable: true,
        configurable: true
    });
    LeaseModal.prototype.save = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var formResult, savedComp, spaceProp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formResult = this.editClosedDealForm.save();
                        if (!formResult || !formResult.closedDeal) {
                            return [2 /*return*/];
                        }
                        this.scrollToTop();
                        this.editClosedDealForm.isSaving = true;
                        return [4 /*yield*/, this.compsService.upsert(formResult.closedDeal)];
                    case 1:
                        savedComp = _a.sent();
                        spaceProp = savedComp.spaceProperty || null;
                        if (!spaceProp && this.selectedRecord.space && this.selectedRecord.space._id) {
                            spaceProp = this.selectedRecord.space;
                        }
                        return [4 /*yield*/, this.setLeasedPropertyStatus(spaceProp || null)];
                    case 2:
                        _a.sent();
                        this.editClosedDealForm.isSaving = false;
                        if (this.initialMode === this.Modes.EDIT) {
                            this.dismiss();
                        }
                        else {
                            this.selectedRecord.comp = savedComp;
                            this.view(this.selectedRecord);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(LeaseModal.prototype, "shouldShowBackArrow", {
        get: function () {
            var shouldShow = false;
            if (this.initialMode !== this.Modes.EDIT) {
                if (this.records.length && this.mode === this.Modes.VIEW) {
                    shouldShow = true;
                }
                else if (this.selectedRecord.comp._id && this.mode === this.Modes.EDIT) {
                    shouldShow = true;
                }
            }
            return shouldShow;
        },
        enumerable: true,
        configurable: true
    });
    LeaseModal.prototype.showAddActivity = function () {
        var activitiesModal = this.modalController.create(ActivityHistoryModal, {
            mode: ActivityHistoryModalMode.EDIT,
            selectedActivity: new TaskModel({
                dueDate: new Date().toISOString(),
                isComplete: true,
                whatId: this.selectedRecord.comp._id,
                whatName: this.selectedRecord.comp.name
            })
        });
        return activitiesModal.present({ updateUrl: false });
    };
    LeaseModal.prototype.showAddTask = function () {
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.EDIT,
            selectedTask: new TaskModel({
                whatId: this.selectedRecord.comp._id,
                whatName: this.selectedRecord.comp.name
            })
        });
        return tasksModal.present({ updateUrl: false });
    };
    LeaseModal.prototype.showLeaseDetails = function () {
        this.shouldDisplayLeaseDetails = true;
    };
    LeaseModal.prototype.toggleTask = function (task) {
        return __awaiter(this, void 0, void 0, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        task.isComplete = !task.isComplete;
                        this.showToast(task);
                        if (task.isComplete) {
                            this.analyticsService.logEvent('Deals Modal - Task Completed');
                        }
                        TaskModel.sortActivities(this.tasks);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.tasksService.upsert(task)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        task.isComplete = false;
                        return [3 /*break*/, 4];
                    case 4:
                        TaskModel.sortActivities(this.tasks);
                        return [2 /*return*/];
                }
            });
        });
    };
    LeaseModal.prototype.viewActivity = function (activity) {
        var activityHistoryModal = this.modalController.create(ActivityHistoryModal, {
            mode: ActivityHistoryModalMode.VIEW,
            selectedActivity: activity
        });
        activityHistoryModal.present({ updateUrl: false });
    };
    LeaseModal.prototype.viewAllActivities = function () {
        var activityHistoryModal = this.modalController.create(ActivityHistoryModal, {
            activities: this.tasks,
            mode: ActivityHistoryModalMode.LIST
        });
        activityHistoryModal.present({ updateUrl: false });
    };
    LeaseModal.prototype.viewAllTasks = function () {
        var tasksModal = this.modalController.create(TasksModal, {
            tasks: this.tasks,
            mode: TasksModalMode.LIST
        });
        tasksModal.present({ updateUrl: false });
    };
    LeaseModal.prototype.viewTask = function (activity) {
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.VIEW,
            selectedTask: activity
        });
        tasksModal.present({ updateUrl: false });
    };
    LeaseModal.prototype.viewProperty = function () {
        return __awaiter(this, void 0, void 0, function () {
            var propertyDetailsModal, _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _b = (_a = this.modalController).create;
                        _c = [PropertyDetailsModal];
                        _d = {
                            selectedProperty: this.selectedRecord.property,
                            mode: PropertyDetailsModalMode.VIEW
                        };
                        return [4 /*yield*/, this.getPropertyTypes()];
                    case 1:
                        propertyDetailsModal = _b.apply(_a, _c.concat([(_d.types = _e.sent(),
                                _d), {
                                cssClass: 'large-modal'
                            }]));
                        propertyDetailsModal.present({ updateUrl: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    LeaseModal.prototype.openContactModal = function (contact) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dismiss({ dismissAll: true })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.router.navigate(['/contacts', contact._id])];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LeaseModal.prototype.openCompanyModal = function (company) {
        return __awaiter(this, void 0, void 0, function () {
            var modal;
            return __generator(this, function (_a) {
                modal = this.modalController.create(CompanyDetailsModal, {
                    mode: CompanyDetailsModalMode.VIEW,
                    selectedCompany: company
                });
                return [2 /*return*/, modal.present({
                        updateUrl: false
                    })];
            });
        });
    };
    LeaseModal.prototype.setLeasedPropertyStatus = function (space, status) {
        if (status === void 0) {
            status = LEASED_SPACE_STATUS;
        }
        if (space && (!space.spaceStatus || (space.spaceStatus && space.spaceStatus !== LEASED_SPACE_STATUS))) {
            space.spaceStatus = LEASED_SPACE_STATUS;
            return this.propertiesService.upsert(space);
        }
        return Promise.resolve();
    };
    LeaseModal.prototype.getPropertyTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var types;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        types = _a.sent();
                        return [2 /*return*/, Object.keys(types.propertyTypes)];
                }
            });
        });
    };
    LeaseModal.prototype.addTask = function (task) {
        if (this.tasks.find(function (t) { return t._id === task._id; })) {
            return;
        }
        this.tasks = this.tasks.concat(new TaskModel(task));
        TaskModel.sortActivities(this.tasks);
    };
    LeaseModal.prototype.loadDealParties = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isInitializingDealParties = true;
                        _a = this;
                        return [4 /*yield*/, this.dealPartiesService.fetch({ where: { compId: this.selectedRecord.comp._id } })];
                    case 1:
                        _a.dealParties = _b.sent();
                        this.isInitializingDealParties = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    LeaseModal.prototype.loadTasks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isInitializingTasks = true;
                        _a = this;
                        return [4 /*yield*/, this.tasksService.byRelatedId(this.selectedRecord.comp._id)];
                    case 1:
                        _a.tasks = _b.sent();
                        this.isInitializingTasks = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    LeaseModal.prototype.setCompany = function () {
        return __awaiter(this, void 0, void 0, function () {
            var companies;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.selectedRecord.comp.clientCompany && this.selectedRecord.comp.clientCompanyId))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.companiesService.in([this.selectedRecord.comp.clientCompanyId])];
                    case 1:
                        companies = _a.sent();
                        this.selectedRecord.comp.clientCompany = companies.length && companies[0] || null;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    LeaseModal.prototype.setSpace = function () {
        return __awaiter(this, void 0, void 0, function () {
            var properties;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.selectedRecord.comp.spaceProperty && this.selectedRecord.comp.spacePropertyId))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.propertiesService.in([this.selectedRecord.comp.spacePropertyId])];
                    case 1:
                        properties = _a.sent();
                        this.selectedRecord.comp.spaceProperty = properties.length && properties[0] || null;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    LeaseModal.prototype.setProperty = function () {
        return __awaiter(this, void 0, void 0, function () {
            var properties;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.selectedRecord.comp.leasedProperty && this.selectedRecord.comp.leasedPropertyId))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.propertiesService.in([this.selectedRecord.comp.leasedPropertyId])];
                    case 1:
                        properties = _a.sent();
                        this.selectedRecord.comp.leasedProperty = properties.length && properties[0] || null;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    LeaseModal.prototype.setContact = function () {
        return __awaiter(this, void 0, void 0, function () {
            var contacts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.selectedRecord.comp.clientContact && this.selectedRecord.comp.clientCompanyId))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.contactsService.in([this.selectedRecord.comp.clientCompanyId])];
                    case 1:
                        contacts = _a.sent();
                        this.selectedRecord.comp.clientContact = contacts.length && contacts[0] || null;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    LeaseModal.prototype.showToast = function (task) {
        var toast = this.toastController.create({
            duration: TOAST_DURATION,
            message: task.isComplete ? 'Task completed.' : 'Task opened.',
            showCloseButton: true
        });
        toast.present();
    };
    LeaseModal.prototype.updateTask = function (task) {
        var existingTask = this.tasks.find(function (t) { return t._id === task._id; });
        if (existingTask) {
            Object.assign(existingTask, task);
            TaskModel.sortActivities(this.tasks);
        }
    };
    return LeaseModal;
}(BaseSlideModal));
export { LeaseModal };
