var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, SimpleChanges } from '@angular/core';
import { LoadingController } from 'ionic-angular';
import { CallListModel, ListingModel, OwnershipModel } from '@apto/models';
import { CallListsService, ContactsService, OwnershipsService } from '@apto/services';
import './add-call-list-members-card.scss';
var AddCallListMembersCard = /** @class */ /*@__PURE__*/ (function () {
    function AddCallListMembersCard(callListsService, contactsService, ownershipsService, loadingController) {
        this.callListsService = callListsService;
        this.contactsService = contactsService;
        this.ownershipsService = ownershipsService;
        this.loadingController = loadingController;
        this.allCallLists = [];
        this.contacts = [];
        this.contactGroups = [];
        this.properties = [];
        this.isInitializingCallLists = true;
        this.clearButtonEnabled = true;
        this.onClearAll = new EventEmitter();
        this.onAddedToList = new EventEmitter();
        this.onError = new EventEmitter();
        this.isLoadingCounts = false;
        this.CallListModel = CallListModel;
        this.limitError = null;
        this.ownerships = [];
        this.selectOwners = true;
        this.selectFormerOwners = false;
        this.selectAssetManagers = false;
        this.subscriptions = [];
        this.getContactLists();
    }
    AddCallListMembersCard.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.callListsService.onUpserted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.callListsService.startPolling();
                    return [2 /*return*/];
                });
            });
        }));
        this.subscriptions.push(this.callListsService.onMembersAdded.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.getContactLists(true)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }));
    };
    AddCallListMembersCard.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        this.hideLoading();
    };
    AddCallListMembersCard.prototype.showLoading = function () {
        this.loading = this.loadingController.create({
            content: 'Building list... Please wait.',
            cssClass: 'app-loading',
            showBackdrop: true
        });
        this.loading.present();
    };
    AddCallListMembersCard.prototype.hideLoading = function () {
        if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
        }
    };
    AddCallListMembersCard.prototype.ngOnChanges = function ($changes) {
        return __awaiter(this, void 0, void 0, function () {
            var contactGroups;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!($changes.properties && ($changes.properties.currentValue || []).length !== ($changes.properties.previousValue || []).length))
                            return [3 /*break*/, 2];
                        this.isLoadingCounts = true;
                        return [4 /*yield*/, this.getContactsFromProperties()];
                    case 1:
                        _a.sent();
                        this.updateContactsFromOwnerships();
                        _a.label = 2;
                    case 2:
                        contactGroups = $changes.contactGroups;
                        if (!(contactGroups && (contactGroups.currentValue || []).length !== (contactGroups.previousValue || []).length))
                            return [3 /*break*/, 4];
                        return [4 /*yield*/, this.getContactsFromContactGroups()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    AddCallListMembersCard.prototype.clearAll = function () {
        this.limitError = null;
        this.onClearAll.emit();
    };
    AddCallListMembersCard.prototype.updateContactsFromOwnerships = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var contactIds, ownerSet, formerOwnerSet, assetManagerSet, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isLoadingCounts = true;
                        contactIds = new Set();
                        ownerSet = new Set();
                        formerOwnerSet = new Set();
                        assetManagerSet = new Set();
                        this.ownerships.forEach(function (ownership) {
                            if (!ownership.contactId) {
                                return;
                            }
                            if (ownership.contactRole === OwnershipModel.ROLE.OWNER || ownership.contactRole === OwnershipModel.ROLE.PRIMARY_OWNER) {
                                _this.selectOwners ? (contactIds.add(ownership.contactId),
                                    ownerSet.add(ownership.contactId)) : ownerSet.add(ownership.contactId);
                            }
                            else if (ownership.contactRole === OwnershipModel.ROLE.FORMER_OWNER) {
                                _this.selectFormerOwners ? (contactIds.add(ownership.contactId),
                                    formerOwnerSet.add(ownership.contactId)) : formerOwnerSet.add(ownership.contactId);
                            }
                            else if (ownership.contactRole === OwnershipModel.ROLE.ASSET_MANAGER) {
                                _this.selectAssetManagers ? (contactIds.add(ownership.contactId),
                                    assetManagerSet.add(ownership.contactId)) : assetManagerSet.add(ownership.contactId);
                            }
                        });
                        this.owners = Array.from(ownerSet);
                        this.formerOwners = Array.from(formerOwnerSet);
                        this.assetManagers = Array.from(assetManagerSet);
                        _a = this;
                        return [4 /*yield*/, this.contactsService.in(Array.from(contactIds))];
                    case 1:
                        _a.contacts = _b.sent();
                        this.isLoadingCounts = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    AddCallListMembersCard.prototype.getContactsFromProperties = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.ownershipsService.byProperties(this.properties.map(function (property) { return property._id; }))];
                    case 1:
                        _a.ownerships = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    AddCallListMembersCard.prototype.getContactsFromContactGroups = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var results, _i, results_1, item;
            return __generator(this, function (_a) {
                this.isLoadingCounts = true;
                this.contacts = [];
                results = this.contactGroups.map(function (contactGroup) {
                    return contactGroup.contacts;
                });
                for (_i = 0, results_1 = results; _i < results_1.length; _i++) {
                    item = results_1[_i];
                    this.contacts = this.contacts.concat(item);
                }
                // dedupe contacts
                this.contacts = this.contacts.filter(function (contact, i) {
                    var index = _this.contacts.findIndex(function (c) { return c._id === contact._id; });
                    return index === i;
                });
                this.isLoadingCounts = false;
                return [2 /*return*/];
            });
        });
    };
    AddCallListMembersCard.prototype.listAdded = function ($event) {
        this.showLoading();
        this.clearAll();
        var callListNameText = $event && $event.selectedCallList && $event.selectedCallList.name;
        if (!callListNameText) {
            callListNameText = 'a new';
        }
        else {
            var callList = $event;
            this.callListsService.updateInCache(callList, callList._id);
        }
        this.onAddedToList.emit();
    };
    AddCallListMembersCard.prototype.showAddToListError = function ($event) {
        this.limitError = $event.error;
        this.onError.emit($event.error);
    };
    AddCallListMembersCard.prototype.getContactLists = function (force) {
        if (force === void 0) {
            force = false;
        }
        return __awaiter(this, void 0, void 0, function () {
            var results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.callListsService.fetch(undefined, true, force)];
                    case 1:
                        results = _a.sent();
                        results.sort(function (a, b) {
                            return (a.name > b.name) ? 1 : -1;
                        });
                        this.allCallLists = results;
                        this.isInitializingCallLists = false;
                        this.hideLoading();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AddCallListMembersCard;
}());
export { AddCallListMembersCard };
