<ion-card class="about-contact-card">
    <ion-row align-items-center>
        <ion-card-header class="about-contact-card--contact-name"
                         *ngIf="contact && contact.fullName">
            About {{contact.fullName}}
        </ion-card-header>
    </ion-row>
    <ion-card-content
        class="about-contact-card--contact-description"
        *ngIf="contact && contact.description && !editingDetails"
        [innerHTML]="contact.description | formattedText">
    </ion-card-content>
    <ion-card-content class="about-contact-card--contact-no-description"
                      no-padding
                      *ngIf="(!contact || !contact.description) && !editingDetails">
        <ion-item class="empty-card">
            <ion-icon name="assignment"
                      material-icons
                      circle
                      small
                      color="white"
                      item-left></ion-icon>
            Add a description and personal details
        </ion-item>
    </ion-card-content>
    <form class="about-contact-form"
          [formGroup]="aboutContactForm">
        <ion-card-content class="about-contact-card--contact-description-edit"
                          [hidden]="!editingDetails">
            <ion-textarea class="about-contact-card--text-area"
                          placeholder="EDIT DETAILS"
                          textarea-elastic
                          rows="1"
                          formControlName="description"></ion-textarea>
        </ion-card-content>
        <button class="about-contact-card--edit-details-button"
                *ngIf="!editingDetails"
                (click)="editDetails()"
                clear
                color="primary-blue"
                icon-right
                ion-button>
                EDIT DETAILS
        </button>
        <button class="about-contact-card--edit-details-button"
                *ngIf="editingDetails"
                (click)="saveDetails()"
                clear
                color="primary-blue"
                icon-right
                ion-button>
                SAVE DETAILS
        </button>
    </form>
</ion-card>
