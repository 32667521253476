import { ModalController } from 'ionic-angular';
import { ContactModel, ListingModel, PursuitModel, CompModel, ContractModel, TypesModel, } from '@apto/models';
import { NumberWithCommas, PricePerSqFtFormat } from '@apto/ionic-lib/pipes';
// TODO these should be in the shared module
import { NegotiationDetailsModal, SourcingDetailsModal, PipelineDetailsModalMode, PursuitDetailsModal, ClosedDealDetailsModal, ContractDetailsModal, } from '@apto/pn';
import './deals-list-card.scss';
var DealsListCard = /** @class */ /*@__PURE__*/ (function () {
    function DealsListCard(modalController) {
        this.modalController = modalController;
        this.maxCount = 0;
    }
    DealsListCard.prototype.getPriceAndSqft = function (property) {
        var items = [];
        if (property.salePrice) {
            var price = new NumberWithCommas().transform(property.salePrice);
            items.push("<strong>$" + price + "</strong>");
        }
        if (property.squareFootage) {
            var sqft = new NumberWithCommas().transform(property.squareFootage);
            items.push(sqft + " sqft");
        }
        if (property.salePrice && property.squareFootage) {
            var price = this.getPricePerSqft(property.salePrice, property.squareFootage);
            var str = new PricePerSqFtFormat().transform(price);
            items.push(str);
        }
        return items.join(' &bull; ');
    };
    DealsListCard.prototype.activityTypeName = function (activity) {
        if (activity instanceof CompModel) {
            return "Closed Deal";
        }
        ;
        if (activity instanceof ListingModel) {
            var isInSourcing = [
                ListingModel.STATUSES.PRE_MARKETING_PREPARATION,
                ListingModel.STATUSES.ON_MARKET,
                ListingModel.STATUSES.IN_SOURCING,
                ListingModel.STATUSES.ACTIVE
            ].includes(activity.onMarketStatus);
            var isInNegotiations = [
                ListingModel.STATUSES.IN_NEGOTIATION,
            ].includes(activity.onMarketStatus);
            if (isInSourcing) {
                return "Sourcing";
            }
            else if (isInNegotiations) {
                return "Negotiation";
            }
        }
        ;
        if (activity instanceof ContractModel) {
            return "Contract";
        }
        ;
        if (activity instanceof PursuitModel) {
            return "Pursuit";
        }
        ;
    };
    DealsListCard.prototype.getPricePerSqft = function (price, sqft) {
        return (parseInt(price, 10) / parseInt(sqft, 10)).toFixed(2).toString();
    };
    DealsListCard.prototype.getKeyByValue = function (obj, value) {
        return Object.keys(obj).find(function (el) { return obj[el] === value; });
    };
    DealsListCard.prototype.viewActivity = function (activity) {
        var _this = this;
        var modalToOpen = function () {
            if (activity instanceof CompModel) {
                activity.type = _this.getKeyByValue(_this.recordTypes.compTypes, activity.recordTypeId);
                return ClosedDealDetailsModal;
            }
            ;
            if (activity instanceof ListingModel) {
                activity.type = _this.getKeyByValue(_this.recordTypes.listingTypes, activity.recordTypeId);
                var isInSourcing = [
                    ListingModel.STATUSES.PRE_MARKETING_PREPARATION,
                    ListingModel.STATUSES.ON_MARKET,
                    ListingModel.STATUSES.IN_SOURCING,
                    ListingModel.STATUSES.ACTIVE
                ].includes(activity.onMarketStatus);
                var isInNegotiations = [
                    ListingModel.STATUSES.IN_NEGOTIATION,
                ].includes(activity.onMarketStatus);
                if (isInSourcing) {
                    return SourcingDetailsModal;
                }
                else if (isInNegotiations) {
                    return NegotiationDetailsModal;
                }
            }
            ;
            if (activity instanceof ContractModel) {
                activity.type = _this.getKeyByValue(_this.recordTypes.contractTypes, activity.recordTypeId);
                return ContractDetailsModal;
            }
            ;
            if (activity instanceof PursuitModel) {
                activity.type = _this.getKeyByValue(_this.recordTypes.pursuitTypes, activity.recordTypeId);
                return PursuitDetailsModal;
            }
            ;
        };
        var modalHandler = this.modalController.create(modalToOpen(), {
            mode: PipelineDetailsModalMode.VIEW,
            selectedRecord: activity
        });
        modalHandler.present({ updateUrl: false });
    };
    DealsListCard.prototype.trackByFn = function (index, item) {
        return item._id;
    };
    DealsListCard.prototype.getGraphStyle = function (stageInfo, status) {
        return { width: this.getWidth(stageInfo), color: stageInfo.count ? '#fff' : '#9e9e9e' };
    };
    DealsListCard.prototype.getWidth = function (countInfo) {
        var widthStyle = '0';
        if (countInfo.count > 0) {
            var widthPercentage = (countInfo.count / this.maxCount) * 100;
            // If the percent width is lower than 8 set to 8 to account for 2 digits.
            if (widthPercentage < 8) {
                widthStyle = '8%';
            }
            else {
                widthStyle = widthPercentage + '%';
            }
        }
        return widthStyle;
    };
    return DealsListCard;
}());
export { DealsListCard };
