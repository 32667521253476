var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ToastController } from 'ionic-angular';
import { Oauth } from 'ng2-cordova-oauth/oauth';
import { AuthService, IonicTokenService, LogsService } from '@apto/ionic-lib/services';
import { TOAST_DURATION } from '@apto/shared/constants';
import { ConfigService, MappingService } from '@apto/pn/services';
import { Salesforce } from '@apto/pn//oauth_providers/salesforce';
import './login.scss';
var LoginPage = /** @class */ /*@__PURE__*/ (function () {
    function LoginPage(authService, configService, logsService, route, oauth, http, mappingService, tokenService, toastController) {
        this.authService = authService;
        this.configService = configService;
        this.logsService = logsService;
        this.route = route;
        this.oauth = oauth;
        this.http = http;
        this.mappingService = mappingService;
        this.tokenService = tokenService;
        this.toastController = toastController;
        this.hasErrors = false;
        this.isLoggingIn = false;
        this.supportPageUrl = 'http://www.apto.com/support';
        this.learnMorePageUrl = 'http://info.apto.com/prospect-and-nurture';
        this.logoClickCount = 0;
        this.tokenUrl = this.configService.serviceUrl + "/oauth/token";
        this.salesforceProvider = new Salesforce({
            clientId: this.configService.oauthClientId,
            redirectUri: this.configService.oauthRedirectUri,
            appScope: [
                'api',
                'id',
                'profile',
                'email',
                'address',
                'phone',
                'openid',
                'refresh_token',
                'offline_access',
                'visualforce',
                'web'
            ]
        });
        this.backgroundCities = [
            'Atlanta',
            'Austin',
            'Baltimore',
            'Boston',
            'Chicago',
            'Denver',
            'Minneapolis',
            'NewOrleans',
            'NewYork',
            'SanFrancisco',
            'Seattle',
            'Toronto',
            'Vancouver',
            'WashingtonDC'
        ];
        this.hasErrors = this.route.snapshot.queryParamMap.get('hasErrors') === 'true';
        this.salesforceProvider.options.resolveOnUri = "" + window.location.origin;
        // need to preserve /#/login URL when pulling the OAuth code from the URL so the router doesn't omit it
        this.salesforceProvider.options.state = window.location.origin + "/%23/login";
    }
    LoginPage.prototype.ngOnInit = function () {
        this.checkForCode();
    };
    Object.defineProperty(LoginPage.prototype, "backgroundCityUrl", {
        get: function () {
            if (!this._backgroundCityUrl) {
                var backgroundCityIndex = Math.floor(Math.random() * this.backgroundCities.length);
                var backgroundCity = this.backgroundCities[backgroundCityIndex];
                this._backgroundCityUrl = "url('./images/" + backgroundCity + ".jpg')";
            }
            return this._backgroundCityUrl;
        },
        enumerable: true,
        configurable: true
    });
    LoginPage.prototype.doLogin = function () {
        var _this = this;
        // if we had any errors with a previous login, force the OAuth screen to prompt for login.
        // this allows the user to try with a different account if necessary.
        if (this.hasErrors) {
            this.salesforceProvider.options.prompt = 'login';
        }
        var params = new HttpParams({ fromString: window.location.search.substr(1) });
        var target = params.get('oauthTarget');
        var oauthOptions = (target) ? { title: '_self' } : undefined;
        this.oauth.logInVia(this.salesforceProvider, oauthOptions)
            .then(function (success) {
            _this.loginWithCode(success.code);
        })
            .catch(function (ex) {
            if (ex.toString().indexOf('sign in flow was canceled') >= 0) {
                _this.logsService.info(ex.message);
            }
            else {
                _this.hasErrors = true;
                _this.logsService.exception('error logging in', ex);
            }
        });
    };
    LoginPage.prototype.logoClicked = function () {
        this.logoClickCount++;
        if (this.logoClickCount % 10 === 0) {
            this.logoClickCount = 0;
            this.salesforceProvider.enableProductionLogin();
            this.tokenUrl = this.configService.serviceUrl + "/oauth/token";
            this.showToast('Production login enabled');
        }
        else if (this.logoClickCount % 5 === 0) {
            this.salesforceProvider.enableSandboxLogin();
            this.tokenUrl = this.configService.serviceUrl + "/oauth/sandbox-token";
            this.showToast('Sandbox login enabled');
        }
    };
    LoginPage.prototype.openLearnMorePage = function () {
        window.open(this.learnMorePageUrl, '_system');
    };
    LoginPage.prototype.openSupportPage = function () {
        window.open(this.supportPageUrl, '_system');
    };
    LoginPage.prototype.checkForCode = function () {
        var params = new HttpParams({ fromString: window.location.search.substr(1) });
        var code = params.get('code');
        if (code) {
            history.replaceState(null, '', '/#/'); // remove query string from URL; no longer needed.
            this.loginWithCode(code);
        }
    };
    LoginPage.prototype.loginWithCode = function (code) {
        var _this = this;
        var params = new HttpParams().set('code', code);
        this.isLoggingIn = true;
        this.http.get(this.tokenUrl, { params: params })
            .subscribe(function (response) {
            return __awaiter(_this, void 0, void 0, function () {
                var user, ex_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!response.token)
                                return [3 /*break*/, 7];
                            this.hasErrors = false;
                            return [4 /*yield*/, this.mappingService.clearEnabledCache()];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2:
                            _a.trys.push([2, 5, , 6]);
                            return [4 /*yield*/, this.tokenService.setToken(response.token)];
                        case 3:
                            _a.sent();
                            return [4 /*yield*/, this.authService.checkLoginAndNavigate()];
                        case 4:
                            user = _a.sent();
                            if (!user) {
                                this.tokenService.clearToken();
                            }
                            return [3 /*break*/, 6];
                        case 5:
                            ex_1 = _a.sent();
                            this.hasErrors = true;
                            this.isLoggingIn = false;
                            this.logsService.exception('error logging in', ex_1);
                            return [3 /*break*/, 6];
                        case 6: return [3 /*break*/, 8];
                        case 7:
                            this.hasErrors = true;
                            this.isLoggingIn = false;
                            _a.label = 8;
                        case 8: return [2 /*return*/];
                    }
                });
            });
        }, function (ex) {
            _this.isLoggingIn = false;
            _this.hasErrors = true;
            _this.logsService.exception('error logging in', ex);
        });
    };
    LoginPage.prototype.showToast = function (message) {
        this.toastController.create({
            duration: TOAST_DURATION,
            message: message
        }).present();
    };
    return LoginPage;
}());
export { LoginPage };
