import { STATES } from '../constants';
var StatesService = /** @class */ /*@__PURE__*/ (function () {
    function StatesService() {
    }
    Object.defineProperty(StatesService.prototype, "values", {
        get: function () {
            return STATES;
        },
        enumerable: true,
        configurable: true
    });
    StatesService.prototype.getState = function (stateCode) {
        var retunVal = '';
        this.values.forEach(function (value) {
            if (value.value === stateCode) {
                retunVal = value.label;
            }
        });
        return retunVal;
    };
    StatesService.prototype.getStateCode = function (state) {
        var retunVal = '';
        this.values.forEach(function (value) {
            if (value.label === state) {
                retunVal = value.value;
            }
        });
        return retunVal;
    };
    return StatesService;
}());
export { StatesService };
