import { PipeTransform } from '@angular/core';
var SortByTaskDueDate = /** @class */ /*@__PURE__*/ (function () {
    function SortByTaskDueDate() {
    }
    SortByTaskDueDate.prototype.transform = function (tasks) {
        return tasks.sort(function (task1, task2) {
            return task1.dueDate < task2.dueDate ? -1 : task1.dueDate > task2.dueDate ? 1 : 0;
        });
    };
    return SortByTaskDueDate;
}());
export { SortByTaskDueDate };
