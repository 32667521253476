import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NavParams, ToastController, ViewController } from 'ionic-angular';
import { TaskModel } from '@apto/models';
import { AppService, ContactsService, TasksService, UsersService } from '@apto/services';
import { TOAST_DURATION } from '@apto/shared/constants';
import { BaseSlideModal } from '@apto/shared/modals/base-slide-modal/base-slide-modal';
import { EditActivityForm } from '@apto/pn/components';
export var ActivityHistoryModalMode = /*@__PURE__*/ (function (ActivityHistoryModalMode) {
    ActivityHistoryModalMode[ActivityHistoryModalMode["LIST"] = 0] = "LIST";
    ActivityHistoryModalMode[ActivityHistoryModalMode["VIEW"] = 1] = "VIEW";
    ActivityHistoryModalMode[ActivityHistoryModalMode["EDIT"] = 2] = "EDIT";
    return ActivityHistoryModalMode;
})({});
import './activity-history-modal.scss';
import { Router } from '@angular/router';
var ActivityHistoryModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ActivityHistoryModal, _super);
    function ActivityHistoryModal(appService, contactsService, tasksService, navParams, toastController, usersService, viewController, router) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.appService = appService;
        _this.contactsService = contactsService;
        _this.tasksService = tasksService;
        _this.navParams = navParams;
        _this.toastController = toastController;
        _this.usersService = usersService;
        _this.viewController = viewController;
        _this.router = router;
        _this.modalUrls = new Map([
            [ActivityHistoryModalMode.VIEW, '/:taskId']
        ]);
        _this.Modes = ActivityHistoryModalMode;
        _this.showActivityTitle = true;
        _this.skipSave = false;
        _this.records = [];
        _this.selectedRecord = new TaskModel();
        _this.sortBy = 'Newest first';
        _this.isLoadingContacts = false;
        _this.isLoadingProperties = false;
        // make available to the view
        _this.TaskModel = TaskModel; // tslint:disable-line
        _this.skipSave = _this.navParams.get('skipSave');
        // concat to create a new reference of activities
        _this.mode = _this.navParams.get('mode') || ActivityHistoryModalMode.LIST;
        _this.records = (navParams.get('activities') || []).concat();
        _this.relatedListing = _this.navParams.get('relatedListing');
        _this.selectedRecord = _this.navParams.get('selectedActivity') || TaskModel.newLoggedCall();
        return _this;
    }
    Object.defineProperty(ActivityHistoryModal.prototype, "getHelpText", {
        get: function () {
            return this.selectedActivity ? "You can't edit this, contact " + this.selectedActivity.ownerName + " to update." : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ActivityHistoryModal.prototype, "disableEdit", {
        get: function () {
            return this.editActivityForm.isSaving || (this.selectedRecord && this.appService.salesforceUserId !== this.selectedRecord.ownerId);
        },
        enumerable: true,
        configurable: true
    });
    ActivityHistoryModal.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var formResult, savedActivity;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formResult = this.editActivityForm.save();
                        if (!(formResult && formResult.activity))
                            return [3 /*break*/, 4];
                        this.editActivityForm.isSaving = true;
                        savedActivity = void 0;
                        if (!!this.skipSave)
                            return [3 /*break*/, 2];
                        this.showToast(formResult.activity);
                        return [4 /*yield*/, this.tasksService.upsert(formResult.activity)];
                    case 1:
                        savedActivity = _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        savedActivity = formResult.activity;
                        _a.label = 3;
                    case 3:
                        this.editActivityForm.isSaving = false;
                        if (this.initialMode === this.Modes.EDIT) {
                            this.dismiss();
                        }
                        else {
                            this.view(savedActivity);
                        }
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    ActivityHistoryModal.prototype.sortChanged = function (sortKey) {
        this.sortBy = sortKey;
        this.sortActivities();
    };
    ActivityHistoryModal.prototype.viewContact = function (_a) {
        var _id = _a._id;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.dismiss({ dismissAll: true })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.router.navigate(['/contacts', _id])];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ActivityHistoryModal.prototype.showToast = function (activity) {
        var toast = this.toastController.create({
            duration: TOAST_DURATION,
            message: activity._id ? 'Updating Activity' : 'Saving Activity',
            showCloseButton: true
        });
        toast.present();
    };
    ActivityHistoryModal.prototype.sortActivities = function () {
        (this.sortBy === 'Newest first')
            ? TaskModel.sortActivities(this.records, 'Date')
            : TaskModel.sortActivities(this.records, 'Date', -1);
    };
    return ActivityHistoryModal;
}(BaseSlideModal));
export { ActivityHistoryModal };
