import { Platform } from 'ionic-angular';
var PlatformService = /** @class */ /*@__PURE__*/ (function () {
    function PlatformService(platform) {
        this.platform = platform;
        this.isRunningNative = this.platform.is('mobile') && !this.platform.is('mobileweb');
        this.isRunningIOS = this.platform.is('ios');
    }
    return PlatformService;
}());
export { PlatformService };
