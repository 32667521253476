import { __extends } from "tslib";
import { debounceTime } from 'rxjs/operators';
import { Range, TextInput } from 'ionic-angular';
import { FormControl } from '@angular/forms';
import { INPUT_DEBOUNCE } from '@apto/ionic-lib/constants';
import { NumberWithCommas } from '@apto/ionic-lib/pipes';
import { BaseFilter } from '../';
import './range-slide-filter.scss';
var RangeSlideFilter = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(RangeSlideFilter, _super);
    function RangeSlideFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.filterRange = {
            min: null,
            max: null
        };
        _this.value = {
            lower: null,
            upper: null
        };
        _this.isCurrency = false;
        _this.minimumPlaceholder = '';
        _this.maximumPlaceholder = '';
        return _this;
    }
    RangeSlideFilter.prototype.init = function (options) {
        var _this = this;
        _super.prototype.init.call(this, options);
        this.value.lower = this.filterRange.min;
        this.value.upper = this.filterRange.max;
        this.formControl = new FormControl();
        this.formControl.valueChanges.pipe(debounceTime(INPUT_DEBOUNCE)).subscribe(function (value) {
            _this.onQueryChange.emit();
        });
    };
    RangeSlideFilter.prototype.getQuery = function () {
        var query = {};
        var isValid = this.validate();
        if (isValid) {
            var lower = {};
            var upper = {};
            var notEqual = {};
            lower[this.queryKey] = { $gte: this.value.lower };
            upper[this.queryKey] = { $lte: this.value.upper };
            notEqual[this.queryKey] = { $ne: null };
            if (this.value.lower > this.filterRange.min || this.value.upper < this.filterRange.max) {
                query.$and = [];
                query.$and.push(notEqual);
            }
            if (this.value.lower > this.filterRange.min) {
                query.$and.push(lower);
            }
            if (this.value.upper < this.filterRange.max) {
                query.$and.push(upper);
            }
        }
        return query;
    };
    RangeSlideFilter.prototype.validate = function () {
        return this.value.lower < this.value.upper;
    };
    RangeSlideFilter.prototype.reset = function () {
        this.value.lower = this.filterRange.min;
        this.value.upper = this.filterRange.max;
        this.minimumInput.value = '';
        this.maximumInput.value = '';
        // Hacky but changing the above values does not change
        // the UI
        this.ionRange._inputUpdated();
    };
    RangeSlideFilter.prototype.isEmpty = function () {
        return this.value.lower === this.filterRange.min &&
            this.value.upper === this.filterRange.max;
    };
    RangeSlideFilter.prototype.onSliderChange = function (event) {
        if (this.isCurrency) {
            this.minimumInput.value = '$' + this.numberToString(event.value.lower);
            this.maximumInput.value = '$' + this.numberToString(event.value.upper);
        }
        else {
            this.minimumInput.value = this.numberToString(event.value.lower);
            this.maximumInput.value = this.numberToString(event.value.upper);
        }
    };
    RangeSlideFilter.prototype.numberToString = function (value) {
        return new NumberWithCommas().transform(value);
    };
    /**
     * Makes sure inputs are numbers, dollar signs ($), or commas (,).
     */
    RangeSlideFilter.prototype.onInputChange = function (event) {
        var currencyRegex = /[^0-9,$]/g;
        this.minimumInput.value = this.minimumInput.value.replace(currencyRegex, '');
        this.maximumInput.value = this.maximumInput.value.replace(currencyRegex, '');
        // change this.value.lower if entered minimum input is a valid monetary value
        if (this.minimumInput.value) {
            var sanitizedString = this.minimumInput.value.replace(/[^0-9]/g, '');
            var num = parseInt(sanitizedString, 0);
            this.value.lower = isNaN(num) ? this.filterRange.min : num;
        }
        else {
            this.value.lower = this.filterRange.min;
        }
        // change this.value.upper if entered maximum input is a valid monetary value
        if (this.maximumInput.value) {
            var sanitizedString = this.maximumInput.value.replace(/[^0-9]/g, '');
            var num = parseInt(sanitizedString, 0);
            this.value.upper = isNaN(num) ? this.filterRange.max : num;
        }
        else {
            this.value.upper = this.filterRange.max;
        }
        // Hacky but changing the above values does not change
        // the UI
        this.ionRange._inputUpdated();
    };
    return RangeSlideFilter;
}(BaseFilter));
export { RangeSlideFilter };
