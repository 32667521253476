<apto-card
    *ngIf="(company && company._id) || (contact && contact._id)"
    class="tenant-card">
    <apto-card-header noBottomPadding>Tenant</apto-card-header>
    <apto-card-content noPadding>
        <apto-list>
            <apto-list-item
                *ngIf="company && company._id"
                class="tenant-card--company"
                link
                (click)="clickedTenantCompany.emit(company)">
                <apto-icon
                    aptoListItemIcon
                    icon="property"
                    size="5"
                    circle
                    circleColor="orange">
                </apto-icon>
                <p *ngIf="company.name" class="tenant-card--name dark-text">{{company.name}}</p>
                <p *ngIf="company.type" class="tenant-card--text tenant-card--companyType">{{company.type}}</p>
                <p *ngIf="company.billingAddress || company.shippingAddress" class="tenant-card--text tenant-card--address">{{company.billingAddress || company.shippingAddress}}</p>
            </apto-list-item>
            <apto-list-item
                *ngIf="contact && contact._id"
                class="tenant-card--contact"
                link
                (click)="clickedTenantContact.emit(contact)">
                <apto-icon
                    aptoListItemIcon
                    icon="person"
                    size="5"
                    circle
                    circleColor="gray">
                </apto-icon>
                <p *ngIf="contact.fullName" class="tenant-card--name tenant-card--fullName dark-text">{{contact.fullName}}</p>
                <p *ngIf="contact.type" class="tenant-card--text tenant-card--contactType">{{contact.type}}</p>
                <p *ngIf="contact.title" class="tenant-card--text tenant-card--title">{{contact.title}}</p>
            </apto-list-item>
        </apto-list>
    </apto-card-content>
</apto-card>