import * as moment from 'moment';
import { CompModel } from '@apto/models';
import { NumberWithCommas } from '@apto/ionic-lib/pipes';

export const getLeaseDateStatus = (comp: CompModel): string => {
    if (comp && comp.leaseExpirationDate) {
        const currentDate = moment();
        const expirationDate = moment(comp.leaseExpirationDate);
        if (expirationDate < currentDate.add(1, 'years')) {
            return 'danger';
        }
        if (expirationDate < currentDate.add(1, 'years')) {
            return 'warning';
        }
    }
    return null;
};

export const getRentalRate = (comp: CompModel): string => {
    if (comp && comp.rentalRate && comp.rentalRateType) {
        const numberWithCommasPipe = new NumberWithCommas();
        const typeMap = {
            '$/SF/Year': 'sf/yr',
            '$/SF/Month': 'sf/mo',
            'Amount/Year': 'yr',
            'Amount/Month': 'mo'
        };
        let rentalRate = comp.rentalRate.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        rentalRate = numberWithCommasPipe.transform(rentalRate).replace('.00', '');
        const rentalRateType = typeMap[comp.rentalRateType];
        return `$${rentalRate}/${rentalRateType}`;
    }
    return null;
};
