import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector } from '@angular/core';
import { OfferModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
import { ContactsService } from '../contacts/contacts-service';
import { AlertController } from 'ionic-angular';
var OffersService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(OffersService, _super);
    function OffersService(injector, contactsService, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.contactsService = contactsService;
        _this.alertController = alertController;
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.OFFERS;
        _this.model = OfferModel;
        _this.serviceName = SERVICE_NAMES.OFFERS;
        _this.attachPurchasers = function (offers) {
            var purchaserIds = offers.map(function (offer) {
                return offer.purchaserId;
            });
            var uniquePurchaserIds = Array.from(new Set(purchaserIds));
            return _this.contactsService.in(uniquePurchaserIds)
                .then(function (purchasers) {
                offers.forEach(function (offer) {
                    offer.purchaser = purchasers.find(function (purchaser) { return offer.purchaserId === purchaser._id; });
                });
                return offers;
            });
        };
        return _this;
    }
    OffersService.prototype.fetch = function (params) {
        return _super.prototype.fetch.call(this, params)
            .then(this.attachPurchasers);
    };
    OffersService.prototype.recent = function (params) {
        return _super.prototype.recent.call(this, params)
            .then(this.attachPurchasers);
    };
    OffersService.prototype.byListing = function (listingId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.byListings([listingId])];
            });
        });
    };
    OffersService.prototype.byListings = function (listingIds) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var cachedListingIds, uncachedListingIds, cachedOffers, uncachedOffers, chunkedQueries, ids, params, results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        listingIds = listingIds.filter(function (id) { return id; });
                        cachedListingIds = [];
                        uncachedListingIds = [];
                        listingIds.forEach(function (listingId) {
                            if (_this.cacheService.get(_this.serviceName, listingId)) {
                                cachedListingIds.push(listingId);
                            }
                            else {
                                uncachedListingIds.push(listingId);
                            }
                        });
                        cachedOffers = [];
                        if (cachedListingIds.length) {
                            cachedOffers = this.cacheService.getAll(this.serviceName).filter(function (offer) { return cachedListingIds.indexOf(offer.listingId) !== -1; });
                        }
                        uncachedOffers = [];
                        if (!uncachedListingIds.length)
                            return [3 /*break*/, 2];
                        chunkedQueries = [];
                        ids = Object.assign([], uncachedListingIds);
                        while (ids.length > 0) {
                            params = {
                                where: {
                                    listingId: { $in: ids.splice(0, 200) }
                                }
                            };
                            chunkedQueries.push(this.fetch(params));
                        }
                        return [4 /*yield*/, Promise.all(chunkedQueries)];
                    case 1:
                        results = _a.sent();
                        uncachedOffers = [].concat.apply([], results); // flatten results arrays
                        uncachedListingIds.forEach(function (listingId) { return _this.cacheService.add(_this.serviceName, listingId, true); });
                        this.attachPurchasers(uncachedOffers);
                        _a.label = 2;
                    case 2: return [2 /*return*/, cachedOffers.concat(uncachedOffers).sort(this.sortOffers)];
                }
            });
        });
    };
    OffersService.prototype.sortOffers = function (offer1, offer2) {
        // primary sort by purchaser id
        if (offer1.purchaserId < offer2.purchaserId) {
            return 1;
        }
        else if (offer1.purchaserId > offer2.purchaserId) {
            return -1;
        }
        // secondary sort by date
        var offer1Date = new Date(offer1.offerDate);
        var offer2Date = new Date(offer2.offerDate);
        return offer1Date < offer2Date ? 1 : -1;
    };
    return OffersService;
}(BaseService));
export { OffersService };
