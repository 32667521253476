import { OnInit } from '@angular/core';
import './stacking-plan-space.scss';
import { getLeaseDateStatus, getRentalRate } from '@apto/pn/services/leasing-data/lease-expiration-helper';
export var MIN_SPACE_WIDTH = '30px';
var StackingPlanSpaceComponent = /** @class */ /*@__PURE__*/ (function () {
    function StackingPlanSpaceComponent() {
        this.width = '100%';
        this.classes = 'StackingPlanSpace';
    }
    StackingPlanSpaceComponent.prototype.ngOnInit = function () {
        this.width = this.getSpaceSize();
        this.classes = this.getSpaceClasses();
    };
    StackingPlanSpaceComponent.prototype.getSpaceClasses = function () {
        var classnames = ['StackingPlanSpace'];
        if (this.spaceData.space &&
            this.spaceData.space.spaceStatus &&
            this.spaceData.space.spaceStatus.toLowerCase() === 'vacant') {
            classnames.push('StackingPlanSpace--vacant');
        }
        else if (this.spaceData.space &&
            this.spaceData.space.spaceStatus &&
            this.spaceData.space.spaceStatus.toLowerCase() === 'available') {
            classnames.push('StackingPlanSpace--available');
        }
        else if (this.spaceData.space &&
            this.spaceData.space.spaceStatus &&
            this.spaceData.space.spaceStatus.toLowerCase() === 'pending') {
            classnames.push('StackingPlanSpace--pending');
        }
        else {
            if (this.spaceData.lease || (this.spaceData.space &&
                this.spaceData.space.spaceStatus &&
                this.spaceData.space.spaceStatus.toLowerCase() === 'leased')) {
                if (this.spaceData.lease && this.spaceData.lease.leaseExpirationDate) {
                    if (getLeaseDateStatus(this.spaceData.lease) === 'danger') {
                        classnames.push('StackingPlanSpace--leaseDanger');
                    }
                    else if (getLeaseDateStatus(this.spaceData.lease) === 'warning') {
                        classnames.push('StackingPlanSpace--leaseWarning');
                    }
                    else {
                        classnames.push('StackingPlanSpace--lease');
                    }
                }
                else {
                    classnames.push('StackingPlanSpace--lease');
                }
            }
        }
        return classnames.join(' ');
    };
    StackingPlanSpaceComponent.prototype.getSpaceSize = function () {
        if (this.spaceData.space &&
            this.spaceData.space.squareFootage) {
            var sqft = Number(this.spaceData.space.squareFootage);
            var total = Number(((sqft / this.totalSqft) * 100)).toFixed(4);
            return total + "%";
        }
        return MIN_SPACE_WIDTH;
    };
    Object.defineProperty(StackingPlanSpaceComponent.prototype, "rentalRate", {
        get: function () {
            return getRentalRate(this.spaceData.lease || null);
        },
        enumerable: true,
        configurable: true
    });
    return StackingPlanSpaceComponent;
}());
export { StackingPlanSpaceComponent };
