import { PipeTransform } from '@angular/core';
var FilterByTaskTypePipe = /** @class */ /*@__PURE__*/ (function () {
    function FilterByTaskTypePipe() {
    }
    FilterByTaskTypePipe.prototype.transform = function (items, type) {
        if (type === 'open') {
            return items.filter(function (item) { return !item.isComplete; });
        }
        else if (type === 'closed') {
            return items.filter(function (item) { return !!item.isComplete; });
        }
        return items;
    };
    return FilterByTaskTypePipe;
}());
export { FilterByTaskTypePipe };
