<div class="error-panel">
    <div class="error">
        <ion-item class="error-description" no-lines>
            <ion-icon class="error-icon"
                    name="alert"
                    item-left></ion-icon>
            <div>
                <h2>Uh oh! This card didn't load.</h2>
            </div>
            <div><a (click)="refresh()">To view, reload the page.</a></div>
        </ion-item>
    </div>
</div>