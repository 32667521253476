import { EventEmitter } from '@angular/core';
import { TaskModel } from '@apto/models';
import './activity-history-card.scss';
var ActivityHistoryCard = /** @class */ /*@__PURE__*/ (function () {
    function ActivityHistoryCard() {
        this.activities = [];
        this.isInitializing = true;
        this.sliceLength = 3;
        this.editEnabled = true;
        this.onAddActivity = new EventEmitter();
        this.onViewActivity = new EventEmitter();
        this.onViewAllActivities = new EventEmitter();
        this.TaskModel = TaskModel;
    }
    ActivityHistoryCard.prototype.addActivity = function () {
        this.onAddActivity.emit();
    };
    ActivityHistoryCard.prototype.view = function (activity) {
        this.onViewActivity.emit({
            contactId: activity.contactId,
            activity: activity
        });
    };
    ActivityHistoryCard.prototype.viewMore = function () {
        this.onViewAllActivities.emit({
            activities: this.activities,
            contactId: this.activities[0].contactId
        });
    };
    return ActivityHistoryCard;
}());
export { ActivityHistoryCard };
