<div class="feedback light-text">Is this helpful?
    <ion-icon extra-small
              class="feedback--thumb-icon"
              name="thumbs-up">
    </ion-icon>
    Yes
    <ion-icon extra-small
              class="feedback--thumb-icon"
              name="thumbs-down">
    </ion-icon>
    No
</div>