<ion-grid class="add-followup--form"
          *ngIf="shouldShow">
    <div class="add-followup--outer">
        <div class="add-followup--inner"
             [class.error]="hasErrors"
             [@3dspin]="animationState">
            <h4 class="title">Add Follow-up Task</h4>
            <span class="light-text">Never miss an opportunity with Apto.</span>

            <edit-task-form [allowEditContact]="false"
                            [relatedContact]="relatedContact"
                            [task]="task"></edit-task-form>

            <div class="add-followup--controls">
                <button class="skip-followup"
                        (click)="skip()"
                        clear
                        color="ancillary-grey"
                        icon-left
                        ion-button
                        small
                        data-automation="addFollowupSkipButton">
                    SKIP FOLLOW-UP
                </button>
                <button class="save-and-next"
                        (click)="save()"
                        clear
                        color="primary-blue"
                        icon-right
                        ion-button
                        small
                        data-automation="addFollowupSaveAndNextButton">
                    {{ hasNext ? "SAVE + NEXT" : "SAVE" }}
                    <ion-icon name="md-arrow-forward"></ion-icon>
                </button>
            </div>

            <busy-panel *ngIf="isSaving" message="Saving Follow-Up"></busy-panel>
        </div>
    </div>
</ion-grid>
