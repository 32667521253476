import './error-card.scss';
var ErrorCard = /** @class */ /*@__PURE__*/ (function () {
    function ErrorCard() {
    }
    ErrorCard.prototype.refresh = function () {
        location.reload();
    };
    return ErrorCard;
}());
export { ErrorCard };
