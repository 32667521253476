import './help-text.scss';
var AptoHelpText = /** @class */ /*@__PURE__*/ (function () {
    function AptoHelpText() {
        this.error = false;
    }
    Object.defineProperty(AptoHelpText.prototype, "classes", {
        get: function () {
            return [
                'apto-help-text',
                this.error ? 'apto-help-text--error' : null
            ].filter(Boolean).join(' ');
        },
        enumerable: true,
        configurable: true
    });
    return AptoHelpText;
}());
export { AptoHelpText };
