import { PipeTransform } from '@angular/core';
var KeysPipe = /** @class */ /*@__PURE__*/ (function () {
    function KeysPipe() {
    }
    KeysPipe.prototype.transform = function (value, args) {
        if (args === void 0) {
            args = null;
        }
        if (!value) {
            return undefined;
        }
        var data = Object.keys(value);
        var reA = /[^a-zA-Z]/g;
        var reN = /[^0-9]/g;
        if (args && args.includes('sort')) {
            data.sort(function (a, b) {
                var AInt = parseInt(a, 10);
                var BInt = parseInt(b, 10);
                if (isNaN(AInt) && isNaN(BInt)) {
                    var aA = a.replace(reA, '');
                    var bA = b.replace(reA, '');
                    if (aA === bA) {
                        var aN = parseInt(a.replace(reN, ''), 10);
                        var bN = parseInt(b.replace(reN, ''), 10);
                        return aN === bN ? 0 : aN > bN ? 1 : -1;
                    }
                    else {
                        return aA > bA ? 1 : -1;
                    }
                }
                else if (isNaN(AInt)) {
                    return 1;
                }
                else if (isNaN(BInt)) {
                    return -1;
                }
                else {
                    return AInt > BInt ? 1 : -1;
                }
            });
        }
        if (args && args.includes('reverse')) {
            data.sort(function (a, b) {
                var AInt = parseInt(a, 10);
                var BInt = parseInt(b, 10);
                if (isNaN(AInt) && isNaN(BInt)) {
                    var aA = a.replace(reA, '');
                    var bA = b.replace(reA, '');
                    if (aA === bA) {
                        var aN = parseInt(a.replace(reN, ''), 10);
                        var bN = parseInt(b.replace(reN, ''), 10);
                        return aN === bN ? 0 : aN > bN ? -1 : 1;
                    }
                    else {
                        return aA > bA ? -1 : 1;
                    }
                }
                else if (isNaN(AInt)) {
                    return 1;
                }
                else if (isNaN(BInt)) {
                    return -1;
                }
                else {
                    return AInt > BInt ? -1 : 1;
                }
            });
        }
        return data;
    };
    return KeysPipe;
}());
export { KeysPipe };
