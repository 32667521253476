<div class="form--apto-ionic-select">
    <ion-label stacked
               [ngClass]="{'required': required}">{{ label }}
        <span *ngIf="required"> *</span>
    </ion-label>
    <apto-ionic-select [value]="selectedValue"
                       (onSelectOption)="setFormValue($event.value)"
                       [options]="labels"
                       [cssClass]="cssClass"></apto-ionic-select>
</div>
<div class="field-error"
     *ngIf="required && form.controls[field].dirty && form.controls[field].errors && form.controls[field].errors['required']">
    {{ label }} is required.
</div>
