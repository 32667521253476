<ion-header class="modal-header unit-mix-modal--header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton();"
                    data-automation="modalBackButton">
                <apto-icon icon="arrowBack" inline size="4"></apto-icon>
            </button>
        </ion-buttons>

        <ion-title *ngIf="mode === Modes.LIST">{{ title }}</ion-title>
        <ion-title *ngIf="mode === Modes.VIEW">Unit Mix Details</ion-title>
        <ion-title *ngIf="mode === Modes.EDIT">{{ selectedRecord._id ? 'Edit' : 'Add' }} Unit Mix</ion-title>

        <ion-buttons end>
            <button *ngIf="mode === Modes.VIEW"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="setMode(Modes.EDIT)"
                    data-automation="modal-edit-button">
                Edit
            </button>
            <button *ngIf="mode === Modes.EDIT && selectedRecord._id"
                    ion-button
                    clear
                    color="danger"
                    hold-button
                    (hold)="delete()"
                    data-automation="modal-delete-button">
                Hold to Delete
            </button>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismiss()"
                    data-automation="modal-dismiss-button">
                <apto-icon icon="close" inline size="4"></apto-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal">
    <ion-slides (ionSlideWillChange)="scrollToTop()">
        <ion-slide>
            <ion-content>
                <!-- Unit Mix List Here -->
            </ion-content>
        </ion-slide>
        <ion-slide>
            <ion-content>
                <!-- View Unit Mix Here -->
            </ion-content>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide">
            <ion-content>
                <ion-card>
                    <ion-card-header>Unit mix details</ion-card-header>
                    <ion-card-content>
                        <edit-unit-mix-form [unitMix]="selectedRecord"></edit-unit-mix-form>
                    </ion-card-content>
                </ion-card>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>
