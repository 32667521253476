<ion-header class="modal-header call-list-modal--header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton();"
                    data-automation="modalBackButton">
                <apto-icon icon="arrowBack" inline size="4"></apto-icon>
            </button>
        </ion-buttons>

        <ion-title *ngIf="mode === Modes.LIST">Call List</ion-title>
        <ion-title *ngIf="mode === Modes.VIEW">
            {{ selectedRecord.name }}
            <span class="subtitle">Prospect list</span>
        </ion-title>
        <ion-title *ngIf="mode === Modes.EDIT">{{ selectedRecord._id ? 'Edit' : 'Add' }} Call List</ion-title>

        <ion-buttons end>
            <span *ngIf="selectedRecord.isLegacy" class="subtitle legacy-message">
                Editing disabled
                <help-button *ngIf="!isLoading && selectedRecord.isLegacy"
                             helpText="<p>The process for building call lists has been improved to allow more control and better reporting.</p>
                                       <p>However, due to these changes, lists built before 10/3/17 are no longer editable.</p>
                                       <p>If needed, you can create a new list using the same groups and views.</p>"
                             showEmailLink="true">
                </help-button>
            </span>
            <button *ngIf="!selectedRecord.isAptoGenerated && ((mode === Modes.VIEW && selectedRecord.isLegacy) || (mode === Modes.EDIT && !selectedRecord.isLegacy && selectedRecord._id))"
                    ion-button
                    clear
                    color="danger"
                    hold-button
                    (hold)="delete()"
                    data-automation="modal-delete-button">
                Hold to Delete
            </button>
            <button *ngIf="mode === Modes.VIEW && !selectedRecord.isLegacy"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="setMode(Modes.EDIT)"
                    [disabled]="isLoadingContacts"
                    data-automation="modal-edit-button">
                Edit
            </button>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    [disabled]="!editCallListForm.form.valid"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismiss()"
                    data-automation="modal-dismiss-button">
                <apto-icon icon="close" inline size="4"></apto-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal call-list-modal--content">
    <busy-panel
        message="Loading call list...">
    </busy-panel>
    <ion-slides [hidden]="listUpdating">
        <ion-slide>
            <ion-content>
                <ion-card>
                    <ion-list>
                        <!-- Add list of CallListItems here -->
                    </ion-list>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide class="call-list-modal--view-slide">
            <ion-content>
                <ion-card>
                    <ion-card-content>
                        <ion-list *ngIf="selectedRecord.members && selectedRecord.members.length">
                            <contact-item
                                          *ngFor="let member of selectedRecord.members | slice:pagination.range.min:pagination.range.max; let i = index"
                                          [contact]="member.contact"
                                          [contacted]="member.callResult"
                                          (onClick)="viewContact(member)"></contact-item>
                        </ion-list>

                        <pagination-navigation [itemsPerPage]="100"
                                               [totalRecords]="selectedRecord.members.length"
                                               (onChange)="scrollToTop()"></pagination-navigation>
                    </ion-card-content>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide">
            <ion-content>
                <edit-call-list-form [addedContacts]="addedContacts"
                                     [callList]="selectedRecord"
                                     [callLists]="records"
                                     (onPaginationChange)="scrollToTop()">
                </edit-call-list-form>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>
