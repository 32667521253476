import { SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AutoCompleteComponent } from '@apto/autocomplete';
import { ContactModel, PhoneNumberModel } from '@apto/models';
import { CompaniesService } from '@apto/services';
import { EMAIL_REGEX, PHONE_NUMBER_REGEX, POSTAL_REGEX, validationMessages } from '@apto/shared/constants';
var EditContactForm = /** @class */ /*@__PURE__*/ (function () {
    function EditContactForm(companiesService, formBuilder) {
        this.companiesService = companiesService;
        this.formBuilder = formBuilder;
        this.validationMessages = validationMessages;
    }
    EditContactForm.prototype.ngOnInit = function () {
        this.selectedCompany = this.contact.company;
        if (this.selectedCompany) {
            this.companyAutoComplete.setValue(this.selectedCompany.name);
        }
        this.setupForm();
    };
    EditContactForm.prototype.ngOnChanges = function (changes) {
        if (changes.contact &&
            changes.contact.previousValue &&
            changes.contact.previousValue._id !== changes.contact.currentValue._id) {
            this.setupForm();
        }
    };
    EditContactForm.prototype.setupForm = function () {
        this.contactForm = this.formBuilder.group({
            firstName: [this.contact.firstName, Validators.required],
            lastName: [this.contact.lastName, Validators.required],
            type: [this.contact.type, Validators.required],
            title: this.contact.title,
            description: this.contact.description,
            phone: this.formBuilder.group({
                label: this.contact.phone.label,
                number: [this.contact.phone.number, Validators.pattern(PHONE_NUMBER_REGEX)]
            }),
            mobilePhone: this.formBuilder.group({
                label: this.contact.mobilePhone.label,
                number: [this.contact.mobilePhone.number, Validators.pattern(PHONE_NUMBER_REGEX)]
            }),
            homePhone: this.formBuilder.group({
                label: this.contact.homePhone.label,
                number: [this.contact.homePhone.number, Validators.pattern(PHONE_NUMBER_REGEX)]
            }),
            otherPhone: this.formBuilder.group({
                label: this.contact.otherPhone.label,
                number: [this.contact.otherPhone.number, Validators.pattern(PHONE_NUMBER_REGEX)]
            }),
            fax: this.formBuilder.group({
                label: this.contact.fax.label,
                number: [this.contact.fax.number, Validators.pattern(PHONE_NUMBER_REGEX)]
            }),
            email: this.formBuilder.group({
                label: this.contact.email.label,
                address: [this.contact.email.address, Validators.pattern(EMAIL_REGEX)]
            }),
            email2: this.formBuilder.group({
                label: this.contact.email2.label,
                address: [this.contact.email2.address, Validators.pattern(EMAIL_REGEX)]
            }),
            address: this.formBuilder.group({
                label: this.contact.address.label,
                street: this.contact.address.street,
                city: this.contact.address.city,
                state: this.contact.address.state,
                country: this.contact.address.country,
                postalCode: [this.contact.address.postalCode, Validators.pattern(POSTAL_REGEX)]
            })
        });
    };
    EditContactForm.prototype.save = function () {
        var _this = this;
        if (this.contactForm.valid) {
            Object.assign(this.contact, this.contactForm.value);
            this.contact.fullName = this.contact.firstName + " " + this.contact.lastName;
            this.contact.company = this.selectedCompany;
            this.contact.companyId = this.selectedCompany && this.selectedCompany._id;
            this.contact.phone = this.processPhoneNumber(this.contact.phone);
            this.contact.mobilePhone = this.processPhoneNumber(this.contact.mobilePhone);
            this.contact.homePhone = this.processPhoneNumber(this.contact.homePhone);
            this.contact.otherPhone = this.processPhoneNumber(this.contact.otherPhone);
            return {
                contact: this.contact,
                company: this.contact.company
            };
        }
        else {
            Object.keys(this.contactForm.controls).forEach(function (fieldKey) {
                _this.contactForm.controls[fieldKey].markAsDirty();
            });
        }
    };
    EditContactForm.prototype.selectCompany = function (company) {
        this.selectedCompany = company;
        if (this.selectedCompany) {
            this.companyAutoComplete.setValue(this.selectedCompany.name);
        }
    };
    EditContactForm.prototype.processPhoneNumber = function (phoneNumber) {
        return phoneNumber.number && !phoneNumber.iconName ?
            new PhoneNumberModel(phoneNumber.label, phoneNumber.number) :
            phoneNumber;
    };
    return EditContactForm;
}());
export { EditContactForm };
