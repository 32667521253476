import { __extends } from "tslib";
import { Injector } from '@angular/core';
import { AlertController } from 'ionic-angular';
import { PropertyModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
var PropertiesServiceBase = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(PropertiesServiceBase, _super);
    function PropertiesServiceBase(injector, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.alertController = alertController;
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.PROPERTIES;
        _this.model = PropertyModel;
        _this.serviceName = SERVICE_NAMES.PROPERTIES;
        return _this;
    }
    return PropertiesServiceBase;
}(BaseService));
export { PropertiesServiceBase };
