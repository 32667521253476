<ion-card class="export-manager--wrapper">
    <ion-card-header class="card-header export-manager--header">
        <span *ngIf="!showHelpMessage">Recent Exports ({{ documents.length }})</span>
        <div
            [ngClass]="{'export-manager--hidden': showHelpMessage}"
            class="export-manager--search">
            <ion-icon
                class="export-manager--search--icon"
                name="search"></ion-icon>
            <input
                type="text"
                name="searchTerm"
                [(ngModel)]="searchTerm"
                [formControl]="searchControl"
                placeholder="Find exports">
            <button
                *ngIf="searchTerm.length > 0"
                class="export-manager--reset--icon"
                color="ancillary-grey"
                (click)="reset($event)">
                <span class="button-inner">
                    <ion-icon name="close"></ion-icon>
                </span>
            </button>
        </div>
    </ion-card-header>
    <ion-card-content class="export-manager--docs">
        <div *ngFor="let doc of documents; let index = index;">
            <export-file [(file)]="documents[index]"></export-file>
        </div>
        <div *ngIf="!documents || documents.length === 0" class="export-manager--none-found">
            <span *ngIf="showHelpMessage" class="export-manager--help-message">
                You can export CSVs and PDFs under Properties and they will show up
                 here for you to download and use. Contact <a href="mailto:customercare@apto.com">customercare@apto.com</a> with questions.</span>
            <span *ngIf="!showHelpMessage" class="export-manager--help-message--no-exports">Could not find any exports.</span>
        </div>
        <busy-panel *ngIf="isLoading"></busy-panel>
    </ion-card-content>
</ion-card>
