import * as moment from 'moment';
import { EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DATEPICKER_OPTIONS } from '../../../constants';
import { MyDatePicker } from 'mydatepicker';
import './date-field.scss';
var DateField = /** @class */ /*@__PURE__*/ (function () {
    function DateField() {
        this.onClearCalendar = new EventEmitter();
        this.onOpenCalendar = new EventEmitter();
        this.onSelectDate = new EventEmitter();
        this.required = false;
        this.datepickerOptions = Object.assign({}, DATEPICKER_OPTIONS);
    }
    DateField.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.form.controls.hasOwnProperty(this.field)) {
            throw new Error("Field \"" + this.field + "\" does not exist on form.");
        }
        Object.assign(this.datepickerOptions, this.options);
        var defaultDate = moment(this.defaultDate);
        if (this.defaultDate && defaultDate.isValid()) {
            this.default = {
                year: defaultDate.year(),
                month: defaultDate.month() + 1,
                day: defaultDate.date()
            };
        }
        this.onOpenCalendar.subscribe(function () {
            _this.myDatePicker.openBtnClicked();
        });
        this.onClearCalendar.subscribe(function () {
            _this.form.controls[_this.field].patchValue(null);
            _this.myDatePicker.clearDate();
        });
        this.onSelectDate.subscribe(function (date) {
            var newDate = _this.myDatePicker.jsDateToMyDate(date);
            _this.myDatePicker.selectDate(newDate, 0);
        });
    };
    DateField.prototype.onInputChanged = function (event) {
        event.value = event.value.trim();
        // Its timezone fix. Main idea - add TZ offset in time for alignment 
        // when converting in absolute format. Because time will be cleared by 
        // Angular input field but TZ not. For example chosen date in input "1/01/2000"
        // with TZ -7 convert to absolute  "1/01/2000, 7:00:00". 
        // But if TZ +4 we will have "12/31/1999, 20:00:00".
        // This fix will align TZ offset
        var date = moment.utc(event.value);
        var regex = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/;
        if (event.value) {
            if (regex.test(event.value) && date.isValid()) {
                this.form.controls[this.field].patchValue(date.toDate());
            }
            else {
                this.form.controls[this.field].setErrors({ syntax: true });
            }
        }
        else {
            this.form.controls[this.field].patchValue(null);
        }
        this.form.controls[this.field].markAsDirty();
    };
    return DateField;
}());
export { DateField };
