<form #formContent
      class="edit-activity-form edit-form"
      [formGroup]="form">
    <div class="input-group" *ngIf="!activity._id">
        <ion-label stacked>Activity type</ion-label>
        <task-type-selector formControlName="type"
                            (onChange)="setDefaultName()"></task-type-selector>
        <div class="field-error"
             *ngIf="form.controls['type'].dirty &&
                    form.controls['type'].invalid &&
                    form.controls['type'].errors['required']">
            A type is required.
        </div>
    </div>

    <div *ngIf="showResultOptions" class="input-group">
        <ion-label stacked>Call result</ion-label>
        <ion-segment formControlName="callResult"
                     mode="ios"
                     color="primary-blue"
                     class="edit-activity-form--result">
            <ion-segment-button value="{{TaskModel.RESULT.REACHED}}">Reached</ion-segment-button>
            <ion-segment-button value="{{TaskModel.RESULT.NOT_REACHED}}">Not reached</ion-segment-button>
            <ion-segment-button value="{{TaskModel.RESULT.LEFT_MESSAGE}}">Left message</ion-segment-button>
            <ion-segment-button value="{{TaskModel.RESULT.OTHER}}">Other</ion-segment-button>
        </ion-segment>
    </div>

    <ion-item>
        <ion-label stacked required>Activity name</ion-label>
        <ion-input (input)="editActivityName()"
                   formControlName="subject"
                   class="light-text"
                   maxLength="255"></ion-input>
    </ion-item>
    <div class="field-error"
         *ngIf="form.controls['subject'].dirty &&
                form.controls['subject'].invalid &&
                form.controls['subject'].errors['required']">
        An activity name is required.
    </div>

    <ion-label stacked>Assigned to</ion-label>
    <ion-auto-complete [dataProvider]="userProvider"
                       [options]="{ placeholder : activity.ownerName }"
                       (itemSelected)="setUser($event);"
                       (input)="checkForBlank($event.target.value)"
                       data-automation="edit-activity-assign-owner"></ion-auto-complete>

    <ion-item>
        <ion-label stacked class="followup-note--label">Note</ion-label>
        <ion-textarea #description
                      textarea-elastic
                      class="elastic-text"
                      formControlName="description"
                      rows="1"></ion-textarea>
    </ion-item>


    <date-field [defaultDate]="this.form.controls.dueDate.value"
                [form]="form"
                field="dueDate"
                label="Date"
                [required]="true"
                [options]="datepickerOptions"></date-field>
    <div class="field-error"
         *ngIf="form.controls['dueDate'].dirty &&
                form.controls['dueDate'].invalid &&
                (form.controls['dueDate'].errors['required'] || form.controls['dueDate'].errors['syntax'])">
        A past date in MM/DD/YYYY format is required.
    </div>
    <div class="field-warning"
         *ngIf="form.controls['dueDate'].dirty &&
                form.controls['dueDate'].invalid &&
                form.controls['dueDate'].errors['invalidFutureActivity']">
        Date cannot be in the future since activities are completed tasks.
    </div>

    <ion-item *ngIf="!allowEditContact">
        <ion-label stacked>Related contact</ion-label>
        <ion-input disabled
                   value="{{ relatedContact && relatedContact.fullName }}"></ion-input>
    </ion-item>
    <ion-label *ngIf="allowEditContact" stacked>
        Related contact
        <add-record
            linkText="Create new"
            objectName="Contact"
            (onRecordCreated)="setRelatedContact($event);">
        </add-record>
    </ion-label>
    <ion-auto-complete #contactSearchbar
                       [hidden]="!allowEditContact"
                       [dataProvider]="contactProvider"
                       [options]="{ placeholder : 'Find contact' }"
                       (itemSelected)="setRelatedContact($event);"
                       (itemsShown)="scrollToBottom();"
                       (input)="checkForBlank($event.target.value)"></ion-auto-complete>

    <div [ngClass]="{ hidden: !featuresService.isFeatureEnabled(featuresService.FEATURES.DEALS) }">
        <ion-label stacked>Related to</ion-label>
        <global-search #globalSearch
                       id="edit-activity-form--related-deal"
                       (itemSelected)="setRelatedObject($event)"
                       (selectionCancelled)="clearRelatedObject()"
                       placeholder="Quickly find deals"
                       [searchObjects]="['deals']"
                       [showAllResultsLink]="false"
                       [showListOnClick]="false">
        </global-search>
    </div>

    <div class="form--apto-ionic-select" *ngIf="form.controls['contactId'].value && form.controls['whatId'].value">
        <ion-label stacked>Marketing status</ion-label>
        <apto-ionic-select [value]="activity.marketingStatus"
                           [options]="marketingStatuses"
                           (onSelectOption)="selectMarketingStatus($event.value)">
        </apto-ionic-select>
    </div>

    <busy-panel *ngIf="isSaving"
                message="Saving activity..."></busy-panel>
</form>
