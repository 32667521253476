import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { ModalController, NavParams, ToastController, ViewController } from 'ionic-angular';
import { CompModel } from '@apto/models';
import { AnalyticsService, AppService, CompsService, ContactsService, DealPartiesService, TasksService, PropertiesService, TypesService } from '@apto/services';
import { EditClosedDealForm, PipelineDetailsModalMode } from '@apto/pn';
import { PipelineSlideModal } from '@apto/pn/modals/pipeline-slide-modal/pipeline-slide-modal';
import './closed-deal-details-modal.scss';
import { Router } from '@angular/router';
var ClosedDealDetailsModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ClosedDealDetailsModal, _super);
    function ClosedDealDetailsModal(analyticsService, appService, navParams, compsService, contactsService, dealPartiesService, modalController, tasksService, toastController, viewController, propertiesService, typesService, router) {
        var _this = _super.call(this, viewController, modalController, navParams, tasksService, propertiesService, toastController, analyticsService, router, typesService) || this;
        _this.appService = appService;
        _this.navParams = navParams;
        _this.compsService = compsService;
        _this.contactsService = contactsService;
        _this.dealPartiesService = dealPartiesService;
        _this.modalController = modalController;
        _this.tasksService = tasksService;
        _this.toastController = toastController;
        _this.viewController = viewController;
        _this.propertiesService = propertiesService;
        _this.typesService = typesService;
        _this.modalUrls = new Map([
            [PipelineDetailsModalMode.VIEW, '/:taskId']
        ]);
        _this.isInitializingDealParties = false;
        _this.isInitializingTasks = false;
        _this.Modes = PipelineDetailsModalMode;
        _this.records = [];
        _this.selectedRecord = new CompModel();
        _this.dealParties = [];
        _this.subscriptions = [];
        _this.mode = _this.navParams.get('mode') || PipelineDetailsModalMode.VIEW;
        _this.selectedRecord = _this.navParams.get('selectedRecord');
        return _this;
    }
    ClosedDealDetailsModal.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.tasksService.onUpserted.subscribe(function (data) {
            if (data.isNew) {
                _this.addTask(data.payload);
            }
            else {
                _this.updateTask(data.payload);
            }
        }));
        this.subscriptions.push(this.tasksService.onDeleted.subscribe(function (data) {
            data.payload.forEach(function (id) { return _this.delTask(id); });
        }));
        this.subscriptions.push(this.compsService.onUpserted.subscribe(function (data) {
            _this.isInitializingDealParties = true;
            _this.loadDealParties();
        }));
        this.loadData();
    };
    Object.defineProperty(ClosedDealDetailsModal.prototype, "isLease", {
        get: function () {
            return this.selectedRecord.type && this.selectedRecord.type.toLowerCase().includes('lease');
        },
        enumerable: true,
        configurable: true
    });
    ClosedDealDetailsModal.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    ClosedDealDetailsModal.prototype.loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(!this.selectedRecord.property && this.selectedRecord.propertyId))
                            return [3 /*break*/, 2];
                        _a = this.selectedRecord;
                        return [4 /*yield*/, this.propertiesService.byId(this.selectedRecord.propertyId)];
                    case 1:
                        _a.property = _b.sent();
                        _b.label = 2;
                    case 2:
                        this.loadTasks();
                        this.loadDealParties();
                        return [2 /*return*/];
                }
            });
        });
    };
    ClosedDealDetailsModal.prototype.delete = function () {
        this.compsService.delete([this.selectedRecord._id]);
        this.dismiss();
    };
    ClosedDealDetailsModal.prototype.save = function (closedDeal) {
        return __awaiter(this, void 0, void 0, function () {
            var formResult, savedComp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.mode === PipelineDetailsModalMode.VIEW))
                            return [3 /*break*/, 1];
                        this.contactsService.upsert(closedDeal);
                        return [3 /*break*/, 3];
                    case 1:
                        formResult = this.editClosedDealForm.save();
                        if (!(formResult && formResult.closedDeal))
                            return [3 /*break*/, 3];
                        this.scrollToTop();
                        this.editClosedDealForm.isSaving = true;
                        return [4 /*yield*/, this.compsService.upsert(formResult.closedDeal)];
                    case 2:
                        savedComp = _a.sent();
                        if (!savedComp) {
                            return [2 /*return*/, this.dismiss()];
                        }
                        this.editClosedDealForm.isSaving = false;
                        this.selectedRecord = new CompModel(savedComp);
                        this.loadData();
                        if (this.initialMode === this.Modes.EDIT) {
                            this.dismiss();
                        }
                        else {
                            this.view(this.selectedRecord);
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ClosedDealDetailsModal.prototype.loadDealParties = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isInitializingDealParties = true;
                        _a = this;
                        return [4 /*yield*/, this.dealPartiesService.fetch({ where: { compId: this.selectedRecord._id } })];
                    case 1:
                        _a.dealParties = _b.sent();
                        this.isInitializingDealParties = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    ClosedDealDetailsModal.prototype.loadTasks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isInitializingTasks = true;
                        _a = this;
                        return [4 /*yield*/, this.tasksService.byRelatedId(this.selectedRecord._id)];
                    case 1:
                        _a.tasks = _b.sent();
                        this.isInitializingTasks = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return ClosedDealDetailsModal;
}(PipelineSlideModal));
export { ClosedDealDetailsModal };
