import { EventEmitter, SimpleChanges } from '@angular/core';
import './pagination-navigation.scss';
var PaginationNavigation = /** @class */ /*@__PURE__*/ (function () {
    function PaginationNavigation() {
        this.itemsPerPage = 100;
        this.totalRecords = 0;
        this.onChange = new EventEmitter();
        this.currentPage = 0;
        this.range = {
            min: 0,
            max: 0
        };
    }
    PaginationNavigation.prototype.ngOnInit = function () {
        this.range = this.getRange();
    };
    PaginationNavigation.prototype.ngOnChanges = function (changes) {
        this.range = this.getRange();
        if (this.range.min >= this.totalRecords) {
            this.previousPage();
        }
    };
    PaginationNavigation.prototype.getRange = function () {
        var min = this.currentPage * this.itemsPerPage;
        var max = (this.currentPage + 1) * this.itemsPerPage;
        if (max > this.totalRecords) {
            max = this.totalRecords;
        }
        return { min: min, max: max };
    };
    PaginationNavigation.prototype.previousPage = function () {
        if (this.range.min > 0) {
            this.currentPage--;
            this.range = this.getRange();
            this.onChange.emit();
        }
    };
    PaginationNavigation.prototype.nextPage = function () {
        if (this.range.max < this.totalRecords) {
            this.currentPage++;
            this.range = this.getRange();
            this.onChange.emit();
        }
    };
    return PaginationNavigation;
}());
export { PaginationNavigation };
