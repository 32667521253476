var CsvExportService = /** @class */ /*@__PURE__*/ (function () {
    function CsvExportService() {
    }
    CsvExportService.prototype.exportCSV = function (csvString, fileName) {
        var csvData = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        var csvURL = window.URL.createObjectURL(csvData);
        var tempLink = document.createElement('a');
        tempLink.href = csvURL;
        tempLink.setAttribute('download', fileName + ".csv");
        tempLink.click();
    };
    return CsvExportService;
}());
export { CsvExportService };
