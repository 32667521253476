<ion-card class="photo-scrollview">
  <ion-card-header>
    Photos
  </ion-card-header>
  <ion-card-content *ngIf="photoUris && photoUris.length">
    <div class="photo-scrollview--gallery">
      <img *ngFor="let photoUri of photoUris"
           [src]="photoUri"
           onError="this.src=''">
    </div>
  </ion-card-content>
  <ion-item *ngIf="!photoUris || !photoUris.length"
            class="empty-card">
    <ion-icon name="photo"
              material-icons
              circle
              small
              color="white"
              item-left></ion-icon>
    Add photos
  </ion-item>
</ion-card>