import { LOG_LEVELS } from '@apto/services/constants';
import { Stacktrace } from './stacktrace';
/*
* This class has to be EXTRA CAREFUL with handling exceptions because
* if we don't handle them correctly you basically won't get logs.
*/
var LogentriesHandler = /** @class */ /*@__PURE__*/ (function () {
    function LogentriesHandler(authService, platform, token) {
        this.authService = authService;
        this.platform = platform;
        this.token = token;
        this._platformInfo = ''; // tslint:disable-line variable-name
        try {
            LE.init({
                token: this.token,
                catchall: true
            });
        }
        catch (ex) {
            // do nothing if init fails.
            // the most common cause will be that a parent app already called init with the same token
        }
    }
    // supported log methods: log | info | warn | error
    LogentriesHandler.prototype.log = function (level, message, context) {
        var stack = (level === LOG_LEVELS.EXCEPTION && context) ? Stacktrace.extract(context) : null;
        var stringLevel = LOG_LEVELS[level];
        var messageObject = {
            isDesktop: true,
            url: window.location.href,
            userId: this.getUserId(),
            orgId: this.getOrgId(),
            level: stringLevel,
            platform: this.getPlatformInfo(),
            stack: null,
            message: message
        };
        if (stack) {
            messageObject.stack = stack;
        }
        switch (level) {
            case LOG_LEVELS.INFO:
                LE.info(messageObject);
                break;
            case LOG_LEVELS.ERROR:
            case LOG_LEVELS.EXCEPTION:
                LE.error(messageObject);
                break;
            default:
                LE.log(messageObject);
                break;
        }
    };
    LogentriesHandler.prototype.getPlatformInfo = function () {
        var _this = this;
        try {
            if (!this._platformInfo) {
                var platformInfo_1 = [];
                this.platform.platforms().forEach(function (platform) {
                    var version = _this.platform.versions()[platform];
                    if (version) {
                        platformInfo_1.push(platform + ": " + version.str);
                    }
                });
                this._platformInfo = platformInfo_1.join(', ');
            }
            return this._platformInfo;
        }
        catch (e) {
            return e;
        }
    };
    LogentriesHandler.prototype.getUserId = function () {
        return this.authService.user ? this.authService.user.userId : '';
    };
    LogentriesHandler.prototype.getOrgId = function () {
        return this.authService.user ? this.authService.user.orgId : '';
    };
    return LogentriesHandler;
}());
export { LogentriesHandler };
