import { __extends } from "tslib";
import { Animation, PageTransition } from 'ionic-angular';
var ModalMDSlideIn = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ModalMDSlideIn, _super);
    function ModalMDSlideIn() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ModalMDSlideIn.prototype.init = function () {
        _super.prototype.init.call(this);
        var ele = this.enteringView.pageRef().nativeElement;
        var backdrop = new Animation(this.plt, ele.querySelector('ion-backdrop'));
        var wrapper = new Animation(this.plt, ele.querySelector('.modal-wrapper'));
        backdrop.fromTo('opacity', 0.01, 0.75);
        wrapper.fromTo('translateY', '40px', '0px');
        wrapper.fromTo('opacity', 0.01, 1);
        var DURATION = 280;
        var EASING = 'cubic-bezier(0.36,0.66,0.04,1)';
        this.element(this.enteringView.pageRef()).easing(EASING).duration(DURATION)
            .add(backdrop)
            .add(wrapper);
    };
    return ModalMDSlideIn;
}(PageTransition));
export { ModalMDSlideIn };
var ModalMDSlideOut = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ModalMDSlideOut, _super);
    function ModalMDSlideOut() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ModalMDSlideOut.prototype.init = function () {
        _super.prototype.init.call(this);
        var ele = this.leavingView.pageRef().nativeElement;
        var backdrop = new Animation(this.plt, ele.querySelector('ion-backdrop'));
        var wrapper = new Animation(this.plt, ele.querySelector('.modal-wrapper'));
        backdrop.fromTo('opacity', 0.75, 0.0);
        wrapper.fromTo('translateY', '0px', '40px');
        wrapper.fromTo('opacity', 0.99, 0);
        this
            .element(this.leavingView.pageRef())
            .duration(200)
            .easing('cubic-bezier(0.47,0,0.745,0.715)')
            .add(wrapper)
            .add(backdrop);
    };
    return ModalMDSlideOut;
}(PageTransition));
export { ModalMDSlideOut };
