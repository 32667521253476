<ion-header class="modal-header pursuit-details-modal--header">
    <ion-navbar color="white">
        <ion-title *ngIf="mode === Modes.VIEW">Pursuit details</ion-title>
        <ion-title *ngIf="mode === Modes.EDIT && !selectedRecord.name">Add a new pursuit</ion-title>
        <ion-title *ngIf="mode === Modes.EDIT && selectedRecord.name">Edit pursuit</ion-title>

        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton()"
                    data-automation="modalBackButton">
                <ion-icon name="arrow-back"></ion-icon>
            </button>
        </ion-buttons>

        <ion-buttons end>
            <button *ngIf="mode === Modes.VIEW && selectedRecord.ownerId === appService.salesforceUserId"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="setMode(Modes.EDIT)"
                    [disabled]="isLoadingRelationships"
                    data-automation="modal-edit-button">
                Edit
            </button>
            <button *ngIf="mode === Modes.EDIT && selectedRecord._id"
                    ion-button
                    clear
                    color="danger"
                    hold-button
                    (hold)="delete()"
                    data-automation="modal-delete-button">
                Hold to Delete
            </button>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismiss()"
                    data-automation="modal-dismiss-button">
                <ion-icon name="close"></ion-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal pipeline-slide-modal">
    <ion-slides (ionSlideWillChange)="scrollToTop()">
        <ion-slide>
            <ion-content>
                <ion-card *ngIf="selectedRecord.property">
                    <ion-grid class="pipeline-slide-modal--property-item">
                        <ion-row>
                            <ion-col col-7>
                                <prop-item [property]="selectedRecord.property"
                                           [isPhotoBannerShown]="false"
                                           [selectedRecord]="selectedRecord"
                                           [isShownOnMap]="false"
                                           (onClick)="viewProperty(selectedRecord.property)">
                                </prop-item>
                            </ion-col>
                            <ion-col col-5>
                                <am-apto-static-map *ngIf="selectedRecord.property && selectedRecord.property.addressString"
                                                 size="390x130"
                                                 scale="2"
                                                 markers="{{selectedRecord.property.addressString.trim()}}"
                                                 class="property-overview--static-map">
                                </am-apto-static-map>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-card>
                <ion-grid>
                    <ion-row>
                        <ion-col col-7
                                 class="pipeline-slide-modal--wide-column">
                            <contact-tasks-card [tasks]="tasks | filterByTaskType:'open' | filterByOwnerId: appService.salesforceUserId"
                                                [sliceLength]="3"
                                                [isInitializing]="isInitializingTasks"
                                                (onTaskCompleted)="toggleTask($event.task)"
                                                (onAddTask)="showAddTask()"
                                                (onClick)="viewTask($event.task)"
                                                (onViewMoreOpenTasks)="viewAllTasks()"></contact-tasks-card>
                            <activity-history-card [activities]="tasks | filterByTaskType:'closed'"
                                                   [sliceLength]="3"
                                                   [isInitializing]="isInitializingTasks"
                                                   (onAddActivity)="showAddActivity()"
                                                   (onViewActivity)="viewActivity($event.activity)"
                                                   (onViewAllActivities)="viewAllActivities()"></activity-history-card>
                            <documents-card [pipelineObject]="selectedRecord"></documents-card>
                        </ion-col>

                        <ion-col col-5>
                            <client-card [pipelineObject]="selectedRecord"
                                         (onClickCompany)="viewCompany($event)"
                                         (onClickContact)="viewContact($event)"></client-card>
                            <deal-team-card [isInitializingDealParties]="isInitializingDealParties"
                                            [dealParties]="dealParties"
                                            [selectedRecord]="selectedRecord"
                                            (onSelect)="viewContact($event)"></deal-team-card>
                            <additional-information-card [pipelineObject]="selectedRecord"></additional-information-card>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-content>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide">
            <ion-content>
                <edit-pursuit-form [pipelineObject]="selectedRecord"></edit-pursuit-form>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>

<ion-footer class="modal-footer">
    <ion-buttons start>
        <button ion-button
                color="danger"
                (click)="dismiss()"
                clear>CLOSE</button>
        <button ion-button
                icon-only
                color="primary-blue"
                (click)="scrollToTop()"
                clear>RETURN TO TOP</button>
    </ion-buttons>
</ion-footer>
