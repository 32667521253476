<ion-card class="contact-tasks-card">
    <ion-card-header>
        Open Tasks
    </ion-card-header>

    <ion-card-content *ngIf="!isInitializing"
                      no-padding>
        <ion-list *ngIf="tasks.length"
                  [ngClass]="{ 'no-click': onClick.observers.length == 0}">
            <task-item *ngFor="let task of tasks | sortByTaskDueDate | slice:0:sliceLength"
                       [task]="task"
                       (onToggleTask)="editEnabled ? markTaskComplete(task) : null"
                       (onClick)="view(task)"></task-item>
        </ion-list>

        <ion-item *ngIf="!tasks.length"
                  class="empty-card">
            <ion-icon name="checkmark"
                      circle
                      small
                      color="white"
                      class="grey-circle"
                      item-left></ion-icon>
            Add tasks
        </ion-item>

        <ion-row class="card-footer-buttons">
            <ion-col *ngIf="tasks.length && tasks.length > 1 && onViewMoreOpenTasks.observers.length > 0">
                <button *ngIf="editEnabled && tasks.length > 3"
                        ion-button
                        clear
                        (click)="viewMore();">
                    VIEW ALL
                </button>
            </ion-col>
            <ion-col text-right>
                <button *ngIf="editEnabled"
                        ion-button
                        clear
                        (click)="addTask();">
                    ADD TASK
                </button>
            </ion-col>
        </ion-row>
    </ion-card-content>

    <ion-card-content *ngIf="isInitializing">
        <busy-panel message="Loading tasks ..."></busy-panel>
    </ion-card-content>
</ion-card>
