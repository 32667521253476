import { PipeTransform } from '@angular/core';
import * as moment from 'moment';
var DateFormat = /** @class */ /*@__PURE__*/ (function () {
    function DateFormat() {
    }
    DateFormat.prototype.transform = function (date, format) {
        if (!date) {
            return date;
        }
        format = format || 'M/D/Y';
        var newDate = moment(date).format(format);
        if (newDate === 'Invalid date') {
            return date;
        }
        else {
            return moment(date).format(format);
        }
    };
    return DateFormat;
}());
export { DateFormat };
