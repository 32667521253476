import { ModuleWithProviders } from '@angular/core';
var AutoCompleteModule = /** @class */ /*@__PURE__*/ (function () {
    function AutoCompleteModule() {
    }
    AutoCompleteModule.forRoot = function () {
        return {
            ngModule: AutoCompleteModule,
            providers: []
        };
    };
    return AutoCompleteModule;
}());
export { AutoCompleteModule };
