import { EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { TaskModel } from '@apto/models';
import './task-type-selector.scss';
var TaskTypeSelector = /** @class */ /*@__PURE__*/ (function () {
    function TaskTypeSelector() {
        this.onChange = new EventEmitter();
        this.TaskModel = TaskModel; // tslint:disable-line variable-name
        this.propagateChange = function (_) { };
    }
    TaskTypeSelector.prototype.setTaskType = function (type) {
        this.selectedType = type;
        this.propagateChange(type);
        this.onChange.emit(type);
    };
    TaskTypeSelector.prototype.registerOnChange = function (fn) {
        this.propagateChange = fn;
    };
    TaskTypeSelector.prototype.registerOnTouched = function (fn) {
        // no handler needed for touch events
    };
    TaskTypeSelector.prototype.writeValue = function (type) {
        this.selectedType = type;
    };
    return TaskTypeSelector;
}());
export { TaskTypeSelector };
