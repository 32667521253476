import * as moment from 'moment';
import { ElementRef, SimpleChanges } from '@angular/core';
import { TextInput } from 'ionic-angular';
import { AutoCompleteComponent } from '@apto/autocomplete';
import { TaskModel, ContactModel, ListingModel } from '@apto/models';
import { AppService, FeaturesService, TaskFormsService } from '@apto/services';
import { GlobalSearch } from '@apto/shared/components';
import './edit-activity-form.scss';
var EditActivityForm = /** @class */ /*@__PURE__*/ (function () {
    function EditActivityForm(featuresService, taskFormsService, appService) {
        this.featuresService = featuresService;
        this.taskFormsService = taskFormsService;
        this.appService = appService;
        this.allowEditContact = true;
        this.TaskModel = TaskModel; // tslint:disable-line
        this.useDefaultActivityName = true;
        this.isSaving = false;
        this.marketingStatuses = Object.keys(TaskModel.MARKETING_STATUSES).map(function (key) { return TaskModel.MARKETING_STATUSES[key]; });
        this.tomorrow = moment().add(1, 'day');
        this.datepickerOptions = {
            alignSelectorRight: true,
            disableSince: {
                year: this.tomorrow.year(),
                month: this.tomorrow.month() + 1,
                day: this.tomorrow.date()
            },
            openSelectorTopOfInput: true,
            showSelectorArrow: false
        };
    }
    Object.defineProperty(EditActivityForm.prototype, "relatedContact", {
        get: function () {
            return this._relatedContact;
        },
        set: function (value) {
            if (this._relatedContact !== value) {
                this._relatedContact = value;
                this.updateContactSearchbarValue();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditActivityForm.prototype.ngOnInit = function () {
        this.setupForm();
        if (!this.form.controls.dueDate.value) {
            this.setInitialDate();
        }
        if (!this.form.controls.subject.value) {
            this.setDefaultName();
        }
        if (!this.form.controls.ownerName.value) {
            this.setUser({
                _id: this.appService.salesforceUserId,
                fullName: this.appService.userDisplayName,
            });
        }
        this.form.patchValue({ contactId: this.relatedContact && this.relatedContact._id || '' });
        if (this.activity.whatId && this.activity.whatName) {
            this.globalSearch.setSearchValue(this.activity.whatName);
        }
        else if (this.relatedListing && this.relatedListing._id) {
            this.form.patchValue({ whatId: this.relatedListing._id });
            this.globalSearch.setSearchValue(this.relatedListing.name);
        }
    };
    EditActivityForm.prototype.ngOnChanges = function (changes) {
        if (changes.activity &&
            changes.activity.previousValue &&
            changes.activity.previousValue._id !== changes.activity.currentValue._id) {
            this.setupForm();
        }
    };
    EditActivityForm.prototype.setupForm = function () {
        this.form = this.taskFormsService.createActivityFormGroup(this.activity);
    };
    EditActivityForm.prototype.setUser = function (user) {
        this.activity.ownerId = user._id;
        this.activity.ownerName = user.fullName;
        this.form.patchValue({ ownerName: this.activity.ownerName });
    };
    EditActivityForm.prototype.save = function () {
        var _this = this;
        if (this.form.valid) {
            var activity = this.taskFormsService.createActivityModel(this.form);
            activity.ownerId = this.activity.ownerId;
            return { activity: activity };
        }
        else {
            Object.keys(this.form.controls).forEach(function (fieldKey) {
                _this.form.controls[fieldKey].markAsDirty();
            });
        }
    };
    EditActivityForm.prototype.editActivityName = function () {
        this.useDefaultActivityName = false;
    };
    Object.defineProperty(EditActivityForm.prototype, "showResultOptions", {
        get: function () {
            return this.form.get('type').value === this.TaskModel.TYPE.CALL;
        },
        enumerable: true,
        configurable: true
    });
    EditActivityForm.prototype.selectMarketingStatus = function (marketingStatus) {
        this.form.patchValue({ marketingStatus: marketingStatus });
    };
    EditActivityForm.prototype.setDefaultName = function () {
        if (!this.form) {
            return;
        }
        var taskType = this.form.get('type').value;
        this.form.patchValue({
            callResult: taskType === TaskModel.TYPE.CALL ? TaskModel.RESULT.REACHED : undefined,
        });
        // set a default name if we're creating a new task and useDefaultActivityName is true
        if (this.useDefaultActivityName && taskType && !this.form.controls.id.value
            && this.relatedContact && this.relatedContact.firstName) {
            var taskName = '';
            switch (taskType) {
                case TaskModel.TYPE.MEETING:
                case TaskModel.TYPE.TOUR:
                    taskName = taskType + " with " + this.relatedContact.firstName;
                    break;
                case TaskModel.TYPE.OTHER:
                    taskName = "Task with " + this.relatedContact.firstName;
                    break;
                default:
                    taskName = taskType + " " + this.relatedContact.firstName;
                    break;
            }
            this.form.patchValue({ subject: taskName });
        }
    };
    EditActivityForm.prototype.setRelatedContact = function (contact) {
        this.relatedContact = contact;
        this.form.patchValue({ contactId: contact && contact._id || '' });
    };
    EditActivityForm.prototype.clearRelatedObject = function () {
        this.form.patchValue({ whatId: null });
    };
    EditActivityForm.prototype.setRelatedObject = function (object) {
        this.globalSearch.setSearchValue(object.item.name);
        this.form.patchValue({ whatId: object.item && object.item._id || '' });
    };
    EditActivityForm.prototype.setInitialDate = function () {
        var dueDate = moment();
        this.form.patchValue({
            dueDate: dueDate.toDate()
        });
    };
    EditActivityForm.prototype.scrollToBottom = function () {
        this.formContent.nativeElement.scrollIntoView(false);
    };
    EditActivityForm.prototype.checkForBlank = function (input) {
        if (input === '') {
            this.form.patchValue({ contactId: '' });
        }
    };
    EditActivityForm.prototype.updateContactSearchbarValue = function () {
        this.contactSearchbar.setValue(this.relatedContact && this.relatedContact.fullName || '');
    };
    return EditActivityForm;
}());
export { EditActivityForm };
