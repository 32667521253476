import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, Injector } from '@angular/core';
import { CallListModel, ContactModel, TaskModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
import { ContactsService } from '../contacts/contacts-service';
import { TasksService } from '../tasks/tasks-service';
import { AlertController } from 'ionic-angular';
var CallListsService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(CallListsService, _super);
    function CallListsService(injector, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.alertController = alertController;
        _this.onMembersAdded = new EventEmitter();
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.CALL_LISTS;
        _this.model = CallListModel;
        _this.serviceName = SERVICE_NAMES.CALL_LISTS;
        _this.sortByName = function (a, b) {
            var aName = a.contact && a.contact.fullName && a.contact.fullName.toLowerCase() || '';
            var bName = b.contact && b.contact.fullName && b.contact.fullName.toLowerCase() || '';
            if (aName < bName) {
                return -1;
            }
            else if (aName > bName) {
                return 1;
            }
            return 0;
        };
        _this.sortMembers = function (a, b) {
            // ascending sort with empty dates at beginning
            // secondary sort on contact name
            if (!a.callResult && !b.callResult) {
                return _this.sortByName(a, b);
            }
            else if (!a.callResult) {
                return -1;
            }
            else if (!b.callResult) {
                return 1;
            }
            else if (a.callResult < b.callResult) {
                return -1;
            }
            else if (a.callResult > b.callResult) {
                return 1;
            }
            return _this.sortByName(a, b);
        };
        _this.contactsService = _this.injector.get(ContactsService);
        _this.tasksService = _this.injector.get(TasksService);
        return _this;
    }
    CallListsService.prototype.byId = function (id, cacheBust) {
        if (cacheBust === void 0) {
            cacheBust = false;
        }
        return __awaiter(this, void 0, void 0, function () {
            var cachedCallList, result, callList;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!cacheBust) {
                            cachedCallList = this.cacheService.get(this.serviceName, id);
                            if (cachedCallList) {
                                return [2 /*return*/, cachedCallList];
                            }
                        }
                        return [4 /*yield*/, this.request('get', "/" + id)];
                    case 1:
                        result = _a.sent();
                        callList = new CallListModel(result);
                        this.cacheService.add(this.serviceName, callList._id, callList);
                        return [2 /*return*/, callList];
                }
            });
        });
    };
    CallListsService.prototype.getPending = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var url, errorMessage, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.endpoint + "/pending";
                        errorMessage = "Failed to get pending for service " + this.serviceName + ".";
                        return [4 /*yield*/, this.http.get(url)
                                .toPromise()
                                .catch(function (ex) {
                                _this.onLogException.emit({ message: errorMessage, ex: ex });
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.status === 'error') {
                            throw new Error(result);
                        }
                        return [2 /*return*/, result.data.map(function (createdCallList) {
                                var callList = new _this.model(createdCallList);
                                _this.cacheService.add(_this.serviceName, callList._id, callList);
                                return callList;
                            })];
                }
            });
        });
    };
    CallListsService.prototype.bulkInsertMembers = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var members, url, errorMessage, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        members = params.models;
                        url = this.endpoint + "/" + params.callListId + "/" + END_POINTS.BULK_INSERT_MEMBERS;
                        errorMessage = "Failed to bulk insert call list members for call list: " + params.callListId + ".";
                        return [4 /*yield*/, this.http.post(url, { members: members })
                                .toPromise()
                                .then(function (res) {
                                _this.analyticsService.logEvent("Bulk Inserted " + _this.serviceName);
                                if (!res['data'].batchInfo) {
                                    _this.alertGroupSaveFailed();
                                }
                                ;
                                return res;
                            })
                                .catch(function (ex) {
                                _this.onLogException.emit({ message: errorMessage, ex: ex });
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.status === 'error') {
                            throw new Error(result);
                        }
                        this.startPolling();
                        return [2 /*return*/, result.data];
                }
            });
        });
    };
    CallListsService.prototype.listenForContactUpdate = function () {
        var _this = this;
        this.contactsService.onUpserted.subscribe(function (event) {
            var contact = event.payload;
            if (contact) {
                _this.cacheService.getAll(_this.serviceName).forEach(function (callList) {
                    if (!callList.isTaskList) {
                        var memberIdx = callList.members.findIndex(function (member) {
                            return member.contactId === contact._id;
                        });
                        if (callList.members[memberIdx] && callList.members[memberIdx].contact) {
                            _this.updateMemberWithContact(callList, memberIdx, contact);
                        }
                    }
                });
            }
        }, function (ex) {
            _this.onLogException.emit({ message: 'Failed to update calllist contact.', ex: ex });
        });
    };
    CallListsService.prototype.updateMemberWithContact = function (callList, memberIdx, contact) {
        Object.keys(callList.members[memberIdx].contact).forEach(function (prop) {
            callList.members[memberIdx].contact[prop] = contact[prop];
        });
    };
    CallListsService.prototype.attachContactsToCallListTasks = function (callList) {
        var contactIds = callList.tasks.map(function (activity) {
            return activity.contactId;
        });
        var uniqueContactIds = Array.from(new Set(contactIds));
        return this.contactsService.in(uniqueContactIds)
            .then(function (contacts) {
            callList.tasks.forEach(function (activity) {
                activity.contact = contacts.find(function (contact) { return activity.contactId === contact._id; });
            });
            return callList;
        });
    };
    CallListsService.prototype.getDealLists = function (params, cacheBust) {
        if (cacheBust === void 0) {
            cacheBust = false;
        }
        return __awaiter(this, void 0, void 0, function () {
            var cachedResults;
            return __generator(this, function (_a) {
                if (!params && !cacheBust) {
                    cachedResults = this.cacheService
                        .getAll(this.serviceName)
                        .filter(function (callList) { return callList.type === CallListModel.TYPE.DEAL_LIST; });
                    if (cachedResults && cachedResults.length) {
                        return [2 /*return*/, cachedResults];
                    }
                }
                params = params || {};
                params.where = params.where || {};
                params.where.type = CallListModel.TYPE.DEAL_LIST;
                return [2 /*return*/, this.fetch(params, false)];
            });
        });
    };
    CallListsService.prototype.slim = function (params, cacheBust) {
        if (cacheBust === void 0) {
            cacheBust = false;
        }
        return __awaiter(this, void 0, void 0, function () {
            var cachedResults;
            return __generator(this, function (_a) {
                if (!params && !cacheBust) {
                    cachedResults = this.cacheService.getAll(this.serviceName);
                    if (cachedResults && cachedResults.length) {
                        return [2 /*return*/, cachedResults];
                    }
                }
                params = params || {};
                params.slim = true;
                return [2 /*return*/, this.fetch(params, false)];
            });
        });
    };
    CallListsService.prototype.in = function (ids) {
        var _this = this;
        return Promise.all(ids.map(function (id) { return _this.byId(Number(id)); }));
    };
    CallListsService.prototype.upsert = function (callList) {
        return __awaiter(this, void 0, void 0, function () {
            var xhrModel, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        xhrModel = Object.assign({}, callList);
                        delete xhrModel.members;
                        return [4 /*yield*/, _super.prototype.upsert.call(this, xhrModel, false)];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, Object.assign(callList, result)];
                }
            });
        });
    };
    CallListsService.prototype.process = function (result, cacheResults) {
        if (cacheResults === void 0) {
            cacheResults = true;
        }
        var callLists = _super.prototype.process.call(this, result, cacheResults);
        return callLists.map(function (callList) {
            if (callList.members) {
                callList.members = callList.members.map(function (groupMember) {
                    groupMember.contact = new ContactModel(groupMember.contact);
                    return groupMember;
                });
            }
            return callList;
        });
    };
    CallListsService.prototype.sortMembersByMarketStatus = function (callList) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var relatedTasks;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        callList.prospects = [];
                        callList.contacted = [];
                        callList.materialsSent = [];
                        callList.toured = [];
                        callList.offered = [];
                        return [4 /*yield*/, this.tasksService.byRelatedId(callList.configJson.listingId)];
                    case 1:
                        relatedTasks = _a.sent();
                        if (relatedTasks.length) {
                            callList.members.forEach(function (member) {
                                _this.sortMemberByMarketStatus(callList, relatedTasks, member);
                            });
                        }
                        else {
                            callList.prospects = callList.members;
                        }
                        callList.members = this.sortMembersForCallList(callList);
                        return [2 /*return*/, callList];
                }
            });
        });
    };
    CallListsService.prototype.addMembersToList = function (callList, contacts) {
        var contactGroupMemberInfo = contacts.map(function (contact) {
            return ({
                contactId: contact._id,
                contactGroupId: callList.contactGroupId
            });
        });
        return this.bulkInsertMembers({ models: contactGroupMemberInfo, callListId: callList._id });
    };
    CallListsService.prototype.sortMembersForCallList = function (callList) {
        return callList.members ? callList.members.sort(this.sortMembers) : callList.members;
    };
    CallListsService.prototype.alertGroupSaveFailed = function () {
        this.alertController.create({
            title: 'Attention',
            subTitle: 'Call list has no associated group of contacts. Changes cannot be applied.',
            buttons: [
                { role: 'OK', text: 'OK' },
            ],
            cssClass: 'alert-massage-info',
        }).present();
    };
    CallListsService.prototype.sortMemberByMarketStatus = function (callList, tasks, member) {
        var activitesForContact = tasks.filter(function (task) {
            return task.isComplete &&
                task.contactId === member.contactId &&
                task.marketingStatus;
        });
        if (activitesForContact.length > 0) {
            var lastMarketingTask = this.tasksService.latestMarketingTask(activitesForContact);
            switch (lastMarketingTask.marketingStatus) {
                case TaskModel.MARKETING_STATUSES.CONTACTED:
                    member.lastMarketingTask = lastMarketingTask;
                    callList.contacted.push(member);
                    break;
                case TaskModel.MARKETING_STATUSES.MATERIALS_SENT:
                    member.lastMarketingTask = lastMarketingTask;
                    callList.materialsSent.push(member);
                    break;
                case TaskModel.MARKETING_STATUSES.TOURED:
                    member.lastMarketingTask = lastMarketingTask;
                    callList.toured.push(member);
                    break;
                case TaskModel.MARKETING_STATUSES.OFFERED:
                    member.lastMarketingTask = lastMarketingTask;
                    callList.offered.push(member);
                    break;
                default:
                    callList.prospects.push(member);
            }
        }
        else {
            callList.prospects.push(member);
        }
    };
    CallListsService.prototype.pollPendingCallLists = function (allCallLists) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var previousPendingCallLists, stillPendingCallLists, promises;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        previousPendingCallLists = (allCallLists || []).filter(function (callList) { return callList.isPending; });
                        return [4 /*yield*/, this.getPending()];
                    case 1:
                        stillPendingCallLists = _a.sent();
                        promises = previousPendingCallLists.map(function (callList) {
                            return __awaiter(_this, void 0, void 0, function () {
                                var stillPendingCallList;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            stillPendingCallList = stillPendingCallLists.find(function (pending) { return pending._id === callList._id; });
                                            if (!!stillPendingCallList)
                                                return [3 /*break*/, 3];
                                            return [4 /*yield*/, this.byId(callList._id, true)];
                                        case 1:
                                            _a.sent();
                                            return [4 /*yield*/, this.sortMembersByMarketStatus(callList)];
                                        case 2:
                                            _a.sent();
                                            return [3 /*break*/, 5];
                                        case 3:
                                            if (!(stillPendingCallList.batchInfo === null))
                                                return [3 /*break*/, 5];
                                            return [4 /*yield*/, this.sortMembersByMarketStatus(callList)];
                                        case 4:
                                            _a.sent();
                                            _a.label = 5;
                                        case 5: return [2 /*return*/];
                                    }
                                });
                            });
                        });
                        return [4 /*yield*/, Promise.all(promises)];
                    case 2:
                        _a.sent();
                        this.finishPolling();
                        if (previousPendingCallLists.length !== 0) {
                            this.startPolling();
                        }
                        if (!(stillPendingCallLists.length === 0))
                            return [3 /*break*/, 4];
                        return [4 /*yield*/, this.onMembersAdded.emit()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    CallListsService.prototype.startPolling = function () {
        if (!this.pollPendingTimer) {
            this.pollPendingTimer = setTimeout(this.pollPendingCallLists.bind(this), 4000);
        }
    };
    CallListsService.prototype.finishPolling = function () {
        clearTimeout(this.pollPendingTimer);
        this.pollPendingTimer = undefined;
    };
    return CallListsService;
}(BaseService));
export { CallListsService };
