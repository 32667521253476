import './additional-information-card.scss';
var AdditionalInformationCard = /** @class */ /*@__PURE__*/ (function () {
    function AdditionalInformationCard() {
        this.pipelineObject = {};
    }
    Object.defineProperty(AdditionalInformationCard.prototype, "showCard", {
        get: function () {
            return (this.pipelineObject.askingPrice ||
                this.pipelineObject.askingPriceActual ||
                this.pipelineObject.brokerProposedPrice ||
                this.pipelineObject.buyerAttorneyContact ||
                this.pipelineObject.buyerLoanContact ||
                this.pipelineObject.capRate ||
                this.pipelineObject.cashOnCash ||
                this.pipelineObject.closeDate ||
                this.pipelineObject.commissionAmount ||
                this.pipelineObject.commissionPercent ||
                this.pipelineObject.contractCloseDate ||
                this.pipelineObject.contractPrice ||
                this.pipelineObject.dateOffMarket ||
                this.pipelineObject.dateOnMarket ||
                this.pipelineObject.deposit ||
                this.pipelineObject.downPayment ||
                this.pipelineObject.effectiveDate ||
                this.pipelineObject.escalations ||
                this.pipelineObject.expirationDate ||
                this.pipelineObject.leaseExpirationDate ||
                this.pipelineObject.leaseCommencementDate ||
                this.pipelineObject.leaseTotal ||
                this.pipelineObject.leaseTermMonths ||
                this.pipelineObject.leaseType ||
                this.pipelineObject.market ||
                this.pipelineObject.maxLeaseRate ||
                this.pipelineObject.maxPrice ||
                this.pipelineObject.maxSqft ||
                this.pipelineObject.minCapRate ||
                this.pipelineObject.minCashOnCash ||
                this.pipelineObject.minIRR ||
                this.pipelineObject.minLIRR ||
                this.pipelineObject.minLeaseRate ||
                this.pipelineObject.minPrice ||
                this.pipelineObject.minSqft ||
                this.pipelineObject.mortgageAmount ||
                this.pipelineObject.needDate ||
                this.pipelineObject.netOperatingIncome ||
                this.pipelineObject.noi ||
                this.pipelineObject.occupancyDate ||
                this.pipelineObject.options ||
                this.pipelineObject.priceType ||
                this.pipelineObject.probability ||
                this.pipelineObject.pursuitDeliveryDate ||
                this.pipelineObject.rentCommencementDate ||
                this.pipelineObject.rentalRate ||
                this.pipelineObject.rentalRateType ||
                this.pipelineObject.repAgreementDate ||
                this.pipelineObject.salePrice ||
                this.pipelineObject.sellerAttorneyContact ||
                this.pipelineObject.sellerPriceExpectation ||
                this.pipelineObject.spaceSubType ||
                this.pipelineObject.spaceType ||
                this.pipelineObject.squareFootage ||
                this.pipelineObject.subMarket ||
                this.pipelineObject.titleCompanyAttorneyContact ||
                this.pipelineObject.totalReturn ||
                this.pipelineObject.yield);
        },
        enumerable: true,
        configurable: true
    });
    return AdditionalInformationCard;
}());
export { AdditionalInformationCard };
