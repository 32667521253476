var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, SimpleChanges } from '@angular/core';
import { CallListModel } from '@apto/models';
import { CallListsService, ContactGroupMembersService } from '@apto/services';
import './current-lists-card.scss';
import { AlertController } from 'ionic-angular';
var CurrentListsCard = /** @class */ /*@__PURE__*/ (function () {
    function CurrentListsCard(callListsService, contactGroupMembersService, alertController) {
        var _this = this;
        this.callListsService = callListsService;
        this.contactGroupMembersService = contactGroupMembersService;
        this.alertController = alertController;
        this.contacts = [];
        this.editEnabled = true;
        this.removeFromList = new EventEmitter();
        this.viewList = new EventEmitter();
        this.onAddList = new EventEmitter();
        this.onUpdateList = new EventEmitter();
        this.onStartUpdateList = new EventEmitter();
        this.callLists = [];
        this.currentCallLists = [];
        this.currentContacts = [];
        this.subscriptions = [];
        this.emitRemoveFromList = function (event, callList) {
            event.stopPropagation();
            var deletedCallList = _this.currentCallLists.indexOf(callList);
            if (callList.members.length === 1) {
                var alert_1 = _this.createAlert('This call list contains no contacts and will be deleted.');
                alert_1.onDidDismiss(function (_, role) {
                    return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            if (role === 'OK') {
                                this.currentCallLists.splice(deletedCallList, 1);
                                this.removeFromList.emit(callList);
                            }
                            else {
                                return [2 /*return*/];
                            }
                            return [2 /*return*/];
                        });
                    });
                });
                alert_1.present();
            }
            else {
                _this.currentCallLists = _this.currentCallLists.filter(function (cl) { return cl._id !== callList._id; });
                _this.removeFromList.emit(callList);
            }
        };
        this.emitViewList = function (callList) {
            _this.viewList.emit(callList);
        };
    }
    CurrentListsCard.prototype.ngOnInit = function () {
        var _this = this;
        this.isInitializing = true;
        this.subscriptions.push(this.contactGroupMembersService.onDeleted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.onStartUpdateList.emit();
                    this.isInitializing = true;
                    this.startPolling();
                    return [2 /*return*/];
                });
            });
        }), this.callListsService.onUpserted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.onStartUpdateList.emit();
                    this.isInitializing = true;
                    this.startPolling();
                    return [2 /*return*/];
                });
            });
        }), this.callListsService.onDeleted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.callLists = this.callLists.filter(function (cl) { return !data.payload.includes(String(cl._id)); });
                    this.onUpdateList.emit(this.callLists);
                    this.getCurrentCallLists();
                    return [2 /*return*/];
                });
            });
        }));
        this.startPolling();
    };
    CurrentListsCard.prototype.ngOnDestroy = function () {
        clearTimeout(this.pollTimeout);
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    CurrentListsCard.prototype.ngOnChanges = function (changes) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(changes.contacts && changes.contacts.currentValue[0]._id))
                            return [3 /*break*/, 2];
                        this.isInitializing = true;
                        return [4 /*yield*/, this.getCallLists()];
                    case 1:
                        _a.sent();
                        this.startPolling();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    CurrentListsCard.prototype.getCallLists = function (force) {
        if (force === void 0) {
            force = false;
        }
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.callListsService.fetch(undefined, true, force)];
                    case 1:
                        _a.callLists = _b.sent();
                        this.callLists.sort(function (a, b) {
                            return (a.name > b.name) ? 1 : -1;
                        });
                        this.getCurrentCallLists();
                        return [2 /*return*/];
                }
            });
        });
    };
    CurrentListsCard.prototype.getCurrentCallLists = function () {
        var _this = this;
        this.currentCallLists = [];
        this.callLists.forEach(function (callList) {
            var isCallListMember = null;
            if (callList.members) {
                isCallListMember = callList.members.find(function (callListMember) {
                    return callListMember.contactId === _this.contacts[0]._id;
                });
            }
            if (isCallListMember && callList.type === CallListModel.TYPE.CONTACT_GROUP_LIST) {
                _this.currentCallLists.push(callList);
            }
        });
        this.currentContacts = this.contacts.slice();
        this.isInitializing = false;
    };
    CurrentListsCard.prototype.createAlert = function (message) {
        return this.alertController.create({
            title: 'Attention',
            subTitle: message,
            buttons: [
                { role: 'OK', text: 'OK' },
                { role: 'CANCEL', text: 'CANCEL' },
            ],
            cssClass: 'alert-massage-info',
        });
    };
    CurrentListsCard.prototype.addList = function (callList) {
        this.onStartUpdateList.emit();
        this.currentCallLists.push(callList);
        this.startPolling();
    };
    CurrentListsCard.prototype.pollPendingCallLists = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var previousPendingCallLists, stillPendingCallLists, promises;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        previousPendingCallLists = this.callLists.filter(function (callList) { return callList.isPending; });
                        return [4 /*yield*/, this.callListsService.getPending()];
                    case 1:
                        stillPendingCallLists = _a.sent();
                        promises = previousPendingCallLists.map(function (callList) {
                            return __awaiter(_this, void 0, void 0, function () {
                                var stillPendingCallList;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            stillPendingCallList = stillPendingCallLists.find(function (pending) { return pending._id === callList._id; });
                                            if (!!stillPendingCallList)
                                                return [3 /*break*/, 2];
                                            return [4 /*yield*/, this.callListsService.byId(callList._id, true)];
                                        case 1:
                                            _a.sent();
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            });
                        });
                        return [4 /*yield*/, Promise.all(promises)];
                    case 2:
                        _a.sent();
                        this.finishPolling();
                        if (previousPendingCallLists.length !== 0) {
                            this.startPolling();
                        }
                        if (!(previousPendingCallLists.length !== 0 && stillPendingCallLists.length === 0))
                            return [3 /*break*/, 4];
                        this.isInitializing = true;
                        return [4 /*yield*/, this.getCallLists()];
                    case 3:
                        _a.sent();
                        this.onUpdateList.emit(this.callLists);
                        _a.label = 4;
                    case 4:
                        if (!(previousPendingCallLists.length === 0 && stillPendingCallLists.length === 0))
                            return [3 /*break*/, 6];
                        return [4 /*yield*/, this.getCallLists(true)];
                    case 5:
                        _a.sent();
                        this.onUpdateList.emit(this.callLists);
                        this.isInitializing = false;
                        _a.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CurrentListsCard.prototype.startPolling = function () {
        clearTimeout(this.pollTimeout);
        this.pollTimeout = setTimeout(this.pollPendingCallLists.bind(this), 4000);
    };
    CurrentListsCard.prototype.finishPolling = function () {
        clearTimeout(this.pollTimeout);
        this.pollTimeout = undefined;
    };
    return CurrentListsCard;
}());
export { CurrentListsCard };
