import { PipeTransform } from '@angular/core';
var FilterByOwnerIdPipe = /** @class */ /*@__PURE__*/ (function () {
    function FilterByOwnerIdPipe() {
    }
    FilterByOwnerIdPipe.prototype.transform = function (items, ownerId) {
        return items.filter(function (item) { return item.ownerId === ownerId; });
    };
    return FilterByOwnerIdPipe;
}());
export { FilterByOwnerIdPipe };
