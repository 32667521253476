import { PipeTransform } from '@angular/core';
var HoursToCall = /** @class */ /*@__PURE__*/ (function () {
    function HoursToCall() {
    }
    HoursToCall.prototype.transform = function (hours, format) {
        if (!hours || hours === 0) {
            return '0 mins';
        }
        var hoursText = 'hrs';
        var minutesText = 'mins';
        var fullHourText = '';
        var fullMinuteText = '';
        var andText = '';
        var hour = Math.floor(hours);
        var minutes = Math.floor((hours - hour) * 60);
        if (hour >= 1) {
            if (hour === 1) {
                hoursText = 'hr';
            }
            fullHourText = hour + " " + hoursText;
        }
        if (minutes >= 1) {
            if (minutes === 1) {
                minutesText = 'min';
            }
            fullMinuteText = minutes + " " + minutesText;
        }
        if (fullHourText && fullMinuteText) {
            andText = ' and ';
        }
        else {
            andText = '';
        }
        return "About " + fullHourText + andText + fullMinuteText;
    };
    return HoursToCall;
}());
export { HoursToCall };
