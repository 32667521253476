<ion-header class="modal-header lease-modal-header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton();"
                    data-automation="modalBackButton">
                <apto-icon icon="arrowBack" inline size="4"></apto-icon>
            </button>
        </ion-buttons>

        <ion-title *ngIf="mode === Modes.LIST">Leases</ion-title>
        <ion-title *ngIf="mode === Modes.VIEW">Lease details</ion-title>
        <ion-title *ngIf="mode === Modes.EDIT">{{ selectedRecord.comp && selectedRecord.comp._id ? 'Edit' : 'Add' }} lease</ion-title>

        <ion-buttons end>
            <button *ngIf="mode === Modes.VIEW"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="onEditClick()"
                    data-automation="modal-edit-button">
                EDIT
            </button>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    [disabled]="editClosedDealForm.isSaving || editClosedDealForm.closedDealForm.invalid"
                    (click)="save()"
                    data-automation="modal-save-button">
                SAVE
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismiss()"
                    data-automation="modal-dismiss-button">
                <apto-icon icon="close" inline size="4"></apto-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal lease-modal">
    <ion-slides>
        <ion-slide>
            <ion-content>
                <!-- Lease List View -->
            </ion-content>
        </ion-slide>
        <ion-slide>
            <ion-content>
                <ion-card *ngIf="selectedRecord.property">
                    <div
                        class="property-item selectable-item"
                        (click)="viewProperty()">
                        <ion-item
                            [ngClass]="{ 'has-image': selectedRecord.property.blobImageData }"
                            detail-push>
                            <apto-icon item-left circle circleColor="blue" size="5" icon="property"></apto-icon>
                            <ion-thumbnail
                                *ngIf="selectedRecord.property.blobImageData"
                                item-right>
                                <img [src]="selectedRecord.property.blobImageData" alt="property image">
                            </ion-thumbnail>
                            <h2
                                class="property-item--dark-text"
                                data-automation="leased-property-name">{{ selectedRecord.property.name }}</h2>
                            <p data-automation="leased-property-address">{{ selectedRecord.property.addressString }}</p>
                            <span
                                *ngIf="selectedRecord.comp"
                                [innerHTML]="priceAndSqft"
                                data-automation="leased-property-price-sqft"></span>
                        </ion-item>
                    </div>
                </ion-card>
                <ion-grid>
                    <ion-row>
                        <ion-col col-7
                                 class="pipeline-slide-modal--wide-column">
                            <ion-card class="lease-summary-card">
                                <ion-card-header class="lease-summary-card--title">
                                    Lease summary
                                </ion-card-header>
                                <ion-card-content no-padding>
                                    <lease-item [lease]="selectedRecord" [previousLeases]="selectedRecord.previousLeases"></lease-item>
                                </ion-card-content>
                                <ion-row class="card-footer-buttons">
                                    <ion-col>
                                        <button
                                                class="lease-summary-card--toggleDetailsButton"
                                                ion-button
                                                clear
                                                (click)="shouldDisplayLeaseDetails ? closeLeaseDetails() : showLeaseDetails()">
                                            {{ (shouldDisplayLeaseDetails ? 'Hide' : 'Show') + ' Lease Details' }}
                                        </button>
                                    </ion-col>
                                </ion-row>
                                <ion-card-content *ngIf="shouldDisplayLeaseDetails">
                                    <div class="lease-summary-card--details">
                                        <ion-card-header no-padding class="lease-summary-card--title">
                                            Lease details
                                        </ion-card-header>
                                        <form-control
                                            *ngIf="selectedRecord.comp && selectedRecord.comp.suiteOrFloor">
                                            <form-label>Suite / Floor #</form-label>
                                            <form-value class="lease-summary-card--details-suiteOrFloor">
                                                {{ selectedRecord.comp.suiteOrFloor }}
                                            </form-value>
                                        </form-control>
                                        <form-control
                                            *ngIf="selectedRecord.comp && selectedRecord.comp.leaseExpirationDate">
                                            <form-label>Lease expiration date</form-label>
                                            <form-value class="lease-summary-card--details-leaseExpirationDate">
                                                {{ selectedRecord.comp.leaseExpirationDate | dateFormat }}
                                            </form-value>
                                        </form-control>
                                        <form-control
                                            *ngIf="selectedRecord.comp && selectedRecord.comp.rentalRate">
                                            <form-label>Current rental rate</form-label>
                                            <form-value class="lease-summary-card--details-rentalRate">
                                                {{ selectedRecord.comp.rentalRate | currency }} {{ rentalRateTypeMap[selectedRecord.comp.rentalRateType] }}
                                            </form-value>
                                        </form-control>
                                        <form-control *ngIf="selectedRecord.comp && selectedRecord.comp.squareFootage">
                                            <form-label>Square footage</form-label>
                                            <form-value class="lease-summary-card--details-squareFootage">
                                                {{ selectedRecord.comp.squareFootage | numberWithCommas }} sqft
                                            </form-value>
                                        </form-control>
                                        <form-control *ngIf="selectedRecord.comp && selectedRecord.comp.leaseType">
                                            <form-label>Lease type</form-label>
                                            <form-value class="lease-summary-card--details-leaseType">{{ selectedRecord.comp.leaseType }}</form-value>
                                        </form-control>
                                        <form-control *ngIf="selectedRecord.comp && selectedRecord.comp.options">
                                            <form-label>Options</form-label>
                                            <form-value class="lease-summary-card--details-options">{{ selectedRecord.comp.options }}</form-value>
                                        </form-control>
                                        <br />
                                        <form-control *ngIf="selectedRecord.comp && selectedRecord.comp.leaseCommencementDate">
                                            <form-label>Lease commencement date</form-label>
                                            <form-value class="lease-summary-card--details-leaseCommencementDate">
                                                    {{ selectedRecord.comp.leaseCommencementDate | dateFormat }}
                                            </form-value>
                                        </form-control>
                                        <form-control *ngIf="selectedRecord.comp && selectedRecord.comp.occupancyDate">
                                            <form-label>Occupancy date</form-label>
                                            <form-value class="lease-summary-card--details-leaseOccupancyDate">
                                                    {{ selectedRecord.comp.occupancyDate | dateFormat }}
                                            </form-value>
                                        </form-control>
                                        <form-control *ngIf="selectedRecord.comp && selectedRecord.comp.rentCommencementDate">
                                            <form-label>Rent commencement date</form-label>
                                            <form-value  class="lease-summary-card--details-rentCommencementDate">
                                                    {{ selectedRecord.comp.rentCommencementDate | dateFormat }}
                                            </form-value>
                                        </form-control>
                                        <form-control *ngIf="selectedRecord.comp && selectedRecord.comp.leaseTermMonths">
                                            <form-label>Lease term (months)</form-label>
                                            <form-value class="lease-summary-card--details-leaseTermMonths">
                                                {{ selectedRecord.comp.leaseTermMonths }}
                                            </form-value>
                                        </form-control>
                                        <form-control *ngIf="selectedRecord.comp && selectedRecord.comp.leaseTotal">
                                            <form-label>Lease total</form-label>
                                            <form-value class="lease-summary-card--details-leaseTotal">{{ selectedRecord.comp.leaseTotal | currency }}</form-value>
                                        </form-control>
                                        <form-control *ngIf="selectedRecord.comp && selectedRecord.comp.escalations">
                                            <form-label>Escalations</form-label>
                                            <form-value class="lease-summary-card--details-escalations">{{ selectedRecord.comp.escalations }}</form-value>
                                        </form-control>
                                    </div>
                                </ion-card-content>
                            </ion-card>
                            <contact-tasks-card [tasks]="tasks | filterByTaskType:'open' | filterByOwnerId: appService.salesforceUserId"
                                                [sliceLength]="3"
                                                [isInitializing]="isInitializingTasks"
                                                (onTaskCompleted)="toggleTask($event.task)"
                                                (onAddTask)="showAddTask()"
                                                (onClick)="viewTask($event.task)"
                                                (onViewMoreOpenTasks)="viewAllTasks()"></contact-tasks-card>
                            <activity-history-card [activities]="tasks | filterByTaskType:'closed'"
                                                   [sliceLength]="3"
                                                   [isInitializing]="isInitializingTasks"
                                                   (onAddActivity)="showAddActivity()"
                                                   (onViewActivity)="viewActivity($event.activity)"
                                                   (onViewAllActivities)="viewAllActivities()"></activity-history-card>
                            <documents-card [pipelineObject]="selectedRecord.comp"></documents-card>
                        </ion-col>
                        <ion-col col-5>
                            <tenant-card
                                *ngIf="selectedRecord.comp.tenantCompany || selectedRecord.comp.tenantContact"
                                (clickedTenantContact)="openContactModal($event)"
                                (clickedTenantCompany)="openCompanyModal($event)"
                                [company]="selectedRecord.comp.tenantCompany"
                                [contact]="selectedRecord.comp.tenantContact">
                            </tenant-card>
                            <landlord-card
                                *ngIf="selectedRecord.comp.landlordCompany || selectedRecord.comp.landlordContact"
                                (clickedLandlordContact)="openContactModal($event)"
                                (clickedLandlordCompany)="openCompanyModal($event)"
                                [company]="selectedRecord.comp.landlordCompany"
                                [contact]="selectedRecord.comp.landlordContact">
                            </landlord-card>
                            <deal-team-card [isInitializingDealParties]="isInitializingDealParties"
                                            [dealParties]="dealParties"
                                            [selectedRecord]="selectedRecord.comp"
                                            (onSelect)="openContactModal($event)"></deal-team-card>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-content>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide">
            <ion-content>
                <edit-closed-deal-form format="lease"
                                       [pipelineObject]="selectedRecord.comp"></edit-closed-deal-form>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>
