var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ModalController } from 'ionic-angular';
import { CallListModel } from '@apto/models';
import { AnalyticsService, CallListsService } from '@apto/services';
import { CsvExportService } from '@apto/shared/services';
import { CallListModal, CallListModalMode } from '@apto/shared/modals';
import './buyer-activity-card.scss';
var BuyerActivityCard = /** @class */ /*@__PURE__*/ (function () {
    function BuyerActivityCard(analyticsService, callListsService, modalController, csvExportService) {
        this.analyticsService = analyticsService;
        this.callListsService = callListsService;
        this.modalController = modalController;
        this.csvExportService = csvExportService;
        this.onBuildCallList = new EventEmitter();
        this.onStartCalling = new EventEmitter();
        this.stageCountInfo = [
            { label: CallListModel.MARKETING_STATUSES.PROSPECTS, count: 0 },
            { label: CallListModel.MARKETING_STATUSES.CONTACTED, count: 0 },
            { label: CallListModel.MARKETING_STATUSES.MATERIALS_SENT, count: 0 },
            { label: CallListModel.MARKETING_STATUSES.TOURED, count: 0 },
            { label: CallListModel.MARKETING_STATUSES.OFFERED, count: 0 }
        ];
    }
    BuyerActivityCard.prototype.ngOnInit = function () {
        this.processCallList();
    };
    Object.defineProperty(BuyerActivityCard.prototype, "maxCount", {
        get: function () {
            return Math.max.apply(Math, this.stageCountInfo.map(function (countInfo) {
                return countInfo.count;
            }));
        },
        enumerable: true,
        configurable: true
    });
    BuyerActivityCard.prototype.build = function () {
        this.onBuildCallList.emit();
        this.analyticsService.logEvent('Buyer Activity Card - Build List');
    };
    BuyerActivityCard.prototype.viewList = function (marketingStatus) {
        var callListModal = this.modalController.create(CallListModal, {
            callList: this.callList,
            marketingStatus: marketingStatus,
            mode: CallListModalMode.VIEW
        });
        return callListModal.present();
    };
    BuyerActivityCard.prototype.getWidth = function (countInfo) {
        var widthStyle = '0';
        if (countInfo.count > 0) {
            var widthPercentage = countInfo.count / this.maxCount * 100;
            // If the percent width is lower than 8 set to 8 to account for 2 digits.
            if (widthPercentage < 8) {
                widthStyle = '8%';
            }
            else {
                widthStyle = widthPercentage + '%';
            }
        }
        return widthStyle;
    };
    BuyerActivityCard.prototype.startCalling = function (marketingStatus) {
        var groupMember = this.callList.statusToMembers[marketingStatus][0];
        this.onStartCalling.emit({
            callList: this.callList,
            callListId: this.callList._id,
            contactId: groupMember ? groupMember.contactId : undefined,
            marketingStatus: marketingStatus
        });
        this.analyticsService.logEvent('Buyer Activity Card - Start Calling ' + marketingStatus);
    };
    BuyerActivityCard.prototype.exportCsv = function () {
        var _this = this;
        var noneFoundString = 'NA';
        var exportString = 'Marketing Status,Contact Name,Activity Date,Activity Type,Activity Description,Activity Note\n';
        Object.keys(this.callList.statusToMembers).slice(0, 5).reverse().forEach(function (key) {
            _this.callList.statusToMembers[key].forEach(function (member) {
                var type = !member.lastMarketingTask ? noneFoundString : member.lastMarketingTask.type || noneFoundString;
                var description = !member.lastMarketingTask ? noneFoundString : member.lastMarketingTask.subject || noneFoundString;
                var note = !member.lastMarketingTask ? noneFoundString : member.lastMarketingTask.description || noneFoundString;
                var lastModifiedDate = !member.lastMarketingTask ? noneFoundString :
                    moment(member.lastMarketingTask.lastModifiedDate).format('YYYY-MM-DD');
                exportString =
                    exportString +
                        key + ',' +
                        member.contact.fullName + ',' +
                        lastModifiedDate + ',' +
                        type + ',' +
                        description + ',' +
                        note + '\n';
            });
        });
        this.csvExportService.exportCSV(exportString, 'Buyer_Activity_Report');
    };
    BuyerActivityCard.prototype.processCallList = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.callList) {
                            return [2 /*return*/];
                        }
                        if (!this.callList.isPending)
                            return [3 /*break*/, 3];
                        // wait 5 seconds and check again
                        return [4 /*yield*/, new Promise(function (resolve) { setTimeout(resolve, 5000); })];
                    case 1:
                        // wait 5 seconds and check again
                        _b.sent();
                        return [4 /*yield*/, this.callListsService.getPending()];
                    case 2:
                        _b.sent();
                        return [2 /*return*/, this.processCallList()];
                    case 3:
                        _a = this;
                        return [4 /*yield*/, this.callListsService.sortMembersByMarketStatus(this.callList)];
                    case 4:
                        _a.callList = _b.sent();
                        this.stageCountInfo[0].count = this.callList.prospects.length;
                        this.stageCountInfo[1].count = this.callList.contacted.length;
                        this.stageCountInfo[2].count = this.callList.materialsSent.length;
                        this.stageCountInfo[3].count = this.callList.toured.length;
                        this.stageCountInfo[4].count = this.callList.offered.length;
                        return [2 /*return*/];
                }
            });
        });
    };
    return BuyerActivityCard;
}());
export { BuyerActivityCard };
