<ion-header class="modal-header tasks-modal--header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton();"
                    data-automation="modalBackButton">
                <apto-icon icon="arrowBack" inline size="4"></apto-icon>
            </button>
        </ion-buttons>

        <ion-title *ngIf="mode === Modes.LIST">{{ title }}</ion-title>
        <ion-title *ngIf="mode === Modes.VIEW">Task Details</ion-title>
        <ion-title *ngIf="mode === Modes.EDIT">{{ selectedRecord._id ? 'Edit' : 'Add' }} Task</ion-title>

        <ion-buttons end>
            <button *ngIf="mode === Modes.VIEW"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="setMode(Modes.EDIT)"
                    data-automation="modal-edit-button">
                Edit
            </button>
            <button *ngIf="mode === Modes.EDIT && selectedRecord._id"
                    ion-button
                    clear
                    color="danger"
                    hold-button
                    (hold)="delete()"
                    data-automation="modal-delete-button">
                Hold to Delete
            </button>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismiss()"
                    data-automation="modal-dismiss-button">
                <apto-icon icon="close" inline size="4"></apto-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal open-tasks-modal">
    <ion-slides (ionSlideWillChange)="scrollToTop()">
        <ion-slide>
            <ion-content>
                <ion-card *ngIf="!isInitializing">
                    <ion-card-header>Open Tasks</ion-card-header>
                    <task-item *ngFor="let task of records | filterByTaskType:'open' | filterByOwnerId: appService.salesforceUserId | sortByTaskDueDate"
                               [task]="task"
                               (onToggleTask)="toggleTask(task)"
                               (onClick)="view(task)"></task-item>

                    <ion-item *ngIf="!(records | filterByTaskType:'open' | filterByOwnerId: appService.salesforceUserId).length"
                              class="empty-card">
                        <ion-icon name="checkmark"
                                  circle
                                  color="white"
                                  class="grey-circle"
                                  item-left></ion-icon>
                        {{filter ? 'No ' + filter + ' tasks' : 'No tasks'}}
                    </ion-item>
                 </ion-card>
                <busy-panel *ngIf="isInitializing"></busy-panel>
             </ion-content>
        </ion-slide>
        <ion-slide>
            <ion-content>
                <ion-card *ngIf="selectedRecord">
                    <task-item [fullDescription]="fullDescription"
                               [hideContactName]="selectedRecord.contact"
                               [task]="selectedRecord"
                               [showSubInfo]="true"
                               (onToggleTask)="toggleTask($event)"></task-item>
                    <hr>
                    <div *ngIf="selectedRecord.contact"
                         class="task-history-modal--related-objects">
                        <ion-item>
                            <p>Related contact</p>
                        </ion-item>
                        <contact-item [contact]="selectedRecord.contact"
                                      (onClick)="viewContact($event)"></contact-item>
                    </div>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide" *ngIf="mode !== Modes.LIST">
            <ion-content>
                <ion-card>
                    <ion-card-header>Task details</ion-card-header>
                    <ion-card-content>
                        <edit-task-form [relatedContact]="selectedRecord.contact"
                                        [relatedListing]="relatedListing"
                                        [task]="selectedRecord"></edit-task-form>
                    </ion-card-content>
                </ion-card>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>
