<div class="autocomplete">
    <ion-searchbar #searchbar
                   [placeholder]="placeholder"
                   [(ngModel)]="searchText"
                   (ionInput)="search()"
                   [debounce]="500"
                   (keydown.enter)="goToResultsPage()">
    </ion-searchbar>
    <button *ngIf="searchText.length > 0"
            ion-button
            color="ancillary-grey"
            (click)="reset()"
            class="reset-button">
        <ion-icon name="close"></ion-icon>
    </button>
    <ion-spinner *ngIf="searchIsLoading" name="crescent"></ion-spinner>
    <div class="scroller"
         *ngIf="showList">
        <ion-list>
            <ion-item *ngIf="!searchIsLoading && !totalResults">
                No results found
            </ion-item>
            <ion-list-header *ngIf="searchResults.contacts && searchResults.contacts.length">
                Contacts ({{searchResults.contacts.length}})
            </ion-list-header>
            <ion-item *ngFor="let contact of searchResults.contacts | slice:0:3"
                      class="clickable"
                      (click)="selectItem(contact)">
                <ion-icon class="grey-color-circle"
                          material-icons
                          name="account_circle"
                          item-left>
                </ion-icon>
                <p [innerHtml]="contact.fullName | boldSubstring:searchText"></p>
            </ion-item>
            <ion-list-header *ngIf="searchResults.properties && searchResults.properties.length">
                Properties ({{searchResults.properties.length}})
            </ion-list-header>
            <ion-item *ngFor="let property of searchResults.properties | slice:0:3"
                      class="clickable"
                      (click)="selectItem(property)">
                <ion-icon name="business"
                          [ngClass]="{'action-circle': property.forSale}"
                          circle
                          material-icons
                          item-left>
                </ion-icon>
                <h3 [innerHtml]="property.name | boldSubstring:searchText"></h3>
                <p [innerHtml]="property.addressString | boldSubstring:searchText"></p>
            </ion-item>
            <ion-list-header *ngIf="searchResults.companies && searchResults.companies.length">
                Companies ({{searchResults.companies.length}})
            </ion-list-header>
            <ion-item *ngFor="let company of searchResults.companies | slice:0:3"
                      class="clickable"
                      (click)="selectItem(company)">
                <ion-icon class="action-circle"
                          circle
                          material-icons
                          name="location_city"
                          item-left>
                </ion-icon>
                <p [innerHtml]="company.name | boldSubstring:searchText"></p>
            </ion-item>
            <ion-list-header *ngIf="searchResults.tasks && searchResults.tasks.length">
                Tasks ({{searchResults.tasks.length}})
            </ion-list-header>
            <ion-item *ngFor="let task of searchResults.tasks | slice:0:3"
                      class="clickable"
                      (click)="selectItem(task)">
                <ion-icon name="playlist_add_check"
                          class="grey-circle"
                          circle
                          material-icons
                          item-left>
                </ion-icon>
                <p [innerHtml]="task.subject | boldSubstring:searchText"></p>
            </ion-item>
            <ion-list-header *ngIf="searchResults.deals && searchResults.deals.length">
                Deals ({{searchResults.deals.length}})
            </ion-list-header>
            <ion-item *ngFor="let deal of searchResults.deals | slice:0:3"
                      class="clickable"
                      (click)="selectItem(deal)">
                <ion-icon name="business"
                          circle
                          material-icons
                          item-left></ion-icon>
                <p [innerHtml]="deal.name | boldSubstring:searchText"></p>
            </ion-item>
            <ion-item *ngIf="totalResults > 0 && showAllResultsLink"
                      class="clickable"
                      (click)="goToResultsPage($event)">
                <p class="global-search--view-all-results">VIEW ALL {{totalResults}} RESULTS
                    <span class="global-search--enter"> (Enter)</span>
                </p>
            </ion-item>
        </ion-list>
    </div>
</div>
