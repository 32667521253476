import { FEATURES } from '../constants';
var FeaturesService = /** @class */ /*@__PURE__*/ (function () {
    function FeaturesService() {
        this.FEATURES = FEATURES;
        this.enabledFeatures = [];
    }
    FeaturesService.prototype.isFeatureEnabled = function (name) {
        return (this.enabledFeatures.indexOf(name) !== -1);
    };
    FeaturesService.prototype.setEnabledFeatures = function (enabledFeatures) {
        this.enabledFeatures = enabledFeatures || [];
    };
    return FeaturesService;
}());
export { FeaturesService };
