<ion-list class="email-popover-list" no-lines>
    <div *ngFor="let email of emails">
        <a ion-item
           detail-none
           text-nowrap
           target="_blank"
           [href]="email.actionLink">

            <ion-icon name="mail"
                      item-left
                      color="primary-blue"
                      small>
            </ion-icon>

            <p class="dark-text">{{email.address}}</p>
        </a>
    </div>
</ion-list>
