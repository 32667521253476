var ConfigService = /** @class */ /*@__PURE__*/ (function () {
    function ConfigService() {
        this.serviceUrl = 'https://apto.herokuapp.com';
        this.aptoLogLevel = 'ERROR';
        this.logentriesToken = '';
        this.segmentKey = '';
        this.appName = '';
        this.attomProxyUrl = process.env.ATTOM_PROXY_URL;
    }
    ConfigService.staticMapURL = '';
    return ConfigService;
}());
export { ConfigService };
