<div *ngIf="totalRecords > 0 && totalRecords > itemsPerPage"class="pagination-navigation">
    <button ion-button
            clear
            color="primary-blue"
            class="pagination-navigation--back-button"
            (click)="previousPage()"
            [disabled]="range.min === 0">
        < Prev
    </button>
    <span class="pagination-navigation--status">
        {{ range.min + 1 }}-{{ range.max }} of {{ totalRecords }}
    </span>
    <button ion-button
            clear
            color="primary-blue"
            class="pagination-navigation--next-button"
            (click)="nextPage()"
            [disabled]="range.max === totalRecords">
        Next >
    </button>
</div>
