import { PipeTransform } from '@angular/core';
var NumberWithCommas = /** @class */ /*@__PURE__*/ (function () {
    function NumberWithCommas() {
    }
    NumberWithCommas.prototype.transform = function (value) {
        if (isNaN(value)) {
            return value;
        }
        else {
            var parts = value.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return parts.join('.');
        }
    };
    return NumberWithCommas;
}());
export { NumberWithCommas };
