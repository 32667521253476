<div class="property-map--container">
    <am-apto-map (onMapReady)="onMapReady()"
              (onMarkerClick)="onMarkerClick($event)"
              (onMarkerHover)="onMarkerHover($event)"
              (onMarkerMouseOut)="onMarkerMouseOut($event)"
              (onMapBoundsChange)="onMapBoundsChange($event)"
              (onSearchHere)="onSearchHere($event)"
              (onItemClick)="onListItemClick($event)"
              (onItemHover)="onListItemHover($event)"
              (onItemLeave)="onListItemLeave($event)"
              (onChangePage)="onChangePage($event)"
              (onUpdatePropertyAddress)="onUpdatePropertyAddress($event)"
              (onAddPropertyClick)="onAddPropertyClick($event)"
              (onAddSelectedToList)="onAddSelectedToList($event)"
              (onCallistMaxSelectionError)="onCallistMaxSelectionError($event)"
              (onExportSelected)="onExportSelected($event)"
              [items]="properties"
              [totalResults]="totalResults"
              [resultLimit]="resultLimit"></am-apto-map>
    <ion-card-content *ngIf="isInitializingMap">
        <busy-panel message="Loading map..."></busy-panel>
    </ion-card-content>
</div>
