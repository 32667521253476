import { EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
var SelectMapField = /** @class */ /*@__PURE__*/ (function () {
    function SelectMapField() {
        this.required = false;
        this.onChange = new EventEmitter();
    }
    SelectMapField.prototype.ngOnInit = function () {
        if (!this.form.controls.hasOwnProperty(this.field)) {
            throw new Error("field \"" + this.field + "\" does not exist on form.");
        }
        this.selectedValue = this.form.controls[this.field].value;
    };
    Object.defineProperty(SelectMapField.prototype, "labels", {
        get: function () {
            return this.options ? Object.keys(this.options).sort() : [];
        },
        enumerable: true,
        configurable: true
    });
    SelectMapField.prototype.setFormValue = function (option) {
        option = this.options[option];
        this.form.controls[this.field].patchValue(option);
        this.selectedValue = option;
        this.onChange.emit(this.selectedValue);
    };
    return SelectMapField;
}());
export { SelectMapField };
