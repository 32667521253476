<ion-card class="call-list-statistics-card">
    <ion-card-header>Call list statistics</ion-card-header>
    <ion-card-content>
        <ion-item>
            <ion-icon material-icons
                      large
                      name="account_circle"></ion-icon>
            {{ numberOfMembers }} Contacts
        </ion-item>
        <ion-item class="call-list-statistics-card--hours-to-call"
                  text-wrap>
            <ion-icon material-icons
                      large
                      name="schedule"></ion-icon>
            {{ (numberOfMembers * CallListModel.MINUTES_PER_CALL / 60) | hoursToCall }} to call
        </ion-item>
    </ion-card-content>
</ion-card>
