import './accepted-offer-card.scss';
var AcceptedOfferCard = /** @class */ /*@__PURE__*/ (function () {
    function AcceptedOfferCard() {
        this.pipelineObject = {};
    }
    Object.defineProperty(AcceptedOfferCard.prototype, "showCard", {
        get: function () {
            return (this.pipelineObject.deposit ||
                this.pipelineObject.effectiveDate ||
                this.pipelineObject.salePrice ||
                this.pipelineObject.closeDate ||
                this.pipelineObject.buyerContact ||
                this.pipelineObject.buyerCompany);
        },
        enumerable: true,
        configurable: true
    });
    return AcceptedOfferCard;
}());
export { AcceptedOfferCard };
