var BaseListPage = /** @class */ /*@__PURE__*/ (function () {
    function BaseListPage() {
        this.currentPageSelected = false;
        this.items = [];
        this.selectedItems = {};
    }
    Object.defineProperty(BaseListPage.prototype, "selectedItemsArray", {
        get: function () {
            var selectedItemsArray = [];
            for (var contactId in this.selectedItems) {
                if (this.selectedItems.hasOwnProperty(contactId)) {
                    selectedItemsArray.push(this.selectedItems[contactId]);
                }
            }
            return selectedItemsArray;
        },
        enumerable: true,
        configurable: true
    });
    BaseListPage.prototype.clearAllItems = function () {
        this.currentPageSelected = false;
        this.selectedItems = {};
    };
    BaseListPage.prototype.clearCurrentItems = function () {
        var _this = this;
        this.items.forEach(function (item) {
            delete _this.selectedItems[item._id];
        });
    };
    BaseListPage.prototype.selectCurrentItems = function () {
        var _this = this;
        this.items.forEach(function (item) {
            _this.selectedItems[item._id] = item;
        });
    };
    BaseListPage.prototype.selectAllChanged = function (isSelected) {
        this.currentPageSelected = isSelected;
        if (isSelected) {
            this.selectCurrentItems();
        }
        else {
            this.clearCurrentItems();
        }
    };
    BaseListPage.prototype.selectChanged = function (isSelected, item) {
        if (isSelected) {
            this.selectedItems[item._id] = item;
        }
        else {
            delete this.selectedItems[item._id];
        }
    };
    return BaseListPage;
}());
export { BaseListPage };
