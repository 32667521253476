import { HttpClient } from '@angular/common/http';
import { LoadingController, NavParams, ViewController } from 'ionic-angular';
import { ConfigService } from '@apto/services';
import './field-check-modal.scss';
var FieldCheckModal = /** @class */ /*@__PURE__*/ (function () {
    function FieldCheckModal(configService, http, loadingController, navParams, viewController) {
        this.configService = configService;
        this.http = http;
        this.loadingController = loadingController;
        this.navParams = navParams;
        this.viewController = viewController;
        this.didFixFail = false;
        this.demoPageUrl = 'http://info.apto.com/demo-mobile-app-download';
        this.missingObjects = this.navParams.get('missingObjects');
        this.missingFields = this.navParams.get('missingFields');
        this.isAptoMissing = this.navParams.get('isAptoMissing');
        this.isUpgradeRequired = this.navParams.get('isUpgradeRequired');
    }
    FieldCheckModal.prototype.openDemoPage = function () {
        window.open(this.demoPageUrl, '_system');
        this.close();
    };
    FieldCheckModal.prototype.tryFix = function () {
        var _this = this;
        var loader = this.loadingController.create({
            content: 'Trying to fix permissions'
        });
        loader.present();
        this.http.post(this.configService.serviceUrl + "/api/desktop/metadata/fixpermissions", null)
            .subscribe(function (result) {
            if (result.status === 'success') {
                loader.dismiss();
                _this.viewController.dismiss(true);
            }
            else {
                _this.didFixFail = true;
                loader.dismissAll();
            }
        }, function () {
            _this.didFixFail = true;
            loader.dismissAll();
        });
    };
    FieldCheckModal.prototype.close = function () {
        this.viewController.dismiss();
    };
    return FieldCheckModal;
}());
export { FieldCheckModal };
