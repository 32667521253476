import { EventEmitter } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { AnalyticsService } from '@apto/services';
import { CsvExportService } from '@apto/shared';
import './offers-card.scss';
var OffersCard = /** @class */ /*@__PURE__*/ (function () {
    function OffersCard(analyticsService, csvExportService) {
        this.analyticsService = analyticsService;
        this.csvExportService = csvExportService;
        this.offers = [];
        this.onAddOffer = new EventEmitter();
        this.onClick = new EventEmitter();
    }
    OffersCard.prototype.addOffer = function () {
        this.onAddOffer.emit();
        this.analyticsService.logEvent('Offers Card - Add Offer');
    };
    OffersCard.prototype.exportCsv = function () {
        var noneFoundString = 'NA';
        var exportString = 'Offer Price,Offer Date,Status,Offerer,Offer Name\n';
        var currencyPipe = new CurrencyPipe('en-US');
        this.offers.forEach(function (offer) {
            var offerDate = offer.offerDate || noneFoundString;
            var offerStatus = offer.status || noneFoundString;
            var purchaser = !offer.purchaser ? noneFoundString : offer.purchaser.fullName || noneFoundString;
            var offerName = offer.name || noneFoundString;
            var offerPrice = !offer.offerPrice ? noneFoundString :
                '\"' + currencyPipe.transform(offer.offerPrice, 'USD', true, '1.0-0') + '\"';
            exportString =
                exportString +
                    offerPrice + ',' +
                    offerDate + ',' +
                    offerName + ',' +
                    purchaser + ',' +
                    offerStatus + '\n';
        });
        this.csvExportService.exportCSV(exportString, 'Offers_Report');
        this.analyticsService.logEvent('Offers Card - Export');
    };
    return OffersCard;
}());
export { OffersCard };
