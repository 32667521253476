var AppcuesService = /** @class */ /*@__PURE__*/ (function () {
    function AppcuesService() {
    }
    AppcuesService.prototype.start = function () {
        Appcues.start();
    };
    AppcuesService.prototype.identify = function (id, details) {
        Appcues.identify(id, details);
    };
    return AppcuesService;
}());
export { AppcuesService };
