import { NavParams } from 'ionic-angular';
import './social-profiles-popover.scss';
var SocialProfilesPopover = /** @class */ /*@__PURE__*/ (function () {
    function SocialProfilesPopover(navParams) {
        this.navParams = navParams;
        this.socialProfiles = [];
        this.socialProfiles = this.navParams.get('socialProfiles');
    }
    return SocialProfilesPopover;
}());
export { SocialProfilesPopover };
