import { SimpleChanges } from '@angular/core';
import { PopoverController } from 'ionic-angular';
import { AutoCompleteComponent } from '@apto/autocomplete';
import { OwnershipModel } from '@apto/models';
import { CompaniesService, ContactsService } from '@apto/services';
import { SelectPopover } from '@apto/ionic-lib/components';
import './manage-related-ownership.scss';
var ManageRelatedOwnership = /** @class */ /*@__PURE__*/ (function () {
    function ManageRelatedOwnership(companiesService, contactsService, popoverController) {
        this.companiesService = companiesService;
        this.contactsService = contactsService;
        this.popoverController = popoverController;
        this.currentOwnerships = [];
        this.allowEdit = false;
        this.updatedOwnerships = [];
    }
    ManageRelatedOwnership.prototype.ngOnInit = function () {
        this.updatedOwnerships = [].concat(this.currentOwnerships || []);
    };
    ManageRelatedOwnership.prototype.ngOnChanges = function (changes) {
        if (changes.currentOwnerships &&
            changes.currentOwnerships.previousValue !== changes.currentOwnerships.currentValue) {
            this.updatedOwnerships = [].concat(this.currentOwnerships || []);
        }
    };
    ManageRelatedOwnership.prototype.getUpdatedOwnerships = function () {
        var _this = this;
        var toDelete = (this.currentOwnerships || []).filter(function (current) {
            return current._id && !_this.updatedOwnerships.find(function (updated) { return current._id === updated._id; });
        });
        var toCreate = (this.updatedOwnerships || []).filter(function (o) { return !o._id; });
        return {
            ownershipsToDelete: toDelete,
            ownershipsToCreate: toCreate
        };
    };
    ManageRelatedOwnership.prototype.changeOwnerStatus = function (ev, ownership) {
        if (!this.allowEdit) {
            return;
        }
        var popover = this.popoverController.create(SelectPopover, {
            options: [
                { value: 'Primary Owner' },
                { value: 'Owner' },
                { value: 'Former Owner' },
                { value: 'Asset Manager' }
            ]
        });
        popover.onDidDismiss(function (value) {
            if (value && value === 'Primary Owner') {
                ownership.isPrimaryContact = true;
                ownership.contactRole = 'Owner';
            }
            else {
                ownership.isPrimaryContact = false;
            }
            ownership.contactRole = value;
        });
        popover.present({ ev: ev });
    };
    ManageRelatedOwnership.prototype.removeOwnership = function (ownership, type) {
        // Find already saved ownership to remove
        // this is so we don't accidentally remove the wrong
        // ownership if they duped it in salesforce
        var idx = this.updatedOwnerships.findIndex(function (o) { return o._id && ownership._id === o._id; });
        if (idx === -1) {
            // Otherwise try using the contact / company id as the removal key
            idx = this.updatedOwnerships.findIndex(function (o) {
                if (o._id && ownership._id === o._id) {
                    return true;
                }
                return ownership[type] && o[type] && ownership[type]._id === o[type]._id;
            });
        }
        if (idx !== -1) {
            this.updatedOwnerships.splice(idx, 1);
        }
    };
    ManageRelatedOwnership.prototype.addContactOwnership = function (contact) {
        var ownership = new OwnershipModel({
            contactId: contact._id,
            contact: contact
        });
        if (!this.hasOwnership(contact, 'contact')) {
            this.updatedOwnerships.push(ownership);
        }
        this.contactAutocomplete.clearValue();
    };
    ManageRelatedOwnership.prototype.addCompanyOwnership = function (company) {
        var ownership = new OwnershipModel({
            companyId: company._id,
            company: company
        });
        if (!this.hasOwnership(company, 'company')) {
            this.updatedOwnerships.push(ownership);
        }
        this.companyAutocomplete.clearValue();
    };
    ManageRelatedOwnership.prototype.hasOwnership = function (contactOrCompany, type) {
        var foundOwnership = this.updatedOwnerships.find(function (o) { return o[type] && o[type]._id === contactOrCompany._id; });
        return !!foundOwnership;
    };
    return ManageRelatedOwnership;
}());
export { ManageRelatedOwnership };
