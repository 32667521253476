<ion-header class="modal-header activity-history-modal--header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
             <button ion-button
                     icon-only
                     color="auxiliary-grey"
                     (click)="backButton();"
                     data-automation="modalBackButton">
                    <apto-icon icon="arrowBack" inline size="4"></apto-icon>
             </button>
        </ion-buttons>

        <ion-title *ngIf="mode === Modes.LIST">Activity History</ion-title>
        <ion-title *ngIf="mode === Modes.VIEW">Activity Details</ion-title>
        <ion-title *ngIf="mode === Modes.EDIT">{{ selectedRecord._id ? 'Edit' : 'Add' }} Activity</ion-title>

        <ion-buttons end>
            <button *ngIf="mode === Modes.VIEW"
                    ion-button
                    clear
                    color="primary-blue"
                    [disabled]="disableEdit"
                    (click)="setMode(Modes.EDIT)"
                    data-automation="modal-edit-button">
                    EDIT
            </button>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismiss()"
                    data-automation="modal-dismiss-button">
                    <apto-icon icon="close" inline size="4"></apto-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal activity-history-modal">
    <ion-slides (ionSlideWillChange)="scrollToTop()">
        <ion-slide>
            <ion-content>
                <ion-card>
                    <ion-item class="sort-by-select">
                        <span>Sort By</span>
                        <apto-ionic-select [options]="['Newest first', 'Oldest first']"
                                           [value]="sortBy"
                                           (onSelectOption)="sortChanged($event.value)"></apto-ionic-select>
                    </ion-item>

                    <activity-item *ngFor="let activity of records | filterByTaskType:'closed'"
                                   [activity]="activity"
                                   (onClick)="view(activity)">
                    </activity-item>

                    <ion-item *ngIf="!records.length" class="empty-card">
                        <apto-icon icon="phone" item-left circle circleColor="lightGray"></apto-icon> No Contact History
                    </ion-item>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide>
            <ion-content>
                <ion-card *ngIf="selectedRecord">
                    <activity-item [activity]="selectedRecord"
                                   [showActivityTitle]="showActivityTitle"
                                   [showSubInfo]="true"></activity-item>
                    <hr>
                    <div *ngIf="selectedRecord.contact"
                         class="activity-history-modal--related-objects">
                        <ion-item>
                            <p>Related contact</p>
                        </ion-item>
                        <contact-item [contact]="selectedRecord.contact"
                                      (onClick)="viewContact($event)"></contact-item>
                    </div>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide">
            <ion-content>
                <ion-card >
                    <ion-card-header>
                        Activity details
                    </ion-card-header>

                    <ion-card-content>
                        <edit-activity-form [activity]="selectedRecord"
                                            [contactProvider]="contactsService"
                                            [userProvider]="usersService"
                                            [relatedContact]="selectedRecord.contact"
                                            [relatedListing]="relatedListing"></edit-activity-form>
                    </ion-card-content>
                </ion-card>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>
