import { EventEmitter } from '@angular/core';
import { PopoverController } from 'ionic-angular';
import { EmailPopover, PhonePopover } from '../../components';
import { OwnershipModel } from '@apto/models';
import './property-ownerships-card.scss';
var PropertyOwnershipsCard = /** @class */ /*@__PURE__*/ (function () {
    function PropertyOwnershipsCard(popoverController) {
        this.popoverController = popoverController;
        this.onClickCompany = new EventEmitter();
        this.onClickContact = new EventEmitter();
        this.onExecuteCall = new EventEmitter();
        this.onManageOwnerships = new EventEmitter();
        this.onViewAllOwnerships = new EventEmitter();
        this.onSendEmail = new EventEmitter();
        this.companyOwnerships = [];
        this.contactOwnerships = [];
        this._ownerships = [];
    }
    Object.defineProperty(PropertyOwnershipsCard.prototype, "ownerships", {
        get: function () {
            return this._ownerships;
        },
        set: function (value) {
            if (this._ownerships !== value) {
                this._ownerships = value;
                var splitOwnerships = OwnershipModel.splitOwnerships(value);
                this.companyOwnerships = splitOwnerships.companyOwnerships;
                this.contactOwnerships = splitOwnerships.contactOwnerships;
            }
        },
        enumerable: true,
        configurable: true
    });
    PropertyOwnershipsCard.prototype.viewAll = function () {
        this.onViewAllOwnerships.emit();
    };
    PropertyOwnershipsCard.prototype.manage = function () {
        this.onManageOwnerships.emit();
    };
    PropertyOwnershipsCard.prototype.executeCall = function (phoneNumber) {
        this.onExecuteCall.emit(phoneNumber);
        window.location.assign(phoneNumber.actionLink);
    };
    PropertyOwnershipsCard.prototype.sendEmail = function (email) {
        this.onSendEmail.emit(email);
        window.location.assign(email.actionLink);
    };
    PropertyOwnershipsCard.prototype.showEmails = function (ev, contact) {
        var popover = this.popoverController.create(EmailPopover, {
            emails: contact.emailsWithValues
        });
        popover.present({ ev: ev });
    };
    PropertyOwnershipsCard.prototype.showPhoneNumbers = function (ev, contact) {
        var popover = this.popoverController.create(PhonePopover, {
            phoneNumbers: contact.phoneNumbersWithValues
        });
        popover.present({ ev: ev });
    };
    return PropertyOwnershipsCard;
}());
export { PropertyOwnershipsCard };
