import { __extends } from "tslib";
import { BaseFilter } from '../';
import './tags-filter.scss';
var TagsFilter = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(TagsFilter, _super);
    function TagsFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.placeholder = 'Add items';
        _this.secondaryPlaceholder = '+ Add another item';
        _this.separatorKeyCodes = [13]; // enter key as default
        _this.items = [];
        return _this;
    }
    TagsFilter.prototype.getQuery = function () {
        var query = {};
        var isValid = this.validate();
        if (isValid) {
            query[this.queryKey] = { $in: this.items };
        }
        return query;
    };
    TagsFilter.prototype.validate = function () {
        return true;
    };
    TagsFilter.prototype.reset = function () {
        this.items = [];
    };
    TagsFilter.prototype.isEmpty = function () {
        return this.items.length === 0;
    };
    TagsFilter.prototype.onInputChanged = function (event) {
        this.onQueryChange.emit();
    };
    return TagsFilter;
}(BaseFilter));
export { TagsFilter };
