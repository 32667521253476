import { __extends } from "tslib";
import { FormControl } from '@angular/forms';
import { BaseFilter } from '../';
import './text-filter.scss';
var TextFilter = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(TextFilter, _super);
    function TextFilter() {
        var _this = _super.call(this) || this;
        _this.input = '';
        _this.formControl = new FormControl();
        _this.formControl.valueChanges.subscribe(function (search) {
            _this.onQueryChange.emit(search);
        });
        return _this;
    }
    TextFilter.prototype.getQuery = function () {
        var query = {};
        if (this.validate()) {
            var likeClause = this.input.trim().split(' ').join('% ');
            query.$or = [
                (_a = {}, _a[this.queryKey] = { $like: likeClause + "%" }, _a),
                (_b = {}, _b[this.queryKey] = { $like: "% " + likeClause + "%" }, _b)
            ];
        }
        return query;
        var _a, _b;
    };
    TextFilter.prototype.validate = function () {
        return this.input.length > 1;
    };
    TextFilter.prototype.reset = function () {
        this.input = '';
    };
    TextFilter.prototype.isEmpty = function () {
        return !this.input;
    };
    return TextFilter;
}(BaseFilter));
export { TextFilter };
