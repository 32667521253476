<ion-card>
    <ion-card-header>
        Documents
    </ion-card-header>
    <ion-card-content no-padding>
        <ion-list>
            <ion-item *ngFor="let document of documents">
                <ion-icon material-icons
                          name="description"
                          circle
                          item-left
                          color="primary-blue">
                </ion-icon>
                <p>{{ document.name }}</p>
                <p class="documents-card--date">{{ document.createdAt | dateFormat }}</p>

                <button ion-button
                        clear
                        color="danger"
                        item-right
                        hold-button
                        (hold)="delete(document)">
                    Hold to Delete
                </button>
                <button ion-button
                        clear
                        color="primary-blue"
                        item-right
                        (click)="download(document)">
                    Download
                </button>
            </ion-item>

            <ion-item *ngIf="!documents.length"
                      class="empty-card">
                <ion-icon name="folder"
                          material-icons
                          circle
                          small
                          color="white"
                          item-left></ion-icon>
                Add documents
            </ion-item>
        </ion-list>
        <ion-row class="card-footer-buttons">
            <ion-col text-right>
                <input #fileUploadField type="file" (change)="onFileUploadFieldChanged($event)" multiple>
                <button ion-button
                        clear
                        color="primary-blue"
                        (click)="clickFileField()">
                    Add Document
                </button>
            </ion-col>
        </ion-row>
    </ion-card-content>

    <ion-card-content *ngIf="initializingMessage">
        <busy-panel [message]="initializingMessage"></busy-panel>
    </ion-card-content>
</ion-card>
