import { EventEmitter } from '@angular/core';
import './call-lists-list-card.scss';
import { CallListModal, CallListModalMode, TasksModal } from '@apto/shared';
import { ModalController } from 'ionic-angular';
var CallListsListCard = /** @class */ /*@__PURE__*/ (function () {
    function CallListsListCard(modalController) {
        this.modalController = modalController;
        this.callLists = [];
        this.viewList = new EventEmitter();
    }
    CallListsListCard.prototype.viewCallList = function (callList) {
        var modalClass = callList.isTaskList ? TasksModal : CallListModal;
        var modal = this.modalController.create(modalClass, {
            callList: callList,
            mode: CallListModalMode.EDIT,
            tasks: callList.tasks,
            title: callList.name,
        });
        modal.present({ updateUrl: false });
    };
    return CallListsListCard;
}());
export { CallListsListCard };
