import { EventEmitter, TemplateRef, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import './autocomplete.scss';
import { Searchbar, TextInput } from 'ionic-angular';
import { debounceTime } from 'rxjs/operators';
// searchbar default options
var defaultOpts = {
    cancelButtonText: 'Cancel',
    showCancelButton: false,
    debounce: 250,
    placeholder: 'Search',
    autocomplete: 'off',
    autocorrect: 'off',
    spellcheck: 'off',
    type: 'search',
    value: '',
    noItems: '',
    clearOnEdit: false,
    clearInput: false
};
var AutoCompleteComponent = /** @class */ /*@__PURE__*/ (function () {
    function AutoCompleteComponent() {
        this.hideListOnSelection = true;
        this.isBusy = false;
        this.showListChanged = false;
        this.keyword = null;
        this.suggestions = [];
        this._showList = false;
        this.itemSelected = new EventEmitter();
        this.itemsShown = new EventEmitter();
        this.itemsHidden = new EventEmitter();
        this.ionAutoInput = new EventEmitter();
        this.changeValue = new EventEmitter();
        this.options = {};
        // set default options
        this.defaultOpts = defaultOpts;
    }
    Object.defineProperty(AutoCompleteComponent.prototype, "showList", {
        get: function () {
            return this._showList;
        },
        set: function (value) {
            if (this._showList === value) {
                return;
            }
            this._showList = value;
            this.showListChanged = true;
        },
        enumerable: true,
        configurable: true
    });
    AutoCompleteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchbarElem.ionChange.pipe(debounceTime(250)).subscribe(function (e) {
            _this.fireChange();
        });
    };
    AutoCompleteComponent.prototype.ngOnDestroy = function () {
        this.searchbarElem.ionChange.unsubscribe();
    };
    AutoCompleteComponent.prototype.ngAfterViewChecked = function () {
        if (this.showListChanged) {
            this.showListChanged = false;
            this.showList ? this.itemsShown.emit() : this.itemsHidden.emit();
        }
    };
    AutoCompleteComponent.prototype.setDisabledState = function (state) {
        if (this.searchbarElem) {
            if (state) {
                this.searchbarElem._searchbarInput.nativeElement.setAttribute('disabled', true);
            }
            else {
                this.searchbarElem._searchbarInput.nativeElement.removeAttribute('disabled');
            }
        }
        if (this.inputElem) {
            this.inputElem.setDisabledState(state);
        }
    };
    AutoCompleteComponent.prototype.getItems = function () {
        var _this = this;
        if (this.showResultsFirst && !this.keyword) {
            this.keyword = '';
        }
        else if (this.keyword.trim() === '') {
            this.suggestions = [];
            return;
        }
        this.isBusy = true;
        var result = this.dataProvider.getResults(this.keyword);
        // if result is instanceof Subject, use it asObservable
        if (result instanceof Subject) {
            result = result.asObservable();
        }
        // if query is async
        if (result instanceof Observable) {
            result
                .subscribe(function (results) {
                _this.isBusy = false;
                _this.suggestions = results;
                _this.showItemList();
            }, function (error) { return console.error(error); });
        }
        else {
            this.isBusy = false;
            this.suggestions = result;
            this.showItemList();
        }
        // emit event
        this.ionAutoInput.emit(this.keyword);
    };
    AutoCompleteComponent.prototype.showItemList = function () {
        this.showList = true;
    };
    AutoCompleteComponent.prototype.hideItemList = function () {
        this.showList = this.alwaysShowList;
    };
    AutoCompleteComponent.prototype.select = function (selection) {
        this.keyword = this.dataProvider.labelAttribute == null || selection[this.dataProvider.labelAttribute] == null
            ? selection : selection[this.dataProvider.labelAttribute];
        if (this.hideListOnSelection) {
            this.hideItemList();
        }
        // emit selection event
        this.itemSelected.emit(selection);
        this.selection = selection;
    };
    AutoCompleteComponent.prototype.getSelection = function () {
        return this.selection;
    };
    AutoCompleteComponent.prototype.getValue = function () {
        return this.keyword;
    };
    AutoCompleteComponent.prototype.setValue = function (value) {
        this.keyword = value;
        return;
    };
    AutoCompleteComponent.prototype.clearValue = function (hideItemList) {
        if (hideItemList === void 0) {
            hideItemList = false;
        }
        this.keyword = null;
        this.selection = null;
        if (hideItemList) {
            this.hideItemList();
        }
        return;
    };
    AutoCompleteComponent.prototype.setFocus = function () {
        if (this.searchbarElem) {
            this.searchbarElem.setFocus();
        }
    };
    AutoCompleteComponent.prototype.documentClickHandler = function (event) {
        if ((this.searchbarElem
            && !this.searchbarElem._elementRef.nativeElement.contains(event.target))
            ||
                (!this.inputElem && this.inputElem._elementRef.nativeElement.contains(event.target))) {
            this.hideItemList();
        }
    };
    AutoCompleteComponent.prototype.fireChange = function () {
        this.changeValue.emit(this.keyword);
    };
    AutoCompleteComponent.prototype.disableEnterKey = function (e) {
        if (e.key === 'Enter') {
            e.stopPropagation();
            e.preventDefault();
        }
    };
    return AutoCompleteComponent;
}());
export { AutoCompleteComponent };
