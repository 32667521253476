var AnalyticsService = /** @class */ /*@__PURE__*/ (function () {
    function AnalyticsService() {
    }
    AnalyticsService.prototype.isEnabled = function () {
        return Boolean(window.analytics);
    };
    AnalyticsService.prototype.identify = function (id, traits) {
        window.analytics.identify(id, traits);
    };
    AnalyticsService.prototype.loadSegmentKey = function (segmentKey) {
        window.analytics.load(segmentKey);
    };
    AnalyticsService.prototype.logEvent = function (event, properties) {
        if (this.isEnabled()) {
            try {
                window.analytics.track(event, properties);
            }
            catch (ex) {
                // do nothing if analytics throws an error
            }
        }
    };
    AnalyticsService.prototype.reset = function () {
        window.analytics.reset();
    };
    return AnalyticsService;
}());
export { AnalyticsService };
