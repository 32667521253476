<form #formContent
      class="edit-call-list-form edit-form"
      [formGroup]="form">
    <ion-card class="edit-call-list-form--call-list-details">
        <ion-card-header>Call list details</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-label stacked required>Call list name</ion-label>
                <ion-input formControlName="name" required (keypress)="disableEnterKey($event)"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="form.controls.name.dirty &&
                        form.controls.name.invalid &&
                        form.controls.name.errors['required']">
                Please enter a name for the call list.
            </div>
            <div class="field-error"
                 *ngIf="form.controls.name.errors?.duplicatedName">
                This name is already in use.
            </div>

            <ion-item>
                <ion-label stacked>Call list description</ion-label>
                <ion-input formControlName="description" (keypress)="disableEnterKey($event)"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="form.controls.description.dirty &&
                        form.controls.description.invalid &&
                        form.controls.description.errors['maxlength']">
                Description length must be less than 255 symbols.
            </div>

            <div class="input-group"
                 [hidden]="callList.isDealList">
                <ion-label stacked required>Contact frequency</ion-label>
                <ion-segment mode="ios"
                             color="primary-blue"
                             formControlName="frequency">
                    <ion-segment-button value="30">30 days</ion-segment-button>
                    <ion-segment-button value="45">45 days</ion-segment-button>
                    <ion-segment-button value="60">60 days</ion-segment-button>
                    <ion-segment-button value="90">90 days</ion-segment-button>
                    <ion-segment-button value="120">120 days</ion-segment-button>
                </ion-segment>
            </div>
        </ion-card-content>
    </ion-card>

    <ion-card class="edit-call-list-form--statistics">
        <ion-card-header>Call statistics</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-icon material-icons
                          name="account_circle"
                          item-left
                          large></ion-icon>
                <ion-label>{{ members.length + addedContacts.length }} Contacts</ion-label>
            </ion-item>
            <ion-item>
                <ion-icon material-icons
                          name="schedule"
                          item-left
                          large></ion-icon>
                <ion-label>{{ ((members.length + addedContacts.length) * CallListModel.MINUTES_PER_CALL / 60) | hoursToCall }} to call</ion-label>
            </ion-item>
            <ion-item *ngIf="addedContacts.length > 0">
                <ion-icon material-icons
                          name="add_circle"
                          item-left
                          large></ion-icon>
                <ion-label>
                    Adding {{ addedContacts.length }} contact{{ addedContacts.length > 1 ? 's' : '' }} on save
                    <button ion-button
                            clear
                            color="primary-blue"
                            (click)="undoAddedContacts()">Undo</button>
                </ion-label>
            </ion-item>
            <ion-item *ngIf="removedMembers.length > 0">
                <ion-icon material-icons
                          name="cancel"
                          item-left
                          large></ion-icon>
                <ion-label>
                    Removing {{ removedMembers.length }} contact{{ removedMembers.length > 1 ? 's' : '' }} on save
                    <button ion-button
                            clear
                            color="primary-blue"
                            (click)="undoRemovedItems()">Undo</button>
                </ion-label>
            </ion-item>
        </ion-card-content>
    </ion-card>

    <ion-card *ngIf="!callList.isCoreClientsList">
        <ion-card-header>
            Add Contacts to List
            <add-record
                linkText="Create new"
                objectName="Contact"
                (onRecordCreated)="addContact($event);"></add-record>
        </ion-card-header>
        <ion-card-content>
            <ion-auto-complete
                               [dataProvider]="contactsService"
                               [options]="{ placeholder : 'Find contact', debounce: 500 }"
                               (itemSelected)="addContact($event)"></ion-auto-complete>

            <ion-list>
                <contact-item *ngFor="let contact of addedContacts; let i = index"
                              [contact]="contact"
                              [showContactInfo]="false"
                              [buttons]="[{ label: 'Remove', color: 'danger' }]"
                              (onButtonClicked)="removeAddedContact(i)"></contact-item>
            </ion-list>
        </ion-card-content>
    </ion-card>

    <ion-card [hidden]="members.length === 0 || callList.isCoreClientsList">
        <ion-card-header>Contacts on list</ion-card-header>
        <ion-card-content>
            <ion-list>
                <call-list-contact-item *ngFor="let member of members | slice:pagination.range.min:pagination.range.max; let i = index"
                                        [groupMember]="member"
                                        [callListCount]="listCounts[member.contactId]"
                                        [buttons]="[{ label: 'Remove', color: 'danger' }]"
                                        (onButtonClicked)="removeContact(i + pagination.currentPage * pagination.itemsPerPage)"
                                        [@listItemState]="members.indexOf(member) >= 0 ? 'in' : ''"></call-list-contact-item>
            </ion-list>

            <pagination-navigation [itemsPerPage]="100"
                                   [totalRecords]="members.length"
                                   (onChange)="onPaginationChange.emit()"></pagination-navigation>
        </ion-card-content>
    </ion-card>

    <busy-panel *ngIf="isSaving"
                message="Saving call list..."></busy-panel>
</form>
