import { EventEmitter } from '@angular/core';
import './photo-scrollview.scss';
var PhotoScrollview = /** @class */ /*@__PURE__*/ (function () {
    function PhotoScrollview() {
        this.onAddPhoto = new EventEmitter();
    }
    PhotoScrollview.prototype.addPhoto = function () {
        this.onAddPhoto.emit();
    };
    return PhotoScrollview;
}());
export { PhotoScrollview };
