<div class="deal-item selectable-item clickable"
     [ngClass]="{ clickable: onClick.observers.length > 0 }">
    <ion-item [attr.detail-push]="onClick.observers.length > 0 ? true : null"
              (click)="onClick.emit(deal)">
        <ion-icon name="business"
                  circle
                  material-icons
                  item-left></ion-icon>

        <h2 class="deal-item--dark-text">{{deal.name}}</h2>
        <p *ngIf="deal?.property?.addressString">
            {{deal.property.addressString}}
        </p>

        <!-- Contract specific fields -->
        <span *ngIf="deal && deal.contractPrice">{{ deal.contractPrice | currency:'USD':'symbol':'1.0-0'}}</span>
        <span *ngIf="deal && deal.contractPrice && deal.property && deal.property.squareFootage"> • </span>
        <span [innerHTML]="getPriceAndSqft(deal.property)"></span>

        <!-- Pursuit specific fields -->
        <span class="property-item--sellerPrice"
              [innerHTML]="getPropertyDetailsFromPursuit(deal)"></span>
    </ion-item>
</div>