import { PipeTransform } from '@angular/core';
var SortByListingStatus = /** @class */ /*@__PURE__*/ (function () {
    function SortByListingStatus() {
    }
    SortByListingStatus.prototype.transform = function (properties) {
        return properties.sort(function (property1, property2) {
            return ((property1.forSale && !property2.forSale) ? -1 :
                ((!property1.forSale && property2.forSale) ? 1 : 0));
        });
    };
    return SortByListingStatus;
}());
export { SortByListingStatus };
