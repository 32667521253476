var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertyModel } from '@apto/models';
import { INTEGER_REGEX, DECIMAL_REGEX, YEAR_REGEX, validationMessages, POSTAL_REGEX } from '@apto/shared/constants';
import { PropertiesService } from '@apto/services';
var EditPropertyForm = /** @class */ /*@__PURE__*/ (function () {
    function EditPropertyForm(formBuilder, propertiesService) {
        this.formBuilder = formBuilder;
        this.propertiesService = propertiesService;
        this.ownerships = [];
        this.PropertyModel = PropertyModel;
        this.picklistOptions = {};
        this.isSaving = false;
        this.validationMessages = validationMessages;
    }
    EditPropertyForm.prototype.ngOnInit = function () {
        this.setupForm();
        this.getPicklistOptions();
    };
    EditPropertyForm.prototype.ngOnChanges = function (changes) {
        if (changes.model &&
            changes.model.previousValue &&
            changes.model.previousValue._id !== changes.model.currentValue._id) {
            this.setupForm();
        }
    };
    EditPropertyForm.prototype.setupForm = function () {
        var _this = this;
        var validateWithPattern = function (keyField, reg) {
            return [_this.model[keyField], Validators.pattern(reg)];
        };
        this.propertyForm = this.formBuilder.group({
            name: [this.model.name, Validators.required],
            type: [this.model.type, Validators.required],
            address: this.formBuilder.group({
                street: [this.model.address.street, Validators.required],
                city: this.model.address.city,
                country: this.model.address.country,
                postalCode: [this.model.address.postalCode, Validators.pattern(POSTAL_REGEX)],
                label: this.model.address.label,
                state: this.model.state
            }),
            salePrice: validateWithPattern('salePrice', INTEGER_REGEX),
            saleDate: this.model.saleDate,
            class: this.model.class,
            squareFootage: validateWithPattern('squareFootage', DECIMAL_REGEX),
            parkingSpaces: validateWithPattern('parkingSpaces', INTEGER_REGEX),
            parkingRatio: validateWithPattern('parkingRatio', DECIMAL_REGEX),
            primaryUse: this.model.primaryUse,
            zoning: this.model.zoning,
            status: this.model.status,
            tenancy: this.model.tenancy,
            units: validateWithPattern('units', INTEGER_REGEX),
            occupancy: validateWithPattern('occupancy', DECIMAL_REGEX),
            parcelNumber: this.model.parcelNumber,
            buildingLocation: this.model.buildingLocation,
            yearRenovated: validateWithPattern('yearRenovated', YEAR_REGEX),
            yearBuilt: validateWithPattern('yearBuilt', YEAR_REGEX),
            stories: validateWithPattern('stories', INTEGER_REGEX),
            market: this.model.market,
            submarket: this.model.submarket,
            landAcres: validateWithPattern('landAcres', DECIMAL_REGEX),
            landSquareFeet: validateWithPattern('landSquareFeet', DECIMAL_REGEX),
            description: this.model.description
        });
    };
    EditPropertyForm.prototype.save = function () {
        var _this = this;
        var formData = this.propertyForm.value;
        for (var key in formData) {
            if (formData[key] === '') {
                formData[key] = null;
            }
        }
        if (this.propertyForm.valid) {
            Object.assign(this.model, this.propertyForm.value);
            return {
                property: this.model
            };
        }
        else {
            var addressForm_1 = this.propertyForm.controls.address;
            Object.keys(this.propertyForm.controls).forEach(function (fieldKey) {
                _this.propertyForm.controls[fieldKey].markAsDirty();
            });
            Object.keys(addressForm_1.controls).forEach(function (fieldKey) {
                addressForm_1.controls[fieldKey].markAsDirty();
            });
        }
    };
    EditPropertyForm.prototype.getPicklistOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.propertiesService.getPicklistOptions([
                                'primaryUse',
                                'market',
                                'submarket',
                                'class',
                                'tenancy',
                                'status'
                            ])];
                    case 1:
                        _a.picklistOptions = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return EditPropertyForm;
}());
export { EditPropertyForm };
