<ion-toolbar>
    <div class="header-navbar--button-container" #headerNavbarDropdowns>
        <a ion-button
           class="disable-hover"
           clear
           (click)="closeAllDropdowns()"
           routerLink="/call-lists">
           <img src="/images/AptoLogo-white.png" alt="Apto" />
        </a>

        <global-search
            (click)="closeAllDropdowns()"
            (itemSelected)="searchItemSelected($event)"></global-search>


        <div class="header-navbar--dropdown--menu" [hidden]="!isOrganizeDropdownVisible">
            <ion-card>
                <ul (click)="closeAllDropdowns()">
                    <li><a class="nav-link" routerLink="/tasks">Tasks</a></li>
                    <li><a class="nav-link" routerLink="/property-map">Properties</a></li>
                    <li><a class="nav-link" routerLink="/contacts">Contacts</a></li>
                    <li><a class="nav-link" routerLink="/companies">Companies</a></li>
                </ul>
            </ion-card>
        </div>
        <a ion-button
           class="disable-hover header-navbar--dropdown-button"
           clear
           [ngClass]="{'active': isOnOrganizePage}"
           (click)="toggleDropdown($event, 'organize')">
            <strong>Organize</strong>
            Consolidate + Prepare
            <apto-icon icon="down" size="4" class="header-navbar--organize-dropdown-arrow" inline></apto-icon>
        </a>

        <a ion-button
           class="disable-hover"
           clear
           (click)="closeAllDropdowns()"
           [ngClass]="{'active': activeViewName === 'CallListsPage' || activeViewName === 'DashboardPage'}"
           routerLink="/call-lists">
            <strong>Connect</strong>
            Find + Win Business
        </a>

        <a *ngIf="featuresService.isFeatureEnabled(featuresService.FEATURES.DEALS)"
           ion-button
           class="disable-hover"
           clear
           (click)="closeAllDropdowns()"
           [ngClass]="{'active': activeViewName === 'DealsPage'}"
           routerLink="/deals">
            <strong>Close</strong>
            Execute + Close Deals
        </a>
    </div>

    <div class="header-navbar--search-and-logout">
        <div class="header-navbar--status-container" #headerNavbarDropdowns>
            <div class="header-navbar--dropdown">
                <span
                    class="header-navbar--dropdown-button"
                    (click)="toggleDropdown($event, 'user')">
                    <apto-icon icon="personCircle" inline></apto-icon>
                    <span class="header-navbar--dropdown-label header-navbar--username">{{ loggedInUserName }}</span>
                    <apto-icon inline icon="down"></apto-icon>
                </span>
                <ion-card [ngClass]="{'header-navbar--dropdown--menu--active': headerNavbarUserDropdownEnabled}" class="header-navbar--dropdown--menu">
                    <ul (click)="closeAllDropdowns()">
                        <li><a (click)="onLogout()">Logout</a></li>
                    </ul>
                </ion-card>
            </div>

            <div class="header-navbar--dropdown" #exportsDropdown>
                <span
                    class="header-navbar--dropdown-button"
                    (click)="toggleDropdown($event, 'file')">
                    <span class="header-navbar--export-icon"><apto-icon *ngIf="!exportsProcessing" icon="download" inline></apto-icon></span>
                    <ion-spinner class="header--navbar--export-progress" *ngIf="exportsProcessing"></ion-spinner>
                    <span class="header-navbar--dropdown-label">Exports</span>
                    <apto-icon inline icon="down"></apto-icon>
                </span>
                <export-manager
                    (onExportComplete)="onExportComplete($event)"
                    (onProcessingStart)="onProcessingStart($event)"
                    (onProcessingEnd)="onProcessingEnd($event)"
                    [ngClass]="{'header-navbar--dropdown--menu--active': headerNavbarExportsDropdownEnabled}"
                    class="header-navbar--dropdown--menu header-navbar--dropdown--menu--export-manager"></export-manager>
            </div>

            <div class="header-navbar--dropdown">
                <span
                    class="header-navbar--dropdown-button"
                    (click)="toggleDropdown($event, 'help')">
                    <apto-icon icon="help" inline></apto-icon>
                    <span class="header-navbar--dropdown-label">Help</span>
                    <apto-icon inline icon="down"></apto-icon>
                </span>
                <ion-card [ngClass]="{'header-navbar--dropdown--menu--active': headerNavbarHelpDropdownEnabled}" class="header-navbar--dropdown--menu">
                    <ul (click)="closeAllDropdowns()">
                        <li><a href="https://help.apto.com/hc/en-us/categories/115000328352" target="_blank" rel="noopener noreferrer">Help Center <apto-icon size="1" icon="link" inline></apto-icon></a></li>
                        <li><a href="https://www.apto.com/best-practices" target="_blank" rel="noopener noreferrer">CRE Best Practices <apto-icon size="1" icon="link" inline></apto-icon></a></li>
                        <li><a href="https://help.apto.com/hc/en-us/requests/new" target="_blank" rel="noopener noreferrer">Report A Problem <apto-icon size="1" icon="link" inline></apto-icon></a></li>
                    </ul>
                </ion-card>
            </div>
        </div>
    </div>
</ion-toolbar>
