import { __extends } from "tslib";
import { map } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { AlertController } from 'ionic-angular';
import { PropertiesServiceBase } from './properties-service-base';
var PropertiesServiceSpaceAutocomplete = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(PropertiesServiceSpaceAutocomplete, _super);
    function PropertiesServiceSpaceAutocomplete(injector, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.alertController = alertController;
        _this.parentPropertyId = null; // used for limiting autocomplete for spaces
        return _this;
    }
    // supports the auto-complete services for use with spaces
    PropertiesServiceSpaceAutocomplete.prototype.getResults = function (keyword, labelAttribute, defaultOwnerOnly) {
        var _this = this;
        if (labelAttribute === void 0) {
            labelAttribute = this.labelAttribute;
        }
        if (defaultOwnerOnly === void 0) {
            defaultOwnerOnly = false;
        }
        var params = {
            immediate: true,
            limit: 5,
            ownerOnly: defaultOwnerOnly,
            sort: labelAttribute,
            where: {}
        };
        var where = {};
        // if parent propertyid, use that as a where
        if (this.parentPropertyId) {
            var parentField = 'parentPropertyId';
            where[parentField] = this.parentPropertyId;
        }
        var likeClause = keyword.trim().split(' ').join('% ');
        where.$or = [
            (_a = {}, _a[labelAttribute] = { $like: likeClause + "%" }, _a),
            (_b = {}, _b[labelAttribute] = { $like: "% " + likeClause + "%" }, _b)
        ];
        params.where = where;
        var query = this.getQueryString(params);
        return this.http.get(this.endpoint + "?" + query).pipe(map(function (results) {
            return results.data.map(function (x) {
                var item = new _this.model(x);
                _this.cacheService.add(_this.serviceName, item._id, item);
                return item;
            });
        }));
        var _a, _b;
    };
    return PropertiesServiceSpaceAutocomplete;
}(PropertiesServiceBase));
export { PropertiesServiceSpaceAutocomplete };
