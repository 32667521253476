<ion-card class="call-lists-list-card">
    <ion-card-header>
        Current Lists
    </ion-card-header>
    <ion-card-content class="call-lists-list-card--content">
        <ion-item *ngFor="let callList of callLists"
                  detail-push
                  class="current-lists"
                  (click)="viewCallList(callList)">
            <ion-icon item-left name="apto-icon-call-list" class="call-lists-list-card--call-list-icon"></ion-icon>
            <p class="call-list-name">{{ callList.name }}</p>
            <div>
                <span>{{ callList.configJson.daysSinceContact }} day cycle</span>
            </div>
        </ion-item>
    </ion-card-content>
</ion-card>
