import { EventEmitter } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { PursuitModel } from '@apto/models';
import { NumberWithCommas, PricePerSqFtFormat } from '../../pipes';
var DealItem = /** @class */ /*@__PURE__*/ (function () {
    function DealItem() {
        this.onClick = new EventEmitter();
    }
    DealItem.prototype.getPricePerSqft = function (price, sqft) {
        return (parseInt(price, 10) / parseInt(sqft, 10)).toFixed(2).toString();
    };
    DealItem.prototype.getPriceAndSqft = function (property) {
        var items = [];
        if (this.deal && this.deal.property && this.deal.property.salePrice) {
            var price = new NumberWithCommas().transform(this.deal.property.salePrice);
            items.push("<strong>$" + price + "</strong>");
        }
        if (this.deal && this.deal.property && this.deal.property.squareFootage) {
            var sqft = new NumberWithCommas().transform(this.deal.property.squareFootage);
            items.push(sqft + " sqft");
        }
        if (this.deal && this.deal.property && this.deal.property.salePrice && this.deal.property.squareFootage) {
            var price = this.getPricePerSqft(this.deal.property.salePrice, this.deal.property.squareFootage);
            var str = new PricePerSqFtFormat().transform(price);
            items.push(str);
        }
        return items.join(' &bull; ');
    };
    DealItem.prototype.getPropertyDetailsFromPursuit = function (pursuit) {
        var items = [];
        if (!pursuit || !(pursuit instanceof PursuitModel)) {
            return '';
        }
        var currencyPipe = new CurrencyPipe('USD');
        if (pursuit.sellerPriceExpectation) {
            var str = currencyPipe.transform(pursuit.sellerPriceExpectation, 'USD', 'symbol', '1.0-0');
            items.push(str);
        }
        if (pursuit.brokerProposedPrice) {
            var str = currencyPipe.transform(pursuit.brokerProposedPrice, 'USD', 'symbol', '1.0-0');
            items.push(str);
        }
        return items.join(' &bull; ');
    };
    return DealItem;
}());
export { DealItem };
