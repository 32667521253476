<ion-item class="contact-context-panel"
          no-lines>

    <div item-left
       *ngIf="person &&
              person.apiResult &&
              person.apiResult.photos &&
              person.apiResult.photos.length">
        <img class="contact-context-panel--profile-photo"
             alt="Powered by FullContact"
             title="Powered by FullContact"
             [src]="person.apiResult.photos[0].url">
    </div>

    <div *ngIf="contact" class="contact-context-panel--name">
        <span>{{contact.fullName}}</span>
        <button *ngIf="!isLoading && showEdit"
                ion-button
                icon-only
                clear
                color="secondary-grey"
                class="contact-context-panel--edit-button"
                (click)="edit()">
            <ion-icon material-icons
                      small
                      name="mode_edit"></ion-icon>
        </button>
    </div>
    <div *ngIf="contact" class="contact-context-panel--title">
        {{contact.type}}<span *ngIf="contact.type && contact.position"> &bull; </span>{{contact.position}}
    </div>

    <div *ngIf="lastActivityStatus && lastActivityTimeframe"
         class="last-activity-text">
        You <b>{{lastActivityStatus}}</b> <span *ngIf="contact">{{contact.firstName}}</span> <b>{{lastActivityTimeframe | humanDate}}</b>
        <b *ngIf="lastActivityChangeDate">(edited: {{lastActivityChangeDate | humanDate}})</b>
    </div>


    <div *ngIf="contact"
         class="contact-context-panel--contact-info form-wrapper"
         item-right>
        <form class="contact-form"
              [formGroup]="contactForm">
            <div *ngIf="contact.phoneNumbersWithValues && contact.phoneNumbersWithValues.length; else elseBlock"
                 class="contact-context-panel--phone">
                <span class="hover-link"
                    (click)="executeCall(contact.phoneNumbersWithValues[0])">
                    <ion-icon class="contact-context-panel--contact-info-icon"
                            name="call"></ion-icon>
                    <span class="contact-context-panel--phone-email">{{contact.phoneNumbersWithValues[0].number}}</span>
                </span>
                <span *ngIf="contact.phoneNumbersWithValues.length > 1"
                    (click)="showPhoneNumbers($event)">
                    <ion-icon material-icons
                            name="arrow_drop_down"></ion-icon>
                </span>
            </div>
        <ng-template #elseBlock>
            <div *ngIf="!editingNumber">
                <span class="hover-link"
                      [class.contact-context-panel--not-allowed]="editingEmail || isSavingContact">
                    <button class="contact-context-panel--add-info"
                            [ngClass]="{disabled: editingEmail || isSavingContact}"
                            (click)="toggleNumberVisibility()"
                            clear
                            color="primary-blue"
                            icon-right
                            ion-button
                            small>
                    <ion-icon class="contact-context-panel--contact-info-icon"
                              name="call"></ion-icon>
                    ADD PHONE
                    </button>
                </span>
            </div>
          <div *ngIf="editingNumber"
               class="contact-context-panel--contact-info"
               item-right>
                <span class="hover-link">
                    <ion-row align-items-center>
                        <ion-icon class="contact-context-panel--contact-info-icon in-edit-mode"
                                  name="call"></ion-icon>
                        <ion-item class="contact-context-panel--add-contact-info"
                                  [class.contact-context-panel--invalid-information]="clickedSaveNumber &&
                                  contactForm.controls.phoneNumber.dirty &&
                                  contactForm.controls.phoneNumber.invalid &&
                                  contactForm.controls.phoneNumber.errors['pattern']">
                            <ion-textarea placeholder="ADD PHONE"
                                            textarea-elastic
                                            rows="1"
                                            formControlName="phoneNumber"> </ion-textarea>
                        </ion-item>
                        <button class="contact-context-panel--add-info contact-context-panel--save-button"
                                (click)="saveNumber()"
                                clear
                                color="primary-blue"
                                icon-right
                                ion-button
                                small>
                            SAVE
                        </button>
                        <button class="contact-context-panel--exit"
                                (click)="toggleNumberVisibility()"
                                clear
                                color="secondary-grey"
                                icon-right
                                ion-button
                                small>
                            <ion-icon
                                    name="md-close"></ion-icon>
                        </button>
                    </ion-row>
                </span>
            <div class="field-error"
                *ngIf="clickedSaveNumber &&
                contactForm.controls.phoneNumber.dirty &&
                contactForm.controls.phoneNumber.invalid &&
                contactForm.controls.phoneNumber.errors['pattern']">
                Please enter a valid phone number.
            </div>
          </div>
        </ng-template>
          <div *ngIf="contact.emailsWithValues && contact.emailsWithValues.length; else elseBlockEmail">
              <a class="contact-context-panel--email-link"
                 href="mailto:{{contact.emailsWithValues[0].address}}">
                  <ion-icon class="contact-context-panel--contact-info-icon"
                            name="mail"></ion-icon>
                  <span class="contact-context-panel--phone-email">{{contact.emailsWithValues[0].address}}</span>
              </a>
              <help-button helpText="<p>Email addresses will automatically open your default email application. Alternatively, right-click or Ctrl-click to open in a new tab.</p>">
              </help-button>
              <span *ngIf="contact.emailsWithValues.length > 1"
                    (click)="showEmails($event)">
                  <ion-icon material-icons
                            name="arrow_drop_down"></ion-icon>
              </span>
          </div>
        <ng-template #elseBlockEmail>
            <div *ngIf="!editingEmail">
                <span class="hover-link"
                      [class.contact-context-panel--not-allowed]="editingNumber || isSavingContact">
                    <button class="contact-context-panel--add-info"
                            [ngClass]="{disabled: editingNumber || isSavingContact}"
                            (click)="toggleEmailVisibility()"
                            clear
                            color="primary-blue"
                            icon-right
                            ion-button
                            small>
                    <ion-icon class="contact-context-panel--contact-info-icon"
                              name="mail"
                              role="img"></ion-icon>
                    ADD EMAIL
                    </button>
                </span>
            </div>
          <div *ngIf="editingEmail"
               class="contact-context-panel--contact-info"
               item-right>
            <span class="hover-link">
                <ion-row align-items-center>
                    <ion-icon class="contact-context-panel--contact-info-icon in-edit-mode"
                            name="mail"></ion-icon>
                    <ion-item class="contact-context-panel--add-contact-info"
                            [class.contact-context-panel--invalid-information]="clickedSaveEmail &&
                            contactForm.controls.email.dirty &&
                            contactForm.controls.email.invalid &&
                            contactForm.controls.email.errors['pattern']">
                    <ion-textarea placeholder="ADD EMAIL"
                                    textarea-elastic
                                    rows="1"
                                    formControlName="email"> </ion-textarea>
                    </ion-item>
                    <button class="contact-context-panel--add-info contact-context-panel--save-button"
                            (click)="saveEmail()"
                            clear
                            color="primary-blue"
                            icon-right
                            ion-button
                            small>
                                SAVE
                    </button>
                    <button class="contact-context-panel--exit"
                            (click)="toggleEmailVisibility()"
                            clear
                            color="secondary-grey"
                            icon-right
                            ion-button
                            small>
                        <ion-icon
                                name="md-close"></ion-icon>
                    </button>
              </ion-row>
            </span>
            <div class="field-error"
                *ngIf="clickedSaveEmail &&
                contactForm.controls.email.dirty &&
                contactForm.controls.email.invalid &&
                contactForm.controls.email.errors['pattern']">
                Please enter a valid email.
            </div>
          </div>
          </ng-template>
    </form>
    </div>


    <busy-panel *ngIf="isInitializing"></busy-panel>
</ion-item>
