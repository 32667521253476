import { PipeTransform } from '@angular/core';
/**
 * bolds the beggining of the matching string in the item
 */
var BoldPrefix = /** @class */ /*@__PURE__*/ (function () {
    function BoldPrefix() {
    }
    BoldPrefix.prototype.transform = function (value, keyword) {
        if (!keyword) {
            return '';
        }
        var escapedKeyword = keyword.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        return value.replace(new RegExp(escapedKeyword, 'gi'), function (str) { return str.bold(); });
    };
    return BoldPrefix;
}());
export { BoldPrefix };
