<ion-grid class="open-tasks--form"
          *ngIf="shouldShow">
    <div class="open-tasks--outer">
        <div class="open-tasks--inner"
             [class.error]="hasErrors"
             [@3dspin]="animationState">
            <h4 class="open-tasks--title">Review Tasks</h4>
            <span class="open-tasks--light-text light-text">Mark any tasks as complete.</span>

            <task-item *ngFor="let task of tasks | sortByTaskDueDate | slice:0:sliceLength"
                       [task]="task"
                       (onToggleTask)="markTaskComplete(task)"
                       (onClick)="view(task)"></task-item>

                <button class="open-tasks--continue"
                        (click)="continue()"
                        clear
                        color="primary-blue"
                        icon-right
                        ion-button
                        small>
                    CONTINUE
                    <ion-icon name="md-arrow-forward"></ion-icon>
                </button>

            <busy-panel *ngIf="isSaving" message="Updating Tasks"></busy-panel>
        </div>
    </div>
</ion-grid>
