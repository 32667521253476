<ion-list class="phone-popover-list" no-lines>
    <div *ngFor="let phoneNumber of phoneNumbers">
        <a *ngIf="phoneNumber.category != 'Fax'"
           ion-item
           detail-none
           text-nowrap
           target="_blank"
           (click)="callNumber(phoneNumber.actionLink);">

            <ion-icon [name]="phoneNumber.iconName"
                      small
                      item-left
                      color="primary-blue">
            </ion-icon>

            <p class="phone-popover--number dark-text">{{phoneNumber.number}}</p>
        </a>
    </div>
</ion-list>
