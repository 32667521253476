<ion-header class="modal-header buyer-needs-modal--header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton();"
                    data-automation="modalBackButton">
                <apto-icon icon="arrowBack" inline size="4"></apto-icon>
            </button>
        </ion-buttons>

        <ion-title *ngIf="mode === Modes.LIST">Buyer's Needs</ion-title>
        <ion-title *ngIf="mode === Modes.VIEW">Buyer's Need Details</ion-title>
        <ion-title *ngIf="mode === Modes.EDIT">{{ selectedRecord._id ? 'Edit' : 'Add' }} Buyer's Need</ion-title>

        <ion-buttons end>
            <button *ngIf="mode === Modes.VIEW"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="setMode(Modes.EDIT)"
                    data-automation="modal-edit-button">
                Edit
            </button>
            <button *ngIf="mode === Modes.EDIT && selectedRecord._id"
                    ion-button
                    clear
                    color="danger"
                    item-right
                    hold-button
                    (hold)="delete()"
                    data-automation="modal-delete-button">
                Hold to Delete
            </button>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismiss()"
                    data-automation="modal-dismiss-button">
                <apto-icon icon="close" inline size="4"></apto-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal buyer-needs-modal">
    <ion-slides>
        <ion-slide>
            <ion-content>
                <ion-card>
                    <ion-list>
                        <!-- Placeholder for list of Buyer's Needs -->
                    </ion-list>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide class="base-slide-modal--view-slide">
            <ion-content>
                <ion-card class="edit-buyer-need-form edit-form">
                    <ion-card-header>Buyer's need overview</ion-card-header>
                    <ion-card-content>
                        <div *ngIf="selectedRecord.name" class="input-group">
                            <ion-label stacked>Name</ion-label>
                            <p>{{ selectedRecord.name }}</p>
                        </div>

                        <div class="checkbox-input-group">
                            <ion-label stacked>Active</ion-label>
                            <input [ngModel]="selectedRecord.isActive"
                                   type="checkbox"
                                   disabled>
                        </div>

                        <div *ngIf="selectedRecord.buyerQuality" class="input-group">
                            <ion-label stacked>Buyer quality</ion-label>
                            <p>{{ selectedRecord.buyerQuality }}</p>
                        </div>

                        <div *ngIf="selectedRecord.acquisitionType" class="input-group">
                            <ion-label stacked>Acquisition type</ion-label>
                            <p>{{ selectedRecord.acquisitionType | replace: ';':', ' }}</p>
                        </div>

                        <div *ngIf="selectedRecord.minPrice" class="input-group">
                            <ion-label stacked>Minimum Price</ion-label>
                            <p>${{ selectedRecord.minPrice | numberWithCommas }}</p>
                        </div>

                        <div *ngIf="selectedRecord.maxPrice" class="input-group">
                            <ion-label stacked>Maximum Price</ion-label>
                            <p>${{ selectedRecord.maxPrice | numberWithCommas }}</p>
                        </div>

                        <div *ngIf="selectedRecord.minSquareFootage" class="input-group">
                            <ion-label stacked>Minimum Square feet</ion-label>
                            <p>{{ (selectedRecord.minSquareFootage | numberWithCommas) }} sqft</p>
                        </div>

                        <div *ngIf="selectedRecord.maxSquareFootage" class="input-group">
                            <ion-label stacked>Maximum Square feet</ion-label>
                            <p>{{ (selectedRecord.maxSquareFootage | numberWithCommas) }} sqft</p>
                        </div>

                        <div *ngIf="selectedRecord.buildingType" class="input-group">
                            <ion-label stacked>Building type</ion-label>
                            <p>{{ selectedRecord.buildingType | replace: ';':', ' }}</p>
                        </div>

                        <div *ngIf="selectedRecord.minCapRate !== null && selectedRecord.minCapRate !== undefined" class="input-group">
                            <ion-label stacked>Minimum CAP rate</ion-label>
                            <p>{{ selectedRecord.minCapRate | fixed }}%</p>
                        </div>

                        <div *ngIf="selectedRecord.minCashOnCash !== null && selectedRecord.minCashOnCash !== undefined" class="input-group">
                            <ion-label stacked>Minimum cash-on-cash</ion-label>
                            <p>{{ selectedRecord.minCashOnCash | fixed }}%</p>
                        </div>

                        <div *ngIf="selectedRecord.minLirr !== null && selectedRecord.minLirr !== undefined" class="input-group">
                            <ion-label stacked>Minimum LIRR</ion-label>
                            <p>{{ selectedRecord.minLirr | fixed }}%</p>
                        </div>

                        <div *ngIf="selectedRecord.minIrr !== null && selectedRecord.minCapRate !== undefined" class="input-group">
                            <ion-label stacked>Minimum IRR</ion-label>
                            <p>{{ selectedRecord.minIrr | fixed }}%</p>
                        </div>
                    </ion-card-content>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide">
            <ion-content>
                <edit-buyer-need-form [buyerNeed]="selectedRecord"></edit-buyer-need-form>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>
