import { BACKGROUND_CITIES } from '../constants';
var BackgroundCityService = /** @class */ /*@__PURE__*/ (function () {
    function BackgroundCityService() {
        this.backgroundCities = BACKGROUND_CITIES;
        var backgroundCityIndex = Math.floor(Math.random() * this.backgroundCities.length);
        var backgroundCity = this.backgroundCities[backgroundCityIndex];
        this.backgroundCityUrl = "url('./images/" + backgroundCity + ".jpg')";
    }
    return BackgroundCityService;
}());
export { BackgroundCityService };
