import { __extends } from "tslib";
import { EventEmitter } from '@angular/core';
import { CompanyModel } from '@apto/models';
import { SelectableItem } from '../selectable-item/selectable-item';
import './company-item.scss';
var CompanyItem = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(CompanyItem, _super);
    function CompanyItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onClick = new EventEmitter();
        return _this;
    }
    CompanyItem.prototype.getDescriptionText = function (company) {
        var components = [];
        if (company.type) {
            components.push(company.type);
        }
        if (company.billingAddress.isSet) {
            components.push(company.billingAddress);
        }
        return components.join(' • ');
    };
    return CompanyItem;
}(SelectableItem));
export { CompanyItem };
