import { __extends } from "tslib";
import { BaseFilter } from '../';
import './select-filter.scss';
var SelectFilter = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(SelectFilter, _super);
    function SelectFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isMultiple = false;
        return _this;
    }
    SelectFilter.prototype.getQuery = function () {
        var query = {};
        var isValid = this.validate();
        if (isValid) {
            query[this.queryKey] = this.selectedOption;
            return query;
        }
        return query;
    };
    SelectFilter.prototype.validate = function () {
        return true;
    };
    SelectFilter.prototype.reset = function () {
        this.selectedOption = null;
    };
    SelectFilter.prototype.isEmpty = function () {
        return !this.selectedOption;
    };
    SelectFilter.prototype.setSelected = function (option) {
        this.selectedOption = option;
        this.onQueryChange.emit();
    };
    return SelectFilter;
}(BaseFilter));
export { SelectFilter };
