<ion-list class="select-popover">
    <ion-item *ngFor="let option of options"
              [ngClass]="{ clickable: !option.disabled }"
              (click)="!option.disabled ? onOptionSelected(option) : null">
        <p class="link"
           [class.selected]="option.value === value"
           [innerHtml]="option.displayHtml || option.value">
        </p>
    </ion-item>
</ion-list>
