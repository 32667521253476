import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NavParams, ViewController } from 'ionic-angular';
import { AutoCompleteComponent } from '@apto/autocomplete';
import { BaseSlideModal } from '@apto/shared/modals';
import { EditOfferForm } from '@apto/pn/components';
export var ManageContactsModalMode = /*@__PURE__*/ (function (ManageContactsModalMode) {
    ManageContactsModalMode[ManageContactsModalMode["LIST"] = 0] = "LIST";
    ManageContactsModalMode[ManageContactsModalMode["VIEW"] = 1] = "VIEW";
    ManageContactsModalMode[ManageContactsModalMode["EDIT"] = 2] = "EDIT";
    return ManageContactsModalMode;
})({});
import './manage-contacts-modal.scss';
import { ContactsService } from '@apto/services';
var ManageContactsModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ManageContactsModal, _super);
    function ManageContactsModal(contactsService, navParams, viewController) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.contactsService = contactsService;
        _this.viewController = viewController;
        _this.selectedRecord = {};
        _this.records = [];
        _this.isInitializing = false;
        _this.Modes = ManageContactsModalMode;
        _this.contacts = [];
        _this.newContacts = [];
        _this.removedContacts = [];
        _this.modalUrls = new Map([
            [ManageContactsModalMode.VIEW, '/:taskId']
        ]);
        _this.mode = navParams.get('mode') || ManageContactsModalMode.LIST;
        _this.company = navParams.get('company');
        var navContacts = _this.navParams.get('contacts') || [];
        _this.contacts = navContacts.slice();
        return _this;
    }
    ManageContactsModal.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var promises;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isInitializing = true;
                        promises = [];
                        if (this.newContacts.length > 0 && this.company) {
                            this.newContacts.forEach(function (contact) {
                                return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        contact.companyId = this.company._id;
                                        contact.company = this.company;
                                        promises.push(this.contactsService.upsert(contact));
                                        return [2 /*return*/];
                                    });
                                });
                            });
                        }
                        if (this.removedContacts.length > 0) {
                            this.removedContacts.forEach(function (contact) {
                                return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        contact.companyId = null;
                                        contact.company = undefined;
                                        promises.push(this.contactsService.upsert(contact));
                                        return [2 /*return*/];
                                    });
                                });
                            });
                        }
                        return [4 /*yield*/, Promise.all(promises)];
                    case 1:
                        _a.sent();
                        this.isInitializing = false;
                        this.viewController.dismiss({
                            contacts: this.contacts
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ManageContactsModal.prototype.addContact = function (contact) {
        if (contact) {
            this.newContacts.push(contact);
            this.contacts.push(contact);
            this.removedContacts = this.removedContacts.filter(function (c) { return c !== contact; });
        }
        this.contactAutocomplete.clearValue();
    };
    ManageContactsModal.prototype.removeContact = function (contact) {
        this.removedContacts.push(contact);
        this.contacts = this.contacts.filter(function (c) { return c !== contact; });
        this.newContacts = this.newContacts.filter(function (c) { return c !== contact; });
    };
    ManageContactsModal.prototype.dismissWithoutSaving = function () {
        this.viewController.dismiss();
    };
    return ManageContactsModal;
}(BaseSlideModal));
export { ManageContactsModal };
