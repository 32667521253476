<ion-item [attr.detail-push]="onClick.observers.length > 0 ? true : null"
          class="activity-item"
          (click)="onClick.emit(activity)">
    <ion-icon name={{getIcon()}}
              circle
              item-left></ion-icon>
    <div class="activity-item--date-owner">
        <h2 class="activity-item--title"
            *ngIf="showActivityTitle">{{ activity.subject }}</h2>
        <p *ngIf="activity.description !== 'testing'"
           class="activity-item--date">
            Completed: {{ activity.completedDate | dateFormat }}
        </p>
        <p *ngIf="activity.description === 'testing'"
           class="activity-item--date">
            Due: {{ activity.dueDate | dateFormat }}
        </p>
         •
        <span [innerHTML]="getStatusHtml()"></span>
    </div>
    <p
        *ngIf="showSubInfo"
        class="activity-item--sub">
        {{ getSubInfo() }}
    </p>
    <div class="activity-item--description"
          *ngIf="activity.description">{{ activity.description }}</div>
</ion-item>
