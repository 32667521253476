import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector } from '@angular/core';
import { CommissionModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
import { ContactsService } from '../contacts/contacts-service';
import { UsersService } from '../users/users-service';
import { AlertController } from 'ionic-angular';
var CommissionsService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(CommissionsService, _super);
    function CommissionsService(injector, contactsService, usersService, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.contactsService = contactsService;
        _this.usersService = usersService;
        _this.alertController = alertController;
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.COMMISSIONS;
        _this.model = CommissionModel;
        _this.serviceName = SERVICE_NAMES.COMMISSIONS;
        return _this;
    }
    CommissionsService.prototype.fetch = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var commissions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.fetch.call(this, params)];
                    case 1:
                        commissions = _a.sent();
                        // NOTE: Eventually we will want to return Commissions with BOTH Contacts and Users attached.
                        //       This code does just that and can replace the current return code in the future.
                        // const comWithUsers = await this.attachUsers(commissions);
                        // const comWithContact = await this.attachContacts(commissions);
                        // return comWithUsers.concat(comWithContact);
                        return [2 /*return*/, this.attachUsers(commissions)];
                }
            });
        });
    };
    CommissionsService.prototype.attachContacts = function (commissions) {
        return __awaiter(this, void 0, void 0, function () {
            var contactIds, uniqueContactIds, contacts, filteredCommissions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        contactIds = commissions.map(function (commission) {
                            return commission.externalAgentId;
                        });
                        uniqueContactIds = Array.from(new Set(contactIds));
                        return [4 /*yield*/, this.contactsService.in(uniqueContactIds)];
                    case 1:
                        contacts = _a.sent();
                        commissions.forEach(function (commission) {
                            commission.externalAgentContact = contacts.find(function (contact) { return commission.externalAgentId === contact._id; });
                        });
                        filteredCommissions = commissions.filter(function (commission) { return commission.externalAgentContact; });
                        return [2 /*return*/, filteredCommissions];
                }
            });
        });
    };
    CommissionsService.prototype.attachUsers = function (commissions) {
        return __awaiter(this, void 0, void 0, function () {
            var userIds, uniqueUserIds, users, filteredCommissions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userIds = commissions.map(function (commission) {
                            return commission.internalAgentId;
                        });
                        uniqueUserIds = Array.from(new Set(userIds));
                        return [4 /*yield*/, this.usersService.in(uniqueUserIds)];
                    case 1:
                        users = _a.sent();
                        commissions.forEach(function (commission) {
                            commission.internalAgentUser = users.find(function (user) { return commission.internalAgentId === user._id; });
                        });
                        filteredCommissions = commissions.filter(function (commission) { return commission.internalAgentUser; });
                        return [2 /*return*/, filteredCommissions];
                }
            });
        });
    };
    return CommissionsService;
}(BaseService));
export { CommissionsService };
