<ion-card class="company-details-card">
    <ion-card-header>Details</ion-card-header>

    <ion-card-content>
        <div *ngIf="company.description">
            <ion-label stacked>Description</ion-label>
            <p class="dark-text" data-automation="company-description">{{company.description}}</p>
        </div>
        <div *ngIf="company.type">
            <ion-label stacked>Company type</ion-label>
            <p class="dark-text">{{company.type}}</p>
        </div>
        <div *ngIf="company.category">
            <ion-label stacked>Industry</ion-label>
            <p class="dark-text">{{company.category}}</p>
        </div>
        <div *ngIf="company.phone.number">
            <ion-label stacked>Phone</ion-label>
            <p class="dark-text">{{company.phone.number}}</p>
        </div>
        <div *ngIf="company.billingAddress.isSet">
            <ion-label stacked>Billing address</ion-label>
            <p class="dark-text" data-automation="company-billing-address">{{company.billingAddress.toString()}}</p>
        </div>
    </ion-card-content>
</ion-card>
