var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { PopoverController } from 'ionic-angular';
import { AnalyticsService, ConfigService, FeaturesService } from '@apto/services';
import { FieldCheckModal } from '../../modals/field-check-modal/field-check-modal';
import { END_POINTS } from '../../constants';
import { IonicTokenService } from '../ionic-token-service/ionic-token-service';
var AuthService = /** @class */ /*@__PURE__*/ (function () {
    function AuthService(analyticsService, featuresService, configService, http, popoverController, tokenService) {
        var _this = this;
        this.analyticsService = analyticsService;
        this.featuresService = featuresService;
        this.configService = configService;
        this.http = http;
        this.popoverController = popoverController;
        this.tokenService = tokenService;
        this.onSetUser = new BehaviorSubject(null);
        this.onDisplayFieldCheck = new EventEmitter();
        this.checkLogin = function () {
            return __awaiter(_this, void 0, void 0, function () {
                var token;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.tokenService.getToken()];
                        case 1:
                            token = _a.sent();
                            if (token) {
                                return [2 /*return*/, this.getUser()];
                            }
                            return [2 /*return*/];
                    }
                });
            });
        };
        this.getUser = function () {
            return new Promise(function (resolve) {
                _this.http.get(_this.configService.serviceUrl + "/" + END_POINTS.CHECK_LOGIN)
                    .subscribe(function (userInfo) {
                    if (!userInfo.id) {
                        if (_this.loginNavHandler) {
                            _this.loginNavHandler(null);
                        }
                        return resolve(null);
                    }
                    if (userInfo.enabledFeatures) {
                        _this.featuresService.setEnabledFeatures(JSON.parse(userInfo.enabledFeatures));
                    }
                    return resolve(userInfo);
                }, function () {
                    return resolve(null);
                });
            });
        };
        this.trackLogin = function (user, fieldCheckData) {
            if (!_this.analyticsService.isEnabled() || !user || !_this.configService.segmentKey) {
                return;
            }
            if (!AuthService.segmentKeyLoaded) {
                _this.analyticsService.loadSegmentKey(_this.configService.segmentKey);
                AuthService.segmentKeyLoaded = true;
            }
            var traits = Object.assign({}, user);
            traits.isTrial = fieldCheckData.isTrialOrg;
            traits.isUpgraded = !fieldCheckData.isUpgradeRequired;
            delete traits.segmentKey; // the current API returns the segment key for the user to use. this needs to be deprecated.
            _this.analyticsService.identify(user.legacyUserId || user.id, traits);
            _this.analyticsService.logEvent(fieldCheckData.isUpgradeRequired ? 'Version requirement not met' : 'Log in');
        };
        this.checkMissingFields = function (user) {
            return new Promise(function (resolve, reject) {
                _this.http.get(_this.configService.serviceUrl + "/" + END_POINTS.FIELD_CHECK, { observe: 'response' })
                    .subscribe(function (response) {
                    if (!response.url.startsWith(_this.configService.serviceUrl) ||
                        response.status !== 200) {
                        resolve(true);
                    }
                    var data = response.body.data;
                    _this.trackLogin(user, data);
                    if (data.objects.length || data.fields.length || data.isUpgradeRequired) {
                        _this.displayFieldCheckModal(data)
                            .then(function (result) {
                            return resolve(result);
                        })
                            .catch(function (err) {
                            reject(err);
                        });
                    }
                    else {
                        return resolve(true);
                    }
                }, function () {
                    // for now, resolve with true. default assumption is that the org is okay.
                    return resolve(true);
                });
            });
        };
    }
    AuthService.prototype.checkLoginAndNavigate = function () {
        var _this = this;
        var user;
        return this.checkLogin()
            .then(function (loginResult) {
            user = loginResult;
            if (user) {
                return _this.checkMissingFields(user);
            }
            else {
                return Promise.resolve(null);
            }
        })
            .then(function (missingFields) {
            if (user && missingFields && _this.loginNavHandler) {
                _this.user = user;
                _this.onSetUser.next(user);
                _this.loginNavHandler(user);
            }
            else if (_this.loginNavHandler) {
                _this.loginNavHandler(null);
            }
            return Promise.resolve(user && missingFields);
        });
    };
    AuthService.prototype.logout = function () {
        this.analyticsService.logEvent('Log out');
        this.user = null;
        this.logoutNavHandler();
    };
    AuthService.prototype.displayFieldCheckModal = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // there are currently 5 Apto-specific objects in the field check.
            // if we are missings that amount or more then we assume that Apto is not installed.
            var isAptoMissing = (data.objects.length >= 5);
            var isUpgradeRequired = data.isUpgradeRequired;
            var fieldCheckModal = _this.popoverController.create(FieldCheckModal, {
                missingFields: data.fields,
                missingObjects: data.objects,
                isAptoMissing: isAptoMissing,
                isUpgradeRequired: isUpgradeRequired
            }, {
                cssClass: 'large-popover-modal loading-popover-modal',
                enableBackdropDismiss: (isAptoMissing || isUpgradeRequired)
            });
            if (isAptoMissing || isUpgradeRequired) {
                reject('Required Apto version not found.');
            }
            else {
                fieldCheckModal.onDidDismiss(function (isFixed) {
                    return resolve(isFixed === true);
                });
            }
            fieldCheckModal.present();
        });
    };
    AuthService.segmentKeyLoaded = false;
    return AuthService;
}());
export { AuthService };
