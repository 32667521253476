import { __extends } from "tslib";
import { EventEmitter } from '@angular/core';
import { SelectableItem } from '../selectable-item/selectable-item';
import { ContactGroupModel, ContactListViewModel } from '@apto/models';
import './contact-group-item.scss';
var ContactGroupItem = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ContactGroupItem, _super);
    function ContactGroupItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.onClick = new EventEmitter();
        _this.selected = false;
        _this.isListView = function (group) {
            return group instanceof ContactListViewModel;
        };
        return _this;
    }
    return ContactGroupItem;
}(SelectableItem));
export { ContactGroupItem };
