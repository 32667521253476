import { EventEmitter } from '@angular/core';
import { TaskModel } from '@apto/models';
import './contact-tasks-card.scss';
var ContactTasksCard = /** @class */ /*@__PURE__*/ (function () {
    function ContactTasksCard() {
        this.isInitializing = true;
        this.sliceLength = 1;
        this.tasks = [];
        this.editEnabled = true;
        this.onTaskCompleted = new EventEmitter();
        this.onAddTask = new EventEmitter();
        this.onClick = new EventEmitter();
        this.onViewAllTasks = new EventEmitter();
        this.onViewMoreOpenTasks = new EventEmitter();
        this.taskIsCompleting = {};
        this.TaskModel = TaskModel;
    }
    ContactTasksCard.prototype.addTask = function () {
        this.onAddTask.emit();
    };
    ContactTasksCard.prototype.markTaskComplete = function (task) {
        if (task.isComplete || this.taskIsCompleting[task._id]) {
            return;
        }
        this.taskIsCompleting[task._id] = true;
        this.onTaskCompleted.emit({ task: task });
    };
    ContactTasksCard.prototype.view = function (task) {
        this.onClick.emit({
            task: task
        });
    };
    ContactTasksCard.prototype.viewAll = function () {
        this.onViewAllTasks.emit({
            contactId: this.tasks[0].contactId
        });
    };
    ContactTasksCard.prototype.viewMore = function () {
        this.onViewMoreOpenTasks.emit({
            contactId: this.tasks[0].contactId
        });
    };
    return ContactTasksCard;
}());
export { ContactTasksCard };
