import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from 'ionic-angular';
import { AnalyticsService, DealPartiesService } from '@apto/services';
import { ManageDealTeamModal, ManageDealTeamModalMode } from '@apto/pn/modals';
import './deal-team-card.scss';
var DealTeamCard = /** @class */ /*@__PURE__*/ (function () {
    function DealTeamCard(analyticsService, dealPartiesService, modalController) {
        this.analyticsService = analyticsService;
        this.dealPartiesService = dealPartiesService;
        this.modalController = modalController;
        this.dealParties = [];
        this.selectedRecord = [];
        this.onSelect = new EventEmitter();
        this.subscriptions = [];
    }
    DealTeamCard.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.dealPartiesService.onDeleted.subscribe(function (data) { return _this.removeDealParties(data.payload); }), this.dealPartiesService.onUpserted.subscribe(function (data) { return _this.updateDealParty(data.payload); }));
    };
    DealTeamCard.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    DealTeamCard.prototype.manageDealTeam = function () {
        var contactModal = this.modalController.create(ManageDealTeamModal, {
            mode: ManageDealTeamModalMode.EDIT,
            dealParties: this.dealParties.map(function (dealParty) { return Object.assign({}, dealParty); }),
            selectedRecord: this.selectedRecord
        });
        contactModal.present({ updateUrl: false });
        this.analyticsService.logEvent('Deal Team Card - Click Manage Button');
    };
    DealTeamCard.prototype.removeDealParties = function (ids) {
        var _this = this;
        ids.forEach((function (dealPartyId) {
            var indexToRemove = _this.dealParties.findIndex(function (originalDealParty) {
                return dealPartyId === originalDealParty._id;
            });
            _this.dealParties.splice(indexToRemove, 1);
        }));
    };
    DealTeamCard.prototype.updateDealParty = function (dealParty) {
        this.dealPartiesService.updateInCache(dealParty, dealParty._id);
        var indexToReplace = this.dealParties.findIndex(function (originalDealParty) {
            return dealParty._id === originalDealParty._id;
        });
        if (indexToReplace < 0) {
            this.dealParties = this.dealParties.concat(dealParty);
        }
        else {
            this.dealParties.splice(indexToReplace, 1, dealParty);
        }
    };
    return DealTeamCard;
}());
export { DealTeamCard };
