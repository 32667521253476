import { EventEmitter } from '@angular/core';
import { CompModel, ContractModel, ListingModel, PursuitModel, } from '@apto/models';
var ClientCard = /** @class */ /*@__PURE__*/ (function () {
    function ClientCard() {
        this.isInitializingClient = false;
        this.onClickCompany = new EventEmitter();
        this.onClickContact = new EventEmitter();
    }
    Object.defineProperty(ClientCard.prototype, "displayName", {
        get: function () {
            if (this.pipelineObject instanceof CompModel) {
                if (this.pipelineObject.type ===
                    CompModel.DEAL_TYPES.SALE + (this.pipelineObject.isExternal ? ' (External)' : '')) {
                    this.company = this.pipelineObject.sellerCompany;
                    this.contact = this.pipelineObject.sellerContact;
                    return this.getDisplayName();
                }
                else if (this.pipelineObject.type ===
                    CompModel.DEAL_TYPES.LEASE + (this.pipelineObject.isExternal ? ' (External)' : '')) {
                    this.company = this.pipelineObject.landlordCompany;
                    this.contact = this.pipelineObject.landlordContact;
                    return this.getDisplayName();
                }
            }
            if (this.pipelineObject instanceof ContractModel) {
                if (this.pipelineObject.type === ContractModel.TYPES.PENDING_LEASE) {
                    this.company = this.pipelineObject.landlordCompany;
                    this.contact = this.pipelineObject.landlordContact;
                    return this.getDisplayName();
                }
                else if (this.pipelineObject.type === ContractModel.TYPES.PENDING_SALE) {
                    this.company = this.pipelineObject.sellerCompany;
                    this.contact = this.pipelineObject.sellerContact;
                    return this.getDisplayName();
                }
            }
            if (this.pipelineObject instanceof ListingModel ||
                this.pipelineObject instanceof PursuitModel) {
                this.company = this.pipelineObject.clientCompany;
                this.contact = this.pipelineObject.clientContact;
                return this.getDisplayName();
            }
        },
        enumerable: true,
        configurable: true
    });
    ClientCard.prototype.viewClient = function () {
        this.onClickCompany.emit({ company: this.company || this.contact });
    };
    Object.defineProperty(ClientCard.prototype, "hasCompany", {
        get: function () {
            return !!this.company;
        },
        enumerable: true,
        configurable: true
    });
    ClientCard.prototype.getDisplayName = function () {
        return this.company ? this.company.name : this.contact ? this.contact.fullName : '';
    };
    return ClientCard;
}());
export { ClientCard };
