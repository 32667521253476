var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SimpleChanges, OnInit, OnChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertyModel } from '@apto/models';
import { ContactsService, PropertiesService, TypesService } from '@apto/services';
import { INTEGER_REGEX } from '@apto/shared/constants';
import { AutoCompleteComponent } from '@apto/autocomplete';
var EditSpaceForm = /** @class */ /*@__PURE__*/ (function () {
    function EditSpaceForm(contactsService, propertiesService, typesService, formBuilder) {
        this.contactsService = contactsService;
        this.propertiesService = propertiesService;
        this.typesService = typesService;
        this.formBuilder = formBuilder;
        this.isSaving = false;
        this.propertyModelInstance = PropertyModel;
        this.picklistOptions = {};
    }
    EditSpaceForm.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (!this.space) {
                    this.space = new PropertyModel();
                }
                this.getTypes();
                this.setupForm();
                this.getPicklistOptions();
                return [2 /*return*/];
            });
        });
    };
    EditSpaceForm.prototype.getTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var types, propTypes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        types = _a.sent();
                        propTypes = types.propertyTypes;
                        this.spaceRecordType = propTypes.Space || null;
                        return [2 /*return*/];
                }
            });
        });
    };
    EditSpaceForm.prototype.getPicklistOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.propertiesService.getPicklistOptions([
                                'spaceStatus',
                                'spaceSubType',
                                'spaceType'
                            ])];
                    case 1:
                        _a.picklistOptions = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EditSpaceForm.prototype.ngOnChanges = function (changes) {
        if (changes.space &&
            changes.space.previousValue &&
            changes.space.previousValue._id !== changes.space.currentValue._id) {
            this.setupForm();
        }
    };
    EditSpaceForm.prototype.saveRelationship = function (autoComplete, attribute, attributeId) {
        if (!autoComplete.getValue()) { // if the text has been deleted, clear the related values
            this.space[attribute] = this.space[attributeId] = null;
        }
        else { // otherwise, set values based on the current selection
            var selection = autoComplete.getSelection();
            if (selection) {
                this.space[attribute] = selection;
                this.space[attributeId] = selection._id;
            }
        }
    };
    EditSpaceForm.prototype.setQuickAddRecord = function (record, autoComplete, attribute, attributeId) {
        this.space[attribute] = record;
        if (this.space[attribute]) {
            this.space[attributeId] = record._id;
            autoComplete.setValue(this.space[attribute].name);
        }
    };
    EditSpaceForm.prototype.save = function () {
        var _this = this;
        if (this.spaceForm.valid) {
            Object.assign(this.space, this.spaceForm.value);
            if (!this.passedInParentProperty) {
                this.saveRelationship(this.parentPropertyAutoComplete, 'parentProperty', 'parentPropertyId');
            }
            this.space.parentPropertyId = this.passedInParentProperty._id;
            this.space.recordTypeId = this.spaceRecordType;
            return {
                space: this.space,
                parentProperty: this.passedInParentProperty
            };
        }
        else {
            Object.keys(this.spaceForm.controls).forEach(function (fieldKey) {
                _this.spaceForm.controls[fieldKey].markAsDirty();
            });
        }
    };
    EditSpaceForm.prototype.setupForm = function () {
        this.setProperty(this.parentPropertyAutoComplete, 'parentProperty', 'parentPropertyId');
        var spaceName = this.space && this.space.name || '';
        this.spaceForm = this.formBuilder.group({
            description: [this.space && this.space.description || '', Validators.compose([Validators.maxLength(32000)])],
            floor: [this.space && this.space.floor || '1', Validators.compose([
                    Validators.required, Validators.maxLength(25)
                ])
            ],
            name: [spaceName, Validators.compose([Validators.required, Validators.maxLength(80)])],
            spaceStatus: this.space && this.space.spaceStatus || '',
            spaceType: this.space && this.space.spaceType || '',
            spaceSubType: this.space && this.space.spaceSubType || '',
            squareFootage: [this.space && this.space.squareFootage || '', Validators.compose([
                    Validators.pattern(INTEGER_REGEX), Validators.maxLength(19)
                ])
            ],
            suiteFloor: [this.space && this.space.suiteFloor || '', Validators.compose([Validators.maxLength(20)])]
        });
    };
    EditSpaceForm.prototype.hasParentProperty = function () {
        return this.passedInParentProperty && this.passedInParentProperty._id;
    };
    EditSpaceForm.prototype.setProperty = function (autoComplete, attribute, attributeId) {
        return __awaiter(this, void 0, void 0, function () {
            var properties;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!this.space[attribute] && this.space[attributeId]))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.propertiesService.in([this.space[attributeId]])];
                    case 1:
                        properties = _a.sent();
                        this.space[attribute] = properties.length && properties[0] || null;
                        _a.label = 2;
                    case 2:
                        if (this.space[attribute]) {
                            autoComplete.setValue(this.space[attribute].name);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    return EditSpaceForm;
}());
export { EditSpaceForm };
