<ion-header class="modal-header property-details-modal-header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton();"
                    data-automation="modalBackButton">
                <apto-icon icon="arrowBack"
                           inline
                           size="4"></apto-icon>
            </button>
        </ion-buttons>

        <ion-title *ngIf="mode === Modes.LIST">Properties</ion-title>
        <ion-title *ngIf="mode === Modes.VIEW">{{ selectedRecord.forSale ? 'Listing Details' : 'Property Details' }}</ion-title>
        <ion-title *ngIf="mode === Modes.EDIT">{{ selectedRecord._id ? 'Edit' : 'Add' }} Property</ion-title>

        <ion-buttons end>
            <button *ngIf="mode === Modes.VIEW"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="setMode(Modes.EDIT)"
                    [disabled]="isLoadingComps || isLoadingListings || isLoadingOwnerships"
                    data-automation="modal-edit-button">
                EDIT
            </button>
            <button *ngIf="mode === Modes.EDIT && selectedRecord._id"
                    ion-button
                    clear
                    color="danger"
                    hold-button
                    (hold)="delete()"
                    data-automation="modal-delete-button">
                Hold to Delete
            </button>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    data-automation="modal-save-button">
                SAVE
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismissWithoutSaving()"
                    data-automation="modal-dismiss-button">
                <apto-icon icon="close"
                           inline
                           size="4"></apto-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>
<ion-content class="property-details-modal">
    <ion-slides>
        <ion-slide>
            <ion-content>
                <ion-card>
                    <ion-item class="sort-by-select">
                        <span>Sort By</span>
                        <apto-ionic-select [options]="['Name', 'Value']"
                                           [value]="selectedSortKey"
                                           (onSelectOption)="sortChanged($event.value)"></apto-ionic-select>
                    </ion-item>

                    <ion-list>
                        <prop-item *ngFor="let property of records"
                                   [property]="property"
                                   (onClick)="view(property)"></prop-item>
                    </ion-list>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide class="property-details-modal-info">
            <apto-container scroll>
            <property-summary-card [property]="selectedRecord"
                                   [isShownOnMap]="isShownOnMap"
                                   [hasDisableAttomBadge]="hasDisableAttomBadge"
                                   [attomDataBadgeCount]="attomDataBadgeCount"
                                   [attomDataPointCount]="attomDataPointCount"
                                   [attomDataOwnerCount]="attomDataOwnerCount"
                                   [hasLoadedAttomData]="hasLoadedAttomData"></property-summary-card>
                <ng-template #overviewTab>
                    <!--
                        This overviewTab template contains the contents of the overview tab
                        so it can be rendered in the tabs if spaces is enabled or as it's own
                        section if spaces is disabled. We don't have to duplicate the code this way.
                    -->
                    <apto-row class="property-details-modal-overview">
                        <apto-col md="6">
                            <photo-scrollview [photoUris]="photos"></photo-scrollview>
                            <text-card *ngIf="showListingInfo()"
                                       [isInitializing]="isLoadingListings"
                                       [title]="'Listing Name'"
                                       [text]="listing.name"></text-card>
                            <text-card *ngIf="showListingInfo() && listing.askingPriceActual"
                                       [isInitializing]="isLoadingListings"
                                       [title]="'Listing Price'"
                                       [text]="'$' + listing.askingPriceActual | numberWithCommas"></text-card>
                            <property-details-card [property]="selectedRecord"></property-details-card>
                            <property-additional-info-card [property]="selectedRecord"></property-additional-info-card>
                            <unit-mix-card *ngIf="showUnitMix"
                                           [property]="selectedRecord"></unit-mix-card>
                        </apto-col>
                        <apto-col md="6">
                            <text-card [title]="'Description'"
                                       [text]="selectedRecord.description"></text-card>
                            <property-ownerships-card [isInitializing]="isLoadingOwnerships"
                                                      [ownerships]="ownerships"
                                                      (onClickCompany)="viewCompany($event)"
                                                      (onClickContact)="viewContact($event)"
                                                      (onManageOwnerships)="editOwnerships()"
                                                      (onViewAllOwnerships)="viewOwnerships();"></property-ownerships-card>
                            <transaction-history-card [isInitializing]="isLoadingComps"
                                                      [transactionHistory]="comps"
                                                      (onViewTransaction)="viewTransaction($event)"></transaction-history-card>
                        </apto-col>
                    </apto-row>
                </ng-template>

                <apto-tab-group *ngIf="spacesEnabled || attomDataPointsEnabled; else spacesDisabledTemplate"
                                class="property-details-modal--tabs"
                                [selectedIndex]="spacesTabIndex"
                                (selectedTabChange)="tabChanged($event)">
                    <apto-tab label="Overview"
                              automation="property-details-overview-tab">
                        <ng-template [ngTemplateOutlet]="overviewTab"></ng-template>
                    </apto-tab>
                    <!-- <apto-tab label="Rent roll" automation="property-details-rent-roll-tab">
                            <apto-card>
                                <apto-card-header>Rent roll</apto-card-header>
                                <apto-card-content>Rent roll content</apto-card-content>
                            </apto-card>
                        </apto-tab> -->
                    <apto-tab *ngIf="spacesEnabled"
                              label="Stacking plan"
                              automation="property-details-stacking-plan-tab">
                        <apto-row>
                            <apto-col>
                                <stacking-plan-card
                                    [property]="selectedRecord"
                                    (addSpaceClick)="addSpace()"
                                    (openSpaceClick)="openSpace($event)">
                                </stacking-plan-card>
                            </apto-col>
                        </apto-row>
                    </apto-tab>
                    <apto-tab *ngIf="attomDataPointsEnabled"
                              label="Augmented Data"
                              automation="property-details-augmented-data-tab">
                        <apto-card>
                            <apto-card-header>Mortgage / Owners</apto-card-header>
                            <apto-card-content>
                                <apto-paragraph compact
                                                *ngFor="let field of attomData.mortgage | keys">
                                    <span *ngIf="attomData.mortgage[field] &&
                                                isObject(attomData.mortgage[field]) &&
                                                !isEmpty(attomData.mortgage[field])">
                                        <strong>{{ field }}</strong> {{ attomData.mortgage[field] | json }}
                                    </span>
                                    <span *ngIf="attomData.mortgage[field] && !isObject(attomData.mortgage[field])">
                                        <strong>{{ field }}</strong> {{ attomData.mortgage[field] }}
                                    </span>
                                </apto-paragraph>
                                <apto-paragraph compact
                                                *ngFor="let field of attomData.owner | keys">
                                    <span *ngIf="attomData.owner[field] &&
                                                isObject(attomData.owner[field]) &&
                                                !isEmpty(attomData.owner[field])">
                                        <strong>{{ field }}</strong> {{ attomData.owner[field] | json }}
                                    </span>
                                    <span *ngIf="attomData.owner[field] && !isObject(attomData.owner[field])">
                                        <strong>{{ field }}</strong> {{ attomData.owner[field] }}
                                    </span>
                                </apto-paragraph>
                            </apto-card-content>
                        </apto-card>

                        <apto-card>
                            <apto-card-header>Record</apto-card-header>
                            <apto-card-content>
                                <apto-paragraph compact *ngFor="let field of attomData.fields | keys">
                                    <span *ngIf="attomData.fields[field] &&
                                                isObject(attomData.fields[field]) &&
                                                !isEmpty(attomData.fields[field])">
                                        <strong>{{ field }}</strong> <pre>{{ attomData.fields[field] | json }}</pre>
                                    </span>
                                    <span *ngIf="attomData.fields[field] && !isObject(attomData.fields[field])">
                                        <strong>{{ field }}</strong> {{ attomData.fields[field] }}
                                    </span>
                                </apto-paragraph>
                            </apto-card-content>
                        </apto-card>

                        <apto-card>
                            <apto-card-header>Sales</apto-card-header>
                            <apto-card-content>
                                <apto-paragraph compact
                                                *ngFor="let sale of attomData.sales">
                                    <pre>{{ sale | json }}</pre>
                                </apto-paragraph>
                            </apto-card-content>
                        </apto-card>

                        <apto-card>
                            <apto-card-header>Taxes</apto-card-header>
                            <apto-card-content>
                                <apto-paragraph compact
                                                *ngFor="let tax of attomData.taxes">
                                    <h3>{{ tax.tax.taxyear }}</h3>
                                    <pre>{{ tax | json }}</pre>
                                </apto-paragraph>
                            </apto-card-content>
                        </apto-card>

                        <busy-panel *ngIf="!hasLoadedAttomData"
                                    [message]="'Loading Augmented Data'"></busy-panel>
                    </apto-tab>
                </apto-tab-group>

                <ng-template #spacesDisabledTemplate>
                    <ng-template [ngTemplateOutlet]="overviewTab"></ng-template>
                </ng-template>

            </apto-container>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide">
            <ion-content>
                <edit-property-form [model]="selectedRecord"
                                    [ownerships]="ownerships"
                                    [types]="types"></edit-property-form>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>
