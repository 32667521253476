import { ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompModel, CompanyModel, ContactModel, ContractModel, ListingModel, PropertyModel, PursuitModel, TaskModel } from '@apto/models';
import { FeaturesService } from '@apto/services';
import { AuthService } from '@apto/ionic-lib/services';
import './header-navbar.scss';
var HeaderNavbar = /** @class */ /*@__PURE__*/ (function () {
    function HeaderNavbar(authService, router, featuresService) {
        this.authService = authService;
        this.router = router;
        this.featuresService = featuresService;
        this.headerNavbarUserDropdownEnabled = false;
        this.headerNavbarHelpDropdownEnabled = false;
        this.headerNavbarExportsDropdownEnabled = false;
        this.exportsProcessing = false;
        this.isOrganizeDropdownVisible = false;
    }
    HeaderNavbar.prototype.ngOnInit = function () {
        if (this.authService.user && this.authService.user.userId) {
            this.loggedInUserName = this.authService.user.userId;
        }
        else {
            this.loggedInUserName = 'Logged In';
        }
        this.activeViewName = this.router.routerState.snapshot.url;
    };
    Object.defineProperty(HeaderNavbar.prototype, "isOnOrganizePage", {
        get: function () {
            return this.activeViewName === 'TasksPage' ||
                this.activeViewName === 'PropertiesPage' ||
                this.activeViewName === 'ContactsPage' ||
                this.activeViewName === 'CompaniesPage';
        },
        enumerable: true,
        configurable: true
    });
    // toggles the dropdowns so multiple aren't open at once
    HeaderNavbar.prototype.toggleDropdown = function (event, dropdown) {
        switch (dropdown) {
            case 'organize':
                this.headerNavbarUserDropdownEnabled = false;
                this.headerNavbarHelpDropdownEnabled = false;
                this.headerNavbarExportsDropdownEnabled = false;
                this.isOrganizeDropdownVisible = !this.isOrganizeDropdownVisible;
                break;
            case 'user':
                this.headerNavbarUserDropdownEnabled = !this.headerNavbarUserDropdownEnabled;
                this.headerNavbarHelpDropdownEnabled = false;
                this.headerNavbarExportsDropdownEnabled = false;
                this.isOrganizeDropdownVisible = false;
                break;
            case 'help':
                this.headerNavbarHelpDropdownEnabled = !this.headerNavbarHelpDropdownEnabled;
                this.headerNavbarUserDropdownEnabled = false;
                this.headerNavbarExportsDropdownEnabled = false;
                this.isOrganizeDropdownVisible = false;
                break;
            case 'file':
                this.headerNavbarHelpDropdownEnabled = false;
                this.headerNavbarUserDropdownEnabled = false;
                this.headerNavbarExportsDropdownEnabled = !this.headerNavbarExportsDropdownEnabled;
                // remove highlights from export dropdown once they open it.
                this.exportDropdown.nativeElement.classList.remove('header-navbar--export-complete');
                this.exportDropdown.nativeElement.classList.remove('header-navbar--export-processing');
                this.isOrganizeDropdownVisible = false;
                break;
            default:
                this.closeAllDropdowns();
                break;
        }
    };
    // close dropdowns when clicking outside of the components
    HeaderNavbar.prototype.onOutsideClick = function (event) {
        var isOutside = true;
        this.headerDropdownWrappers.forEach(function (container) {
            if (container.nativeElement.contains(event.target)) {
                isOutside = false;
            }
        });
        if (isOutside) {
            this.closeAllDropdowns();
        }
    };
    HeaderNavbar.prototype.closeAllDropdowns = function () {
        this.headerNavbarUserDropdownEnabled = false;
        this.headerNavbarHelpDropdownEnabled = false;
        this.headerNavbarExportsDropdownEnabled = false;
        this.isOrganizeDropdownVisible = false;
    };
    HeaderNavbar.prototype.searchItemSelected = function ($event) {
        switch ($event.item.constructor) {
            case CompanyModel:
                this.router.navigate(['/companies', $event.item._id]);
                break;
            case ContactModel:
                this.router.navigate(['/contacts', $event.item._id]);
                break;
            case PropertyModel:
                this.router.navigate(['/property-map', $event.item._id]);
                break;
            case TaskModel:
                this.router.navigate(['/tasks', $event.item._id]);
                break;
            case ListingModel:
                this.router.navigate(['/deals', $event.item._id]);
                break;
            case PursuitModel:
                this.router.navigate(['/deals', $event.item._id]);
                break;
            case ContractModel:
                this.router.navigate(['/deals', $event.item._id]);
                break;
            case CompModel:
                this.router.navigate(['/deals', $event.item._id]);
        }
    };
    HeaderNavbar.prototype.onLogout = function () {
        this.authService.logout();
    };
    // highlight the export icon when export is finished
    HeaderNavbar.prototype.onExportComplete = function (file) {
        this.exportDropdown.nativeElement.classList.add('header-navbar--export-complete');
    };
    // highlight export dropdown when processing export
    HeaderNavbar.prototype.onProcessingStart = function ($event) {
        this.exportsProcessing = true;
        this.exportDropdown.nativeElement.classList.add('header-navbar--export-processing');
    };
    // remove highlight from export dropdown when processing export finished
    HeaderNavbar.prototype.onProcessingEnd = function ($event) {
        this.exportsProcessing = false;
        this.exportDropdown.nativeElement.classList.remove('header-navbar--export-processing');
    };
    return HeaderNavbar;
}());
export { HeaderNavbar };
