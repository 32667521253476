import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s)
        if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
            t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++)
            if (e.indexOf(p[i]) < 0)
                t[p[i]] = s[p[i]];
    return t;
};
import { NavParams, ViewController } from 'ionic-angular';
import { PopoverController } from 'ionic-angular';
import { AutoCompleteComponent } from '@apto/autocomplete';
import { ContractModel, CompModel, DealPartyModel, ListingModel, OfferModel, PursuitModel } from '@apto/models';
import { ContactsService, DealPartiesService } from '@apto/services';
import { SelectPopover } from '@apto/ionic-lib/components';
import { BaseSlideModal } from '@apto/shared/modals';
import { EditOfferForm } from '@apto/pn/components';
export var ManageDealTeamModalMode = /*@__PURE__*/ (function (ManageDealTeamModalMode) {
    ManageDealTeamModalMode[ManageDealTeamModalMode["LIST"] = 0] = "LIST";
    ManageDealTeamModalMode[ManageDealTeamModalMode["VIEW"] = 1] = "VIEW";
    ManageDealTeamModalMode[ManageDealTeamModalMode["EDIT"] = 2] = "EDIT";
    return ManageDealTeamModalMode;
})({});
import './manage-deal-team-modal.scss';
var ManageDealTeamModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ManageDealTeamModal, _super);
    function ManageDealTeamModal(contactsService, dealPartiesService, navParams, popoverController, viewController) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.contactsService = contactsService;
        _this.dealPartiesService = dealPartiesService;
        _this.popoverController = popoverController;
        _this.dealParties = [];
        _this.selectedRecord = [];
        _this.isInitializing = false;
        _this.Modes = ManageDealTeamModalMode;
        _this.records = [];
        _this.roleOptions = Object.values(DealPartyModel.ROLES);
        _this.removedDealParties = [];
        _this.created = new Set();
        _this.modified = new Set();
        _this.removed = new Set();
        _this.modalUrls = new Map([
            [ManageDealTeamModalMode.VIEW, '/:taskId']
        ]);
        _this.mode = navParams.get('mode') || ManageDealTeamModalMode.LIST;
        _this.title = navParams.get('title') || 'Open Offers';
        _this.selectedRecord = navParams.get('selectedRecord') || new OfferModel();
        _this.dealParties = navParams.get('dealParties') || [];
        return _this;
    }
    ManageDealTeamModal.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var removedIds, upsertCreated, upsertModified;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isInitializing = true;
                        if (!this.removed.size)
                            return [3 /*break*/, 2];
                        removedIds = Array.from(this.removed).map(function (dealParty) { return dealParty._id; });
                        return [4 /*yield*/, this.dealPartiesService.delete(removedIds)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!(this.modified.size || this.created.size))
                            return [3 /*break*/, 5];
                        upsertCreated = Array.from(this.created).map(function (dealParty) { return _this.dealPartiesService.upsert(dealParty); });
                        upsertModified = Array.from(this.modified).map(function (dealParty) {
                            var saleDate = dealParty.saleDate, partyToSave = __rest(dealParty, ["saleDate"]);
                            return _this.dealPartiesService.upsert(partyToSave);
                        });
                        return [4 /*yield*/, Promise.all(upsertCreated)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, Promise.all(upsertModified)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.isInitializing = false;
                        this.dismiss();
                        return [2 /*return*/];
                }
            });
        });
    };
    ManageDealTeamModal.prototype.saveStageId = function (dealParty, selectedRecord) {
        if (selectedRecord instanceof PursuitModel) {
            dealParty.pursuitId = this.selectedRecord._id;
        }
        else if (selectedRecord instanceof ListingModel) {
            dealParty.listingId = this.selectedRecord._id;
        }
        else if (selectedRecord instanceof ContractModel) {
            dealParty.contractId = this.selectedRecord._id;
        }
        else if (selectedRecord instanceof CompModel) {
            dealParty.compId = this.selectedRecord._id;
        }
    };
    ManageDealTeamModal.prototype.addDealParty = function (contact, selectedRecord) {
        var dealParty = new DealPartyModel({
            contactId: contact._id,
            contact: contact
        });
        if (!this.dealParties.find(function (dp) { return dp.contactId === contact._id; })) {
            this.saveStageId(dealParty, selectedRecord);
            this.modifyRecords(dealParty, 'add');
            this.dealParties.push(dealParty);
        }
        this.contactAutocomplete.clearValue();
    };
    ManageDealTeamModal.prototype.removeDealParty = function (removedDealParty) {
        this.modifyRecords(removedDealParty, 'remove');
        var index = this.dealParties.findIndex(function (dealParty) { return dealParty.contactId === removedDealParty.contactId; });
        this.dealParties.splice(index, 1);
    };
    ManageDealTeamModal.prototype.changeOwnerStatus = function (ev, dealParty) {
        var _this = this;
        var popover = this.popoverController.create(SelectPopover, {
            options: this.roleOptions.map(function (option) { return ({ value: option }); })
        });
        popover.onDidDismiss(function (value) {
            dealParty.role = value;
            _this.modifyRecords(dealParty, 'modify');
        });
        popover.present({ ev: ev });
    };
    ManageDealTeamModal.prototype.modifyRecords = function (dealPartyMember, type) {
        if ((type === 'add' || type === 'modify') && !dealPartyMember._id) {
            this.created.add(dealPartyMember);
        }
        else if ((type === 'add' || type === 'modify') && dealPartyMember._id) {
            this.modified.add(dealPartyMember);
        }
        else if (type === 'remove') {
            this.created.delete(dealPartyMember);
            this.modified.delete(dealPartyMember);
            if (dealPartyMember._id) {
                this.removed.add(dealPartyMember);
            }
        }
    };
    ManageDealTeamModal.prototype.dismissWithoutSaving = function () {
        this.viewController.dismiss();
    };
    return ManageDealTeamModal;
}(BaseSlideModal));
export { ManageDealTeamModal };
