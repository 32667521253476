import { CallListModel } from '@apto/models';
import './call-list-statistics-card.scss';
var CallListStatisticsCard = /** @class */ /*@__PURE__*/ (function () {
    function CallListStatisticsCard() {
        this.CallListModel = CallListModel;
    }
    Object.defineProperty(CallListStatisticsCard.prototype, "numberOfMembers", {
        get: function () {
            if (!this.groups) {
                return 0;
            }
            return this.groups.reduce(function (sum, group) {
                return sum + (group.numberOfMembers || 0);
            }, 0);
        },
        enumerable: true,
        configurable: true
    });
    return CallListStatisticsCard;
}());
export { CallListStatisticsCard };
