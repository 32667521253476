var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { debounceTime } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ToastController } from 'ionic-angular';
import { LogsService } from '@apto/ionic-lib/services';
import { INPUT_DEBOUNCE, TOAST_DURATION } from '@apto/ionic-lib/constants';
import { ExportService, ExportFileStatus } from '@apto/shared/services';
import './export-manager.scss';
var ExportManager = /** @class */ /*@__PURE__*/ (function () {
    function ExportManager(logsService, exportService, toastController) {
        this.logsService = logsService;
        this.exportService = exportService;
        this.toastController = toastController;
        this.onExportStart = new EventEmitter();
        this.onExportComplete = new EventEmitter();
        this.onProcessingStart = new EventEmitter();
        this.onProcessingEnd = new EventEmitter();
        this.documents = [];
        this.searchTerm = '';
        this.successStatus = ExportFileStatus.SUCCESS;
        this.failStatus = ExportFileStatus.FAILED;
        this.pendingStatus = ExportFileStatus.PENDING;
        this.isLoading = false;
        this.showHelpMessage = false;
    }
    ExportManager.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.searchControl = new FormControl();
                        return [4 /*yield*/, this.getDocuments()];
                    case 1:
                        _a.sent();
                        // we don't have any documents on first run so assume we should show the help
                        if (!this.documents.length) {
                            this.showHelpMessage = true;
                        }
                        this.exportService.onExportComplete.subscribe(function (file) {
                            var existingFile = _this.documents.find(function (doc) { return doc.id === file.id; });
                            if (existingFile) {
                                existingFile.status = file.status;
                                existingFile.size = file.size;
                            }
                            else {
                                _this.documents.unshift(file);
                            }
                            _this.onExportComplete.emit(file);
                            _this.showToast(file);
                        }, this.logsService.error);
                        this.exportService.onPollingStart.subscribe(function (e) {
                            _this.onProcessingStart.emit(e);
                        }, this.logsService.error);
                        this.exportService.onExportStart.subscribe(function (e) {
                            _this.getDocuments();
                            _this.onExportStart.emit(e);
                        }, this.logsService.error);
                        this.exportService.onPollingEnd.subscribe(function (e) {
                            _this.onProcessingEnd.emit(e);
                        }, this.logsService.error);
                        // poll any initial docs that are pending
                        this.pollInitialDocuments();
                        // search field changes
                        this.searchControl.valueChanges.pipe(debounceTime(INPUT_DEBOUNCE)).subscribe(function (search) {
                            var query = [];
                            if (_this.valid()) {
                                query.push("name__li=" + _this.searchTerm.replace('\/g', '\\/'));
                            }
                            _this.getDocuments(query.join('&'));
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    ExportManager.prototype.reset = function (e) {
        e.stopPropagation();
        this.searchTerm = '';
    };
    ExportManager.prototype.valid = function () {
        return this.searchTerm.length > 0;
    };
    // poll any docs that are pending
    ExportManager.prototype.pollInitialDocuments = function () {
        var _this = this;
        this.documents.forEach(function (d) {
            if (d.status === ExportFileStatus.PENDING) {
                _this.exportService.addExportToPoller(d.id);
            }
        });
    };
    ExportManager.prototype.getDocuments = function (query) {
        if (query === void 0) {
            query = '';
        }
        return __awaiter(this, void 0, void 0, function () {
            var result, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.isLoading)
                            return [3 /*break*/, 5];
                        this.isLoading = true;
                        this.showHelpMessage = false;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.exportService.getExports(query)];
                    case 2:
                        result = _a.sent();
                        this.documents = result.data.results || [];
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.logsService.error(err_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.isLoading = false;
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    ExportManager.prototype.showToast = function (file) {
        var message;
        if (file.status === ExportFileStatus.SUCCESS) {
            message = "Your export \"" + file.name + "\" is ready for download.";
        }
        else {
            message = "An error occured for export \"" + file.name + "\".";
        }
        var toast = this.toastController.create({
            duration: TOAST_DURATION,
            message: message,
            showCloseButton: true
        });
        toast.present();
    };
    return ExportManager;
}());
export { ExportManager };
