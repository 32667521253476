import { EventEmitter } from '@angular/core';
import { ContactModel } from '@apto/models';
import './call-list-contact-item.scss';
var CallListContactItem = /** @class */ /*@__PURE__*/ (function () {
    function CallListContactItem() {
        this.buttons = [];
        this.onButtonClicked = new EventEmitter();
        this.contact = new ContactModel();
        this.lists = 0;
    }
    CallListContactItem.prototype.ngOnInit = function () {
        this.contact = this.groupMember.contact;
        this.timeAgo = this.groupMember.completedDate;
    };
    return CallListContactItem;
}());
export { CallListContactItem };
