import { __extends } from "tslib";
import { EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BaseFilter } from '../';
import './date-range-filter.scss';
var DateRangeFilter = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(DateRangeFilter, _super);
    function DateRangeFilter(formBuilder) {
        var _this = _super.call(this) || this;
        _this.formBuilder = formBuilder;
        _this.startDatePlaceholder = '';
        _this.stopDatePlaceholder = '';
        _this.hasInitialized = false;
        _this.onClearCalendar = new EventEmitter();
        _this.startDateOptions = {};
        _this.stopDateOptions = { alignSelectorRight: true };
        _this.form = _this.formBuilder.group({
            startDate: null,
            stopDate: null
        });
        return _this;
    }
    DateRangeFilter.prototype.ngOnInit = function () {
        var _this = this;
        this.form.get('startDate').valueChanges.subscribe(function (value) {
            _this.onQueryChange.emit();
        });
        this.form.get('stopDate').valueChanges.subscribe(function (value) {
            _this.onQueryChange.emit();
        });
    };
    DateRangeFilter.prototype.getQuery = function () {
        var query = {};
        var isValid = this.validate();
        if (isValid && !this.isEmpty()) {
            if (this.form.controls.startDate.value && !this.form.controls.stopDate.value) {
                query[this.queryKey] = {
                    $gte: this.form.controls.startDate.value
                };
            }
            else if (!this.form.controls.startDate.value && this.form.controls.stopDate.value) {
                query[this.queryKey] = {
                    $lte: this.form.controls.stopDate.value
                };
            }
            else {
                var from = {};
                var to = {};
                from[this.queryKey] = { $gte: this.form.controls.startDate.value };
                to[this.queryKey] = { $lte: this.form.controls.stopDate.value };
                query.$and = [];
                query.$and.push(from);
                query.$and.push(to);
            }
        }
        return query;
    };
    DateRangeFilter.prototype.validate = function () {
        if (this.form.controls.startDate.value &&
            this.form.controls.stopDate.value &&
            this.form.controls.startDate.value > this.form.controls.stopDate.value) {
            return false;
        }
        return !this.form.controls.startDate.errors && !this.form.controls.stopDate.errors;
    };
    DateRangeFilter.prototype.reset = function () {
        this.onClearCalendar.emit();
    };
    DateRangeFilter.prototype.isEmpty = function () {
        return !this.form.controls.stopDate.value && !this.form.controls.startDate.value;
    };
    return DateRangeFilter;
}(BaseFilter));
export { DateRangeFilter };
