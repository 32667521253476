import { Content, NavParams, Slides, ViewController } from 'ionic-angular';
import { CallListModel, ContactGroupModel, ContactListViewModel } from '@apto/models';
import { AppService } from '@apto/services';
import { AddCallListDetails } from '../../components/add-call-list-details/add-call-list-details';
import { CallListGroupSelector } from '../../components/call-list-group-selector/call-list-group-selector';
import './call-list-setup-modal.scss';
var CallListSetupModal = /** @class */ /*@__PURE__*/ (function () {
    function CallListSetupModal(navParams, viewController) {
        var _this = this;
        this.viewController = viewController;
        this.isEditMode = false;
        this.selectedGroups = [];
        this.contactGroupsPromise = navParams.get('contactGroupsPromise');
        this.contactListViewsPromise = navParams.get('contactListViewsPromise');
        this.urlsPromise = navParams.get('urlsPromise');
        var callList = navParams.get('callList');
        if (callList) {
            this.callList = callList;
            this.isEditMode = true;
        }
        else {
            this.callList = new CallListModel({
                configJson: {}
            });
        }
        this.callLists = navParams.get('callLists') || [];
        AppService.onLogout.subscribe(function () {
            _this.viewController.dismiss();
        });
    }
    CallListSetupModal.prototype.ionViewWillEnter = function () {
        // don't allow swipe on slides
        this.slides.onlyExternal = true;
    };
    CallListSetupModal.prototype.back = function () {
        this.slides.slidePrev();
    };
    CallListSetupModal.prototype.next = function () {
        var groupItems = this.callListGroupSelector.save();
        if (groupItems) {
            var contactGroups_1 = [];
            var contactListViews_1 = [];
            groupItems.forEach(function (groupItem) {
                if (groupItem instanceof ContactGroupModel) {
                    contactGroups_1.push(groupItem);
                }
                else if (groupItem instanceof ContactListViewModel) {
                    contactListViews_1.push(groupItem);
                }
            });
            this.callList.configJson.contactGroupIds = contactGroups_1.map(function (group) { return group._id; });
            this.callList.configJson.contactListViewIds = contactListViews_1.map(function (listView) { return listView._id; });
            this.selectedGroups = [].concat(contactGroups_1).concat(contactListViews_1);
            this.slides.slideNext();
        }
    };
    CallListSetupModal.prototype.delete = function () {
        this.viewController.dismiss({
            action: 'delete',
            callList: this.callList
        });
    };
    CallListSetupModal.prototype.dismiss = function () {
        this.viewController.dismiss();
    };
    CallListSetupModal.prototype.save = function () {
        var callList = this.addCallListDetails.save();
        if (callList) {
            this.viewController.dismiss({
                action: 'save',
                callList: callList
            });
        }
    };
    return CallListSetupModal;
}());
export { CallListSetupModal };
