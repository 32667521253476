import { NavParams, ViewController } from 'ionic-angular';
import { AppService } from '@apto/services';
var ViewRelatedOwnershipsModal = /** @class */ /*@__PURE__*/ (function () {
    function ViewRelatedOwnershipsModal(navParams, viewController) {
        var _this = this;
        this.navParams = navParams;
        this.viewController = viewController;
        this.ownerships = [];
        this.ownerships = navParams.get('ownerships');
        AppService.onLogout.subscribe(function () {
            _this.viewController.dismiss();
        });
    }
    ViewRelatedOwnershipsModal.prototype.dismiss = function () {
        this.viewController.dismiss();
    };
    return ViewRelatedOwnershipsModal;
}());
export { ViewRelatedOwnershipsModal };
