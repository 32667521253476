<form #formContent
      [formGroup]="form"
      class="edit-buyer-need-form edit-form">

    <ion-card>
        <ion-card-header>Buyer's need overview</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-label stacked
                           required>Name</ion-label>
                <ion-input formControlName="name"
                           maxlength="255"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="form.controls['name'].dirty &&
                        form.controls['name'].invalid &&
                        form.controls['name'].errors['required']">
                Please enter a name.
            </div>

            <div class="checkbox-input-group">
                <ion-label stacked>Active</ion-label>
                <input type="checkbox"
                       formControlName="isActive">
            </div>

            <select-field [form]="form"
                          field="buyerQuality"
                          label="Buyer quality"
                          [options]="buyerQualities"
                          [cssClass]="'edit-form--select-popover'"></select-field>

            <div class="filter-panel--option tags-filter--component">
                <ion-label stacked>Acquisition types</ion-label>
                <tag-input [(ngModel)]="acquisitionTypes"
                           [ngModelOptions]="{ standalone: true }"
                           modelAsStrings="true"
                           placeholder="Enter an acquisition type and press [Enter]"
                           secondaryPlaceholder="Enter an acquisition type and press [Enter]">
                    <tag-input-dropdown [autocompleteItems]="BuyerNeedsModel.acquisitionTypes"></tag-input-dropdown>
                </tag-input>
            </div>

            <div class="min-max-input-group">
                <ion-label stacked>Price</ion-label>

                <ion-input class="two-column-input"
                           formControlName="minPrice"
                           placeholder="Minimum"></ion-input>
                <ion-input class="two-column-input"
                           formControlName="maxPrice"
                           placeholder="Maximum"></ion-input>

                <div class="clearfix"></div>
            </div>
            <div class="field-error"
                *ngIf="(form.get('minPrice').dirty &&
                        form.get('minPrice').errors &&
                        form.get('minPrice').errors.pattern) ||
                        (form.get('maxPrice').dirty &&
                        form.get('maxPrice').errors &&
                        form.get('maxPrice').errors.pattern)">
                {{validationMessages.INTEGER_REGEX_MIN_MAX}}
            </div>

            <div class="min-max-input-group">
                <ion-label stacked>Square feet</ion-label>

                <ion-input class="two-column-input"
                           formControlName="minSquareFootage"
                           placeholder="Minimum"></ion-input>
                <ion-input class="two-column-input"
                           formControlName="maxSquareFootage"
                           placeholder="Maximum"></ion-input>

                <div class="clearfix"></div>
            </div>
            <div class="field-error"
                *ngIf="(form.get('minSquareFootage').dirty &&
                        form.get('minSquareFootage').errors &&
                        form.get('minSquareFootage').errors.pattern) ||
                        (form.get('maxSquareFootage').dirty &&
                        form.get('maxSquareFootage').errors &&
                        form.get('maxSquareFootage').errors.pattern)">
                {{validationMessages.INTEGER_REGEX_MIN_MAX}}
            </div>

            <div class="filter-panel--option tags-filter--component">
                <ion-label stacked>Building types</ion-label>
                <tag-input [(ngModel)]="buildingTypes"
                           [ngModelOptions]="{ standalone: true }"
                           modelAsStrings="true"
                           placeholder="Enter a building type and press [Enter]"
                           secondaryPlaceholder="Enter a building type and press [Enter]">
                    <tag-input-dropdown [autocompleteItems]="BuyerNeedsModel.buildingTypes"></tag-input-dropdown>
                </tag-input>
            </div>

            <ion-item>
                <ion-label stacked>Minimum CAP rate</ion-label>
                <ion-input formControlName="minCapRate"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="form.get('minCapRate').dirty &&
                        form.get('minCapRate').errors &&
                        form.get('minCapRate').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>

            <ion-item>
                <ion-label stacked>Minimum cash-on-cash</ion-label>
                <ion-input formControlName="minCashOnCash"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="form.get('minCashOnCash').dirty &&
                        form.get('minCashOnCash').errors &&
                        form.get('minCashOnCash').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>

            <ion-item>
                <ion-label stacked>Minimum LIRR</ion-label>
                <ion-input formControlName="minLirr"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="form.get('minLirr').dirty &&
                        form.get('minLirr').errors &&
                        form.get('minLirr').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>

            <ion-item>
                <ion-label stacked>Minimum IRR</ion-label>
                <ion-input formControlName="minIrr"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="form.get('minIrr').dirty &&
                        form.get('minIrr').errors &&
                        form.get('minIrr').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>
        </ion-card-content>
    </ion-card>

    <busy-panel *ngIf="loadingMessage"
                [message]="loadingMessage"></busy-panel>
</form>
