var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { QueryList } from '@angular/core';
import { Content, Slides, ViewController, NavParams } from 'ionic-angular';
import { AppService } from '@apto/services';
var BaseSlideModal = /** @class */ /*@__PURE__*/ (function () {
    function BaseSlideModal(viewController, navParams) {
        var _this = this;
        this.viewController = viewController;
        this.navParams = navParams;
        AppService.onLogout.subscribe(function () {
            _this.viewController.dismiss();
        });
    }
    BaseSlideModal.prototype.ionViewWillEnter = function () {
        // don't allow swipe on slides
        this.slides.onlyExternal = true;
        this.setMode(this.mode);
        this.initialMode = this.mode;
    };
    BaseSlideModal.prototype.ionViewWillLeave = function () {
        this.removeUrlState();
    };
    Object.defineProperty(BaseSlideModal.prototype, "shouldShowBackArrow", {
        get: function () {
            var shouldShow = false;
            if (this.initialMode !== this.Modes.EDIT) {
                // We have a records list and we are in VIEW mode
                if (this.records.length && this.mode === this.Modes.VIEW) {
                    shouldShow = true;
                    // We have a company with an id and are in EDIT mode
                }
                else if (this.selectedRecord._id && this.mode === this.Modes.EDIT) {
                    shouldShow = true;
                }
            }
            return shouldShow;
        },
        enumerable: true,
        configurable: true
    });
    BaseSlideModal.prototype.backButton = function () {
        if (this.mode === this.Modes.VIEW) {
            this.setMode(this.Modes.LIST);
        }
        else if (this.mode === this.Modes.EDIT) {
            this.setMode(this.Modes.VIEW);
        }
    };
    BaseSlideModal.prototype.scrollToTop = function () {
        this.content.forEach(function (content) {
            // timing issue can result in this method firing while content children are being destroy.
            // ensure that content is still defined before trying to call scrollToTop.
            content && content.scrollToTop();
        });
    };
    BaseSlideModal.prototype.setMode = function (mode) {
        this.mode = mode;
        this.slides.slideTo(this.mode);
        this.scrollToTop();
        this.pushUrlState(this.mode);
    };
    BaseSlideModal.prototype.removeUrlState = function () {
        var modalNav = this.navParams.get('url');
        if (modalNav) {
            history.pushState(undefined, undefined, modalNav.baseHash);
        }
    };
    BaseSlideModal.prototype.pushUrlState = function (mode) {
        var _this = this;
        var modalNav = this.navParams.get('url');
        var url = this.modalUrls.get(mode);
        if (modalNav && url) {
            var navParamMatches = url.split('/');
            navParamMatches.forEach(function (match) {
                // New records won't have navParams set
                if (match.includes('Id') && _this.selectedRecord && _this.selectedRecord._id) {
                    url = url.replace(match, _this.selectedRecord._id);
                    // Otherwise look for the navparam name based on the url name :recordId
                }
                else if (match.startsWith(':')) {
                    var navParamName = match.replace(':', '');
                    var urlParamValue = modalNav.params[navParamName];
                    url = url.replace(match, urlParamValue);
                }
            });
            history.pushState(undefined, undefined, "" + modalNav.baseHash + url);
        }
    };
    BaseSlideModal.prototype.dismiss = function (options) {
        if (options === void 0) {
            options = {
                dismissAll: false
            };
        }
        return __awaiter(this, void 0, void 0, function () {
            var nav, views;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        nav = this.viewController.getNav();
                        views = nav.getViews();
                        if (!(options.dismissAll && views.length > 1))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all(views.map(function (view) { return nav.removeView(view); }))];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.viewController.dismiss()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    BaseSlideModal.prototype.view = function (record) {
        this.selectedRecord = record;
        this.setMode(this.Modes.VIEW);
    };
    return BaseSlideModal;
}());
export { BaseSlideModal };
