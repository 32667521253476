export const FOLLOWUP_DELAY = 1500;
export const TOAST_DURATION = 3000;

export const DECIMAL_REGEX = /^(\d+\.?\d{0,2}|\.\d{1,2})$/;     // restricts to two decimal places
export const INTEGER_REGEX = /^\d+$/;
export const YEAR_REGEX = /^\d{4}$/;
export const PHONE_NUMBER_REGEX = /^([0-9]{1,3})?([-\s\.]{0,1})?(\+[0-9]{1,3})?[(]{0,1}[0-9]{3}[)]{0,1}[-\s\.]{0,1}[0-9]{3}[-\s\.]{0,1}[0-9]{4}?( x\d{1,9})?$/; // tslint:disable-line
export const EMAIL_REGEX = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/;  // tslint:disable-line max-line-length
export const LIST_VIEW_MAX_RECORDS = 200;
export const MAX_RECORDS = 100000;
export const HTTP_TIMEOUT = 60000;

export const DATEPICKER_OPTIONS = {
    dateFormat: 'mm/dd/yyyy',
    firstDayOfWeek: 'su',
    sunHighlight: false
};
export const DATE_FORMAT = 'YYYY-MM-DD';
export const INPUT_DEBOUNCE = 700;

export const END_POINTS = {
    FIELD_CHECK: 'api/desktop/metadata/fieldcheck',
    CHECK_LOGIN: 'checkLogin'
};
