<div class="filter-panel--option range-slide-filter--component">
    <ion-label stacked>{{ label }}</ion-label>

    <ion-input #minimumInput
               class="two-column-input"
               (input)="onInputChange($event)"
               [formControl]="formControl"
               [placeholder]="minimumPlaceholder"></ion-input>
    <ion-input #maximumInput
               class="two-column-input"
               (input)="onInputChange($event)"
               [formControl]="formControl"
               [placeholder]="maximumPlaceholder"></ion-input>

    <ion-range dualKnobs="true"
               [min]="filterRange.min"
               [max]="filterRange.max"
               [(ngModel)]="value"
               (ionChange)="onSliderChange($event)"></ion-range>

    <div *ngIf="value.lower > value.upper" class="error">
        Minimum price should be lower than maximum price.
    </div>
</div>
