import { NavParams } from 'ionic-angular';
import './help-popover.scss';
var HelpPopover = /** @class */ /*@__PURE__*/ (function () {
    function HelpPopover(navParams) {
        this.navParams = navParams;
        this.helpText = this.navParams.get('helpText');
        this.helpArticleUrl = this.navParams.get('helpArticleUrl');
        this.showEmailLink = this.navParams.get('showEmailLink');
    }
    return HelpPopover;
}());
export { HelpPopover };
