<ion-header class="modal-header pipeline-conversion-modal--header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="mode !== Mode.View">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="back()"
                    data-automation="modalBackButton">
                <ion-icon name="arrow-back"></ion-icon>
            </button>
        </ion-buttons>

        <ion-title *ngIf="mode === Mode.View">Add {{ pipelineConversionParams.destination | lowercase }} tasks</ion-title>
        <ion-title *ngIf="mode === Mode.Add">Add Task</ion-title>
        <ion-title *ngIf="mode === Mode.Edit">Edit Task</ion-title>

        <ion-buttons end>
            <button *ngIf="mode !== Mode.View"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="saveTask()"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    [disabled]="!pipelineConversionParams.pipelineObject?._id"
                    (click)="dismiss()"
                    data-automation="modal-dismiss-button">
                <ion-icon name="close"></ion-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="pipeline-conversion-modal">
    <ion-slides (ionSlideWillChange)="scrollToTop()">
        <ion-slide>
            <ion-content>
                <ion-card *ngIf="recordTypeIsRequired && pipelineConversionParams.pipelineObject?._id"
                          id="pipeline-conversion-modal--type-selection-card">
                    <p class="light-grey-text">Record type</p>
                    <p class="grey-text">Select the record type for your {{ pipelineConversionParams.destination.replace(' Deal', '') | lowercase
                        }} deal</p>

                    <ion-item>
                        <apto-ionic-select placeholder="SELECT A RECORD TYPE"
                                           [options]="recordTypeOptions"
                                           [value]="pipelineConversionParams.pipelineObject?.type"
                                           (onSelectOption)="onRecordTypeChanged($event.value)">
                        </apto-ionic-select>
                    </ion-item>
                </ion-card>
                <ion-card class="pipeline-conversion-modal--templated-tasks">
                    <p class="light-grey-text">Templated tasks</p>
                    <p class="grey-text">Add tasks to your {{ pipelineConversionParams.destination.replace(' Deal', '') | lowercase }} deal by
                        selecting them from a template</p>

                    <ion-item>
                        <playbook-selector [options]="playbooks"
                                           placeholder="SELECT A PLAYBOOK"
                                           [targetStage]="pipelineConversionParams.destination"
                                           (onSelectOption)="onPlaybookSelectionChanged($event.value)">
                        </playbook-selector>
                    </ion-item>

                    <div *ngIf="tasksFromTemplate.length"
                         class="pipeline-conversion-modal--selection">
                        <ion-checkbox class="small"
                                      mode="ios"
                                      color="primary-blue"
                                      [ngModel]="selectAll"
                                      (ngModelChange)="onSelectAllTasks($event)">
                        </ion-checkbox>
                        <span class="light-grey-text">
                            Showing {{ selectedTasks.size }} of {{ tasksFromTemplate.length }} tasks
                        </span>
                    </div>
                    <ion-list>
                        <task-item *ngFor="let task of tasksFromTemplate"
                                   [hideTaskCompletion]="true"
                                   [task]="task"
                                   [selected]="selectedTasks.has(task)"
                                   [selectable]="true"
                                   [removeable]="true"
                                   (onRemoveTask)="onRemoveTask($event)"
                                   (onClick)="viewTask($event, true)"
                                   (onSelectToggled)="onTaskSelectionChanged($event, task)"></task-item>
                    </ion-list>
                    <ion-row id="add-to-template-button"
                             class="card-footer-buttons"
                             *ngIf="playbook">
                        <ion-col text-right>
                            <button ion-button
                                    clear
                                    color="primary-blue"
                                    (click)="addTask(true)">
                                ADD TASK TO TEMPLATE
                            </button>
                        </ion-col>
                    </ion-row>
                </ion-card>
                <ion-card class="pipeline-conversion-modal--additional-tasks">
                    <p class="light-grey-text">Additional tasks</p>
                    <p class="grey-text">Add individual tasks to your {{ pipelineConversionParams.destination.replace(' Deal', '') | lowercase
                        }} deal without modifying your templates</p>
                    <ion-list>
                        <task-item *ngFor="let task of additionalTasks"
                                   [hideTaskCompletion]="true"
                                   [task]="task"
                                   [removeable]="true"
                                   [removeButtonText]="'DELETE'"
                                   (onRemoveTask)="onDeleteTask($event)"
                                   (onClick)="viewTask($event)"></task-item>
                    </ion-list>
                    <ion-row id="add-task-button"
                             class="card-footer-buttons">
                        <ion-col text-right>
                            <button ion-button
                                    clear
                                    color="primary-blue"
                                    (click)="addTask()">
                                ADD TASK
                            </button>
                        </ion-col>
                    </ion-row>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide>
            <ion-content>
                <ion-card>
                    <ion-card-header>Task details</ion-card-header>
                    <ion-card-content>
                        <edit-task-form *ngIf="mode !== Mode.View"
                                        [task]="task"></edit-task-form>
                    </ion-card-content>
                </ion-card>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>

<ion-footer class="modal-footer">
    <ion-buttons start>
        <button id="pipeline-conversion-modal--close-button"
                class="disable-hover"
                ion-button
                color="danger"
                [disabled]="!pipelineConversionParams.pipelineObject?._id"
                (click)="dismiss()"
                clear>CLOSE</button>
        <button ion-button
                icon-only
                color="primary-blue"
                (click)="scrollToTop()"
                clear>RETURN TO TOP</button>
    </ion-buttons>

    <ion-buttons end>
        <ng-container *ngIf="mode === Mode.View">
            <button *ngIf="pipelineConversionParams.pipelineObject?._id; else conversionPending"
                    id="pipeline-conversion-modal--add-tasks-button"
                    class="disable-hover"
                    ion-button
                    color="primary-blue"
                    (click)="addTasks()">ADD TASKS TO DEAL</button>
            <ng-template #conversionPending>
                <button id="pipeline-conversion-modal--conversion-pending-button"
                        class="disable-hover"
                        disabled="disabled"
                        color="primary-blue"
                        ion-button>
                    <ion-spinner></ion-spinner>&nbsp; CONVERSION IN PROGRESS
                </button>
            </ng-template>
        </ng-container>
    </ion-buttons>
</ion-footer>
