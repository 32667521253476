import { EventEmitter, OnChanges } from '@angular/core';
import { CallListModel } from '@apto/models';
import './call-list-panel.scss';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
var CallListPanel = /** @class */ /*@__PURE__*/ (function () {
    function CallListPanel(router, location) {
        this.router = router;
        this.location = location;
        this.hasBack = true;
        this.onClose = new EventEmitter();
        this.onViewList = new EventEmitter();
        this.onViewNext = new EventEmitter();
        this.onViewPrevious = new EventEmitter();
        this.hasPrevious = false;
        this.hasNext = false;
        this.CallListModel = CallListModel;
    }
    CallListPanel.prototype.ngOnChanges = function () {
        if (this.currentContactId && this.callList) {
            this.hasNext = !!this.callList.nextContact(this.currentContactId, this.marketingStatus);
            this.hasPrevious = !!this.callList.previousContact(this.currentContactId, this.marketingStatus);
        }
    };
    Object.defineProperty(CallListPanel.prototype, "hoursLeft", {
        get: function () {
            return this.callList.hoursToCall();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CallListPanel.prototype, "contactsLeft", {
        get: function () {
            return this.callList.members.filter(function (member) { return !member.callResult; }).length;
        },
        enumerable: true,
        configurable: true
    });
    CallListPanel.prototype.close = function () {
        if (this.callList) {
            this.router.navigate(['/call-lists']);
        }
        else {
            this.location.back();
        }
    };
    CallListPanel.prototype.viewList = function () {
        this.onViewList.emit();
    };
    CallListPanel.prototype.viewNext = function () {
        this.onViewNext.emit();
    };
    CallListPanel.prototype.viewPrevious = function () {
        this.onViewPrevious.emit();
    };
    return CallListPanel;
}());
export { CallListPanel };
