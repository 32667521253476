<ion-grid *ngIf="shouldShow"
          class="add-activity--flyout">
    <div class="add-activity--outer">
        <div class="add-activity--inner"
            [@3dspin]="animationState">
            <h4 class="title">Log Activity</h4>

            <edit-activity-form [activity]="activity"
                                [contactProvider]="contactsService"
                                [userProvider]="usersService"
                                [relatedContact]="contact"
                                [relatedListing]="listing"></edit-activity-form>

            <div class="add-activity--controls--container">
                <div class="add-activity--controls--hide">
                    <button class="add-activity--controls--cancel"
                            (click)="cancel()"
                            clear
                            color="ancillary-grey"
                            icon-left
                            ion-button
                            small
                            data-automation="addActivityCancelButton">
                        Cancel
                    </button>
                </div>
                <div class="add-activity--controls--success">
                    <button class="add-activity--controls--save"
                            (click)="save()"
                            clear
                            color="primary-blue"
                            icon-right
                            ion-button
                            small
                            data-automation="addActivitySaveButton">
                        Save
                    </button>
                    <button class="add-activity--controls--save-and-next"
                            (click)="saveAndNext()"
                            clear
                            color="primary-blue"
                            icon-right
                            ion-button
                            small
                            data-automation="addActivitySaveAndNextButton">
                        Save + Next
                        <ion-icon name="md-arrow-forward"></ion-icon>
                    </button>
                </div>
            </div>

            <busy-panel *ngIf="isLoading" message="Logging Activity..."></busy-panel>
        </div>
    </div>
</ion-grid>
