import { NavParams, ViewController } from 'ionic-angular';
import './attom-data-points-popover.scss';
var AttomDataPointsPopover = /** @class */ /*@__PURE__*/ (function () {
    function AttomDataPointsPopover(navParams, viewController) {
        this.navParams = navParams;
        this.viewController = viewController;
        this.dataPoints = this.navParams.get('dataPoints');
        this.ownerDataPoints = this.navParams.get('ownerDataPoints');
    }
    AttomDataPointsPopover.prototype.dismiss = function () {
        this.viewController.dismiss();
    };
    return AttomDataPointsPopover;
}());
export { AttomDataPointsPopover };
