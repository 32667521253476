import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { AppService } from '../app/app-service';
import { BaseService } from '../base/base-service';
import { AlertController } from 'ionic-angular';
var ImagesService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ImagesService, _super);
    function ImagesService(injector, sanitizer, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.sanitizer = sanitizer;
        _this.alertController = alertController;
        _this.imageEndpoint = _this.configService.serviceUrl + "/" + END_POINTS.IMAGE;
        _this.imagesEndpoint = _this.configService.serviceUrl + "/" + END_POINTS.IMAGES;
        _this.serviceName = SERVICE_NAMES.IMAGES;
        AppService.onLogout.subscribe(function () {
            _this.cacheService.clear();
        });
        return _this;
    }
    ImagesService.prototype.byId = function (id) {
        return this.byUrl(this.imageEndpoint + "/" + id);
    };
    ImagesService.prototype.byObjectId = function (id, asBlob) {
        var _this = this;
        if (asBlob === void 0) {
            asBlob = true;
        }
        return new Promise(function (resolve, reject) {
            return _this.http.get(_this.imagesEndpoint + "/" + id)
                .subscribe(function (response) {
                var imageUrls = response.data || [];
                if (!asBlob) {
                    return resolve(imageUrls);
                }
                return Promise.all(imageUrls.map(function (imageUrl) { return _this.byUrl(imageUrl); }))
                    .then(function (blobs) {
                    resolve(blobs);
                });
            }, function (ex) {
                if (ex) {
                    _this.onLogException.emit({ message: "Failed to fetch for service " + _this.serviceName + ".", ex: ex });
                }
            });
        });
    };
    ImagesService.prototype.byUrl = function (url) {
        var _this = this;
        var cachedResult = this.cacheService.get(this.serviceName, url);
        if (cachedResult) {
            return cachedResult;
        }
        return this.http.get(url, { responseType: 'blob' }).pipe(map(function (response) {
            var shortDataUri = window.URL.createObjectURL(response);
            var safeShortDataUri = _this.sanitizer.bypassSecurityTrustUrl(shortDataUri);
            _this.cacheService.add(_this.serviceName, url, safeShortDataUri);
            return safeShortDataUri;
        }), catchError(function (ex) {
            if (ex) {
                _this.onLogException.emit({ message: "Failed to fetch for service " + _this.serviceName + ".", ex: ex });
            }
            return observableThrowError(ex);
        }))
            .toPromise();
    };
    ImagesService.prototype.upsert = function (imageRecord) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.http.post(_this.imageEndpoint, imageRecord)
                            .subscribe(function (result) {
                            var imageId = result.data.id;
                            return resolve(imageId);
                        }, function (ex) {
                            if (!ex) {
                                return;
                            }
                            // If a user tries to update an object that they've already deleted in Salesforce, we catch
                            // the error and ask them if they want to recreate it or leave it deleted. If they chose to
                            // leave it deleted, we still propogate the error to the collection can handle it, but we don't
                            // need to log it.
                            if (ex.error !== 'delete') {
                                _this.onLogException.emit({ message: "Failed to upsert object " + imageRecord + " for service " + _this.serviceName + ".", ex: ex });
                            }
                            return reject(ex);
                        });
                    })];
            });
        });
    };
    return ImagesService;
}(BaseService));
export { ImagesService };
