<ion-header class="modal-header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton();">
                <ion-icon name="arrow-back"></ion-icon>
            </button>
        </ion-buttons>

        <ion-title>Manage properties</ion-title>

        <ion-buttons end>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismissWithoutSaving()"
                    data-automation="modal-dismiss-button">
                <ion-icon name="close"></ion-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>
<ion-content class="details-modal manage-ownerships-modal">
    <ion-slides (ionSlideWillChange)="scrollToTop()">
        <ion-slide>
            <ion-content>
                <ion-card *ngIf="selectedRecord && selectedRecord.constructor.name !== 'PropertyModel' && !navParams.get('isProperty')">
                    <ion-card-header>
                        Related properties
                        <add-record
                                linkText="Create new"
                                objectName="Property"
                                (onRecordCreated)="addOwnership($event);"></add-record>
                    </ion-card-header>
                    <ion-card-content>
                        <ng-container *ngIf="!isInitializing">
                            <ion-list *ngIf="ownerships && ownerships.length">
                                <prop-item *ngFor="let ownership of ownerships"
                                               [property]="ownership.property"
                                               [ownership]="ownership"
                                               [isRemovableFromList]="true"
                                               [isImageShown]="true"
                                               [isShownOnMap]="false"
                                               [showSpaceFeature]="false"
                                               (onRemoveFromList)="removeOwnership(ownership)"
                                               [showFor]="showFor"
                                ></prop-item>
                            </ion-list>

                            <ion-item *ngIf="!ownerships || !ownerships.length"
                                      class="empty-card">
                                <ion-icon name="business"
                                          material-icons
                                          circle
                                          small
                                          color="white"
                                          item-left></ion-icon>
                                Add properties
                            </ion-item>

                            <ion-row class="card-footer-buttons">
                                <ion-auto-complete
                                        class="manage-ownerships-modal--property-autocomplete"
                                        #propertyAutocomplete
                                        [dataProvider]="propertiesService"
                                        [options]="{ placeholder : 'Find property' }"
                                        (itemSelected)="addOwnership($event);"></ion-auto-complete>
                            </ion-row>
                        </ng-container>
                        <busy-panel *ngIf="isInitializing"
                                    message="Loading properties..."></busy-panel>
                    </ion-card-content>
                </ion-card>
                <ion-card *ngIf="selectedRecord && selectedRecord.constructor.name === 'PropertyModel' || navParams.get('isProperty')">
                    <ion-card-header>
                        Owners
                    </ion-card-header>
                    <ion-card-content>
                        <ng-container *ngIf="!isInitializing">
                            <ion-list *ngIf="ownerships && ownerships.length">
                                <ng-container *ngFor="let ownership of ownerships">
                                    <ion-item *ngIf="ownership.company && ownership.companyId && !ownership.contactId" no-lines>
                                        <div class="manage-ownerships-modal--ownership-item" [attr.data-automation]="'ownership-item--' + ownership.company.name">
                                            <ion-icon class="grey-circle"
                                                      circle
                                                      material-icons
                                                      name="location_city">
                                            </ion-icon>
                                            <div class="manage-ownerships-modal--ownership-details">
                                                <span class="dark-text">
                                                    {{ownership.company.name}}
                                                </span>
                                                <span
                                                    class="link manage-ownerships-modal--contact-role"
                                                    (click)="changeOwnerStatus($event, ownership);">
                                                    <span>{{ownership.contactRole || '-- No status selected --'}}</span>
                                                </span>
                                                <p>Company</p>
                                            </div>
                                            <button
                                                ion-button
                                                icon-only
                                                clear
                                                (click)="removeOwnership(ownership)">
                                                <ion-icon class="close-circle"
                                                          circle
                                                          name="close"></ion-icon>
                                            </button>
                                        </div>
                                    </ion-item>
                                </ng-container>
                            </ion-list>
                            <ion-item *ngIf="companyOwnershipsLength === 0"
                                      class="empty-card">
                                <ion-icon name="business"
                                          material-icons
                                          circle
                                          small
                                          color="white"
                                          item-left></ion-icon>
                                Add owners (company)
                            </ion-item>

                            <ion-row class="card-footer-buttons">
                                <ion-label stacked>Related company
                                    <add-record
                                            linkText="Create new"
                                            objectName="Company"
                                            (onRecordCreated)="addOwnership($event);">
                                    </add-record>
                                </ion-label>
                                <ion-auto-complete
                                        #companyAutocomplete
                                        [dataProvider]="companiesService"
                                        [options]="{ placeholder : 'Find company' }"
                                        (itemSelected)="addOwnership($event);"></ion-auto-complete>
                            </ion-row>
                        </ng-container>
                        <ng-container *ngIf="!isInitializing">
                            <ion-list *ngIf="ownerships && ownerships.length">
                                <ng-container *ngFor="let ownership of ownerships">
                                    <ion-item *ngIf="ownership && ownership.contact" no-lines>
                                        <div class="manage-ownerships-modal--ownership-item" [attr.data-automation]="'ownership-item--' + ownership.contact.fullName">
                                            <ion-icon
                                                    class="grey-color-circle"
                                                    material-icons
                                                    name="account_circle"
                                                    large></ion-icon>
                                            <div class="manage-ownerships-modal--ownership-details">
                                                <p>
                                                <span class="dark-text">
                                                    {{ownership.contact.fullName}}
                                                </span>
                                                    <span
                                                        class="link manage-ownerships-modal--contact-role"
                                                        (click)="changeOwnerStatus($event, ownership, true);">
                                                    <span
                                                        *ngIf="ownership.contactRole ==='Owner' && ownership.isPrimaryContact; else ownershipRole">
                                                        Primary Owner
                                                    </span>
                                                    <ng-template #ownershipRole>
                                                        <span>{{ownership.contactRole || '-- No status selected --'}}</span>
                                                    </ng-template>
                                                </span>
                                                </p>
                                                <p *ngIf="ownership.contact.company">{{ownership.contact.company.name}}</p>
                                                <p>{{ownership.contact.title}}</p>
                                            </div>
                                            <button
                                                    ion-button
                                                    item-right
                                                    icon-only
                                                    clear
                                                    (click)="removeOwnership(ownership);">
                                                <ion-icon
                                                        class="close-circle"
                                                        circle
                                                        name="close"></ion-icon>
                                            </button>
                                        </div>
                                    </ion-item>
                                </ng-container>
                            </ion-list>
                            <ion-item *ngIf="contactOwnershipsLength === 0"
                                      class="empty-card">
                                <ion-icon name="business"
                                          material-icons
                                          circle
                                          small
                                          color="white"
                                          item-left></ion-icon>
                                Add owners (contact)
                            </ion-item>

                            <ion-row class="card-footer-buttons">
                                <ion-label stacked>Related contact
                                    <add-record
                                            linkText="Create new"
                                            objectName="Contact"
                                            (onRecordCreated)="addOwnership($event);">
                                    </add-record>
                                </ion-label>
                                <ion-auto-complete
                                        #contactAutocomplete
                                        [dataProvider]="contactsService"
                                        [options]="{ placeholder : 'Find contact' }"
                                        (itemSelected)="addOwnership($event);"></ion-auto-complete>
                            </ion-row>
                        </ng-container>
                        <busy-panel
                                *ngIf="isInitializing"
                                message="Loading properties..."></busy-panel>
                    </ion-card-content>
                </ion-card>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>
