import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { EventEmitter } from '@angular/core';
import { ModalController } from 'ionic-angular';
import { BuyerNeedsModel, CallListModel } from '@apto/models';
import { AnalyticsService, BuyerNeedsService, ContactsService, FeaturesService, ListingsService, TypesService } from '@apto/services';
import { LogsService } from '@apto/ionic-lib/services';
import { ContactDetailsModal, ContactDetailsModalMode } from '@apto/shared/modals/contact-details-modal/contact-details-modal';
import { LIST_VIEW_MAX_RECORDS } from '@apto/shared/constants';
import { FilterPanel, RangeFilter, SearchFilter, SelectFilter, SelectMapFilter, TagsFilter, TextFilter } from '@apto/pn/components';
import { BaseListPage } from '../base-list-page/base-list-page';
import './contacts.scss';
import { AppcuesService, MetricsService } from '@apto/shared';
var FORM_DEBOUNCE_TIME = 500;
var ContactsPage = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ContactsPage, _super);
    function ContactsPage(appcuesService, route, analyticsService, featuresService, listingsService, logsService, metricsService, modalController, buyerNeedsService, contactsService, router, typesService) {
        var _this = _super.call(this) || this;
        _this.appcuesService = appcuesService;
        _this.route = route;
        _this.analyticsService = analyticsService;
        _this.featuresService = featuresService;
        _this.listingsService = listingsService;
        _this.logsService = logsService;
        _this.metricsService = metricsService;
        _this.modalController = modalController;
        _this.buyerNeedsService = buyerNeedsService;
        _this.contactsService = contactsService;
        _this.router = router;
        _this.typesService = typesService;
        _this.filterChangeEvent = new EventEmitter();
        _this.LIST_VIEW_MAX_RECORDS = LIST_VIEW_MAX_RECORDS;
        _this.CallListModel = CallListModel;
        _this.contacts = [];
        _this.filterType = 'contacts';
        _this.header = 'Recent Contacts';
        _this.isInitializing = true;
        _this.listType = 'recent';
        _this.selectedContacts = {};
        _this.sortMap = {
            'A-Z': { lastName: 1 },
            'Z-A': { lastName: -1 }
        };
        _this.searchesInFlight = 0;
        _this.searchStartedEvent = new EventEmitter();
        _this.searchFinishedEvent = new EventEmitter();
        _this.subscriptions = [];
        _this.types = [];
        _this.sortKeys = Object.keys(_this.sortMap);
        _this.selectedSortKey = _this.sortKeys[0];
        return _this;
    }
    ContactsPage.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var contactId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.metricsService.start('Contacts Page');
                        this.metricsService.setRouteName('/contacts');
                        this.analyticsService.logEvent('Contacts Tab Load');
                        this.subscriptions.push(this.contactsService.onDeleted.subscribe(function () { return _this.updateContacts(); }));
                        this.subscriptions.push(this.contactsService.onUpserted.subscribe(function () { return _this.updateContacts(); }));
                        this.subscriptions.push(this.filterChangeEvent.pipe(debounceTime(FORM_DEBOUNCE_TIME)).subscribe(function () { return _this.updateContacts(); }));
                        this.subscriptions.push(this.searchStartedEvent.subscribe(function () { _this.searchStarted(); }));
                        this.subscriptions.push(this.searchFinishedEvent.subscribe(function () { _this.searchFinished(); }));
                        this.createFilterPanel();
                        contactId = this.route.snapshot.paramMap.get('contactId');
                        if (contactId) {
                            this.contactsService.in([contactId])
                                .then(function (contacts) {
                                _this.viewContact(contacts[0]);
                            });
                        }
                        return [4 /*yield*/, this.updateContacts()];
                    case 1:
                        _a.sent();
                        this.appcuesService.start();
                        this.metricsService.stop('Contacts Page');
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactsPage.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    ContactsPage.prototype.searchStarted = function () {
        this.searchesInFlight++;
        this.isInitializing = true;
    };
    ContactsPage.prototype.searchFinished = function () {
        this.searchesInFlight--;
        if (this.searchesInFlight <= 0) {
            this.isInitializing = false;
            this.searchesInFlight = 0;
        }
    };
    ContactsPage.prototype.addContact = function () {
        var contactDetailsModal = this.modalController.create(ContactDetailsModal, {
            mode: ContactDetailsModalMode.EDIT,
            types: Object.keys(this.types)
        }, {
            cssClass: 'large-modal'
        });
        contactDetailsModal.present({ updateUrl: false });
    };
    ContactsPage.prototype.createFilterPanel = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var types, listingId, filterListings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.searchFilterPanel.add(SearchFilter, {
                            placeholder: 'Find a contact',
                            queryKey: 'name'
                        });
                        return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        types = _a.sent();
                        this.types = types.contactTypes;
                        this.contactsFilterPanel.add(SelectMapFilter, {
                            label: 'Contact type',
                            queryKey: 'RecordTypeId',
                            optionsMap: this.types
                        });
                        this.contactsFilterPanel.add(TagsFilter, {
                            label: 'Zip codes',
                            queryKey: 'MailingPostalCode',
                            placeholder: 'Add another zip code',
                            secondaryPlaceholder: 'Add zip codes',
                            separatorKeyCodes: [13, 32] // enter and space
                        });
                        // Buyer's Needs
                        this.buyerNeedsFilterPanel.add(TextFilter, {
                            label: 'Interest name',
                            queryKey: 'name',
                            placeholder: 'Enter an interest'
                        });
                        this.buyerNeedsFilterPanel.add(SelectFilter, {
                            label: 'Buyer quality',
                            queryKey: 'buyerQuality',
                            optionsList: BuyerNeedsModel.buyerQualities
                        });
                        this.buyerNeedsFilterPanel.add(SelectFilter, {
                            label: 'Acquisition type',
                            queryKey: 'acquisitionType',
                            optionsList: BuyerNeedsModel.acquisitionTypes
                        });
                        this.buyerNeedsFilterPanel.add(RangeFilter, {
                            label: 'Price',
                            minimumPlaceholder: 'Minimum',
                            minKey: 'minPrice',
                            maximumPlaceholder: 'Maximum',
                            maxKey: 'maxPrice'
                        });
                        this.buyerNeedsFilterPanel.add(RangeFilter, {
                            label: 'Square feet',
                            minimumPlaceholder: 'Minimum',
                            minKey: 'minSquareFootage',
                            maximumPlaceholder: 'Maximum',
                            maxKey: 'maxSquareFootage'
                        });
                        this.buyerNeedsFilterPanel.add(SelectFilter, {
                            label: 'Building type',
                            queryKey: 'buildingType',
                            optionsList: BuyerNeedsModel.buildingTypes
                        });
                        this.buyerNeedsFilterPanel.add(RangeFilter, {
                            label: 'Minimum CAP rate',
                            minKey: 'minCapRate',
                            minimumPlaceholder: 'Minimum',
                            minOnly: true
                        });
                        this.buyerNeedsFilterPanel.add(RangeFilter, {
                            label: 'Minimum cash-on-cash',
                            minKey: 'minCashOnCash',
                            minimumPlaceholder: 'Minimum',
                            minOnly: true
                        });
                        this.buyerNeedsFilterPanel.add(RangeFilter, {
                            label: 'Minimum LIRR',
                            minKey: 'minLirr',
                            minimumPlaceholder: 'Minimum',
                            minOnly: true
                        });
                        this.buyerNeedsFilterPanel.add(RangeFilter, {
                            label: 'Minimum IRR',
                            minKey: 'minIrr',
                            minimumPlaceholder: 'Minimum',
                            minOnly: true
                        });
                        this.subscriptions.push(this.searchFilterPanel.onQueryChange.subscribe(function (filters) {
                            _this.isInitializing = true;
                            _this.filterChangeEvent.emit();
                        }, function (err) { return _this.logsService.error(err); }), this.contactsFilterPanel.onQueryChange.subscribe(function (filters) {
                            _this.isInitializing = true;
                            _this.filterChangeEvent.emit();
                        }, function (err) { return _this.logsService.error(err); }), this.buyerNeedsFilterPanel.onQueryChange.subscribe(function (filters) {
                            _this.isInitializing = true;
                            _this.filterChangeEvent.emit();
                        }, function (err) { return _this.logsService.error(err); }));
                        listingId = this.route.snapshot.queryParams.listingId;
                        if (!listingId)
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.listingsService.in([listingId])];
                    case 2:
                        filterListings = _a.sent();
                        if (filterListings.length > 0) {
                            this.filterListing = filterListings[0];
                        }
                        _a.label = 3;
                    case 3:
                        this.setBuyerNeedsFilters();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactsPage.prototype.onFiltersChanged = function () {
        var searchIsSet = !this.searchFilterPanel.isEmpty();
        var contactsIsSet = this.filterType === 'contacts' && !this.contactsFilterPanel.isEmpty();
        var buyerNeedsIsSet = this.filterType === 'buyerNeeds' && !this.buyerNeedsFilterPanel.isEmpty();
        if (searchIsSet || contactsIsSet || buyerNeedsIsSet) {
            this.listType = 'all';
        }
        this.updateContacts();
    };
    ContactsPage.prototype.updateContacts = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.searchStartedEvent.emit();
                        this.setListTypeOnEmptyFilter();
                        return [4 /*yield*/, this.getContacts()];
                    case 1:
                        _a.sent();
                        this.setContactCounts();
                        this.currentPageSelected = false;
                        this.searchFinishedEvent.emit();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactsPage.prototype.setListTypeOnEmptyFilter = function () {
        if (!this.filtersAreEmpty()) {
            this.listType = 'all';
        }
    };
    ContactsPage.prototype.getContacts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var params, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!(this.filterType === 'buyerNeeds'))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.updateContactsWithBuyerNeeds()];
                    case 1:
                        _c.sent();
                        return [3 /*break*/, 6];
                    case 2:
                        params = {
                            sort: this.sortMap[this.selectedSortKey],
                            limit: LIST_VIEW_MAX_RECORDS
                        };
                        if (!(this.listType === 'recent'))
                            return [3 /*break*/, 4];
                        _a = this;
                        return [4 /*yield*/, this.contactsService.recent(params)];
                    case 3:
                        _a.items = _c.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        if (!(this.listType === 'all'))
                            return [3 /*break*/, 6];
                        params.where = this.getFilterQuery();
                        _b = this;
                        return [4 /*yield*/, this.contactsService.fetch(params)];
                    case 5:
                        _b.items = _c.sent();
                        _c.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    ContactsPage.prototype.setContactCounts = function () {
        var _this = this;
        this.contactsService.count().then(function (count) {
            _this.totalContacts = count;
        });
    };
    ContactsPage.prototype.call = function (phoneNumber) {
        window.location.assign(phoneNumber.actionLink);
    };
    ContactsPage.prototype.email = function (email) {
        window.location.assign(email.actionLink);
    };
    ContactsPage.prototype.filtersAreEmpty = function () {
        if (!this.searchFilterPanel.isEmpty()) {
            return false;
        }
        if (this.filterType === 'contacts') {
            return this.contactsFilterPanel.isEmpty();
        }
        else {
            return this.buyerNeedsFilterPanel.isEmpty();
        }
    };
    ContactsPage.prototype.onFilterTypeChanged = function () {
        this.updateContacts();
    };
    ContactsPage.prototype.removeContactFromItem = function (item) {
        delete this.selectedItems[item._id];
    };
    ContactsPage.prototype.resetFilters = function () {
        this.searchFilterPanel.resetFilters();
        this.contactsFilterPanel.resetFilters();
        this.buyerNeedsFilterPanel.resetFilters();
        this.updateContacts();
    };
    ContactsPage.prototype.selectContactFromItem = function (item) {
        this.selectedItems[item._id] = item;
    };
    ContactsPage.prototype.setListType = function (type) {
        this.listType = type;
        if (this.listType === 'groups') {
            var queryParams = {};
            if (this.filterListing) {
                queryParams = {
                    listingId: this.filterListing._id
                };
            }
            this.router.navigate(['/contact-groups'], { queryParams: queryParams });
        }
        else {
            if (this.searchFilterPanel && !this.searchFilterPanel.isEmpty()) {
                this.searchFilterPanel.resetFilters();
            }
            this.updateContacts();
        }
    };
    ContactsPage.prototype.sortChanged = function (sortKey) {
        this.selectedSortKey = sortKey;
        this.updateContacts();
    };
    ContactsPage.prototype.updateContactsWithBuyerNeeds = function () {
        return __awaiter(this, void 0, void 0, function () {
            var params, buyerNeeds, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = {
                            limit: 200
                        };
                        params.where = this.getFilterQuery();
                        return [4 /*yield*/, this.buyerNeedsService.fetch(params)];
                    case 1:
                        buyerNeeds = _b.sent();
                        if (!(buyerNeeds.length > 0))
                            return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, this.contactsService.fetch({
                                limit: 200,
                                where: {
                                    _id: {
                                        $in: buyerNeeds.map(function (buyerNeed) { return buyerNeed.contactId; })
                                    }
                                }
                            })];
                    case 2:
                        _a.items = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        this.items = [];
                        _b.label = 4;
                    case 4:
                        if (this.filterListing) {
                            this.selectAllChanged(true);
                        }
                        else {
                            this.currentPageSelected = false;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactsPage.prototype.viewContact = function (contact) {
        if (!contact || !contact._id) {
            return;
        }
        this.router.navigate(['/contacts', contact._id]);
    };
    ContactsPage.prototype.setBuyerNeedsFilters = function () {
        if (this.filterListing && !this.filterListing.propertyId) {
            this.listType = 'all';
        }
        else if (this.filterListing && this.filterListing.propertyId) {
            this.filterType = 'buyerNeeds';
            this.buyerNeedsFilterPanel.setFilterValue('Price', 'min', this.filterListing.minPrice);
            this.buyerNeedsFilterPanel.setFilterValue('Price', 'max', this.filterListing.maxPrice);
            this.buyerNeedsFilterPanel.setFilterValue('Square feet', 'min', this.filterListing.minSqft);
            this.buyerNeedsFilterPanel.setFilterValue('Square feet', 'max', this.filterListing.maxSqft);
            this.buyerNeedsFilterPanel.selectFilterValue('Building type', this.filterListing.propertyType);
            this.buyerNeedsFilterPanel.setFilterValue('Minimum CAP rate', 'min', this.filterListing.minCapRate);
            this.buyerNeedsFilterPanel.setFilterValue('Minimum cash-on-cash', 'min', this.filterListing.minCashOnCash);
            this.buyerNeedsFilterPanel.setFilterValue('Minimum LIRR', 'min', this.filterListing.minLIRR);
            this.buyerNeedsFilterPanel.setFilterValue('Minimum IRR', 'min', this.filterListing.minIRR);
        }
    };
    ContactsPage.prototype.getFilterQuery = function () {
        if (this.filterType === 'contacts') {
            return FilterPanel.getCombinedQuery(this.searchFilterPanel.getQuery(), this.contactsFilterPanel.getQuery());
        }
        else {
            return FilterPanel.getCombinedQuery(this.searchFilterPanel.getQuery(), this.buyerNeedsFilterPanel.getQuery());
        }
    };
    return ContactsPage;
}(BaseListPage));
export { ContactsPage };
