import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as moment from 'moment';
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AutoCompleteComponent } from '@apto/autocomplete';
import { AuthService, LogsService } from '@apto/ionic-lib/services';
import { ContractModel } from '@apto/models';
import { CompaniesService, ContactsService, PropertiesService, ImagesService, TypesService, FeaturesService, PropertiesServiceSpaceAutocomplete } from '@apto/services';
import { BasePipelineEditForm } from '../base-pipeline-edit-form/base-pipeline-edit-form';
import { DECIMAL_REGEX, INTEGER_REGEX, PERCENT_INT_REGEX, validationMessages } from '@apto/shared';
var EditContractForm = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(EditContractForm, _super);
    function EditContractForm(companiesService, contactsService, propertiesService, spaceAutocompleteService, featuresService, authService, logsService, formBuilder, imagesService, typesService) {
        var _this = _super.call(this, companiesService, contactsService, propertiesService, featuresService, authService, logsService) || this;
        _this.companiesService = companiesService;
        _this.contactsService = contactsService;
        _this.propertiesService = propertiesService;
        _this.spaceAutocompleteService = spaceAutocompleteService;
        _this.featuresService = featuresService;
        _this.authService = authService;
        _this.logsService = logsService;
        _this.formBuilder = formBuilder;
        _this.imagesService = imagesService;
        _this.typesService = typesService;
        _this.ContractModel = ContractModel;
        _this.dealType = '';
        _this.isPendingLease = false;
        _this.isPendingSale = false;
        _this.isSaving = false;
        _this.photos = [];
        _this.tomorrow = moment().add(1, 'day');
        _this.types = [];
        _this.validationMessages = validationMessages;
        _this.datepickerOptions = {
            alignSelectorRight: true,
            openSelectorTopOfInput: true,
            showSelectorArrow: false
        };
        return _this;
    }
    EditContractForm.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.pipelineObject) {
            this.pipelineObject = new ContractModel();
        }
        else {
            this.setDealType(this.pipelineObject.type);
        }
        if (this.pipelineObject.propertyId) {
            this.photos = [];
            this.imagesService.byObjectId(this.pipelineObject.propertyId)
                .then(function (blobs) {
                _this.photos = blobs;
            });
        }
        this.setupForm();
        this.getTypes();
    };
    EditContractForm.prototype.propertySelected = function (property) {
        this.spaceAutocompleteService.parentPropertyId = property._id;
        this.spaceAutoComplete.clearValue();
        this.setSpaceAutocompleteState();
    };
    EditContractForm.prototype.propertyChanged = function (ky) {
        if (ky === '' || !this.propertyAutoComplete.getValue()) {
            this.spaceAutocompleteService.parentPropertyId = null;
            this.spaceAutoComplete.clearValue();
        }
        this.setSpaceAutocompleteState();
    };
    EditContractForm.prototype.setSpaceAutocompleteState = function () {
        if (!this.spaceAutocompleteService.parentPropertyId) {
            this.spaceAutoComplete.setDisabledState(true);
        }
        else {
            this.spaceAutoComplete.setDisabledState(false);
        }
    };
    EditContractForm.prototype.save = function () {
        var _this = this;
        if (this.contractForm.valid) {
            Object.assign(this.pipelineObject, this.contractForm.value);
            this.saveRelationship(this.buyerAttorneyContactAutoComplete, 'buyerAttorneyContact', 'buyerAttorneyContactId');
            this.saveRelationship(this.buyerCompanyAutoComplete, 'buyerCompany', 'buyerCompanyId');
            this.saveRelationship(this.buyerContactAutoComplete, 'buyerContact', 'buyerContactId');
            this.saveRelationship(this.buyerLoanContactAutoComplete, 'buyerLoanContact', 'buyerLoanContactId');
            this.saveRelationship(this.landlordCompanyAutoComplete, 'landlordCompany', 'landlordCompanyId');
            this.saveRelationship(this.landlordContactAutoComplete, 'landlordContact', 'landlordContactId');
            this.saveRelationship(this.propertyAutoComplete, 'tempProperty', 'tempPropertyId');
            this.saveRelationship(this.sellerAttorneyContactAutoComplete, 'sellerAttorneyContact', 'sellerAttorneyContactId');
            this.saveRelationship(this.sellerCompanyAutoComplete, 'sellerCompany', 'sellerCompanyId');
            this.saveRelationship(this.sellerContactAutoComplete, 'sellerContact', 'sellerContactId');
            this.saveRelationship(this.tenantCompanyAutoComplete, 'tenantCompany', 'tenantCompanyId');
            this.saveRelationship(this.tenantContactAutoComplete, 'tenantContact', 'tenantContactId');
            this.saveRelationship(this.titleCompanyAttorneyContactAutoComplete, 'titleCompanyAttorneyContact', 'titleCompanyAttorneyContactId'); // tslint:disable-line max-line-length
            // set tempProperty back to property
            this.pipelineObject.property = this.pipelineObject.tempProperty;
            this.pipelineObject.propertyId = this.pipelineObject.tempPropertyId;
            if (this.spacesEnabled) {
                this.saveRelationship(this.spaceAutoComplete, 'spaceProperty', 'spacePropertyId');
                if (this.pipelineObject.spacePropertyId && this.pipelineObject.spaceProperty) {
                    this.pipelineObject.spaceParentPropertyId = this.pipelineObject.propertyId;
                    this.pipelineObject.property = this.pipelineObject.spaceProperty;
                    this.pipelineObject.propertyId = this.pipelineObject.spacePropertyId;
                }
            }
            return { contract: this.pipelineObject };
        }
        else {
            Object.keys(this.contractForm.controls).forEach(function (fieldKey) {
                _this.contractForm.controls[fieldKey].markAsDirty();
            });
        }
    };
    EditContractForm.prototype.chooseDealType = function (option) {
        this.setDealType(option);
        if (!this.isPendingLease && this.spacesEnabled) {
            this.spaceAutoComplete.clearValue();
        }
    };
    EditContractForm.prototype.setDealType = function (type) {
        this.dealType = type;
        this.pipelineObject.type = type;
        this.isPendingSale = this.pipelineObject.type && this.pipelineObject.type.toLowerCase().includes('sale');
        this.isPendingLease = this.pipelineObject.type && this.pipelineObject.type.toLowerCase().includes('lease');
    };
    // called from base class ngOnDestroy
    EditContractForm.prototype.onDestroy = function () {
        this.spaceAutocompleteService.parentPropertyId = null;
    };
    EditContractForm.prototype.setupForm = function () {
        var _this = this;
        this.pipelineObject.tempProperty = this.pipelineObject.property;
        this.pipelineObject.tempPropertyId = this.pipelineObject.propertyId;
        this.setCompany(this.buyerCompanyAutoComplete, 'buyerCompany', 'buyerCompanyId');
        this.setCompany(this.landlordCompanyAutoComplete, 'landlordCompany', 'landlordCompanyId');
        this.setCompany(this.sellerCompanyAutoComplete, 'sellerCompany', 'sellerCompanyId');
        this.setCompany(this.tenantCompanyAutoComplete, 'tenantCompany', 'tenantCompanyId');
        this.setContact(this.buyerAttorneyContactAutoComplete, 'buyerAttorneyContact', 'buyerAttorneyContactId');
        this.setContact(this.buyerContactAutoComplete, 'buyerContact', 'buyerContactId');
        this.setContact(this.buyerLoanContactAutoComplete, 'buyerLoanContact', 'buyerLoanContactId');
        this.setContact(this.landlordContactAutoComplete, 'landlordContact', 'landlordContactId');
        this.setContact(this.sellerAttorneyContactAutoComplete, 'sellerAttorneyContact', 'sellerAttorneyContactId');
        this.setContact(this.sellerContactAutoComplete, 'sellerContact', 'sellerContactId');
        this.setContact(this.tenantContactAutoComplete, 'tenantContact', 'tenantContactId');
        this.setContact(this.titleCompanyAttorneyContactAutoComplete, 'titleCompanyAttorneyContact', 'titleCompanyAttorneyContactId');
        if (this.spacesEnabled) {
            this.setupFormForSpace();
        }
        else {
            this.setProperty(this.propertyAutoComplete, 'tempProperty', 'tempPropertyId');
        }
        var contractName = this.pipelineObject && this.pipelineObject.name || '';
        var validateWithPattern = function (keyField, reg) {
            return [_this.pipelineObject[keyField], Validators.pattern(reg)];
        };
        this.contractForm = this.formBuilder.group({
            contractPrice: validateWithPattern('contractPrice', INTEGER_REGEX),
            commissionAmount: validateWithPattern('commissionAmount', DECIMAL_REGEX),
            contractCloseDate: this.pipelineObject && this.pipelineObject.contractCloseDate,
            deposit: validateWithPattern('deposit', INTEGER_REGEX),
            effectiveDate: this.pipelineObject && this.pipelineObject.effectiveDate,
            name: [contractName, Validators.compose([Validators.required, Validators.maxLength(80)])],
            probability: validateWithPattern('probability', PERCENT_INT_REGEX),
            type: [this.pipelineObject && this.pipelineObject.type, Validators.required]
        });
    };
    EditContractForm.prototype.setupFormForSpace = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.pipelineObject.tempPropertyId && !this.pipelineObject.tempProperty))
                            return [3 /*break*/, 2];
                        _a = this.pipelineObject;
                        return [4 /*yield*/, this.propertiesService.byId(this.pipelineObject.tempPropertyId)];
                    case 1:
                        _a.tempProperty = _b.sent();
                        _b.label = 2;
                    case 2:
                        // if we have a property and it is a space set the space stuff
                        if (this.pipelineObject.tempProperty && this.pipelineObject.tempProperty.parentPropertyId) {
                            // set the space from the property because it is actually a space
                            this.pipelineObject.spacePropertyId = this.pipelineObject.tempProperty._id;
                            this.pipelineObject.spaceProperty = this.pipelineObject.tempProperty;
                            // reset the tempProperty to be the parent property
                            this.pipelineObject.tempPropertyId = this.pipelineObject.tempProperty.parentPropertyId;
                            this.pipelineObject.tempProperty = null;
                        }
                        // set autocomplete scope
                        this.spaceAutocompleteService.parentPropertyId = this.pipelineObject.tempPropertyId;
                        this.pipelineObject.spaceParentPropertyId = this.pipelineObject.tempPropertyId;
                        this.setSpace(this.spaceAutoComplete, 'spaceProperty', 'spacePropertyId');
                        this.setProperty(this.propertyAutoComplete, 'tempProperty', 'tempPropertyId');
                        this.setSpaceAutocompleteState();
                        return [2 /*return*/];
                }
            });
        });
    };
    EditContractForm.prototype.getTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var types;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        types = _a.sent();
                        this.types = Object.keys(types.contractTypes);
                        return [2 /*return*/];
                }
            });
        });
    };
    return EditContractForm;
}(BasePipelineEditForm));
export { EditContractForm };
