var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { ModalController } from 'ionic-angular';
import { PropertyModel, UnitMixModel } from '@apto/models';
import { UnitMixService } from '@apto/services';
import { UnitMixModal, UnitMixModalMode } from '@apto/shared/modals';
import './unit-mix-card.scss';
var UnitMixCard = /** @class */ /*@__PURE__*/ (function () {
    function UnitMixCard(modalController, unitMixService) {
        this.modalController = modalController;
        this.unitMixService = unitMixService;
        this.unitMixes = [];
        this.subscriptions = [];
    }
    UnitMixCard.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.unitMixService.onDeleted.subscribe(function () { return _this.getUnitMixes(); }), this.unitMixService.onUpserted.subscribe(function () { return _this.getUnitMixes(); }));
        this.getUnitMixes();
    };
    UnitMixCard.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    UnitMixCard.prototype.openUnitMixModal = function (unitMixModel) {
        this.modalController.create(UnitMixModal, {
            mode: UnitMixModalMode.EDIT,
            selectedRecord: unitMixModel || new UnitMixModel({ property: this.property })
        }).present({ updateUrl: false });
    };
    UnitMixCard.prototype.rentAndSquareFootage = function (unitMix) {
        var items = [];
        if (unitMix.monthlyRent) {
            items.push("$" + unitMix.monthlyRent + "/month");
        }
        if (unitMix.unitSf) {
            items.push(unitMix.unitSf + " sqft");
        }
        if (unitMix.monthlyRent && unitMix.unitSf) {
            items.push("$" + unitMix.monthlyRentPerSf + "/sqft/month");
        }
        return items.join(' &bull; ');
    };
    UnitMixCard.prototype.getUnitMixes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.loadingMessage = 'Loading unit mixes...';
                        if (!this.property._id)
                            return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.unitMixService.fetch({
                                where: { propertyId: this.property._id },
                            })];
                    case 1:
                        _a.unitMixes = _b.sent();
                        _b.label = 2;
                    case 2:
                        this.loadingMessage = null;
                        return [2 /*return*/];
                }
            });
        });
    };
    return UnitMixCard;
}());
export { UnitMixCard };
