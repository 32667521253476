import { __extends } from "tslib";
import { OAuthProvider } from 'ng2-cordova-oauth/provider';
var Salesforce = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(Salesforce, _super);
    function Salesforce() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.authUrl = 'https://login.salesforce.com/services/oauth2/authorize';
        _this.APP_SCOPE_DELIMITER = ' ';
        _this.defaults = {
            responseType: 'code'
        };
        return _this;
    }
    Salesforce.prototype.enableProductionLogin = function () {
        this.authUrl = 'https://login.salesforce.com/services/oauth2/authorize';
    };
    Salesforce.prototype.enableSandboxLogin = function () {
        this.authUrl = 'https://test.salesforce.com/services/oauth2/authorize';
    };
    Salesforce.prototype.optionsToDialogUrl = function (options) {
        var url = _super.prototype.optionsToDialogUrl.call(this, options);
        if (options.prompt) {
            url += "&prompt=" + options.prompt;
        }
        return url;
    };
    return Salesforce;
}(OAuthProvider));
export { Salesforce };
