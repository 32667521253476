<ion-card class="offers-card">
    <ion-card-header class="accepted-offer-card--font-small">Offers</ion-card-header>
    <ion-item *ngIf="!offers.length"
              class="empty-card">
        <ion-icon name="attach_money"
                  material-icons
                  circle
                  small
                  item-left></ion-icon>
        Add an offer for this property
    </ion-item>

    <ion-item *ngFor="let offer of offers"
              detail-push
              (click)="onClick.emit(offer)">
        <ion-icon material-icons
                  name="attach_money"
                  item-left
                  circle
                  small>
        </ion-icon>
        <span *ngIf="offer.name">{{ offer.name }}</span>
        <span *ngIf="offer.name && offer.offerPrice || offer.name && offer.status || offer.name && offer.offerDate"> • </span>
        <span *ngIf="offer.offerPrice">{{ offer.offerPrice | currency:'USD':true:'1.0-0' }}</span>
        <span class="offers-card-light-text"
              *ngIf="offer.offerPrice && offer.status || offer.offerPrice && offer.offerDate"> • </span>
        <span class="offers-card-light-text"
              *ngIf="offer.status">{{ offer.status }}</span>
        <span class="offers-card-light-text"
              *ngIf="offer.status && offer.offerDate"> • </span>
        <span class="offers-card-light-text"
              *ngIf="offer.offerDate">{{ offer.offerDate | dateFormat}}</span>
        <div>
            <span class="offers-card-light-text"
                  *ngIf="offer.purchaser?.fullName">{{ offer.purchaser.fullName }}</span>
            <span class="offers-card-light-text"
                  *ngIf="offer.purchaser?.fullName && offer?.purchaser?.company?.name"> • </span>
            <span class="offers-card-light-text"
                  *ngIf="offer.purchaser?.company?.name">{{ offer.purchaser.company.name }}</span>
        </div>
    </ion-item>

    <busy-panel *ngIf="isInitializingOffers"
                message="Loading offers..."></busy-panel>
    <ion-row class="card-footer-buttons">
        <ion-col text-left>
            <button ion-button
                    clear
                    (click)="exportCsv()">
                Export
            </button>
        </ion-col>
        <ion-col text-right>
            <button ion-button
                    clear
                    (click)="addOffer()">
                Add Offer
            </button>
        </ion-col>
    </ion-row>
</ion-card>