import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector } from '@angular/core';
import { TaskModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
import { ContactsService } from '../contacts/contacts-service';
import { AlertController } from 'ionic-angular';
import moment from 'moment';
var TasksService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(TasksService, _super);
    function TasksService(injector, contactsService, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.contactsService = contactsService;
        _this.alertController = alertController;
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.TASKS;
        _this.model = TaskModel;
        _this.serviceName = SERVICE_NAMES.TASKS;
        _this.attachContacts = function (tasks) {
            var contactIds = tasks.map(function (task) {
                return task.contactId;
            });
            var uniqueContactIds = Array.from(new Set(contactIds));
            return _this.contactsService.in(uniqueContactIds)
                .then(function (contacts) {
                tasks.forEach(function (task) {
                    task.contact = contacts.find(function (contact) { return task.contactId === contact._id; });
                });
                return tasks;
            });
        };
        return _this;
    }
    TasksService.prototype.fetch = function (params) {
        return _super.prototype.fetch.call(this, params)
            .then(this.attachContacts);
    };
    TasksService.prototype.byContact = function (contactId, force) {
        var _this = this;
        var areContactTasksCached = this.cacheService.get(this.serviceName, contactId);
        if (areContactTasksCached && !force) {
            var contactTasks = this.cacheService.getAll(this.serviceName).filter(function (task) { return task.contactId === contactId; });
            return Promise.resolve(contactTasks);
        }
        var params = {
            where: {
                contactId: contactId
            }
        };
        return this.fetch(params)
            .then(function (tasks) {
            _this.cacheService.add(_this.serviceName, contactId, true); // flag that we have already queried all tasks for this contact
            return tasks;
        });
    };
    TasksService.prototype.byRelatedId = function (whatId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.byRelatedIds([whatId])];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    TasksService.prototype.byRelatedIds = function (whatIds, cacheBust, params) {
        var _this = this;
        if (cacheBust === void 0) {
            cacheBust = false;
        }
        if (params === void 0) {
            params = {};
        }
        whatIds = whatIds.filter(function (whatId) { return whatId && whatId !== 'null'; }); // remove null ids from array;
        if (!whatIds.length) {
            return Promise.resolve([]);
        }
        var cachedWhatIds = [];
        if (!cacheBust) {
            whatIds = whatIds.filter(function (whatId) {
                if (_this.cacheService.get(_this.serviceName, whatId)) {
                    cachedWhatIds.push(whatId);
                    return false;
                }
                return true;
            });
        }
        var cachedResults = [];
        if (cachedWhatIds.length) {
            cachedResults = this.cacheService.getAll(this.serviceName).filter(function (task) { return cachedWhatIds.indexOf(task.whatId) !== -1; });
        }
        if (!whatIds.length) {
            return Promise.resolve(cachedResults);
        }
        var chunkedQueries = [];
        var idsToChunk = Object.assign([], whatIds);
        while (idsToChunk.length > 0) {
            Object.assign(params, {
                where: {
                    whatId: {
                        $in: idsToChunk.splice(0, 200)
                    }
                }
            });
            chunkedQueries.push(this.fetch(params));
        }
        return Promise.all(chunkedQueries)
            .then(function (results) {
            // flag that we have already queried all tasks for these related ids
            whatIds.forEach(function (whatId) { return _this.cacheService.add(_this.serviceName, whatId, true); });
            return [].concat.apply(cachedResults, results); // flatten results arrays
        });
    };
    TasksService.prototype.completedTasks = function (params) {
        params = this.cloneParams(params);
        params.where.status = TaskModel.STATUS.COMPLETE;
        params.where.callDisposition = null;
        params.where.callResult = null;
        params.ownerOnly = true;
        return this.fetch(params);
    };
    TasksService.prototype.openTasks = function (params) {
        params = this.cloneParams(params);
        params.where.status = {
            $ne: TaskModel.STATUS.COMPLETE
        };
        params.ownerOnly = true;
        return this.fetch(params);
    };
    TasksService.prototype.upsert = function (model) {
        // instead of serializing the dueDate as an ISO string (default behavior for a POST),
        // send just the date string based on the user's timezone.
        if (!(model instanceof TaskModel)) {
            // if a JSON representation was given as the param, create an instance of an actual TaskModel
            // so that we can get a due date string
            model = new TaskModel(model);
        }
        model.dueDate = moment.utc(model.dueDate).format('YYYY-MM-DD');
        if (model.isComplete && model.type === TaskModel.TYPE.CALL && !model.callResult) {
            model.callResult = TaskModel.RESULT.REACHED;
        }
        return _super.prototype.upsert.call(this, model);
    };
    TasksService.prototype.latestMarketingTask = function (tasks) {
        var statusArray = Object.keys(TaskModel.MARKETING_STATUSES).map(function (key) {
            return TaskModel.MARKETING_STATUSES[key];
        });
        tasks.sort(function (a, b) {
            if (a.marketingStatus === b.marketingStatus) {
                return 0;
            }
            else if (statusArray.indexOf(a.marketingStatus) < statusArray.indexOf(b.marketingStatus)) {
                return 1;
            }
            else {
                return -1;
            }
        });
        return !tasks.length ? null : tasks[0];
    };
    TasksService.prototype.getQueryString = function (params) {
        params = params || {};
        params.where = Object.assign({}, { IsRecurrence: false }, params.where);
        return _super.prototype.getQueryString.call(this, params);
    };
    TasksService.prototype.cloneParams = function (params) {
        var clonedParams = Object.assign({}, params);
        clonedParams.where = Object.assign({}, clonedParams.where);
        return clonedParams;
    };
    return TasksService;
}(BaseService));
export { TasksService };
