<ion-header class="modal-header offers-modal offers-modal--header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton();"
                    data-automation="modalBackButton">
                <ion-icon name="arrow-back"></ion-icon>
            </button>
        </ion-buttons>

        <ion-title *ngIf="mode === Modes.LIST">{{ title }}</ion-title>
        <ion-title *ngIf="mode === Modes.VIEW">Offer Details</ion-title>
        <ion-title *ngIf="mode === Modes.EDIT">{{ selectedRecord._id ? 'Edit' : 'Add' }} Offer</ion-title>

        <ion-buttons end>
            <button *ngIf="mode === Modes.VIEW"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="setMode(Modes.EDIT)"
                    data-automation="modal-edit-button">
                Edit
            </button>
            <button *ngIf="mode === Modes.EDIT && selectedRecord._id"
                    ion-button
                    clear
                    color="danger"
                    hold-button
                    (hold)="delete()"
                    data-automation="modal-delete-button">
                Hold to Delete
            </button>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismiss()"
                    data-automation="modal-dismiss-button">
                <ion-icon name="close"></ion-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal open-tasks-modal">
    <ion-slides (ionSlideWillChange)="scrollToTop()">
        <ion-slide>
            <ion-content>
                <ion-card>
                    <ion-card-header>Open Details</ion-card-header>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide">
            <ion-content>
                <ion-card>
                    <ion-card-header>Offer Details</ion-card-header>
                    <ion-card-content>
                        <div class="offers-modal--detail-item"
                        *ngIf="selectedRecord.name">
                            <ion-label stacked>Offer name</ion-label>
                            <p class="dark-text">
                                {{ selectedRecord.name }}
                            </p>
                        </div>
                        <div class="offers-modal--detail-item"
                        *ngIf="selectedRecord.status">
                            <ion-label stacked>Offer status</ion-label>
                            <p class="dark-text">
                                {{ selectedRecord.status }}
                            </p>
                        </div>
                        <div class="offers-modal--detail-item"
                        *ngIf="selectedRecord.offerDate">
                            <ion-label stacked>Offer date</ion-label>
                            <p class="dark-text">
                                {{ selectedRecord.offerDate | dateFormat}}
                            </p>
                        </div>
                        <div class="offers-modal--detail-item"
                        *ngIf="selectedRecord.offerPrice">
                            <ion-label stacked>Offer price</ion-label>
                            <p class="dark-text">
                                {{ selectedRecord.offerPrice | currency:'USD':true:'1.0-0' }}
                            </p>
                        </div>
                        <div class="offers-modal--detail-item"
                        *ngIf="selectedRecord.purchaser">
                            <ion-label stacked>Offerer</ion-label>
                            <p class="dark-text">
                                {{ selectedRecord.purchaser.fullName }}
                            </p>
                        </div>
                    </ion-card-content>
                </ion-card>
                <ion-card *ngIf="showAdditionalInformation">
                    <ion-card-header>Additional Information</ion-card-header>
                    <ion-card-content>
                        <div class="offers-modal--detail-item"
                        *ngIf="selectedRecord.feasibilityPeriod">
                            <ion-label stacked>Feasibility period</ion-label>
                            <p class="dark-text">
                                {{ selectedRecord.feasibilityPeriod }}
                            </p>
                        </div>
                        <div class="offers-modal--detail-item"
                        *ngIf="selectedRecord.financingPeriod">
                            <ion-label stacked>Financing period</ion-label>
                            <p class="dark-text">
                                {{ selectedRecord.financingPeriod }}
                            </p>
                        </div>
                        <div class="offers-modal--detail-item"
                        *ngIf="selectedRecord.closeDate">
                            <ion-label stacked>Close date</ion-label>
                            <p class="dark-text">
                                {{ selectedRecord.closeDate }}
                            </p>
                        </div>
                        <div class="offers-modal--detail-item"
                        *ngIf="selectedRecord.initialDeposit">
                            <ion-label stacked>Initial deposit</ion-label>
                            <p class="dark-text">
                                {{ selectedRecord.initialDeposit | currency:'USD':true:'1.0-0' }}
                            </p>
                        </div>
                        <div class="offers-modal--detail-item"
                        *ngIf="selectedRecord.additionalDeposit">
                            <ion-label stacked>Additional deposit</ion-label>
                            <p class="dark-text">
                                {{ selectedRecord.additionalDeposit | currency:'USD':true:'1.0-0' }}
                            </p>
                        </div>
                        <div class="offers-modal--detail-item"
                        *ngIf="selectedRecord.independentConsideration">
                            <ion-label stacked>Independent consideration</ion-label>
                            <p class="dark-text">
                                {{ selectedRecord.independentConsideration | currency:'USD':true:'1.0-0' }}
                            </p>
                        </div>
                    </ion-card-content>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide">
            <ion-content>
                <edit-offer-form [offer]="selectedRecord"></edit-offer-form>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>
