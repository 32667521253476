import { EventEmitter } from '@angular/core';
import { TaskModel } from '@apto/models';
import './activity-item.scss';
var ActivityItem = /** @class */ /*@__PURE__*/ (function () {
    function ActivityItem() {
        this.showActivityTitle = false;
        this.showSubInfo = false;
        this.onClick = new EventEmitter();
        this.TaskModel = TaskModel;
    }
    ActivityItem.prototype.getSubInfo = function () {
        var result = [];
        if (this.activity.type) {
            result.push("Type: " + this.activity.type);
        }
        if (this.activity.callResult && this.activity.type === TaskModel.TYPE.CALL) {
            result.push("Result: " + this.activity.callResult);
        }
        return result.join(', ');
    };
    ActivityItem.prototype.getIcon = function () {
        if (this.activity.type === TaskModel.TYPE.CALL) {
            return this.iconName = 'call';
        }
        else if (this.activity.type === TaskModel.TYPE.EMAIL) {
            return this.iconName = 'mail';
        }
        else if (this.activity.type === TaskModel.TYPE.MEETING) {
            return this.iconName = 'calendar';
        }
        else if (this.activity.type === TaskModel.TYPE.TOUR) {
            return this.iconName = 'home';
        }
        else {
            return this.iconName = 'document';
        }
    };
    ActivityItem.prototype.getStatusHtml = function () {
        if (this.activity.type === TaskModel.TYPE.CALL && this.activity.callResult === TaskModel.RESULT.REACHED) {
            return "<span class=\"call-result call-reached\"> Reached</span> by " + this.activity.ownerName;
        }
        else if (this.activity.type === TaskModel.TYPE.CALL && this.activity.callResult === TaskModel.RESULT.NOT_REACHED) {
            return "<span class=\"call-result\"> Not Reached</span> by " + this.activity.ownerName;
        }
        else if (this.activity.type === TaskModel.TYPE.CALL && this.activity.callResult === TaskModel.RESULT.LEFT_MESSAGE) {
            return "Message left by " + this.activity.ownerName;
        }
        else if (this.activity.type === TaskModel.TYPE.EMAIL) {
            return "Emailed by " + this.activity.ownerName;
        }
        else if (this.activity.type === TaskModel.TYPE.MEETING) {
            return "Met with " + this.activity.ownerName;
        }
        else if (this.activity.type === TaskModel.TYPE.TOUR) {
            return "Toured with " + this.activity.ownerName;
        }
        else {
            return "Other activity with " + this.activity.ownerName;
        }
    };
    return ActivityItem;
}());
export { ActivityItem };
