import { EventEmitter } from '@angular/core';
import { TaskModel } from '@apto/models';
import { AnalyticsService } from '@apto/services';
var AnimationTimeMs = 150; // tslint:disable-line
import './open-tasks.scss';
var OpenTasks = /** @class */ /*@__PURE__*/ (function () {
    function OpenTasks(analyticsService) {
        this.analyticsService = analyticsService;
        this.tasks = [];
        this.onSave = new EventEmitter();
        this.onTaskCompleted = new EventEmitter();
        this.onClick = new EventEmitter();
        this.animationState = 'open';
        this.shouldShow = false;
        this.markedATaskAsComplete = false;
        this.TaskModel = TaskModel; // tslint:disable-line
    }
    OpenTasks.prototype.show = function () {
        this.shouldShow = true;
    };
    OpenTasks.prototype.markTaskComplete = function (task) {
        this.markedATaskAsComplete = true;
        this.analyticsService.logEvent('Task Review Completion');
        this.onTaskCompleted.emit({ task: task });
    };
    OpenTasks.prototype.hide = function () {
        var _this = this;
        this.animationState = 'close';
        setTimeout(function () {
            _this.animationState = 'open';
            _this.shouldShow = false;
        }, AnimationTimeMs);
    };
    OpenTasks.prototype.view = function (task) {
        this.onClick.emit({
            task: task
        });
    };
    OpenTasks.prototype.continue = function () {
        if (this.markedATaskAsComplete === false) {
            this.analyticsService.logEvent('Task Review Skipped');
        }
        this.onSave.emit(null);
    };
    return OpenTasks;
}());
export { OpenTasks };
