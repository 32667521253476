<ion-card *ngIf="contacts.length || contactGroups.length || properties.length"
          class="add-call-list-members-card">
    <ion-card-header>
        Selected Contacts
    </ion-card-header>
    <ion-card-content>
        <ion-item>
            <ion-icon material-icons
                      small
                      name="account_circle"></ion-icon>
            <span *ngIf="!isLoadingCounts; else loadingContactCount">
                {{ contacts.length }} Contacts
            </span>
            <ng-template #loadingContactCount>
                <apto-shimmer class="add-call-list-members-card--contact-count"></apto-shimmer>
            </ng-template>
            <ion-icon material-icons
                      small
                      name="schedule"></ion-icon>
            <span *ngIf="!isLoadingCounts; else loadingCallTime">
                {{ contacts.length * (CallListModel.MINUTES_PER_CALL / 60) | hoursToCall }} to call
            </span>
            <ng-template #loadingCallTime>
                <apto-shimmer class="add-call-list-members-card--time-to-call"></apto-shimmer>
            </ng-template>
        </ion-item>
        <div class="add-call-list-members-card--ownership-selector"
             *ngIf="properties.length">
            <ion-label class="add-call-list-members-card--owner-selector-title-label">Owner roles to add</ion-label>
            <ion-list *ngIf="!isLoadingCounts; else loadingContactCount">
                <ion-item no-lines>
                    <ion-label stacked>Owner <span *ngIf="owners.length >= 1">({{owners.length}})</span></ion-label>
                    <ion-checkbox mode="ios"
                                  class="small"
                                  color="primary-blue"
                                  item-left
                                  [(ngModel)]="selectOwners"
                                  (ionChange)="updateContactsFromOwnerships()">
                    </ion-checkbox>
                </ion-item>
                <ion-item no-lines>
                    <ion-label stacked>Former owner <span *ngIf="formerOwners.length >= 1">({{formerOwners.length}})</span></ion-label>
                    <ion-checkbox mode="ios"
                                  class="small"
                                  color="primary-blue"
                                  item-left
                                  [(ngModel)]="selectFormerOwners"
                                  (ionChange)="updateContactsFromOwnerships()">
                    </ion-checkbox>
                </ion-item>
                <ion-item no-lines>
                    <ion-label stacked>Asset manager <span *ngIf="assetManagers.length >= 1">({{assetManagers.length}})</span></ion-label>
                    <ion-checkbox mode="ios"
                                  class="small"
                                  color="primary-blue"
                                  item-left
                                  [(ngModel)]="selectAssetManagers"
                                  (ionChange)="updateContactsFromOwnerships()">
                    </ion-checkbox>
                </ion-item>
            </ion-list>
        </div>
        <div *ngIf="limitError"
             class="add-call-list-members-card--call-list-limit-error">
            <p>
                We cannot add {{ limitError.amountOver }} contacts to the {{ limitError.callList.name }} Call List with {{ limitError.callList.members.length
                }} contacts.
            </p>
            <p>
                You will exceed the limit of {{ CallListModel.CALL_LIST_MEMBER_LIMIT | number: '1.0-0'}} contacts per call list.
            </p>
            <p>
                Feel free to create a new Call List or remove contacts to then add to this list.
            </p>
        </div>
    </ion-card-content>
    <ion-row class="card-footer-buttons">
        <ion-col>
            <button ion-button
                    clear
                    class="clear-all-button"
                    (click)="clearAll();"
                    *ngIf="clearButtonEnabled">
                CLEAR ALL
            </button>
        </ion-col>
        <ion-col text-right>
            <add-to-list-button [contacts]="contacts"
                                [filterListing]="filterListing"
                                [allCallLists]="allCallLists"
                                [isInitializing]="isInitializingCallLists"
                                (onAddList)="listAdded($event)"
                                (onAddListError)="showAddToListError($event)">
            </add-to-list-button>
        </ion-col>
    </ion-row>
</ion-card>