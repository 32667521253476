<ion-card class="transaction-history-card">
    <ion-card-header>Transaction history</ion-card-header>
    <ion-list class="transaction-history--sale-history-list"
              *ngIf="!isInitializing">
        <ion-item *ngFor="let transaction of transactionHistory"
                  detail-push
                  (click)="viewTransaction(transaction)"
                  class="transaction-history--sale-history-item">

            <ion-icon name="business"
                      circle
                      material-icons
                      item-left></ion-icon>

            <div *ngIf="transaction?.isSale">
                <ion-col class="transaction-history--sale-data-item dark-text"
                        data-automation="transaction-type"
                        width-33>Sold</ion-col>
                <span *ngIf="transaction?.salePrice"> • </span>
                <ion-col class="transaction-history--sale-data-item dark-text"
                        *ngIf="transaction?.salePrice"
                        data-automation="transaction-price"
                        width-33>{{transaction.salePrice | currency:'USD':'symbol':'1.0-0'}}</ion-col>
                <span *ngIf="transaction?.closeDate"> • </span>
                <ion-col class="transaction-history--sale-data-item"
                        *ngIf="transaction?.closeDate"
                        data-automation="transaction-date"
                        width-33> {{transaction.closeDate | date:"MM/dd/yy"}} </ion-col>
                <p *ngIf="transaction?.buyerCompany">{{transaction.buyerCompany.name}}</p>
            </div>
            <div *ngIf="transaction?.isLease">
                <ion-col class="transaction-history--sale-data-item dark-text"
                        data-automation="transaction-type"
                        width-33>Leased</ion-col>
                <span *ngIf="transaction?.rentalRate && transaction?.rentalRateType"> • </span>
                <ion-col class="transaction-history--sale-data-item dark-text"
                        *ngIf="transaction?.rentalRate && transaction?.rentalRateType"
                        data-automation="transaction-price"
                        width-33>{{transaction.rentalRateType.replace(regex, transaction.rentalRate | currency:'USD':'symbol':'1.0-0')}}</ion-col>
                <span *ngIf="transaction?.leaseCommencementDate"> • </span>
                <ion-col class="transaction-history--sale-data-item"
                        *ngIf="transaction?.leaseCommencementDate"
                        data-automation="transaction-date"
                        width-33> {{transaction.leaseCommencementDate | date:"MM/dd/yy"}} </ion-col>
                <p *ngIf="transaction?.tenantCompany">{{transaction.tenantCompany.name}}</p>
            </div>
        </ion-item>

        <ion-item *ngIf="(!transactionHistory || !transactionHistory.length) && !isInitializing"
                  class="empty-card">
            <ion-icon name="folder"
                      material-icons
                      circle
                      small
                      color="white"
                      item-left></ion-icon>
            Add transactions
        </ion-item>
    </ion-list>
    <busy-panel *ngIf="isInitializing"></busy-panel>
</ion-card>
