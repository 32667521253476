import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from '@angular/core';
import { ModalController, NavParams, ViewController } from 'ionic-angular';
import { ListingModel, PropertyModel } from '@apto/models';
import { AuthService, LogsService } from '@apto/ionic-lib/services';
import { ImagesService, OwnershipsService, PropertiesService, FeaturesService, AnalyticsService } from '@apto/services';
import { PropertyOwnershipsCard } from '@apto/ionic-lib/cards';
import { BaseSlideModal } from '@apto/shared/modals/base-slide-modal/base-slide-modal';
import { ClosedDealDetailsModal } from '@apto/pn/modals/closed-deal-details-modal/closed-deal-details-modal';
import { Router } from '@angular/router';
import { DashboardDataService } from '@apto/pn/services';
import { CompanyDetailsModal, CompanyDetailsModalMode, ViewRelatedOwnershipsModal } from '@apto/pn/modals';
import { EditPropertyForm } from '@apto/pn/components';
import { AttomService } from '@apto/pn/services/attom/attomService';
export var PropertyDetailsModalMode = /*@__PURE__*/ (function (PropertyDetailsModalMode) {
    PropertyDetailsModalMode[PropertyDetailsModalMode["LIST"] = 0] = "LIST";
    PropertyDetailsModalMode[PropertyDetailsModalMode["VIEW"] = 1] = "VIEW";
    PropertyDetailsModalMode[PropertyDetailsModalMode["EDIT"] = 2] = "EDIT";
    return PropertyDetailsModalMode;
})({});
import './property-details-modal.scss';
import { SpaceModal, SpaceModalMode } from '@apto/pn/modals/space-modal/space-modal';
import { ManageOwnershipsModal } from '@apto/pn';
import { Storage } from '@ionic/storage';
import { isObject, isEmpty } from '@apto/shared/utils/objectUtils';
var PropertyDetailsModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(PropertyDetailsModal, _super);
    function PropertyDetailsModal(navParams, viewController, dashboardDataService, imagesService, modalController, ownershipsService, propertiesService, router, authService, featuresService, logsService, storage, analyticsService, attomService) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.navParams = navParams;
        _this.viewController = viewController;
        _this.dashboardDataService = dashboardDataService;
        _this.imagesService = imagesService;
        _this.modalController = modalController;
        _this.ownershipsService = ownershipsService;
        _this.propertiesService = propertiesService;
        _this.router = router;
        _this.authService = authService;
        _this.featuresService = featuresService;
        _this.logsService = logsService;
        _this.storage = storage;
        _this.analyticsService = analyticsService;
        _this.attomService = attomService;
        _this.Modes = PropertyDetailsModalMode;
        _this.records = [];
        _this.selectedRecord = new PropertyModel();
        _this.selectedSortKey = 'Name';
        _this.types = [];
        _this.isLoadingComps = false;
        _this.isLoadingListings = false;
        _this.isLoadingOwnerships = false;
        _this.PropertyModel = PropertyModel; // tslint:disable-line
        _this.ownerships = [];
        _this.comps = [];
        _this.spacesEnabled = false;
        _this.SPACES_TAB_OVERVIEW_INDEX = 0;
        _this.SPACES_TAB_STACKING_PLAN_INDEX = 1;
        _this.ATTOM_TAB_INDEX = 2;
        _this.hasLoadedAttomData = false;
        _this.errorLoadingAttomData = false;
        _this.attomData = {
            fields: null,
            sales: null,
            taxes: null,
            owner: null,
            mortgage: null
        };
        _this.attomDataBadgeCount = 0;
        _this.attomDataPointCount = 0;
        _this.attomDataOwnerCount = 0;
        _this.hasDisableAttomBadge = true;
        _this.attomDataPointsEnabled = false;
        _this.isObject = isObject;
        _this.isEmpty = isEmpty;
        _this.modalUrls = new Map([[PropertyDetailsModalMode.VIEW, '/:propertyId']]);
        _this.sortMap = {
            Value: {
                getValue: function (property) {
                    return property.salePrice;
                },
                sortOrder: 'descending'
            },
            Name: {
                getValue: function (property) {
                    return property.name.toLowerCase();
                },
                sortOrder: 'ascending'
            }
        };
        _this.subscriptions = [];
        _this.contactId = _this.navParams.get('contactId');
        _this.mode = _this.navParams.get('mode') || PropertyDetailsModalMode.LIST;
        var records = navParams.get('properties');
        _this.records = (records && records.concat()) || [];
        _this.selectedRecord = _this.navParams.get('selectedProperty') || new PropertyModel();
        _this.spacesTabIndex =
            _this.navParams.get('spacesTabIndex') || _this.SPACES_TAB_OVERVIEW_INDEX;
        _this.types = _this.navParams.get('types') || [];
        // Log this event in constructor to capture the initial view of the Stacking Plan if it was opened directly from elsewhere
        if (_this.spacesTabIndex === _this.SPACES_TAB_STACKING_PLAN_INDEX) {
            _this.analyticsService.logEvent('Spaces View Stacking Plan');
        }
        _this.subscriptions.push(_this.authService.onSetUser.subscribe(function (user) {
            _this.spacesEnabled = _this.featuresService.isFeatureEnabled(_this.featuresService.FEATURES.SPACES);
            _this.attomDataPointsEnabled = _this.featuresService.isFeatureEnabled(_this.featuresService.FEATURES.ATTOM_DATA_POINTS);
        }, _this.logsService.error));
        return _this;
    }
    PropertyDetailsModal.prototype.ionViewWillEnter = function () {
        _super.prototype.ionViewWillEnter.call(this);
        if (this.mode === this.Modes.VIEW) {
            this.view(this.selectedRecord);
        }
        this.sort();
    };
    PropertyDetailsModal.prototype.ngOnInit = function () {
        // Only if the form was opened from a map page, it will have an 'addressSuggestions' field and geodata.
        // Otherwise no need to mark 'not mappable' (form was opened from deal page)
        this.isShownOnMap = Object.keys(this.selectedRecord).includes('addressSuggestions');
        // remove space from type options of a property
        if (this.types && this.types.length) {
            this.types = this.types.filter(function (t) { return t.toLowerCase() !== 'space'; });
        }
        this.getSubscriptions();
    };
    PropertyDetailsModal.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    PropertyDetailsModal.prototype.viewTransaction = function (event) {
        var detailsModal = this.modalController.create(ClosedDealDetailsModal, {
            selectedRecord: event
        });
        detailsModal.present({ updateUrl: false });
    };
    PropertyDetailsModal.prototype.getPropertyIds = function (property, allProperties) {
        var propertyIds = allProperties.map(function (record) { return record._id; });
        if (property._id && propertyIds.indexOf(property._id) === -1) {
            propertyIds.push(property._id);
        }
        return propertyIds;
    };
    PropertyDetailsModal.prototype.showListingInfo = function () {
        if (this.listing) {
            return !(this.listing.dateOffMarket ||
                this.listing.onMarketStatus === ListingModel.STATUSES.IN_CONTRACT);
        }
        return false;
    };
    PropertyDetailsModal.prototype.view = function (property) {
        var _this = this;
        this.selectedRecord = property;
        var propertyIds = this.getPropertyIds(this.selectedRecord, this.records);
        this.loadComps(propertyIds);
        this.loadListings(propertyIds);
        this.loadOwnerships(propertyIds);
        this.photos = [];
        this.imagesService.byObjectId(property._id).then(function (blobs) {
            _this.photos = blobs;
        });
        this.loadAttomData(property);
        this.setMode(PropertyDetailsModalMode.VIEW);
    };
    PropertyDetailsModal.prototype.loadAttomData = function (property) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, e_1;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.storage.get('apto-attom-disable-datapoints-badge')];
                    case 1:
                        _a.hasDisableAttomBadge = _c.sent();
                        if (!(!this.hasDisableAttomBadge &&
                            this.featuresService.isFeatureEnabled(this.featuresService.FEATURES.ATTOM_DATA_POINTS)))
                            return [3 /*break*/, 6];
                        _c.label = 2;
                    case 2:
                        _c.trys.push([2, 4, , 5]);
                        _b = this;
                        return [4 /*yield*/, this.attomService.loadAll(this.selectedRecord)];
                    case 3:
                        _b.attomData = _c.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _c.sent();
                        this.logsService.error(e_1);
                        this.errorLoadingAttomData = true;
                        return [3 /*break*/, 5];
                    case 5:
                        if (!this.errorLoadingAttomData) {
                            this.attomDataPointCount = this.attomService.countFields(this.attomData) +
                                this.attomService.countSales(this.attomData) +
                                this.attomService.countMortgage(this.attomData) +
                                this.attomService.countTaxes(this.attomData);
                            this.attomDataOwnerCount = this.attomService.countOwners(this.attomData);
                            this.attomDataBadgeCount = this.attomDataPointCount + this.attomDataOwnerCount;
                        }
                        _c.label = 6;
                    case 6:
                        this.hasLoadedAttomData = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyDetailsModal.prototype.save = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var formResult, savedProperty, propertyIds;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formResult = this.editPropertyForm.save();
                        if (!(formResult && formResult.property))
                            return [3 /*break*/, 2];
                        this.scrollToTop();
                        this.editPropertyForm.isSaving = true;
                        return [4 /*yield*/, this.propertiesService.upsert(formResult.property)];
                    case 1:
                        savedProperty = _a.sent();
                        if (this.navParams.get('isQuickAdd')) {
                            // TODO: Remove next line later
                            savedProperty.addressString = savedProperty.address.toString().replace(/\r/, ' ');
                            return [2 /*return*/, this.viewController.dismiss(savedProperty)];
                        }
                        if (!savedProperty) {
                            return [2 /*return*/, this.dismiss()];
                        }
                        this.editPropertyForm.isSaving = false;
                        propertyIds = this.getPropertyIds(savedProperty, this.records);
                        this.dashboardDataService.clearPropertyData(propertyIds);
                        this.view(savedProperty);
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    PropertyDetailsModal.prototype.viewCompany = function (event) {
        var companyModal = this.modalController.create(CompanyDetailsModal, {
            mode: CompanyDetailsModalMode.VIEW,
            selectedCompany: event.company
        }, {
            cssClass: 'large-modal'
        });
        companyModal.present({ updateUrl: false });
    };
    PropertyDetailsModal.prototype.viewContact = function (_a) {
        var contact = _a.contact;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.dismiss({ dismissAll: true })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.router.navigate(['/contacts', contact._id])];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertyDetailsModal.prototype.viewOwnerships = function () {
        var relatedContactsModal = this.modalController.create(ViewRelatedOwnershipsModal, {
            ownerships: this.ownerships
        }, {
            cssClass: 'small-modal'
        });
        relatedContactsModal.present({ updateUrl: false });
    };
    PropertyDetailsModal.prototype.editOwnerships = function () {
        var _this = this;
        var ownershipModal = this.modalController.create(ManageOwnershipsModal, {
            selectedRecord: new PropertyModel(this.selectedRecord),
            ownerships: this.ownerships,
            isProperty: true
        });
        ownershipModal.onDidDismiss(function (result) {
            if (result) {
                _this.ownerships = result.ownerships;
                _this.dashboardDataService.clearPropertyData([_this.selectedRecord._id]);
            }
        });
        ownershipModal.present({ updateUrl: false });
    };
    Object.defineProperty(PropertyDetailsModal.prototype, "showUnitMix", {
        get: function () {
            var values = ['Mixed-Use', 'Multifamily'];
            return values.includes(this.selectedRecord.type);
        },
        enumerable: true,
        configurable: true
    });
    PropertyDetailsModal.prototype.sortChanged = function (sortValue) {
        this.selectedSortKey = sortValue;
        this.sort();
    };
    PropertyDetailsModal.prototype.tabChanged = function (event) {
        if (event.index === this.SPACES_TAB_STACKING_PLAN_INDEX) {
            this.analyticsService.logEvent('Spaces View Stacking Plan');
        }
    };
    PropertyDetailsModal.prototype.addSpace = function () {
        this.analyticsService.logEvent('Spaces Clicked Add Space From Stacking Plan');
        var spaceModal = this.modalController.create(SpaceModal, {
            mode: SpaceModalMode.EDIT,
            selectedRecord: {
                space: null,
                parentProperty: this.selectedRecord
            },
            isQuickAdd: false
        });
        return spaceModal.present({ updateUrl: false });
    };
    PropertyDetailsModal.prototype.openSpace = function (spaceData) {
        this.analyticsService.logEvent('Spaces Clicked View Space From Stacking Plan');
        var spaceModal = this.modalController.create(SpaceModal, {
            selectedRecord: {
                space: spaceData.space,
                parentProperty: this.selectedRecord
            },
            mode: SpaceModalMode.VIEW,
            isQuickAdd: false
        });
        return spaceModal.present({ updateUrl: false });
    };
    PropertyDetailsModal.prototype.sort = function () {
        var _this = this;
        this.records.sort(function (first, second) {
            var options = _this.sortMap[_this.selectedSortKey];
            var firstValue = options.getValue(first);
            var secondValue = options.getValue(second);
            if (firstValue > secondValue) {
                return options.sortOrder === 'ascending' ? 1 : -1;
            }
            else if (firstValue < secondValue) {
                return options.sortOrder === 'ascending' ? -1 : 1;
            }
            else {
                return 0;
            }
        });
    };
    PropertyDetailsModal.prototype.saveOwnerships = function (property, ownershipsToCreate) {
        var _this = this;
        var promises = [];
        ownershipsToCreate.forEach(function (ownership) {
            ownership.propertyId = property._id;
            promises.push(_this.ownershipsService.upsert(ownership));
        });
        return Promise.all(promises);
    };
    PropertyDetailsModal.prototype.getListing = function (listings) {
        var _this = this;
        if (!listings || !listings.length) {
            return null;
        }
        if (this.selectedRecord.listingId) {
            return listings.find(function (listing) { return listing._id === _this.selectedRecord.listingId; });
        }
        else {
            return null;
        }
    };
    PropertyDetailsModal.prototype.delete = function () {
        this.propertiesService.delete([this.selectedRecord._id]);
        this.dismiss();
    };
    PropertyDetailsModal.prototype.getSubscriptions = function () {
        var _this = this;
        this.subscriptions.push(this.propertiesService.onDeleted.subscribe(function (data) {
            if (data.payload.includes(_this.selectedRecord._id)) {
                _this.dismiss();
            }
        }, this.logsService.error), this.propertiesService.onUpserted.subscribe(function (data) {
            // guard to not act on spaces changing
            var newRecord = data.payload;
            if (!newRecord.parentPropertyId) {
                _this.selectedRecord = newRecord;
            }
        }, this.logsService.error));
    };
    PropertyDetailsModal.prototype.dismissWithoutSaving = function () {
        this.viewController.dismiss();
    };
    PropertyDetailsModal.prototype.loadComps = function (propertyIds) {
        var _this = this;
        this.isLoadingComps = true;
        this.dashboardDataService.getSoldAndLeasedComps(propertyIds).then(function (comps) {
            _this.comps = comps.filter(function (comp) {
                return (comp.soldPropertyId === _this.selectedRecord._id ||
                    comp.leasedPropertyId === _this.selectedRecord._id);
            });
            _this.isLoadingComps = false;
        });
    };
    PropertyDetailsModal.prototype.loadListings = function (propertyIds) {
        var _this = this;
        this.isLoadingListings = true;
        this.dashboardDataService.getListings(propertyIds).then(function (listings) {
            _this.listing = _this.getListing(listings);
            _this.isLoadingListings = false;
        });
    };
    PropertyDetailsModal.prototype.loadOwnerships = function (propertyIds) {
        var _this = this;
        this.isLoadingOwnerships = true;
        this.dashboardDataService
            .getOwnerships(propertyIds)
            .then(function (ownerships) {
            _this.ownerships = ownerships.filter(function (ownership) {
                return ownership.propertyId === _this.selectedRecord._id && !ownership._deleted;
            });
            // the card wont update itself because internally it splits up the ownerships
            // into different variables on instantiation that the template uses
            _this.propertyOwnershipsCard.ownerships = _this.ownerships;
            _this.isLoadingOwnerships = false;
        });
    };
    return PropertyDetailsModal;
}(BaseSlideModal));
export { PropertyDetailsModal };
