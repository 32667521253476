import { EventEmitter, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { LogsService } from '@apto/ionic-lib/services';
var FilterPanel = /** @class */ /*@__PURE__*/ (function () {
    function FilterPanel(resolver, logsService) {
        var _this = this;
        this.resolver = resolver;
        this.logsService = logsService;
        this.searchPlaceholder = '';
        this.searchQueryKey = '';
        this.showFilters = true;
        this.shownFilters = 0;
        this.onQueryChange = new EventEmitter();
        this.filters = [];
        this.isMinimized = true;
        this.lastQuery = '{}';
        this.isValid = function () {
            var invalidFilters = _this.filters.filter(function (f) { return !f.instance.isEmpty() && !f.instance.validate(); });
            return !(invalidFilters.length > 0);
        };
        this.isEmpty = function () {
            var nonEmptyFilters = _this.filters.filter(function (f) { return !f.instance.isEmpty(); });
            return !(nonEmptyFilters.length > 0);
        };
    }
    FilterPanel.getCombinedQuery = function (first, second) {
        var query = Object.assign({}, first);
        if (!second) {
            return query;
        }
        if (!Array.isArray(second)) {
            second = [second];
        }
        second.forEach(function (q) {
            Object.keys(q).forEach(function (key) {
                if (key === '$and' && '$and' in query) {
                    query.$and = query.$and.concat(q.$and);
                }
                else if (key === '$or' && '$or' in query) {
                    query.$or = query.$or.concat(q.$or);
                }
                else if (key === '$nor' && '$nor' in query) {
                    query.$nor = query.$nor.concat(q.$nor);
                }
                else if (key in query) {
                    if (!query.$and) {
                        query.$and = [];
                    }
                    query.$and.push((_a = {}, _a[key] = query[key], _a));
                    query.$and.push((_b = {}, _b[key] = q[key], _b));
                    delete query[key];
                }
                else {
                    query[key] = q[key];
                }
                var _a, _b;
            });
        });
        return query;
    };
    FilterPanel.prototype.ngOnDestroy = function () {
        this.clear();
    };
    FilterPanel.prototype.add = function (filterClass, options) {
        var factory = this.resolver.resolveComponentFactory(filterClass);
        // sort the component into the proper container
        var isOverflow = this.shownFilters > 0 && this.getFilters().length >= this.shownFilters;
        var container = isOverflow ? this.overflowContainer : this.filterContainer;
        var filter = container.createComponent(factory);
        var instance = filter.instance;
        instance.init(options);
        instance.onQueryChange.subscribe(this.applyFilters.bind(this), this.logsService.error.bind(this));
        this.filters.push(filter);
    };
    FilterPanel.prototype.setFilterValue = function (label, instanceMember, value) {
        if (value !== undefined) {
            var filterMatch = this.getFilter(label);
            filterMatch.instance[instanceMember] = value.toString();
            filterMatch.instance.onQueryChange.emit();
        }
    };
    FilterPanel.prototype.selectFilterValue = function (label, option) {
        var filterMatch = this.getFilter(label);
        filterMatch.instance.setSelected(option);
    };
    FilterPanel.prototype.applyFilters = function () {
        if (!this.isValid()) {
            return;
        }
        var query = this.getQuery();
        if (this.didQueryChange(query)) {
            this.lastQuery = JSON.stringify(query);
            this.onQueryChange.emit(query);
        }
    };
    /**
     * Gets the current filter components excluding the default search filter.
     */
    FilterPanel.prototype.getFilters = function () {
        return this.filters.slice(1);
    };
    FilterPanel.prototype.getFilter = function (label) {
        return this.filters.find(function (filter) {
            return filter.instance.label === label;
        });
    };
    FilterPanel.prototype.getQuery = function () {
        var query = {};
        this.filters.forEach(function (filter) {
            if (!filter.instance.isEmpty()) {
                var q = filter.instance.getQuery();
                if (q.$and) {
                    if (!query.$and) {
                        query.$and = [];
                    }
                    query.$and = query.$and.concat(q.$and);
                    delete q.$and;
                }
                Object.assign(query, q);
            }
        });
        return query;
    };
    Object.defineProperty(FilterPanel.prototype, "hasOverflow", {
        get: function () {
            return this.shownFilters > 0 && this.getFilters().length > this.shownFilters;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Resets each filter to its initial values.
     */
    FilterPanel.prototype.resetFilters = function () {
        this.filters.forEach(function (filter) { return filter.instance.reset(); });
    };
    /**
     * Toggles the filter panel between minimized and complete.
     */
    FilterPanel.prototype.toggle = function () {
        this.isMinimized = !this.isMinimized;
    };
    /**
     * Removes all filters in the FilterPanel.
     */
    FilterPanel.prototype.clear = function () {
        this.filters.forEach(function (filter) { return filter.destroy(); });
    };
    FilterPanel.prototype.didQueryChange = function (newQuery) {
        return JSON.stringify(newQuery) !== this.lastQuery;
    };
    return FilterPanel;
}());
export { FilterPanel };
