var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { throwError as observableThrowError, from as observableFrom, TimeoutError } from 'rxjs';
import { takeUntil, switchMap, timeout, catchError } from 'rxjs/operators';
import { Injector } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { AlertController } from 'ionic-angular';
import { AppService } from '@apto/services';
import { AuthService } from '../auth/auth-service';
import { LogsService } from '../logs';
import { HTTP_TIMEOUT } from '../../constants';
import { IonicTokenService } from '../ionic-token-service/ionic-token-service';
import { ConfigService } from '@apto/services';
var InterceptorService = /** @class */ /*@__PURE__*/ (function () {
    /** Note: If dependencies change here, update the providers in AptoIonicLib module to reflect the changes
     *  {
     *      provide: HTTP_INTERCEPTORS,
     *      useClass: ApiInterceptor,
     *      deps: [AlertController, Injector, TokenService],
     *      multi: true
     *  }
     */
    function InterceptorService(alertController, injector, tokenService, configService) {
        var _this = this;
        this.alertController = alertController;
        this.injector = injector;
        this.tokenService = tokenService;
        this.configService = configService;
        this.httpErrorHandlers = [];
        this.logoutButton = {
            role: 'logout',
            text: 'Log Out'
        };
        this.retryButton = {
            role: 'retry',
            text: 'Retry'
        };
        this.okButton = {
            role: 'ok',
            text: 'Ok'
        };
        this.httpErrorMessages = {
            401: {
                title: 'Session Expired',
                subTitle: 'Your session has expired. Please log in again.',
                buttons: [this.logoutButton]
            },
            504: {
                title: 'Server Error',
                subTitle: 'Unable to communicate with the server. Please check your data connection and try again.',
                buttons: [this.logoutButton, this.retryButton, this.okButton],
                cssClass: 'http-service-error'
            },
            default: {
                title: 'Server Error',
                subTitle: 'An unknown error has occurred. Please try again later.',
                buttons: [this.logoutButton, this.retryButton, this.okButton],
                cssClass: 'http-service-error'
            }
        };
        // if a user tries to manipulate an object that they deleted in Salesforce, ask them if they want to
        // recreate it or leave it deleted.
        this.handleMissingObject = function () {
            return new Promise(function (resolve, reject) {
                var httpAlert = _this.alertController.create({
                    title: 'Object deleted in Salesforce',
                    subTitle: 'That object no longer exists in Salesforce. Would you like to recreate it?',
                    buttons: [
                        { role: 'delete', text: 'No' },
                        { role: 'recreate', text: 'Yes' }
                    ],
                    cssClass: 'http-service-error'
                });
                httpAlert.onDidDismiss(function (data, role) {
                    if (role === 'recreate') {
                        resolve();
                    }
                    else {
                        reject('delete');
                    }
                });
                httpAlert.present();
            });
        };
    }
    InterceptorService.prototype.intercept = function (request, next) {
        var _this = this;
        var source = observableFrom(this.tokenService.getToken());
        return source.pipe(switchMap(function (token) {
            var headers = {};
            if (token) {
                headers.Authorization = "Bearer " + token;
            }
            if (!request.headers.has('Content-Type')) {
                headers.ContentType = 'application/json';
            }
            request = request.clone({ setHeaders: headers });
            return next.handle(request).pipe(takeUntil(AppService.onLogout), timeout(HTTP_TIMEOUT), catchError(_this.catchError(request, next)));
        }));
    };
    InterceptorService.prototype.isFailedResponseFromAttom = function (response) {
        var attomUrl = this.configService.attomProxyUrl.split('https://')[1];
        return response &&
            response.url &&
            response.url.indexOf(attomUrl) !== -1 &&
            response.status >= 400 ? true : false;
    };
    InterceptorService.prototype.catchError = function (request, next) {
        var _this = this;
        return function (response) {
            if (response instanceof TimeoutError) {
                response = new HttpResponse({
                    status: 504,
                    url: request.url
                });
            }
            // Images that dont exist shouldn't cause a popup to retry
            if (response && response.url && response.url.indexOf('image') !== -1 && (response.status === 404 ||
                response.status === 500)) {
                return observableThrowError(response);
            }
            // Mapping api requests should not cause a popup
            if (response &&
                response.url &&
                response.url.indexOf('apto-mapping') !== -1 &&
                response.status >= 400) {
                return observableThrowError(response);
            }
            // Attom data api requests should not cause a popup
            if (_this.isFailedResponseFromAttom(response)) {
                return observableThrowError(response);
            }
            // Failed CORS requests should not cause a popup
            if (response && response.status === 0) {
                return observableThrowError(response);
            }
            var model = request.body;
            try {
                var errorHandler = function () {
                    return __awaiter(_this, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    if (!(model && response && response.error.error === 'entity is deleted'))
                                        return [3 /*break*/, 2];
                                    return [4 /*yield*/, this.handleMissingObject()];
                                case 1:
                                    _a.sent();
                                    request = this.deleteIdFromParams(model, request);
                                    return [3 /*break*/, 4];
                                case 2: return [4 /*yield*/, this.handleHttpError(response)];
                                case 3:
                                    _a.sent();
                                    _a.label = 4;
                                case 4: return [2 /*return*/];
                            }
                        });
                    });
                };
                return observableFrom(errorHandler()).pipe(switchMap(function () {
                    return _this.intercept(request, next);
                }));
            }
            catch (ex) {
                return observableThrowError(ex);
            }
        };
    };
    // if we need to delete the id from our request (i.e. the record has been deleted in SF and we need to recreate it)
    // then we need to delete the id from our payload and from the request URI. the request also needs to be a POST.
    InterceptorService.prototype.deleteIdFromParams = function (model, request) {
        if (!model._id) {
            return request;
        }
        request = request.clone({
            method: 'post',
            url: request.url.replace("/" + model._id, '')
        });
        delete model._id;
        return request;
    };
    InterceptorService.prototype.getAlertOptions = function (response) {
        var alertOptions = this.httpErrorMessages[response.status];
        if (!alertOptions) {
            var errorMessage = response.error && response.error.error || response.message;
            if (errorMessage.includes('insufficient access rights')) {
                errorMessage = 'You do not have permisson to perform this action. Contact your system administrator';
            }
            if (errorMessage) {
                alertOptions = {
                    title: 'Server Error',
                    subTitle: errorMessage,
                    buttons: [this.logoutButton, this.retryButton, this.okButton],
                    cssClass: 'http-service-error'
                };
            }
            else {
                alertOptions = this.httpErrorMessages.default;
            }
        }
        alertOptions.enableBackdropDismiss = false;
        return alertOptions;
    };
    InterceptorService.prototype.handleHttpError = function (error) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // Don't log CORS errors or 401s. Users will get a 401 if they try to access a page with an expired or
            // invalid auth token and we already handle that scenario.
            if (error.status !== 0 && error.status !== 401) {
                _this.logHttpError("HTTPService Error: status=" + error.status + " url=" + error.url);
            }
            if (!_this.httpAlert) {
                _this.httpAlert = _this.alertController.create(_this.getAlertOptions(error));
                _this.httpAlert.onDidDismiss(function (data, role) {
                    _this.handleAllHttpErrors(role);
                });
                _this.httpAlert.present();
            }
            _this.httpErrorHandlers.push({
                resolve: resolve,
                reject: reject
            });
        });
    };
    InterceptorService.prototype.handleAllHttpErrors = function (role) {
        if (role === this.logoutButton.role) {
            this.httpErrorHandlers.forEach(function (httpErrorHandler) {
                httpErrorHandler.reject();
            });
            this.logout();
        }
        else if (role === this.retryButton.role) {
            this.httpErrorHandlers.forEach(function (httpErrorHandler) {
                httpErrorHandler.resolve();
            });
        }
        else if (role === this.okButton.role) {
            this.httpErrorHandlers.forEach(function (httpErrorHandler) {
                httpErrorHandler.reject();
            });
        }
        this.httpErrorHandlers = [];
        this.httpAlert = null;
    };
    InterceptorService.prototype.logHttpError = function (errorMessage) {
        if (!this.logsService) {
            this.logsService = this.injector.get(LogsService);
        }
        this.logsService.info(errorMessage);
    };
    InterceptorService.prototype.logout = function () {
        if (!this.authService) {
            this.authService = this.injector.get(AuthService);
        }
        this.authService.logout();
    };
    return InterceptorService;
}());
export { InterceptorService };
