<ion-header class="modal-header call-list-members-modal--header">
    <ion-navbar color="white">
        <ion-title>Select Contacts</ion-title>
        <ion-buttons end>
            <button ion-button
                icon-only
                clear
                color="auxiliary-grey"
                margin-left
                (click)="dismiss()"
                data-automation="modal-dismiss-button">
                <apto-icon icon="close" inline size="4"></apto-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal call-list-members-modal--content">
    <add-call-list-members-card
        [properties]="propertyModels"
        [clearButtonEnabled]="false"
        ></add-call-list-members-card>
</ion-content>