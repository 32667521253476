<form #formContent
      [formGroup]="form"
      class="edit-task-form edit-form">
    <div class="input-group">
        <ion-label stacked>Task to do...</ion-label>
        <task-type-selector formControlName="type"
                            (onChange)="setTaskName()"></task-type-selector>
        <div class="field-error"
             *ngIf="form.controls['type'].dirty &&
                    form.controls['type'].invalid &&
                    form.controls['type'].errors['required']">
            A type is required.
        </div>
    </div>

    <ion-item>
        <ion-label stacked required>Task name</ion-label>
        <ion-input (input)="editTaskName()"
                   formControlName="subject"
                   class="light-text"
                   maxlength="255"></ion-input>
    </ion-item>
    <div class="field-error"
         *ngIf="form.controls['subject'].dirty &&
                form.controls['subject'].invalid &&
                form.controls['subject'].errors['required']">
        A task name is required.
    </div>

    <ion-label stacked>Assigned to</ion-label>
    <ion-auto-complete [dataProvider]="userProvider"
                       [options]="{ placeholder : task.ownerName }"
                       (itemSelected)="setUser($event);"
                       (input)="checkForBlank($event.target.value)"
                       data-automation="edit-task-assign-owner"></ion-auto-complete>

    <ion-item>
        <ion-label stacked class="followup-note--label">Note</ion-label>
        <ion-textarea #description
                      textarea-elastic
                      class="elastic-text"
                      formControlName="description"
                      rows="1"></ion-textarea>
    </ion-item>

    <div class="input-group">
        <ion-label stacked>Set a date from today</ion-label>
        <ion-segment (click)="setQuickDueDate()"
                     mode="ios"
                     color="primary-blue"
                     formControlName="quickDueDateOption">
            <ion-segment-button value="1">1 day</ion-segment-button>
            <ion-segment-button value="3">3 days</ion-segment-button>
            <ion-segment-button value="7">1 week</ion-segment-button>
            <ion-segment-button value="30">1 month</ion-segment-button>
            <ion-segment-button value="90">3 months</ion-segment-button>
            <ion-segment-button value="select" (click)="togglePicker($event)">Custom</ion-segment-button>
        </ion-segment>
    </div>

    <date-field [defaultDate]="this.form.controls.dueDate.value"
                [form]="form"
                field="dueDate"
                label="Date"
                [required]="true"
                [onOpenCalendar]="onOpenCalendar"
                [onSelectDate]="onSelectDate"
                [options]="datepickerOptions"></date-field>
    <div class="field-error"
         *ngIf="form.controls['dueDate'].dirty &&
                form.controls['dueDate'].invalid &&
                (form.controls['dueDate'].errors['required'] || form.controls['dueDate'].errors['syntax'])">
        A future date in MM/DD/YYYY format is required.
    </div>
    <div class="field-warning"
         *ngIf="form.controls['dueDate'].dirty &&
                form.controls['dueDate'].invalid &&
                form.controls['dueDate'].errors['invalidPastTask']">
        Date cannot be in the past.
    </div>

    <ion-label stacked>Critical date</ion-label>
    <label>
        <input type="checkbox"
               formControlName="priority">
    </label>

    <ion-item *ngIf="!allowEditContact">
        <ion-label stacked>Related contact</ion-label>
        <ion-input disabled
                   value="{{ relatedContact && relatedContact.fullName }}"></ion-input>
    </ion-item>
    <ion-label *ngIf="allowEditContact" stacked>
        Related contact
        <add-record
            linkText="Create new"
            objectName="Contact"
            (onRecordCreated)="setRelatedContact($event);">
        </add-record>
    </ion-label>
    <ion-auto-complete #contactSearchbar
                       [hidden]="!allowEditContact"
                       [dataProvider]="contactProvider"
                       [options]="{ placeholder : 'Find contact' }"
                       (itemSelected)="setRelatedContact($event);"
                       (itemsShown)="scrollToBottom();"
                       (input)="checkForBlank($event.target.value)"></ion-auto-complete>

    <div [ngClass]="{ hidden: !featuresService.isFeatureEnabled(featuresService.FEATURES.DEALS) }">
        <ion-label stacked>Related to</ion-label>
        <global-search #globalSearch
                       id="edit-task-form--related-deal"
                       placeholder="Quickly find deals"
                       (itemSelected)="setRelatedObject($event)"
                       (selectionCancelled)="clearRelatedObject()"
                       [searchObjects]="['deals']"
                       [showAllResultsLink]="false"
                       [showListOnClick]="false">
        </global-search>
    </div>

    <busy-panel *ngIf="isSaving"
                message="Saving task..."></busy-panel>
</form>
