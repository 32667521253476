import { __extends } from "tslib";
import { EventEmitter } from '@angular/core';
import { TaskModel } from '@apto/models';
import { SelectableItem } from '../selectable-item/selectable-item';
import './task-item.scss';
var TaskItem = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(TaskItem, _super);
    function TaskItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.hideTaskCompletion = false;
        _this.hideContactName = false;
        _this.fullDescription = false;
        _this.showSnooze = false;
        _this.removeable = false;
        _this.removeButtonText = 'REMOVE';
        _this.showSubInfo = false;
        _this.onClick = new EventEmitter();
        _this.onRemoveTask = new EventEmitter();
        _this.onSnoozeTask = new EventEmitter();
        _this.onToggleTask = new EventEmitter();
        return _this;
    }
    Object.defineProperty(TaskItem.prototype, "isCriticalDate", {
        get: function () {
            return this.task.priority === TaskModel.PRIORITY.HIGH;
        },
        enumerable: true,
        configurable: true
    });
    TaskItem.prototype.getSubInfo = function () {
        var result = [];
        if (this.task.type) {
            result.push("Type: " + this.task.type);
        }
        if (this.task.callResult && this.task.type === TaskModel.TYPE.CALL) {
            result.push("Result: " + this.task.callResult);
        }
        return result.join(', ');
    };
    TaskItem.prototype.snoozeTask = function (event, task) {
        event.stopPropagation();
        this.onSnoozeTask.emit(task);
    };
    TaskItem.prototype.toggleTaskComplete = function (event, task) {
        event.stopPropagation();
        this.onToggleTask.emit(task);
    };
    TaskItem.prototype.removeTask = function (event, task) {
        event.stopPropagation();
        this.onRemoveTask.emit(task);
    };
    return TaskItem;
}(SelectableItem));
export { TaskItem };
