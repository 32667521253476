import { NumberWithCommas } from '@apto/ionic-lib/pipes';
import * as moment from 'moment';
import './previous-lease-item.scss';
import { getLeaseDateStatus, getRentalRate } from '@apto/pn/services/leasing-data/lease-expiration-helper';
var PreviousLeaseItem = /** @class */ /*@__PURE__*/ (function () {
    function PreviousLeaseItem() {
    }
    Object.defineProperty(PreviousLeaseItem.prototype, "cost", {
        get: function () {
            return getRentalRate(this.lease.comp || null);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreviousLeaseItem.prototype, "leaseDuration", {
        get: function () {
            if (!this.lease.comp.leaseExpirationDate) {
                return null;
            }
            var expirationYear = Number(moment(this.lease.comp.leaseExpirationDate).format('YYYY'));
            var commencementYear = Number(moment(this.lease.comp.leaseCommencementDate).format('YYYY'));
            return Math.ceil(expirationYear - commencementYear);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreviousLeaseItem.prototype, "leaseExpiration", {
        get: function () {
            if (this.lease.comp && this.lease.comp.leaseExpirationDate) {
                return moment(this.lease.comp.leaseExpirationDate).format('M/D/YYYY');
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreviousLeaseItem.prototype, "leaseMarkerClass", {
        get: function () {
            if (this.lease.comp && this.lease.comp.leaseExpirationDate) {
                var status_1 = getLeaseDateStatus(this.lease.comp);
                if (status_1 === 'danger') {
                    return 'previous-lease-item--marker--lease-danger';
                }
                if (status_1 === 'warning') {
                    return 'previous-lease-item--marker--lease-warning';
                }
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreviousLeaseItem.prototype, "leaseExpirationClass", {
        get: function () {
            if (this.lease.comp && this.lease.comp.leaseExpirationDate) {
                var cssClass = 'previous-lease-item--expiration-date';
                if (getLeaseDateStatus(this.lease.comp) === 'danger') {
                    cssClass += ' previous-lease-item--danger';
                }
                else if (getLeaseDateStatus(this.lease.comp) === 'warning') {
                    cssClass += ' previous-lease-item--warning';
                }
                return cssClass;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreviousLeaseItem.prototype, "leaseRange", {
        get: function () {
            var dates = [];
            if (this.lease.comp.leaseCommencementDate) {
                dates.push(moment(this.lease.comp.leaseCommencementDate).format('MM/DD/YYYY'));
            }
            if (this.lease.comp.leaseExpirationDate) {
                dates.push(moment(this.lease.comp.leaseExpirationDate).format('MM/DD/YYYY'));
            }
            return dates.join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreviousLeaseItem.prototype, "companyName", {
        get: function () {
            if (!this.lease.company || !this.lease.company.name) {
                return null;
            }
            return this.lease.company.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreviousLeaseItem.prototype, "contactName", {
        get: function () {
            if (!this.lease.contact || !this.lease.contact.fullName) {
                return null;
            }
            return this.lease.contact.fullName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PreviousLeaseItem.prototype, "squareFootage", {
        get: function () {
            if (!this.lease.comp.squareFootage) {
                return null;
            }
            var numberWithCommasPipe = new NumberWithCommas();
            return numberWithCommasPipe.transform(this.lease.comp.squareFootage);
        },
        enumerable: true,
        configurable: true
    });
    return PreviousLeaseItem;
}());
export { PreviousLeaseItem };
