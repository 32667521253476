var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef } from '@angular/core';
import { AlertController } from 'ionic-angular';
import { AnalyticsService, DocumentsService } from '@apto/services';
import './documents-card.scss';
var DocumentsCard = /** @class */ /*@__PURE__*/ (function () {
    function DocumentsCard(alertController, analyticsService, documentsService) {
        this.alertController = alertController;
        this.analyticsService = analyticsService;
        this.documentsService = documentsService;
        this.pipelineObject = {};
        this.documents = [];
        this.initializingMessage = 'Loading documents...';
    }
    DocumentsCard.prototype.ngOnInit = function () {
        var _this = this;
        this.documentsService.onDisplayError.subscribe(function (message) {
            _this.alertController.create({
                message: message,
                buttons: ['OK']
            }).present();
        });
        this.getDocuments();
    };
    DocumentsCard.prototype.clickFileField = function () {
        this.fileUploadField.nativeElement.click();
    };
    DocumentsCard.prototype.delete = function (document) {
        return __awaiter(this, void 0, void 0, function () {
            var id, index;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.initializingMessage = 'Deleting document...';
                        id = document.deleteId ? document.deleteId : document._id;
                        return [4 /*yield*/, this.documentsService.delete([id])];
                    case 1:
                        _a.sent();
                        index = this.documents.findIndex(function (d) { return d._id === document._id; });
                        this.documents.splice(index, 1);
                        this.initializingMessage = null;
                        this.analyticsService.logEvent('Documents Card - Delete Document');
                        return [2 /*return*/];
                }
            });
        });
    };
    DocumentsCard.prototype.download = function (document) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.documentsService.download(document)];
                    case 1:
                        _a.sent();
                        this.analyticsService.logEvent('Documents Card - Download Document');
                        return [2 /*return*/];
                }
            });
        });
    };
    DocumentsCard.prototype.onFileUploadFieldChanged = function (fileInput) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!fileInput.target.files || !fileInput.target.files[0]) {
                            return [2 /*return*/];
                        }
                        this.initializingMessage = 'Uploading document...';
                        return [4 /*yield*/, this.documentsService.uploadFiles(this.pipelineObject._id, fileInput.target.files)];
                    case 1:
                        _a.sent();
                        this.getDocuments();
                        this.analyticsService.logEvent('Documents Card - Upload Document');
                        return [2 /*return*/];
                }
            });
        });
    };
    DocumentsCard.prototype.getDocuments = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.pipelineObject || !this.pipelineObject._id) {
                            this.initializingMessage = null;
                            return [2 /*return*/];
                        }
                        this.initializingMessage = 'Loading documents...';
                        _a = this;
                        return [4 /*yield*/, this.documentsService.fetch({
                                where: {
                                    parentId: this.pipelineObject._id
                                }
                            })];
                    case 1:
                        _a.documents = _b.sent();
                        this.initializingMessage = null;
                        return [2 /*return*/];
                }
            });
        });
    };
    return DocumentsCard;
}());
export { DocumentsCard };
