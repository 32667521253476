import { EventEmitter } from '@angular/core';
import { TaskModel, ContactModel } from '@apto/models';
import { UsersService } from '@apto/services';
import { EditTaskForm } from '@apto/shared/components/edit-task-form/edit-task-form';
var AnimationTimeMs = 150; // tslint:disable-line
import './add-followup.scss';
var AddFollowup = /** @class */ /*@__PURE__*/ (function () {
    function AddFollowup(usersService) {
        this.usersService = usersService;
        this.hasNext = true;
        this.onSkip = new EventEmitter();
        this.onSave = new EventEmitter();
        this.animationState = 'open';
        this.shouldShow = false;
        this.TaskModel = TaskModel; // tslint:disable-line
        this.isSaving = false;
        this.hasErrors = false;
    }
    AddFollowup.prototype.show = function () {
        this.shouldShow = true;
        this.task = new TaskModel({
            contact: this.relatedContact,
            contactId: this.relatedContact._id
        });
    };
    AddFollowup.prototype.hide = function () {
        var _this = this;
        this.animationState = 'close';
        setTimeout(function () {
            _this.animationState = 'open';
            _this.shouldShow = false;
        }, AnimationTimeMs);
    };
    AddFollowup.prototype.skip = function () {
        this.onSkip.emit();
    };
    AddFollowup.prototype.save = function () {
        var formResult = this.editTaskForm.save();
        if (formResult && formResult.task) {
            this.editTaskForm.isSaving = true;
            this.onSave.emit(formResult.task);
        }
    };
    return AddFollowup;
}());
export { AddFollowup };
