import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Injector } from '@angular/core';
import { ContactModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
import { CompaniesService } from '../companies/companies-service';
import { AlertController } from 'ionic-angular';
var ContactsService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ContactsService, _super);
    function ContactsService(injector, companiesService, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.companiesService = companiesService;
        _this.alertController = alertController;
        _this.labelAttribute = 'fullName'; // used by auto-complete services
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.CONTACTS;
        _this.model = ContactModel;
        _this.serviceName = SERVICE_NAMES.CONTACTS;
        _this.attachCompanies = function (contacts) {
            var companyIds = contacts.map(function (contact) {
                return contact.companyId;
            });
            var uniqueCompanyIds = Array.from(new Set(companyIds));
            return _this.companiesService.in(uniqueCompanyIds)
                .then(function (companies) {
                contacts.forEach(function (contact) {
                    contact.company = companies.find(function (company) { return contact.companyId === company._id; });
                });
                return contacts;
            });
        };
        return _this;
    }
    ContactsService.prototype.recent = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var contacts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.recent.call(this, params)];
                    case 1:
                        contacts = _a.sent();
                        return [2 /*return*/, this.attachCompanies(contacts)];
                }
            });
        });
    };
    ContactsService.prototype.fetch = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var contacts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, _super.prototype.fetch.call(this, params)];
                    case 1:
                        contacts = _a.sent();
                        return [2 /*return*/, this.attachCompanies(contacts)];
                }
            });
        });
    };
    ContactsService.prototype.byCompanyID = function (companyId) {
        var _this = this;
        var areContactsCached = this.cacheService.get(this.serviceName, companyId);
        if (areContactsCached) {
            var contacts = this.cacheService.getAll(this.serviceName).filter(function (contact) { return contact.companyId === companyId; });
            return this.attachCompanies(contacts);
        }
        var params = {
            where: { companyId: companyId }
        };
        return this.fetch(params)
            .then(function (contacts) {
            _this.cacheService.add(_this.serviceName, companyId, true); // flag that we have already queried all contacts for this company
            return contacts;
        });
    };
    ContactsService.prototype.byCompanyIDs = function (companyIds) {
        return __awaiter(this, void 0, void 0, function () {
            var cacheKey, areContactsCached, params, contacts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        cacheKey = companyIds.join(',');
                        areContactsCached = this.cacheService.get(this.serviceName, cacheKey);
                        if (areContactsCached) {
                            return [2 /*return*/, this.cacheService.getAll(this.serviceName).filter(function (contact) {
                                    return companyIds.includes(contact.companyId);
                                })];
                        }
                        params = {
                            where: {
                                companyId: {
                                    $in: companyIds
                                }
                            },
                            omitDeleted: true
                        };
                        return [4 /*yield*/, this.fetch(params)];
                    case 1:
                        contacts = _a.sent();
                        this.cacheService.add(this.serviceName, cacheKey, true);
                        return [2 /*return*/, contacts];
                }
            });
        });
    };
    return ContactsService;
}(BaseService));
export { ContactsService };
