import { PipeTransform } from '@angular/core';
var FormattedTextPipe = /** @class */ /*@__PURE__*/ (function () {
    function FormattedTextPipe() {
    }
    FormattedTextPipe.prototype.transform = function (txt) {
        if (txt) {
            return txt.replace(/(\r\n|\n|\r)/g, '<br>');
        }
        return txt;
    };
    return FormattedTextPipe;
}());
export { FormattedTextPipe };
