import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NavParams, ToastController, ViewController } from 'ionic-angular';
import { TaskModel } from '@apto/models';
import { AppService, ContactsService, TasksService, UsersService } from '@apto/services';
import { TOAST_DURATION } from '@apto/shared/constants';
import { BaseSlideModal } from '@apto/shared/modals';
import { EditTaskForm } from '@apto/shared/components';
import { Router } from '@angular/router';
export var TasksModalMode = /*@__PURE__*/ (function (TasksModalMode) {
    TasksModalMode[TasksModalMode["LIST"] = 0] = "LIST";
    TasksModalMode[TasksModalMode["VIEW"] = 1] = "VIEW";
    TasksModalMode[TasksModalMode["EDIT"] = 2] = "EDIT";
    return TasksModalMode;
})({});
import './tasks-modal.scss';
var TasksModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(TasksModal, _super);
    function TasksModal(appService, contactsService, router, navParams, tasksService, toastController, viewController, usersService) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.appService = appService;
        _this.contactsService = contactsService;
        _this.router = router;
        _this.tasksService = tasksService;
        _this.toastController = toastController;
        _this.usersService = usersService;
        _this.filter = '';
        _this.fullDescription = true;
        _this.isInitializing = false;
        _this.Modes = TasksModalMode;
        _this.records = [];
        _this.selectedRecord = new TaskModel();
        _this.taskIsToggling = {};
        _this.modalUrls = new Map([
            [TasksModalMode.VIEW, '/:taskId']
        ]);
        _this.mode = navParams.get('mode') || TasksModalMode.LIST;
        _this.records = navParams.get('tasks') || [];
        _this.relatedListing = _this.navParams.get('relatedListing');
        _this.title = navParams.get('title') || 'Open Tasks';
        _this.selectedRecord = _this.navParams.get('selectedTask') || new TaskModel();
        _this.whatId = _this.navParams.get('whatId');
        if (_this.whatId) {
            _this.loadTasks();
        }
        return _this;
    }
    TasksModal.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var formResult, savedTask, newTask_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formResult = this.editTaskForm.save();
                        if (!(formResult && formResult.task))
                            return [3 /*break*/, 2];
                        savedTask = void 0;
                        newTask_1 = formResult.task;
                        this.editTaskForm.isSaving = true;
                        this.showToast(newTask_1);
                        return [4 /*yield*/, this.tasksService.upsert(newTask_1)];
                    case 1:
                        savedTask = _a.sent();
                        if (!savedTask) {
                            this.records = this.records.filter(function (record) { return record._id !== newTask_1._id; });
                            if (!this.records.length) {
                                // user editing a record in SF and chose to not recreate it, and there are no other tasks left in the list
                                return [2 /*return*/, this.dismiss()];
                            }
                        }
                        if (savedTask && !this.selectedRecord._id) {
                            this.selectedRecord = savedTask;
                        }
                        this.editTaskForm.isSaving = false;
                        if (this.initialMode === this.Modes.EDIT) {
                            this.dismiss();
                        }
                        else {
                            this.view(savedTask);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    TasksModal.prototype.getIcon = function (task) {
        return this.showAsComplete(task) ? 'checkmark-circle' : 'ios-radio-button-off';
    };
    TasksModal.prototype.getColor = function (task) {
        return this.showAsComplete(task) ? 'success' : '';
    };
    TasksModal.prototype.showAsComplete = function (task) {
        return (task.isComplete && !this.taskIsToggling[task._id])
            || (!task.isComplete && this.taskIsToggling[task._id]);
    };
    TasksModal.prototype.toggleTask = function (task) {
        if (this.taskIsToggling[task._id]) {
            return;
        }
        this.showToast(task);
        this.taskIsToggling[task._id] = true;
        // Give time to show the green checkmark before dissapearing
        setTimeout(this.upsertToggledTask.bind(this), 500, task);
    };
    TasksModal.prototype.viewContact = function (_a) {
        var _id = _a._id;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.dismiss({ dismissAll: true })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.router.navigate(['/contacts', _id])];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    TasksModal.prototype.delete = function () {
        this.tasksService.delete([this.selectedRecord._id]);
        this.dismiss();
    };
    TasksModal.prototype.showToast = function (task) {
        var toast = this.toastController.create({
            duration: TOAST_DURATION,
            message: task._id ? 'Updating Task' : 'Saving Task',
            showCloseButton: true
        });
        toast.present();
    };
    TasksModal.prototype.upsertToggledTask = function (task) {
        return __awaiter(this, void 0, void 0, function () {
            var savedTask, ex_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        task.isComplete = !task.isComplete;
                        delete this.taskIsToggling[task._id];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.tasksService.upsert(task)];
                    case 2:
                        savedTask = _a.sent();
                        if (savedTask) {
                            TaskModel.sortActivities(this.records);
                        }
                        else {
                            task.isComplete = !task.isComplete;
                            this.records = this.records.filter(function (record) { return record._id !== task._id; });
                            if (!this.records.length) {
                                return [2 /*return*/, this.dismiss()];
                            }
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        task.isComplete = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    TasksModal.prototype.loadTasks = function () {
        var _this = this;
        this.isInitializing = true;
        this.tasksService.byRelatedId(this.whatId).then(function (tasks) {
            _this.records = tasks;
            _this.isInitializing = false;
        });
    };
    return TasksModal;
}(BaseSlideModal));
export { TasksModal };
