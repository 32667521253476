import { EventEmitter } from '@angular/core';
import './company-related-contacts-card.scss';
var CompanyRelatedContactsCard = /** @class */ /*@__PURE__*/ (function () {
    function CompanyRelatedContactsCard() {
        this.onClick = new EventEmitter();
        this.onManageContactsClicked = new EventEmitter();
    }
    CompanyRelatedContactsCard.prototype.call = function (phoneNumber) {
        window.location.assign(phoneNumber.actionLink);
    };
    CompanyRelatedContactsCard.prototype.email = function (email) {
        window.location.assign(email.actionLink);
    };
    return CompanyRelatedContactsCard;
}());
export { CompanyRelatedContactsCard };
