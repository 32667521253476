<div class="filter-panel--option date-range-filter--component">
    <ion-label stacked>{{ label }}</ion-label>

    <date-field class="two-column-input"
                [form]="form"
                field="startDate"
                placeholder="Start date"
                [onClearCalendar]="onClearCalendar"
                [options]="startDateOptions"></date-field>
    <date-field class="two-column-input"
                [form]="form"
                field="stopDate"
                placeholder="End date"
                [onClearCalendar]="onClearCalendar"
                [options]="stopDateOptions"></date-field>

    <div class="error" *ngIf="form.controls.startDate.errors">Start date must be in MM/DD/YYYY format.</div>
    <div class="error" *ngIf="form.controls.stopDate.errors">End date must be in MM/DD/YYYY format.</div>
    <div class="error" *ngIf="form.controls.startDate.value && form.controls.stopDate.value && form.controls.startDate.value > form.controls.stopDate.value">Start date must be before End date.</div>

    <div class="clearfix"></div>
</div>
