<div class="task-item selectable-item clickable"
     [ngClass]="{ clickable: onClick.observers.length > 0 }">
     <ion-checkbox class="small"
                   *ngIf="selectable"
                   [ngClass]="{ checked: selected }"
                   [ngModel]="selected"
                   (ngModelChange)="itemToggled($event)"
                   color="primary-blue"
                   mode="ios">
    </ion-checkbox>
    <ion-item [attr.detail-push]="onClick.observers.length > 0 ? true : null"
              (click)="onClick.emit(task)">
        <ion-icon *ngIf="task.isComplete"
                  name="checkmark-circle"
                  color="success"
                  item-left
                  class="task-item--complete-status"
                  (click)="toggleTaskComplete($event, task)"></ion-icon>
        <ion-icon *ngIf="!task.isComplete && !hideTaskCompletion"
                  item-left
                  class="task-item--open-status"
                  (click)="toggleTaskComplete($event, task)"></ion-icon>

        <h2 class="task-item--title">
            {{task.subject}}
            <span class="task-item--contact-name"
                  *ngIf="!hideContactName && task.contact">• {{task.contact.fullName}}</span>
        </h2>
        <div class="task-item--description"
            [ngClass]="{'task-item--full-description': fullDescription}"
            *ngIf="task.description"
            [innerHTML]="task.description | formattedText"></div>
        <p *ngIf="task.isComplete"
           [ngClass]="{'task-item--overdue': task.isOverdue}">
            Completed on: {{task.completedDate | dateFormat}}
        </p>
        <p *ngIf="task.isComplete && task.dueDate"
           class="task-item--bullet">
            •
        </p>
        <p *ngIf="task.dueDate" [ngClass]="{'task-item--overdue': task.isOverdue}">
            <ion-badge *ngIf="isCriticalDate">Critical date</ion-badge>
            <span *ngIf="isCriticalDate">•</span>
            Due Date: {{task.dueDate | dateFormat}}
            <span *ngIf="!task.isComplete && task.isOverdue"> - Overdue</span>
        </p>
        <p *ngIf="!task.dueDate && !task.isComplete"
           class="task--no-data">
            No due date specified
        </p>

        <div>
            <p
                *ngIf="showSubInfo"
                class="activity-item--sub">
                {{ getSubInfo() }}
            </p>
        </div>

        <button *ngIf="showSnooze && !task.isComplete && task.dueDate"
                class="task-item--snooze-button"
                ion-button
                clear
                icon-left
                item-right
                large
                (click)="snoozeTask($event, task)">
            <ion-icon name="time"></ion-icon>
            <span>SNOOZE 1 DAY</span>
        </button>

        <button *ngIf="removeable"
                class="task-item--remove-button"
                color="danger"
                ion-button
                clear
                icon-left
                item-right
                (click)="removeTask($event, task)">
            <span>{{ removeButtonText }}</span>
        </button>
    </ion-item>
</div>
