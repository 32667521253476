import { AptoIconRegistry } from 'apto-ui';
import { DomSanitizer } from '@angular/platform-browser';
/*
    a centralized module to load in used AptoUI components in one place
*/
var AptoUIModule = /** @class */ /*@__PURE__*/ (function () {
    function AptoUIModule(iconRegistry, sanitizer) {
        if (process.env.NODE_ENV === 'test') {
            // don't load icons in test and supress "icon not found" errors cause we don't care in tests
            iconRegistry.setErrorSupression(true);
        }
        else {
            // load apto ui icon sprite
            // icon path is set in config/webpack.common.js
            iconRegistry.addSvgIconSet(sanitizer.bypassSecurityTrustResourceUrl('/icon/apto-icon-sprite.svg'));
        }
    }
    return AptoUIModule;
}());
export { AptoUIModule };
