import { Injector } from '@angular/core';
import { Platform } from 'ionic-angular';
import { ConfigService } from '@apto/services';
import { LOG_LEVELS } from '@apto/services/constants';
import { AuthService, ConsoleHandler, LogentriesHandler, LogsService } from './services';
import './themes/apto.build.default';
import './themes/apto.cards';
import './themes/apto.components';
export function loggerFactory(authService, configService, injector, platform) {
    var logger = new LogsService(injector);
    logger.level = LOG_LEVELS[configService.aptoLogLevel];
    logger.addHandler('console', new ConsoleHandler());
    if (configService.logentriesToken) {
        logger.addHandler('logentries', new LogentriesHandler(authService, platform, configService.logentriesToken));
    }
    return logger;
}
var AptoIonicLibModule = /** @class */ /*@__PURE__*/ (function () {
    function AptoIonicLibModule() {
    }
    return AptoIonicLibModule;
}());
export { AptoIonicLibModule };
