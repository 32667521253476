<div class="manage-related-ownership">
    <span *ngFor="let ownership of updatedOwnerships">
        <ion-item *ngIf="ownership && ownership.contact">
            <div class="manage-related-ownership--ownership-item">
                <ion-icon class="grey-color-circle"
                          material-icons
                          name="account_circle"
                          large>
                </ion-icon>
                <div class="manage-related-ownership--ownership-details">
                    <p>
                        <span class="dark-text">
                            {{ownership.contact.fullName}}
                        </span>
                        <span class="link manage-related-ownership--contact-role"
                              (click)="changeOwnerStatus($event, ownership);">
                            <span *ngIf="ownership.contactRole ==='Owner' && ownership.isPrimaryContact; else ownershipRole">Primary Owner</span>
                            <ng-template #ownershipRole><span>{{ownership.contactRole || '-- No status selected --'}}</span></ng-template>
                        </span>
                    </p>
                        <p *ngIf="ownership.contact.company">{{ownership.contact.company.name}}</p>
                        <p>{{ownership.contact.title}}</p>
                </div>
                <button *ngIf="allowEdit"
                        ion-button
                        item-right
                        icon-only
                        clear
                        (click)="removeOwnership(ownership, 'contact');">
                    <ion-icon class="close-circle"
                              circle
                              name="close"></ion-icon>
                </button>
            </div>
        </ion-item>
    </span>
    <ion-auto-complete #contactAutocomplete
                       *ngIf="allowEdit"
                       [dataProvider]="contactsService"
                       [options]="{ placeholder : 'Add contact' }"
                       (itemSelected)="addContactOwnership($event);"></ion-auto-complete>
    <span *ngFor="let ownership of updatedOwnerships">
        <ion-item *ngIf="ownership && ownership.company && !ownership.contact">
            <div class="manage-related-ownership--ownership-item">
                <ion-icon class="grey-circle"
                          circle
                          material-icons
                          name="location_city">
                </ion-icon>
                <div class="manage-related-ownership--ownership-details">
                    <p>
                        <span class="dark-text">
                            {{ownership.company.name}}
                        </span>
                        <span class="link manage-related-ownership--contact-role"
                              (click)="changeOwnerStatus($event, ownership);">
                            <span>{{ownership.contactRole}}</span>
                            <ng-template #ownershipRole><span>{{ownership.contactRole || '-- No status selected --'}}</span></ng-template>
                        </span>
                    </p>
                    <p>Company</p>
                </div>
                <button *ngIf="allowEdit"
                        ion-button
                        icon-only
                        clear
                        (click)="removeOwnership(ownership, 'company')">
                    <ion-icon class="close-circle"
                              circle
                              name="close"></ion-icon>
                </button>
            </div>
        </ion-item>
    </span>
    <ion-auto-complete #companyAutocomplete
                       *ngIf="allowEdit"
                       [dataProvider]="companiesService"
                       [options]="{ placeholder : 'Add company' }"
                       (itemSelected)="addCompanyOwnership($event);"></ion-auto-complete>
</div>
