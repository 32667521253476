<ion-card class="rescour-news-card">
    <ion-card-header>
        Recent News
    </ion-card-header>
    <ion-card-content *ngIf="!isInitializing" no-padding>
        <ion-list>
            <ion-item
                *ngFor="let article of shownNews"
                class="rescour-news-card--article"
                [ngClass]="{ hovered: hoveredNews && hoveredNews.id === article.id, viewed: viewedArticles.has(article.id) }"
                (mouseover)="isHovered(article, true)"
                (mouseout)="isHovered(article, false)"
                (click)="clickedNewsArticle(article)">
                    <ion-icon class="rescour-news-card--article--icon" item-left></ion-icon>
                    <h2 [innerHTML]="article.title"></h2>
                    <p>
                        {{ article.source }} &bull; {{ article.postedDate | date:"MM/dd/yy" }}<br>
                        <span [innerHTML]="article.summary"></span>
                    </p>
            </ion-item>
            <ion-item *ngIf="!shownNews.length" class="empty-card">
                <ion-icon
                    name="close"
                    circle
                    small
                    color="white"
                    item-left>
                </ion-icon>
                <ion-label>No recent news found</ion-label>
            </ion-item>
        </ion-list>
        <ion-row class="card-footer-buttons">
            <ion-col *ngIf="news.length > 3">
                <button
                    ion-button
                    clear
                    (click)="toggleVisible();"
                    data-automation="toggle-visible-news">
                    {{ news.length === shownNews.length ? 'ONLY VIEW 3' : 'VIEW ALL' }}
                    <span *ngIf="news.length > shownNews.length" class="rescour-news-card--count">{{ news.length }}</span>
                </button>
            </ion-col>
            <ion-col text-right>
                <img class="rescour-news-card--rescour-logo" src="/images/rescour-logo.png" alt="Rescour">
            </ion-col>
        </ion-row>
    </ion-card-content>
    <ion-card-content *ngIf="isInitializing">
        <busy-panel message="Loading recent news"></busy-panel>
    </ion-card-content>
</ion-card>
