import { ElementRef, EventEmitter, SimpleChanges } from '@angular/core';
import { TextInput, PopoverController } from 'ionic-angular';
import { CallListModel, ContactModel, DesktopPreferencesModel, TaskModel } from '@apto/models';
import { AnalyticsService } from '@apto/services';
import { SelectPopover } from '@apto/ionic-lib/components';
import './log-activity-panel.scss';
var LogActivityPanel = /** @class */ /*@__PURE__*/ (function () {
    function LogActivityPanel(popoverController, analyticsService) {
        this.popoverController = popoverController;
        this.analyticsService = analyticsService;
        this.onRemove = new EventEmitter();
        this.onSaveTask = new EventEmitter();
        this.onSkip = new EventEmitter();
        this.activityNote = '';
        this.showCallResultValidationMessage = false;
        this.TaskModel = TaskModel; // tslint:disable-line
    }
    LogActivityPanel.prototype.ngOnInit = function () {
        if (!this.preferences) {
            this.preferences = new DesktopPreferencesModel();
        }
    };
    LogActivityPanel.prototype.ngOnChanges = function (changes) {
        if (changes.contact) {
            this.activityNote = '';
        }
    };
    LogActivityPanel.prototype.saveTask = function () {
        if (this.result) {
            var task = TaskModel.newLoggedActivity(this.contact);
            task.description = this.activityNote;
            if (this.result === TaskModel.RESULT.REACHED ||
                this.result === TaskModel.RESULT.NOT_REACHED ||
                this.result === TaskModel.RESULT.LEFT_MESSAGE) {
                task.type = TaskModel.TYPE.CALL;
                task.callResult = this.result;
            }
            else if (this.result === TaskModel.TYPE.EMAIL) {
                task.type = TaskModel.TYPE.EMAIL;
            }
            else if (this.result === TaskModel.TYPE.MEETING) {
                task.type = TaskModel.TYPE.MEETING;
            }
            else if (this.result === TaskModel.TYPE.TOUR) {
                task.type = TaskModel.TYPE.TOUR;
            }
            else {
                task.type = TaskModel.TYPE.OTHER;
            }
            task.subject = task.type;
            this.onSaveTask.emit({
                addFollowUp: this.preferences.configJson.addFollowUp,
                openTasks: this.preferences.configJson.openTasks,
                task: task
            });
            this.resetForm();
        }
        else {
            this.showCallResultValidationMessage = true;
        }
    };
    LogActivityPanel.prototype.moreOptions = function (ev) {
        var _this = this;
        var popover = this.popoverController.create(SelectPopover, {
            options: [
                { value: 'Email' },
                { value: 'Meeting' },
                { value: 'Tour' },
                { value: 'Other' }
            ],
            value: this.result
        });
        popover.onDidDismiss(function (value) {
            _this.result = value;
        });
        popover.present({ ev: ev });
    };
    LogActivityPanel.prototype.clickReviewTasks = function () {
        if (this.preferences.configJson.openTasks) {
            this.analyticsService.logEvent('Task Review Unchecked');
        }
        else {
            this.analyticsService.logEvent('Task Review Checked');
        }
    };
    LogActivityPanel.prototype.clickAddFollowUp = function () {
        if (this.preferences.configJson.addFollowUp) {
            this.analyticsService.logEvent('Add Follow Up Unchecked');
        }
        else {
            this.analyticsService.logEvent('Add Follow Up Checked');
        }
    };
    LogActivityPanel.prototype.removeContact = function () {
        this.onRemove.emit({
            contact: this.contact
        });
    };
    LogActivityPanel.prototype.skipContact = function () {
        this.onSkip.emit({
            contact: this.contact
        });
    };
    LogActivityPanel.prototype.resetForm = function () {
        this.activityNote = '';
        this.result = null;
        this.showCallResultValidationMessage = false;
    };
    return LogActivityPanel;
}());
export { LogActivityPanel };
