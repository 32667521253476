var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit, AfterContentChecked } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController, ModalController } from 'ionic-angular';
import { SelectPopover } from '@apto/ionic-lib/components';
import { SearchService } from '@apto/ionic-lib/services';
import { PropertyModel } from '@apto/models';
import { PropertiesService, TypesService } from '@apto/services';
import { CompanyDetailsModal, CompanyDetailsModalMode, PropertyDetailsModal, PropertyDetailsModalMode } from '@apto/pn';
import { ContactDetailsModal, ContactDetailsModalMode, TasksModal, TasksModalMode } from '@apto/shared/modals';
import { AppcuesService } from '@apto/shared/services';
import './search-results.scss';
var SearchResultsPage = /** @class */ /*@__PURE__*/ (function () {
    function SearchResultsPage(appCuesService, modalController, popoverController, propertiesService, router, searchService, typesService) {
        this.appCuesService = appCuesService;
        this.modalController = modalController;
        this.popoverController = popoverController;
        this.propertiesService = propertiesService;
        this.router = router;
        this.searchService = searchService;
        this.typesService = typesService;
        this.itemSelected = new EventEmitter();
        this.searchResults = {};
        this.searchTerm = '';
        this.totalResults = '';
    }
    SearchResultsPage.prototype.ngOnInit = function () {
        this.appCuesService.start();
    };
    SearchResultsPage.prototype.ngAfterContentChecked = function () {
        var _a = this.searchService.getCurrentResults(), id = _a.id, results = _a.results;
        if (results && id !== this.resultsId) {
            this.searchResults = results.searchResults;
            this.searchTerm = results.searchTerm;
            this.totalResults = results.totalResults;
            if (this.searchResults.deals) {
                this.attachPropertiesToDeals();
            }
            this.resultsId = id;
        }
    };
    SearchResultsPage.prototype.refineSearch = function () {
        this.searchService.setFocusToSearchBar();
    };
    SearchResultsPage.prototype.showOptions = function (ev) {
        var _this = this;
        var popover = this.popoverController.create(SelectPopover, {
            options: [
                { value: 'Contact' },
                { value: 'Company' },
                { value: 'Property' },
                { value: 'Task' }
            ]
        });
        popover.onDidDismiss(function (value) {
            return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                var _a, types, contactDetailsModal, companyModal, types, propertyDetailsModal, tasksModal;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = value;
                            switch (_a) {
                                case 'Contact': return [3 /*break*/, 1];
                                case 'Company': return [3 /*break*/, 3];
                                case 'Property': return [3 /*break*/, 4];
                                case 'Task': return [3 /*break*/, 6];
                            }
                            return [3 /*break*/, 7];
                        case 1: return [4 /*yield*/, this.typesService.fetch()];
                        case 2:
                            types = _b.sent();
                            contactDetailsModal = this.modalController.create(ContactDetailsModal, {
                                mode: ContactDetailsModalMode.EDIT,
                                types: Object.keys(types.contactTypes)
                            }, {
                                cssClass: 'large-modal'
                            });
                            contactDetailsModal.present({ updateUrl: false });
                            contactDetailsModal.onDidDismiss(function (data) {
                                if (!data) {
                                    return;
                                }
                                _this.router.navigate(['/contacts', data._id]);
                            });
                            return [3 /*break*/, 7];
                        case 3:
                            {
                                companyModal = this.modalController.create(CompanyDetailsModal, {
                                    mode: CompanyDetailsModalMode.EDIT,
                                    options: {
                                        isInitializing: false
                                    }
                                }, {
                                    cssClass: 'large-modal'
                                });
                                companyModal.present({ updateUrl: false });
                                companyModal.onDidDismiss(function () {
                                    _this.router.navigate(['/companies']);
                                });
                                return [3 /*break*/, 7];
                            }
                            _b.label = 4;
                        case 4: return [4 /*yield*/, this.typesService.fetch()];
                        case 5:
                            types = _b.sent();
                            propertyDetailsModal = this.modalController.create(PropertyDetailsModal, {
                                selectedProperty: new PropertyModel(),
                                mode: PropertyDetailsModalMode.EDIT,
                                types: Object.keys(types.propertyTypes)
                            }, {
                                cssClass: 'large-modal'
                            });
                            propertyDetailsModal.present({ updateUrl: false });
                            propertyDetailsModal.onDidDismiss(function () {
                                _this.router.navigate(['/property-map']);
                            });
                            return [3 /*break*/, 7];
                        case 6:
                            {
                                tasksModal = this.modalController.create(TasksModal, {
                                    mode: TasksModalMode.EDIT
                                });
                                tasksModal.present({ updateUrl: false });
                                tasksModal.onDidDismiss(function (task) {
                                    if (!task) {
                                        return;
                                    }
                                    _this.router.navigate(['/tasks', task._id]);
                                });
                                return [3 /*break*/, 7];
                            }
                            _b.label = 7;
                        case 7: return [2 /*return*/];
                    }
                });
            });
        });
        popover.present({ ev: ev });
    };
    SearchResultsPage.prototype.attachPropertiesToDeals = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this.searchResults;
                        return [4 /*yield*/, this.propertiesService.attach('propertyId', 'property', this.searchResults.deals)];
                    case 1:
                        _a.deals = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return SearchResultsPage;
}());
export { SearchResultsPage };
