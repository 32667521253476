import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ViewController, ModalController, NavParams, ToastController } from 'ionic-angular';
import { TaskModel } from '@apto/models';
import { AnalyticsService, TasksService, PropertiesService, TypesService } from '@apto/services';
import { ActivityHistoryModal, ActivityHistoryModalMode, CompanyDetailsModal, CompanyDetailsModalMode, PropertyDetailsModal, PropertyDetailsModalMode, SpaceModal, SpaceModalMode } from '@apto/pn/modals';
import { TOAST_DURATION } from '@apto/shared/constants';
import { BaseSlideModal, TasksModal, TasksModalMode } from '@apto/shared/modals';
import './pipeline-slide-modal.scss';
import { Router } from '@angular/router';
var PipelineSlideModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(PipelineSlideModal, _super);
    function PipelineSlideModal(viewController, modalController, navParams, tasksService, propertiesService, toastController, analyticsService, router, typesService) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.modalController = modalController;
        _this.tasksService = tasksService;
        _this.propertiesService = propertiesService;
        _this.toastController = toastController;
        _this.analyticsService = analyticsService;
        _this.router = router;
        _this.typesService = typesService;
        _this.activities = [];
        _this.tasks = [];
        return _this;
    }
    PipelineSlideModal.prototype.getPropertyTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var types;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        types = _a.sent();
                        return [2 /*return*/, Object.keys(types.propertyTypes)];
                }
            });
        });
    };
    PipelineSlideModal.prototype.showAddActivity = function () {
        var activitiesModal = this.modalController.create(ActivityHistoryModal, {
            mode: ActivityHistoryModalMode.EDIT,
            selectedActivity: new TaskModel({
                dueDate: new Date().toISOString(),
                isComplete: true,
                whatId: this.selectedRecord._id,
                whatName: this.selectedRecord.name
            })
        });
        return activitiesModal.present({ updateUrl: false });
    };
    PipelineSlideModal.prototype.showAddTask = function () {
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.EDIT,
            selectedTask: new TaskModel({
                whatId: this.selectedRecord._id,
                whatName: this.selectedRecord.name
            })
        });
        return tasksModal.present({ updateUrl: false });
    };
    PipelineSlideModal.prototype.toggleTask = function (task) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                task.isComplete = !task.isComplete;
                this.showToast(task);
                if (task.isComplete) {
                    this.analyticsService.logEvent('Deals Modal - Task Completed');
                }
                TaskModel.sortActivities(this.tasks);
                return [2 /*return*/, this.tasksService.upsert(task)
                        .catch(function () {
                        task.isComplete = false;
                    })
                        .then(function () {
                        TaskModel.sortActivities(_this.tasks);
                    })];
            });
        });
    };
    PipelineSlideModal.prototype.viewActivity = function (activity) {
        var activityHistoryModal = this.modalController.create(ActivityHistoryModal, {
            mode: ActivityHistoryModalMode.VIEW,
            selectedActivity: activity
        });
        return activityHistoryModal.present({ updateUrl: false });
    };
    PipelineSlideModal.prototype.viewAllActivities = function () {
        var activityHistoryModal = this.modalController.create(ActivityHistoryModal, {
            activities: this.tasks,
            mode: ActivityHistoryModalMode.LIST
        });
        return activityHistoryModal.present({ updateUrl: false });
    };
    PipelineSlideModal.prototype.viewAllTasks = function () {
        var tasksModal = this.modalController.create(TasksModal, {
            tasks: this.tasks,
            mode: TasksModalMode.LIST
        });
        return tasksModal.present({ updateUrl: false });
    };
    PipelineSlideModal.prototype.viewCompany = function (event) {
        var companyModal = this.modalController.create(CompanyDetailsModal, {
            mode: CompanyDetailsModalMode.VIEW,
            selectedCompany: event.company
        });
        return companyModal.present({ updateUrl: false });
    };
    PipelineSlideModal.prototype.viewContact = function (_a) {
        var _id = _a._id;
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, this.dismiss({ dismissAll: true })];
                    case 1:
                        _b.sent();
                        return [4 /*yield*/, this.router.navigate(['/contacts', _id])];
                    case 2:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PipelineSlideModal.prototype.viewProperty = function (property) {
        return __awaiter(this, void 0, void 0, function () {
            var propertyModal, _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        if (property && property.parentPropertyId) {
                            return [2 /*return*/, this.viewSpace(property)];
                        }
                        _b = (_a = this.modalController).create;
                        _c = [PropertyDetailsModal];
                        _d = {
                            mode: PropertyDetailsModalMode.VIEW,
                            selectedProperty: property
                        };
                        return [4 /*yield*/, this.getPropertyTypes()];
                    case 1:
                        propertyModal = _b.apply(_a, _c.concat([(_d.types = _e.sent(),
                                _d)]));
                        return [2 /*return*/, propertyModal.present({ updateUrl: false })];
                }
            });
        });
    };
    PipelineSlideModal.prototype.viewSpace = function (space) {
        return __awaiter(this, void 0, void 0, function () {
            var spaceModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.attachParentProperty(space)];
                    case 1:
                        _a.sent();
                        spaceModal = this.modalController.create(SpaceModal, {
                            selectedRecord: {
                                space: space,
                                parentProperty: space.parentProperty
                            },
                            mode: SpaceModalMode.VIEW,
                            isQuickAdd: false
                        });
                        return [2 /*return*/, spaceModal.present({ updateUrl: false })];
                }
            });
        });
    };
    PipelineSlideModal.prototype.viewTask = function (activity) {
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.VIEW,
            selectedTask: activity
        });
        return tasksModal.present({ updateUrl: false });
    };
    PipelineSlideModal.prototype.addTask = function (task) {
        if (this.tasks.find(function (t) { return t._id === task._id; })) {
            return;
        }
        this.tasks = this.tasks.concat(new TaskModel(task));
        TaskModel.sortActivities(this.tasks);
    };
    PipelineSlideModal.prototype.delTask = function (taskId) {
        this.tasks = this.tasks.filter(function (t) { return t._id !== taskId; });
        TaskModel.sortActivities(this.tasks);
    };
    PipelineSlideModal.prototype.updateTask = function (task) {
        var existingTask = this.tasks.find(function (t) { return t._id === task._id; });
        if (existingTask) {
            Object.assign(existingTask, task);
            TaskModel.sortActivities(this.tasks);
        }
    };
    PipelineSlideModal.prototype.attachParentProperty = function (property) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, ex_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(property && property.parentPropertyId && !property.parentProperty))
                            return [3 /*break*/, 4];
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = property;
                        return [4 /*yield*/, this.propertiesService.byId(property.parentPropertyId)];
                    case 2:
                        _a.parentProperty = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _b.sent();
                        property.parentProperty = null;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, Promise.resolve()];
                }
            });
        });
    };
    PipelineSlideModal.prototype.showToast = function (task) {
        var toast = this.toastController.create({
            duration: TOAST_DURATION,
            message: task.isComplete ? 'Task completed.' : 'Task opened.',
            showCloseButton: true
        });
        toast.present();
    };
    return PipelineSlideModal;
}(BaseSlideModal));
export { PipelineSlideModal };
