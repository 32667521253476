import { LOG_LEVELS } from '@apto/services/constants';
import { Stacktrace } from './stacktrace';
var ConsoleHandler = /** @class */ /*@__PURE__*/ (function () {
    function ConsoleHandler() {
    }
    ConsoleHandler.prototype.log = function (level, message, context) {
        var datetime = this.getDate();
        var stringLevel = LOG_LEVELS[level];
        console.log(datetime + " " + stringLevel + " " + message);
        if (level === LOG_LEVELS.EXCEPTION && context) {
            var trace = Stacktrace.extract(context);
            console.error(message + "\n" + trace);
        }
    };
    ConsoleHandler.prototype.getDate = function () {
        return new Date().toISOString();
    };
    return ConsoleHandler;
}());
export { ConsoleHandler };
