<ion-header class="modal-header contact-details-modal--header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton()"
                    data-automation="modalBackButton">
                <apto-icon icon="arrowBack" inline size="4"></apto-icon>
            </button>
        </ion-buttons>

        <ion-title *ngIf="mode === Modes.LIST">Contacts</ion-title>
        <ion-title *ngIf="mode === Modes.VIEW">Contact Details</ion-title>
        <ion-title *ngIf="mode === Modes.EDIT">{{ selectedRecord._id ? 'Edit' : 'Add' }} Contact</ion-title>
        <ion-title *ngIf="mode === Modes.VIEWLINKS">Contact relationships</ion-title>

        <ion-buttons end>
            <button *ngIf="mode === Modes.VIEW"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="setMode(Modes.EDIT)"
                    [disabled]="isLoadingCallLists || isLoadingProperties || isLoadingTasks"
                    data-automation="modal-edit-button">
                Edit
            </button>
            <button *ngIf="mode === Modes.EDIT && selectedRecord._id"
                    [disabled]="isLoadingLinkedItems"
                    ion-button
                    clear
                    color="danger"
                    hold-button
                    (hold)="delete()"
                    data-automation="modal-delete-button"
                    icon-end>
                Hold to Delete
                <ion-spinner *ngIf="isLoadingLinkedItems"></ion-spinner>
            </button>
            
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismiss()"
                    data-automation="modal-dismiss-button">
                <apto-icon icon="close" inline size="4"></apto-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal">
    <ion-slides>
        <ion-slide>
            <ion-content>
                <ion-card>
                    <ion-card-content>
                        <ion-list>
                            <contact-item *ngFor="let contact of records"
                                          [contact]="contact"
                                          (onClick)="view(contact)"></contact-item>
                        </ion-list>
                    </ion-card-content>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide>
            <ion-content>
                <ion-card class="contact-details-modal--context-card">
                    <contact-context-panel [contact]="selectedRecord"
                                           [isInitializing]="false"
                                           [activities]="tasks | filterByTaskType:'closed'"></contact-context-panel>
                </ion-card>
                <ion-grid>
                    <ion-row>
                        <ion-col col-6>
                            <contact-portfolio-card [properties]="properties"
                                                    [shownProperties]="properties.slice(0,shownProperties)"
                                                    [isInitializing]="isLoadingProperties">
                            </contact-portfolio-card>
                            <contact-tasks-card [tasks]="tasks | filterByTaskType:'open' | filterByOwnerId: appService.salesforceUserId"
                                                [isInitializing]="isLoadingTasks"
                                                [sliceLength]="3"
                                                (onAddTask)="showAddTask()"
                                                (onTaskCompleted)="toggleTask($event.task)">
                            </contact-tasks-card>
                            <activity-history-card [activities]="tasks | filterByTaskType:'closed'"
                                                   [isInitializing]="isLoadingTasks"
                                                   (onAddActivity)="showAddActivity()">
                            </activity-history-card>
                        </ion-col>
                        <ion-col col-6>
                            <about-contact-card [contact]="selectedRecord"
                                                (onEditContact)="save($event)"></about-contact-card>
                            <current-lists-card [contacts]="[selectedRecord]"
                                                (viewList)="viewCallList($event)"
                                                (removeFromList)="removeFromList($event)"></current-lists-card>
                            <buyer-needs-card [contact]="selectedRecord"></buyer-needs-card>
                        </ion-col>
                    </ion-row>
                </ion-grid>
            </ion-content>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide" *ngIf="mode === Modes.EDIT">
            <ion-content>
                <edit-contact-form [contact]="selectedRecord"
                                   [types]="types"></edit-contact-form>
            </ion-content>
        </ion-slide>
        <ion-slide *ngIf="mode === Modes.VIEWLINKS">
            <ion-content>
                <ion-col col-6 *ngIf="!linkedEntitiesWorkingMessage">
                    <deals-list-card *ngIf="hasLinkedActivities()"
                                     [dealActivity]="linkedDealActivities"
                                     [recordTypes]="recordTypes">
                    </deals-list-card>
                    <contact-portfolio-card *ngIf="hasLinkedProperties()"
                                            [properties]="getLinkedProperties()"
                                            [shownProperties]="properties.slice(0,shownProperties)"
                                            [isInitializing]="isLoadingProperties"
                                            (onViewProperty)="viewProperty($event)">
                    </contact-portfolio-card>
                    <contact-tasks-card *ngIf="hasLinkedTasks()"
                                        [tasks]="linkedTasks"
                                        [isInitializing]="isLoadingTasks"
                                        [sliceLength]="3"
                                        (onClick)="viewTask($event.task)"
                                        (onViewMoreOpenTasks)="viewAllOpenTasks($event)"
                                        [editEnabled]="false">
                    </contact-tasks-card>
                    <call-lists-list-card *ngIf="hasLinkedCallLists()" 
                                        [callLists]="linkedCallLists"
                                        (viewList)="viewCallList($event)">
                    </call-lists-list-card>
                </ion-col>
                <busy-panel *ngIf="linkedEntitiesWorkingMessage"
                    [message]="linkedEntitiesWorkingMessage">
                </busy-panel>
            </ion-content>
        </ion-slide>        
    </ion-slides>
</ion-content>
<ion-footer *ngIf="mode === Modes.VIEWLINKS && !linkedEntitiesWorkingMessage" class="modal-footer">
    <ion-buttons end>
        <button ion-button
                icon-only
                color="primary-blue"
                (click)="deleteWithLinks()">Remove relationship with the contact</button>
    </ion-buttons>
</ion-footer>
