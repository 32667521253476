var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, PopoverController } from 'ionic-angular';
import * as moment from 'moment';
import { CompModel, ContractModel, PursuitModel, TaskModel } from '@apto/models';
import { AnalyticsService, AppService, CallListsService, CompsService, PropertiesService } from '@apto/services';
import { SelectPopover } from '@apto/ionic-lib/components';
import { TasksModal, TasksModalMode } from '@apto/shared/modals/tasks-modal/tasks-modal';
import { ClosedDealDetailsModal } from '@apto/pn/modals/closed-deal-details-modal/closed-deal-details-modal';
import { PursuitDetailsModal } from '@apto/pn/modals/pursuit-details-modal/pursuit-details-modal';
import { NegotiationDetailsModal } from '@apto/pn/modals/negotiation-details-modal/negotiation-details-modal';
import { SourcingDetailsModal } from '@apto/pn/modals/sourcing-details-modal/sourcing-details-modal';
import { ContractDetailsModal } from '@apto/pn/modals/contract-details-modal/contract-details-modal';
export var PipelineTaskStatus = /*@__PURE__*/ (function (PipelineTaskStatus) {
    PipelineTaskStatus[PipelineTaskStatus["OVERDUE"] = 0] = "OVERDUE";
    PipelineTaskStatus[PipelineTaskStatus["DUE_SOON"] = 1] = "DUE_SOON";
    PipelineTaskStatus[PipelineTaskStatus["DEFAULT"] = 2] = "DEFAULT";
    return PipelineTaskStatus;
})({});
import './pipeline-card.scss';
var PipelineCard = /** @class */ /*@__PURE__*/ (function () {
    function PipelineCard(analyticsService, appService, callListsService, compsService, modalController, popoverController, propertiesService, router) {
        this.analyticsService = analyticsService;
        this.appService = appService;
        this.callListsService = callListsService;
        this.compsService = compsService;
        this.modalController = modalController;
        this.popoverController = popoverController;
        this.propertiesService = propertiesService;
        this.router = router;
        this.isLoading = false;
        this.pipelineObject = {};
        this.criticalDates = [];
        this.PipelineTaskStatus = PipelineTaskStatus;
        this.offers = [];
        this.pipelineTaskStatus = PipelineTaskStatus.DEFAULT;
        this.callLists = [];
        this.isDealListLoading = false;
    }
    Object.defineProperty(PipelineCard.prototype, "openPipelineTasks", {
        get: function () {
            return this._openPipelineTasks;
        },
        set: function (value) {
            if (this._openPipelineTasks !== value) {
                this._openPipelineTasks = value;
                this.setPipelineTaskStatus();
                this.criticalDates = this._openPipelineTasks.filter(function (task) {
                    return task.priority === TaskModel.PRIORITY.CRITICAL_DATE;
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PipelineCard.prototype, "title", {
        get: function () {
            if (this.pipelineObject.isExternal) {
                return this.pipelineObject.type;
            }
            if (this.pipelineObject.isSellerRep) {
                return 'Seller Rep';
            }
            else if (this.pipelineObject.isBuyerRep) {
                return 'Buyer Rep';
            }
            else if (this.pipelineObject.isTenantRep) {
                return 'Tenant Rep';
            }
            else if (this.pipelineObject.isLandlordRep) {
                return 'Landlord Rep';
            }
            else {
                return this.pipelineObject.type;
            }
        },
        enumerable: true,
        configurable: true
    });
    PipelineCard.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!!this.pipelineObject.property)
                            return [3 /*break*/, 4];
                        if (!this.pipelineObject.leasedPropertyId)
                            return [3 /*break*/, 2];
                        _a = this.pipelineObject;
                        return [4 /*yield*/, this.propertiesService.byId(this.pipelineObject.leasedPropertyId)];
                    case 1:
                        _a.property = _c.sent();
                        _c.label = 2;
                    case 2:
                        if (!this.pipelineObject.soldPropertyId)
                            return [3 /*break*/, 4];
                        _b = this.pipelineObject;
                        return [4 /*yield*/, this.propertiesService.byId(this.pipelineObject.soldPropertyId)];
                    case 3:
                        _b.property = _c.sent();
                        _c.label = 4;
                    case 4:
                        this.callList = this.callLists.find(function (callList) {
                            return callList.configJson.listingId === _this.pipelineObject._id;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    PipelineCard.prototype.ngOnChanges = function (changes) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.callList && this.callList.members && !this.isDealListLoading))
                            return [3 /*break*/, 2];
                        this.isDealListLoading = true;
                        return [4 /*yield*/, this.callListsService.sortMembersByMarketStatus(this.callList)];
                    case 1:
                        _a.sent();
                        this.isDealListLoading = false;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    PipelineCard.prototype.openTaskTemplateModal = function () {
    };
    PipelineCard.prototype.viewAllTasks = function (event, openTasks) {
        var tasksModal = this.modalController.create(TasksModal, {
            mode: TasksModalMode.LIST,
            tasks: openTasks,
            filter: 'open'
        });
        tasksModal.present({ updateUrl: false });
    };
    PipelineCard.prototype.viewDetails = function () {
        var _this = this;
        this.attachPropertyPhoto(this.pipelineObject.property);
        var modalToOpen = null;
        if (this.pipelineObject instanceof PursuitModel) {
            modalToOpen = PursuitDetailsModal;
        }
        else if (this.pipelineObject.isInNegotiations) {
            modalToOpen = NegotiationDetailsModal;
        }
        else if (this.pipelineObject.isInSourcing) {
            modalToOpen = SourcingDetailsModal;
        }
        else if (this.pipelineObject instanceof ContractModel) {
            modalToOpen = ContractDetailsModal;
        }
        else if (this.pipelineObject instanceof CompModel) {
            modalToOpen = ClosedDealDetailsModal;
        }
        var detailsModal = this.modalController.create(modalToOpen, {
            selectedRecord: this.pipelineObject,
            callList: this.callList
        });
        if (this.pipelineObject.isInSourcing) {
            detailsModal.onDidDismiss(function (data) {
                if (data) {
                    if (data.selectedRecord) {
                        _this.router.navigate(['/contacts'], {
                            queryParams: {
                                listingId: data.selectedRecord._id
                            }
                        });
                    }
                    else if (data.marketingStatus) {
                        var params = data.marketingStatus ? { marketingStatus: data.marketingStatus } : {};
                        _this.router.navigate(['/call-lists', data.callListId, data.contactId], {
                            queryParams: params
                        });
                    }
                }
            });
        }
        detailsModal.present({ updateUrl: false });
    };
    PipelineCard.prototype.viewOffers = function () {
    };
    PipelineCard.prototype.canStartCalling = function () {
        if (!this.callList) {
            return false;
        }
        if (this.callList.contactsNotCalled() && !this.callList.isPending && !this.isDealListLoading) {
            return true;
        }
        return false;
    };
    PipelineCard.prototype.startCalling = function (ev) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var options, popover;
            return __generator(this, function (_a) {
                options = [];
                Object.keys(this.callList.statusToMembers)
                    .filter(function (status) { return status !== 'undefined'; })
                    .forEach(function (status) {
                    var members = _this.callList.statusToMembers[status];
                    var count = members.length;
                    options.push({
                        value: "" + status,
                        displayHtml: "<span>" + status + " (" + count + ")</span>",
                        disabled: !count
                    });
                });
                popover = this.popoverController.create(SelectPopover, { options: options });
                popover.onDidDismiss(function (marketingStatus) {
                    if (!marketingStatus) {
                        return;
                    }
                    _this.navigateToDashboard(marketingStatus);
                });
                popover.present({ ev: ev });
                return [2 /*return*/];
            });
        });
    };
    PipelineCard.prototype.archiveDeal = function (pipelineObject) {
        return __awaiter(this, void 0, void 0, function () {
            var updatedComp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        updatedComp = Object.assign({}, pipelineObject, { archive: true });
                        this.isLoading = true;
                        return [4 /*yield*/, this.compsService.upsert(updatedComp)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PipelineCard.prototype.attachPropertyPhoto = function (property) {
        if (!property || !property.defaultImageAttachmentId || property.blobImageData) {
            return;
        }
        this.propertiesService.attachDefaultPhotos([property], true);
    };
    PipelineCard.prototype.navigateToDashboard = function (marketingStatus) {
        return __awaiter(this, void 0, void 0, function () {
            var event, groupMember, params;
            return __generator(this, function (_a) {
                event = (this.callList.isAptoGenerated) ? this.callList.name + " Start" : 'Call List Start';
                this.analyticsService.logEvent(event, {
                    contactsNotCalled: this.callList.contactsNotCalled(),
                    totalContacts: this.callList.members.length
                });
                groupMember = this.callList.statusToMembers[marketingStatus][0];
                params = marketingStatus ? { marketingStatus: marketingStatus } : {};
                this.router.navigate(['/call-lists', this.callList._id, groupMember.contactId], {
                    queryParams: params
                });
                return [2 /*return*/];
            });
        });
    };
    PipelineCard.prototype.setPipelineTaskStatus = function () {
        this.pipelineTaskStatus = PipelineTaskStatus.DEFAULT;
        for (var _i = 0, _a = this._openPipelineTasks; _i < _a.length; _i++) {
            var task = _a[_i];
            if (task.isComplete || !task.dueDate) {
                continue;
            }
            if (task.isOverdue) {
                this.pipelineTaskStatus = PipelineTaskStatus.OVERDUE;
                break;
            }
            var dueIn = moment(task.dueDate).diff(moment().format('YYYY-MM-DD'), 'days');
            if (dueIn <= PipelineCard.TASK_DUE_SOON_DAYS) {
                this.pipelineTaskStatus = PipelineTaskStatus.DUE_SOON;
            }
        }
    };
    PipelineCard.TASK_DUE_SOON_DAYS = 3;
    return PipelineCard;
}());
export { PipelineCard };
