import * as moment from 'moment';
import { ElementRef, EventEmitter, SimpleChanges } from '@angular/core';
import { TextInput } from 'ionic-angular';
import { AutoCompleteComponent } from '@apto/autocomplete';
import { TaskModel, ContactModel, ListingModel } from '@apto/models';
import { AppService, ContactsService, FeaturesService, TaskFormsService, UsersService, } from '@apto/services';
import { GlobalSearch } from '@apto/shared/components';
import './edit-task-form.scss';
var EditTaskForm = /** @class */ /*@__PURE__*/ (function () {
    function EditTaskForm(contactProvider, featuresService, taskFormsService, userProvider, appService) {
        this.contactProvider = contactProvider;
        this.featuresService = featuresService;
        this.taskFormsService = taskFormsService;
        this.userProvider = userProvider;
        this.appService = appService;
        this.allowEditContact = true;
        this.task = new TaskModel();
        this.isSaving = false;
        this.useDefaultTaskName = true;
        this.onOpenCalendar = new EventEmitter();
        this.onSelectDate = new EventEmitter();
        this.yesterday = moment().subtract(1, 'day');
        this.datepickerOptions = {
            alignSelectorRight: true,
            disableUntil: {
                year: this.yesterday.year(),
                month: this.yesterday.month() + 1,
                day: this.yesterday.date()
            },
            openSelectorTopOfInput: true,
            showSelectorArrow: false
        };
    }
    Object.defineProperty(EditTaskForm.prototype, "relatedContact", {
        get: function () {
            return this._relatedContact;
        },
        set: function (value) {
            if (this._relatedContact !== value) {
                this._relatedContact = value;
                this.updateContactSearchbarValue();
            }
        },
        enumerable: true,
        configurable: true
    });
    EditTaskForm.prototype.ngOnInit = function () {
        this.setupForm();
        if (!this.task.dueDate) {
            this.setQuickDueDate();
        }
        if (!this.form.controls.subject.value) {
            this.setTaskName();
        }
        if (!this.form.controls.ownerName.value) {
            this.setUser({
                _id: this.appService.salesforceUserId,
                fullName: this.appService.userDisplayName,
            });
        }
        this.form.patchValue({ contactId: this.relatedContact && this.relatedContact._id || '' });
        if (this.task.whatId && this.task.whatName) {
            this.globalSearch.setSearchValue(this.task.whatName);
        }
        else if (this.relatedListing && this.relatedListing._id) {
            this.form.patchValue({ whatId: this.relatedListing._id });
            this.globalSearch.setSearchValue(this.relatedListing.name);
        }
    };
    EditTaskForm.prototype.ngOnChanges = function (changes) {
        if (changes.task &&
            changes.task.previousValue &&
            changes.task.previousValue._id !== changes.task.currentValue._id) {
            this.setupForm();
        }
    };
    EditTaskForm.prototype.setupForm = function () {
        this.form = this.taskFormsService.createTaskFormGroup(this.task);
    };
    EditTaskForm.prototype.save = function () {
        var _this = this;
        if (this.form.valid) {
            var task = this.taskFormsService.createTaskModel(this.form);
            task.ownerId = this.task.ownerId;
            return { task: task };
        }
        else {
            Object.keys(this.form.controls).forEach(function (fieldKey) {
                _this.form.controls[fieldKey].markAsDirty();
            });
        }
    };
    EditTaskForm.prototype.editTaskName = function () {
        this.useDefaultTaskName = false;
    };
    EditTaskForm.prototype.setRelatedContact = function (contact) {
        this.relatedContact = contact;
        this.form.patchValue({ contactId: contact && contact._id || '' });
    };
    EditTaskForm.prototype.setUser = function (user) {
        this.task.ownerId = user._id;
        this.task.ownerName = user.fullName;
        this.form.patchValue({ ownerName: this.task.ownerName });
    };
    EditTaskForm.prototype.clearRelatedObject = function () {
        this.form.patchValue({ whatId: null });
        this.form.value.whatId = undefined;
    };
    EditTaskForm.prototype.setRelatedObject = function (object) {
        this.globalSearch.setSearchValue(object.item.name);
        this.form.patchValue({ whatId: object.item && object.item._id || '' });
    };
    EditTaskForm.prototype.setTaskName = function () {
        if (!this.form) {
            return;
        }
        var taskType = this.form.get('type').value;
        // set a default name if we're creating a new task and useDefaultTaskName is true
        if (this.useDefaultTaskName && taskType && !this.form.controls.id.value
            && this.relatedContact && this.relatedContact.firstName) {
            var taskName = '';
            switch (taskType) {
                case TaskModel.TYPE.MEETING:
                case TaskModel.TYPE.TOUR:
                    taskName = taskType + " with " + this.relatedContact.firstName;
                    break;
                case TaskModel.TYPE.OTHER:
                    taskName = "Task with " + this.relatedContact.firstName;
                    break;
                default:
                    taskName = taskType + " " + this.relatedContact.firstName;
                    break;
            }
            this.form.patchValue({ subject: taskName });
        }
    };
    EditTaskForm.prototype.setQuickDueDate = function () {
        var numberOfDaysToAdd = this.form.get('quickDueDateOption').value;
        if (numberOfDaysToAdd !== 'select') {
            var dueDate = moment().utc().add(Number(numberOfDaysToAdd), 'd');
            this.form.patchValue({
                dueDate: dueDate.toDate()
            });
            this.onSelectDate.emit(dueDate.toDate());
        }
    };
    EditTaskForm.prototype.scrollToBottom = function () {
        this.formContent.nativeElement.scrollIntoView(false);
    };
    EditTaskForm.prototype.checkForBlank = function (input) {
        if (input === '') {
            this.form.patchValue({ contactId: '' });
        }
    };
    EditTaskForm.prototype.togglePicker = function (ev) {
        event.stopPropagation();
        this.onOpenCalendar.emit();
    };
    EditTaskForm.prototype.updateContactSearchbarValue = function () {
        this.contactSearchbar.setValue(this.relatedContact && this.relatedContact.fullName || '');
    };
    return EditTaskForm;
}());
export { EditTaskForm };
