import { ModuleWithProviders } from '@angular/core';
import { ConfigService } from '.';
var AptoServicesModule = /** @class */ /*@__PURE__*/ (function () {
    function AptoServicesModule() {
    }
    AptoServicesModule.forRoot = function (ConfigServiceProvider) {
        return {
            ngModule: AptoServicesModule,
            providers: [
                { provide: ConfigService, useClass: ConfigServiceProvider }
            ]
        };
    };
    return AptoServicesModule;
}());
export { AptoServicesModule };
