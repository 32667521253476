<div class="search-filter--component">
    <ion-item>
        <ion-label color="secondary-grey"><ion-icon name="search"></ion-icon></ion-label>
        <ion-input [(ngModel)]="searchTerm"
                   [formControl]="searchControl"
                   [placeholder]="placeholder"
                   #searchFilterInput></ion-input>
        <button *ngIf="searchTerm.length > 0"
                ion-button
                item-right
                color="ancillary-grey"
                (click)="reset()">
            <ion-icon name="close"></ion-icon>
        </button>
    </ion-item>
</div>
