<ion-grid #rootElement
          class="log-activity-panel">
    <ion-row align-items-center>
        <ion-segment [(ngModel)]="result"
                     mode="ios"
                     color="primary-blue"
                     class="log-activity-panel--select">
            <ion-segment-button value="{{TaskModel.RESULT.REACHED}}">Reached</ion-segment-button>
            <ion-segment-button value="{{TaskModel.RESULT.NOT_REACHED}}">Not Reached</ion-segment-button>
            <ion-segment-button value="{{TaskModel.RESULT.LEFT_MESSAGE}}">Left Message</ion-segment-button>
            <ion-segment-button value={{result}}
                                (click)="moreOptions($event)">
                <ion-icon class="log-activity-panel--dropdown-icon"
                          name="arrow-dropdown"></ion-icon>
            </ion-segment-button>
        </ion-segment>

        <button *ngIf="result"
                clear
                color="ancillary-grey"
                ion-button
                small
                class="clear-result"
                (click)="result = null"
                data-automation="logActivityCancelButton">
            <ion-icon name="close"></ion-icon>
        </button>
        <button *ngIf="callList"
                ion-button
                clear
                color="ancillary-grey"
                small
                class="log-activity-panel--skip"
                (click)="skipContact()"
                data-automation="logActivitySkipButton">SKIP</button>
        <button *ngIf="callList && !callList.isAptoGenerated"
                ion-button
                clear
                color="ancillary-grey"
                small
                class="log-activity-panel--remove"
                (click)="removeContact($event)"
                data-automation="logActivityRemoveButton">REMOVE</button>
    </ion-row>

    <ion-row align-items-center
             *ngIf="showCallResultValidationMessage && !result">
        <div class="call-result-validation-warning">
            <ion-icon name="warning" item-start></ion-icon>
            <span class="bold">Oh, hey!</span>
            You need to select a contact result here to save.
        </div>
    </ion-row>

    <ion-row align-items-center>
        <ion-item class="add-activity-note">
            <ion-textarea #activityNoteTextarea
                          textarea-elastic
                          class="elastic-text"
                          placeholder="Add activity note"
                          rows="1"
                          [(ngModel)]="activityNote"></ion-textarea>
        </ion-item>

        <button class="save-and-next"
                (click)="saveTask()"
                clear
                color="primary-blue"
                icon-right
                ion-button
                small
                data-automation="logActivitySaveButton">
            <ng-container *ngIf="callList">
                SAVE + NEXT
                <ion-icon name="md-arrow-forward"></ion-icon>
            </ng-container>
            <ng-container *ngIf="!callList">
                SAVE
            </ng-container>
        </button>
    </ion-row>

    <ion-row class="top-padding" align-items-center>
        <label class="log-activity-panel--openTasks">
            <input type="checkbox"
                   [(ngModel)]="preferences.configJson.openTasks"
                   (click)="clickReviewTasks()">
                   Review Tasks
        </label>
        <label class="log-activity-panel--followup">
            <input type="checkbox"
                   [(ngModel)]="preferences.configJson.addFollowUp"
                   (click)="clickAddFollowUp()">
                   Add Follow-up
        </label>
    </ion-row>
</ion-grid>

<busy-panel *ngIf="isBusy"></busy-panel>
