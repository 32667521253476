var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as moment from 'moment';
import { ListingModel } from '@apto/models';
import { CompsService, ListingsService } from '@apto/services';
import { LogsService } from '@apto/ionic-lib/services';
var MappingDataManager = /** @class */ /*@__PURE__*/ (function () {
    function MappingDataManager(compsService, listingsService, logsService) {
        this.compsService = compsService;
        this.listingsService = listingsService;
        this.logsService = logsService;
        this.compCache = new Map();
        this.listingsCache = new Map();
    }
    MappingDataManager.prototype.setLogger = function (logsService) {
        this.logsService = logsService;
    };
    MappingDataManager.prototype.getSoldFromCacheByPropertyId = function (propertyId) {
        return this.compCache.has(propertyId) ? this.compCache.get(propertyId) : [];
    };
    MappingDataManager.prototype.getListingFromCacheByPropertyId = function (propertyId) {
        return this.listingsCache.get(propertyId);
    };
    MappingDataManager.prototype.isInternalListing = function (listing, listingTypes) {
        var type = listingTypes.get(listing.recordTypeId);
        return type && type.toLowerCase().includes('external') ? false : true;
    };
    // if any of the comps are internal flag it as internal
    MappingDataManager.prototype.isInternalComp = function (comps, compTypes) {
        var results = comps.filter(function (comp) {
            var type = compTypes.get(comp.recordTypeId);
            return type && type.toLowerCase().includes('external');
        });
        if (results.length !== comps.length) {
            return true;
        }
        return results.length ? false : true;
    };
    // determine if a property was sold/leased within the last year
    MappingDataManager.prototype.isRecentlySold = function (prop) {
        var recentDate = moment().subtract(1, 'years');
        return prop.saleDate && moment(prop.saleDate, 'YYYY-MM-DDTHH:mm:ss.SSS').isAfter(recentDate) ? true : false;
    };
    MappingDataManager.prototype.filterForSale = function (props, capRate, soldPrice) {
        var _this = this;
        return props.filter(function (prop) {
            var listing = _this.getListingFromCacheByPropertyId(prop._id);
            return (listing &&
                listing.onMarketStatus !== ListingModel.STATUSES.CLOSED &&
                (listing.isBuyerRep || listing.isSellerRep) &&
                _this.valueInRange(listing, 'capRate', capRate ? capRate : null) &&
                _this.valueInRange(listing, 'askingPriceActual', soldPrice ? soldPrice : null));
        });
    };
    MappingDataManager.prototype.filterForLease = function (props) {
        var _this = this;
        return props.filter(function (prop) {
            var listing = _this.getListingFromCacheByPropertyId(prop._id);
            return (listing &&
                listing.onMarketStatus !== ListingModel.STATUSES.CLOSED &&
                (listing.isLandlordRep || listing.isTenantRep));
        });
    };
    MappingDataManager.prototype.filterSold = function (props, capRate) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCompsByPropertyIds(props.map(function (p) { return p._id; }))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, props.filter(function (prop) {
                                var results = _this.getSoldFromCacheByPropertyId(prop._id).filter(function (comp) {
                                    return comp.isSale && _this.valueInRange(comp, 'capRate', capRate ? capRate : null);
                                });
                                return results.length > 0;
                            })];
                }
            });
        });
    };
    MappingDataManager.prototype.filterLeased = function (props) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getCompsByPropertyIds(props.map(function (p) { return p._id; }))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, props.filter(function (prop) {
                                var results = _this.getSoldFromCacheByPropertyId(prop._id).filter(function (comp) {
                                    return comp.isLease;
                                });
                                return results.length > 0;
                            })];
                }
            });
        });
    };
    MappingDataManager.prototype.getListings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var allListings, ex_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.listingsService.fetch()];
                    case 1:
                        allListings = _a.sent();
                        allListings.forEach(function (listing) {
                            _this.listingsCache.set(listing.propertyId, listing);
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        ex_1 = _a.sent();
                        this.logsService.exception('error getting listings', ex_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MappingDataManager.prototype.getCompsByPropertyIds = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var soldChunks, ex_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, Promise.all(this.chunkArray(ids).map(function (chunk) { return _this.compsService.bySoldOrLeasedProperties(chunk); }))];
                    case 1:
                        soldChunks = _a.sent();
                        // loop over all the sold chunks and set the models into our local cache
                        soldChunks.forEach(function (solds) {
                            solds.forEach(function (comp) {
                                var cache;
                                var exists;
                                if (comp.soldPropertyId) {
                                    cache = _this.getSoldFromCacheByPropertyId(comp.soldPropertyId);
                                    exists = cache.filter(function (c) { return c._id === comp._id; });
                                    if (!exists.length) {
                                        cache.push(comp);
                                        _this.compCache.set(comp.soldPropertyId, cache);
                                    }
                                }
                                if (comp.leasedPropertyId) {
                                    cache = _this.getSoldFromCacheByPropertyId(comp.leasedPropertyId);
                                    exists = cache.filter(function (c) { return c._id === comp._id; });
                                    if (!exists.length) {
                                        cache.push(comp);
                                        _this.compCache.set(comp.leasedPropertyId, cache);
                                    }
                                }
                            });
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        ex_2 = _a.sent();
                        this.logsService.exception('error getting comps for properties', ex_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    MappingDataManager.prototype.valueInRange = function (comp, fieldName, filter) {
        if (filter === void 0) {
            filter = null;
        }
        if (!filter) {
            return true;
        }
        var value = comp[fieldName];
        if (value) {
            var min = Number(filter.min.value);
            var max = Number(filter.max.value);
            if (min && max) {
                return value >= min && value <= max;
            }
            else if (min) {
                return value >= min;
            }
            else if (max) {
                return value <= max;
            }
        }
        return false;
    };
    MappingDataManager.prototype.chunkArray = function (myArray, chunkSize) {
        if (chunkSize === void 0) {
            chunkSize = 100;
        }
        var results = [];
        while (myArray.length) {
            results.push(myArray.splice(0, chunkSize));
        }
        return results;
    };
    return MappingDataManager;
}());
export { MappingDataManager };
