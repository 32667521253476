import { EventEmitter } from '@angular/core';
import './landlord-card.scss';
import { ContactModel, CompanyModel } from '@apto/models';
var LandlordCard = /** @class */ /*@__PURE__*/ (function () {
    function LandlordCard() {
        this.clickedLandlordContact = new EventEmitter();
        this.clickedLandlordCompany = new EventEmitter();
    }
    return LandlordCard;
}());
export { LandlordCard };
