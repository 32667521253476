var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from 'ionic-angular';
import { defer, Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import { CompModel, ContractModel, ListingModel, PipelineModel, PursuitModel, TaskModel } from '@apto/models';
import { AnalyticsService, AppService, CallListsService, CompsService, ContractsService, DealsService, OffersService, PropertiesService, PursuitsService, TasksService, TypesService } from '@apto/services';
import { LogsService } from '@apto/ionic-lib/services';
import { DealListingsService, MetricsService } from '@apto/shared/services';
import { ClosedDealDetailsModal, ContractDetailsModal, NegotiationDetailsModal, PipelineDetailsModalMode, PursuitDetailsModal, SourcingDetailsModal, PipelineConversionModal } from '@apto/pn/modals';
import { PrefixService } from '@apto/pn/services';
import './deals-page.scss';
import moment from 'moment';
var DealsPage = /** @class */ /*@__PURE__*/ (function () {
    function DealsPage(analyticsService, appService, callListsService, compsService, contractsService, dealListingsService, dealsService, metricsService, offersService, propertiesService, pursuitsService, tasksService, typesService, logsService, modalController, prefixService, route) {
        var _this = this;
        this.analyticsService = analyticsService;
        this.appService = appService;
        this.callListsService = callListsService;
        this.compsService = compsService;
        this.contractsService = contractsService;
        this.dealListingsService = dealListingsService;
        this.dealsService = dealsService;
        this.metricsService = metricsService;
        this.offersService = offersService;
        this.propertiesService = propertiesService;
        this.pursuitsService = pursuitsService;
        this.tasksService = tasksService;
        this.typesService = typesService;
        this.logsService = logsService;
        this.modalController = modalController;
        this.prefixService = prefixService;
        this.route = route;
        this.callLists = [];
        this.pursuits = [];
        this.sourcings = [];
        this.negotiations = [];
        this.contracts = [];
        this.closedDeals = [];
        this.isLoadingPursuits = false;
        this.isLoadingListings = false;
        this.isLoadingContracts = false;
        this.isLoadingClosedDeals = false;
        this.sortBy = 'Newest to oldest';
        this.sortOptions = [
            'Newest to oldest',
            'Oldest to newest',
            'Alphabetical',
            'Upcoming task due date',
            'Deal offering price',
            'Commission'
        ];
        this.whatIdToOpenTasks = {};
        this.ngUnsubscribe = new Subject();
        this.attachOffersToListings = function (listings) {
            return __awaiter(_this, void 0, void 0, function () {
                var listingIds, offers, listingIdToOffersMap;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            listingIds = listings.map(function (listing) { return listing._id; });
                            return [4 /*yield*/, this.offersService.byListings(listingIds)];
                        case 1:
                            offers = _a.sent();
                            listingIdToOffersMap = offers.reduce(function (map, offer) {
                                if (!map[offer.listingId]) {
                                    map[offer.listingId] = [offer];
                                }
                                else {
                                    map[offer.listingId].push(offer);
                                }
                                return map;
                            }, {});
                            listings.forEach(function (listing) { return listing.offers = listingIdToOffersMap[listing._id] || []; });
                            return [2 /*return*/];
                    }
                });
            });
        };
        this.onPursuitsDeleted = function (event) {
            _this.pursuits = _this.removeObjectFromList(_this.pursuits, event.payload);
        };
        this.onPursuitsUpserted = function (event) {
            _this.addObjectToList(_this.pursuits, event.payload);
            if (event.isNew) {
                _this.analyticsService.logEvent('Deals Page - Create Pursuit');
            }
            else {
                _this.analyticsService.logEvent('Deals Page - Edit Pursuit');
            }
        };
        this.onListingsDeleted = function (event) {
            _this.sourcings = _this.removeObjectFromList(_this.sourcings, event.payload);
            _this.negotiations = _this.removeObjectFromList(_this.negotiations, event.payload);
        };
        this.onListingsUpserted = function (event) {
            return __awaiter(_this, void 0, void 0, function () {
                var listing, propertyHash, stageString;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            listing = event.payload;
                            return [4 /*yield*/, this.getPropertiesFromListings([listing])];
                        case 1:
                            propertyHash = _a.sent();
                            listing.property = propertyHash[listing.propertyId];
                            if (listing.isInSourcing) {
                                this.addObjectToList(this.sourcings, event.payload);
                            }
                            if (listing.isInNegotiations) {
                                this.addObjectToList(this.negotiations, event.payload);
                                // if the listing was in sourcing, remove that old record
                                this.removeObjectFromList(this.sourcings, [event.payload._id]);
                            }
                            if (!listing.isInSourcing && !listing.isInNegotiations)
                                this.removeObjectFromList(this.sourcings, [event.payload._id]);
                            stageString = listing.isInSourcing ? 'Sourcing' : 'Negotiations';
                            if (event.isNew) {
                                this.analyticsService.logEvent('Deals Page - Create Listing (' + stageString + ')');
                            }
                            else {
                                this.analyticsService.logEvent('Deals Page - Edit Listing (' + stageString + ')');
                            }
                            return [2 /*return*/];
                    }
                });
            });
        };
        this.onContractsDeleted = function (event) {
            _this.contracts = _this.removeObjectFromList(_this.contracts, event.payload);
        };
        this.onContractsUpserted = function (event) {
            _this.addObjectToList(_this.contracts, event.payload);
            if (event.isNew) {
                _this.analyticsService.logEvent('Deals Page - Create Contract');
            }
            else {
                _this.analyticsService.logEvent('Deals Page - Edit Contract');
            }
        };
        this.onClosedDealsDeleted = function (event) {
            _this.closedDeals = _this.removeObjectFromList(_this.closedDeals, event.payload);
        };
        this.onClosedDealsUpserted = function (event) {
            return __awaiter(_this, void 0, void 0, function () {
                var comps, comp, existingClosedDealIdx;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.propertiesService.attachPropertiesToComps([event.payload])];
                        case 1:
                            comps = _a.sent();
                            comp = comps[0];
                            existingClosedDealIdx = this.closedDeals.findIndex(function (item) { return item._id && item._id === comp._id; });
                            if (existingClosedDealIdx !== -1) {
                                this.closedDeals.splice(existingClosedDealIdx, 1, comp);
                            }
                            else {
                                this.addObjectToList(this.closedDeals, comp);
                            }
                            if (event.isNew) {
                                this.analyticsService.logEvent('Deals Page - Create Closed Deal');
                            }
                            else {
                                this.analyticsService.logEvent('Deals Page - Edit Closed Deal');
                            }
                            return [2 /*return*/];
                    }
                });
            });
        };
        this.onTasksDeleted = function (event) {
            Object.keys(_this.whatIdToOpenTasks).forEach(function (whatId) {
                var tasks = _this.whatIdToOpenTasks[whatId];
                _this.whatIdToOpenTasks[whatId] = _this.removeObjectFromList(tasks, event.payload);
            });
            if (_this.sortBy === 'Upcoming task due date') {
                _this.sortDeals();
            }
        };
        this.onTasksUpserted = function (event) {
            var task = event.payload;
            if (!task.whatId) {
                return;
            }
            var openTasks = _this.whatIdToOpenTasks[task.whatId] || [];
            if (task.isComplete) {
                // the task is complete, so remove it from the open tasks list for the related deal
                _this.whatIdToOpenTasks[task.whatId] = _this.removeObjectFromList(openTasks, [task._id]);
                TaskModel.sort(_this.whatIdToOpenTasks[task.whatId]);
            }
            else if (!openTasks.includes(task)) {
                // task is open but wasn't in the open tasks list for the related deal, so add it
                // use concat so that the list's reference is mutated and bindings update
                _this.whatIdToOpenTasks[task.whatId] = openTasks.concat(task);
                TaskModel.sort(openTasks);
            }
            if (_this.sortBy === 'Upcoming task due date') {
                _this.sortDeals();
            }
            _this.analyticsService.logEvent('Deals Page - Create ' + (task.isComplete ? 'Activity' : 'Task'));
        };
    }
    DealsPage.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var dealId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.metricsService.start('Deals Page');
                        dealId = this.route.snapshot.paramMap.get('dealId');
                        if (dealId) {
                            this.metricsService.setRouteName('/deals/:dealId');
                        }
                        else {
                            this.metricsService.setRouteName('/deals');
                        }
                        this.createServiceListeners();
                        return [4 /*yield*/, Promise.all([
                                this.loadCallLists(),
                                this.loadPipelineObjects(),
                                this.typesService.fetch()
                            ])];
                    case 1:
                        _a.sent();
                        this.metricsService.stop('Deals Page');
                        this.analyticsService.logEvent('Load Deals Page');
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.route.paramMap
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(function (paramMap) {
            var dealId = paramMap.get('dealId');
            if (!dealId) {
                return;
            }
            _this.openModal(dealId);
        });
    };
    DealsPage.prototype.ngOnDestroy = function () {
        this.serviceListeners.forEach(function (listener) { return listener.unsubscribe(); });
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    };
    DealsPage.prototype.addPursuit = function () {
        this.addPipelineObject(new PursuitModel());
    };
    DealsPage.prototype.addInSourcing = function () {
        this.addPipelineObject(new ListingModel({ onMarketStatus: ListingModel.STATUSES.IN_SOURCING }));
    };
    DealsPage.prototype.addInNegotiation = function () {
        this.addPipelineObject(new ListingModel({ onMarketStatus: ListingModel.STATUSES.IN_NEGOTIATION }));
    };
    DealsPage.prototype.addInContract = function () {
        this.addPipelineObject(new ContractModel());
    };
    DealsPage.prototype.addClosedDeal = function () {
        this.addPipelineObject(new CompModel());
    };
    DealsPage.prototype.convertToSourcing = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var sourcing, indexOfPursuit, conversionParams, newSourcing;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.logStageChange(data.dragData && data.dragData.property);
                        sourcing = ListingModel.sourcingFromPursuit(data.dragData);
                        sourcing.property = data.dragData.property;
                        this.addObjectToList(this.sourcings, sourcing);
                        indexOfPursuit = this.pursuits.indexOf(data.dragData);
                        this.pursuits.splice(indexOfPursuit, 1);
                        conversionParams = { destination: PipelineModel.STAGES.SOURCING };
                        this.showConversionModal(conversionParams);
                        return [4 /*yield*/, this.dealListingsService.createFromPursuit(data.dragData)];
                    case 1:
                        newSourcing = _a.sent();
                        conversionParams.pipelineObject = newSourcing;
                        newSourcing.property = sourcing.property;
                        sourcing._id = newSourcing._id;
                        this.replaceMatchingObjectInList(this.sourcings, newSourcing);
                        this.analyticsService.logEvent('Deals Page - Convert to Sourcing');
                        this.whatIdToOpenTasks[newSourcing._id] = this.whatIdToOpenTasks[data.dragData._id];
                        delete this.whatIdToOpenTasks[data.dragData._id];
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.convertToNegotiation = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var negotiation, clonedNegotiation, indexOfSourcing, conversionParams, indexOfClonedNegotiation;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.logStageChange(data.dragData && data.dragData.property);
                        negotiation = data.dragData;
                        negotiation.onMarketStatus = ListingModel.STATUSES.IN_NEGOTIATION;
                        clonedNegotiation = Object.assign({}, negotiation);
                        delete clonedNegotiation._id;
                        this.addObjectToList(this.negotiations, clonedNegotiation);
                        indexOfSourcing = this.sourcings.indexOf(data.dragData);
                        this.sourcings.splice(indexOfSourcing, 1);
                        conversionParams = { destination: PipelineModel.STAGES.NEGOTIATION };
                        this.showConversionModal(conversionParams);
                        return [4 /*yield*/, this.dealListingsService.upsert(negotiation)];
                    case 1:
                        _a.sent();
                        conversionParams.pipelineObject = negotiation;
                        indexOfClonedNegotiation = this.negotiations.indexOf(clonedNegotiation);
                        this.negotiations.splice(indexOfClonedNegotiation, 1);
                        this.analyticsService.logEvent('Deals Page - Convert to Negotiations');
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.convertToContract = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var contract, indexOfNegotiation, conversionParams, newContract;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.logStageChange(data.dragData && data.dragData.property);
                        contract = ContractModel.fromListing(data.dragData);
                        contract.property = data.dragData.property;
                        this.addObjectToList(this.contracts, contract);
                        indexOfNegotiation = this.negotiations.indexOf(data.dragData);
                        this.negotiations.splice(indexOfNegotiation, 1);
                        conversionParams = { destination: PipelineModel.STAGES.CONTRACT };
                        this.showConversionModal(conversionParams);
                        return [4 /*yield*/, this.contractsService.createFromListing(data.dragData)];
                    case 1:
                        newContract = _a.sent();
                        conversionParams.pipelineObject = newContract;
                        newContract.property = contract.property;
                        contract._id = newContract._id;
                        this.replaceMatchingObjectInList(this.contracts, newContract);
                        if (!newContract)
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.dealListingsService.upsert(new ListingModel({
                                _id: data.dragData._id,
                                onMarketStatus: ListingModel.STATUSES.IN_CONTRACT
                            }))];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.analyticsService.logEvent('Deals Page - Convert to Contract');
                        this.whatIdToOpenTasks[newContract._id] = this.whatIdToOpenTasks[data.dragData._id];
                        delete this.whatIdToOpenTasks[data.dragData._id];
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.convertToClosed = function (data) {
        return __awaiter(this, void 0, void 0, function () {
            var comp, indexOfContracts, conversionParams, listing, listing_data, fileds, list_elem_1, newComp;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.logStageChange(data.dragData && data.dragData.property);
                        data.dragData.contractCloseDate = moment().utcOffset(0, false).toDate();
                        comp = CompModel.fromContract(data.dragData);
                        this.addObjectToList(this.closedDeals, comp);
                        indexOfContracts = this.contracts.indexOf(data.dragData);
                        this.contracts.splice(indexOfContracts, 1);
                        conversionParams = { destination: PipelineModel.STAGES.CLOSED_DEAL };
                        this.showConversionModal(conversionParams);
                        return [4 /*yield*/, this.dealListingsService.fetch({ where: { id: comp.listingId } })];
                    case 1:
                        listing = _a.sent();
                        listing_data = {};
                        if (listing && listing.length) {
                            fileds = ['askingPriceActual', 'noi', 'capRate', 'yield'];
                            list_elem_1 = listing[0];
                            fileds.forEach(function (el) {
                                listing_data[el] = list_elem_1[el];
                            });
                        }
                        return [4 /*yield*/, this.compsService.createFromContract(data.dragData, listing_data)];
                    case 2:
                        newComp = _a.sent();
                        conversionParams.pipelineObject = newComp;
                        comp._id = newComp._id;
                        this.replaceMatchingObjectInList(this.closedDeals, newComp);
                        this.analyticsService.logEvent('Deals Page - Convert to Closed Deal');
                        this.whatIdToOpenTasks[newComp._id] = this.whatIdToOpenTasks[data.dragData._id];
                        delete this.whatIdToOpenTasks[data.dragData._id];
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.loadPipelineObjects = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.propertiesService.autoAttachDefaultPhoto = false;
                        return [4 /*yield*/, Promise.all([
                                this.loadPursuits(),
                                this.loadListings(),
                                this.loadContracts(),
                                this.loadClosedDeals()
                            ])];
                    case 1:
                        _a.sent();
                        this.propertiesService.autoAttachDefaultPhoto = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.getPropertiesFromListings = function (listings) {
        return __awaiter(this, void 0, void 0, function () {
            var propertyIds, propertyHash, properties;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        propertyIds = listings.map(function (listing) { return listing.propertyId; });
                        propertyHash = {};
                        return [4 /*yield*/, this.propertiesService.in(propertyIds)];
                    case 1:
                        properties = _a.sent();
                        properties.forEach(function (property) {
                            propertyHash[property._id] = property;
                        });
                        return [2 /*return*/, propertyHash];
                }
            });
        });
    };
    DealsPage.prototype.sortChanged = function (sortBy) {
        this.sortBy = sortBy;
        this.sortDeals();
    };
    DealsPage.prototype.logStageChange = function (property) {
        if (property && property.parentPropertyId) {
            this.analyticsService.logEvent('Spaces Related Deal Changed Stage');
        }
    };
    DealsPage.prototype.openModal = function (dealId) {
        var _this = this;
        var serviceMap = {
            listings: this.dealListingsService,
            contracts: this.contractsService,
            comps: this.compsService,
            pursuits: this.pursuitsService
        };
        var fetchPrefixes = function (prefixes) {
            var dealPrefix = dealId.slice(0, 3);
            var dealType = prefixes.find(function (_a) {
                var prefix = _a.prefix;
                return prefix === dealPrefix;
            }).type;
            var service = serviceMap[dealType];
            return service.in([dealId]);
        };
        this.prefixService.getPrefixes(['listings', 'contracts', 'comps', 'pursuits'])
            .pipe(takeUntil(this.ngUnsubscribe), switchMap(function (res) { return defer(function () { return fetchPrefixes(res); }); }))
            .subscribe(function (dealObject) {
            _this.addPipelineObject(dealObject[0], PipelineDetailsModalMode.VIEW);
        }, function (err) {
            _this.logsService.error(err.message);
        });
    };
    DealsPage.prototype.createServiceListeners = function () {
        this.serviceListeners = [
            this.pursuitsService.onDeleted.subscribe(this.onPursuitsDeleted),
            this.pursuitsService.onUpserted.subscribe(this.onPursuitsUpserted),
            this.dealListingsService.onDeleted.subscribe(this.onListingsDeleted),
            this.dealListingsService.onUpserted.subscribe(this.onListingsUpserted),
            this.contractsService.onDeleted.subscribe(this.onContractsDeleted),
            this.contractsService.onUpserted.subscribe(this.onContractsUpserted),
            this.compsService.onDeleted.subscribe(this.onClosedDealsDeleted),
            this.compsService.onUpserted.subscribe(this.onClosedDealsUpserted),
            this.tasksService.onDeleted.subscribe(this.onTasksDeleted),
            this.tasksService.onUpserted.subscribe(this.onTasksUpserted)
        ];
    };
    DealsPage.prototype.addPipelineObject = function (pipelineObject, mode) {
        var _this = this;
        if (mode === void 0) {
            mode = PipelineDetailsModalMode.EDIT;
        }
        var modalToOpen = null;
        var stage;
        if (pipelineObject instanceof PursuitModel) {
            modalToOpen = PursuitDetailsModal;
            stage = PipelineModel.STAGES.PURSUIT;
        }
        else if (pipelineObject instanceof ListingModel && pipelineObject.isInNegotiations) {
            modalToOpen = NegotiationDetailsModal;
            stage = PipelineModel.STAGES.NEGOTIATION;
        }
        else if (pipelineObject instanceof ListingModel && pipelineObject.isInSourcing) {
            modalToOpen = SourcingDetailsModal;
            stage = PipelineModel.STAGES.SOURCING;
        }
        else if (pipelineObject instanceof ContractModel) {
            modalToOpen = ContractDetailsModal;
            stage = PipelineModel.STAGES.CONTRACT;
        }
        else if (pipelineObject instanceof CompModel) {
            modalToOpen = ClosedDealDetailsModal;
            stage = PipelineModel.STAGES.CLOSED_DEAL;
        }
        var detailsModal = this.modalController.create(modalToOpen, { mode: mode, selectedRecord: pipelineObject });
        // Track any objects created while the modal is open.
        var createdRecord;
        var subscriptions = [
            this.pursuitsService.onUpserted.subscribe(function (data) { return createdRecord = data.payload; }),
            this.dealListingsService.onUpserted.subscribe(function (data) { return createdRecord = data.payload; }),
            this.contractsService.onUpserted.subscribe(function (data) { return createdRecord = data.payload; }),
            this.compsService.onUpserted.subscribe(function (data) { return createdRecord = data.payload; })
        ];
        // Show Conversion Modal if any records were created while the modal was open.
        detailsModal.onDidDismiss(function () {
            subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
            if (!createdRecord) {
                return;
            }
            var conversionParams = { destination: stage, pipelineObject: createdRecord };
            _this.showConversionModal(conversionParams);
        });
        detailsModal.present({ updateUrl: false });
    };
    DealsPage.prototype.addObjectToList = function (list, record) {
        if (list.indexOf(record) !== -1 || list.find(function (item) { return item._id && item._id === record._id; })) {
            return;
        }
        list.push(record);
        this.sortDeals([list]);
    };
    DealsPage.prototype.getRelatedTasks = function (pipelineObjects) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var whatIdToOpenTasks, whatIds, relatedTasks;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        whatIdToOpenTasks = {};
                        whatIds = pipelineObjects.map(function (pipelineObject) { return pipelineObject._id; });
                        whatIds.forEach(function (whatId) { return whatIdToOpenTasks[whatId] = []; });
                        return [4 /*yield*/, this.tasksService.byRelatedIds(whatIds, false, { ownerOnly: true })];
                    case 1:
                        relatedTasks = _a.sent();
                        TaskModel.sortActivities(relatedTasks);
                        relatedTasks.forEach(function (task) {
                            if (!task.isComplete) {
                                whatIdToOpenTasks[task.whatId].push(task);
                            }
                        });
                        whatIds.forEach(function (whatId) { return _this.whatIdToOpenTasks[whatId] = whatIdToOpenTasks[whatId]; });
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.loadCallLists = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.callListsService.getDealLists()];
                    case 1:
                        _a.callLists = _b.sent();
                        this.callLists.sort(function (a, b) {
                            return (a.name > b.name) ? 1 : -1;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.loadClosedDeals = function () {
        return __awaiter(this, void 0, void 0, function () {
            var comps;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoadingClosedDeals = true;
                        return [4 /*yield*/, this.dealsService.getComps()];
                    case 1:
                        comps = _a.sent();
                        return [4 /*yield*/, this.propertiesService.attachPropertiesToComps(comps)];
                    case 2:
                        comps = _a.sent();
                        this.getRelatedTasks(comps);
                        this.closedDeals = comps;
                        this.isLoadingClosedDeals = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.loadContracts = function () {
        return __awaiter(this, void 0, void 0, function () {
            var contractParams, contracts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoadingContracts = true;
                        contractParams = { where: { status: { $ne: ContractModel.STATUSES.CLOSED } }, sort: '-createdDate' };
                        return [4 /*yield*/, this.contractsService.fetch(contractParams)];
                    case 1:
                        contracts = _a.sent();
                        this.getRelatedTasks(contracts);
                        this.contracts = contracts;
                        this.isLoadingContracts = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.loadListings = function () {
        return __awaiter(this, void 0, void 0, function () {
            var ignoredStatuses, listingParams, listings, propertyHash;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoadingListings = true;
                        ignoredStatuses = [
                            ListingModel.STATUSES.CLOSED,
                            ListingModel.STATUSES.DEAD,
                            ListingModel.STATUSES.IN_CONTRACT,
                            ListingModel.STATUSES.OFF_MARKET
                        ];
                        listingParams = { where: { onMarketStatus: { $nin: ignoredStatuses } }, sort: '-createdDate' };
                        return [4 /*yield*/, this.dealListingsService.fetch(listingParams)];
                    case 1:
                        listings = _a.sent();
                        this.getRelatedTasks(listings);
                        return [4 /*yield*/, this.getPropertiesFromListings(listings)];
                    case 2:
                        propertyHash = _a.sent();
                        listings.forEach(function (listing) {
                            listing.property = propertyHash[listing.propertyId];
                        });
                        return [4 /*yield*/, this.populateSourcingsAndNegotiations(listings)];
                    case 3:
                        _a.sent();
                        this.isLoadingListings = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.loadPursuits = function () {
        return __awaiter(this, void 0, void 0, function () {
            var pursuitParams, pursuits;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoadingPursuits = true;
                        pursuitParams = {
                            where: {
                                status: {
                                    $nin: [
                                        PursuitModel.STATUSES.WON,
                                        PursuitModel.STATUSES.DEAD,
                                        PursuitModel.STATUSES.NOT_WON
                                    ]
                                }
                            },
                            sort: '-createdDate'
                        };
                        return [4 /*yield*/, this.pursuitsService.fetch(pursuitParams)];
                    case 1:
                        pursuits = _a.sent();
                        this.getRelatedTasks(pursuits);
                        this.pursuits = pursuits;
                        this.isLoadingPursuits = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.populateSourcingsAndNegotiations = function (listings) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.sourcings = [];
                        this.negotiations = [];
                        return [4 /*yield*/, this.attachOffersToListings(listings)];
                    case 1:
                        _a.sent();
                        listings.forEach(function (listing) {
                            if (listing.isInSourcing) {
                                _this.sourcings.push(listing);
                            }
                            else if (listing.isInNegotiations) {
                                _this.negotiations.push(listing);
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    DealsPage.prototype.removeObjectFromList = function (list, ids) {
        return list.filter(function (record) { return ids.indexOf(record._id) === -1; });
    };
    DealsPage.prototype.replaceMatchingObjectInList = function (list, record) {
        var i = list.findIndex(function (item) { return item._id === record._id; });
        if (i !== -1) {
            list[i] = record;
        }
    };
    DealsPage.prototype.showConversionModal = function (pipelineConversionParams) {
        this.modalController
            .create(PipelineConversionModal, { pipelineConversionParams: pipelineConversionParams }, { enableBackdropDismiss: false })
            .present({ updateUrl: false });
    };
    DealsPage.prototype.sortDeals = function (listsOfDeals) {
        var _this = this;
        listsOfDeals = listsOfDeals || [
            this.pursuits,
            this.negotiations,
            this.sourcings,
            this.contracts,
            this.closedDeals
        ];
        if (this.sortBy === 'Newest to oldest') {
            listsOfDeals.forEach(function (deals) { return _this.sortByField(deals, 'createdDate', false); });
        }
        else if (this.sortBy === 'Oldest to newest') {
            listsOfDeals.forEach(function (deals) { return _this.sortByField(deals, 'createdDate'); });
        }
        else if (this.sortBy === 'Alphabetical') {
            listsOfDeals.forEach(function (deals) { return _this.sortByField(deals, 'name'); });
        }
        else if (this.sortBy === 'Upcoming task due date') {
            listsOfDeals.forEach(function (deals) { return _this.sortByUpcomingTask(deals); });
        }
        else if (this.sortBy === 'Deal offering price') {
            listsOfDeals.forEach(function (deals) { return _this.sortByField(deals, 'price', false); });
        }
        else if (this.sortBy === 'Commission') {
            listsOfDeals.forEach(function (deals) { return _this.sortByField(deals, 'commissionAmount', false); });
        }
    };
    DealsPage.prototype.sortByField = function (deals, field, ascending) {
        if (ascending === void 0) {
            ascending = true;
        }
        // treat empty values as empty strings for consistent sorting
        // use case-invariant sorting
        var getSortValue = function (value) {
            if (!value) {
                return '';
            }
            if (typeof value === 'string') {
                return value.toLowerCase();
            }
            return value;
        };
        deals.sort(function (a, b) {
            var aValue = getSortValue(a[field]);
            var bValue = getSortValue(b[field]);
            if (aValue > bValue) {
                return (ascending) ? 1 : -1;
            }
            else if (aValue < bValue) {
                return (ascending) ? -1 : 1;
            }
            // secondary sort on name
            var aName = getSortValue(a.name);
            var bName = getSortValue(b.name);
            if (aName > bName) {
                return (ascending) ? 1 : -1;
            }
            else if (aName < bName) {
                return (ascending) ? -1 : 1;
            }
            return 0;
        });
    };
    DealsPage.prototype.sortByUpcomingTask = function (deals) {
        var _this = this;
        deals.sort(function (a, b) {
            // get date fields. set to empty strings if null or undefined for consistent sorting.
            var aTasks = _this.whatIdToOpenTasks[a._id];
            var aUpcomingTaskDueDate = (aTasks && aTasks.length) ? aTasks[0].dueDate || '' : '';
            var aUpcomingTaskModifiedDate = (aTasks && aTasks.length) ? aTasks[0].lastModifiedDate || '' : '';
            var bTasks = _this.whatIdToOpenTasks[b._id];
            var bUpcomingTaskDueDate = (bTasks && bTasks.length) ? bTasks[0].dueDate || '' : '';
            var bUpcomingTaskModifiedDate = (bTasks && bTasks.length) ? bTasks[0].dueDate || '' : '';
            // ascending sort with empty dates at the end
            if (!aUpcomingTaskDueDate) {
                return 1;
            }
            else if (!bUpcomingTaskDueDate) {
                return -1;
            }
            else if (aUpcomingTaskDueDate > bUpcomingTaskDueDate) {
                return 1;
            }
            else if (aUpcomingTaskDueDate < bUpcomingTaskDueDate) {
                return -1;
            }
            // secondary sort on last modified date
            if (aUpcomingTaskModifiedDate > bUpcomingTaskModifiedDate) {
                return 1;
            }
            else if (aUpcomingTaskModifiedDate < bUpcomingTaskModifiedDate) {
                return -1;
            }
            return 0;
        });
    };
    return DealsPage;
}());
export { DealsPage };
