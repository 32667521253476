import { CompanyModel } from '@apto/models';
import './company-summary-card.scss';
var CompanySummaryCard = /** @class */ /*@__PURE__*/ (function () {
    function CompanySummaryCard() {
    }
    CompanySummaryCard.prototype.executeCall = function (phoneNumber) {
        window.location.assign(phoneNumber.actionLink);
    };
    return CompanySummaryCard;
}());
export { CompanySummaryCard };
