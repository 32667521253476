var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter } from '@angular/core';
import './add-record.scss';
import { CompanyDetailsModal, CompanyDetailsModalMode, PropertyDetailsModal, PropertyDetailsModalMode, SpaceModal, SpaceModalMode } from '@apto/pn/modals';
import { ModalController } from 'ionic-angular';
import { ContactDetailsModal, ContactDetailsModalMode } from '@apto/shared';
import { TypesService } from '@apto/services';
import { PropertyModel } from '@apto/models';
var AddRecord = /** @class */ /*@__PURE__*/ (function () {
    function AddRecord(modalController, typesService) {
        this.modalController = modalController;
        this.typesService = typesService;
        this.onRecordCreated = new EventEmitter();
    }
    AddRecord.prototype.addRecord = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.initModal()];
                    case 1:
                        _a.modal = _b.sent();
                        this.modal.onDidDismiss(function (data) {
                            if (data) {
                                _this.onRecordCreated.emit(data);
                            }
                        });
                        this.modal.present({ updateUrl: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    AddRecord.prototype.initModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var modal;
            return __generator(this, function (_a) {
                if (this.objectName === 'Company') {
                    modal = this.createCompanyEditModal();
                }
                else if (this.objectName === 'Contact') {
                    modal = this.createContactEditModal();
                }
                else if (this.objectName === 'Property') {
                    modal = this.createPropertyEditModal();
                }
                else if (this.objectName === 'Space') {
                    modal = this.createSpaceEditModal(this.spaceParentProperty);
                }
                else {
                    throw new Error(this.objectName + " not found for AddRecord Component.");
                }
                return [2 /*return*/, modal];
            });
        });
    };
    AddRecord.prototype.createCompanyEditModal = function () {
        var options = {
            isInitializing: false
        };
        return this.modalController.create(CompanyDetailsModal, {
            mode: CompanyDetailsModalMode.EDIT,
            options: options,
            isQuickAdd: true
        }, {
            cssClass: 'large-modal'
        });
    };
    AddRecord.prototype.createContactEditModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var types;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        types = _a.sent();
                        return [2 /*return*/, this.modalController.create(ContactDetailsModal, {
                                mode: ContactDetailsModalMode.EDIT,
                                types: Object.keys(types.contactTypes),
                                isQuickAdd: true
                            }, {
                                cssClass: 'large-modal'
                            })];
                }
            });
        });
    };
    AddRecord.prototype.createPropertyEditModal = function () {
        return __awaiter(this, void 0, void 0, function () {
            var types;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        types = _a.sent();
                        return [2 /*return*/, this.modalController.create(PropertyDetailsModal, {
                                selectedProperty: new PropertyModel(),
                                mode: PropertyDetailsModalMode.EDIT,
                                types: Object.keys(types.propertyTypes),
                                isQuickAdd: true
                            }, {
                                cssClass: 'large-modal'
                            })];
                }
            });
        });
    };
    AddRecord.prototype.createSpaceEditModal = function (parentProperty) {
        return this.modalController.create(SpaceModal, {
            mode: SpaceModalMode.EDIT,
            selectedRecord: {
                space: null,
                parentProperty: parentProperty
            },
            isQuickAdd: true
        });
    };
    return AddRecord;
}());
export { AddRecord };
