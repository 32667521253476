import { Injector, OnDestroy, OnInit } from '@angular/core';
import { CallListsService, ContactGroupsService, ContactListViewsService, DocumentsService, FullContactService, ImagesService, PropertiesService } from '@apto/services';
import { LOG_LEVELS } from '@apto/services/constants';
var LogsService = /** @class */ /*@__PURE__*/ (function () {
    function LogsService(injector) {
        this.injector = injector;
        this.level = LOG_LEVELS.DEBUG;
        this.handlers = {};
        this.subscriptions = [];
    }
    LogsService.prototype.ngOnInit = function () {
        var _this = this;
        var callListsService = this.injector.get(CallListsService);
        var contactGroupsService = this.injector.get(ContactGroupsService);
        var contactListViewsService = this.injector.get(ContactListViewsService);
        var documentsService = this.injector.get(DocumentsService);
        var fullContactService = this.injector.get(FullContactService);
        var imagesService = this.injector.get(ImagesService);
        var propertiesService = this.injector.get(PropertiesService);
        this.subscriptions.push(callListsService.onLogException.subscribe(function (event) { return _this.exception(event.message, event.ex); }));
        this.subscriptions.push(contactGroupsService.onLogException.subscribe(function (event) { return _this.exception(event.message, event.ex); }));
        this.subscriptions.push(contactListViewsService.onLogException.subscribe(function (event) { return _this.exception(event.message, event.ex); }));
        this.subscriptions.push(documentsService.onLogException.subscribe(function (event) { return _this.exception(event.message, event.ex); }));
        this.subscriptions.push(fullContactService.onLogException.subscribe(function (event) { return _this.exception(event.message, event.ex); }));
        this.subscriptions.push(imagesService.onLogException.subscribe(function (event) { return _this.exception(event.message, event.ex); }));
        this.subscriptions.push(propertiesService.onLogException.subscribe(function (event) { return _this.exception(event.message, event.ex); }));
    };
    LogsService.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    LogsService.prototype.debug = function (message) {
        this.log(LOG_LEVELS.DEBUG, message);
    };
    LogsService.prototype.info = function (message) {
        this.log(LOG_LEVELS.INFO, message);
    };
    LogsService.prototype.error = function (message) {
        this.log(LOG_LEVELS.ERROR, message);
    };
    LogsService.prototype.exception = function (message, context) {
        this.log(LOG_LEVELS.EXCEPTION, message, context);
    };
    LogsService.prototype.addHandler = function (name, handler) {
        this.handlers[name] = handler;
    };
    LogsService.prototype.removeHandler = function (name) {
        delete this.handlers[name];
    };
    LogsService.prototype.log = function (level, message, context) {
        var _this = this;
        if (level >= this.level) {
            Object.keys(this.handlers).forEach(function (key) {
                try {
                    _this.handlers[key].log(level, message, context);
                }
                catch (ex) {
                    // allow log handlers to fail silently so we don't enter a recursive state
                }
            });
        }
    };
    return LogsService;
}());
export { LogsService };
