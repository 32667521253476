var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { LogsService } from '@apto/ionic-lib/services';
import { ConfigService } from '@apto/pn/services/config/config-service';
var ExportFileStatus = /** @class */ /*@__PURE__*/ (function () {
    function ExportFileStatus() {
    }
    ExportFileStatus.PENDING = 'pending';
    ExportFileStatus.FAILED = 'failed';
    ExportFileStatus.SUCCESS = 'success';
    return ExportFileStatus;
}());
export { ExportFileStatus };
var ExportFile = /** @class */ /*@__PURE__*/ (function () {
    function ExportFile() {
    }
    return ExportFile;
}());
export { ExportFile };
var ExportResult = /** @class */ /*@__PURE__*/ (function () {
    function ExportResult() {
    }
    return ExportResult;
}());
export { ExportResult };
var ExportSearchResponse = /** @class */ /*@__PURE__*/ (function () {
    function ExportSearchResponse() {
    }
    return ExportSearchResponse;
}());
export { ExportSearchResponse };
var ExportCreateResponse = /** @class */ /*@__PURE__*/ (function () {
    function ExportCreateResponse() {
    }
    return ExportCreateResponse;
}());
export { ExportCreateResponse };
var ExportPropertiesShape = /** @class */ /*@__PURE__*/ (function () {
    function ExportPropertiesShape() {
    }
    return ExportPropertiesShape;
}());
export { ExportPropertiesShape };
var ExportUpdateRequest = /** @class */ /*@__PURE__*/ (function () {
    function ExportUpdateRequest() {
    }
    return ExportUpdateRequest;
}());
export { ExportUpdateRequest };
var ExportCreateRequest = /** @class */ /*@__PURE__*/ (function () {
    function ExportCreateRequest() {
    }
    return ExportCreateRequest;
}());
export { ExportCreateRequest };
var EXPORT_POLLING_INTERVAL = 10000;
var ExportService = /** @class */ /*@__PURE__*/ (function () {
    function ExportService(configService, http, logsService) {
        this.configService = configService;
        this.http = http;
        this.logsService = logsService;
        this.serviceName = 'ExportService';
        this.processingFiles = [];
        this.onExportComplete = new Subject();
        this.onExportStart = new Subject();
        this.onPollingStart = new Subject();
        this.onPollingEnd = new Subject();
        this.pollingInterval = EXPORT_POLLING_INTERVAL;
    }
    ExportService.prototype.addExportToPoller = function (id) {
        if (!this.processingFiles.includes(id)) {
            this.processingFiles.push(id);
            this.poll();
        }
    };
    ExportService.prototype.getStuckTime = function () {
        return moment.utc().subtract(5, 'minutes');
    };
    ExportService.prototype.poll = function () {
        var _this = this;
        if (this.poller) {
            clearTimeout(this.poller);
        }
        this.onPollingStart.next();
        this.poller = setTimeout(function () {
            return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                var response, query, err_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            query = [];
                            if (!this.processingFiles.length)
                                return [3 /*break*/, 5];
                            this.processingFiles.forEach(function (id) {
                                query.push("id__in=" + id);
                            });
                            _a.label = 1;
                        case 1:
                            _a.trys.push([1, 3, , 4]);
                            return [4 /*yield*/, this.getExports(query.join('&'))];
                        case 2:
                            response = _a.sent();
                            return [3 /*break*/, 4];
                        case 3:
                            err_1 = _a.sent();
                            this.logsService.error(err_1);
                            return [3 /*break*/, 4];
                        case 4:
                            if (response && response.data.results.length) {
                                response.data.results.forEach(function (file) {
                                    _this.checkForLongRunningExport(file);
                                    if (file.status !== ExportFileStatus.PENDING) {
                                        var index = _this.processingFiles.indexOf(file.id);
                                        if (index > -1) {
                                            _this.processingFiles.splice(index, 1);
                                            _this.onExportComplete.next(file);
                                        }
                                    }
                                });
                            }
                            if (this.processingFiles.length) {
                                this.poll();
                            }
                            else {
                                this.onPollingEnd.next();
                            }
                            return [3 /*break*/, 6];
                        case 5:
                            this.onPollingEnd.next();
                            _a.label = 6;
                        case 6: return [2 /*return*/];
                    }
                });
            });
        }, this.pollingInterval);
    };
    ExportService.prototype.checkForLongRunningExport = function (file) {
        if (file.status === ExportFileStatus.PENDING &&
            file.created_at &&
            this.getStuckTime().isAfter(moment.utc(file.created_at, 'YYYY-MM-DDTHH:mm:ss.SSS'))) {
            file.status = ExportFileStatus.FAILED;
            try {
                this.markExportAsFailed({
                    id: file.id,
                    status: ExportFileStatus.FAILED
                });
            }
            catch (ex) {
                this.logsService.error(ex);
            }
        }
    };
    ExportService.prototype.getExports = function (query) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            return _this.http
                .get(_this.configService.exportServiceUrl + "/api/file?" + query)
                .subscribe(function (result) {
                return resolve(result);
            }, function (error) {
                if (error) {
                    return reject(error);
                }
            });
        });
    };
    ExportService.prototype.exportProperties = function (propertyRequest) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            return _this.http
                .post(_this.configService.exportServiceUrl + "/api/file/create", propertyRequest)
                .subscribe(function (result) {
                if (result.data.id) {
                    _this.onExportStart.next();
                    _this.addExportToPoller(result.data.id);
                }
                return resolve(result);
            }, function (error) {
                if (error) {
                    return reject(error);
                }
            });
        });
    };
    ExportService.prototype.markExportAsFailed = function (propertyRequest) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            return _this.http
                .post(_this.configService.exportServiceUrl + "/api/file/update", propertyRequest)
                .subscribe(function (result) {
                return resolve(result);
            }, function (error) {
                if (error) {
                    return reject(error);
                }
            });
        });
    };
    ExportService.prototype.downloadLink = function (file) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            return _this.http
                .get(_this.configService.exportServiceUrl + "/api/file/download?fileId=" + file.id)
                .subscribe(function (result) {
                if (result.message !== 'OK') {
                    return reject(result);
                }
                return resolve(result.data.downloadUrl);
            }, function (error) {
                _this.logsService.exception("Failed to fetch for service " + _this.serviceName + ".", error);
                return reject(error);
            });
        });
    };
    return ExportService;
}());
export { ExportService };
