<div class="background-loading login--login-content"
     [style.background-image]="backgroundCityUrl">
    <ion-grid>
        <ion-row>
            <ion-col>
                <img class="login--logo"
                     alt="Apto Logo"
                     src="/images/AptoLogo-white.png"
                     (click)="logoClicked();">
            </ion-col>
        </ion-row>
        <ion-row *ngIf="!isLoggingIn">
            <ion-col>
                <button id="loginButton"
                        ion-button
                        color="action"
                        (click)="doLogin();">
                    Log in
                </button>
                <p *ngIf="hasErrors" class="login--login-text-container error">
                    There was an error logging in please try again or
                    <span class="login--contact-support"
                          (click)="openSupportPage();">contact support</span>.
                </p>
            </ion-col>
        </ion-row>
        <ion-row *ngIf="isLoggingIn">
            <ion-col>
                <div class="login--login-text-container">
                    <ion-spinner class="spinner-small spinner-white"
                                 item-left></ion-spinner>
                    <p class="login--loading-text">Logging in</p>
                </div>
            </ion-col>
        </ion-row>

        <ion-card *ngIf="!isLoggingIn"
                  class="login--no-apto-card">
            <ion-card-header class="login--learn-more-header">
                Don't have Apto?
            </ion-card-header>
            <ion-card-content>
                <p>Created for commercial real estate brokers,
                Apto is the #1 software for managing client relationships,
                listings, deals, and back-office.</p>
                <span class="login--learn-more"
                      (click)="openLearnMorePage();">Learn more ></span>
            </ion-card-content>
        </ion-card>
    </ion-grid>
</div>
