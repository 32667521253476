import { NavParams, ViewController } from 'ionic-angular';
import './select-popover.scss';
var SelectPopover = /** @class */ /*@__PURE__*/ (function () {
    function SelectPopover(navParams, viewController) {
        this.viewController = viewController;
        this.options = navParams.get('options');
        this.value = navParams.get('value');
    }
    SelectPopover.prototype.onOptionSelected = function (option) {
        this.viewController.dismiss(option.value);
    };
    return SelectPopover;
}());
export { SelectPopover };
