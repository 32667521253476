<form #formContent
      [formGroup]="form"
      class="edit-form">

    <ion-label stacked>Property *</ion-label>
    <ion-auto-complete [dataProvider]="propertiesService"
                       [options]="{ placeholder : 'Find property' }"
                       (itemSelected)="selectProperty($event)"></ion-auto-complete>
    <div class="field-error"
         *ngIf="form.get('propertyId').dirty &&
                form.get('propertyId').errors &&
                form.get('propertyId').errors.required">
        A property is required.
    </div>

    <ion-item>
        <ion-label stacked>Number of units *</ion-label>
        <ion-input formControlName="numUnits"
                   class="light-text"></ion-input>
    </ion-item>
    <div class="field-error"
         *ngIf="form.get('numUnits').dirty &&
                form.get('numUnits').errors &&
                (form.get('numUnits').errors.required ||
                form.get('numUnits').errors.pattern)">
                {{validationMessages.INTEGER_REGEX}}
    </div>

    <select-map-field [form]="form"
                  field="unitType"
                  label="Unit type"
                  [options]="picklistOptions.unitType"
                  [cssClass]="'edit-form--select-popover'"></select-map-field>

    <ion-item>
        <ion-label stacked>Unit SF</ion-label>
        <ion-input formControlName="unitSf"
                   class="light-text"></ion-input>
    </ion-item>
    <div class="field-error"
         *ngIf="form.get('unitSf').dirty &&
                form.get('unitSf').errors &&
                form.get('unitSf').errors.pattern">
                {{validationMessages.DECIMAL_REGEX}}
    </div>

    <ion-item>
        <ion-label stacked>Monthly rent</ion-label>
        <ion-input formControlName="monthlyRent"
                   class="light-text"></ion-input>
    </ion-item>
    <div class="field-error"
         *ngIf="form.get('monthlyRent').dirty &&
                form.get('monthlyRent').errors &&
                form.get('monthlyRent').errors.pattern">
                {{validationMessages.DECIMAL_REGEX}}
    </div>

    <busy-panel *ngIf="isSaving"
                message="Saving unit mix..."></busy-panel>
</form>
