<ion-card class="company-summary-card">
    <ion-card-header>Company</ion-card-header>
    <ion-card-content>
        <ion-row>
            <ion-col col-1>
                <ion-icon material-icons
                          name="location_city"
                          class="company-summary-card--company-icon"
                          circle
                          item-left></ion-icon>
            </ion-col>

            <ion-col col-8 padding-left>
                <ion-card-title class="company-summary-card--name">{{company.name}}</ion-card-title>
                <span *ngIf="company.category">{{company.category}}</span>
                <span *ngIf="company.category && company.shippingAddress.street"> • </span>
                <span *ngIf="company.shippingAddress.street">{{company.shippingAddress.toString()}}</span>
            </ion-col>

            <ion-col col-3>
                <ion-row *ngIf="company.phone.number">
                    <span class="hover-link company-summary-card--number company-summary-card--phone"
                    (click)="executeCall(company.phone)">
                      <ion-icon name="call"></ion-icon>
                      {{company.phone.number}}
                  </span>
                </ion-row>

                <ion-row *ngIf="company.fax.number">
                    <span data-automation='company-fax' class="company-summary-card--number">
                        <apto-icon icon="fax" inline></apto-icon>
                        {{company.fax.number}}
                    </span>
                </ion-row>
            </ion-col>
        </ion-row>
    </ion-card-content>
</ion-card>
