<ion-card class="pipeline-card"
          [class.buyer-seller-rep]="(pipelineObject.isSellerRep || pipelineObject.isBuyerRep || (pipelineObject.type && pipelineObject.type.toLowerCase().includes('sale'))) && !pipelineObject.isExternal"
          [class.landlord-tenant-rep]="(pipelineObject.isTenantRep || pipelineObject.isLandlordRep || (pipelineObject.type && pipelineObject.type.toLowerCase().includes('lease'))) && !pipelineObject.isExternal"
          [class.owned-by-other-broker]="pipelineObject.ownerId !== appService.salesforceUserId"
          [class.no-rep]="pipelineObject.isExternal ||
                          (!pipelineObject.isSellerRep &&
                          !pipelineObject.isBuyerRep &&
                          !pipelineObject.isTenantRep &&
                          !pipelineObject.isLandlordRep && 
                          !(pipelineObject.type && pipelineObject.type.toLowerCase().includes('sale')) && 
                          !(pipelineObject.type && pipelineObject.type.toLowerCase().includes('lease')))"
          [attr.automation]="pipelineObject.name"
          *ngIf="pipelineObject && !pipelineObject.archive">
    <ion-card-header>
        <span>
            {{ title }}
            <ion-badge *ngIf="openPipelineTasks && openPipelineTasks.length"
                       [class.badge-md-ancillary-grey]="pipelineTaskStatus === PipelineTaskStatus.DEFAULT"
                       [class.badge-md-primary-blue]="pipelineTaskStatus === PipelineTaskStatus.DUE_SOON"
                       [class.badge-md-action]="pipelineTaskStatus === PipelineTaskStatus.OVERDUE">
                {{ openPipelineTasks.length }}
            </ion-badge>
        </span>
        <button ion-button
                icon-only
                clear
                small
                (click)="openTaskTemplateModal()">
            <ion-icon name="menu"
                      color="ancillary-grey"></ion-icon>
        </button>
    </ion-card-header>
    <ion-card-content>
        <p class="pipeline-card--name"
           [title]="pipelineObject.name">
            {{ pipelineObject.name }}
        </p>
        <p class="property-address">
            {{ pipelineObject.property && pipelineObject.property.addressString }}
        </p>
        <p class="offer-buttons"
           *ngIf="offers.length">
            <button ion-button
                    small
                    color="primary-blue"
                    (click)="viewOffers()">
                {{ offers.length }} {{ isSellerOrBuyerRep ? 'offers' : 'LOI' }}
            </button>
        </p>
        <div *ngIf="criticalDates.length > 0">
            <p class="pipeline-card--critical-date-title">Critical Dates</p>
            <div *ngFor="let task of criticalDates.slice(0, 3)"
                  class="pipeline-card--critical-date-line">
                <div class="pipeline-card--critical-date-line-wrapper">
                    <span class="pipeline-card--critical-date-subject">{{task.subject}}</span>
                    <span class="pipeline-card--critical-date-dash"> - </span>
                    <span class="pipeline-card--critical-date-date">{{task.dueDate | dateFormat}}</span>
                </div>
            </div>
            <button ion-button
                    clear
                    class="pipeline-card--view-all-tasks"
                    (click)="viewAllTasks($event, openPipelineTasks);">
                View all
            </button>
        </div>
    </ion-card-content>
    <ion-row class="card-footer-buttons">
        <ion-col text-left
                 *ngIf="pipelineObject.instanceType === 'Listing' && canStartCalling()">
            <button ion-button
                    clear
                    class="pipeline-card--start-calling"
                    (click)="startCalling($event)">
                Start Calling
                <ion-icon class="call-lists-page--dropdown-icon" name="arrow-dropdown"></ion-icon>
            </button>
        </ion-col>
        <ion-col text-left
                 *ngIf="pipelineObject.instanceType === 'Closed Deal'">
            <button ion-button
                    clear
                    class="pipeline-card--archive"
                    (click)="archiveDeal(pipelineObject)">
                Archive
            </button>
        </ion-col>
        <ion-col text-right>
            <button ion-button
                    clear
                    class="pipeline-card--view-details"
                    (click)="viewDetails();">
                View Details
            </button>
        </ion-col>
    </ion-row>

    <busy-panel *ngIf="!pipelineObject._id || isLoading"></busy-panel>
</ion-card>
