import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from '@angular/core';
import { NavParams, ViewController, ModalController } from 'ionic-angular';
import { PropertyModel, CompModel } from '@apto/models';
import { BaseSlideModal } from '@apto/shared/modals/base-slide-modal/base-slide-modal';
import { AnalyticsService, PropertiesService, CompsService, ImagesService, CompaniesService, ContactsService, TypesService } from '@apto/services';
import { EditSpaceForm } from '@apto/pn/components';
import './space-modal.scss';
import { LeaseModal, LeaseModalMode } from '@apto/pn/modals/lease-modal/lease-modal';
import { LeasingDataService } from '@apto/pn/services';
import { LogsService } from '@apto/ionic-lib/services';
import { ContactDetailsModal, ContactDetailsModalMode } from '@apto/shared';
import { CompanyDetailsModal, CompanyDetailsModalMode } from '@apto/pn/modals/company-details-modal/company-details-modal';
export var SpaceModalMode = /*@__PURE__*/ (function (SpaceModalMode) {
    SpaceModalMode[SpaceModalMode["LIST"] = 0] = "LIST";
    SpaceModalMode[SpaceModalMode["VIEW"] = 1] = "VIEW";
    SpaceModalMode[SpaceModalMode["EDIT"] = 2] = "EDIT";
    return SpaceModalMode;
})({});
var SpaceModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(SpaceModal, _super);
    function SpaceModal(logsService, analyticsService, navParams, propertiesService, viewController, modalController, leasingDataService, compService, imagesService, companiesService, contactsService, typesService) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.logsService = logsService;
        _this.analyticsService = analyticsService;
        _this.propertiesService = propertiesService;
        _this.modalController = modalController;
        _this.leasingDataService = leasingDataService;
        _this.compService = compService;
        _this.imagesService = imagesService;
        _this.companiesService = companiesService;
        _this.contactsService = contactsService;
        _this.typesService = typesService;
        _this.selectedRecord = {
            space: new PropertyModel(),
            parentProperty: new PropertyModel()
        };
        _this.Modes = SpaceModalMode;
        _this.records = [];
        _this.leases = null;
        _this.currentLease = null;
        _this.isloadingLeases = false;
        _this.photos = null;
        _this.modalUrls = new Map([
            [SpaceModalMode.VIEW, '/:spaceId']
        ]);
        _this.subscriptions = [];
        _this.mode = navParams.get('mode') || SpaceModalMode.LIST;
        _this.selectedRecord = _this.navParams.get('selectedRecord') || _this.selectedRecord;
        _this.isQuickAdd = _this.navParams.get('isQuickAdd') || false;
        return _this;
    }
    SpaceModal.prototype.ngOnInit = function () {
        this.getSubscriptions();
    };
    SpaceModal.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    SpaceModal.prototype.ionViewWillEnter = function () {
        _super.prototype.ionViewWillEnter.call(this);
        if (this.mode === this.Modes.VIEW) {
            this.view(this.selectedRecord);
        }
    };
    Object.defineProperty(SpaceModal.prototype, "shouldShowBackArrow", {
        get: function () {
            var shouldShow = false;
            if (this.initialMode !== this.Modes.EDIT) {
                // We have a records list and we are in VIEW mode
                if (this.records.length && this.mode === this.Modes.VIEW) {
                    shouldShow = true;
                    // We have a company with an id and are in EDIT mode
                }
                else if (this.selectedRecord &&
                    this.selectedRecord.space &&
                    this.selectedRecord.space._id &&
                    this.mode === this.Modes.EDIT) {
                    shouldShow = true;
                }
            }
            return shouldShow;
        },
        enumerable: true,
        configurable: true
    });
    SpaceModal.prototype.delete = function () {
        this.propertiesService.delete([
            this.selectedRecord.space._id
        ]);
        this.dismiss();
    };
    SpaceModal.prototype.save = function (addNew) {
        return __awaiter(this, void 0, void 0, function () {
            var formResult, savedSpace;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formResult = this.form.save();
                        if (!formResult || !formResult.space) {
                            return [2 /*return*/];
                        }
                        this.scrollToTop();
                        this.form.isSaving = true;
                        return [4 /*yield*/, this.propertiesService.upsert(formResult.space)];
                    case 1:
                        savedSpace = _a.sent();
                        savedSpace.parentProperty = this.selectedRecord.parentProperty;
                        if (this.isQuickAdd) {
                            return [2 /*return*/, this.viewController.dismiss(savedSpace)];
                        }
                        this.form.isSaving = false;
                        if (addNew) {
                            this.addAnother(savedSpace);
                            this.dismiss();
                        }
                        else if (this.initialMode === this.Modes.EDIT) {
                            this.dismiss();
                        }
                        else {
                            this.selectedRecord.space = savedSpace;
                            this.view(this.selectedRecord);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    SpaceModal.prototype.addAnother = function (space) {
        return __awaiter(this, void 0, void 0, function () {
            var spaceModal;
            return __generator(this, function (_a) {
                spaceModal = this.createSpaceModal(space);
                return [2 /*return*/, spaceModal.present({ updateUrl: false })];
            });
        });
    };
    SpaceModal.prototype.createSpaceModal = function (space) {
        return this.modalController.create(SpaceModal, {
            mode: this.Modes.EDIT,
            selectedRecord: {
                space: null,
                parentProperty: space.parentProperty
            }
        });
    };
    SpaceModal.prototype.view = function (record) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.selectedRecord = record;
                this.loadPhotos();
                this.fetchLeases();
                this.setMode(this.Modes.VIEW);
                return [2 /*return*/];
            });
        });
    };
    SpaceModal.prototype.fetchLeases = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, ex_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isloadingLeases = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = this;
                        return [4 /*yield*/, this.leasingDataService.getLeasesForSpace(this.selectedRecord.space)];
                    case 2:
                        _a.leases = _b.sent();
                        if (this.leases && this.leases.length) {
                            this.currentLease = this.leases[0].comp || null;
                            this.attachLeaseData(this.currentLease);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _b.sent();
                        this.leases = null;
                        this.logsService.exception('Failed to fetch leases for space', ex_1);
                        return [3 /*break*/, 4];
                    case 4:
                        this.isloadingLeases = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SpaceModal.prototype.showAddLease = function () {
        this.analyticsService.logEvent('Spaces Clicked Add Lease From Stacking Plan');
        var comp = new CompModel({
            leasedProperty: this.selectedRecord.parentProperty,
            leasedPropertyId: this.selectedRecord.parentProperty._id,
            spaceProperty: this.selectedRecord.space,
            spacePropertyId: this.selectedRecord.space._id
        });
        var selectedRecord = {
            comp: comp,
            property: this.selectedRecord.parentProperty,
            space: this.selectedRecord.space,
            previousLeases: []
        };
        var modal = this.modalController.create(LeaseModal, {
            mode: LeaseModalMode.EDIT,
            selectedRecord: selectedRecord
        });
        return modal.present({
            updateUrl: false
        });
    };
    SpaceModal.prototype.showLease = function (lease) {
        var modal = this.modalController.create(LeaseModal, {
            mode: LeaseModalMode.VIEW,
            selectedRecord: lease
        });
        return modal.present({
            updateUrl: false
        });
    };
    /**
     * reload leases if comps are deleted or updated
     * reload leases if spaces are deleted or updated
     */
    SpaceModal.prototype.getSubscriptions = function () {
        var _this = this;
        this.subscriptions.push(this.compService.onUpserted.subscribe(function () { return _this.fetchLeases(); }, this.logsService.error), this.compService.onDeleted.subscribe(function () { return _this.fetchLeases(); }, this.logsService.error), this.propertiesService.onUpserted.subscribe(function () { return _this.fetchLeases(); }, this.logsService.error), this.propertiesService.onDeleted.subscribe(function () { return _this.fetchLeases(); }, this.logsService.error));
    };
    SpaceModal.prototype.attachLeaseData = function (lease) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (lease) {
                    this.setTenantCompany(lease);
                    this.setLandlordCompany(lease);
                    this.setLandlordContact(lease);
                    this.setTenantContact(lease);
                }
                return [2 /*return*/];
            });
        });
    };
    SpaceModal.prototype.openContactModal = function (contact) {
        return __awaiter(this, void 0, void 0, function () {
            var types, modal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        types = _a.sent();
                        modal = this.modalController.create(ContactDetailsModal, {
                            mode: ContactDetailsModalMode.VIEW,
                            types: Object.keys(types.contactTypes),
                            selectedContact: contact
                        });
                        return [2 /*return*/, modal.present({
                                updateUrl: false
                            })];
                }
            });
        });
    };
    SpaceModal.prototype.openCompanyModal = function (company) {
        return __awaiter(this, void 0, void 0, function () {
            var modal;
            return __generator(this, function (_a) {
                modal = this.modalController.create(CompanyDetailsModal, {
                    mode: CompanyDetailsModalMode.VIEW,
                    selectedCompany: company
                });
                return [2 /*return*/, modal.present({
                        updateUrl: false
                    })];
            });
        });
    };
    SpaceModal.prototype.setTenantCompany = function (lease) {
        return __awaiter(this, void 0, void 0, function () {
            var companies;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!lease.tenantCompany && lease.tenantCompanyId))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.companiesService.in([lease.tenantCompanyId])];
                    case 1:
                        companies = _a.sent();
                        lease.tenantCompany = companies.length && companies[0] || null;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    SpaceModal.prototype.setTenantContact = function (lease) {
        return __awaiter(this, void 0, void 0, function () {
            var contacts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!lease.tenantContact && lease.tenantContactId))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.contactsService.in([lease.tenantContactId])];
                    case 1:
                        contacts = _a.sent();
                        lease.tenantContact = contacts.length && contacts[0] || null;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    SpaceModal.prototype.setLandlordCompany = function (lease) {
        return __awaiter(this, void 0, void 0, function () {
            var companies;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!lease.landlordCompany && lease.landlordCompanyId))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.companiesService.in([lease.landlordCompanyId])];
                    case 1:
                        companies = _a.sent();
                        lease.landlordCompany = companies.length && companies[0] || null;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    SpaceModal.prototype.setLandlordContact = function (lease) {
        return __awaiter(this, void 0, void 0, function () {
            var contacts;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(!lease.landlordContact && lease.landlordContactId))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.contactsService.in([lease.landlordContactId])];
                    case 1:
                        contacts = _a.sent();
                        lease.landlordContact = contacts.length && contacts[0] || null;
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    SpaceModal.prototype.loadPhotos = function () {
        return __awaiter(this, void 0, void 0, function () {
            var record, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.selectedRecord && this.selectedRecord.space))
                            return [3 /*break*/, 2];
                        record = this.selectedRecord.space;
                        _a = this;
                        return [4 /*yield*/, this.imagesService.byObjectId(record._id)];
                    case 1:
                        _a.photos = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return SpaceModal;
}(BaseSlideModal));
export { SpaceModal };
