var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AutoCompleteComponent } from '@apto/autocomplete';
import { CompanyModel } from '@apto/models';
import { TypesService, CompaniesService } from '@apto/services';
import { PHONE_NUMBER_REGEX, POSTAL_REGEX, validationMessages } from '@apto/shared/constants';
import './edit-company-form.scss';
var EditCompanyForm = /** @class */ /*@__PURE__*/ (function () {
    function EditCompanyForm(companiesService, formBuilder, typesService) {
        this.companiesService = companiesService;
        this.formBuilder = formBuilder;
        this.typesService = typesService;
        this.CompanyModel = CompanyModel;
        this.addedProperties = {};
        this.arePropertiesLoaded = false;
        this.isSaving = false;
        this.originalProperties = {};
        this.picklistOptions = {};
        this.removedProperties = {};
        this.types = [];
        this.validationMessages = validationMessages;
    }
    EditCompanyForm.prototype.ngOnInit = function () {
        this.getPicklistOptions();
        this.getTypes();
        this.setupForm();
    };
    EditCompanyForm.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (!this.arePropertiesLoaded && this.company.properties) {
            this.arePropertiesLoaded = true;
            this.company.properties.forEach(function (property) {
                _this.originalProperties[property._id] = property;
            });
        }
        if (changes.company &&
            changes.company.previousValue &&
            changes.company.previousValue._id !== changes.company.currentValue._id) {
            this.setupForm();
        }
    };
    EditCompanyForm.prototype.setupForm = function () {
        this.companyForm = this.formBuilder.group({
            fax: this.formBuilder.group({
                label: this.company.fax.label,
                number: [this.company.fax.number, Validators.pattern(PHONE_NUMBER_REGEX)]
            }),
            name: [this.company.name, Validators.required],
            phone: this.formBuilder.group({
                label: this.company.phone.label,
                number: [this.company.phone.number, Validators.pattern(PHONE_NUMBER_REGEX)]
            }),
            type: [this.company.type, Validators.required],
            category: this.company.category,
            description: this.company.description,
            website: this.formBuilder.group({
                label: this.company.website.label,
                url: this.company.website.url
            }),
            billingAddress: this.formBuilder.group({
                street: this.company.billingAddress.street,
                city: this.company.billingAddress.city,
                state: this.company.billingAddress.state,
                postalCode: [this.company.billingAddress.postalCode, Validators.pattern(POSTAL_REGEX)],
                country: this.company.billingAddress.country
            }),
            shippingAddress: this.formBuilder.group({
                street: this.company.shippingAddress.street,
                city: this.company.shippingAddress.city,
                state: this.company.shippingAddress.state,
                postalCode: [this.company.shippingAddress.postalCode, Validators.pattern(POSTAL_REGEX)],
                country: this.company.shippingAddress.country
            })
        });
    };
    EditCompanyForm.prototype.save = function () {
        var _this = this;
        if (this.companyForm.valid) {
            Object.assign(this.company, this.companyForm.value);
            return {
                company: this.company,
                addedProperties: this.addedProperties,
                removedProperties: this.removedProperties
            };
        }
        else {
            var phoneForm_1 = this.companyForm.controls.phone;
            var faxForm_1 = this.companyForm.controls.fax;
            Object.keys(this.companyForm.controls).forEach(function (fieldKey) {
                _this.companyForm.controls[fieldKey].markAsDirty();
            });
            Object.keys(phoneForm_1.controls).forEach(function (fieldKey) {
                phoneForm_1.controls[fieldKey].markAsDirty();
            });
            Object.keys(faxForm_1.controls).forEach(function (fieldKey) {
                faxForm_1.controls[fieldKey].markAsDirty();
            });
        }
    };
    EditCompanyForm.prototype.addProperty = function (property) {
        if (!this.company.properties) {
            this.company.properties = [];
        }
        if (!this.addedProperties[property._id] && !this.originalProperties[property._id]) {
            this.addedProperties[property._id] = property;
            this.company.properties.push(property);
        }
        else if (this.originalProperties[property._id] && this.removedProperties[property._id]) {
            delete this.removedProperties[property._id];
            this.company.properties.push(property);
        }
        this.propertySearchbar.clearValue();
    };
    EditCompanyForm.prototype.removeContact = function (contact) {
        this.company.contacts = this.company.contacts.filter(function (existingContact) { return existingContact._id !== contact._id; });
    };
    EditCompanyForm.prototype.removeProperty = function (property) {
        if (this.originalProperties[property._id]) {
            this.removedProperties[property._id] = property;
        }
        else if (this.addedProperties[property._id]) {
            delete this.addedProperties[property._id];
        }
        this.company.properties = this.company.properties.filter(function (existingProperties) { return existingProperties._id !== property._id; });
    };
    EditCompanyForm.prototype.getPicklistOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.companiesService.getPicklistOptions(['category'])];
                    case 1:
                        _a.picklistOptions = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EditCompanyForm.prototype.getTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var types;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        types = _a.sent();
                        this.types = Object.keys(types.companyTypes);
                        return [2 /*return*/];
                }
            });
        });
    };
    return EditCompanyForm;
}());
export { EditCompanyForm };
