import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { debounceTime } from 'rxjs/operators';
import { AfterViewChecked, AfterViewInit, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from 'ionic-angular';
import { CompModel } from '@apto/models';
import { AnalyticsService, CompaniesService, FeaturesService, TypesService } from '@apto/services';
import { AppcuesService, MetricsService } from '@apto/shared/services';
import { AuthService, LogsService } from '@apto/ionic-lib/services';
import { LIST_VIEW_MAX_RECORDS } from '@apto/shared/constants';
import { BaseListPage } from '../base-list-page/base-list-page';
import { CompanyDetailsModal, CompanyDetailsModalMode } from '../../modals';
// The panels must be imported after the modals to prevent a circular dependency on the company-details-modal
import { FilterPanel, SearchFilter, SelectFilter, SelectMapFilter } from '../../components';
import './companies.scss';
import { DateRangeFilter } from '@apto/pn';
var FORM_DEBOUNCE_TIME = 500;
var CompaniesPage = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(CompaniesPage, _super);
    function CompaniesPage(analyticsService, appcuesService, companiesService, featuresService, logsService, metricsService, route, authService, modalController, typesService) {
        var _this = _super.call(this) || this;
        _this.analyticsService = analyticsService;
        _this.appcuesService = appcuesService;
        _this.companiesService = companiesService;
        _this.featuresService = featuresService;
        _this.logsService = logsService;
        _this.metricsService = metricsService;
        _this.route = route;
        _this.authService = authService;
        _this.modalController = modalController;
        _this.typesService = typesService;
        _this.filterChangeEvent = new EventEmitter();
        _this.isInitializing = false;
        _this.searchesInFlight = 0;
        _this.searchStartedEvent = new EventEmitter();
        _this.searchFinishedEvent = new EventEmitter();
        _this.LIST_VIEW_MAX_RECORDS = LIST_VIEW_MAX_RECORDS;
        _this.leasingEnabled = false;
        _this.breakdown = '';
        _this.header = 'Recent Companies';
        _this.items = [];
        _this.listType = 'recent';
        _this.sortMap = {
            'A-Z': { name: 1 },
            'Z-A': { name: -1 }
        };
        _this.totalCompanies = 0;
        _this.subscriptions = [];
        _this.hasRenderedLeasing = false;
        _this.sortKeys = Object.keys(_this.sortMap);
        _this.selectedSortKey = _this.sortKeys[0];
        _this.subscriptions.push(_this.authService.onSetUser.subscribe(function (user) {
            if (!user) {
                return;
            }
            _this.leasingEnabled = _this.featuresService.isFeatureEnabled(_this.featuresService.FEATURES.LEASING);
        }, _this.logsService.error));
        return _this;
    }
    CompaniesPage.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var companyId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.metricsService.start('Companies Page');
                        companyId = this.route.snapshot.paramMap.get('companyId');
                        if (companyId) {
                            this.metricsService.setRouteName('/companies/:companyId');
                        }
                        else {
                            this.metricsService.setRouteName('/companies');
                        }
                        this.createSearchFilterPanel();
                        this.createCompanyFilterPanel();
                        this.subscriptions.push(this.companiesService.onDeleted.subscribe(function () { return _this.updateCompanies(); }));
                        this.subscriptions.push(this.companiesService.onUpserted.subscribe(function () { return _this.updateCompanies(); }));
                        this.subscriptions.push(this.filterChangeEvent.pipe(debounceTime(FORM_DEBOUNCE_TIME)).subscribe(function () { return _this.updateCompanies(); }));
                        this.subscriptions.push(this.searchStartedEvent.subscribe(function () { _this.searchStarted(); }));
                        this.subscriptions.push(this.searchFinishedEvent.subscribe(function () { _this.searchFinished(); }));
                        this.companiesService.count().then(function (count) {
                            _this.totalCompanies = count;
                        });
                        return [4 /*yield*/, this.updateCompanies()];
                    case 1:
                        _a.sent();
                        this.appcuesService.start();
                        this.metricsService.stop('Companies Page');
                        return [2 /*return*/];
                }
            });
        });
    };
    CompaniesPage.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.companyId$ = this.route.paramMap.subscribe(function (paramMap) {
            return __awaiter(_this, void 0, void 0, function () {
                var companies;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!paramMap.get('companyId')) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, this.companiesService.in([paramMap.get('companyId')])];
                        case 1:
                            companies = _a.sent();
                            if (companies.length > 0) {
                                this.viewCompany(companies[0]);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        });
    };
    /*
        We need to add the leasing filter in a lifecycle event because
        the feature flag check (user has been authenticated) can come in any time after the component has rendered
        and we need to update the view with the filter
    */
    CompaniesPage.prototype.ngAfterViewChecked = function () {
        var _this = this;
        if (this.leasingEnabled && !this.hasRenderedLeasing) {
            this.hasRenderedLeasing = true;
            // We need this to schedule the updates to be in the same cycle
            setTimeout(function () {
                _this.createLeaseFilterPanel();
            });
        }
    };
    CompaniesPage.prototype.ngOnDestroy = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.companyId$.unsubscribe();
                this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
                this.analyticsService.logEvent('Companies Tab Load');
                return [2 /*return*/];
            });
        });
    };
    CompaniesPage.prototype.searchStarted = function () {
        this.searchesInFlight++;
        this.isInitializing = true;
    };
    CompaniesPage.prototype.searchFinished = function () {
        this.searchesInFlight--;
        if (this.searchesInFlight <= 0) {
            this.isInitializing = false;
            this.searchesInFlight = 0;
        }
    };
    CompaniesPage.prototype.getCompanies = function () {
        return __awaiter(this, void 0, void 0, function () {
            var params, _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        params = {
                            sort: this.sortMap[this.selectedSortKey],
                            limit: LIST_VIEW_MAX_RECORDS
                        };
                        if (!(this.listType === 'recent'))
                            return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.companiesService.recent(params)];
                    case 1:
                        _a.items = _d.sent();
                        return [3 /*break*/, 6];
                    case 2:
                        params.where = this.getFilterQuery();
                        if (!(this.leasingEnabled && this.leaseFilterPanel && !this.leaseFilterPanel.isEmpty()))
                            return [3 /*break*/, 4];
                        this.analyticsService.logEvent('Leasing Filtered Companies using a lease filter');
                        _b = this;
                        return [4 /*yield*/, this.companiesService.filterByLeaseComps(params)];
                    case 3:
                        _b.items = _d.sent();
                        return [3 /*break*/, 6];
                    case 4:
                        _c = this;
                        return [4 /*yield*/, this.companiesService.fetch(params)];
                    case 5:
                        _c.items = _d.sent();
                        _d.label = 6;
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    CompaniesPage.prototype.addCompany = function () {
        var options = {
            isInitializing: false
        };
        var companyModal = this.modalController.create(CompanyDetailsModal, {
            mode: CompanyDetailsModalMode.EDIT,
            options: options
        }, {
            cssClass: 'large-modal'
        });
        companyModal.present({ updateUrl: false });
    };
    CompaniesPage.prototype.filtersAreEmpty = function () {
        return this.searchFilterPanel.isEmpty() &&
            this.companiesFilterPanel.isEmpty() &&
            (this.leasingEnabled && this.leaseFilterPanel ? this.leaseFilterPanel.isEmpty() : true);
    };
    CompaniesPage.prototype.getBreakdown = function (companies) {
        var breakdown = '';
        var types = {};
        if (companies) {
            companies.forEach(function (company) {
                var type = company.type || 'No type assigned';
                if (!types[type]) {
                    types[type] = 0;
                }
                types[type]++;
            });
        }
        for (var i = 0; i < 3; i++) {
            if (Object.keys(types).length === 0) {
                break;
            }
            var max = Object.keys(types).reduce(function (a, b) {
                return types[a] > types[b] ? a : b;
            });
            var percent = Math.floor(types[max] / companies.length * 100);
            breakdown += percent + "% " + max + ", ";
            delete types[max];
        }
        return breakdown.substring(0, breakdown.length - 2);
    };
    CompaniesPage.prototype.resetFilters = function () {
        var _this = this;
        this.companiesFilterPanel.resetFilters();
        if (this.leasingEnabled) {
            this.leaseFilterPanel.resetFilters();
        }
        ;
        //mydatepicker using setFocus with delay 10ms when we call clearDate method. 
        //For prevent focus hooking in this fields. Set focus to the search field.
        setTimeout(function () {
            _this.searchFilterPanel.resetFilters();
            _this.searchFilterPanel.filters[0].instance.focus();
            _this.updateCompanies();
        }, 15);
    };
    CompaniesPage.prototype.setListType = function (type) {
        this.listType = type;
        if (!this.filtersAreEmpty()) {
            this.resetFilters();
        }
        else {
            this.updateCompanies();
        }
    };
    CompaniesPage.prototype.sortChanged = function (sortKey) {
        this.selectedSortKey = sortKey;
        this.updateCompanies();
        this.currentPageSelected = false;
    };
    CompaniesPage.prototype.updateCompanies = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.searchStartedEvent.emit();
                        this.setListTypeOnEmptyFilter();
                        return [4 /*yield*/, this.getCompanies()];
                    case 1:
                        _a.sent();
                        this.breakdown = this.getBreakdown(this.items);
                        this.searchFinishedEvent.emit();
                        return [2 /*return*/];
                }
            });
        });
    };
    CompaniesPage.prototype.setListTypeOnEmptyFilter = function () {
        if (!this.filtersAreEmpty()) {
            this.listType = 'all';
        }
    };
    CompaniesPage.prototype.viewCompany = function (company) {
        if (!company || !company._id) {
            return;
        }
        var options = {
            isLoading: true
        };
        var companyModal = this.modalController.create(CompanyDetailsModal, {
            mode: CompanyDetailsModalMode.VIEW,
            options: options,
            selectedCompany: company,
            url: {
                params: { companyId: company._id },
                baseHash: '#/companies'
            }
        }, {
            cssClass: 'large-modal'
        });
        companyModal.present({ updateUrl: false });
    };
    CompaniesPage.prototype.getFilterQuery = function () {
        if (this.leasingEnabled) {
            return FilterPanel.getCombinedQuery(this.searchFilterPanel.getQuery(), [
                this.companiesFilterPanel.getQuery(),
                this.leaseFilterPanel.getQuery()
            ]);
        }
        else {
            return FilterPanel.getCombinedQuery(this.searchFilterPanel.getQuery(), [
                this.companiesFilterPanel.getQuery()
            ]);
        }
    };
    CompaniesPage.prototype.createCompanyFilterPanel = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var _a, picklistOptions, types;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4 /*yield*/, Promise.all([
                            this.companiesService.getPicklistOptions(['category']),
                            this.typesService.fetch()
                        ])];
                    case 1:
                        _a = _b.sent(), picklistOptions = _a[0], types = _a[1];
                        this.companiesFilterPanel.add(SelectFilter, {
                            label: 'Company type',
                            queryKey: 'type',
                            optionsList: Object.keys(types.companyTypes)
                        });
                        this.companiesFilterPanel.add(SelectMapFilter, {
                            label: 'Industry',
                            queryKey: 'category',
                            optionsMap: picklistOptions.category
                        });
                        this.subscriptions.push(this.companiesFilterPanel.onQueryChange.subscribe(function (filters) {
                            _this.isInitializing = true;
                            _this.filterChangeEvent.emit();
                        }, function (err) { return _this.logsService.error(err); }));
                        return [2 /*return*/];
                }
            });
        });
    };
    CompaniesPage.prototype.createSearchFilterPanel = function () {
        var _this = this;
        this.searchFilterPanel.add(SearchFilter, {
            placeholder: 'Find a company',
            queryKey: 'name'
        });
        this.subscriptions.push(this.searchFilterPanel.onQueryChange.subscribe(function (filters) {
            _this.isInitializing = true;
            _this.filterChangeEvent.emit();
        }, function (err) { return _this.logsService.error(err); }));
    };
    CompaniesPage.prototype.createLeaseFilterPanel = function () {
        var _this = this;
        this.leaseFilterPanel.add(DateRangeFilter, {
            label: 'Lease expiration date',
            queryKey: 'leaseExpirationDate'
        });
        this.leaseFilterPanel.add(SelectFilter, {
            label: 'Lease type',
            queryKey: 'leaseType',
            optionsList: CompModel.LEASE_TYPES
        });
        this.leaseFilterPanel.add(SelectFilter, {
            label: 'Lease rate type',
            queryKey: 'rentalRateType',
            optionsList: CompModel.RENTAL_RATE_TYPES
        });
        this.subscriptions.push(this.leaseFilterPanel.onQueryChange.subscribe(function (filters) {
            _this.isInitializing = true;
            _this.filterChangeEvent.emit();
        }, function (err) { return _this.logsService.error(err); }));
    };
    return CompaniesPage;
}(BaseListPage));
export { CompaniesPage };
