import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EventEmitter, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES, MAX_UPLOAD_FILE_COUNT, MAX_UPLOAD_FILE_SIZE, MAX_UPLOAD_TOTAL_FILE_SIZE } from '../constants';
import { AppService } from '../app/app-service';
import { BaseService } from '../base/base-service';
import { AlertController } from 'ionic-angular';
var DocumentsService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(DocumentsService, _super);
    function DocumentsService(injector, sanitizer, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.sanitizer = sanitizer;
        _this.alertController = alertController;
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.DOCUMENTS;
        _this.onDisplayError = new EventEmitter();
        _this.model = DocumentModel;
        _this.serviceName = SERVICE_NAMES.DOCUMENTS;
        AppService.onLogout.subscribe(function () {
            _this.cacheService.clear();
        });
        return _this;
    }
    /**
     * Downloads the given Document to the user's computer.
     * @param document The document to download.
     */
    DocumentsService.prototype.download = function (document) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var cachedResult;
            return __generator(this, function (_a) {
                cachedResult = this.cacheService.get(this.serviceName, document._id);
                if (cachedResult) {
                    return [2 /*return*/, cachedResult];
                }
                return [2 /*return*/, this.http.get(this.endpoint + "/" + document._id, { responseType: 'blob' }).pipe(map(function (response) {
                        var shortDataUri = window.URL.createObjectURL(response);
                        var safeShortDataUri = _this.sanitizer.bypassSecurityTrustUrl(shortDataUri);
                        _this.cacheService.add(_this.serviceName, document._id, safeShortDataUri);
                        _this.saveData(response, document.name, response.type);
                        return safeShortDataUri;
                    }), catchError(function (ex) {
                        if (ex) {
                            _this.onLogException.emit({ message: "Failed to fetch for service " + _this.serviceName + ".", ex: ex });
                        }
                        return observableThrowError(ex);
                    }))
                        .toPromise()];
            });
        });
    };
    /**
     * @deprecated upload() has been deprecated and replaced by uploadFiles().
     * Uploads a document to Salesforce and attaches it to a specified parent.
     * @param parentId The ID of the parent to attach the document to.
     * @param fileName The name of the file.
     * @param data The Base64-encoding of the file's contents.
     */
    DocumentsService.prototype.upload = function (parentId, fileName, data) {
        return __awaiter(this, void 0, void 0, function () {
            var method, path, params;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        method = 'post';
                        path = '';
                        params = { data: data, fileName: fileName, parentId: parentId };
                        return [4 /*yield*/, this.request(method, path, params)];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    /**
     * Uploads one or more documents to Salesforce and attaches it to a specified parent.
     * @param parentId The ID of the parent to attach the document to.
     * @param files The HTML file objects.
     */
    DocumentsService.prototype.uploadFiles = function (parentId, files) {
        return __awaiter(this, void 0, void 0, function () {
            var formData, i, file;
            return __generator(this, function (_a) {
                if (!files || !this.validateFiles(files)) {
                    return [2 /*return*/];
                }
                formData = new FormData();
                formData.append('parentId', parentId);
                for (i = 0; i < files.length; i++) {
                    file = files.item(i);
                    formData.append(i.toString(), file, file.name);
                }
                return [2 /*return*/, this.http.post(this.endpoint + "/upload", formData).toPromise()];
            });
        });
    };
    /**
     * Downloads a file to the user's computer.
     * @param data The contents of the file.
     * @param fileName The name of the file.
     * @param contentType The file MIME type.
     */
    DocumentsService.prototype.saveData = function (data, fileName, contentType) {
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        var blob = new Blob([data], { type: contentType });
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    };
    DocumentsService.prototype.showError = function (message) {
        this.onDisplayError.emit(message);
    };
    DocumentsService.prototype.validateFiles = function (files) {
        if (files.length > MAX_UPLOAD_FILE_COUNT) {
            this.showError("Error: You can only upload " + MAX_UPLOAD_FILE_COUNT + " files at a time.");
            return false;
        }
        var maxFileSize = MAX_UPLOAD_FILE_SIZE * 1024 * 1024;
        var maxTotalFileSize = MAX_UPLOAD_TOTAL_FILE_SIZE * 1024 * 1024;
        var totalFileSize = 0;
        for (var i = 0; i < files.length; i++) {
            var file = files.item(i);
            if (file.size > maxFileSize) {
                this.showError("Error: The file " + file.name + " is too large. " +
                    ("The max file size is " + MAX_UPLOAD_FILE_SIZE + " MB."));
                return false;
            }
            totalFileSize += file.size;
        }
        if (totalFileSize > maxTotalFileSize) {
            this.showError("Error: You can only upload " + MAX_UPLOAD_TOTAL_FILE_SIZE + " MB of files at once.\n"
                + 'Please select fewer files and try again.');
            return false;
        }
        return true;
    };
    return DocumentsService;
}(BaseService));
export { DocumentsService };
