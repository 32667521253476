<ion-item class="previous-lease-item"
          detail-push>
      <ion-icon circle
                material-icons
                name="location_city"
                class="action-circle {{leaseMarkerClass}}"
                item-left></ion-icon>
      <div class="previous-lease-item--primaryInfo">
            <strong *ngIf="lease.comp && lease.comp.name"
                    class="previous-lease-item--info"
                    data-automation="previous-lease-name">{{ lease.comp.name }}</strong>
            <span *ngIf="cost"
                  class="previous-lease-item--info"
                  data-automation="previous-lease-cost">{{ cost }}</span>
      </div>
      <div class="previous-lease-item--secondaryInfo">
            <strong *ngIf="leaseExpiration"
                    class="previous-lease-item--info previous-lease-item-expiration-date {{ leaseExpirationClass }}"
                    data-automation="previous-lease-expiration-date">Lease Exp. {{ leaseExpiration }}</strong>
            <span *ngIf="companyName"
                  class="previous-lease-item--info"
                  data-automation="previous-lease-company">{{ companyName }}</span>
            <span *ngIf="contactName"
                  class="previous-lease-item--info"
                  data-automation="previous-lease-contact">{{ contactName }}</span>
            <span *ngIf="squareFootage"
                  class="previous-lease-item--info"
                  data-automation="previous-lease-sqft">{{ squareFootage }} sqft</span>
      </div>
</ion-item>
