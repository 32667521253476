<ion-header class="modal-header manage-deal-team-modal--header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton();"
                    data-automation="modalBackButton">
                <ion-icon name="arrow-back"></ion-icon>
            </button>
        </ion-buttons>

        <ion-title>Manage Parties to the Transaction</ion-title>

        <ion-buttons end>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismissWithoutSaving()"
                    data-automation="modal-dismiss-button">
                <ion-icon name="close"></ion-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal manage-deal-team-modal">
    <ion-slides (ionSlideWillChange)="scrollToTop()">
        <ion-slide>
            <ion-content>
                <ion-card>
                    <ion-card-header>
                        Related contacts
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="addDealParty($event, selectedRecord);"></add-record>
                    </ion-card-header>
                    <ion-list>
                        <ion-item *ngFor="let dealParty of dealParties">
                            <ion-icon class="grey-color-circle"
                                      material-icons
                                      name="account_circle"
                                      item-left
                                      large>
                            </ion-icon>
                            <span class="manage-deal-team-modal--name dark-text"
                                  *ngIf="dealParty.contact?.fullName">{{dealParty.contact.fullName}}</span>
                            <span class="link manage-deal-team-modal--contact-role"
                                  (click)="changeOwnerStatus($event, dealParty);">
                                <span *ngIf="dealParty.contactRole ==='Owner' && dealParty.isPrimaryContact; else dealPartyRole">Primary Owner</span>
                                <ng-template #dealPartyRole>
                                    <span>{{dealParty.role || '-- No team role selected --'}}</span>
                                </ng-template>
                            </span>
                            <p class="manage-deal-team-modal--text"
                               *ngIf="dealParty.contact?.type">{{dealParty.contact.type}}</p>
                            <p class="manage-deal-team-modal--text"
                               *ngIf="dealParty.contact?.title">{{dealParty.contact.title}}</p>
                            <button class="manage-deal-team-modal--delete-button"
                                    item-right
                                    ion-button
                                    icon-only
                                    clear
                                    (click)="removeDealParty(dealParty)">
                                <ion-icon class="close-circle"
                                          circle
                                          name="close"></ion-icon>
                            </button>
                        </ion-item>

                    </ion-list>
                    <ion-auto-complete class="manage-deal-team-modal--contact-autocomplete"
                                       #contactAutocomplete
                                       [dataProvider]="contactsService"
                                       [options]="{ placeholder : 'Find contact' }"
                                       (itemSelected)="addDealParty($event, selectedRecord);"></ion-auto-complete>
                </ion-card>
                <busy-panel *ngIf="isInitializing"></busy-panel>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>