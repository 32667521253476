var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter } from '@angular/core';
import { ModalController, LoadingController } from 'ionic-angular';
import { CallListModel } from '@apto/models';
import { AnalyticsService, ContactsService, CallListsService } from '@apto/services';
import { CallListModal, CallListModalMode } from '@apto/shared/modals';
import './add-call-list-members-from-companies-card.scss';
var initialErrorState = {
    hasError: false,
    errorMessage: ''
};
var AddCallListMembersFromCompaniesCard = /** @class */ /*@__PURE__*/ (function () {
    function AddCallListMembersFromCompaniesCard(analyticsService, callListsService, contactsService, modalController, loadingController) {
        var _this = this;
        this.analyticsService = analyticsService;
        this.callListsService = callListsService;
        this.contactsService = contactsService;
        this.modalController = modalController;
        this.loadingController = loadingController;
        this.companies = [];
        this.allCallLists = [];
        this.isInitializingCallLists = true;
        this.onClearAll = new EventEmitter();
        this.onAddedToList = new EventEmitter();
        this.onError = new EventEmitter();
        this.CallListModel = CallListModel;
        this.errorState = initialErrorState;
        this.subscriptions = [];
        this.onNewCallListSave = function () {
            _this.showLoading();
        };
        this.getContactLists();
    }
    AddCallListMembersFromCompaniesCard.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.callListsService.onUpserted.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.callListsService.startPolling();
                    return [2 /*return*/];
                });
            });
        }));
        this.subscriptions.push(this.callListsService.onMembersAdded.subscribe(function (data) {
            return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.getContactLists(true)];
                        case 1:
                            _a.sent();
                            this.hideLoading();
                            this.onListAddedSuccess();
                            return [2 /*return*/];
                    }
                });
            });
        }));
    };
    AddCallListMembersFromCompaniesCard.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        this.hideLoading();
    };
    AddCallListMembersFromCompaniesCard.prototype.showLoading = function () {
        this.loading = this.loadingController.create({
            content: 'Building list... Please wait.',
            cssClass: 'app-loading',
            showBackdrop: true
        });
        this.loading.present();
    };
    AddCallListMembersFromCompaniesCard.prototype.hideLoading = function () {
        if (this.loading) {
            this.loading.dismiss();
            this.loading = null;
        }
    };
    AddCallListMembersFromCompaniesCard.prototype.onClearAllClick = function () {
        this.resetErrorState();
        this.onClearAll.emit();
    };
    AddCallListMembersFromCompaniesCard.prototype.getContactsFromCompanies = function () {
        return this.contactsService.byCompanyIDs(this.companies.map(function (company) { return company._id; }));
    };
    AddCallListMembersFromCompaniesCard.prototype.onExistingCallListSelect = function (callListName) {
        return __awaiter(this, void 0, void 0, function () {
            var contacts, selectedCallList, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.showLoading();
                        return [4 /*yield*/, this.getContactsFromCompanies()];
                    case 1:
                        contacts = _a.sent();
                        selectedCallList = this.allCallLists.find(function (callList) { return callList.name === callListName; });
                        _a.label = 2;
                    case 2:
                        _a.trys.push([2, 4, , 5]);
                        return [4 /*yield*/, this.callListsService.addMembersToList(selectedCallList, contacts)];
                    case 3:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        this.hideLoading();
                        this.onListAddedError(error_1);
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    AddCallListMembersFromCompaniesCard.prototype.onNewCallListSelect = function () {
        return __awaiter(this, void 0, void 0, function () {
            var contacts, newCallList, callListModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.analyticsService.logEvent('Clicked Build A New List From Companies');
                        return [4 /*yield*/, this.getContactsFromCompanies()];
                    case 1:
                        contacts = _a.sent();
                        newCallList = new CallListModel({
                            configJson: {},
                            members: [],
                            type: CallListModel.TYPE.CONTACT_GROUP_LIST
                        });
                        callListModal = this.modalController.create(CallListModal, {
                            addedContacts: contacts,
                            callList: newCallList,
                            mode: CallListModalMode.EDIT,
                            callLists: this.allCallLists,
                            onSaveSuccess: this.onNewCallListSave
                        }, {
                            cssClass: 'large-modal'
                        });
                        callListModal.present({ updateUrl: false });
                        return [2 /*return*/];
                }
            });
        });
    };
    AddCallListMembersFromCompaniesCard.prototype.onListAddedSuccess = function () {
        this.resetErrorState();
        this.onClearAll.emit();
        this.onAddedToList.emit();
    };
    AddCallListMembersFromCompaniesCard.prototype.onListAddedError = function (error) {
        this.errorState = {
            hasError: true,
            errorMessage: "\nWe cannot add " + error.amountOver + " contacts to the " + error.callList.name + " Call List with " + error.callList.members.length + " contacts.\n\nYou will exceed the limit of " + CallListModel.CALL_LIST_MEMBER_LIMIT + " contacts per call list.\n\nFeel free to create a new Call List or remove contacts to then add to this list."
        };
        this.onError.emit(error);
    };
    AddCallListMembersFromCompaniesCard.prototype.getContactLists = function (force) {
        if (force === void 0) {
            force = false;
        }
        return __awaiter(this, void 0, void 0, function () {
            var results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isInitializingCallLists = true;
                        return [4 /*yield*/, this.callListsService.fetch({
                                sort: {
                                    name: 'asc'
                                }
                            }, true, force)];
                    case 1:
                        results = _a.sent();
                        this.allCallLists = results.filter(function (callList) {
                            return !callList.isAptoGenerated && !callList.isLegacy && callList.isLessThanMemberLimit;
                        });
                        this.isInitializingCallLists = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    AddCallListMembersFromCompaniesCard.prototype.resetErrorState = function () {
        this.errorState = initialErrorState;
    };
    return AddCallListMembersFromCompaniesCard;
}());
export { AddCallListMembersFromCompaniesCard };
