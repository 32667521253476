<ion-card>
    <ion-card-header *ngIf="!isInitializing">
        {{title}}
    </ion-card-header>

    <ion-card-content
        *ngIf="!isInitializing"
        [innerHTML]="text | formattedText">
    </ion-card-content>

    <ion-item *ngIf="!text"
              class="empty-card">
        <ion-icon name="description"
                  material-icons
                  circle
                  small
                  color="white"
                  item-left></ion-icon>
        Add description
    </ion-item>

    <busy-panel *ngIf="isInitializing"></busy-panel>
</ion-card>