<ion-content class="field-check-modal">
    <div *ngIf="isAptoMissing">
        <p>
            Apto is either not installed or expired for your org.
        </p>
    </div>

    <div *ngIf="!isAptoMissing && isUpgradeRequired">
        <p>
            A software update is needed to display all your ownership information.
            Apto version 3.4 or above is required.
        </p>
    </div>

    <div *ngIf="!isUpgradeRequired">
        <p *ngIf="didFixFail"
           color="error">
            Unable to automatically fix permissions. Please have an administrator assign these permissions for you.
        </p>

        <p>Apto needs to update your profile so that you can view the following:</p>
        <div class="scroll-bar">
            <div class="scroller">
                <div *ngIf="missingObjects && missingObjects.length">
                    <h5>Objects</h5>
                    <ul>
                        <li *ngFor="let missingObject of missingObjects"
                            class="missing-item">{{missingObject}}</li>
                    </ul>
                </div>

                <div *ngIf="missingFields && missingFields.length">
                    <h5>Fields</h5>
                    <ul>
                        <li *ngFor="let missingField of missingFields"
                            class="missing-item">{{missingField}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</ion-content>

<ion-footer class="modal-footer">
    <ion-navbar>
        <ion-buttons end>
            <button *ngIf="!isUpgradeRequired && !didFixFail"
                    ion-button
                    clear
                    item-right
                    class="action"
                    color="action"
                    (click)="tryFix();">
                TRY TO FIX IT
            </button>
            <button *ngIf="!isAptoMissing"
                    ion-button
                    clear
                    item-right
                    class="action"
                    color="action"
                    (click)="close();">
                CLOSE
            </button>
            <button *ngIf="isAptoMissing"
                    ion-button
                    clear
                    item-right
                    class="action"
                    color="action"
                    (click)="openDemoPage();">
                LEARN MORE
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-footer>
