import { PropertyModel } from '@apto/models';
import './property-summary-card.scss';
import { PopoverController } from 'ionic-angular';
import { FeaturesService } from '@apto/services/features/features-service';
import { AttomDataPointsPopover } from '@apto/pn/components/attom-data-points-popover/attom-data-points-popover';
import { FEATURES } from '@apto/services/constants';
var PropertySummaryCard = /** @class */ /*@__PURE__*/ (function () {
    function PropertySummaryCard(popoverController, featuresService) {
        this.popoverController = popoverController;
        this.featuresService = featuresService;
        this.isShownOnMap = true;
        this.attomFeature = FEATURES.ATTOM_DATA_POINTS;
    }
    PropertySummaryCard.prototype.attomClick = function (ev) {
        if (this.featuresService.isFeatureEnabled(FEATURES.ATTOM_DATA_POINTS)) {
            var popover = this.popoverController.create(AttomDataPointsPopover, {
                dataPoints: this.attomDataPointCount,
                ownerDataPoints: this.attomDataOwnerCount,
                property: this.property
            }, { cssClass: 'property-summary-card--attom-data-points-popover' });
            popover.present({ ev: ev });
        }
    };
    return PropertySummaryCard;
}());
export { PropertySummaryCard };
