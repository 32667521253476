<form [formGroup]="contractForm"
      novalidate
      class="edit-contract-form edit-form">
    <ion-card>
        <ion-card-header>Contract details</ion-card-header>
        <ion-card-content>
            <div data-automation="contractForm-name">
                <ion-item>
                    <ion-label stacked
                            required>Contract name</ion-label>
                    <ion-input formControlName="name"
                            required></ion-input>
                </ion-item>
                <div class="field-error"
                    *ngIf="contractForm.controls['name'].dirty &&
                    contractForm.controls['name'].errors &&
                    contractForm.controls['name'].errors['required']">
                    A name is required.
                </div>
                <div class="field-error"
                    *ngIf="contractForm.controls['name'].dirty &&
                            contractForm.controls['name'].errors &&
                            contractForm.controls['name'].errors['maxlength']">
                    Contract name must be 80 characters or less.
                </div>
            </div>

            <select-field *ngIf="!pipelineObject._id"
                          [form]="contractForm"
                          field="type"
                          label="Deal type"
                          [options]="types"
                          [required]="true"
                          [cssClass]="'edit-form--select-popover'"
                          (onChange)="chooseDealType($event)"></select-field>
            <div *ngIf="pipelineObject._id">
                <ion-label stacked>Deal type</ion-label>
                <p>{{dealType}}</p>
            </div>

            <div data-automation="pending-sale-fields"
                 [hidden]="!isPendingSale">
                <div data-automation="seller-company-search">
                    <ion-label stacked>
                        Seller Company
                        <add-record
                                linkText="Create new"
                                objectName="Company"
                                (onRecordCreated)="setQuickAddRecord($event, sellerCompanyAutoComplete, 'sellerCompany', 'sellerCompanyId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #sellerCompany
                                    [dataProvider]="companiesService"
                                    [options]="{ placeholder : 'Find company' }"></ion-auto-complete>
                </div>

                <div data-automation="seller-contact-search">
                    <ion-label stacked>
                        Seller Contact
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="setQuickAddRecord($event, sellerContactAutoComplete, 'sellerContact', 'sellerContactId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #sellerContact
                                    [dataProvider]="contactsService"
                                    [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
                </div>

                <div data-automation="buyer-company-search">
                    <ion-label stacked>
                        Buyer Company
                        <add-record
                                linkText="Create new"
                                objectName="Company"
                                (onRecordCreated)="setQuickAddRecord($event, buyerCompanyAutoComplete, 'buyerCompany', 'buyerCompanyId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #buyerCompany
                                    [dataProvider]="companiesService"
                                    [options]="{ placeholder : 'Find company' }"></ion-auto-complete>
                </div>

                <div data-automation="buyer-contact-search">
                    <ion-label stacked>
                        Buyer Contact
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="setQuickAddRecord($event, buyerContactAutoComplete, 'buyerContact', 'buyerContactId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #buyerContact
                                    [dataProvider]="contactsService"
                                    [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
                </div>
            </div>

            <div data-automation="pending-lease-fields"
                 [hidden]="!isPendingLease">
                <div data-automation="landlord-company-search">
                    <ion-label stacked>
                        Landlord Company
                        <add-record
                                linkText="Create new"
                                objectName="Company"
                                (onRecordCreated)="setQuickAddRecord($event, landlordCompanyAutoComplete, 'landlordCompany', 'landlordCompanyId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #landlordCompany
                                    [dataProvider]="companiesService"
                                    [options]="{ placeholder : 'Find company' }"></ion-auto-complete>
                </div>

                <div data-automation="landlord-contact-search">
                    <ion-label stacked>
                        Landlord Contact
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="setQuickAddRecord($event, landlordContactAutoComplete, 'landlordContact', 'landlordContactId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #landlordContact
                                    [dataProvider]="contactsService"
                                    [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
                </div>

                <div data-automation="tenant-company-search">
                    <ion-label stacked>
                        Tenant Company
                        <add-record
                                linkText="Create new"
                                objectName="Company"
                                (onRecordCreated)="setQuickAddRecord($event, tenantCompanyAutoComplete, 'tenantCompany', 'tenantCompanyId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #tenantCompany
                                    [dataProvider]="companiesService"
                                    [options]="{ placeholder : 'Find company' }"></ion-auto-complete>
                </div>

                <div data-automation="tenant-contact-search">
                    <ion-label stacked>
                        Tenant Contact
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="setQuickAddRecord($event, tenantContactAutoComplete, 'tenantContact', 'tenantContactId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #tenantContact
                                    [dataProvider]="contactsService"
                                    [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
                </div>
            </div>

            <div
                [hidden]="!dealType"
                data-automation="property-search">
                <ion-label stacked>
                    Property
                    <add-record
                            linkText="Create new"
                            objectName="Property"
                            (onRecordCreated)="setQuickAddRecord($event, propertyAutoComplete, 'tempProperty', 'tempPropertyId')">
                    </add-record>
                </ion-label>
                <ion-auto-complete
                    #property
                    (changeValue)="propertyChanged($event)"
                    (itemSelected)="propertySelected($event)"
                    [dataProvider]="propertiesService"
                    [options]="{ placeholder : 'Find property' }"></ion-auto-complete>
            </div>

            <div
                [hidden]="!spacesEnabled || !isPendingLease"
                data-automation="space-search">
                <ion-label stacked>Space</ion-label>
                <ion-auto-complete
                    #space
                    [dataProvider]="spaceAutocompleteService"
                    [options]="{ placeholder : 'Find space' }"></ion-auto-complete>
            </div>

        </ion-card-content>
    </ion-card>
    <div data-automation="additional-information"
         [hidden]="!dealType">
        <ion-card>
            <ion-card-header>Additional information</ion-card-header>
            <ion-card-content>
                <ion-item>
                    <ion-label stacked>Commission amount</ion-label>
                    <ion-input formControlName="commissionAmount"></ion-input>
                </ion-item>
                <div class="field-error"
                    *ngIf="contractForm.get('commissionAmount').dirty &&
                            contractForm.get('commissionAmount').errors &&
                            contractForm.get('commissionAmount').errors.pattern">
                    {{validationMessages.DECIMAL_REGEX}}
                </div>
                <ion-item>
                    <ion-label stacked>Probability (%)</ion-label>
                    <ion-input formControlName="probability"></ion-input>
                </ion-item>
                <div class="field-error"
                    *ngIf="contractForm.get('probability').dirty &&
                            contractForm.get('probability').errors &&
                            contractForm.get('probability').errors.pattern">
                    {{validationMessages.PERCENT_INT_REGEX}}
                </div>
                <ion-item *ngIf="isPendingSale">
                    <ion-label stacked>Contract price</ion-label>
                    <ion-input formControlName="contractPrice"></ion-input>
                </ion-item>
                <div class="field-error"
                    *ngIf="contractForm.get('contractPrice').dirty &&
                            contractForm.get('contractPrice').errors &&
                            contractForm.get('contractPrice').errors.pattern">
                    {{validationMessages.INTEGER_REGEX}}
                </div>
                <ion-item>
                    <ion-label stacked>Deposit</ion-label>
                    <ion-input formControlName="deposit"></ion-input>
                </ion-item>
                <div class="field-error"
                    *ngIf="contractForm.get('deposit').dirty &&
                            contractForm.get('deposit').errors &&
                            contractForm.get('deposit').errors.pattern">
                    {{validationMessages.INTEGER_REGEX}}
                </div>
                <date-field [defaultDate]="contractForm.controls.effectiveDate.value"
                            [form]="contractForm"
                            field="effectiveDate"
                            label="Effective date"
                            [options]="datepickerOptions"></date-field>
                <date-field [defaultDate]="contractForm.controls.contractCloseDate.value"
                            [form]="contractForm"
                            field="contractCloseDate"
                            label="Contract close date"
                            [options]="datepickerOptions"></date-field>
                <div data-automation="buyer-loan-contact-search"
                    [hidden]="isPendingLease">
                    <ion-label stacked>Buyer's loan contact</ion-label>
                    <ion-auto-complete #buyerLoanContact
                                    [dataProvider]="contactsService"
                                    [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
                </div>
                <div data-automation="buyer-attorney-search"
                    [hidden]="isPendingLease">
                    <ion-label stacked>
                        Buyer's attorney
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="setQuickAddRecord($event, buyerAttorneyContactAutoComplete, 'buyerAttorneyContact', 'buyerAttorneyContactId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #buyerAttorneyContact
                                    [dataProvider]="contactsService"
                                    [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
                </div>
                <div data-automation="seller-attorney-search"
                    [hidden]="isPendingLease">
                    <ion-label stacked>
                        Seller's attorney
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="setQuickAddRecord($event, sellerAttorneyContactAutoComplete, 'sellerAttorneyContact', 'sellerAttorneyContactId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #sellerAttorneyContact
                                    [dataProvider]="contactsService"
                                    [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
                </div>
                <div data-automation="title-company-attorney-search"
                    [hidden]="isPendingLease">
                    <ion-label stacked>
                        Title Company / Attorney
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="setQuickAddRecord($event, titleCompanyAttorneyContactAutoComplete, 'titleCompanyAttorneyContact', 'titleCompanyAttorneyContactId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #titleCompanyAttorneyContact
                                    [dataProvider]="contactsService"
                                    [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
                </div>
            </ion-card-content>
        </ion-card>
    </div>

    <photo-scrollview *ngIf="photos.length > 0"
                      [photoUris]="photos"></photo-scrollview>

    <busy-panel *ngIf="isSaving"
                message="Saving contract..."></busy-panel>
</form>