import { EventEmitter } from '@angular/core';
import { RescourNewsModel } from '@apto/models';
import './rescour-news-card.scss';
var RescourNewsCard = /** @class */ /*@__PURE__*/ (function () {
    function RescourNewsCard() {
        this.news = [];
        this.shownNews = [];
        this.isInitializing = false;
        this.onNewsClick = new EventEmitter();
        this.onNewsHover = new EventEmitter();
        this.onNewsToggleVisible = new EventEmitter();
        this.viewedArticles = new Set();
        this.sessionStorageKey = 'aptoRescourNewsViewed';
    }
    RescourNewsCard.prototype.ngOnInit = function () {
        this.viewedArticles = this.getViewedArticles();
    };
    RescourNewsCard.prototype.clickedNewsArticle = function (article) {
        this.onNewsClick.emit(article);
    };
    RescourNewsCard.prototype.toggleVisible = function () {
        this.onNewsToggleVisible.emit();
    };
    RescourNewsCard.prototype.isHovered = function (news, isHovered) {
        if (isHovered) {
            this.hoveredNews = news;
        }
        else {
            this.hoveredNews = null;
        }
        this.onNewsHover.emit({ news: news, isHovered: isHovered });
    };
    RescourNewsCard.prototype.getViewedArticles = function () {
        if (typeof (Storage) !== 'undefined') {
            var viewed = sessionStorage.getItem(this.sessionStorageKey);
            if (viewed) {
                var values = JSON.parse(viewed);
                return new Set(values);
            }
        }
        return new Set();
    };
    RescourNewsCard.prototype.viewedNewsHandler = function (article) {
        this.viewedArticles.add(article.id);
        if (typeof (Storage) !== 'undefined') {
            sessionStorage.setItem(this.sessionStorageKey, JSON.stringify(Array.from(this.viewedArticles)));
        }
    };
    return RescourNewsCard;
}());
export { RescourNewsCard };
