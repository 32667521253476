<ion-item class="call-list-panel"
          no-lines>
    <apto-button
        *ngIf="hasBack"
        kind="secondaryDark"
        type="link"
        item-left
        automation="callListPanelClose"
        (click)="close()">
        <apto-icon icon="arrowBack" inline></apto-icon>
    </apto-button>

    <ng-container *ngIf="callList && !isInitializing">
        <div class="call-list-panel--title">
            {{ callList.name }}
            <span *ngIf="marketingStatus && marketingStatus !== CallListModel.MARKETING_STATUSES.ALL">
                ({{ marketingStatus }})
            </span>
        </div>

        <ion-row class="call-list-panel--highlights">
            <ion-col>
                <apto-icon icon="personCircle" size="2" inline></apto-icon>
                <span>
                    {{ contactsLeft }} of {{ (callList.statusToMembers[marketingStatus] ? callList.statusToMembers[marketingStatus].length
                    : 0) }} Total Contacts
                </span>
            </ion-col>
            <ion-col class="text-align-right">
                <apto-icon icon="time" size="2" inline></apto-icon>
                <span>{{ hoursLeft | hoursToCall}}</span>
            </ion-col>
        </ion-row>

        <ion-row class="call-list-panel--navigation">
            <ion-col col-4>
                <apto-button
                    *ngIf="hasPrevious"
                    [disabled]="!readySwitch"
                    kind="secondaryDark"
                    type="link"
                    float-left
                    automation="callListPanelViewPrevious"
                    (click)="viewPrevious()">
                    <apto-icon icon="arrowBack" inline></apto-icon>
                </apto-button>
            </ion-col>
            <ion-col col-4>
                <apto-button
                    kind="secondaryDark"
                    type="link"
                    automation="callListPanelViewList"
                    (click)="viewList()">
                    VIEW LIST
                </apto-button>
            </ion-col>
            <ion-col col-4>
                <apto-button
                    *ngIf="hasNext"
                    [disabled]="!readySwitch"
                    kind="secondaryDark"
                    type="link"
                    float-right
                    automation="callListPanelViewNext"
                    (click)="viewNext()">
                    <apto-icon icon="arrowForward" inline></apto-icon>
                </apto-button>
            </ion-col>
        </ion-row>
    </ng-container>

    <div *ngIf="!callList && !isInitializing"
        [ngClass]="{ 'has-back': hasBack }">
        <div class="call-list-panel--contact">
            <strong>Contacts</strong>
        </div>
    </div>

    <busy-panel *ngIf="isInitializing"></busy-panel>
</ion-item>
