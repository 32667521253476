var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, EventEmitter, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { Searchbar } from 'ionic-angular';
import { SearchService } from '@apto/ionic-lib/services/';
import './global-search.scss';
var GlobalSearch = /** @class */ /*@__PURE__*/ (function () {
    function GlobalSearch(elementRef, searchService, router) {
        this.elementRef = elementRef;
        this.searchService = searchService;
        this.router = router;
        this.placeholder = 'Quick find';
        this.searchObjects = ['Properties', 'Companies', 'Contacts', 'Tasks', 'Deals'];
        this.showAllResultsLink = true;
        this.showListOnClick = true;
        this.itemSelected = new EventEmitter();
        this.selectionCancelled = new EventEmitter();
        this._showList = false;
        this.minimumSearchLength = 2;
        this.searchResults = {};
        this.totalResults = 0;
        this.searchText = '';
        this.searchIsLoading = false;
    }
    Object.defineProperty(GlobalSearch.prototype, "showList", {
        get: function () {
            return this._showList && this.searchText.length >= this.minimumSearchLength;
        },
        set: function (val) {
            this._showList = val;
        },
        enumerable: true,
        configurable: true
    });
    GlobalSearch.prototype.ngOnInit = function () {
        var _this = this;
        this.searchBarFocus$ = this.searchService.getFocusSearchBar().subscribe(function () {
            _this.searchbar.setFocus();
        });
    };
    GlobalSearch.prototype.ngOnDestroy = function () {
        this.searchBarFocus$.unsubscribe();
    };
    GlobalSearch.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.searchbar.ionFocus.subscribe(function () {
            _this.showList = _this.showListOnClick;
        });
    };
    GlobalSearch.prototype.setSearchValue = function (value) {
        this.searchText = value;
    };
    GlobalSearch.prototype.selectItem = function (item) {
        this.showList = false;
        this.itemSelected.emit({
            item: item
        });
    };
    GlobalSearch.prototype.goToResultsPage = function () {
        if (this.totalResults > 0) {
            this.searchService.setCurrentResults({
                searchResults: this.searchResults,
                totalResults: this.totalResults,
                searchText: this.searchText
            });
            this.router.navigate(['/search']);
            this.showList = false;
        }
    };
    GlobalSearch.prototype.search = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.searchText === '') {
                            this.selectionCancelled.emit();
                        }
                        this.searchIsLoading = true;
                        if (!(this.searchText.length >= this.minimumSearchLength))
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, new Promise(function (resolve) {
                                _this.searchService.getResults({
                                    types: _this.searchObjects.map(function (objectName) { return objectName.toLowerCase(); }),
                                    where: {
                                        searchTerm: _this.searchText
                                    }
                                }).subscribe(resolve);
                            })];
                    case 1:
                        results = _a.sent();
                        this.showList = true;
                        this.searchResults = results;
                        this.totalResults = (results.companies || []).length +
                            (results.contacts || []).length +
                            (results.properties || []).length +
                            (results.tasks || []).length +
                            (results.deals || []).length;
                        this.searchIsLoading = false;
                        return [3 /*break*/, 3];
                    case 2:
                        this.showList = false;
                        this.searchIsLoading = false;
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    GlobalSearch.prototype.reset = function () {
        this.selectionCancelled.emit();
        this.searchText = '';
    };
    GlobalSearch.prototype.clickout = function (event) {
        if (!this.elementRef.nativeElement.contains(event.target)) {
            this.showList = false;
        }
    };
    return GlobalSearch;
}());
export { GlobalSearch };
