import * as newrelic from 'new-relic-browser';
var MetricsService = /** @class */ /*@__PURE__*/ (function () {
    function MetricsService() {
        this.user = {};
    }
    MetricsService.prototype.setInteractionName = function (interactionName) {
        newrelic.interaction().setName(interactionName);
    };
    MetricsService.prototype.setUserActionName = function (userActionName) {
        this.userActionName = userActionName;
    };
    MetricsService.prototype.error = function (error) {
        newrelic.noticeError(error);
    };
    MetricsService.prototype.setRouteName = function (name) {
        newrelic.setCurrentRouteName(name);
    };
    MetricsService.prototype.setUser = function (user) {
        this.user = user;
    };
    MetricsService.prototype.start = function (name) {
        performance.mark(name + "--START");
    };
    MetricsService.prototype.stop = function (name, attrs) {
        performance.mark(name + "--STOP");
        performance.measure(name, name + "--START", name + "--STOP");
        var entries = performance.getEntriesByName(name);
        var duration = entries[entries.length - 1].duration / 1000;
        var interaction = newrelic.interaction()
            .setAttribute(name, duration)
            .setAttribute('salesforceUserId', this.user.salesforceUserId)
            .setAttribute('orgId', this.user.orgId)
            .setAttribute('name', this.user.firstName + " " + this.user.lastName);
        if (attrs) {
            Object.keys(attrs).forEach(function (key) {
                interaction.setAttribute(key, attrs[key]);
            });
        }
        interaction.save();
    };
    MetricsService.prototype.clearUserActionName = function (name) {
        this.userActionName = undefined;
    };
    return MetricsService;
}());
export { MetricsService };
