import { __extends } from "tslib";
import { Storage } from '@ionic/storage';
import { TokenService } from '@apto/services';
var IonicTokenService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(IonicTokenService, _super);
    function IonicTokenService(storage) {
        var _this = _super.call(this) || this;
        _this.setStorageProvider(storage);
        return _this;
    }
    return IonicTokenService;
}(TokenService));
export { IonicTokenService };
