import { PipeTransform } from '@angular/core';
import * as moment from 'moment';
var HumanDatePipe = /** @class */ /*@__PURE__*/ (function () {
    function HumanDatePipe() {
    }
    HumanDatePipe.prototype.getDiffDateStr = function (startDate, endDate) {
        var delta = {
            days: startDate.diff(endDate, 'days'),
            months: startDate.diff(endDate, 'months'),
            years: startDate.diff(endDate, 'years'),
        };
        if (delta.years === 1) {
            return 'one year';
        }
        if (delta.years > 1) {
            return delta.years + " years";
        }
        if (delta.months === 1) {
            return 'one month';
        }
        if (delta.months > 1) {
            return delta.months + " months";
        }
        if (delta.days === 1) {
            return 'one day';
        }
        if (delta.days > 1) {
            return delta.days + " days";
        }
        return '';
    };
    HumanDatePipe.prototype.transform = function (value) {
        if (!value) {
            return '';
        }
        var currentDate = moment(value).startOf('d');
        var nowDate = moment().startOf('d');
        if (currentDate.format('YYYY-MM-DD') === nowDate.format('YYYY-MM-DD')) {
            return 'today';
        }
        if (currentDate.isAfter(nowDate)) {
            return 'in ' + this.getDiffDateStr(currentDate, nowDate);
        }
        else {
            return this.getDiffDateStr(nowDate, currentDate) + ' ago';
        }
    };
    return HumanDatePipe;
}());
export { HumanDatePipe };
