<ion-card *ngIf="showCard" class="accepted-offer-card">
    <ion-item>
        <ion-icon class="circle"
                  material-icons
                  name="folder"
                  circle
                  item-left>
        </ion-icon>
        <p class="accepted-offer-card--font-small">{{ isClosedDeal ? 'Closed contract' : 'Accepted offer'}}</p>
        <span class="additional-information-card--number dark-text">
            {{ pipelineObject.contractPrice | currency:'USD':true:'1.0-0' }}
        </span>
        <span *ngIf="pipelineObject?.deposit && pipelineObject?.effectiveDate"> • </span>
        <span>{{ pipelineObject.effectiveDate | dateFormat }}</span>

        <span class="additional-information-card--number dark-text">
                {{ pipelineObject.salePrice | currency:'USD':true:'1.0-0' }}
            </span>
            <span *ngIf="pipelineObject?.closeDate"> • </span>
            <span>{{ pipelineObject.closeDate | dateFormat }}</span>

        <div class="accepted-offer-card--font-small">
            <span *ngIf="pipelineObject?.buyerContact">{{ pipelineObject.buyerContact.fullName}}</span>
            <span *ngIf="pipelineObject?.buyerContact && pipelineObject.buyerCompany"> • </span>
            <span *ngIf="pipelineObject?.buyerCompany">{{ pipelineObject.buyerCompany.name}}</span>
        </div>
    </ion-item>
</ion-card>
