<ion-header class="modal-header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton();">
                <ion-icon name="arrow-back"></ion-icon>
            </button>
        </ion-buttons>

        <ion-title>Manage contacts</ion-title>

        <ion-buttons end>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismissWithoutSaving()">
                <ion-icon name="close"></ion-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>
<ion-content class="details-modal manage-contacts-modal">
    <ion-slides (ionSlideWillChange)="scrollToTop()">
        <ion-slide>
            <ion-content>
                <ion-card>
                    <ion-card-header>
                        Related contacts
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="addContact($event);"></add-record>
                    </ion-card-header>
                    <ion-card-content>
                        <ng-container *ngIf="!isInitializing">
                            <ion-list *ngIf="contacts && contacts.length">
                                <ion-item *ngFor="let contact of contacts"
                                          no-lines="true"
                                          [attr.data-automation]="'contact-item--' + contact.fullName">
                                    <ion-icon class="grey-color-circle"
                                              material-icons
                                              name="account_circle"
                                              item-left
                                              large>
                                    </ion-icon>
                                    <span class="manage-contacts-modal--name dark-text"
                                          *ngIf="contact.fullName">{{contact.fullName}}</span>
                                    <p class="manage-contacts-modal--text"
                                       *ngIf="contact.type">{{contact.type}}</p>
                                    <p class="manage-contacts-modal--text"
                                       *ngIf="contact.title">{{contact.title}}</p>
                                    <button class="manage-contacts-modal--delete-button"
                                            item-right
                                            ion-button
                                            icon-only
                                            clear
                                            (click)="removeContact(contact)">
                                        <ion-icon class="close-circle"
                                                  circle
                                                  name="close"></ion-icon>
                                    </button>
                                </ion-item>
                            </ion-list>

                            <ion-item *ngIf="!contacts || !contacts.length"
                                      class="empty-card">
                                <ion-icon name="person"
                                          material-icons
                                          circle
                                          small
                                          color="white"
                                          item-left></ion-icon>
                                Add contacts
                            </ion-item>

                            <ion-row class="card-footer-buttons">
                                <ion-auto-complete
                                        class="manage-contacts-modal--contact-autocomplete"
                                        #contactAutocomplete
                                        [dataProvider]="contactsService"
                                        [options]="{ placeholder : 'Find contact' }"
                                        (itemSelected)="addContact($event);"></ion-auto-complete>
                            </ion-row>
                        </ng-container>

                        <busy-panel *ngIf="isInitializing"
                                    message="Loading contacts..."></busy-panel>
                    </ion-card-content>
                </ion-card>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>
