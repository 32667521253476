import * as TraceKit from 'tracekit';
var Stacktrace = /** @class */ /*@__PURE__*/ (function () {
    function Stacktrace() {
    }
    Stacktrace.extract = function (error) {
        // https://github.com/csnover/TraceKit/blob/v0.4.4/tracekit.js#L346
        try {
            // Try to extract the stacktrace.
            var stacktraceAsString = '';
            var stackInfo = TraceKit.computeStackTrace(error);
            if (stackInfo && stackInfo.mode !== 'failed') {
                var stackLength = stackInfo.stack.length - 1;
                for (var x = 0; x < stackLength; x++) {
                    var item = stackInfo.stack[x];
                    stacktraceAsString += "\t" + (item.func || '[anonymous]') + "() " + item.url + ":" + (item.line || '0') + "\n";
                }
                if (stacktraceAsString) {
                    return stacktraceAsString;
                }
            }
            return "Something went wrong: " + JSON.stringify(error);
        }
        catch (e) {
            return "Unable to capture stacktrace: " + e;
        }
    };
    return Stacktrace;
}());
export { Stacktrace };
