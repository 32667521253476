import { CompaniesService } from '../companies/companies-service';
import { ContactsService } from '../contacts/contacts-service';
var OwnershipRelationsService = /** @class */ /*@__PURE__*/ (function () {
    function OwnershipRelationsService(contactService, companyService) {
        this.contactService = contactService;
        this.companyService = companyService;
    }
    /**
     * Attaches company records for each ownership that needs it
     */
    OwnershipRelationsService.prototype.attachCompanyOwners = function (ownerships) {
        var companyIds = [];
        ownerships.forEach(function (ownership) {
            if (ownership.companyId && !ownership.company) {
                companyIds.push(ownership.companyId);
            }
        });
        return this.companyService.in(companyIds)
            .then(function (companies) {
            companies.forEach(function (company) {
                ownerships.forEach((function (ownership) {
                    if (ownership.companyId === company._id) {
                        ownership.company = company;
                    }
                }));
            });
        });
    };
    /**
     * Attaches contact records for each ownership that needs it
     */
    OwnershipRelationsService.prototype.attachContactOwners = function (ownerships) {
        var contactIds = [];
        ownerships.forEach(function (ownership) {
            if (ownership.contactId && !ownership.contact) {
                contactIds.push(ownership.contactId);
            }
        });
        return this.contactService.in(contactIds)
            .then(function (contacts) {
            contacts.forEach(function (contact) {
                ownerships.forEach((function (ownership) {
                    if (ownership.contactId === contact._id) {
                        ownership.contact = contact;
                    }
                }));
            });
        });
    };
    return OwnershipRelationsService;
}());
export { OwnershipRelationsService };
