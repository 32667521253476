import { EventEmitter } from '@angular/core';
import './transaction-history-card.scss';
var TransactionHistoryCard = /** @class */ /*@__PURE__*/ (function () {
    function TransactionHistoryCard() {
        this.onViewTransaction = new EventEmitter();
        this.regex = /\$|Amount/; // This variable is needed because Angular's templating language doesn't support inline RegExps
    }
    TransactionHistoryCard.prototype.viewTransaction = function (comp) {
        this.onViewTransaction.emit(comp);
    };
    return TransactionHistoryCard;
}());
export { TransactionHistoryCard };
