var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LogsService } from '@apto/ionic-lib/services';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@apto/pn/services/config/config-service';
import { countNonEmptyKeysAndSubKeys } from '@apto/shared/utils/objectUtils';
var AttomService = /** @class */ /*@__PURE__*/ (function () {
    function AttomService(http, logsService, configService) {
        this.http = http;
        this.logsService = logsService;
        this.configService = configService;
    }
    AttomService.prototype.fetchExtendedPropertyFields = function (streetAddress, city, state) {
        var _this = this;
        var baseUrl = this.configService.attomProxyUrl + "/propertyapi/v1.0.0/property/expandedprofile";
        var query = "address1=" + streetAddress + "&address2=" + city + ",%20" + state;
        return new Promise(function (resolve, reject) {
            return _this.http.get(baseUrl + "?" + query).subscribe(function (result) {
                return resolve(result);
            }, function (err) {
                if (err) {
                    _this.logsService.exception("Failed to fetch for service Attom.", err);
                    return reject(err);
                }
            });
        });
    };
    AttomService.prototype.fetchSaleRecords = function (streetAddress, city, state) {
        var _this = this;
        var baseUrl = this.configService.attomProxyUrl + "/propertyapi/v1.0.0/saleshistory/detail";
        var query = "address1=" + streetAddress + "&address2=" + city + ",%20" + state;
        return new Promise(function (resolve, reject) {
            return _this.http.get(baseUrl + "?" + query).subscribe(function (result) {
                return resolve(result);
            }, function (err) {
                if (err) {
                    _this.logsService.exception("Failed to fetch for service Attom.", err);
                    return reject(err);
                }
            });
        });
    };
    AttomService.prototype.fetchTaxRecords = function (attomOnboardPropId) {
        var _this = this;
        var baseUrl = this.configService.attomProxyUrl + "/propertyapi/v1.0.0/assessmenthistory/detail";
        var query = "id=" + attomOnboardPropId;
        return new Promise(function (resolve, reject) {
            return _this.http.get(baseUrl + "?" + query).subscribe(function (result) {
                return resolve(result);
            }, function (err) {
                if (err) {
                    _this.logsService.exception("Failed to fetch for service Attom.", err);
                    return reject(err);
                }
            });
        });
    };
    AttomService.prototype.fetchMortageOwner = function (attomOnboardPropId) {
        var _this = this;
        var baseUrl = this.configService.attomProxyUrl + "/propertyapi/v1.0.0/property/detailmortgageowner";
        var query = "id=" + attomOnboardPropId;
        return new Promise(function (resolve, reject) {
            return _this.http.get(baseUrl + "?" + query).subscribe(function (result) {
                return resolve(result);
            }, function (err) {
                if (err) {
                    _this.logsService.exception("Failed to fetch for service Attom.", err);
                    return reject(err);
                }
            });
        });
    };
    AttomService.prototype.loadAll = function (property) {
        return __awaiter(this, void 0, void 0, function () {
            var data, attomFieldsPromise, attomSaleRecordsPromise, _a, attomFields, attomSaleRecords, onboardPropertyId, attomTaxRecordsPromise, attomMortgageOwnerPromise, _b, attomTaxRecords, attomMortgageOwner;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        data = {
                            fields: null,
                            sales: null,
                            taxes: null,
                            owner: null,
                            mortgage: null
                        };
                        attomFieldsPromise = this.fetchExtendedPropertyFields(property.address.street, property.address.city, property.address.state);
                        attomSaleRecordsPromise = this.fetchSaleRecords(property.address.street, property.address.city, property.address.state);
                        return [4 /*yield*/, Promise.all([
                                attomFieldsPromise,
                                attomSaleRecordsPromise.catch(function (e) { return e; })
                            ])];
                    case 1:
                        _a = _c.sent(), attomFields = _a[0], attomSaleRecords = _a[1];
                        data.fields = Object.assign({}, attomFields.property[0].building, attomFields.property[0].area, attomFields.property[0].lot, attomFields.property[0].summary);
                        if (attomSaleRecords.status && attomSaleRecords.status.msg === 'SuccessWithResult') {
                            data.sales = attomSaleRecords.property[0].salehistory;
                        }
                        if (!(attomFields &&
                            attomFields.property &&
                            attomFields.property[0] &&
                            attomFields.property[0].identifier &&
                            attomFields.property[0].identifier.obPropId))
                            return [3 /*break*/, 3];
                        onboardPropertyId = attomFields.property[0].identifier.obPropId;
                        attomTaxRecordsPromise = this.fetchTaxRecords(onboardPropertyId);
                        attomMortgageOwnerPromise = this.fetchMortageOwner(onboardPropertyId);
                        return [4 /*yield*/, Promise.all([
                                attomTaxRecordsPromise.catch(function (e) { return e; }),
                                attomMortgageOwnerPromise.catch(function (e) { return e; })
                            ])];
                    case 2:
                        _b = _c.sent(), attomTaxRecords = _b[0], attomMortgageOwner = _b[1];
                        if (attomTaxRecords.status && attomTaxRecords.status.msg === 'SuccessWithResult') {
                            data.taxes = attomTaxRecords.property[0].assessmenthistory;
                        }
                        if (attomMortgageOwner.status &&
                            attomMortgageOwner.status.msg === 'SuccessWithResult') {
                            data.owner = attomMortgageOwner.property[0].owner;
                            data.mortgage = attomMortgageOwner.property[0].mortgage;
                        }
                        _c.label = 3;
                    case 3: return [2 /*return*/, data];
                }
            });
        });
    };
    AttomService.prototype.countFields = function (attomData) {
        return countNonEmptyKeysAndSubKeys(attomData.fields);
    };
    AttomService.prototype.countSales = function (attomData) {
        return attomData.sales && attomData.sales.length;
    };
    AttomService.prototype.countTaxes = function (attomData) {
        return attomData.taxes && attomData.taxes.length;
    };
    AttomService.prototype.countOwners = function (attomData) {
        var count = 0;
        if (attomData.owner) {
            if (Object.keys(attomData.owner.owner1)) {
                count++;
            }
            if (Object.keys(attomData.owner.owner2)) {
                count++;
            }
            if (attomData.owner.mailingaddressoneline) {
                count++;
            }
        }
        return count;
    };
    AttomService.prototype.countMortgage = function (attomData) {
        return countNonEmptyKeysAndSubKeys(attomData.mortgage);
    };
    return AttomService;
}());
export { AttomService };
