<ion-content class="page list-page search-results">
    <ion-grid class="content-grid">
        <ion-row class="list-page--header">
            <h2>Search Results</h2>
            <div class="list-page--summary">
                <ion-icon material-icons
                          small
                          name="location_city"></ion-icon>
                <div class="list-page--label">
                    <div class="list-page--line">
                        {{totalResults}} results for "{{searchTerm}}"
                    </div>
                </div>
            </div>
        </ion-row>

        <ion-row class="list-page--body">
            <ion-col>
                <ion-content>
                    <ion-card>
                        <ion-card-header class="search-results--header-row">
                            <strong>All Results</strong>
                                <ion-row class="search-results-row">
                                    <h4 class="search-results--result-numbers">Showing {{totalResults}} results. Want to <div class="search-results-refine-search hover-link"
                                                                                                                              (click)="refineSearch()">
                                        REFINE YOUR SEARCH</div>?
                                    </h4>
                                    <div class="search-results--add-new">
                                        <h4 class="search-results--add-new-msg">Don't have it in Apto?
                                            <span (click)="showOptions($event)">
                                                <button class="search-results--btn"
                                                        ion-button
                                                        align-top
                                                        clear
                                                        color="primary-blue">ADD NEW
                                                        <ion-icon material-icons
                                                                  name="arrow_drop_down">
                                                        </ion-icon>
                                                </button>
                                            </span>
                                        </h4>
                                    </div>
                                </ion-row>
                        </ion-card-header>
                        <ion-card-content>
                            <ion-list>
                                <ng-container *ngIf="searchResults.contacts">
                                    <h4 class="search-results--category-title">
                                        Contacts
                                    </h4>
                                    <contact-item *ngFor="let contact of searchResults.contacts"
                                                  [contact] = "contact"
                                                  [routerLink]="['/contacts', contact._id]">
                                    </contact-item>
                                </ng-container>
                                <ng-container *ngIf="searchResults.properties">
                                    <h4 class="search-results--category-title">
                                        Properties
                                    </h4>
                                    <prop-item *ngFor="let property of searchResults.properties"
                                               [property]="property"
                                               [routerLink]="['/property-map', property._id]">
                                    </prop-item>
                                </ng-container>
                                <ng-container *ngIf="searchResults.companies">
                                    <h4 class="search-results--category-title">
                                        Companies
                                    </h4>
                                    <company-item *ngFor="let company of searchResults.companies"
                                        [company]="company"
                                        [routerLink]="['/companies', company._id]">
                                    </company-item>
                                </ng-container>
                                <ng-container *ngIf="searchResults.tasks">
                                    <h4 class="search-results--category-title">
                                        Tasks
                                    </h4>
                                    <task-item *ngFor="let task of searchResults.tasks"
                                            [task]="task"
                                            [routerLink]="['/tasks', task._id]">
                                    </task-item>
                                </ng-container>
                                <ng-container *ngIf="searchResults.deals">
                                    <h4 class="search-results--category-title">
                                        Deals
                                    </h4>
                                    <deal-item *ngFor="let deal of searchResults.deals"
                                            [deal]="deal"
                                            [routerLink]="['/deals', deal._id]"></deal-item>
                                </ng-container>
                            </ion-list>
                        </ion-card-content>
                    </ion-card>
                </ion-content>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
