import { EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
// This component should be used for any selector within a form.
// For selectors NOT found in a form, use the 'apto-ionic-select' component.
var SelectField = /** @class */ /*@__PURE__*/ (function () {
    function SelectField() {
        this.required = false;
        this.onChange = new EventEmitter();
    }
    SelectField.prototype.ngOnInit = function () {
        if (!this.form.controls.hasOwnProperty(this.field)) {
            throw new Error("field \"" + this.field + "\" does not exist on form.");
        }
        this.selectedValue = this.form.controls[this.field].value;
    };
    SelectField.prototype.setFormValue = function (option) {
        this.form.controls[this.field].patchValue(option);
        this.selectedValue = option;
        if (this.onChange) {
            this.onChange.emit(this.selectedValue);
        }
    };
    return SelectField;
}());
export { SelectField };
