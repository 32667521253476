<ion-card class="contact-leasing-portfolio-tabs-card">
    <ion-card-header>
        {{cardTitle}}
    </ion-card-header>
    <ion-card-content class="error-content">
        <ion-item class="error-description">
            <ion-icon class="error-icon"
                      name="alert"
                      item-left></ion-icon>
            <div>
                <h2>Uh oh! This card didn't load.</h2>
            </div>
            <div><a (click)="refresh()">To view, reload the page.</a></div>
        </ion-item>
    </ion-card-content>
</ion-card>