<div class="attom-data-points-popover">
    <div>
        <apto-paragraph>Apto has <strong>{{ dataPoints }} data points</strong> about this property.</apto-paragraph>
        <apto-paragraph>In addition, we have <strong>{{ ownerDataPoints }} data points</strong> around the owner of this property.</apto-paragraph>
        <div class="attom-data-points-popover--controls">
            <apto-button class="attom-data-points-popover--close"
                            (click)="dismiss()"
                            kind="secondary">CLOSE</apto-button>
        </div>
    </div>
</div>