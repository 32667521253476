<ion-header class="modal-header view-related-ownerships-modal-header">
    <ion-navbar color="white">
        <ion-title>
            Related contacts and companies
        </ion-title>

        <ion-buttons end>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismiss();"
                    data-automation="modal-dismiss-button">
                    <apto-icon icon="close" inline size="4"></apto-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content>
    <ion-card>
        <ion-card-header>
            Owners
        </ion-card-header>
        <ion-card-content>
            <manage-related-ownership [currentOwnerships]="ownerships" [allowEdit]="false"></manage-related-ownership>
        </ion-card-content>
    </ion-card>
</ion-content>
