import { __extends } from "tslib";
import { Injector } from '@angular/core';
import { PlaybookModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
import { AlertController } from 'ionic-angular';
var PlaybooksService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(PlaybooksService, _super);
    function PlaybooksService(injector, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.alertController = alertController;
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.PLAYBOOKS;
        _this.model = PlaybookModel;
        _this.serviceName = SERVICE_NAMES.PLAYBOOKS;
        return _this;
    }
    PlaybooksService.prototype.upsert = function (playbook) {
        return _super.prototype.upsert.call(this, playbook, false);
    };
    return PlaybooksService;
}(BaseService));
export { PlaybooksService };
