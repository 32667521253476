import { EventEmitter } from '@angular/core';
import { ContactModel, CompanyModel } from '@apto/models';
import './tenant-card.scss';
var TenantCard = /** @class */ /*@__PURE__*/ (function () {
    function TenantCard() {
        this.clickedTenantContact = new EventEmitter();
        this.clickedTenantCompany = new EventEmitter();
    }
    return TenantCard;
}());
export { TenantCard };
