import { __extends } from "tslib";
import { Injector } from '@angular/core';
import { CompanyModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
import { AlertController } from 'ionic-angular';
// created this base companies service to get around a ton of circular dependancies
var CompaniesServiceBase = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(CompaniesServiceBase, _super);
    function CompaniesServiceBase(injector, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.alertController = alertController;
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.COMPANIES;
        _this.model = CompanyModel;
        _this.serviceName = SERVICE_NAMES.COMPANIES;
        return _this;
    }
    return CompaniesServiceBase;
}(BaseService));
export { CompaniesServiceBase };
