<ion-card class="activity-history-card">
    <ion-card-header>
        Activity History
    </ion-card-header>
    <ion-card-content *ngIf="!isInitializing"
                      no-padding>
        <ion-list *ngIf="activities.length"
                  [ngClass]="{ 'no-click': onViewActivity.observers.length == 0 }">
            <activity-item *ngFor="let activity of activities | slice:0:sliceLength"
                           [activity]="activity"
                           (onClick)="view(activity)">
            </activity-item>
        </ion-list>

        <ion-item *ngIf="!activities.length"
                  class="empty-card">
            <ion-icon name="call"
                      material-icons
                      circle
                      small
                      color="white"
                      item-left></ion-icon>
            Add activities
        </ion-item>

        <ion-row class="card-footer-buttons">
            <ion-col *ngIf="activities.length > 3 && onViewAllActivities.observers.length > 0">
                <button ion-button
                        clear
                        (click)="viewMore();">
                    VIEW ALL
                </button>
            </ion-col>
            <ion-col text-right>
                <button *ngIf="editEnabled"
                        ion-button
                        clear
                        (click)="addActivity();">
                    ADD ACTIVITY
                </button>
            </ion-col>
        </ion-row>
    </ion-card-content>

    <ion-card-content *ngIf="isInitializing">
        <busy-panel message="Loading activities ..."></busy-panel>
    </ion-card-content>
</ion-card>
