import { NavParams, ViewController } from 'ionic-angular';
import './phone-popover.scss';
var PhonePopover = /** @class */ /*@__PURE__*/ (function () {
    function PhonePopover(navParams, viewController) {
        this.viewController = viewController;
        this.phoneNumbers = navParams.get('phoneNumbers');
    }
    PhonePopover.prototype.logCall = function () {
        // sending true on dismiss indicates that the calling page should prompt to log a call
        this.viewController.dismiss(true);
    };
    PhonePopover.prototype.callNumber = function (callLink) {
        window.location.assign(callLink);
        this.logCall();
    };
    return PhonePopover;
}());
export { PhonePopover };
