<apto-card>
    <apto-card-header noBottomPadding>Stacking plan</apto-card-header>
    <apto-card-content noPadding>
        <stacking-plan
            *ngIf="stackingPlan && stackingPlan.floors"
            [stackingPlan]="stackingPlan"
            (onClickSpace)="openSpaceClick.emit($event)">
        </stacking-plan>
        <apto-loader
            *ngIf="stackingPlanLoading"
            text="Loading Stacking Plan"
            automation="stacking-plan-loader">
        </apto-loader>
        <apto-list-item
            *ngIf="!stackingPlanLoading && (!stackingPlan || !stackingPlan.floors)"
            empty
            data-automation="noStackingPlan">
            <apto-icon
                aptoListItemIcon
                circle
                size="5"
                circleColor="lightGray"
                icon="space"></apto-icon>
            No spaces added.
        </apto-list-item>
    </apto-card-content>
    <apto-card-footer>
        <apto-card-footer-right>
            <apto-button automation="stacking-plan-card--add-space"
                        kind="primary"
                        type="link"
                        (click)="addSpaceClick.emit()">Add Space</apto-button>
        </apto-card-footer-right>
    </apto-card-footer>
</apto-card>
