<ion-header class="modal-header company-modal--header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <button ion-button
                    icon-only
                    color="auxiliary-grey"
                    (click)="backButton();"
                    data-automation="modalBackButton">
                <apto-icon icon="arrowBack" inline size="4"></apto-icon>
            </button>
        </ion-buttons>

        <ion-title *ngIf="mode === Modes.LIST">Companies</ion-title>
        <ion-title *ngIf="mode === Modes.VIEW">Company Details</ion-title>
        <ion-title *ngIf="mode === Modes.EDIT">{{ selectedRecord._id ? 'Edit' : 'Add' }} Company</ion-title>

        <ion-buttons end>
            <button *ngIf="mode === Modes.VIEW"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="setMode(Modes.EDIT)"
                    [disabled]="isLoadingContacts || isLoadingProperties"
                    data-automation="modal-edit-button">
                Edit
            </button>
            <button *ngIf="mode === Modes.EDIT && selectedRecord._id"
                    ion-button
                    clear
                    color="danger"
                    hold-button
                    (hold)="delete()"
                    data-automation="modal-delete-button">
                Hold to Delete
            </button>
            <button *ngIf="mode === Modes.EDIT"
                    ion-button
                    clear
                    color="primary-blue"
                    (click)="save()"
                    data-automation="modal-save-button">
                Save
            </button>
            <button ion-button
                    icon-only
                    clear
                    color="auxiliary-grey"
                    margin-left
                    (click)="dismiss()"
                    data-automation="modal-dismiss-button">
                    <apto-icon icon="close" inline size="4"></apto-icon>
            </button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal company-details-modal">
    <ion-slides>
        <ion-slide>
            <ion-content>
                <ion-card>
                    <ion-list>
                        <company-item *ngFor="let company of records"
                                       [company]="company"
                                       (onClick)="view(company)"></company-item>
                    </ion-list>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide>
            <ion-content>
                <company-summary-card [company]="selectedRecord"></company-summary-card>
                <company-related-contacts-card [contacts]="contacts"
                                               [isInitializing]="isLoadingContacts"
                                               (onClick)="viewContact($event)"
                                               (onManageContactsClicked)="manageRelatedContacts()"></company-related-contacts-card>

                <company-related-properties-card
                    *ngIf="!leasingEnabled"
                    [properties]="properties"
                    [isInitializing]="isLoadingProperties">
                </company-related-properties-card>

                <contact-leasing-portfolio-tabs-card
                    *ngIf="leasingEnabled"
                    [properties]="properties"
                    [showSpaceFeature]="spacesEnabled"
                    [shownProperties]="shownProperties"
                    [isInitializingProperties]="isLoadingProperties"
                    [isInitializingLeases]="isloadingLeases"
                    [leases]="leases"
                    [shownLeases]="shownLeases"
                    [childSpaceCounts]="childSpaceCounts"
                    [isLoadingChildSpaceCounts]="isLoadingChildSpaceCounts"
                    (onViewProperty)="viewProperty($event)"
                    (onLeaseClicked)="viewLease($event)"
                    (onViewAllPortfolio)="toggleVisibleProperties($event)"
                    (onLeasesToggleVisible)="toggleVisibleLeases($event)"
                    (onTabChanged)="changeLeasingCardTab($event)"
                    (onAddLeaseClicked)="showAddLease()"
                    (onAddSpace)="showAddSpace($event)"
                    (onViewSpaces)="viewProperty($event, true)"
                    (onManageOwnershipsModalClicked)="showAddProperty()">
                </contact-leasing-portfolio-tabs-card>

                <company-details-card [company]="selectedRecord"></company-details-card>
            </ion-content>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide">
            <ion-content>
                <edit-company-form [company]="selectedRecord"
                                   [propertyProvider]="propertiesService">
                </edit-company-form>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>
