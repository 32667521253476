var ConfigService = /** @class */ /*@__PURE__*/ (function () {
    function ConfigService() {
        this.appName = 'pn';
        this.aptoLogLevel = process.env.APTO_LOG_LEVEL || 'DEBUG';
        this.dataRefreshMinutes = Number('') || 1; // number of minutes to wait between refreshes, negative to disable
        this.google = {
            clientId: process.env.APTO_GOOGLE_CLIENT_ID,
            apiKey: process.env.APTO_GOOGLE_API_KEY,
            staticMapUrl: process.env.MAPPING_SERVICE_STATIC_MAP_URL ||
                'https://apto-mapping-staging.herokuapp.com/api/static-map'
        };
        this.logentriesToken = process.env.LOGENTRIES_TOKEN;
        this.mappingServiceUrl = process.env.MAPPING_SERVICE_URL;
        this.exportServiceUrl = process.env.EXPORT_SERVICE_URL;
        this.maxCacheAgeInDays = 15; // max cache age before a full refresh is required
        this.oauthClientId = process.env.OAUTH_CLIENT_ID;
        this.oauthRedirectUri = process.env.OAUTH_CALLBACK_URL;
        this.segmentKey = process.env.SEGMENT_KEY;
        this.attomProxyUrl = process.env.ATTOM_PROXY_URL;
        this.serviceUrl = process.env.BASE_SERVICE_URL;
        this.addToListEnabled = true;
        this.addPropertyFromPlaceEnabled = true;
        this.searchNearPlaceEnabled = true;
        this.exportEnabled = true;
    }
    return ConfigService;
}());
export { ConfigService };
