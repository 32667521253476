import * as moment from 'moment';
import { NavParams } from 'ionic-angular';
import './organizations-popover.scss';
var OrganizationsPopover = /** @class */ /*@__PURE__*/ (function () {
    function OrganizationsPopover(navParams) {
        this.navParams = navParams;
        this.organizations = [];
        this.organizations = this.navParams.get('organizations');
    }
    OrganizationsPopover.prototype.humanDate = function (dateString) {
        return moment(dateString).format('MMMM YYYY');
    };
    return OrganizationsPopover;
}());
export { OrganizationsPopover };
