import { __extends } from "tslib";
import { BaseFilter } from '../';
var SelectMapFilter = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(SelectMapFilter, _super);
    function SelectMapFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.optionsMap = {};
        return _this;
    }
    SelectMapFilter.prototype.getQuery = function () {
        var query = {};
        var isValid = this.validate();
        if (isValid) {
            query[this.queryKey] = this.optionsMap[this.selectedOption];
            return query;
        }
        return query;
    };
    SelectMapFilter.prototype.getOptions = function () {
        return this.optionsMap ? Object.keys(this.optionsMap) : [];
    };
    SelectMapFilter.prototype.validate = function () {
        return true;
    };
    SelectMapFilter.prototype.reset = function () {
        this.selectedOption = null;
    };
    SelectMapFilter.prototype.isEmpty = function () {
        return !this.selectedOption;
    };
    SelectMapFilter.prototype.setSelected = function (option) {
        this.selectedOption = option;
        this.onQueryChange.emit();
    };
    return SelectMapFilter;
}(BaseFilter));
export { SelectMapFilter };
