<ion-card>
    <ion-card-header>
        Related
    </ion-card-header>

    <ion-card-content>
        <ion-list *ngIf="!isInitializing">
            <ion-item *ngFor="let ownership of companyOwnerships"
                      mode="ios"
                      (click)="onClickCompany.emit({ company: ownership.company })"
                      detail-push>
                <ion-icon class="grey-circle"
                          circle
                          material-icons
                          name="location_city"
                          item-left>
                </ion-icon>

                <strong class="contact-item--name">{{ownership.company.name}}</strong>
                <span class="contact-item--contact-role">
                    {{ownership.contactRole}}
                </span>
                <p>Company</p>
            </ion-item>

            <contact-item *ngFor="let ownership of contactOwnerships"
                          [contact]="ownership.contact"
                          [ownership]="ownership"
                          [showContactInfo]="false"
                          (onClick)="onClickContact.emit({ contact: ownership.contact })"></contact-item>
        </ion-list>

        <ion-item *ngIf="!contactOwnerships.length && !companyOwnerships.length && !isInitializing"
                  class="empty-card">
            <ion-icon name="account_circle"
                      material-icons
                      circle
                      small
                      color="white"
                      item-left></ion-icon>
            Add related contacts or companies
        </ion-item>

        <busy-panel *ngIf="isInitializing"></busy-panel>
    </ion-card-content>

    <ion-row class="card-footer-buttons">
        <ion-col text-left>
            <button *ngIf="onViewAllOwnerships.observers.length > 0"
                    ion-button
                    clear
                    (click)="viewAll();">
                VIEW ALL
            </button>
        </ion-col>
        <ion-col text-right>
            <button ion-button
                    clear
                    (click)="manage();">
                MANAGE
            </button>
        </ion-col>
    </ion-row>
</ion-card>
