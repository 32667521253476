import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter } from '@angular/core';
import { ModalController } from 'ionic-angular';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { AnalyticsService, ContactGroupsService, ContactListViewsService, ListingsService } from '@apto/services';
import { AppcuesService, MetricsService } from '@apto/shared/services';
import { LogsService } from '@apto/ionic-lib/services';
import { LIST_VIEW_MAX_RECORDS } from '@apto/shared/constants';
import { BaseListPage } from '../base-list-page/base-list-page';
import { FilterPanel } from '../../components/filter-panel/filter-panel';
import { SearchFilter } from '../../components/filter-panel/search-filter/search-filter';
import './contact-groups.scss';
var FORM_DEBOUNCE_TIME = 500;
var ContactGroupsPage = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ContactGroupsPage, _super);
    function ContactGroupsPage(analyticsService, appcuesService, contactGroupsService, contactListViewsService, listingsService, logsService, metricsService, modalController, route, router) {
        var _this = _super.call(this) || this;
        _this.analyticsService = analyticsService;
        _this.appcuesService = appcuesService;
        _this.contactGroupsService = contactGroupsService;
        _this.contactListViewsService = contactListViewsService;
        _this.listingsService = listingsService;
        _this.logsService = logsService;
        _this.metricsService = metricsService;
        _this.modalController = modalController;
        _this.route = route;
        _this.router = router;
        _this.filterChangeEvent = new EventEmitter();
        _this.LIST_VIEW_MAX_RECORDS = LIST_VIEW_MAX_RECORDS;
        _this.contactListViewGroups = [];
        _this.filters = {};
        _this.isInitializing = true;
        _this.sortMap = {
            'A-Z': { name: 1 },
            'Z-A': { name: -1 }
        };
        _this.searchesInFlight = 0;
        _this.searchStartedEvent = new EventEmitter();
        _this.searchFinishedEvent = new EventEmitter();
        _this.subscriptions = [];
        _this.sortKeys = Object.keys(_this.sortMap);
        _this.selectedSortKey = _this.sortKeys[0];
        return _this;
    }
    ContactGroupsPage.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var listingId, listings;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.metricsService.start('Contact Groups Page');
                        this.metricsService.setRouteName('/contact-groups');
                        this.analyticsService.logEvent('Contact Groups Page Load');
                        listingId = this.route.snapshot.queryParamMap.get('listingId');
                        // get the total asynchronously
                        Promise.all([this.contactGroupsService.count(), this.contactListViewsService.count()])
                            .then(function (_a) {
                            var groupCount = _a[0], viewCount = _a[1];
                            _this.totalGroups = groupCount + viewCount;
                        });
                        this.subscriptions.push(this.filterChangeEvent.pipe(debounceTime(FORM_DEBOUNCE_TIME)).subscribe(function () { return _this.updateContactGroups(); }));
                        this.subscriptions.push(this.searchStartedEvent.subscribe(function () { _this.searchStarted(); }));
                        this.subscriptions.push(this.searchFinishedEvent.subscribe(function () { _this.searchFinished(); }));
                        return [4 /*yield*/, this.updateContactGroups()];
                    case 1:
                        _a.sent();
                        if (!listingId)
                            return [3 /*break*/, 3];
                        return [4 /*yield*/, this.listingsService.in([listingId])];
                    case 2:
                        listings = _a.sent();
                        if (listings.length > 0) {
                            this.filterListing = listings[0];
                        }
                        _a.label = 3;
                    case 3:
                        this.createFilterPanel();
                        this.metricsService.stop('Contact Groups Page');
                        this.appcuesService.start();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactGroupsPage.prototype.searchStarted = function () {
        this.searchesInFlight++;
        this.isInitializing = true;
    };
    ContactGroupsPage.prototype.searchFinished = function () {
        this.searchesInFlight--;
        if (this.searchesInFlight <= 0) {
            this.isInitializing = false;
            this.searchesInFlight = 0;
        }
    };
    ContactGroupsPage.prototype.createFilterPanel = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.searchFilterPanel.add(SearchFilter, {
                    placeholder: 'Find a contact group',
                    queryKey: 'name'
                });
                this.searchFilterPanel.onQueryChange.subscribe(function (filters) {
                    _this.isInitializing = true;
                    _this.filterChangeEvent.emit();
                }, function (err) { return _this.logsService.error(err); });
                return [2 /*return*/];
            });
        });
    };
    ContactGroupsPage.prototype.filtersAreEmpty = function () {
        return this.searchFilterPanel.isEmpty();
    };
    ContactGroupsPage.prototype.resetFilters = function () {
        this.searchFilterPanel.resetFilters();
        this.updateContactGroups();
    };
    ContactGroupsPage.prototype.sortChanged = function (sortKey) {
        this.selectedSortKey = sortKey;
        this.sortItems(sortKey === 'A-Z');
    };
    ContactGroupsPage.prototype.sortItems = function (ascendingSort) {
        this.items.sort(function (a, b) {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return ascendingSort ? 1 : -1;
            }
            else if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return ascendingSort ? -1 : 1;
            }
            else {
                return 0;
            }
        });
    };
    ContactGroupsPage.prototype.toContactsPage = function () {
        this.router.navigate(['/contacts'], {
            queryParams: {
                listingId: this.route.snapshot.queryParams.listingId
            }
        });
    };
    ContactGroupsPage.prototype.updateContactGroups = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var params, contactGroupPromise, contactListViewPromise, results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.searchStartedEvent.emit();
                        params = {
                            limit: LIST_VIEW_MAX_RECORDS,
                            sort: { name: 1 },
                            where: this.searchFilterPanel.getQuery()
                        };
                        contactGroupPromise = this.contactGroupsService.fetch(params);
                        contactListViewPromise = new Promise(function (resolve) {
                            return __awaiter(_this, void 0, void 0, function () {
                                var _this = this;
                                var _a, regexs_1;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0:
                                            _a = this;
                                            return [4 /*yield*/, this.contactListViewsService.fetch()];
                                        case 1:
                                            _a.contactListViewGroups = _b.sent();
                                            this.contactListViewGroups.forEach(function (listView) {
                                                return __awaiter(_this, void 0, void 0, function () {
                                                    var info;
                                                    return __generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0: return [4 /*yield*/, this.contactListViewsService.info(listView._id)];
                                                            case 1:
                                                                info = _a.sent();
                                                                listView.createdDate = info.createdDate;
                                                                listView.numberOfMembers = info.numberOfMembers;
                                                                listView.contacts = info.contacts;
                                                                return [2 /*return*/];
                                                        }
                                                    });
                                                });
                                            });
                                            if (params.where.$or) {
                                                regexs_1 = params.where.$or.map(function (query) {
                                                    var formattedString = query.name.$like.replace(/%/g, '.*');
                                                    var regex = new RegExp(formattedString, 'gi');
                                                    return regex;
                                                });
                                                this.contactListViewGroups = this.contactListViewGroups.filter(function (item) {
                                                    var isMatch = false;
                                                    for (var _i = 0, regexs_2 = regexs_1; _i < regexs_2.length; _i++) {
                                                        var regex = regexs_2[_i];
                                                        if (regex.test(item.name)) {
                                                            isMatch = true;
                                                            break;
                                                        }
                                                    }
                                                    return isMatch;
                                                });
                                            }
                                            resolve(undefined);
                                            return [2 /*return*/];
                                    }
                                });
                            });
                        });
                        return [4 /*yield*/, Promise.all([contactGroupPromise, contactListViewPromise])];
                    case 1:
                        results = _a.sent();
                        this.contactGroups = results[0];
                        this.items = this.contactGroups.concat(this.contactListViewGroups);
                        this.sortItems(true);
                        this.items = this.items.splice(0, params.limit);
                        this.currentPageSelected = false;
                        this.searchFinishedEvent.emit();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ContactGroupsPage;
}(BaseListPage));
export { ContactGroupsPage };
