import { fromEvent as observableFromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
// modified from https://github.com/fiznool/angular2-elastic, commit d25efd3a4a44e62f71359f93ffa6ddac1c64b89b
import { AfterViewInit, ElementRef, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
var TextareaElasticDirective = /** @class */ /*@__PURE__*/ (function () {
    function TextareaElasticDirective(element, ngZone, model) {
        this.element = element;
        this.ngZone = ngZone;
        this.model = model;
    }
    TextareaElasticDirective.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.model) {
            return;
        }
        // Listen for changes to the underlying model
        // to adjust the textarea size.
        this.modelSub = this.model
            .valueChanges.pipe(debounceTime(100))
            .subscribe(function () { return _this.adjust(); });
    };
    TextareaElasticDirective.prototype.ngOnDestroy = function () {
        if (this.modelSub) {
            this.modelSub.unsubscribe();
        }
        if (this.resizeSub) {
            this.resizeSub.unsubscribe();
        }
    };
    TextareaElasticDirective.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.isTextarea(this.element.nativeElement)) {
            this.setupTextarea(this.element.nativeElement);
            return;
        }
        var children = Array.from(this.element.nativeElement.children);
        var textareaEl = children.find(function (el) { return _this.isTextarea(el); });
        if (textareaEl) {
            this.setupTextarea(textareaEl);
            return;
        }
        throw new Error('The `textarea-elastic` attribute directive must be used on a `textarea` or an element that contains a `textarea`.');
    };
    TextareaElasticDirective.prototype.onInput = function () {
        // This is run whenever the user changes the input.
        this.adjust();
    };
    TextareaElasticDirective.prototype.isTextarea = function (el) {
        return el.tagName === 'TEXTAREA';
    };
    TextareaElasticDirective.prototype.setupTextarea = function (textareaEl) {
        var _this = this;
        this.textareaEl = textareaEl;
        // Set some necessary styles
        var style = this.textareaEl.style;
        style.overflow = 'auto';
        style.resize = 'none';
        // Listen for window resize events
        this.ngZone.runOutsideAngular(function () {
            _this.resizeSub = observableFromEvent(window, 'resize').pipe(debounceTime(250))
                .subscribe(function () { _this.adjust(); });
        });
        // Ensure we adjust the textarea if
        // content is already present
        this.adjust();
    };
    TextareaElasticDirective.prototype.adjust = function () {
        var _this = this;
        if (!this.textareaEl || this.requestId) {
            return;
        }
        this.requestId = window.requestAnimationFrame(function () {
            _this.requestId = null;
            _this.textareaEl.style.height = 'auto';
            _this.textareaEl.style.height = _this.textareaEl.scrollHeight + "px";
        });
    };
    return TextareaElasticDirective;
}());
export { TextareaElasticDirective };
