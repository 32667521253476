import { EventEmitter } from '@angular/core';
import './selectable-item.scss';
var SelectableItem = /** @class */ /*@__PURE__*/ (function () {
    function SelectableItem() {
        this.selectable = false;
        this.selected = false;
        this.onSelectToggled = new EventEmitter();
    }
    SelectableItem.prototype.itemToggled = function ($event) {
        this.onSelectToggled.emit($event);
    };
    return SelectableItem;
}());
export { SelectableItem };
