<ion-card *ngIf="(!!displayName && !isInitializingClient) || isInitializingClient">
    <ion-item *ngIf="!!displayName && !isInitializingClient" detail-push
              (click)="viewClient()">
        <ion-icon *ngIf="hasCompany"
                  class="action-circle"
                  material-icons
                  name="location_city"
                  circle
                  item-left>
        </ion-icon>
        <ion-icon *ngIf="!hasCompany"
                  class="grey-color-circle"
                  material-icons
                  name="account_circle"
                  item-left
                  large>
        </ion-icon>
        <p>Client</p>
        <p class="dark-text">
            {{ displayName }}
        </p>
    </ion-item>
    <ion-item *ngIf="isInitializingClient">
        <busy-panel></busy-panel>
    </ion-item>
</ion-card>