<div class="filter-panel--option tags-filter--component">
    <ion-label stacked>{{ label }}</ion-label>
    <tag-input [(ngModel)]="items"
               modelAsStrings="true"
               [placeholder]="placeholder"
               [secondaryPlaceholder]="secondaryPlaceholder"
               [separatorKeyCodes]="separatorKeyCodes"
               (onAdd)="onInputChanged($event)"
               (onRemove)="onInputChanged($event)">
    </tag-input>
</div>
