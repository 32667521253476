import { ModalController, NavParams } from 'ionic-angular';
import { LeaseModal, LeaseModalMode } from '@apto/pn';
import './previous-leases-popover.scss';
var PreviousLeasesPopover = /** @class */ /*@__PURE__*/ (function () {
    function PreviousLeasesPopover(modalController, navParams) {
        this.modalController = modalController;
        this.previousLeases = navParams.get('previousLeases');
    }
    PreviousLeasesPopover.prototype.viewLease = function (event) {
        var leaseModal = this.modalController.create(LeaseModal, {
            selectedRecord: event,
            mode: LeaseModalMode.VIEW
        }, {
            cssClass: 'large-modal'
        });
        leaseModal.present({ updateUrl: false });
    };
    return PreviousLeasesPopover;
}());
export { PreviousLeasesPopover };
