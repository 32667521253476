import * as moment from 'moment';
import { PopoverController } from 'ionic-angular';
import { FullContactPersonModel } from '@apto/models';
import { SocialProfilesPopover } from '../../components/social-profiles-popover/social-profiles-popover';
import { OrganizationsPopover } from '../../components/organizations-popover/organizations-popover';
import './fullcontact-insights-card.scss';
var FullContactInsightsCard = /** @class */ /*@__PURE__*/ (function () {
    function FullContactInsightsCard(popoverController) {
        this.popoverController = popoverController;
        this.isInitializing = false;
    }
    FullContactInsightsCard.prototype.getSocialProfile = function (type) {
        if (this.person.apiResult.socialProfiles &&
            this.person.apiResult.socialProfiles.length) {
            return this.person.apiResult.socialProfiles.find(function (profile) { return profile.type === type; });
        }
        return {};
    };
    FullContactInsightsCard.prototype.showAllSocialProfiles = function (ev) {
        var popover = this.popoverController.create(SocialProfilesPopover, {
            socialProfiles: this.person.apiResult.socialProfiles
        }, {
            cssClass: 'fullcontact-insights-card--social-profiles-popover'
        });
        popover.present({ ev: ev });
    };
    FullContactInsightsCard.prototype.showAllOrganizations = function (ev) {
        var popover = this.popoverController.create(OrganizationsPopover, {
            organizations: this.person.apiResult.organizations
        }, {
            cssClass: 'fullcontact-insights-card--organizations-popover'
        });
        popover.present({ ev: ev });
    };
    FullContactInsightsCard.prototype.getInterests = function () {
        var topics = this.person.apiResult.digitalFootprint.topics;
        return topics.map(function (topic) { return topic.value; }).join(', ');
    };
    FullContactInsightsCard.prototype.humanDate = function (dateString) {
        return moment(dateString).format('MMMM YYYY');
    };
    FullContactInsightsCard.prototype.hasLowConfidence = function () {
        return parseFloat(this.person.apiResult.likelihood) < 0.90;
    };
    return FullContactInsightsCard;
}());
export { FullContactInsightsCard };
