import { EventEmitter } from '@angular/core';
import { PopoverController } from 'ionic-angular';
import { SelectPopover } from '@apto/ionic-lib/components';
var Dropdown = /** @class */ /*@__PURE__*/ (function () {
    function Dropdown(popoverController) {
        this.popoverController = popoverController;
        this.options = [];
        this.onSelect = new EventEmitter();
    }
    Dropdown.prototype.showOptions = function (ev) {
        var _this = this;
        if (this.disabled) {
            return;
        }
        var popover = this.popoverController.create(SelectPopover, {
            options: this.options
        }, {
            cssClass: 'color-last-option no-lines wide'
        });
        popover.onDidDismiss(function (value) {
            _this.onSelect.emit(value);
        });
        popover.present({ ev: ev });
    };
    return Dropdown;
}());
export { Dropdown };
