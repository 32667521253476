<div class="StackingPlanLegend">
    <div class="StackingPlanLegend-item StackingPlanLegend-item--vacant">
        Vacant
    </div>
    <div class="StackingPlanLegend-item StackingPlanLegend-item--available">
        Available
    </div>
    <div class="StackingPlanLegend-item StackingPlanLegend-item--lease">
        Lease &gt;2y
    </div>
    <div class="StackingPlanLegend-item StackingPlanLegend-item--leaseWarning">
        Lease &lt;2y
    </div>
    <div class="StackingPlanLegend-item StackingPlanLegend-item--leaseDanger">
        Lease &lt;12m
    </div>
</div>
<div class="StackingPlan">
    <div
        *ngFor="let u of stackingPlan.floors | keys:'reverse'"
        class="StackingPlan-floor">
        <div class="StackingPlan-floorMeta">
            <span class="StackingPlan-floorLabel">
                <strong>{{stackingPlan.floors[u].label}}</strong>
            </span>
            <span
                *ngIf="stackingPlan.floors[u].totalSqft"
                class="StackingPlan-floorSqft">
                {{stackingPlan.floors[u].totalSqft | numberWithCommas}}sf
            </span>
            <span
                *ngIf="stackingPlan.floors[u].numberAvailable > 0"
                class="StackingPlan-available">
                <strong>{{stackingPlan.floors[u].numberAvailable}} available</strong>
            </span>
        </div>
        <div class="StackingPlan-spaces">
            <stacking-plan-space
                *ngFor="let obj of stackingPlan.floors[u].spaces"
                [spaceData]="obj"
                [totalSqft]="stackingPlan.floors[u].totalSqft || 0"
                (click)="onClickSpace.emit(obj)">
            </stacking-plan-space>
        </div>
    </div>
</div>
