var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, SimpleChanges } from '@angular/core';
import { Storage } from '@ionic/storage';
import { PropertyModel } from '@apto/models';
import './contact-leasing-portfolio-tabs-card.scss';
export var ContactLeasingPortfolioTabs;
(function (ContactLeasingPortfolioTabs) {
    ContactLeasingPortfolioTabs["PORTFOLIO"] = "PORTFOLIO";
    ContactLeasingPortfolioTabs["LEASES"] = "LEASES";
})(ContactLeasingPortfolioTabs || (ContactLeasingPortfolioTabs = {}));
var ContactLeasingPortfolioTabsCard = /** @class */ /*@__PURE__*/ (function () {
    function ContactLeasingPortfolioTabsCard(storage) {
        this.storage = storage;
        this.tabTypes = ContactLeasingPortfolioTabs;
        this.currentTab = ContactLeasingPortfolioTabs.PORTFOLIO;
        this.properties = [];
        this.shownProperties = [];
        this.hoveredProperty = new PropertyModel();
        this.childSpaceCounts = {};
        this.isInitializingProperties = true;
        this.isInitializingLeases = true;
        this.isLoadingChildSpaceCounts = false;
        this.leases = [];
        this.shownLeases = [];
        this.showSpaceFeature = false;
        this.onAddSpace = new EventEmitter();
        this.onItemHovered = new EventEmitter();
        this.onLeaseClicked = new EventEmitter();
        this.onTabChanged = new EventEmitter();
        this.onViewAllPortfolio = new EventEmitter();
        this.onViewProperty = new EventEmitter();
        this.onViewSpaces = new EventEmitter();
        this.onLeasesToggleVisible = new EventEmitter();
        this.onAddLeaseClicked = new EventEmitter();
        this.onManageOwnershipsModalClicked = new EventEmitter();
        this.sessionStorageKey = 'contactLeasingPortfolioTabsCardState';
    }
    ContactLeasingPortfolioTabsCard.prototype.ngOnInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var lastTab;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.getLastTab()];
                    case 1:
                        lastTab = _a.sent();
                        if (lastTab) {
                            this.currentTab = lastTab;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactLeasingPortfolioTabsCard.prototype.ngOnChanges = function (changes) {
        if ((changes.shownLeases &&
            changes.shownLeases.currentValue.length !==
                (changes.shownLeases.previousValue || []).length) ||
            (changes.shownProperties &&
                changes.shownProperties.currentValue.length !==
                    (changes.shownProperties.previousValue || []).length)) {
            if (this.totalLeases > 0 && this.properties.length === 0) {
                this.currentTab = ContactLeasingPortfolioTabs.LEASES;
            }
            if (this.totalLeases === 0 && this.properties.length > 0) {
                this.currentTab = ContactLeasingPortfolioTabs.PORTFOLIO;
            }
        }
    };
    Object.defineProperty(ContactLeasingPortfolioTabsCard.prototype, "totalLeases", {
        // get lease total
        get: function () {
            return this.leases ? this.leases.length : 0;
        },
        enumerable: true,
        configurable: true
    });
    ContactLeasingPortfolioTabsCard.prototype.getLastTab = function () {
        return __awaiter(this, void 0, void 0, function () {
            var tab;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.get(this.sessionStorageKey)];
                    case 1:
                        tab = _a.sent();
                        if (tab && ContactLeasingPortfolioTabs[tab]) {
                            return [2 /*return*/, ContactLeasingPortfolioTabs[tab]];
                        }
                        return [2 /*return*/, null];
                }
            });
        });
    };
    ContactLeasingPortfolioTabsCard.prototype.changeTab = function (type) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.currentTab = type;
                        this.onTabChanged.emit(type);
                        return [4 /*yield*/, this.setLastTab(type)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    ContactLeasingPortfolioTabsCard.prototype.setLastTab = function (tab) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.storage.set(this.sessionStorageKey, tab)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ContactLeasingPortfolioTabsCard;
}());
export { ContactLeasingPortfolioTabsCard };
