import { CACHE_EXPIRATION_TIME } from '../constants';
import { AppService } from '../app/app-service';
import { CacheEntry } from './cache-entry';
var CacheService = /** @class */ /*@__PURE__*/ (function () {
    function CacheService() {
        var _this = this;
        this.cache = {};
        AppService.onLogout.subscribe(function () {
            _this.clear();
        });
    }
    CacheService.prototype.add = function (serviceName, key, value) {
        if (!this.cache[serviceName]) {
            this.cache[serviceName] = {};
        }
        var cachedEntry = this.cache[serviceName][key];
        if (cachedEntry) {
            Object.assign(cachedEntry.value, value);
            cachedEntry.timestamp = Date.now();
        }
        this.cache[serviceName][key] = cachedEntry || new CacheEntry(value);
    };
    CacheService.prototype.clear = function () {
        this.cache = {};
    };
    CacheService.prototype.get = function (serviceName, key) {
        var _this = this;
        if (!this.cache[serviceName]) {
            return;
        }
        if (Array.isArray(key)) {
            return key.map(function (k) { return _this.get(serviceName, k); })
                .filter(function (value) { return value; }); // remove any null records from unmatched or expired entries
        }
        var cacheEntry = this.cache[serviceName][key];
        if (!cacheEntry) {
            return;
        }
        if (this.isExpired(cacheEntry)) {
            this.remove(serviceName, key);
            return undefined;
        }
        cacheEntry.timestamp = Date.now();
        return cacheEntry.value;
    };
    CacheService.prototype.getAll = function (serviceName) {
        var _this = this;
        if (!(this.cache[serviceName])) {
            return [];
        }
        return Object.keys(this.cache[serviceName]).map(function (key) { return _this.cache[serviceName][key].value; });
    };
    CacheService.prototype.remove = function (serviceName, key) {
        var _this = this;
        if (!this.cache[serviceName]) {
            return;
        }
        if (Array.isArray(key)) {
            key.map(function (k) { return delete _this.cache[serviceName][k]; });
        }
        else {
            delete this.cache[serviceName][key];
        }
    };
    CacheService.prototype.isExpired = function (cacheEntry) {
        return cacheEntry.timestamp + CACHE_EXPIRATION_TIME < Date.now();
    };
    return CacheService;
}());
export { CacheService };
