import { __extends } from "tslib";
import { EventEmitter } from '@angular/core';
import { PropertyModel } from '@apto/models';
import { SelectableItem } from '../selectable-item/selectable-item';
import { NumberWithCommas, PricePerSqFtFormat } from '../../pipes';
import './property-item.scss';
var PropertyItem = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(PropertyItem, _super);
    function PropertyItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isImageShown = true;
        _this.isShownOnMap = true;
        _this.isPhotoBannerShown = true;
        _this.onMouseover = new EventEmitter();
        _this.onMouseout = new EventEmitter();
        _this.onClick = new EventEmitter();
        return _this;
    }
    PropertyItem.prototype.getPricePerSqft = function (price, sqft) {
        return (parseInt(price, 10) / parseInt(sqft, 10)).toFixed(2).toString();
    };
    PropertyItem.prototype.getPriceAndSqft = function (property) {
        var items = [];
        if (property.salePrice) {
            var price = new NumberWithCommas().transform(property.salePrice);
            items.push("<strong>$" + price + "</strong>");
        }
        if (property.squareFootage) {
            var sqft = new NumberWithCommas().transform(property.squareFootage);
            items.push(sqft + " sqft");
        }
        if (property.salePrice && property.squareFootage) {
            var price = this.getPricePerSqft(property.salePrice, property.squareFootage);
            var str = new PricePerSqFtFormat().transform(price);
            items.push(str);
        }
        return items.join(' &bull; ');
    };
    return PropertyItem;
}(SelectableItem));
export { PropertyItem };
