import { PipeTransform } from '@angular/core';
var BoldSubstring = /** @class */ /*@__PURE__*/ (function () {
    function BoldSubstring() {
    }
    BoldSubstring.prototype.escapeRegExp = function (text) {
        return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    };
    BoldSubstring.prototype.transform = function (value, textToBold) {
        var _this = this;
        if (!value || !textToBold) {
            return value;
        }
        var textToBoldRegex = new RegExp(this.escapeRegExp(textToBold), 'gi');
        var matches = new Set(value.match(textToBoldRegex));
        matches.forEach(function (stringToReplace) {
            value = value.replace(new RegExp(_this.escapeRegExp(stringToReplace), 'g'), "<b>" + stringToReplace + "</b>");
        });
        return value;
    };
    return BoldSubstring;
}());
export { BoldSubstring };
