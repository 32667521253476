<ion-card class="contact-portfolio-card">
    <ion-card-header>
        Portfolio
    </ion-card-header>
    <ion-card-content *ngIf="!isInitializing"
                      no-padding>
        <ion-list *ngIf="shownProperties.length"
                  [ngClass]="{ 'no-click': onViewProperty.observers.length == 0 }">
            <property-item *ngFor="let property of shownProperties"
                           [property]="property"
                           [hoveredProperty]="hoveredProperty"
                           [isImageShown]="true"
                           [isShownOnMap]="false"
                           (onMouseover)="isHovered(property, true)"
                           (onMouseout)="isHovered(property, false)"
                           (onClick)="viewProperty(property)"></property-item>
        </ion-list>

        <ion-item *ngIf="!shownProperties.length"
                  class="empty-card">
            <ion-icon name="business"
                      material-icons
                      circle
                      small
                      color="white"
                      item-left></ion-icon>
            Add properties
        </ion-item>

        <ion-row class="card-footer-buttons">
            <ion-col *ngIf="properties.length > shownProperties.length && onViewAllPortfolio.observers.length > 0">
                <button ion-button
                        clear
                        (click)="viewMore();">
                    VIEW ALL
                </button>
            </ion-col>
        </ion-row>
    </ion-card-content>

    <ion-card-content *ngIf="isInitializing">
        <busy-panel message="Loading properties ..."></busy-panel>
    </ion-card-content>
</ion-card>
