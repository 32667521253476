import { __extends } from "tslib";
import { FormControl } from '@angular/forms';
import { BaseFilter } from '../';
import './range-filter.scss';
var RangeFilter = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(RangeFilter, _super);
    function RangeFilter() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.convertToStrings = false;
        _this.maximumPlaceholder = '';
        _this.max = '';
        _this.maxKey = 'max';
        _this.minimumPlaceholder = '';
        _this.min = '';
        _this.minKey = 'min';
        _this.wholeNumbers = false;
        _this.minOnly = false;
        _this.errors = [];
        return _this;
    }
    RangeFilter.prototype.init = function (options) {
        var _this = this;
        _super.prototype.init.call(this, options);
        this.minFormControl = new FormControl();
        this.maxFormControl = new FormControl();
        this.minFormControl.valueChanges.subscribe(function (value) {
            _this.onQueryChange.emit(value);
        });
        this.maxFormControl.valueChanges.subscribe(function (value) {
            _this.onQueryChange.emit(value);
        });
    };
    RangeFilter.prototype.getQuery = function () {
        if (this.validate()) {
            if (this.min && this.max) {
                return {
                    $and: [
                        (_a = {},
                            _a[this.minKey] = {
                                $lte: Number(this.min)
                            },
                            _a),
                        (_b = {},
                            _b[this.maxKey] = {
                                $gte: Number(this.max)
                            },
                            _b)
                    ]
                };
            }
            if (this.min) {
                return _c = {},
                    _c[this.minKey] = {
                        $lte: Number(this.min)
                    },
                    _c;
            }
            if (this.max) {
                return _d = {},
                    _d[this.maxKey] = {
                        $gte: Number(this.max)
                    },
                    _d;
            }
        }
        return {};
        var _a, _b, _c, _d;
    };
    RangeFilter.prototype.validate = function () {
        var maxIsValid = this.isValidInput(this.max);
        var minIsValid = this.isValidInput(this.min);
        var wholeNumberString = this.wholeNumbers ? 'whole ' : '';
        this.errors = [];
        if (this.min !== '' && !minIsValid) {
            this.errors.push("Minimum value must be a valid " + wholeNumberString + "number.");
        }
        if (this.max !== '' && !maxIsValid) {
            this.errors.push("Maximum value must be a valid " + wholeNumberString + "number.");
        }
        if (minIsValid &&
            maxIsValid &&
            this.getNumberFromString(this.min) > this.getNumberFromString(this.max)) {
            this.errors.push('Minimum value must be less than or equal to the maximum value.');
        }
        return !this.errors.length;
    };
    RangeFilter.prototype.reset = function () {
        this.min = '';
        this.max = '';
    };
    RangeFilter.prototype.isEmpty = function () {
        return !this.isValidInput(this.min) && !this.isValidInput(this.max);
    };
    RangeFilter.prototype.isValidInput = function (input) {
        if (input === '') {
            return false;
        }
        var value = this.getNumberFromString(input);
        return !isNaN(value);
    };
    RangeFilter.prototype.getNumberFromString = function (input) {
        var regex = /[^0-9\.]/g;
        var sanitizedInput = input.replace(regex, '');
        var value = this.wholeNumbers
            ? parseInt(sanitizedInput, 10)
            : parseFloat(sanitizedInput);
        return value;
    };
    return RangeFilter;
}(BaseFilter));
export { RangeFilter };
