<div class="filter-panel--option range-slide-filter--component">
    <ion-label stacked>{{ label }}</ion-label>

    <ion-input type="number"
               [step]="wholeNumbers ? '1' : '0.01'"
               [(ngModel)]="min"
               [ngClass]="{'two-column-input': !minOnly}"
               [formControl]="minFormControl"
               [placeholder]="minimumPlaceholder"></ion-input>
    <ion-input *ngIf="!minOnly"
               type="number"
               [step]="wholeNumbers ? '1' : '0.01'"
               [(ngModel)]="max"
               class="two-column-input"
               [formControl]="maxFormControl"
               [placeholder]="maximumPlaceholder"></ion-input>

    <div *ngFor="let error of errors"
         class="error">
        {{ error }}
    </div>

    <div class="clearfix"></div>
</div>