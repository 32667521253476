<ion-card class="fullcontact-insights-card">
    <ion-card-header>
        Profile Insights
        <span *ngIf="!isInitializing && person.apiResult && hasLowConfidence()">
            <span class="fullcontact-insights-card--low-confidence">
                <strong>Low insight confidence</strong>
            </span>
            The data source we pull from has given these insights a low likelihood of being
            connected with the person being displayed. We still show them just in case
            they are a match, but caution that it may be inaccurate.
        </span>
    </ion-card-header>

    <ion-card-content *ngIf="!isInitializing && person.apiResult">
        <div class="fullcontact-insights-card--insights">
            <div *ngIf="person.apiResult.contactInfo"
                 class="fullcontact-insights-card--name">
                <strong>{{ person.apiResult.contactInfo.fullName }}</strong>
            </div>
            <div>
                <span *ngIf="person.apiResult.demographics && person.apiResult.demographics.ageRange">
                    {{ person.apiResult.demographics.ageRange }}yo
                </span>
                <span *ngIf="person.apiResult.demographics && person.apiResult.demographics.gender">
                    {{ person.apiResult.demographics.gender }}
                </span>
                <span *ngIf="person.apiResult.demographics && person.apiResult.demographics.locationGeneral">
                    in {{ person.apiResult.demographics.locationGeneral }}
                </span>
                <div *ngIf="getSocialProfile('twitter')"
                     class="fullcontact-insights-card--bio">
                    <em>{{ getSocialProfile('twitter').bio }}</em>
                </div>
            </div>
            <div class="fullcontact-insights-card--section"
                 *ngIf="person.apiResult.digitalFootprint && person.apiResult.digitalFootprint.topics && person.apiResult.digitalFootprint.topics.length">
                <div class="fullcontact-insights-card--section-header">Interests</div>
                <div>
                    {{ getInterests() }}
                </div>
            </div>
            <div class="fullcontact-insights-card--section"
                 *ngIf="person.apiResult.organizations && person.apiResult.organizations.length">
                <div class="fullcontact-insights-card--section-header">Work</div>
                <div>
                    <div *ngFor="let org of person.apiResult.organizations">
                        <span *ngIf="org.current">
                            {{ org.name }}
                            <span *ngIf="org.title">- {{ org.title }}</span>
                            <span *ngIf="org.startDate">since {{ humanDate(org.startDate) }}</span>
                        </span>
                    </div>
                    <div>
                        <span class="fullcontact-insights-card--popover-link"
                              (click)="showAllOrganizations($event)">
                            View all ({{ person.apiResult.organizations.length }})
                            <ion-icon material-icons name="arrow_drop_down"></ion-icon>
                        </span>
                    </div>
                </div>
            </div>
            <div class="fullcontact-insights-card--section"
                 *ngIf="person.apiResult.socialProfiles && person.apiResult.socialProfiles.length">
                <div class="fullcontact-insights-card--section-header">Social Profiles</div>
                <div>
                    <span *ngIf="person.apiResult.socialProfiles && getSocialProfile('linkedin')">
                        <a href="{{ getSocialProfile('linkedin').url }}" target="_linkedin" rel="noopener noreferrer">LinkedIn</a>,
                    </span>
                    <span *ngIf="person.apiResult.socialProfiles && getSocialProfile('twitter')">
                        <a href="{{ getSocialProfile('twitter').url }}" target="_twitter" rel="noopener noreferrer">Twitter</a>,
                    </span>
                    <span class="fullcontact-insights-card--popover-link"
                          (click)="showAllSocialProfiles($event)">
                        View all ({{ person.apiResult.socialProfiles.length }})
                        <ion-icon material-icons name="arrow_drop_down"></ion-icon>
                    </span>
                </div>
            </div>
        </div>
        <a class="fullcontact-attribution"
           href="http://fullcontact.com"
           title="Powered by FullContact"
           target="_blank">
            <img src="https://s3.amazonaws.com/fullcontact-static/js/badges/fc-badge-light.png"
                 alt="Powered by FullContact" />
        </a>
    </ion-card-content>

    <ion-card-content *ngIf="isInitializing">
        <busy-panel message="Loading insights ..."></busy-panel>
    </ion-card-content>
</ion-card>
