<ion-card *ngIf="showCard">
    <ion-card-header>Additional Information</ion-card-header>
    <ion-item>
        <div *ngIf="pipelineObject.commissionAmount"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Commission amount</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.commissionAmount | currency:'USD':true }}
            </p>
        </div>
        <div *ngIf="pipelineObject.probability"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Probability</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.probability }}%
            </p>
        </div>
        <div *ngIf="pipelineObject.sellerPriceExpectation"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Seller price expectation</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.sellerPriceExpectation | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.brokerProposedPrice"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Broker proposed price</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.brokerProposedPrice | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.pursuitDeliveryDate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Pursuit delivery date</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.pursuitDeliveryDate | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.askingPrice || pipelineObject.askingPriceActual"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Asking price</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.askingPrice || pipelineObject.askingPriceActual | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.netOperatingIncome || pipelineObject.noi"
             class="additional-information-card-info">
            <p class="additional-information-card--title">NOI</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.netOperatingIncome || pipelineObject.noi | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.capRate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">CAP rate (%)</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.capRate }}%
            </p>
        </div>
        <div *ngIf="pipelineObject.cashOnCash || pipelineObject.yield"
             class="additional-information-card-info">
            <p class="additional-information-card--title"> Yield (Cash-on-cash %)</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.cashOnCash || pipelineObject.yield }}%
            </p>
        </div>
        <div *ngIf="pipelineObject.totalReturn"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Total return (%)</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.totalReturn }}%
            </p>
        </div>
        <div *ngIf="pipelineObject.spaceType"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Space type</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.spaceType }}
            </p>
        </div>
        <div *ngIf="pipelineObject.spaceSubType"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Space sub-type</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.spaceSubType }}
            </p>
        </div>
        <div *ngIf="pipelineObject.market"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Market</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.market }}
            </p>
        </div>
        <div *ngIf="pipelineObject.subMarket"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Sub market</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.subMarket }}
            </p>
        </div>
        <div *ngIf="pipelineObject.minPrice"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Minimum purchase price</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.minPrice | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.maxPrice"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Maximum purchase price</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.maxPrice | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.minLeaseRate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Minimum lease rate ($/sf)</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.minLeaseRate | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.maxLeaseRate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Maximum lease rate ($/sf)</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.maxLeaseRate | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.minSqft"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Minimum square feet</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.minSqft }}
            </p>
        </div>
        <div *ngIf="pipelineObject.maxSqft"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Maximum square feet</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.maxSqft }}
            </p>
        </div>
        <div *ngIf="pipelineObject.minCapRate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Minimum CAP rate (%)</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.minCapRate }}%
            </p>
        </div>
        <div *ngIf="pipelineObject.minCashOnCash"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Minimum cash-on-cash (%)</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.minCashOnCash }}%
            </p>
        </div>
        <div *ngIf="pipelineObject.minIRR"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Minimum IRR (%)</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.minIRR }}%
            </p>
        </div>
        <div *ngIf="pipelineObject.minLIRR"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Minimum LIRR (%)</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.minLIRR }}%
            </p>
        </div>
        <div *ngIf="pipelineObject.dateOnMarket"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Date on market</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.dateOnMarket | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.dateOffMarket"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Date off market</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.dateOffMarket | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.needDate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Need date</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.needDate | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.repAgreementDate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Rep agreement executed date</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.repAgreementDate | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.expirationDate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Rep agreement expiration date</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.expirationDate | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.contractPrice"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Contract price</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.contractPrice | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.deposit"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Deposit</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.deposit | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.effectiveDate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Effective date</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.effectiveDate | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.contractCloseDate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Contract close date</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.contractCloseDate | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.buyerLoanContact"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Buyer's loan contact</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.buyerLoanContact.fullName }}
            </p>
        </div>
        <div *ngIf="pipelineObject.buyerAttorneyContact"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Buyer's attorney</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.buyerAttorneyContact.fullName }}
            </p>
        </div>
        <div *ngIf="pipelineObject.sellerAttorneyContact"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Seller's attorney</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.sellerAttorneyContact.fullName }}
            </p>
        </div>
        <div *ngIf="pipelineObject.titleCompanyAttorneyContact"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Title company / attorney</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.titleCompanyAttorneyContact.fullName }}
            </p>
        </div>
        <div *ngIf="pipelineObject.researchComplete"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Research complete</p>
            <p class="additional-information-card--number dark-text">
                <ion-icon material-icons
                          color="primary-blue"
                          name="check_box">
                </ion-icon>
            </p>
        </div>
        <div *ngIf="pipelineObject.priceType"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Price type</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.priceType }}
            </p>
        </div>
        <div *ngIf="pipelineObject.commissionPercent"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Commission percent</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.commissionPercent }}%
            </p>
        </div>
        <div *ngIf="pipelineObject.salePrice"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Sale price</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.salePrice | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.downPayment"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Down payment</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.downPayment | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.mortgageAmount"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Mortgage amount</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.mortgageAmount | currency:'USD':true:'1.0-0' }}
            </p>
        </div>
        <div *ngIf="pipelineObject.closeDate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Close date</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.closeDate | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.squareFootage"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Square footage</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.squareFootage }}
            </p>
        </div>
        <div *ngIf="pipelineObject.escalations"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Escalations</p>
            <p
                class="additional-information-card--number dark-text"
                [innerHTML]="pipelineObject.escalations | formattedText"></p>
        </div>
        <div *ngIf="pipelineObject.leaseExpirationDate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Lease expiration date</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.leaseExpirationDate | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.leaseCommencementDate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Lease commencement date</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.leaseCommencementDate | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.leaseTotal"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Lease total</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.leaseTotal }}
            </p>
        </div>
        <div *ngIf="pipelineObject.leaseTermMonths"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Lease term months</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.leaseTermMonths }}
            </p>
        </div>
        <div *ngIf="pipelineObject.leaseType"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Lease type</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.leaseType }}
            </p>
        </div>
        <div *ngIf="pipelineObject.occupancyDate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Occupancy date</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.occupancyDate | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.options"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Options</p>
            <p
                class="additional-information-card--number dark-text"
                [innerHTML]="pipelineObject.options | formattedText"></p>
        </div>
        <div *ngIf="pipelineObject.rentCommencementDate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Rent commencement date</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.rentCommencementDate | dateFormat }}
            </p>
        </div>
        <div *ngIf="pipelineObject.rentalRate"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Rental rate</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.rentalRate }}
            </p>
        </div>
        <div *ngIf="pipelineObject.rentalRateType"
             class="additional-information-card-info">
            <p class="additional-information-card--title">Rental rate type</p>
            <p class="additional-information-card--number dark-text">
                {{ pipelineObject.rentalRateType }}
            </p>
        </div>
    </ion-item>
</ion-card>