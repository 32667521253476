import { PipeTransform } from '@angular/core';
var ReplacePipe = /** @class */ /*@__PURE__*/ (function () {
    function ReplacePipe() {
    }
    ReplacePipe.prototype.transform = function (value, pattern, replace, flags) {
        if (flags === void 0) {
            flags = 'g';
        }
        if (!value) {
            return '';
        }
        if (!pattern || replace === null || replace === undefined) {
            return value;
        }
        var regex = new RegExp(pattern, flags);
        return value.replace(regex, replace);
    };
    return ReplacePipe;
}());
export { ReplacePipe };
