import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StatesService } from '@apto/services';
var StateSelect = /** @class */ /*@__PURE__*/ (function () {
    function StateSelect(stateService) {
        this.stateService = stateService;
    }
    StateSelect.prototype.ngOnInit = function () {
        var code = this.parentForm.controls[this.groupName].value.state;
        this.displayValue = this.stateService.getState(code) || code;
    };
    StateSelect.prototype.setFormValue = function (stateLabel) {
        var state = this.stateService.values.find(function (v) { return v.label === stateLabel.value; });
        var form = this.parentForm.controls[this.groupName];
        form.controls.state.patchValue(state.value);
        this.displayValue = state.label;
    };
    StateSelect.prototype.values = function () {
        return this.stateService.values.map(function (v) { return v.label; });
    };
    return StateSelect;
}());
export { StateSelect };
