import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NavParams, ToastController, ViewController } from 'ionic-angular';
import { OfferModel } from '@apto/models';
import { BaseSlideModal } from '@apto/shared/modals/base-slide-modal/base-slide-modal';
import { TOAST_DURATION } from '@apto/shared/constants';
import { AnalyticsService, OffersService } from '@apto/services';
import { EditOfferForm } from '@apto/pn/components/edit-offer-form/edit-offer-form';
export var OffersModalMode = /*@__PURE__*/ (function (OffersModalMode) {
    OffersModalMode[OffersModalMode["LIST"] = 0] = "LIST";
    OffersModalMode[OffersModalMode["VIEW"] = 1] = "VIEW";
    OffersModalMode[OffersModalMode["EDIT"] = 2] = "EDIT";
    return OffersModalMode;
})({});
import './offers-modal.scss';
var OffersModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(OffersModal, _super);
    function OffersModal(analyticsService, navParams, offersService, toastController, viewController) {
        var _this = _super.call(this, viewController, navParams) || this;
        _this.analyticsService = analyticsService;
        _this.offersService = offersService;
        _this.toastController = toastController;
        _this.Modes = OffersModalMode;
        _this.records = [];
        _this.modalUrls = new Map([
            [OffersModalMode.VIEW, '/:taskId']
        ]);
        _this.mode = navParams.get('mode') || OffersModalMode.LIST;
        _this.title = navParams.get('title') || 'Open Offers';
        _this.selectedRecord = _this.navParams.get('selectedRecord') || new OfferModel();
        return _this;
    }
    Object.defineProperty(OffersModal.prototype, "showAdditionalInformation", {
        get: function () {
            return (this.selectedRecord.feasibilityPeriod ||
                this.selectedRecord.financingPeriod ||
                this.selectedRecord.closeDate ||
                this.selectedRecord.initialDeposit ||
                this.selectedRecord.additionalDeposit ||
                this.selectedRecord.independentConsideration);
        },
        enumerable: true,
        configurable: true
    });
    OffersModal.prototype.save = function () {
        return __awaiter(this, void 0, void 0, function () {
            var formResult, savedOffer;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        formResult = this.editOfferForm.save();
                        if (!(formResult && formResult.offer))
                            return [3 /*break*/, 2];
                        this.editOfferForm.isSaving = true;
                        this.showToast(formResult.offer);
                        return [4 /*yield*/, this.offersService.upsert(formResult.offer)];
                    case 1:
                        savedOffer = _a.sent();
                        if (savedOffer && !this.selectedRecord.listingId) {
                            this.selectedRecord = savedOffer;
                        }
                        this.editOfferForm.isSaving = false;
                        if (this.initialMode === this.Modes.EDIT) {
                            this.dismiss();
                        }
                        else {
                            this.view(savedOffer);
                        }
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    OffersModal.prototype.delete = function () {
        this.offersService.delete([this.selectedRecord._id]);
        this.dismiss();
    };
    OffersModal.prototype.showToast = function (offer) {
        var toast = this.toastController.create({
            duration: TOAST_DURATION,
            message: offer._id ? 'Updating Offer' : 'Saving Offer',
            showCloseButton: true
        });
        toast.present();
    };
    return OffersModal;
}(BaseSlideModal));
export { OffersModal };
