import { PopoverController } from 'ionic-angular';
import { EventEmitter } from '@angular/core';
import { SelectPopover } from '../';
import './apto-ionic-select.scss';
// This component should be used for any selector NOT found within a form.
// For selectors in a form, use the 'select-field' component.
var AptoIonicSelect = /** @class */ /*@__PURE__*/ (function () {
    function AptoIonicSelect(popoverController) {
        this.popoverController = popoverController;
        this.onSelectOption = new EventEmitter();
        this.tries = 0;
    }
    AptoIonicSelect.prototype.change = function (ev) {
        var _this = this;
        if (this.tries < 2 && !this.options) {
            setTimeout(function () {
                _this.tries += 1;
                _this.change(ev);
            }, 500);
            return;
        }
        this.tries = 0;
        var popover = this.popoverController.create(SelectPopover, {
            options: this.options.map(function (option) { return ({ value: option }); }),
            value: this.value
        }, { cssClass: this.cssClass });
        popover.onDidDismiss(function (value) {
            if (value || value === '') {
                _this.value = value;
                _this.onSelectOption.emit({ value: value });
            }
        });
        popover.present({ ev: ev });
    };
    return AptoIonicSelect;
}());
export { AptoIonicSelect };
