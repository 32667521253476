<ion-card class="contact-leasing-portfolio-tabs-card">
    <ion-card-header>
        <ion-buttons start>
            <button
                ion-button
                clear
                small
                [ngClass]="{'active': currentTab === tabTypes.PORTFOLIO}"
                (click)="changeTab(tabTypes.PORTFOLIO)"
                data-automation="owns-tab-button">
                Owns ({{ properties && properties.length || 0 }})
            </button>
            <button
                ion-button
                clear
                small
                [ngClass]="{'active': currentTab === tabTypes.LEASES}"
                (click)="changeTab(tabTypes.LEASES)"
                data-automation="leases-tab-button">
                Leases ({{ totalLeases }})
            </button>
        </ion-buttons>
    </ion-card-header>

    <ion-card-content>
        <ng-container *ngIf="currentTab === tabTypes.PORTFOLIO">
            <ng-container *ngIf="!isInitializingLeases">
                <ion-list *ngIf="shownProperties && shownProperties.length">
                    <prop-item
                        *ngFor="let property of shownProperties"
                        [property]="property"
                        [ownership]="property.ownership"
                        [editOwnership]="false"
                        [hoveredProperty]="hoveredProperty"
                        [isImageShown]="true"
                        [isLoadingChildSpaceCounts]="isLoadingChildSpaceCounts"
                        [isShownOnMap]="false"
                        [childSpaceCount]="childSpaceCounts && childSpaceCounts[property._id]"
                        [showSpaceFeature]="showSpaceFeature"
                        (onAddSpace)="onAddSpace.emit({ property: property })"
                        (onMouseover)="onItemHovered.emit({ property: property, isHovered: true })"
                        (onMouseout)="onItemHovered.emit({ property: property, isHovered: false })"
                        (onClick)="onViewProperty.emit({ property: property })"
                        (onViewSpaces)="onViewSpaces.emit({ property: property })"></prop-item>
                </ion-list>

                <ion-item
                    *ngIf="!shownProperties || !shownProperties.length"
                    class="empty-card">
                    <ion-icon
                        name="business"
                        material-icons
                        circle
                        small
                        color="white"
                        item-left></ion-icon>
                    Add properties
                </ion-item>

                <ion-row class="card-footer-buttons">
                    <ion-col>
                        <button
                            *ngIf="shownProperties && properties && properties.length > shownProperties.length"
                            ion-button
                            clear
                            (click)="onViewAllPortfolio.emit({ properties: properties })"
                            data-automation="toggle-visible-properties">
                            VIEW ALL
                            <span *ngIf="properties.length > shownProperties.length" class="contact-leasing-portfolio-tab--visible-count">{{ properties.length }}</span>
                        </button>
                    </ion-col>
                    <ion-col text-right>
                        <button
                            ion-button
                            (click)="onManageOwnershipsModalClicked.emit()"
                            data-automation='manage-ownerships-modal-button'
                            clear>MANAGE PROPERTIES</button>
                    </ion-col>
                </ion-row>
            </ng-container>

            <busy-panel
                *ngIf="isInitializingLeases"
                message="Loading properties..."></busy-panel>
        </ng-container>

        <ng-container *ngIf="currentTab === tabTypes.LEASES">
            <ng-container *ngIf="!isInitializingLeases">
                <ion-list *ngIf="shownLeases && shownLeases.length">
                    <lease-item
                        *ngFor="let lease of shownLeases"
                        [hoveredProperty]="hoveredProperty"
                        [lease]="lease"
                        [previousLeases]="lease.previousLeases"
                        (onClick)="onLeaseClicked.emit($event)"
                        (onMouseover)="onItemHovered.emit({ property: lease.property, isHovered: true })"
                        (onMouseout)="onItemHovered.emit({ property: lease.property, isHovered: false })"></lease-item>
                    <div class="contact-leasing-portfolio-tab--lease-key">Lease expiration
                        <span class="lease-item--marker--lease-warning">&lt; 2y</span>
                        <span class="lease-item--marker--lease-danger">&lt; 12m</span>
                    </div>
                </ion-list>
                <ion-item
                    *ngIf="!shownLeases || !shownLeases.length"
                    class="empty-card">
                    <ion-icon
                        name="location_city"
                        material-icons
                        circle
                        small
                        color="white"
                        item-left></ion-icon>
                    Add leases
                </ion-item>

                <ion-row class="card-footer-buttons">
                    <ion-col>
                        <button
                            *ngIf="shownLeases && leases && leases.length > 3"
                            ion-button
                            clear
                            (click)="onLeasesToggleVisible.emit($event)"
                            data-automation="toggle-visible-leases">
                            {{ leases.length === shownLeases.length ? 'ONLY VIEW 3' : 'VIEW ALL' }}
                            <span
                                *ngIf="leases.length > shownLeases.length"
                                class="contact-leasing-portfolio-tab--visible-count">{{ leases.length }}</span>
                        </button>
                    </ion-col>
                    <ion-col text-right>
                        <button
                            ion-button
                            (click)="onAddLeaseClicked.emit($event)"
                            data-automation="add-contact-lease"
                            clear>ADD LEASE</button>
                    </ion-col>
                </ion-row>
            </ng-container>

            <busy-panel
                *ngIf="isInitializingLeases"
                message="Loading leases..."></busy-panel>
        </ng-container>
    </ion-card-content>
</ion-card>
