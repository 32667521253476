<ion-card class="company-related-contacts-card">
    <ion-card-header>Related contacts</ion-card-header>
    <ion-card-content *ngIf="!isInitializing">
        <ion-list>
            <contact-item *ngFor="let contact of contacts"
                          [contact]="contact"
                          (onCall)="call($event)"
                          (onClick)="onClick.emit({ contact: contact })"
                          (onEmail)="email($event)"></contact-item>
        </ion-list>

        <ion-item *ngIf="!contacts || !contacts.length"
                  class="empty-card">
            <ion-icon name="account_circle"
                      material-icons
                      circle
                      small
                      color="white"
                      item-left></ion-icon>
            Add contacts
        </ion-item>
        <ion-row class="card-footer-buttons">
            <ion-col text-right>
                <button
                        ion-button
                        (click)="onManageContactsClicked.emit($event)"
                        data-automation='manage-contacts-button'
                        clear>MANAGE CONTACTS</button>
            </ion-col>
        </ion-row>
    </ion-card-content>

    <ion-card-content *ngIf="isInitializing">
        <busy-panel message="Loading related contacts ..."></busy-panel>
    </ion-card-content>
</ion-card>
