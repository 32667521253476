<ion-card class="add-call-list-members-from-companies-card">
    <ion-card-header>
        Selected Companies
    </ion-card-header>
    <ion-card-content>
        <ion-item>
            <div class="add-call-list-members-from-companies-card--media-object">
                <ion-icon material-icons
                        small
                        name="account_circle"></ion-icon>
                <div class="add-call-list-members-from-companies-card--count-wrapper">
                    <apto-paragraph>{{ companies.length }} Companies</apto-paragraph>
                    <apto-help-text>
                        All related contacts are included
                    </apto-help-text>
                </div>
            </div>
        </ion-item>
        <div *ngIf="errorState.hasError"
             class="add-call-list-members-from-companies-card--call-list-limit-error">
            {{ errorState.errorMessage }}
        </div>
    </ion-card-content>
    <ion-row class="card-footer-buttons">
        <ion-col>
            <button ion-button
                    clear
                    class="clear-all-button"
                    (click)="onClearAllClick();">
                CLEAR ALL
            </button>
        </ion-col>
        <ion-col text-right>
            <call-list-dropdown
                [callLists]="allCallLists"
                [disabled]="isInitializingCallLists"
                (onNewCallListSelect)="onNewCallListSelect()"
                (onExistingCallListSelect)="onExistingCallListSelect($event)">
            </call-list-dropdown>
        </ion-col>
    </ion-row>
</ion-card>