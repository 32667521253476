import { Router } from '@angular/router';
import { FeaturesService } from '@apto/services';
var PropertyMapGuard = /** @class */ /*@__PURE__*/ (function () {
    function PropertyMapGuard(featuresService, router) {
        this.featuresService = featuresService;
        this.router = router;
    }
    PropertyMapGuard.prototype.canActivate = function (route) {
        if (this.featuresService.isFeatureEnabled('mapping_v1')) {
            return true;
        }
        else {
            var urlParts = route.url.toString();
            var newURl = urlParts.replace('property-map', 'properties');
            this.router.navigate(newURl.split(','));
            return false;
        }
    };
    return PropertyMapGuard;
}());
export { PropertyMapGuard };
