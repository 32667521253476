import * as moment from 'moment';
import { FormBuilder, Validators } from '@angular/forms';
import { TaskModel } from '@apto/models';
var TaskFormsService = /** @class */ /*@__PURE__*/ (function () {
    function TaskFormsService(formBuilder) {
        this.formBuilder = formBuilder;
    }
    TaskFormsService.prototype.createActivityFormGroup = function (activity) {
        return this.createFormGroup(activity, true);
    };
    TaskFormsService.prototype.createActivityModel = function (formGroup) {
        return this.createModel(formGroup, true);
    };
    TaskFormsService.prototype.createTaskFormGroup = function (task) {
        return this.createFormGroup(task, false);
    };
    TaskFormsService.prototype.createTaskModel = function (formGroup) {
        return this.createModel(formGroup, false);
    };
    TaskFormsService.prototype.createFormGroup = function (task, isActivity) {
        var validateDueDate = function (control) {
            if (!control.value) {
                return null;
            }
            var date = moment.utc(control.value);
            var isInvalidTask = !date.isSameOrAfter(moment(), 'day');
            var isInvalidActivity = date.isAfter(moment(), 'day');
            if (isActivity && isInvalidActivity) {
                return { invalidFutureActivity: true };
            }
            else if (task && !isActivity && isInvalidTask) {
                return { invalidPastTask: true };
            }
            else {
                return null;
            }
        };
        var getDefaultDueDate = function () {
            if (task && task.dueDate) {
                return moment.utc(task.dueDate).format('YYYY-MM-DD');
            }
            return null;
        };
        var getDefaultFormValue = function (fieldName) {
            return (task) ? task[fieldName] : null;
        };
        var diff = task.dueDate ? moment.utc(task.dueDate).diff(moment.utc().startOf('D')) : 0;
        var diffDays = diff / (3600 * 24 * 1000);
        var diffConvert = function (value) {
            return [1, 3, 7, 30, 90].includes(value) ? String(value) : 'select';
        };
        var formGroup = this.formBuilder.group({
            id: getDefaultFormValue('_id'),
            callResult: getDefaultFormValue('callResult'),
            contactId: [getDefaultFormValue('contactId')],
            description: getDefaultFormValue('description'),
            dueDate: [getDefaultDueDate(), [Validators.required, validateDueDate]],
            isComplete: getDefaultFormValue('isComplete') || false,
            marketingStatus: getDefaultFormValue('marketingStatus'),
            priority: task && task.priority === TaskModel.PRIORITY.HIGH,
            ownerName: getDefaultFormValue('ownerName'),
            subject: [getDefaultFormValue('subject'), Validators.required],
            type: [getDefaultFormValue('type') || TaskModel.TYPE.CALL, Validators.required],
            quickDueDateOption: (isActivity || task.dueDate) ? diffConvert(diffDays) : '3',
            whatId: getDefaultFormValue('whatId')
        });
        if (!isActivity) {
            formGroup.controls.subject.setValidators(Validators.required);
        }
        return formGroup;
    };
    TaskFormsService.prototype.createModel = function (formGroup, isActivity) {
        var task = new TaskModel({
            _id: formGroup.controls.id.value || undefined,
            callResult: formGroup.controls.callResult.value,
            contactId: formGroup.controls.contactId.value,
            description: formGroup.controls.description.value || undefined,
            dueDate: formGroup.controls.dueDate.value,
            isComplete: formGroup.controls.isComplete.value || false,
            marketingStatus: formGroup.controls.marketingStatus.value || undefined,
            ownerName: formGroup.controls.ownerName.value,
            priority: formGroup.controls.priority.value ? TaskModel.PRIORITY.HIGH : TaskModel.PRIORITY.NORMAL,
            subject: formGroup.controls.subject.value,
            type: formGroup.controls.type.value,
            whatId: formGroup.controls.whatId.value
        });
        if (isActivity) {
            task.setDefaultSubject();
        }
        return task;
    };
    return TaskFormsService;
}());
export { TaskFormsService };
