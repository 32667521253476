import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { AfterViewInit, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from 'ionic-angular';
import { PropertyModel } from '@apto/models';
import { AnalyticsService, PropertiesService, TypesService } from '@apto/services';
import { LogsService } from '@apto/ionic-lib/services';
import { AppcuesService, MetricsService } from '@apto/shared';
import { LIST_VIEW_MAX_RECORDS } from '@apto/shared/constants';
import { DateRangeFilter, FilterPanel, RangeFilter, SearchFilter, SelectFilter, SelectMapFilter, TagsFilter } from '@apto/pn/components';
import { PropertyDetailsModal, PropertyDetailsModalMode } from '@apto/pn/modals';
import { BaseListPage } from '../base-list-page/base-list-page';
import './properties.scss';
var PropertiesPage = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(PropertiesPage, _super);
    function PropertiesPage(appcuesService, analyticsService, logsService, metricsService, modalController, propertiesService, route, typesService, zone) {
        var _this = _super.call(this) || this;
        _this.appcuesService = appcuesService;
        _this.analyticsService = analyticsService;
        _this.logsService = logsService;
        _this.metricsService = metricsService;
        _this.modalController = modalController;
        _this.propertiesService = propertiesService;
        _this.route = route;
        _this.typesService = typesService;
        _this.zone = zone;
        _this.LIST_VIEW_MAX_RECORDS = LIST_VIEW_MAX_RECORDS;
        _this.additionalFiltersAreShown = false;
        _this.breakdown = '';
        _this.hoveredProperty = new PropertyModel();
        _this.iconMap = {
            active: '/icon/property-active.png',
            primary: '/icon/property-primary.png',
            activeHovered: '/icon/property-active-hovered.png',
            primaryHovered: '/icon/property-primary-hovered.png'
        };
        _this.imageLoadErrors = {};
        _this.isInitializingProperties = true;
        _this.listType = 'recent';
        _this.propertyTypes = {};
        _this.sortMap = {
            Name: { name: 1 },
            Price: { salePrice: -1 }
        };
        _this.totalProperties = 0;
        _this.subscriptions = [];
        _this.sortKeys = Object.keys(_this.sortMap);
        _this.selectedSortKey = _this.sortKeys[0];
        return _this;
    }
    PropertiesPage.prototype.ngOnInit = function () {
        var _this = this;
        this.metricsService.start('Properties Page');
        var propertyId = this.route.snapshot.paramMap.get('propertyId');
        if (propertyId) {
            this.metricsService.setRouteName('/properties/:propertyId');
        }
        else {
            this.metricsService.setRouteName('/properties');
        }
        this.createFilterPanel();
        this.subscriptions.push(this.propertiesService.onDeleted.subscribe(function () { return _this.updateProperties(); }));
        this.subscriptions.push(this.propertiesService.onUpserted.subscribe(function () { return _this.updateProperties(); }));
        this.propertiesService.count().then(function (count) {
            _this.totalProperties = count;
        });
        // if no recent properties are returned on page load, switch to the
        // "All Properties" tab and search all properties instead
        this.updateProperties().then(function () {
            if (!_this.items.length) {
                _this.listType = 'all';
                _this.updateProperties();
            }
        });
        this.analyticsService.logEvent('Properties Tab Load');
        this.appcuesService.start();
        this.metricsService.stop('Properties Page');
    };
    PropertiesPage.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.propertyId$ = this.route.paramMap.subscribe(function (paramMap) {
            return __awaiter(_this, void 0, void 0, function () {
                var properties;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!paramMap.get('propertyId')) {
                                return [2 /*return*/];
                            }
                            return [4 /*yield*/, this.propertiesService.in([paramMap.get('propertyId')])];
                        case 1:
                            properties = _a.sent();
                            if (properties.length > 0) {
                                this.viewProperty(properties[0]);
                            }
                            return [2 /*return*/];
                    }
                });
            });
        });
    };
    PropertiesPage.prototype.ngOnDestroy = function () {
        this.propertyId$.unsubscribe();
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    PropertiesPage.prototype.addProperty = function () {
        var propertyDetailsModal = this.modalController.create(PropertyDetailsModal, {
            selectedProperty: new PropertyModel(),
            mode: PropertyDetailsModalMode.EDIT,
            types: Object.keys(this.propertyTypes)
        }, {
            cssClass: 'large-modal'
        });
        propertyDetailsModal.present({ updateUrl: false });
    };
    PropertiesPage.prototype.createFilterPanel = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var picklistOptions, types;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.searchFilterPanel.add(SearchFilter, {
                            placeholder: 'Find a property',
                            queryKey: 'name'
                        });
                        return [4 /*yield*/, this.propertiesService.getPicklistOptions([
                                'market',
                                'submarket',
                                'tenancy'
                            ])];
                    case 1:
                        picklistOptions = _a.sent();
                        return [4 /*yield*/, this.typesService.fetch()];
                    case 2:
                        types = _a.sent();
                        this.propertyTypes = types.propertyTypes;
                        this.propertiesFilterPanel.add(SelectFilter, {
                            label: 'Property type',
                            queryKey: 'RecordTypeId',
                            optionsList: Object.keys(this.propertyTypes)
                        });
                        this.propertiesFilterPanel.add(RangeFilter, {
                            label: 'Property value',
                            minKey: 'salePrice',
                            maxKey: 'salePrice',
                            minimumPlaceholder: 'Minimum',
                            maximumPlaceholder: 'Maximum'
                        });
                        this.propertiesFilterPanel.add(RangeFilter, {
                            label: 'Square footage',
                            minKey: 'squareFootage',
                            maxKey: 'squareFootage',
                            minimumPlaceholder: 'Minimum',
                            maximumPlaceholder: 'Maximum'
                        });
                        this.propertiesFilterPanel.add(TagsFilter, {
                            label: 'ZIP',
                            queryKey: 'postalCode',
                            placeholder: 'Enter zip codes separated by spaces',
                            secondaryPlaceholder: 'Enter zip codes separated by spaces',
                            separatorKeyCodes: [13, 32] // enter and space
                        });
                        this.propertiesFilterPanel.add(SelectMapFilter, {
                            label: 'Market',
                            queryKey: 'market',
                            optionsMap: picklistOptions.market || {}
                        });
                        this.additionalPropertiesFilterPanel.add(SelectMapFilter, {
                            label: 'Submarket',
                            queryKey: 'submarket',
                            optionsMap: picklistOptions.submarket || {}
                        });
                        this.additionalPropertiesFilterPanel.add(DateRangeFilter, {
                            label: 'Sold date',
                            queryKey: 'saleDate',
                            startDatePlaceholder: 'Start date',
                            stopDatePlaceholder: 'End date'
                        });
                        this.additionalPropertiesFilterPanel.add(RangeFilter, {
                            label: 'Lot size range (acres)',
                            minKey: 'landAcres',
                            maxKey: 'landAcres',
                            minimumPlaceholder: 'Minimum',
                            maximumPlaceholder: 'Maximum'
                        });
                        this.additionalPropertiesFilterPanel.add(RangeFilter, {
                            label: 'Year built',
                            minKey: 'yearBuilt',
                            maxKey: 'yearBuilt',
                            convertToStrings: true,
                            minimumPlaceholder: 'Minimum',
                            maximumPlaceholder: 'Maximum',
                            wholeNumbers: true
                        });
                        this.additionalPropertiesFilterPanel.add(SelectMapFilter, {
                            label: 'Tenancy',
                            queryKey: 'tenancy',
                            optionsMap: picklistOptions.tenancy || {}
                        });
                        this.searchFilterPanel.onQueryChange.subscribe(function (filters) { return _this.updateFilters(); }, function (err) { return _this.logsService.error(err); });
                        this.additionalPropertiesFilterPanel.onQueryChange.subscribe(function (filters) { return _this.updateFilters(); }, function (err) { return _this.logsService.error(err); });
                        this.propertiesFilterPanel.onQueryChange.subscribe(function (filters) { return _this.updateFilters(); }, function (err) { return _this.logsService.error(err); });
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertiesPage.prototype.filtersAreEmpty = function () {
        var additionalPropertiesFilterPanelIsEmpty = !this.additionalFiltersAreShown || this.additionalPropertiesFilterPanel.isEmpty();
        return this.searchFilterPanel.isEmpty() && this.propertiesFilterPanel.isEmpty() && additionalPropertiesFilterPanelIsEmpty;
    };
    PropertiesPage.prototype.getBreakdown = function (properties) {
        var breakdown = '';
        var types = {};
        // get totals of each type
        properties.forEach(function (property) {
            var type = property.type;
            if (!types[type]) {
                types[type] = 0;
            }
            types[type]++;
        });
        // get strings for top 3 types
        for (var i = 0; i < 3; i++) {
            if (Object.keys(types).length === 0) {
                break;
            }
            var max = Object.keys(types).reduce(function (a, b) {
                return types[a] > types[b] ? a : b;
            });
            var percent = Math.floor(types[max] / properties.length * 100);
            breakdown += percent + "% " + max + ", ";
            delete types[max];
        }
        return breakdown.substring(0, breakdown.length - 2);
    };
    PropertiesPage.prototype.getFilterQuery = function () {
        var query = FilterPanel.getCombinedQuery(this.searchFilterPanel.getQuery(), this.propertiesFilterPanel.getQuery());
        if (this.additionalFiltersAreShown) {
            return FilterPanel.getCombinedQuery(query, this.additionalPropertiesFilterPanel.getQuery());
        }
        else {
            return query;
        }
    };
    PropertiesPage.prototype.hoverProperty = function (event) {
        var _this = this;
        this.zone.run(function () {
            if (event.isHovered) {
                _this.hoveredProperty = event.property;
            }
            else {
                _this.hoveredProperty = new PropertyModel();
            }
        });
    };
    PropertiesPage.prototype.onImageLoadError = function (failedImgSrc) {
        this.imageLoadErrors[failedImgSrc] = true;
    };
    PropertiesPage.prototype.resetFilters = function () {
        this.additionalPropertiesFilterPanel.resetFilters();
        this.searchFilterPanel.resetFilters();
        this.propertiesFilterPanel.resetFilters();
        this.updateProperties();
    };
    PropertiesPage.prototype.setListType = function (type) {
        this.listType = type;
        if (!this.filtersAreEmpty) {
            this.resetFilters();
        }
        else {
            this.updateProperties();
        }
    };
    PropertiesPage.prototype.sortChanged = function (sortKey) {
        this.selectedSortKey = sortKey;
        this.updateProperties();
    };
    PropertiesPage.prototype.toggleFilters = function () {
        this.additionalFiltersAreShown = !this.additionalFiltersAreShown;
    };
    PropertiesPage.prototype.updateFilters = function () {
        if (!this.filtersAreEmpty()) {
            this.listType = 'all';
        }
        this.updateProperties();
    };
    PropertiesPage.prototype.updateProperties = function () {
        return __awaiter(this, void 0, void 0, function () {
            var params, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        this.isInitializingProperties = true;
                        params = {
                            sort: this.sortMap[this.selectedSortKey],
                            limit: LIST_VIEW_MAX_RECORDS
                        };
                        if (!(this.listType === 'recent'))
                            return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.propertiesService.recent(params)];
                    case 1:
                        _a.items = _c.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        params.where = this.getFilterQuery();
                        if (this.selectedSortKey === 'Price') {
                            params.where.salePrice = { $ne: null };
                        }
                        _b = this;
                        return [4 /*yield*/, this.propertiesService.fetch(params)];
                    case 3:
                        _b.items = _c.sent();
                        _c.label = 4;
                    case 4:
                        this.breakdown = this.getBreakdown(this.items);
                        this.isInitializingProperties = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    PropertiesPage.prototype.viewProperty = function (property) {
        if (!property || !property._id) {
            return;
        }
        var propertyDetailsModal = this.modalController.create(PropertyDetailsModal, {
            selectedProperty: property,
            mode: PropertyDetailsModalMode.VIEW,
            types: Object.keys(this.propertyTypes),
            url: {
                params: { propertyId: property._id },
                baseHash: '#/properties'
            }
        }, {
            cssClass: 'large-modal'
        });
        propertyDetailsModal.present({ updateUrl: false });
    };
    return PropertiesPage;
}(BaseListPage));
export { PropertiesPage };
