var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injector } from '@angular/core';
import { JOB_MAX_POLLS, JOB_POLL_DELAY, SERVICE_NAMES, TOTAL_REQS_MAX } from '../constants';
import { AnalyticsService } from '../analytics/analytics-service';
import { CacheService } from '../cache/cache-service';
import { ConfigService } from '../config/config-service';
import { AlertController } from 'ionic-angular';
var salesforceHttpErrorDisplaying = false;
var BaseService = /** @class */ /*@__PURE__*/ (function () {
    function BaseService(injector, alertController) {
        this.injector = injector;
        this.alertController = alertController;
        this.onLogException = new EventEmitter();
        this.onDeleted = new EventEmitter();
        this.onUpserted = new EventEmitter();
        this.labelAttribute = 'name'; // used by auto-complete services
        this.analyticsService = this.injector.get(AnalyticsService);
        this.cacheService = this.injector.get(CacheService);
        this.configService = this.injector.get(ConfigService);
        this.http = this.injector.get(HttpClient);
        this.defaultQueryParams = {
            appName: this.configService.appName,
            omitDeleted: true
        };
        this.onDeleted.subscribe(function (event) {
            BaseService.onAnyDeletion.emit(event);
        });
        this.onUpserted.subscribe(function (event) {
            BaseService.onAnyUpsertion.emit(event);
        });
    }
    BaseService.prototype.fetch = function (params, shouldCacheResults, force) {
        if (shouldCacheResults === void 0) {
            shouldCacheResults = true;
        }
        return __awaiter(this, void 0, void 0, function () {
            var cachedResults, results;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!params && !force) {
                            cachedResults = this.cacheService.getAll(this.serviceName);
                            if (cachedResults && cachedResults.length) {
                                return [2 /*return*/, cachedResults];
                            }
                        }
                        return [4 /*yield*/, this.request('get', '', params)];
                    case 1:
                        results = _a.sent();
                        return [2 /*return*/, results && this.process(results, shouldCacheResults) || []];
                }
            });
        });
    };
    BaseService.prototype.in = function (ids, cacheBust) {
        if (cacheBust === void 0) {
            cacheBust = false;
        }
        var cachedResults = [];
        ids = ids.filter(function (id) { return id && id !== 'null'; }); // remove null ids from array;
        if (!ids.length) {
            return Promise.resolve([]);
        }
        if (!cacheBust) {
            cachedResults = this.cacheService.get(this.serviceName, ids) || [];
            ids = ids.filter(function (id) {
                // only query for ids that weren't cached
                return !cachedResults.find(function (cachedResult) { return cachedResult._id === id; });
            });
        }
        if (!ids.length) {
            return Promise.resolve(cachedResults);
        }
        var chunkedQueries = [];
        while (ids.length > 0) {
            var params = {
                where: {
                    _id: {
                        $in: ids.splice(0, 200)
                    }
                }
            };
            chunkedQueries.push(this.fetch(params));
        }
        return Promise.all(chunkedQueries)
            .then(function (results) {
            return [].concat.apply(cachedResults, results); // flatten results arrays
        });
    };
    BaseService.prototype.upsert = function (upsertObject, refresh) {
        var _this = this;
        if (refresh === void 0) {
            refresh = true;
        }
        var method;
        var url;
        var isNew = !upsertObject._id;
        if (!isNew) {
            this.analyticsService.logEvent(this.serviceName + " Edited");
            method = 'put';
            url = this.endpoint + "/" + upsertObject._id;
        }
        else {
            this.analyticsService.logEvent(this.serviceName + " Added");
            method = 'post';
            url = "" + this.endpoint;
        }
        return new Promise(function (resolve, reject) {
            _this.http[method](url, upsertObject)
                .subscribe(function (response) {
                if (response.status === 'error') {
                    return reject(response);
                }
                return resolve(response.data);
            }, function (ex) {
                if (!ex) {
                    return;
                }
                // If a user tries to update an object that they've already deleted in Salesforce, we catch
                // the error and ask them if they want to recreate it or leave it deleted. If they chose to
                // leave it deleted, we still propogate the error to the collection can handle it, but we don't
                // need to log it.
                if (ex.error !== 'delete') {
                    _this.onLogException.emit({ message: "Failed to upsert object " + upsertObject + " for service " + _this.serviceName + ".", ex: ex });
                }
                return reject(ex);
            });
        })
            .then(function (result) { return refresh ? _this.in([result._id], true) : _this.process(result); })
            .then(function (results) {
            if (results && results.length === 1) {
                // Use assign to keep attached objects
                Object.assign(upsertObject, results[0]);
                _this.onUpserted.emit({
                    payload: upsertObject,
                    isNew: isNew,
                    model: _this.model
                });
                return upsertObject;
            }
        })
            .catch(function (err) {
            // if the user selected to delete the object, remove it from memory. it's already been deleted from Salesforce.
            if (err === 'delete') {
                _this.cacheService.remove(_this.serviceName, upsertObject._id);
                _this.onDeleted.emit({ payload: [upsertObject._id], model: _this.model });
            }
            else {
                return Promise.reject(err);
            }
        });
    };
    BaseService.prototype.delete = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var url, errorMessage, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.endpoint + "/" + ids.join(',');
                        errorMessage = "Failed to delete " + ids.join(', ') + " for " + this.serviceName + ".";
                        return [4 /*yield*/, this.http.delete(url)
                                .toPromise()
                                .then(function (res) {
                                _this.cacheService.remove(_this.serviceName, ids);
                                _this.onDeleted.emit({ payload: ids, model: _this.model });
                                _this.analyticsService.logEvent("Deleted " + _this.serviceName);
                                return res;
                            })
                                .catch(function (ex) {
                                _this.onLogException.emit({ message: errorMessage, ex: ex });
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.status === 'error') {
                            throw new Error(result);
                        }
                        return [2 /*return*/, result.data];
                }
            });
        });
    };
    BaseService.prototype.recent = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var query, url, errorMessage, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = this.getQueryString(params);
                        url = this.endpoint + "/recent?" + query;
                        errorMessage = "Failed to fetch for service " + this.serviceName + ".";
                        return [4 /*yield*/, this.http.get(url)
                                .toPromise()
                                .catch(function (ex) {
                                _this.onLogException.emit({ message: errorMessage, ex: ex });
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.status === 'error') {
                            throw new Error(result);
                        }
                        return [2 /*return*/, result.data && this.process(result.data) || []];
                }
            });
        });
    };
    BaseService.prototype.count = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var query, url, errorMessage, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        query = this.getQueryString(params);
                        url = this.endpoint + "/count?" + query;
                        errorMessage = "Failed to get count for service " + this.serviceName + ".";
                        return [4 /*yield*/, this.http.get(url)
                                .toPromise()
                                .catch(function (ex) {
                                _this.onLogException.emit({ message: errorMessage, ex: ex });
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.status === 'error') {
                            throw new Error(result);
                        }
                        return [2 /*return*/, result.data];
                }
            });
        });
    };
    BaseService.prototype.search = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var url, errorMessage, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.endpoint + "/search";
                        errorMessage = "Failed to fetch for service " + this.serviceName + ".";
                        return [4 /*yield*/, this.http.post(url, params)
                                .toPromise()
                                .catch(function (ex) {
                                _this.onLogException.emit({ message: errorMessage, ex: ex });
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.status === 'error') {
                            throw new Error(result);
                        }
                        return [2 /*return*/, result.data && this.process(result.data) || []];
                }
            });
        });
    };
    BaseService.prototype.info = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var url, errorMessage, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.endpoint + "/info/" + id;
                        errorMessage = "Failed to get info for service " + this.serviceName + ".";
                        return [4 /*yield*/, this.http.get(url)
                                .toPromise()
                                .catch(function (ex) {
                                _this.onLogException.emit({ message: errorMessage, ex: ex });
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.status === 'error') {
                            throw new Error(result);
                        }
                        return [2 /*return*/, result.data];
                }
            });
        });
    };
    BaseService.prototype.bulkInsert = function (models) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var url, errorMessage, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.endpoint + "/bulk";
                        errorMessage = "Failed to bulk insert object " + models + " for service " + this.serviceName + ".";
                        return [4 /*yield*/, this.http.post(url, models)
                                .toPromise()
                                .then(function (res) {
                                _this.analyticsService.logEvent("Bulk Inserted " + _this.serviceName);
                                return res;
                            })
                                .catch(function (ex) {
                                _this.onLogException.emit({ message: errorMessage, ex: ex });
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.status === 'error') {
                            throw new Error(result);
                        }
                        return [2 /*return*/, result.data];
                }
            });
        });
    };
    BaseService.prototype.updateInCache = function (entity, key) {
        this.cacheService.remove(this.serviceName, key);
        this.cacheService.add(this.serviceName, key, entity);
    };
    BaseService.prototype.bulkDelete = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var url, errorMessage, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = this.endpoint + "/bulk/" + ids.join(',');
                        errorMessage = "Failed to bulk delete " + ids.join(', ') + " for " + this.serviceName + ".";
                        return [4 /*yield*/, this.http.delete(url)
                                .toPromise()
                                .then(function (res) {
                                _this.onDeleted.emit({ payload: ids, model: _this.model });
                                _this.analyticsService.logEvent("Deleted " + _this.serviceName);
                                _this.cacheService.remove(_this.serviceName, ids);
                                return res;
                            })
                                .catch(function (ex) {
                                _this.onLogException.emit({ message: errorMessage, ex: ex });
                            })];
                    case 1:
                        result = _a.sent();
                        if (result.status === 'error') {
                            throw new Error(result);
                        }
                        return [2 /*return*/, result.data];
                }
            });
        });
    };
    BaseService.prototype.attach = function (idKey, attachToKey, objects) {
        return __awaiter(this, void 0, void 0, function () {
            var ids, attachableObjects;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!objects.length) {
                            return [2 /*return*/, []];
                        }
                        ids = objects
                            .filter(function (object) { return object[idKey]; })
                            .map(function (object) { return object[idKey]; });
                        // passing empty array will query ALL records
                        if (!ids.length) {
                            return [2 /*return*/, objects];
                        }
                        return [4 /*yield*/, this.fetch({
                                where: { _id: { $in: ids } }
                            })];
                    case 1:
                        attachableObjects = _a.sent();
                        attachableObjects.forEach(function (attachableObject) {
                            var objectsToAttachTo = objects.filter(function (obj) { return obj[idKey] === attachableObject._id; });
                            objectsToAttachTo.forEach(function (object) {
                                object[attachToKey] = attachableObject;
                            });
                        });
                        return [2 /*return*/, objects];
                }
            });
        });
    };
    // suports the auto-complete services
    BaseService.prototype.getResults = function (keyword, labelAttribute, defaultOwnerOnly) {
        var _this = this;
        if (labelAttribute === void 0) {
            labelAttribute = this.labelAttribute;
        }
        if (defaultOwnerOnly === void 0) {
            defaultOwnerOnly = false;
        }
        var params = {
            immediate: true,
            limit: 5,
            ownerOnly: defaultOwnerOnly,
            sort: labelAttribute,
            where: {}
        };
        var where = {};
        var likeClause = keyword.trim().split(' ').join('% ');
        where.$or = [
            (_a = {}, _a[labelAttribute] = { $like: likeClause + "%" }, _a),
            (_b = {}, _b[labelAttribute] = { $like: "% " + likeClause + "%" }, _b)
        ];
        params.where = where;
        var query = this.getQueryString(params);
        return this.http.get(this.endpoint + "?" + query).pipe(map(function (results) {
            return results.data.map(function (x) {
                var item = new _this.model(x);
                _this.cacheService.add(_this.serviceName, item._id, item);
                return item;
            });
        }));
        var _a, _b;
    };
    BaseService.prototype.getPicklistOptions = function (fields) {
        return __awaiter(this, void 0, void 0, function () {
            var response, picklistOptions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.request('get', '/picklist-options', { fields: fields.join(',') })];
                    case 1:
                        response = _a.sent();
                        picklistOptions = {};
                        Object.keys(response).forEach(function (key) {
                            picklistOptions[key] = response[key].reduce(function (accumulator, option) {
                                accumulator[option.value] = option.label;
                                return accumulator;
                            }, {});
                        });
                        return [2 /*return*/, picklistOptions];
                }
            });
        });
    };
    BaseService.prototype.request = function (method, path, params, queryCount, origParams, totalReqs) {
        if (params === void 0) {
            params = {};
        }
        if (queryCount === void 0) {
            queryCount = 0;
        }
        if (totalReqs === void 0) {
            totalReqs = 0;
        }
        return __awaiter(this, void 0, void 0, function () {
            var url, result, query, logMessage, httpAlert;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        method = method.toLowerCase();
                        path = path ? path : '';
                        url = this.endpoint + path;
                        if (!(method === 'get' || method === 'delete'))
                            return [3 /*break*/, 2];
                        query = this.getQueryString(params);
                        return [4 /*yield*/, this.http[method](url + "?" + query).toPromise()];
                    case 1:
                        result = _a.sent();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.http[method](url, params).toPromise()];
                    case 3:
                        result = _a.sent();
                        _a.label = 4;
                    case 4:
                        if (result.status === 'error') {
                            throw new Error(result);
                        }
                        if (!(result.data && result.data.jobId))
                            return [3 /*break*/, 6];
                        if (queryCount === 0) {
                            origParams = params;
                        }
                        // if we've hit the max polling attempts, the original job may have failed or stalled,
                        // so we let the client know that it didn't work
                        // We also give the client the ability to reload the page so that they can try again
                        if (queryCount > JOB_MAX_POLLS) {
                            if (totalReqs < TOTAL_REQS_MAX) {
                                // We retry the entire request flow 1 time for a total request count of 2
                                return [2 /*return*/, this.request(method, path, origParams, 0, origParams, ++totalReqs)];
                            }
                            if (!salesforceHttpErrorDisplaying) {
                                logMessage = {
                                    url: url,
                                    alert: true,
                                    title: 'Oops! Something is taking too long'
                                };
                                salesforceHttpErrorDisplaying = true;
                                LE.info(logMessage);
                                httpAlert = this.alertController.create({
                                    title: 'Oops! Something is taking too long',
                                    subTitle: 'Click the button below to refresh your page.',
                                    cssClass: 'http-service-error',
                                    buttons: [
                                        { role: 'refresh', text: 'Refresh Page' },
                                        { role: 'close', text: 'close' }
                                    ]
                                });
                                httpAlert.onDidDismiss(function (data, role) {
                                    if (role && role === 'refresh') {
                                        window.location.reload();
                                    }
                                });
                                return [2 /*return*/, httpAlert.present()];
                            }
                        }
                        if (!!salesforceHttpErrorDisplaying)
                            return [3 /*break*/, 6];
                        return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, JOB_POLL_DELAY); })];
                    case 5:
                        _a.sent();
                        return [2 /*return*/, this.request(method, path, { jobId: result.data.jobId }, ++queryCount, origParams, totalReqs)];
                    case 6: return [2 /*return*/, result.data];
                }
            });
        });
    };
    BaseService.prototype.getQueryString = function (params) {
        params = params || {};
        params = Object.assign({}, this.defaultQueryParams, params);
        if (params.where && params.where.searchTerm === '') {
            delete params.where.searchTerm;
        }
        var queries = [];
        Object.keys(params).map(function (key) {
            queries.push(key + '=' + encodeURIComponent(JSON.stringify(params[key])));
        });
        return queries.join('&');
    };
    BaseService.prototype.process = function (results, cacheResults) {
        var _this = this;
        if (cacheResults === void 0) {
            cacheResults = true;
        }
        if (!this.model) {
            throw new Error('Instantiating this class requires a Model to be set.');
        }
        var result = results.constructor === Array ? results : [results];
        return result.map(function (x) {
            var item = new _this.model(x);
            if (cacheResults) {
                // types service is a special case for keys
                if (_this.serviceName !== SERVICE_NAMES.TYPES) {
                    _this.cacheService.add(_this.serviceName, item._id, item);
                }
            }
            return item;
        });
    };
    BaseService.onAnyDeletion = new EventEmitter();
    BaseService.onAnyUpsertion = new EventEmitter();
    return BaseService;
}());
export { BaseService };
