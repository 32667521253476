<form [formGroup]="companyForm"
      novalidate
      class="edit-company-form edit-form">
    <ion-card>
        <ion-card-header>Company overview</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-label stacked
                           required>Name</ion-label>
                <ion-input formControlName="name"
                           required></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="companyForm.get('name').dirty &&
                                            companyForm.get('name').errors &&
                                            companyForm.get('name').errors.required">
                A company name is required.
            </div>

            <select-field [form]="companyForm"
                          field="type"
                          label="Company type"
                          [options]="types"
                          [required]="true"
                          [cssClass]="'edit-form--select-popover'"></select-field>

            <ion-item formGroupName="phone">
                <ion-label stacked>{{company.phone.label}}</ion-label>
                <ion-input formControlName="number"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="companyForm.get('phone').get('number').dirty &&
                        companyForm.get('phone').get('number').invalid">
                {{validationMessages.PHONE_NUMBER_REGEX}}
            </div>

            <ion-item formGroupName="fax">
                <ion-label stacked>{{company.fax.label}}</ion-label>
                <ion-input formControlName="number"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="companyForm.get('fax').get('number').dirty &&
                        companyForm.get('fax').get('number').invalid">
                {{validationMessages.PHONE_NUMBER_REGEX}}
            </div>

            <ion-item formGroupName="website">
                <ion-label stacked>Website</ion-label>
                <ion-input formControlName="url"></ion-input>
            </ion-item>

            <select-map-field [form]="companyForm"
                              field="category"
                              label="Industry"
                              [options]="picklistOptions.category"
                              [cssClass]="'edit-form--select-popover'"></select-map-field>

            <ion-item>
                <ion-label stacked>Description</ion-label>
                <ion-textarea textarea-elastic
                              class="elastic-text"
                              formControlName="description"></ion-textarea>
            </ion-item>
        </ion-card-content>
    </ion-card>

    <ion-card>
        <ion-card-header>Contact shipping address</ion-card-header>
        <ion-card-content>
            <ion-item-group formGroupName="shippingAddress">
                <ion-item>
                    <ion-label stacked>Street address</ion-label>
                    <ion-input formControlName="street"></ion-input>
                </ion-item>

                <ion-item>
                    <ion-label stacked>City</ion-label>
                    <ion-input formControlName="city"></ion-input>
                </ion-item>

                <state-select [parentForm]="companyForm"
                              [groupName]="'shippingAddress'"
                              [cssClass]="'edit-form--select-popover'"></state-select>

                <ion-item>
                    <ion-label stacked>Country</ion-label>
                    <ion-input formControlName="country"></ion-input>
                </ion-item>

                <ion-item>
                    <ion-label stacked>Zip/Postal code</ion-label>
                    <ion-input formControlName="postalCode"></ion-input>
                </ion-item>
                <div class="field-error"
                    *ngIf="companyForm.get('shippingAddress').get('postalCode').dirty &&
                           companyForm.get('shippingAddress').get('postalCode').errors &&
                           companyForm.get('shippingAddress').get('postalCode').errors.pattern">
                    {{validationMessages.POSTAL_REGEX}}
                </div>
            </ion-item-group>
        </ion-card-content>
    </ion-card>

    <ion-card>
        <ion-card-header>Contact billing address</ion-card-header>
        <ion-card-content>
            <ion-item-group formGroupName="billingAddress">
                <ion-item>
                    <ion-label stacked>Street address</ion-label>
                    <ion-input formControlName="street"></ion-input>
                </ion-item>

                <ion-item>
                    <ion-label stacked>City</ion-label>
                    <ion-input formControlName="city"></ion-input>
                </ion-item>

                <state-select [parentForm]="companyForm"
                              [groupName]="'billingAddress'"
                              [cssClass]="'edit-form--select-popover'"></state-select>

                <ion-item>
                    <ion-label stacked>Country</ion-label>
                    <ion-input formControlName="country"></ion-input>
                </ion-item>

                <ion-item>
                    <ion-label stacked>Zip/Postal code</ion-label>
                    <ion-input formControlName="postalCode"></ion-input>
                </ion-item>
                <div class="field-error"
                    *ngIf="companyForm.get('billingAddress').get('postalCode').dirty &&
                           companyForm.get('billingAddress').get('postalCode').errors &&
                           companyForm.get('billingAddress').get('postalCode').errors.pattern">
                    {{validationMessages.POSTAL_REGEX}}
                </div>
            </ion-item-group>
        </ion-card-content>
    </ion-card>

    <busy-panel *ngIf="isSaving"
                message="Saving company..."></busy-panel>
</form>
