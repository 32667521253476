import { EventEmitter } from '@angular/core';
import { CallListModel } from '@apto/models';
var NEW_LIST_TEXT = 'BUILD A NEW LIST';
var createMenuOptions = function (callLists) {
    var menuOptions = callLists.map(function (callList) {
        return ({
            value: callList.name,
            displayHtml: "<span class=\"dark-text\">" + callList.name + "</span> (" + callList.members.length + " contacts)",
            disabled: false
        });
    });
    menuOptions = menuOptions.concat([
        {
            value: null,
            displayHtml: "<span class=\"add-to-list-button--helper\">\n                        Cannot add to lists over " + CallListModel.CALL_LIST_MEMBER_LIMIT + " contacts\n                      </span>",
            disabled: true
        },
        {
            value: NEW_LIST_TEXT,
            displayHtml: NEW_LIST_TEXT,
            disabled: false
        }
    ]);
    return menuOptions;
};
var ɵ0 = createMenuOptions;
var CallListDropdown = /** @class */ /*@__PURE__*/ (function () {
    function CallListDropdown() {
        this.disabled = false;
        this.buttonText = 'ADD TO LIST';
        this.loadingCallListsButtonText = 'LOADING CALL LISTS';
        this.onNewCallListSelect = new EventEmitter();
        this.onExistingCallListSelect = new EventEmitter();
        this.onNoCallListSelect = new EventEmitter();
        this.options = [];
        this._callLists = [];
    }
    Object.defineProperty(CallListDropdown.prototype, "callLists", {
        get: function () {
            return this._callLists;
        },
        set: function (value) {
            this._callLists = value;
            this.options = createMenuOptions(value);
        },
        enumerable: true,
        configurable: true
    });
    CallListDropdown.prototype.onValueSelect = function (value) {
        if (!value) {
            this.onNoCallListSelect.emit();
        }
        else if (value === NEW_LIST_TEXT) {
            this.onNewCallListSelect.emit();
        }
        else {
            this.onExistingCallListSelect.emit(value);
        }
    };
    return CallListDropdown;
}());
export { CallListDropdown };
export { ɵ0 };
