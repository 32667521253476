import { EventEmitter } from '@angular/core';
import { ContactModel } from '@apto/models';
import { FormBuilder } from '@angular/forms';
import './about-contact-card.scss';
var AboutContactCard = /** @class */ /*@__PURE__*/ (function () {
    function AboutContactCard(formBuilder) {
        this.formBuilder = formBuilder;
        this.onEditContact = new EventEmitter();
        this.editingDetails = false;
    }
    Object.defineProperty(AboutContactCard.prototype, "contact", {
        get: function () {
            return this._contact;
        },
        set: function (value) {
            this._contact = value;
            this.setupForm();
        },
        enumerable: true,
        configurable: true
    });
    AboutContactCard.prototype.ngOnInit = function () {
        this.setupForm();
    };
    AboutContactCard.prototype.editDetails = function () {
        this.editingDetails = !this.editingDetails;
    };
    AboutContactCard.prototype.saveDetails = function () {
        this.editingDetails = !this.editingDetails;
        this.contact.description = this.aboutContactForm.controls.description.value;
        this.onEditContact.emit(this.contact);
    };
    AboutContactCard.prototype.setupForm = function () {
        this.aboutContactForm = this.formBuilder.group({
            description: this.contact && this.contact.description || ''
        });
    };
    return AboutContactCard;
}());
export { AboutContactCard };
