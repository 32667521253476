import { HttpClient, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '@apto/pn/services';
var PrefixService = /** @class */ /*@__PURE__*/ (function () {
    function PrefixService(http, configService) {
        this.http = http;
        this.configService = configService;
        this.typeCache = [];
        this.serviceUrl = this.configService.serviceUrl;
    }
    PrefixService.prototype.getPrefixes = function (types) {
        var _this = this;
        var neededTypes = types.filter(function (type) {
            var typeInCache = _this.typeCache.find(function (_a) {
                var cachedType = _a.type;
                return cachedType === type;
            });
            return !typeInCache;
        });
        if (neededTypes.length === 0) {
            var requestedTypes = this.typeCache.filter(function (_a) {
                var type = _a.type;
                return types.includes(type);
            });
            return of(requestedTypes);
        }
        else {
            var first = neededTypes[0], rest = neededTypes.slice(1);
            var httpParams_1 = new HttpParams().set('types', first);
            rest.forEach(function (param) {
                httpParams_1 = httpParams_1.append('types', param);
            });
            var options = { params: httpParams_1 };
            return this.http.get(this.serviceUrl + "/api/prefixes", options)
                .pipe(map(function (res) {
                (_a = _this.typeCache).push.apply(_a, res);
                var requestedTypes = _this.typeCache.filter(function (_a) {
                    var type = _a.type;
                    return types.includes(type);
                });
                return requestedTypes;
                var _a;
            }));
        }
    };
    return PrefixService;
}());
export { PrefixService };
