<div 
    class="company-item selectable-item clickable">
    <ion-checkbox 
        class="small"
        *ngIf="selectable"
        [ngClass]="{ checked: selected }"
        [ngModel]="selected"
        (ngModelChange)="itemToggled($event)"
        color="primary-blue"
        mode="ios">
    </ion-checkbox>
    <ion-item 
        [attr.detail-push]="onClick.observers.length > 0 ? true : null"
        (click)="onClick.emit(company)">
        <ion-icon 
            class="action-circle"
            circle
            material-icons
            name="location_city"
            item-left>
        </ion-icon>
        <strong class="company-item--label">
            {{ company.name }}
        </strong> 
        <span class="company-item--details-text" [innerHTML]="getDescriptionText(company)"></span>
    </ion-item>
</div>

