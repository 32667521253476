import { __extends } from "tslib";
var __assign = (this && this.__assign) || Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s)
            if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
    }
    return t;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ModalController, NavParams, ToastController, ViewController } from 'ionic-angular';
import { ListingModel, OfferModel } from '@apto/models';
import { AnalyticsService, AppService, ContactsService, DealPartiesService, OffersService, TasksService, PropertiesService, TypesService } from '@apto/services';
import { DealListingsService } from '@apto/shared/services';
import { EditListingForm } from '@apto/pn/components';
import { PipelineDetailsModalMode } from '@apto/pn/modals';
import { PipelineSlideModal } from '@apto/pn/modals/pipeline-slide-modal/pipeline-slide-modal';
import { OffersModal, OffersModalMode } from '@apto/pn/modals/offers-modal/offers-modal';
import { Router } from '@angular/router';
var SourcingDetailsModal = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(SourcingDetailsModal, _super);
    function SourcingDetailsModal(analyticsService, appService, navParams, contactsService, dealPartiesService, dealListingsService, modalController, offersService, tasksService, propertiesService, toastController, viewController, typesService, router) {
        var _this = _super.call(this, viewController, modalController, navParams, tasksService, propertiesService, toastController, analyticsService, router, typesService) || this;
        _this.appService = appService;
        _this.navParams = navParams;
        _this.contactsService = contactsService;
        _this.dealPartiesService = dealPartiesService;
        _this.dealListingsService = dealListingsService;
        _this.modalController = modalController;
        _this.offersService = offersService;
        _this.tasksService = tasksService;
        _this.propertiesService = propertiesService;
        _this.toastController = toastController;
        _this.viewController = viewController;
        _this.typesService = typesService;
        _this.modalUrls = new Map([
            [PipelineDetailsModalMode.VIEW, '/:taskId']
        ]);
        _this.isInitializingDealParties = false;
        _this.isInitializingTasks = false;
        _this.isInitializingOffers = false;
        _this.isLoadingRelationships = true;
        _this.Modes = PipelineDetailsModalMode;
        _this.records = [];
        _this.selectedRecord = new ListingModel();
        _this.dealParties = [];
        _this.offers = [];
        _this.subscriptions = [];
        return _this;
    }
    SourcingDetailsModal.prototype.ngOnInit = function () {
        var _this = this;
        this.mode = this.navParams.get('mode') || PipelineDetailsModalMode.VIEW;
        this.selectedRecord = this.navParams.get('selectedRecord');
        this.callList = this.navParams.get('callList');
        this.loadData();
        this.subscriptions.push(this.tasksService.onUpserted.subscribe(function (data) {
            if (data.isNew) {
                _this.addTask(data.payload);
            }
            else {
                _this.updateTask(data.payload);
            }
        }));
        this.subscriptions.push(this.tasksService.onDeleted.subscribe(function (data) {
            data.payload.forEach(function (id) { return _this.delTask(id); });
        }));
        this.subscriptions.push(this.dealListingsService.onUpserted.subscribe(function (data) {
            _this.loadOffers();
            _this.isInitializingDealParties = true;
            _this.loadDealParties();
        }));
        this.subscriptions.push(this.offersService.onDeleted.subscribe(function (data) {
            _this.loadOffers();
        }));
    };
    SourcingDetailsModal.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    SourcingDetailsModal.prototype.loadData = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setParentProperty()
                            .then(function () {
                            _this.isLoadingRelationships = false;
                        });
                        if (!(!this.selectedRecord.property && this.selectedRecord.propertyId))
                            return [3 /*break*/, 2];
                        _a = this.selectedRecord;
                        return [4 /*yield*/, this.propertiesService.byId(this.selectedRecord.propertyId)];
                    case 1:
                        _a.property = _b.sent();
                        _b.label = 2;
                    case 2:
                        this.loadTasks();
                        this.loadDealParties();
                        this.loadOffers();
                        return [2 /*return*/];
                }
            });
        });
    };
    SourcingDetailsModal.prototype.viewPropertyExt = function () {
        var listingProps = {
            forSale: true,
            daysOnMarket: this.selectedRecord.daysOnMarket && this.selectedRecord.daysOnMarket > 0
                ? this.selectedRecord.daysOnMarket
                : null,
            listingId: this.selectedRecord._id,
            internal: !(this.selectedRecord.type || '').includes('external'),
        };
        this.viewProperty(__assign({}, this.selectedRecord.property, listingProps));
    };
    SourcingDetailsModal.prototype.buildCallList = function () {
        this.viewController.dismiss({ selectedRecord: this.selectedRecord });
    };
    SourcingDetailsModal.prototype.startCalling = function (event) {
        this.viewController.dismiss(event);
    };
    SourcingDetailsModal.prototype.delete = function () {
        this.dealListingsService.delete([this.selectedRecord._id]);
        this.dismiss();
    };
    SourcingDetailsModal.prototype.save = function (listing) {
        return __awaiter(this, void 0, void 0, function () {
            var formResult, savedListing;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.mode === PipelineDetailsModalMode.VIEW))
                            return [3 /*break*/, 1];
                        this.contactsService.upsert(listing);
                        return [3 /*break*/, 3];
                    case 1:
                        formResult = this.editListingForm.save();
                        if (!(formResult && formResult.listing))
                            return [3 /*break*/, 3];
                        this.scrollToTop();
                        this.editListingForm.isSaving = true;
                        return [4 /*yield*/, this.dealListingsService.upsert(formResult.listing)];
                    case 2:
                        savedListing = _a.sent();
                        if (!savedListing) {
                            return [2 /*return*/, this.dismiss()];
                        }
                        this.editListingForm.isSaving = false;
                        this.view(savedListing);
                        if (this.initialMode === this.Modes.EDIT) {
                            this.dismiss();
                        }
                        else {
                            this.view(savedListing);
                        }
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SourcingDetailsModal.prototype.showAddOffer = function () {
        var offersModal = this.modalController.create(OffersModal, {
            mode: OffersModalMode.EDIT,
            selectedRecord: new OfferModel({
                listingId: this.selectedRecord._id
            })
        });
        return offersModal.present({ updateUrl: false });
    };
    SourcingDetailsModal.prototype.viewOffer = function (offer) {
        var offersModal = this.modalController.create(OffersModal, {
            mode: OffersModalMode.VIEW,
            selectedRecord: offer
        });
        offersModal.present({ updateUrl: false });
    };
    SourcingDetailsModal.prototype.editOffer = function (offer) {
        var offersModal = this.modalController.create(OffersModal, {
            mode: OffersModalMode.EDIT,
            selectedRecord: offer
        });
        return offersModal.present({ updateUrl: false });
    };
    SourcingDetailsModal.prototype.loadDealParties = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isInitializingDealParties = true;
                        _a = this;
                        return [4 /*yield*/, this.dealPartiesService.fetch({ where: { listingId: this.selectedRecord._id } })];
                    case 1:
                        _a.dealParties = _b.sent();
                        this.isInitializingDealParties = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SourcingDetailsModal.prototype.loadOffers = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isInitializingOffers = true;
                        _a = this;
                        return [4 /*yield*/, this.offersService.byListing(this.selectedRecord._id)];
                    case 1:
                        _a.offers = _b.sent();
                        this.isInitializingOffers = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SourcingDetailsModal.prototype.loadTasks = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isInitializingTasks = true;
                        _a = this;
                        return [4 /*yield*/, this.tasksService.byRelatedId(this.selectedRecord._id)];
                    case 1:
                        _a.tasks = _b.sent();
                        this.isInitializingTasks = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SourcingDetailsModal.prototype.setParentProperty = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.selectedRecord.property)
                            return [3 /*break*/, 2];
                        return [4 /*yield*/, this.attachParentProperty(this.selectedRecord.property)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    return SourcingDetailsModal;
}(PipelineSlideModal));
export { SourcingDetailsModal };
