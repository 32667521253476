import { EventEmitter } from '@angular/core';
import { PopoverController } from 'ionic-angular';
import { ContactModel, FullContactPersonModel, TaskModel } from '@apto/models';
import { EmailPopover, PhonePopover } from '@apto/ionic-lib/components';
import { FormBuilder, Validators } from '@angular/forms';
import { PHONE_NUMBER_REGEX, EMAIL_REGEX } from '@apto/shared/constants';
import './contact-context-panel.scss';
import moment from 'moment';
var ContactContextPanel = /** @class */ /*@__PURE__*/ (function () {
    function ContactContextPanel(popoverController, formBuilder) {
        this.popoverController = popoverController;
        this.formBuilder = formBuilder;
        this.isInitializing = true;
        this.isSavingContact = false;
        this.showEdit = false;
        this.onEditContact = new EventEmitter();
        this.onExecuteCall = new EventEmitter();
        this.onSendEmail = new EventEmitter();
        this.phoneNumber = '';
        this.editingEmail = false;
        this.editingNumber = false;
        this.clickedSaveEmail = false;
        this.clickedSaveNumber = false;
        this.setupForm();
    }
    Object.defineProperty(ContactContextPanel.prototype, "activities", {
        set: function (value) {
            if (this.currentActivities !== value) {
                this.currentActivities = value;
                this.getLastActivityInfo();
            }
        },
        enumerable: true,
        configurable: true
    });
    ContactContextPanel.prototype.edit = function () {
        this.onEditContact.emit(this.contact);
    };
    ContactContextPanel.prototype.navigateToUrl = function (url) {
        window.location.assign(url);
    };
    ContactContextPanel.prototype.executeCall = function (phoneNumber) {
        this.onExecuteCall.emit(phoneNumber);
        this.navigateToUrl(phoneNumber.actionLink);
    };
    ContactContextPanel.prototype.showEmails = function (ev) {
        var popover = this.popoverController.create(EmailPopover, {
            emails: this.contact.emailsWithValues
        }, {
            cssClass: 'contact-context-panel--email-popover'
        });
        popover.present({ ev: ev });
    };
    ContactContextPanel.prototype.showPhoneNumbers = function (ev) {
        var popover = this.popoverController.create(PhonePopover, {
            phoneNumbers: this.contact.phoneNumbersWithValues
        }, {
            cssClass: 'contact-context-panel--phone-popover'
        });
        popover.present({ ev: ev });
    };
    ContactContextPanel.prototype.toggleEmailVisibility = function () {
        this.editingEmail = !this.editingEmail;
    };
    ContactContextPanel.prototype.toggleNumberVisibility = function () {
        this.editingNumber = !this.editingNumber;
    };
    ContactContextPanel.prototype.saveNumber = function () {
        this.clickedSaveNumber = true;
        if (!this.contactForm.controls.phoneNumber.valid) {
            this.contactForm.controls.phoneNumber.markAsDirty();
        }
        else {
            this.contact.phone.number = this.contactForm.controls.phoneNumber.value;
            this.onEditContact.emit(this.contact);
            this.toggleNumberVisibility();
            this.clickedSaveNumber = false;
        }
    };
    ContactContextPanel.prototype.saveEmail = function () {
        this.clickedSaveEmail = true;
        if (!this.contactForm.controls.email.valid) {
            this.contactForm.controls.email.markAsDirty();
        }
        else {
            this.contact.email.address = this.contactForm.controls.email.value;
            this.onEditContact.emit(this.contact);
            this.toggleEmailVisibility();
            this.clickedSaveEmail = false;
        }
    };
    ContactContextPanel.prototype.setupForm = function () {
        this.contactForm = this.formBuilder.group({
            phoneNumber: ['', Validators.pattern(PHONE_NUMBER_REGEX)],
            email: ['', Validators.pattern(EMAIL_REGEX)]
        });
    };
    ContactContextPanel.prototype.getLastActivityInfo = function () {
        if (this.currentActivities && this.currentActivities.length) {
            var tempActivities = this.currentActivities.filter(function (el) { return el.isComplete; });
            if (tempActivities.length === 0)
                return;
            var mostRecentActivity = tempActivities.reduce(function (a, b) {
                return a.completedDate > b.completedDate ? a : b;
            });
            // We can't change completedDate for testing, so we replace it with dueDate if need to test.
            this.lastActivityTimeframe =
                mostRecentActivity.description === 'testing'
                    ? mostRecentActivity.dueDate
                    : mostRecentActivity.completedDate;
            switch (mostRecentActivity.callResult) {
                case TaskModel.RESULT.REACHED:
                    this.lastActivityStatus = 'reached';
                    break;
                case TaskModel.RESULT.LEFT_MESSAGE:
                    this.lastActivityStatus = 'left a message for';
                    break;
                case TaskModel.RESULT.SENT_EMAIL:
                    this.lastActivityStatus = 'sent an email to';
                    break;
                default:
                    this.lastActivityStatus = 'contacted';
            }
            if (moment(mostRecentActivity.completedDate).format('YYYY-MM-DD') !==
                moment(mostRecentActivity.lastModifiedDate).format('YYYY-MM-DD')) {
                this.lastActivityChangeDate = mostRecentActivity.lastModifiedDate;
            }
            else {
                this.lastActivityChangeDate = '';
            }
        }
        else {
            this.lastActivityChangeDate = '';
            this.lastActivityStatus = '';
            this.lastActivityTimeframe = '';
        }
    };
    return ContactContextPanel;
}());
export { ContactContextPanel };
