<ion-card class="deal-team-card">
    <ion-card-header class="accepted-offer-card--font-small">Parties to the Transaction</ion-card-header>
    <ion-item *ngIf="!dealParties.length"
              class="empty-card">
        <ion-icon name="account_circle"
                  circle
                  small
                  color="white"
                  item-left></ion-icon>
        Add contacts for this deal
    </ion-item>
    <ion-list>
        <ion-item [attr.detail-push]="true"
                  (click)="onSelect.emit(dealParty.contact)"
                  *ngFor="let dealParty of dealParties">
            <ion-icon class="grey-color-circle"
                      material-icons
                      name="account_circle"
                      item-left
                      large>
            </ion-icon>
            <p class="deal-team-card--name dark-text"
               *ngIf="dealParty.contact?.fullName">{{dealParty.contact.fullName}}</p>
            <p class="deal-team-card--text"
               *ngIf="dealParty.role">{{dealParty.role}}</p>
            <p class="deal-team-card--text"
               *ngIf="dealParty.contact?.title">{{dealParty.contact.title}}</p>
        </ion-item>
    </ion-list>
    <busy-panel *ngIf="isInitializingDealParties"
                message="Loading the deal team..."></busy-panel>
    <ion-row class="card-footer-buttons">
        <ion-col text-right>
            <button ion-button
                    clear
                    (click)="manageDealTeam()">
                Manage
            </button>
        </ion-col>
    </ion-row>
</ion-card>
