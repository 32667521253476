<ion-item class="call-list-contact-item">
    <ion-icon class="grey-color-circle"
              material-icons
              name="account_circle"
              item-left
              large>
    </ion-icon>

    <p>
        <span class="call-list-contact-item--name">
            {{contact.fullName}}
        </span>
        <span *ngIf="contact" class="call-list-contact-item--position">
            {{contact.type}} <span *ngIf="contact.type && contact.position"> &bull; </span>{{contact.position}}
        </span>
    </p>

    <p *ngIf="groupMember.lastActivityDate || callListCount !== undefined" class="call-list-contact-item--summary">
        <span *ngIf="callListCount !== undefined">
            <ion-icon material-icons extra-small name="list"></ion-icon>
            On {{ callListCount }} call lists
        </span>

        <span *ngIf="groupMember.callResult">
            <ion-icon material-icons extra-small name="call"></ion-icon>
            Contacted {{ timeAgo | humanDate}}
        </span>
    </p>

    <button *ngFor="let button of buttons"
            ion-button
            clear
            [color]="button.color || 'primary-blue'"
            item-right
            (click)="onButtonClicked.emit({ label: button.label })">
        {{ button.label }}
    </button>
</ion-item>
