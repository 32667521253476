<ng-template #spaceTemplate>
    <div class="StackingPlanSpace-header">
        <span
            *ngIf="spaceData.space.spaceStatus && (!spaceData.lease || spaceData.space.spaceStatus.toLowerCase() !== 'leased')"
            class="StackingPlanSpace-status">
            {{spaceData.space.spaceStatus}}
        </span>
        <span
            *ngIf="spaceData.space.name && !spaceData.space.spaceStatus && !spaceData.lease"
            class="StackingPlanSpace-name">
            {{spaceData.space.name}}
        </span>
        <span
            *ngIf="spaceData.space.spaceStatus && spaceData.space.spaceStatus.toLowerCase() === 'leased' && spaceData.lease && spaceData.lease.tenantCompany && spaceData.lease.tenantCompany.name; else useTenantContact"
            class="StackingPlanSpace-tenant">
            {{spaceData.lease.tenantCompany.name}}
        </span>
        <ng-template #useTenantContact>
            <span
                *ngIf="spaceData.space.spaceStatus && spaceData.space.spaceStatus.toLowerCase() === 'leased' && spaceData.lease && spaceData.lease.tenantContact && spaceData.lease.tenantContact.fullName else useSpaceName"
                class="StackingPlanSpace-tenant">
                {{spaceData.lease.tenantContact.fullName}}
            </span>
        </ng-template>
        <ng-template #useSpaceName>
            <span
                *ngIf="spaceData.space.spaceStatus && spaceData.space.spaceStatus.toLowerCase() === 'leased' && spaceData.lease && spaceData.space.name"
                class="StackingPlanSpace-tenant">
                {{spaceData.space.name}}
            </span>
        </ng-template>
        <span
            *ngIf="spaceData.space.spaceStatus && spaceData.space.spaceStatus.toLowerCase() === 'leased' && spaceData.lease && spaceData.lease.leaseExpirationDate"
            class="StackingPlanSpace-leaseExpiration">
            Lease Exp. {{spaceData.lease.leaseExpirationDate | dateFormat}}
        </span>
    </div>
    <div class="StackingPlanSpace-footer">
        <span
            *ngIf="spaceData.space.suiteFloor"
            class="StackingPlanSpace-suit">
            {{spaceData.space.suiteFloor}}
        </span>
        <span
            *ngIf="spaceData.space.squareFootage"
            class="StackingPlanSpace-sqft">
            {{spaceData.space.squareFootage | numberWithCommas}}sf
        </span>
        <span
            *ngIf="spaceData.space.spaceStatus && spaceData.space.spaceStatus.toLowerCase() === 'leased' && spaceData.lease"
            class="StackingPlanSpace-price">{{rentalRate}}
        </span>
    </div>
</ng-template>

<div class="StackingPlanSpace-container">
    <apto-tooltip>
        <apto-tooltip-trigger>
            <ng-template [ngTemplateOutlet]="spaceTemplate"></ng-template>
        </apto-tooltip-trigger>
        <apto-tooltip-content>
            <div class="StackingPlanSpace-tooltip">
                <ng-template [ngTemplateOutlet]="spaceTemplate"></ng-template>
            </div>
        </apto-tooltip-content>
    </apto-tooltip>
</div>