import { PipeTransform } from '@angular/core';
var FixedPipe = /** @class */ /*@__PURE__*/ (function () {
    function FixedPipe() {
    }
    FixedPipe.prototype.transform = function (value, decimals) {
        if (decimals === void 0) {
            decimals = 2;
        }
        if (!value) {
            return '';
        }
        return value.toFixed(decimals);
    };
    return FixedPipe;
}());
export { FixedPipe };
