<form [formGroup]="propertyForm"
      class="edit-property-form edit-form">
    <ion-card>
        <ion-card-header>Property overview</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-label stacked required>Property name</ion-label>
                <ion-input formControlName="name"
                           required></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="propertyForm.get('name').dirty &&
                       propertyForm.get('name').errors &&
                       propertyForm.get('name').errors.required">
                A property name is required.
            </div>

            <ion-item-group formGroupName="address">
                <ion-item>
                   <ion-label stacked required>Street address</ion-label>
                   <ion-input formControlName="street"></ion-input>
                </ion-item>
                <div class="field-error"
                    *ngIf="propertyForm.get('address').get('street').dirty &&
                           propertyForm.get('address').get('street').errors &&
                           propertyForm.get('address').get('street').errors.required">
                    A property street address is required.
                </div>

                <ion-item>
                   <ion-label stacked>City</ion-label>
                   <ion-input formControlName="city"></ion-input>
                </ion-item>

                <state-select [parentForm]="propertyForm"
                              [groupName]="'address'"
                              [cssClass]="'edit-form--select-popover'"></state-select>

                <ion-item>
                   <ion-label stacked>ZIP / Postal code</ion-label>
                   <ion-input formControlName="postalCode"></ion-input>
                </ion-item>
                <div class="field-error"
                    *ngIf="propertyForm.get('address').get('postalCode').dirty &&
                           propertyForm.get('address').get('postalCode').errors &&
                           propertyForm.get('address').get('postalCode').errors.pattern">
                    {{validationMessages.POSTAL_REGEX}}
                </div>
                <!-- <ion-item>
                   <ion-label stacked>Country</ion-label>
                   <ion-input formControlName="country"></ion-input>
                </ion-item> -->
            </ion-item-group>
        </ion-card-content>
    </ion-card>

    <ion-card>
        <ion-card-header>Property details</ion-card-header>
        <ion-card-content>
            <select-field [form]="propertyForm"
                          field="type"
                          label="Property type"
                          [options]="types"
                          [required]="true"
                          [cssClass]="'edit-form--select-popover'"></select-field>
            <select-map-field [form]="propertyForm"
                          field="primaryUse"
                          label="Property use"
                          [options]="picklistOptions.primaryUse"
                          [cssClass]="'edit-form--select-popover'"></select-map-field>
            <select-map-field [form]="propertyForm"
                          field="market"
                          label="Market"
                          [options]="picklistOptions.market"
                          [cssClass]="'edit-form--select-popover'"></select-map-field>
            <select-map-field [form]="propertyForm"
                          field="submarket"
                          label="Submarket"
                          [options]="picklistOptions.submarket"
                          [cssClass]="'edit-form--select-popover'"></select-map-field>
            <select-map-field [form]="propertyForm"
                          field="class"
                          label="Building class"
                          [options]="picklistOptions.class"
                          [cssClass]="'edit-form--select-popover'"></select-map-field>
            <ion-item>
                <ion-label stacked>Square footage</ion-label>
                <ion-input formControlName="squareFootage"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="propertyForm.get('squareFootage').dirty &&
                        propertyForm.get('squareFootage').errors &&
                        propertyForm.get('squareFootage').errors.pattern">
                {{validationMessages.DECIMAL_REGEX}}
            </div>
            <ion-item>
                <ion-label stacked>Land (acres)</ion-label>
                <ion-input formControlName="landAcres"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="propertyForm.get('landAcres').dirty &&
                       propertyForm.get('landAcres').errors &&
                       propertyForm.get('landAcres').errors.pattern">
                {{validationMessages.DECIMAL_REGEX}}
            </div>
            <ion-item>
                <ion-label stacked>Land (SF)</ion-label>
                <ion-input formControlName="landSquareFeet"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="propertyForm.get('landSquareFeet').dirty &&
                       propertyForm.get('landSquareFeet').errors &&
                       propertyForm.get('landSquareFeet').errors.pattern">
                {{validationMessages.DECIMAL_REGEX}}
            </div>
            <ion-item>
                <ion-label stacked>Number of stories</ion-label>
                <ion-input formControlName="stories"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="propertyForm.get('stories').dirty &&
                       propertyForm.get('stories').errors &&
                       propertyForm.get('stories').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <ion-item>
                <ion-label stacked>Year built</ion-label>
                <ion-input formControlName="yearBuilt"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="propertyForm.get('yearBuilt').dirty &&
                       propertyForm.get('yearBuilt').errors &&
                       propertyForm.get('yearBuilt').errors.pattern">
                {{validationMessages.YEAR_REGEX}}
            </div>
            <ion-item>
                <ion-label stacked>Year renovated</ion-label>
                <ion-input formControlName="yearRenovated"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="propertyForm.get('yearRenovated').dirty &&
                       propertyForm.get('yearRenovated').errors &&
                       propertyForm.get('yearRenovated').errors.pattern">
                {{validationMessages.YEAR_REGEX}}
            </div>
            <ion-item>
                <ion-label stacked>Location description</ion-label>
                <ion-textarea textarea-elastic
                              class="elastic-text"
                              formControlName="description"></ion-textarea>
            </ion-item>
        </ion-card-content>
    </ion-card>

    <ion-card>
        <ion-card-header>Additional details</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-label stacked>Parcel number</ion-label>
                <ion-input formControlName="parcelNumber"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label stacked>Occupancy (%)</ion-label>
                <ion-input formControlName="occupancy"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="propertyForm.get('occupancy').dirty &&
                       propertyForm.get('occupancy').errors &&
                       propertyForm.get('occupancy').errors.pattern">
                {{validationMessages.DECIMAL_REGEX}}
            </div>
            <ion-item>
                <ion-label stacked>Units</ion-label>
                <ion-input formControlName="units"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="propertyForm.get('units').dirty &&
                       propertyForm.get('units').errors &&
                       propertyForm.get('units').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <select-map-field [form]="propertyForm"
                          field="tenancy"
                          label="Tenancy"
                          [options]="picklistOptions.tenancy"
                          [cssClass]="'edit-form--select-popover'"></select-map-field>
            <select-map-field [form]="propertyForm"
                          field="status"
                          label="Building status"
                          [options]="picklistOptions.status"
                          [cssClass]="'edit-form--select-popover'"></select-map-field>
            <ion-item>
                <ion-label stacked>Zoning</ion-label>
                <ion-input formControlName="zoning"></ion-input>
            </ion-item>
            <ion-item>
                <ion-label stacked>Parking spaces</ion-label>
                <ion-input formControlName="parkingSpaces"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="propertyForm.get('parkingSpaces').dirty &&
                       propertyForm.get('parkingSpaces').errors &&
                       propertyForm.get('parkingSpaces').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <!-- <ion-item>
                <ion-label stacked>Parking ratio (spaces per 1000 sq ft)</ion-label>
                <ion-input formControlName="parkingRatio"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="propertyForm.get('parkingRatio').dirty &&
                       propertyForm.get('parkingRatio').errors &&
                       propertyForm.get('parkingRatio').errors.pattern">
                {{validationMessages.DECIMAL_REGEX}}
            </div> -->
        </ion-card-content>
    </ion-card>

    <busy-panel *ngIf="isSaving"
                message="Saving property..."></busy-panel>
</form>
