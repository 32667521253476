<ion-card class="current-lists-card">
    <ion-card-header>
        Current Lists
    </ion-card-header>
    <ion-card-content *ngIf="!isInitializing" class="current-lists-card--content">
        <ion-item *ngFor="let callList of currentCallLists"
                  detail-push
                  class="current-lists"
                  [class.current-lists--pending]="callList.isPending"
                  (click)="emitViewList(callList)">
            <ion-icon item-left name="apto-icon-call-list" class="current-lists-card--call-list-icon"></ion-icon>
            <p class="call-list-name">{{ callList.name }}</p>
            <div *ngIf="callList.isPending" class='current-lists--updating-message'>Pending</div>
            <div [hidden]="callList.isPending">
                <span>{{ callList.configJson.daysSinceContact }} day cycle</span>
                <span *ngIf="!callList.isLegacy && !callList.isAptoGenerated && editEnabled"> •
                    <button ion-button
                            clear
                            class="current-lists-card--remove-button"
                            (click)="emitRemoveFromList($event, callList)">Remove
                    </button>
                </span>
            </div>
        </ion-item>
        <ion-item *ngIf="!currentCallLists.length && editEnabled"
                  class="empty-card">
            <ion-icon name="photo"
                      material-icons
                      circle
                      small
                      color="white"
                      item-left></ion-icon>
                Add contact to a call list
        </ion-item>
        <add-to-list-button *ngIf="editEnabled" class="current-lists-card--add-button"
                            [currentCallLists]="currentCallLists"
                            [contacts]='currentContacts'
                            [allCallLists]="callLists"
                            [isInitializing]="isInitializing"
                            (onAddList)="addList($event)">
        </add-to-list-button>
    </ion-card-content>

    <ion-card-content *ngIf="isInitializing" class="current-lists-card--busy-content">
        <busy-panel message="Loading lists ..."></busy-panel>
    </ion-card-content>
</ion-card>
