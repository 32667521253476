import { EventEmitter } from '@angular/core';
import { PopoverController, ModalController } from 'ionic-angular';
import { CallListModel, ListingModel } from '@apto/models';
import { CallListsService } from '@apto/services';
import { SelectPopover } from '@apto/ionic-lib/components';
import { CallListModal, CallListModalMode } from '@apto/shared/modals/call-list-modal/call-list-modal';
import './add-to-list-button.scss';
var AddToListButton = /** @class */ /*@__PURE__*/ (function () {
    function AddToListButton(popoverController, callListsService, modalController) {
        this.popoverController = popoverController;
        this.callListsService = callListsService;
        this.modalController = modalController;
        this.currentCallLists = [];
        this.contacts = [];
        this.allCallLists = [];
        this.isInitializing = false;
        this.onAddList = new EventEmitter();
        this.onAddListError = new EventEmitter();
        this.buildListButtonText = 'BUILD A NEW LIST';
        this.subscriptions = [];
    }
    AddToListButton.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.callListsService.onUpserted.subscribe(function (data) {
            if (!data.isNew) {
                return;
            }
            var callList = new CallListModel(data.payload);
            callList.batchInfo = { id: null, jobId: null, totalContacts: 1, processedContacts: 0 };
            _this.onAddList.emit(callList);
        }));
    };
    AddToListButton.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    AddToListButton.prototype.addList = function (ev) {
        var _this = this;
        if (this.filterListing) {
            var callList = new CallListModel({
                configJson: {
                    daysSinceContact: 30,
                    listingId: this.filterListing._id
                },
                name: this.filterListing.name,
                members: [],
                type: CallListModel.TYPE.DEAL_LIST
            });
            this.openAddCallListModal(callList);
        }
        else {
            this.findCallListsCurrentUserIsNotAPartOf();
            var options = this.leftOverCallListOptions.map(function (callList) {
                return {
                    value: callList.name,
                    displayHtml: "<span class=\"dark-text\">" + callList.name + "</span> (" + callList.members.length + " contacts)"
                };
            });
            options.push.apply(options, [{
                    value: null,
                    displayHtml: "<span class=\"add-to-list-button--helper\">\n                            Cannot add to lists over " + CallListModel.CALL_LIST_MEMBER_LIMIT + " contacts\n                          </span>",
                    disabled: true
                }, {
                    value: this.buildListButtonText
                }]);
            var popover = this.popoverController.create(SelectPopover, {
                options: options
            }, {
                cssClass: 'color-last-option no-lines wide'
            });
            popover.onDidDismiss(function (value) {
                if (value) {
                    if (value === _this.buildListButtonText) {
                        _this.openAddCallListModal();
                    }
                    else {
                        _this.pushAddedCallList(value);
                    }
                }
            });
            popover.present({ ev: ev });
        }
    };
    AddToListButton.prototype.openAddCallListModal = function (callList) {
        if (!callList) {
            callList = new CallListModel({
                configJson: {},
                members: [],
                type: CallListModel.TYPE.CONTACT_GROUP_LIST
            });
        }
        var callListModal = this.modalController.create(CallListModal, {
            addedContacts: this.contacts,
            callList: callList,
            mode: CallListModalMode.EDIT,
            callLists: this.allCallLists
        }, {
            cssClass: 'large-modal'
        });
        callListModal.present({ updateUrl: false });
    };
    AddToListButton.prototype.findCallListsCurrentUserIsNotAPartOf = function () {
        var eligibleCallLists = this.allCallLists.filter(function (callList) {
            return !callList.isAptoGenerated && !callList.isLegacy && callList.isLessThanMemberLimit;
        });
        var eligibleCurrentCallLists = this.currentCallLists.filter(function (callList) {
            return !callList.isAptoGenerated && !callList.isLegacy && callList.isLessThanMemberLimit;
        });
        this.leftOverCallListOptions = eligibleCallLists.filter(function (callList) {
            return !eligibleCurrentCallLists.some(function (currentCallList) { return callList._id === currentCallList._id; });
        });
    };
    AddToListButton.prototype.pushAddedCallList = function (value) {
        var _this = this;
        var selectedCallList = this.allCallLists.find(function (callList) {
            return callList.name === value;
        });
        selectedCallList.batchInfo = { id: null, jobId: null, totalContacts: 1, processedContacts: 0 };
        this.onAddList.emit(selectedCallList);
        var contactGroupId = selectedCallList.contactGroupId;
        var contactGroupMemberInformation = this.contacts.map(function (contact) {
            return { contactId: contact._id, contactGroupId: contactGroupId };
        });
        this.callListsService.bulkInsertMembers({ models: contactGroupMemberInformation, callListId: selectedCallList._id })
            .then(function (results) {
            selectedCallList.batchInfo = results.batchInfo;
            _this.callListsService.byId(selectedCallList._id, true);
            _this.callListsService.onMembersAdded.emit();
        })
            .catch(function (response) {
            _this.onAddListError.emit({ error: JSON.parse(response.error) });
        });
    };
    return AddToListButton;
}());
export { AddToListButton };
