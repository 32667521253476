var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { LogsService } from '@apto/ionic-lib/services';
import { PropertiesService, ContactsService, CompaniesService, CompsService } from '@apto/services';
function indexBy(list, key) {
    if (list === void 0) {
        list = [];
    }
    if (!list) {
        return {};
    }
    return list.reduce(function (agg, item) {
        agg[item[key]] = item;
        return agg;
    }, {});
}
var LeasingDataService = /** @class */ /*@__PURE__*/ (function () {
    function LeasingDataService(compsService, companiesService, contactsService, propertiesService, logsService) {
        this.compsService = compsService;
        this.companiesService = companiesService;
        this.contactsService = contactsService;
        this.propertiesService = propertiesService;
        this.logsService = logsService;
    }
    LeasingDataService.prototype.getLeasesForCompany = function (company) {
        return __awaiter(this, void 0, void 0, function () {
            var leases, populatedLeases;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!company || !company._id) {
                            return [2 /*return*/, []];
                        }
                        return [4 /*yield*/, this.getLeases(null, company._id)];
                    case 1:
                        leases = _a.sent();
                        if (!leases.length) {
                            return [2 /*return*/, []];
                        }
                        leases = CompsService.sortLeases(leases);
                        return [4 /*yield*/, this.getIContactLeaseShapes(leases)];
                    case 2:
                        populatedLeases = _a.sent();
                        return [2 /*return*/, this.groupByProperty(populatedLeases)];
                }
            });
        });
    };
    LeasingDataService.prototype.getLeasesForSpace = function (space) {
        return __awaiter(this, void 0, void 0, function () {
            var leases, populatedLeases;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!space || !space._id) {
                            return [2 /*return*/, []];
                        }
                        return [4 /*yield*/, this.compsService.byLeasedSpaces([space._id])];
                    case 1:
                        leases = _a.sent();
                        if (!leases.length) {
                            return [2 /*return*/, []];
                        }
                        leases = CompsService.sortLeasesCurrentFirst(leases);
                        return [4 /*yield*/, this.getIContactLeaseShapes(leases)];
                    case 2:
                        populatedLeases = _a.sent();
                        return [2 /*return*/, this.groupByProperty(populatedLeases)];
                }
            });
        });
    };
    LeasingDataService.prototype.getLeasesForContact = function (contact, withCompany) {
        if (withCompany === void 0) {
            withCompany = true;
        }
        return __awaiter(this, void 0, void 0, function () {
            var leases, populatedLeases;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!contact || !contact._id) {
                            return [2 /*return*/, []];
                        }
                        return [4 /*yield*/, this.getLeases(contact._id, contact.companyId || null, withCompany)];
                    case 1:
                        leases = _a.sent();
                        if (!leases || !leases.length) {
                            return [2 /*return*/, []];
                        }
                        leases = CompsService.sortLeases(leases);
                        return [4 /*yield*/, this.getIContactLeaseShapes(leases)];
                    case 2:
                        populatedLeases = _a.sent();
                        return [2 /*return*/, this.groupByProperty(populatedLeases)];
                }
            });
        });
    };
    LeasingDataService.prototype.getIContactLeaseShapes = function (comps) {
        return __awaiter(this, void 0, void 0, function () {
            var companyIds, propertyIds, contactIds, results, companiesById, contactsById, propertiesById, iContactLeaseShapes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        companyIds = new Set();
                        propertyIds = new Set();
                        contactIds = new Set();
                        comps.forEach(function (comp) {
                            if (comp.tenantCompanyId) {
                                companyIds.add(comp.tenantCompanyId);
                            }
                            if (comp.leasedPropertyId) {
                                propertyIds.add(comp.leasedPropertyId);
                            }
                            else if (comp.soldPropertyId) {
                                propertyIds.add(comp.soldPropertyId); // fallback in case leasedPropertyId was not filled out but sold was
                            }
                            if (comp.tenantContactId) {
                                contactIds.add(comp.tenantContactId);
                            }
                            else if (comp.landlordContactId) {
                                contactIds.add(comp.landlordContactId);
                            }
                        });
                        return [4 /*yield*/, Promise.all([
                                this.getCompaniesByIds(Array.from(companyIds)),
                                this.getContactsById(Array.from(contactIds)),
                                this.propertiesService.in(Array.from(propertyIds))
                            ])];
                    case 1:
                        results = _a.sent();
                        companiesById = indexBy(results[0], '_id');
                        contactsById = indexBy(results[1], '_id');
                        propertiesById = indexBy(results[2], '_id');
                        iContactLeaseShapes = [];
                        // Populate records from their ID values.
                        comps.forEach(function (comp) {
                            var propertyId = comp.leasedPropertyId || comp.soldPropertyId;
                            var companyId = comp.tenantCompanyId || comp.landlordCompanyId || comp.buyerCompanyId;
                            var leaseContact = null;
                            if (comp.tenantContactId && contactsById[comp.tenantContactId]) {
                                leaseContact = contactsById[comp.tenantContactId];
                            }
                            else if (comp.landlordContactId && contactsById[comp.landlordContactId]) {
                                leaseContact = contactsById[comp.landlordContactId];
                            }
                            iContactLeaseShapes.push({
                                comp: comp,
                                contact: leaseContact,
                                company: companiesById[companyId],
                                previousLeases: [],
                                property: propertiesById[propertyId]
                            });
                        });
                        return [2 /*return*/, iContactLeaseShapes];
                }
            });
        });
    };
    LeasingDataService.prototype.getLeases = function (contactId, companyId, withCompany) {
        if (companyId === void 0) {
            companyId = null;
        }
        if (withCompany === void 0) {
            withCompany = true;
        }
        return __awaiter(this, void 0, void 0, function () {
            var leases, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!contactId && !companyId) {
                            return [2 /*return*/, []];
                        }
                        leases = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.compsService.byLandlordOrTenant(contactId, companyId, withCompany)];
                    case 2:
                        leases = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _a.sent();
                        leases = [];
                        this.logsService.exception('Get leases dashboard error', err_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, leases];
                }
            });
        });
    };
    LeasingDataService.prototype.getCompaniesByIds = function (companyIds) {
        return __awaiter(this, void 0, void 0, function () {
            var companies, err_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        companies = [];
                        if (!companyIds || !companyIds.length) {
                            return [2 /*return*/, []];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.companiesService.in(companyIds)];
                    case 2:
                        companies = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _a.sent();
                        companies = [];
                        this.logsService.exception('get companies by id dashboard error', err_2);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, companies];
                }
            });
        });
    };
    LeasingDataService.prototype.getContactsById = function (ids) {
        return __awaiter(this, void 0, void 0, function () {
            var contacts, ex_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!ids || !ids.length) {
                            return [2 /*return*/, []];
                        }
                        contacts = [];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.contactsService.in(ids)];
                    case 2:
                        contacts = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        ex_1 = _a.sent();
                        contacts = [];
                        this.logsService.exception('Failed to fetch contact by id from DashBoardDataService', ex_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, contacts && contacts.length ? contacts : null];
                }
            });
        });
    };
    LeasingDataService.prototype.groupByProperty = function (leases) {
        // Group leases by their properties.
        var leasesByPropertyId = {};
        leases.forEach(function (lease) {
            var propertyId = lease.property ? lease.property._id : null;
            if (leasesByPropertyId[propertyId]) {
                leasesByPropertyId[propertyId].previousLeases.push(lease);
            }
            else {
                leasesByPropertyId[propertyId] = lease;
            }
        });
        return Object.values(leasesByPropertyId);
    };
    return LeasingDataService;
}());
export { LeasingDataService };
