import { __extends } from "tslib";
import { Injector } from '@angular/core';
import { ListingsService } from '@apto/services';
import { SERVICE_NAMES } from '@apto/services/constants';
import { AlertController } from 'ionic-angular';
var DealListingsService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(DealListingsService, _super);
    function DealListingsService(injector, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.alertController = alertController;
        _this.serviceName = SERVICE_NAMES.DEAL_LISTINGS;
        return _this;
    }
    return DealListingsService;
}(ListingsService));
export { DealListingsService };
