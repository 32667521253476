import { __extends } from "tslib";
import { Injector } from '@angular/core';
import { URLModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
import { AlertController } from 'ionic-angular';
var UrlsService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(UrlsService, _super);
    function UrlsService(injector, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.alertController = alertController;
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.URLS;
        _this.model = URLModel;
        _this.serviceName = SERVICE_NAMES.URLS;
        return _this;
    }
    return UrlsService;
}(BaseService));
export { UrlsService };
