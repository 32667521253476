<form [formGroup]="closedDealForm"
      novalidate
      class="edit-closed-deal-form edit-form">
    <ion-card>
        <ion-card-header class="title">
            <ng-container *ngIf="isLease || format === 'lease'">Lease</ng-container>
            <ng-container *ngIf="!isLease && format !== 'lease'">Deal</ng-container>
            details
        </ion-card-header>
        <ion-card-content>
            <div data-automation="closedDealForm-name">
                <ion-item>
                    <ion-label stacked
                               required>Deal Name</ion-label>
                    <ion-input formControlName="name"
                               required></ion-input>
                </ion-item>
                <div class="field-error"
                     *ngIf="closedDealForm.get('name').dirty &&
                            closedDealForm.get('name').errors &&
                            closedDealForm.get('name').errors['required']">
                    A name is required.
                </div>
                <div class="field-error"
                     *ngIf="closedDealForm.get('name').dirty &&
                            closedDealForm.get('name').errors &&
                            closedDealForm.get('name').errors['maxlength']">
                    Deal name must be 80 characters or less.
                </div>
            </div>

            <select-field *ngIf="!pipelineObject._id"
                          [form]="closedDealForm"
                          field="type"
                          label="Deal type"
                          [options]="types"
                          [required]="true"
                          [cssClass]="'edit-form--select-popover'"
                          (onChange)="chooseDealType($event)"></select-field>
            <div *ngIf="pipelineObject._id">
                <ion-label stacked>Deal type</ion-label>
                <p>{{dealType}}</p>
            </div>

            <div data-automation="sale-fields"
                 [hidden]="!isSale">
                <div data-automation="seller-company-search">
                    <ion-label stacked>
                        Seller Company
                        <add-record
                                linkText="Create new"
                                objectName="Company"
                                (onRecordCreated)="setQuickAddRecord($event, sellerCompanyAutoComplete, 'sellerCompany', 'sellerCompanyId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #sellerCompany
                                       [dataProvider]="companiesService"
                                       [options]="{ placeholder : 'Find company' }"></ion-auto-complete>
                </div>

                <div data-automation="seller-contact-search">
                    <ion-label stacked>
                        Seller Contact
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="setQuickAddRecord($event, sellerContactAutoComplete, 'sellerContact', 'sellerContactId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #sellerContact
                                       [dataProvider]="contactsService"
                                       [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
                </div>

                <div data-automation="buyer-company-search">
                    <ion-label stacked>
                        Buyer Company
                        <add-record
                                linkText="Create new"
                                objectName="Company"
                                (onRecordCreated)="setQuickAddRecord($event, buyerCompanyAutoComplete, 'buyerCompany', 'buyerCompanyId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #buyerCompany
                                       [dataProvider]="companiesService"
                                       [options]="{ placeholder : 'Find company' }"></ion-auto-complete>
                </div>

                <div data-automation="buyer-contact-search">
                    <ion-label stacked>
                        Buyer Contact
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="setQuickAddRecord($event, buyerContactAutoComplete, 'buyerContact', 'buyerContactId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #buyerContact
                                       [dataProvider]="contactsService"
                                       [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
                </div>
            </div>

            <div data-automation="lease-fields"
                 [hidden]="!dealType || !isLease">
                <div data-automation="landlord-company-search">
                    <ion-label stacked>
                        Landlord Company
                        <add-record
                                linkText="Create new"
                                objectName="Company"
                                (onRecordCreated)="setQuickAddRecord($event, landlordCompanyAutoComplete, 'landlordCompany', 'landlordCompanyId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #landlordCompany
                                       [dataProvider]="companiesService"
                                       [options]="{ placeholder : 'Find company' }"></ion-auto-complete>
                </div>

                <div data-automation="landlord-contact-search">
                    <ion-label stacked>
                        Landlord Contact
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="setQuickAddRecord($event, landlordContactAutoComplete, 'landlordContact', 'landlordContactId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #landlordContact
                                       [dataProvider]="contactsService"
                                       [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
                </div>

                <div data-automation="tenant-company-search">
                    <ion-label stacked>
                        Tenant Company
                        <add-record
                                linkText="Create new"
                                objectName="Company"
                                (onRecordCreated)="setQuickAddRecord($event, tenantCompanyAutoComplete, 'tenantCompany', 'tenantCompanyId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #tenantCompany
                                       [dataProvider]="companiesService"
                                       [options]="{ placeholder : 'Find company' }"></ion-auto-complete>
                </div>

                <div data-automation="tenant-contact-search">
                    <ion-label stacked>
                        Tenant Contact
                        <add-record
                                linkText="Create new"
                                objectName="Contact"
                                (onRecordCreated)="setQuickAddRecord($event, tenantContactAutoComplete, 'tenantContact', 'tenantContactId')">
                        </add-record>
                    </ion-label>
                    <ion-auto-complete #tenantContact
                                       [dataProvider]="contactsService"
                                       [options]="{ placeholder : 'Find contact' }"></ion-auto-complete>
                </div>
            </div>

            <div data-automation="property-search"
                 [hidden]="!isSale">
                <ion-label stacked>
                    Property
                    <add-record
                            linkText="Create new"
                            objectName="Property"
                            (onRecordCreated)="setQuickAddRecord($event, propertyAutoComplete, 'property', 'propertyId')">
                    </add-record>
                </ion-label>
                <ion-auto-complete #property
                                   [dataProvider]="propertiesService"
                                   [options]="{ placeholder : 'Find property' }"></ion-auto-complete>
            </div>

            <div data-automation="leased-property-search"
                 [hidden]="!dealType || !isLease">
                <ion-label stacked>
                    Leased Property
                    <add-record
                            linkText="Create new"
                            objectName="Property"
                            (onRecordCreated)="setQuickAddRecord($event, leasedPropertyAutoComplete, 'leasedProperty', 'leasedPropertyId')">
                    </add-record>
                </ion-label>
                <ion-auto-complete
                    #leasedProperty
                    (changeValue)="leasedPropertyChanged($event)"
                    (itemSelected)="leasedPropertySelected($event)"
                    [dataProvider]="propertiesService"
                    [options]="{ placeholder : 'Find property' }"></ion-auto-complete>
            </div>

            <div
                [hidden]="!spacesEnabled || !isLease"
                data-automation="lease-space-search">
                <ion-label stacked>Leased Space
                    <add-record
                            *ngIf="spaceAutocompleteService.parentPropertyId"
                            linkText="Create new"
                            [spaceParentProperty]="leasedPropertyAutoComplete.getSelection() || pipelineObject.leasedProperty"
                            objectName="Space"
                            (onRecordCreated)="setQuickAddRecord($event, leasedSpaceAutoComplete, 'spaceProperty', 'spacePropertyId')">
                    </add-record>
                </ion-label>
                <ion-auto-complete
                    #leasedSpace
                    [dataProvider]="spaceAutocompleteService"
                    [options]="{ placeholder : 'Find space' }"></ion-auto-complete>
            </div>

            <date-field *ngIf="dealType"
                        [defaultDate]="closedDealForm.get('transactionDate').value"
                        [form]="closedDealForm"
                        data-automation="transaction-date"
                        field="transactionDate"
                        label="Transacton date"
                        [options]="datepickerOptions"></date-field>
        </ion-card-content>
    </ion-card>

    <ion-card *ngIf="dealType">
        <ion-card-header>Commission</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-label stacked>Commission amount</ion-label>
                <ion-input formControlName="commissionAmount"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="closedDealForm.get('commissionAmount').dirty &&
                        closedDealForm.get('commissionAmount').errors &&
                        closedDealForm.get('commissionAmount').errors.pattern">
                {{validationMessages.DECIMAL_REGEX}}
            </div>
            <ion-item>
                <ion-label stacked>Commission (%)</ion-label>
                <ion-input formControlName="commissionPercent"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="closedDealForm.get('commissionPercent').dirty &&
                        closedDealForm.get('commissionPercent').errors &&
                        closedDealForm.get('commissionPercent').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>
        </ion-card-content>
    </ion-card>

    <ion-card *ngIf="dealType">
        <ion-card-header class="summary-title">
            <ng-container *ngIf="isLease">Lease</ng-container>
            <ng-container *ngIf="isSale">Deal</ng-container>
            summary
        </ion-card-header>
        <ion-card-content>
            <ion-item *ngIf="isSale">
                <ion-label stacked>Sale price</ion-label>
                <ion-input formControlName="salePrice"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="closedDealForm.get('salePrice').dirty &&
                        closedDealForm.get('salePrice').errors &&
                        closedDealForm.get('salePrice').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <ion-item *ngIf="isSale">
                <ion-label stacked>Asking price</ion-label>
                <ion-input formControlName="askingPrice"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="closedDealForm.get('askingPrice').dirty &&
                        closedDealForm.get('askingPrice').errors &&
                        closedDealForm.get('askingPrice').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <ion-label stacked
                       *ngIf="isSale">Research complete?</ion-label>
            <label *ngIf="isSale">
                <input type="checkbox"
                       formControlName="researchComplete">
            </label>
            <select-map-field *ngIf="isSale"
                              [form]="closedDealForm"
                              field="priceType"
                              label="Price type"
                              [options]="picklistOptions.priceType"
                              [cssClass]="'edit-form--select-popover'"></select-map-field>
            <ion-item *ngIf="isSale">
                <ion-label stacked>Down payment</ion-label>
                <ion-input formControlName="downPayment"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="closedDealForm.get('downPayment').dirty &&
                        closedDealForm.get('downPayment').errors &&
                        closedDealForm.get('downPayment').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <ion-item *ngIf="isSale">
                <ion-label stacked>Mortgage amount</ion-label>
                <ion-input formControlName="mortgageAmount"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="closedDealForm.get('mortgageAmount').dirty &&
                        closedDealForm.get('mortgageAmount').errors &&
                        closedDealForm.get('mortgageAmount').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <date-field *ngIf="isSale"
                        [defaultDate]="closedDealForm.get('closeDate').value"
                        [form]="closedDealForm"
                        field="closeDate"
                        label="Close date"
                        [options]="datepickerOptions"></date-field>
            <ion-item *ngIf="isSale">
                <ion-label stacked>CAP rate (%)</ion-label>
                <ion-input formControlName="capRate"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="closedDealForm.get('capRate').dirty &&
                   closedDealForm.get('capRate').errors &&
                   closedDealForm.get('capRate').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>
            <ion-item *ngIf="isSale">
                <ion-label stacked>Cash-on-cash (%)</ion-label>
                <ion-input formControlName="cashOnCash"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="closedDealForm.get('cashOnCash').dirty &&
                   closedDealForm.get('cashOnCash').errors &&
                   closedDealForm.get('cashOnCash').errors.pattern">
                {{validationMessages.PERCENT_REGEX}}
            </div>
            <ion-item *ngIf="isSale">
                <ion-label stacked>NOI</ion-label>
                <ion-input formControlName="netOperatingIncome"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="closedDealForm.get('netOperatingIncome').dirty &&
                        closedDealForm.get('netOperatingIncome').errors &&
                        closedDealForm.get('netOperatingIncome').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <ion-item>
                <ion-label stacked>Square footage</ion-label>
                <ion-input formControlName="squareFootage"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="closedDealForm.get('squareFootage').dirty &&
                    closedDealForm.get('squareFootage').errors &&
                    closedDealForm.get('squareFootage').errors.pattern">
                 {{validationMessages.DECIMAL_REGEX}}
            </div>
            <ion-item *ngIf="isLease">
                <ion-label stacked>Rental rate</ion-label>
                <ion-input formControlName="rentalRate"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="closedDealForm.get('rentalRate').dirty &&
                        closedDealForm.get('rentalRate').errors &&
                        closedDealForm.get('rentalRate').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <select-map-field *ngIf="isLease"
                          [form]="closedDealForm"
                          field="rentalRateType"
                          label="Rental rate type"
                          [options]="picklistOptions.rentalRateType"
                          [required]="false"
                          [cssClass]="'edit-form--select-popover'"></select-map-field>
            <ion-item *ngIf="isLease">
                <ion-label stacked>Lease total</ion-label>
                <ion-input formControlName="leaseTotal"></ion-input>
            </ion-item>
            <div class="field-error"
                 *ngIf="closedDealForm.get('leaseTotal').dirty &&
                        closedDealForm.get('leaseTotal').errors &&
                        closedDealForm.get('leaseTotal').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <select-map-field *ngIf="isLease"
                          [form]="closedDealForm"
                          field="leaseType"
                          label="Lease type"
                          [options]="picklistOptions.leaseType"
                          [required]="false"
                          [cssClass]="'edit-form--select-popover'"></select-map-field>
            <ion-item *ngIf="isLease">
                <ion-label stacked>Lease term (months)</ion-label>
                <ion-input formControlName="leaseTermMonths"></ion-input>
            </ion-item>
            <div class="field-error"
                *ngIf="closedDealForm.get('leaseTermMonths').dirty &&
                        closedDealForm.get('leaseTermMonths').errors &&
                        closedDealForm.get('leaseTermMonths').errors.pattern">
                {{validationMessages.INTEGER_REGEX}}
            </div>
            <date-field *ngIf="isLease"
                        [defaultDate]="closedDealForm.get('leaseCommencementDate').value"
                        [form]="closedDealForm"
                        field="leaseCommencementDate"
                        label="Lease commencement date"
                        [options]="datepickerOptions"></date-field>
            <date-field *ngIf="isLease"
                        [defaultDate]="closedDealForm.get('occupancyDate').value"
                        [form]="closedDealForm"
                        field="occupancyDate"
                        label="Occupancy date"
                        [options]="datepickerOptions"></date-field>
            <date-field *ngIf="isLease"
                        [defaultDate]="closedDealForm.get('rentCommencementDate').value"
                        [form]="closedDealForm"
                        field="rentCommencementDate"
                        label="Rent commencement date"
                        [options]="datepickerOptions"></date-field>
            <date-field *ngIf="isLease"
                        [defaultDate]="closedDealForm.get('leaseExpirationDate').value"
                        [form]="closedDealForm"
                        field="leaseExpirationDate"
                        label="Lease expiration date"
                        [options]="datepickerOptions"></date-field>
            <ion-item *ngIf="isLease">
                <ion-label stacked>Escalations</ion-label>
                <ion-input
                    data-test="escalations"
                    maxLength="255"
                    formControlName="escalations">
                </ion-input>
            </ion-item>
            <apto-form-control-help
                *ngIf="isLease"
                data-test="escalations-errors"
                [error]="closedDealForm.get('escalations') && closedDealForm.get('escalations').value && closedDealForm.get('escalations').value.length >= 255">
                {{ closedDealForm.get('escalations') && closedDealForm.get('escalations').value ? closedDealForm.get('escalations').value.length : 0 }} of 255 characters max available
            </apto-form-control-help>
            <ion-item *ngIf="isLease">
                <ion-label stacked>Options</ion-label>
                <ion-input
                    data-test="options"
                    maxLength="255"
                    formControlName="options">
                </ion-input>
            </ion-item>
            <apto-form-control-help
                *ngIf="isLease"
                data-test="options-errors"
                [error]="closedDealForm.get('options') && closedDealForm.get('options').value && closedDealForm.get('options').value.length >= 255">
                {{ closedDealForm.get('options') && closedDealForm.get('options').value ? closedDealForm.get('options').value.length : 0 }} of 255 characters max available
            </apto-form-control-help>
        </ion-card-content>
    </ion-card>
    <ion-card id="edit-closed-deal-description" *ngIf="dealType">
        <ion-card-header>Description</ion-card-header>
        <ion-card-content>
            <ion-item>
                <ion-input
                    formControlName="transactionNotes"
                    type="text"
                    maxLength="1000">
                </ion-input>
            </ion-item>
            <apto-form-control-help
                [error]="closedDealForm.get('transactionNotes').value.length >= 1000">
                {{ closedDealForm.get('transactionNotes').value.length }} of 1000 characters max available
            </apto-form-control-help>
        </ion-card-content>
    </ion-card>

    <photo-scrollview *ngIf="photos.length > 0"
                      [photoUris]="photos"></photo-scrollview>

    <busy-panel *ngIf="isSaving && isSale"
                message="Saving Closed Deal..."></busy-panel>

    <busy-panel *ngIf="isSaving && isLease"
                message="Saving Lease..."></busy-panel>
</form>
