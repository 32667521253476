<button ion-button 
        icon-only
        clear
        round
        small
        class="help-button disable-hover"
        color="secondary-grey"
        (click)="showHelp($event)">
  <ion-icon name="help-circle"
            class="help-icon"></ion-icon>
</button>