<ion-header class="modal-header space-modal-header">
    <ion-navbar color="white">
        <ion-buttons start
                     *ngIf="shouldShowBackArrow">
            <apto-button kind="secondaryDark"
                         type="link"
                         float-left
                         automation="spaceModal"
                         (click)="backButton()"
                         data-automation="modalBackButton">
                <apto-icon icon="arrowBack"
                           size="4"
                           inline></apto-icon>
            </apto-button>
        </ion-buttons>

        <ion-title *ngIf="mode === Modes.VIEW">Space details</ion-title>
        <ion-title *ngIf="mode === Modes.EDIT">{{ selectedRecord.space ? 'Edit' : 'Add' }} Space</ion-title>

        <ion-buttons end>
            <apto-button *ngIf="mode === Modes.VIEW"
                         type="link"
                         kind="primary"
                         (click)="setMode(Modes.EDIT)"
                         data-automation="modal-edit-button">
                Edit
            </apto-button>
            <button *ngIf="mode === Modes.EDIT && selectedRecord.space && selectedRecord.space._id"
                    data-automation="space-modal--hold-to-delete"
                    class="space-modal--hold-to-delete"
                    ion-button
                    clear
                    color="danger"
                    hold-button
                    (hold)="delete()"
                    data-automation="modal-delete-button">
                Hold to Delete
            </button>
            <apto-button *ngIf="mode === Modes.EDIT"
                         kind="primary"
                         type="link"
                         (click)="save()"
                         data-automation="modal-save-button">
                SAVE SPACE
            </apto-button>
            <apto-button *ngIf="mode === Modes.EDIT && !isQuickAdd"
                            (click)="addNew = true; save(addNew);"
                            data-automation="modalSaveAndNewButton">
                SAVE + ADD NEW
            </apto-button>
            <apto-button (click)="dismiss()"
                         type="link"
                         kind="secondary"
                         data-automation="modal-dismiss-button">
                <apto-icon icon="close" inline size="4"></apto-icon>
            </apto-button>
        </ion-buttons>
    </ion-navbar>
</ion-header>

<ion-content class="details-modal space-modal">
    <ion-slides (ionSlideWillChange)="scrollToTop()">
        <ion-slide>
            <ion-content>
                <ion-card>
                    <ion-card-header>Space Details</ion-card-header>
                </ion-card>
            </ion-content>
        </ion-slide>
        <ion-slide>
            <apto-container scroll>
                <property-summary-card
                    *ngIf="selectedRecord && selectedRecord.parentProperty"
                    [property]="selectedRecord.parentProperty">
                </property-summary-card>
                <apto-row class="space-details-modal-overview">
                    <apto-col md="7">
                        <photo-scrollview
                            *ngIf="photos"
                            [photoUris]="photos"></photo-scrollview>
                        <apto-card *ngIf="selectedRecord && selectedRecord.space && selectedRecord.space._id"
                                   class="space-modal-card--summary">
                            <apto-card-header noBottomPadding>Space summary</apto-card-header>
                            <apto-card-content>
                                <div class="space-modal--detail-item-name"
                                     *ngIf="selectedRecord.space.name">
                                    <apto-header class="space-modal--title" type="4">Space name</apto-header>
                                    <p class="space-modal--text dark-text">
                                        {{ selectedRecord.space.name }}
                                    </p>
                                </div>
                                <div class="space-modal--detail-item-space-status"
                                     *ngIf="selectedRecord.space.spaceStatus">
                                    <apto-header class="space-modal--title" type="4">Space status</apto-header>
                                    <p class="space-modal--text dark-text">
                                        {{ selectedRecord.space.spaceStatus }}
                                    </p>
                                </div>
                                <div class="space-modal--detail-item-space-type"
                                     *ngIf="selectedRecord.space.spaceType">
                                    <apto-header class="space-modal--title" type="4">Space type</apto-header>
                                    <p class="space-modal--text dark-text">
                                        {{ selectedRecord.space.spaceType }}
                                    </p>
                                    </div>
                                    <div class="space-modal--detail-item-space-sub-type"
                                         *ngIf="selectedRecord.space.spaceSubType">
                                        <apto-header class="space-modal--title" type="4">Space sub type</apto-header>
                                        <p class="space-modal--text dark-text">
                                            {{ selectedRecord.space.spaceSubType }}
                                        </p>
                                    </div>
                                <div class="space-modal--detail-item-floor"
                                     *ngIf="selectedRecord.space.floor">
                                    <apto-header class="space-modal--title" type="4">Floor</apto-header>
                                    <p class="space-modal--text dark-text">
                                        {{ selectedRecord.space.floor }}
                                    </p>
                                </div>
                                <div class="space-modal--detail-item-suite-floor"
                                     *ngIf="selectedRecord.space.suiteFloor">
                                    <apto-header class="space-modal--title" type="4">Suite number</apto-header>
                                    <p class="space-modal--text dark-text">
                                        {{ selectedRecord.space.suiteFloor }}
                                    </p>
                                </div>
                                <div class="space-modal--detail-item-square-footage"
                                     *ngIf="selectedRecord.space.squareFootage">
                                    <apto-header class="space-modal--title" type="4">Square footage</apto-header>
                                    <p class="space-modal--text dark-text">
                                        {{ selectedRecord.space.squareFootage | numberWithCommas }}
                                    </p>
                                </div>
                                <div class="space-modal--detail-item-description"
                                     *ngIf="selectedRecord.space.description">
                                    <apto-header class="space-modal--title" type="4">Description</apto-header>
                                    <p class="space-modal--text dark-text">
                                        {{ selectedRecord.space.description }}
                                    </p>
                                </div>
                            </apto-card-content>
                        </apto-card>

                        <apto-card data-automation="spaceModal-currentLease">
                            <apto-card-header noBottomPadding>Current lease</apto-card-header>
                            <apto-card-content noPadding>
                                <ion-list *ngIf="leases">
                                    <lease-item
                                        *ngFor="let lease of leases"
                                        [lease]="lease"
                                        [previousLeases]="lease.previousLeases"
                                        (onClick)="showLease(lease)">
                                    </lease-item>
                                </ion-list>
                                <ion-item
                                    *ngIf="!leases"
                                    class="empty-card">
                                    <ion-icon
                                        name="location_city"
                                        material-icons
                                        circle
                                        small
                                        color="white"
                                        item-left></ion-icon>
                                    Add leases
                                </ion-item>
                                <busy-panel
                                    *ngIf="isloadingLeases"
                                    message="Loading leases..."></busy-panel>
                            </apto-card-content>
                            <apto-card-footer>
                                <apto-card-footer-right>
                                    <apto-button
                                        kind="primary"
                                        type="link"
                                        (click)="showAddLease()">Add Lease</apto-button>
                                </apto-card-footer-right>
                            </apto-card-footer>
                        </apto-card>
                    </apto-col>
                    <apto-col md="5">
                        <tenant-card
                            (clickedTenantContact)="openContactModal($event)"
                            (clickedTenantCompany)="openCompanyModal($event)"
                            [company]="currentLease && currentLease.tenantCompany"
                            [contact]="currentLease && currentLease.tenantContact">
                        </tenant-card>
                        <landlord-card
                            (clickedLandlordContact)="openContactModal($event)"
                            (clickedLandlordCompany)="openCompanyModal($event)"
                            [company]="currentLease && currentLease.landlordCompany"
                            [contact]="currentLease && currentLease.landlordContact">
                        </landlord-card>
                    </apto-col>
                </apto-row>
            </apto-container>
        </ion-slide>
        <ion-slide class="base-slide-modal--edit-slide">
            <ion-content>
                <edit-space-form
                    *ngIf="selectedRecord"
                    [space]="selectedRecord.space"
                    [passedInParentProperty]="selectedRecord.parentProperty"></edit-space-form>
            </ion-content>
        </ion-slide>
    </ion-slides>
</ion-content>