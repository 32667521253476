import { __extends } from "tslib";
import { Injector } from '@angular/core';
import { UserModel } from '@apto/models';
import { API_IMPLEMENTATION_ERRORS, END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
import { AlertController } from 'ionic-angular';
var UsersService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(UsersService, _super);
    function UsersService(injector, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.alertController = alertController;
        _this.labelAttribute = 'fullName'; // used by auto-complete services
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.USERS;
        _this.model = UserModel;
        _this.serviceName = SERVICE_NAMES.USERS;
        return _this;
    }
    // Unimplemented API routes!!!
    UsersService.prototype.upsert = function () { throw new Error(API_IMPLEMENTATION_ERRORS.UPSERT); };
    UsersService.prototype.delete = function () { throw new Error(API_IMPLEMENTATION_ERRORS.DELETE); };
    UsersService.prototype.recent = function () { throw new Error(API_IMPLEMENTATION_ERRORS.RECENT); };
    UsersService.prototype.count = function () { throw new Error(API_IMPLEMENTATION_ERRORS.COUNT); };
    UsersService.prototype.search = function () { throw new Error(API_IMPLEMENTATION_ERRORS.SEARCH); };
    UsersService.prototype.info = function () { throw new Error(API_IMPLEMENTATION_ERRORS.INFO); };
    UsersService.prototype.bulkInsert = function () { throw new Error(API_IMPLEMENTATION_ERRORS.BULK_INSERT); };
    UsersService.prototype.bulkDelete = function () { throw new Error(API_IMPLEMENTATION_ERRORS.BULK_DELETE); };
    return UsersService;
}(BaseService));
export { UsersService };
