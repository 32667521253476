import { Config } from 'ionic-angular';
import { ModalMDSlideIn, ModalMDSlideOut } from './animations/modalAnimations';
var AppModule = /** @class */ /*@__PURE__*/ (function () {
    function AppModule(config) {
        this.config = config;
        this.config.setTransition('modal-slide-in-custom', ModalMDSlideIn);
        this.config.setTransition('modal-slide-out-custom', ModalMDSlideOut);
    }
    return AppModule;
}());
export { AppModule };
