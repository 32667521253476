import { EventEmitter } from '@angular/core';
import { PropertyModel } from '@apto/models';
import './contact-portfolio-card.scss';
var ContactPortfolioCard = /** @class */ /*@__PURE__*/ (function () {
    function ContactPortfolioCard() {
        this.properties = [];
        this.shownProperties = [];
        this.hoveredProperty = new PropertyModel();
        this.isInitializing = true;
        this.onViewAllPortfolio = new EventEmitter();
        this.onViewProperty = new EventEmitter();
        this.onItemHovered = new EventEmitter();
    }
    ContactPortfolioCard.prototype.viewMore = function () {
        this.onViewAllPortfolio.emit({
            properties: this.properties
        });
    };
    ContactPortfolioCard.prototype.viewProperty = function (property) {
        this.onViewProperty.emit({ property: property });
    };
    ContactPortfolioCard.prototype.isHovered = function (property, isHovered) {
        this.onItemHovered.emit({ property: property, isHovered: isHovered });
    };
    return ContactPortfolioCard;
}());
export { ContactPortfolioCard };
