import { HttpClient } from '@angular/common/http';
import { AuthService, LogsService } from '@apto/ionic-lib/services';
import { ConfigService } from '../config/config-service';
var MappingProperty = /** @class */ /*@__PURE__*/ (function () {
    function MappingProperty() {
    }
    return MappingProperty;
}());
export { MappingProperty };
var MappingPageResult = /** @class */ /*@__PURE__*/ (function () {
    function MappingPageResult() {
    }
    return MappingPageResult;
}());
export { MappingPageResult };
var MappingUpsertResult = /** @class */ /*@__PURE__*/ (function () {
    function MappingUpsertResult() {
    }
    return MappingUpsertResult;
}());
export { MappingUpsertResult };
var MappingApiShape = /** @class */ /*@__PURE__*/ (function () {
    function MappingApiShape() {
    }
    return MappingApiShape;
}());
export { MappingApiShape };
var MappingApiUpsertShape = /** @class */ /*@__PURE__*/ (function () {
    function MappingApiUpsertShape() {
    }
    return MappingApiUpsertShape;
}());
export { MappingApiUpsertShape };
var MappingStore = /** @class */ /*@__PURE__*/ (function () {
    function MappingStore(configService, http, logsService, authService) {
        this.configService = configService;
        this.http = http;
        this.logsService = logsService;
        this.authService = authService;
        this.endpoint = this.configService.mappingServiceUrl + "/api/property";
        this.serviceName = 'MappingData';
    }
    MappingStore.prototype.updateProperty = function (property) {
        var _this = this;
        var apiProperty = property.transformToMappingAPI();
        apiProperty.sf_org_id = this.authService.user && this.authService.user.orgId;
        return new Promise(function (resolve, reject) {
            if (!apiProperty.sf_org_id) {
                _this.logsService.exception("Failed to update for service " + _this.serviceName + ". Org id not set", _this);
                return reject(new Error('SalesForce org not set'));
            }
            return _this.http.post(_this.endpoint, [apiProperty])
                .subscribe(function (result) {
                if (result.message !== 'OK') {
                    return reject(result);
                }
                return resolve(result);
            }, function (err) {
                if (err) {
                    _this.logsService.exception("Failed to update for service " + _this.serviceName + ".", err);
                    return reject(err);
                }
            });
        });
    };
    MappingStore.prototype.fetch = function (query) {
        var _this = this;
        var url = this.endpoint + "?" + query;
        return new Promise(function (resolve, reject) {
            return _this.http.get(url)
                .subscribe(function (result) {
                if (result.message !== 'OK') {
                    return reject(result);
                }
                return resolve(result);
            }, function (err) {
                if (err) {
                    _this.logsService.exception("Failed to fetch for service " + _this.serviceName + ".", err);
                    return reject(err);
                }
            });
        });
    };
    return MappingStore;
}());
export { MappingStore };
