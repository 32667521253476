import { __extends } from "tslib";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AutoCompleteComponent } from '@apto/autocomplete';
import { AuthService, LogsService } from '@apto/ionic-lib/services';
import { CompModel } from '@apto/models';
import { CompaniesService, ContactsService, PropertiesService, ImagesService, TypesService, CompsService, PropertiesServiceSpaceAutocomplete, FeaturesService } from '@apto/services';
import { BasePipelineEditForm } from '../base-pipeline-edit-form/base-pipeline-edit-form';
import moment from 'moment';
import { DECIMAL_REGEX, INTEGER_REGEX, PERCENT_REGEX, validationMessages } from '@apto/shared';
var EditClosedDealForm = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(EditClosedDealForm, _super);
    function EditClosedDealForm(companiesService, contactsService, propertiesService, spaceAutocompleteService, featuresService, authService, logsService, formBuilder, imagesService, typesService, compsService) {
        var _this = _super.call(this, companiesService, contactsService, propertiesService, featuresService, authService, logsService) || this;
        _this.companiesService = companiesService;
        _this.contactsService = contactsService;
        _this.propertiesService = propertiesService;
        _this.spaceAutocompleteService = spaceAutocompleteService;
        _this.featuresService = featuresService;
        _this.authService = authService;
        _this.logsService = logsService;
        _this.formBuilder = formBuilder;
        _this.imagesService = imagesService;
        _this.typesService = typesService;
        _this.compsService = compsService;
        _this.format = 'closed deal';
        _this.isSaving = false;
        _this.leaseTypes = Object.values(CompModel.LEASE_TYPES);
        _this.photos = [];
        _this.picklistOptions = {};
        _this.priceTypes = Object.values(CompModel.PRICE_TYPES);
        _this.rentalRateTypes = Object.values(CompModel.RENTAL_RATE_TYPES);
        _this.types = [];
        _this.datepickerOptions = {
            alignSelectorRight: true,
            openSelectorTopOfInput: true,
            showSelectorArrow: false
        };
        _this.validationMessages = validationMessages;
        return _this;
    }
    EditClosedDealForm.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.pipelineObject) {
            this.pipelineObject = new CompModel();
        }
        else {
            this.setDealType(this.pipelineObject.type);
        }
        if (this.pipelineObject.propertyId) {
            this.photos = [];
            this.imagesService
                .byObjectId(this.pipelineObject.propertyId)
                .then(function (blobs) {
                _this.photos = blobs;
            });
        }
        this.setupForm();
        this.getPicklistOptions();
        this.getTypes();
    };
    EditClosedDealForm.prototype.save = function () {
        var _this = this;
        if (this.closedDealForm.valid) {
            Object.assign(this.pipelineObject, this.closedDealForm.value);
            this.saveRelationship(this.buyerCompanyAutoComplete, 'buyerCompany', 'buyerCompanyId');
            this.saveRelationship(this.buyerContactAutoComplete, 'buyerContact', 'buyerId');
            this.saveRelationship(this.landlordCompanyAutoComplete, 'landlordCompany', 'landlordCompanyId');
            this.saveRelationship(this.landlordContactAutoComplete, 'landlordContact', 'landlordContactId');
            this.saveRelationship(this.leasedPropertyAutoComplete, 'leasedProperty', 'leasedPropertyId');
            this.saveRelationship(this.propertyAutoComplete, 'property', 'propertyId');
            this.saveRelationship(this.sellerCompanyAutoComplete, 'sellerCompany', 'sellerCompanyId');
            this.saveRelationship(this.sellerContactAutoComplete, 'sellerContact', 'sellerId');
            this.saveRelationship(this.tenantCompanyAutoComplete, 'tenantCompany', 'tenantCompanyId');
            this.saveRelationship(this.tenantContactAutoComplete, 'tenantContact', 'tenantContactId');
            if (this.spacesEnabled) {
                this.saveRelationship(this.leasedSpaceAutoComplete, 'spaceProperty', 'spacePropertyId');
            }
            return { closedDeal: this.pipelineObject };
        }
        else {
            Object.keys(this.closedDealForm.controls).forEach(function (fieldKey) {
                _this.closedDealForm.controls[fieldKey].markAsDirty();
            });
        }
    };
    EditClosedDealForm.prototype.leasedPropertySelected = function (property) {
        this.spaceAutocompleteService.parentPropertyId = property._id;
        this.leasedSpaceAutoComplete.clearValue();
        this.setSpaceAutocompleteState();
    };
    EditClosedDealForm.prototype.leasedPropertyChanged = function (ky) {
        if (ky === '' || !this.leasedPropertyAutoComplete.getValue()) {
            this.spaceAutocompleteService.parentPropertyId = null;
            this.leasedSpaceAutoComplete.clearValue();
        }
        this.setSpaceAutocompleteState();
    };
    EditClosedDealForm.prototype.setSpaceAutocompleteState = function () {
        if (!this.spaceAutocompleteService.parentPropertyId) {
            this.leasedSpaceAutoComplete.setDisabledState(true);
        }
        else {
            this.leasedSpaceAutoComplete.setDisabledState(false);
        }
    };
    EditClosedDealForm.prototype.chooseDealType = function (option) {
        this.setDealType(option);
        this.format = null;
    };
    EditClosedDealForm.prototype.setDealType = function (type) {
        this.dealType = type;
        this.pipelineObject.type = type;
    };
    Object.defineProperty(EditClosedDealForm.prototype, "isSale", {
        get: function () {
            return this.pipelineObject.type && this.pipelineObject.type.toLowerCase().includes('sale');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EditClosedDealForm.prototype, "isLease", {
        get: function () {
            return this.pipelineObject.type && this.pipelineObject.type.toLowerCase().includes('lease');
        },
        enumerable: true,
        configurable: true
    });
    // called from base class ngOnDestroy
    EditClosedDealForm.prototype.onDestroy = function () {
        this.spaceAutocompleteService.parentPropertyId = null;
    };
    EditClosedDealForm.prototype.setupForm = function () {
        var _this = this;
        this.setCompany(this.buyerCompanyAutoComplete, 'buyerCompany', 'buyerCompanyId');
        this.setCompany(this.landlordCompanyAutoComplete, 'landlordCompany', 'landlordCompanyId');
        this.setCompany(this.sellerCompanyAutoComplete, 'sellerCompany', 'sellerCompanyId');
        this.setCompany(this.tenantCompanyAutoComplete, 'tenantCompany', 'tenantCompanyId');
        this.setContact(this.buyerContactAutoComplete, 'buyerContact', 'buyerId');
        this.setContact(this.landlordContactAutoComplete, 'landlordContact', 'landlordContactId');
        this.setContact(this.sellerContactAutoComplete, 'sellerContact', 'sellerId');
        this.setContact(this.tenantContactAutoComplete, 'tenantContact', 'tenantContactId');
        this.setProperty(this.leasedPropertyAutoComplete, 'leasedProperty', 'leasedPropertyId');
        this.setProperty(this.propertyAutoComplete, 'property', 'propertyId');
        if (this.spacesEnabled) {
            this.spaceAutocompleteService.parentPropertyId = this.pipelineObject.leasedPropertyId || null;
            this.setSpace(this.leasedSpaceAutoComplete, 'spaceProperty', 'spacePropertyId');
        }
        var closedDealName = this.pipelineObject && this.pipelineObject.name || '';
        var validateWithPattern = function (keyField, reg) {
            return [_this.pipelineObject[keyField], Validators.pattern(reg)];
        };
        this.closedDealForm = this.formBuilder.group({
            askingPrice: validateWithPattern('askingPrice', INTEGER_REGEX),
            capRate: validateWithPattern('capRate', PERCENT_REGEX),
            cashOnCash: validateWithPattern('cashOnCash', PERCENT_REGEX),
            closeDate: (this.pipelineObject && this.pipelineObject.closeDate) || moment.utc().toDate(),
            commissionAmount: validateWithPattern('commissionAmount', DECIMAL_REGEX),
            commissionPercent: validateWithPattern('commissionPercent', PERCENT_REGEX),
            downPayment: validateWithPattern('downPayment', INTEGER_REGEX),
            escalations: this.pipelineObject && this.pipelineObject.escalations,
            leaseCommencementDate: this.pipelineObject && this.pipelineObject.leaseCommencementDate,
            leaseExpirationDate: this.pipelineObject && this.pipelineObject.leaseExpirationDate,
            leaseTermMonths: validateWithPattern('leaseTermMonths', INTEGER_REGEX),
            leaseTotal: validateWithPattern('leaseTotal', INTEGER_REGEX),
            leaseType: this.pipelineObject && this.pipelineObject.leaseType,
            mortgageAmount: validateWithPattern('mortgageAmount', INTEGER_REGEX),
            name: [closedDealName, Validators.compose([Validators.required, Validators.maxLength(80)])],
            netOperatingIncome: validateWithPattern('netOperatingIncome', INTEGER_REGEX),
            occupancyDate: this.pipelineObject && this.pipelineObject.occupancyDate,
            options: this.pipelineObject && this.pipelineObject.options,
            priceType: this.pipelineObject && this.pipelineObject.priceType,
            rentCommencementDate: this.pipelineObject && this.pipelineObject.rentCommencementDate,
            rentalRate: validateWithPattern('rentalRate', INTEGER_REGEX),
            rentalRateType: this.pipelineObject && this.pipelineObject.rentalRateType,
            researchComplete: this.pipelineObject && this.pipelineObject.researchComplete || false,
            salePrice: validateWithPattern('salePrice', INTEGER_REGEX),
            squareFootage: validateWithPattern('squareFootage', DECIMAL_REGEX),
            transactionDate: (this.pipelineObject && this.pipelineObject.transactionDate) || moment.utc().toDate(),
            transactionNotes: this.pipelineObject && this.pipelineObject.transactionNotes || '',
            type: [this.pipelineObject && this.pipelineObject.type, Validators.required]
        });
        this.setSpaceAutocompleteState();
    };
    EditClosedDealForm.prototype.getPicklistOptions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, this.compsService.getPicklistOptions(['priceType', 'rentalRateType', 'leaseType'])];
                    case 1:
                        _a.picklistOptions = _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    EditClosedDealForm.prototype.getTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            var compTypes, filteredTypes;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.typesService.fetch()];
                    case 1:
                        compTypes = (_a.sent()).compTypes;
                        filteredTypes = Object.keys(compTypes).filter(function (type) {
                            return _this.format !== 'lease' || type.toLowerCase().includes('lease');
                        });
                        this.types = filteredTypes;
                        return [2 /*return*/];
                }
            });
        });
    };
    return EditClosedDealForm;
}(BasePipelineEditForm));
export { EditClosedDealForm };
