import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { ConfigService } from '@apto/services';
import { ContactModel, ContactGroupModel, CompanyModel, PipelineFactory, PropertyModel, TaskModel } from '@apto/models';
var SearchService = /** @class */ /*@__PURE__*/ (function () {
    function SearchService(http, injector) {
        this.http = http;
        this.injector = injector;
        this.configService = this.injector.get(ConfigService);
        this.endpoint = this.configService.serviceUrl + "/api/search";
        this.defaultQueryParams = {
            appName: this.configService.appName,
            omitDeleted: true
        };
        this._focus$ = new Subject();
        this.resultsCache = { id: Date.now(), results: null };
    }
    SearchService.prototype.escapeReservedSalesForceCharacters = function (keyword) {
        var reservedCharacters = ['?', '&', '|', '!', '{', '}', '[', ']', '(', ')', '^', '~', '*', ':', '\"', '\'', '+', '-'];
        reservedCharacters.forEach(function (character) {
            var regExp = new RegExp('\\' + character, 'g');
            keyword = keyword.replace(regExp, '\\' + character);
        });
        return keyword;
    };
    SearchService.prototype.setCurrentResults = function (results) {
        this.resultsCache.id = Date.now();
        this.resultsCache.results = results;
    };
    SearchService.prototype.getCurrentResults = function () {
        return this.resultsCache;
    };
    SearchService.prototype.getFocusSearchBar = function () {
        return this._focus$.asObservable();
    };
    SearchService.prototype.setFocusToSearchBar = function () {
        this._focus$.next();
    };
    SearchService.prototype.getResults = function (params) {
        params.searchKey = "\"" + this.escapeReservedSalesForceCharacters(params.where.searchTerm) + "*\"";
        params.limit = params.limit || 5;
        var query = this.getQueryString(params);
        return this.http.get(this.endpoint + "?" + query).pipe(map(function (result) {
            var results = result.data;
            if (results.companies) {
                results.companies = results.companies.map(function (company) {
                    return new CompanyModel(company);
                });
            }
            if (results.contacts) {
                results.contacts = results.contacts.map(function (contact) {
                    return new ContactModel(contact);
                });
            }
            if (results.contactGroups) {
                results.contactGroups = results.contactGroups.map(function (contactGroup) {
                    return new ContactGroupModel(contactGroup);
                });
            }
            if (results.properties) {
                results.properties = results.properties.map(function (property) {
                    return new PropertyModel(property);
                });
            }
            if (results.tasks) {
                results.tasks = results.tasks.map(function (task) {
                    return new TaskModel(task);
                });
            }
            if (results.deals) {
                results.deals = results.deals.map(function (deal) {
                    return PipelineFactory.create(deal);
                });
            }
            return results;
        }));
    };
    // type is one of: 'contacts', 'properties', 'tasks', or 'companies'
    // the params object must contain the searchTerm property
    SearchService.prototype.getIdsByType = function (type, params) {
        var _this = this;
        params.type = type;
        return new Promise(function (resolve) {
            return _this.getResults(params).subscribe(function (result) {
                delete params.where.searchTerm;
                if (result[type]) {
                    return resolve(result[type].map(function (model) {
                        return model._id;
                    }));
                }
                else {
                    return resolve([]);
                }
            });
        });
    };
    SearchService.prototype.getQueryString = function (params) {
        params = params || {};
        params = Object.assign({}, this.defaultQueryParams, params);
        var queries = [];
        Object.keys(params).map(function (key) {
            queries.push(key + '=' + encodeURIComponent(JSON.stringify(params[key])));
        });
        return queries.join('&');
    };
    return SearchService;
}());
export { SearchService };
