<ion-card class="deals-list-card">
    <ion-card-header>
        Deals list
    </ion-card-header>
    <ion-card-content no-padding
                      class="deal-activity-card--activity-list">
        <ion-list>
            <ng-container *ngIf="dealActivity?.length">
                <ion-item *ngFor="let activity of dealActivity; trackBy: trackByFn"
                          class="deal-activity-card--deal-activity-item"
                          detail-push
                          (click)="viewActivity(activity)">
                    <ion-icon material-icons
                            name="business"
                            circle
                            item-left
                            color="primary-blue">
                    </ion-icon>
                    <div class="deal-activity-card--item--details">
                        <ion-row class="deal-activity--title">
                            <span class="deal-activity-card--item--title">
                                {{ activity.name }}
                            </span>
                        </ion-row>
                        <ion-row>
                            <p>{{ activity.property?.addressString }}</p>
                        </ion-row>
                        <ion-row>
                            <p>{{ activityTypeName(activity) }}</p>
                        </ion-row>
                        <ion-row>
                            <p *ngIf="activity.property" [innerHTML]="getPriceAndSqft(activity.property)"></p>
                        </ion-row>
                    </div>
                </ion-item>
            </ng-container>
        </ion-list>
    </ion-card-content>
</ion-card>
