<ion-card class="company-related-contacts-card">
    <ion-card-header>Related properties</ion-card-header>
    <ion-card-content *ngIf="!isInitializing">
        <ion-list>
            <property-item *ngFor="let property of properties"
                           [property]="property"
                           [isImageShown]="false"
                           [isShownOnMap]="false"></property-item>
        </ion-list>

        <ion-item *ngIf="!properties || !properties.length"
                  class="empty-card">
            <ion-icon name="business"
                      material-icons
                      circle
                      small
                      color="white"
                      item-left></ion-icon>
            Add properties
        </ion-item>
    </ion-card-content>

    <ion-card-content *ngIf="isInitializing">
        <busy-panel message="Loading related properties ..."></busy-panel>
    </ion-card-content>
</ion-card>
