var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function () { if (t[0] & 1)
            throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function () { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f)
            throw new TypeError("Generator is already executing.");
        while (_)
            try {
                if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done)
                    return t;
                if (y = 0, t)
                    op = [0, t.value];
                switch (op[0]) {
                    case 0:
                    case 1:
                        t = op;
                        break;
                    case 4:
                        _.label++;
                        return { value: op[1], done: false };
                    case 5:
                        _.label++;
                        y = op[1];
                        op = [0];
                        continue;
                    case 7:
                        op = _.ops.pop();
                        _.trys.pop();
                        continue;
                    default:
                        if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
                            _ = 0;
                            continue;
                        }
                        if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) {
                            _.label = op[1];
                            break;
                        }
                        if (op[0] === 6 && _.label < t[1]) {
                            _.label = t[1];
                            t = op;
                            break;
                        }
                        if (t && _.label < t[2]) {
                            _.label = t[2];
                            _.ops.push(op);
                            break;
                        }
                        if (t[2])
                            _.ops.pop();
                        _.trys.pop();
                        continue;
                }
                op = body.call(thisArg, _);
            }
            catch (e) {
                op = [6, e];
                y = 0;
            }
            finally {
                f = t = 0;
            }
        if (op[0] & 5)
            throw op[1];
        return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ModalController } from 'ionic-angular';
import { BuyerNeedsModel, ContactModel } from '@apto/models/';
import { BuyerNeedsService } from '@apto/services';
import { NumberWithCommas } from '@apto/ionic-lib/pipes';
import { BuyerNeedsModal, BuyerNeedsModalMode } from '@apto/shared/modals/buyer-needs-modal/buyer-needs-modal';
import './buyer-needs-card.scss';
var BuyerNeedsCard = /** @class */ /*@__PURE__*/ (function () {
    function BuyerNeedsCard(buyerNeedsService, modalController) {
        this.buyerNeedsService = buyerNeedsService;
        this.modalController = modalController;
        this.buyerNeeds = [];
        this.loadingMessage = 'Loading buyer\'s needs...';
        this.subscriptions = [];
    }
    BuyerNeedsCard.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.buyerNeedsService.onDeleted.subscribe(function (data) {
            _this.removeBuyerNeeds(data.payload);
        }));
        this.subscriptions.push(this.buyerNeedsService.onUpserted.subscribe(function (data) {
            _this.upsertBuyerNeed(data.payload);
        }));
        return this.getBuyerNeeds();
    };
    BuyerNeedsCard.prototype.ngOnChanges = function (changes) {
        if (!changes.contact.previousValue || changes.contact.previousValue._id !== changes.contact.currentValue._id) {
            this.getBuyerNeeds();
        }
    };
    BuyerNeedsCard.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
    };
    BuyerNeedsCard.prototype.addBuyerNeed = function () {
        var modal = this.modalController.create(BuyerNeedsModal, {
            mode: BuyerNeedsModalMode.EDIT,
            selectedRecord: new BuyerNeedsModel({ contactId: this.contact._id })
        });
        modal.present();
    };
    BuyerNeedsCard.prototype.showBuyerNeed = function (buyerNeed) {
        var modal = this.modalController.create(BuyerNeedsModal, {
            mode: BuyerNeedsModalMode.VIEW,
            selectedRecord: buyerNeed
        });
        modal.present();
    };
    BuyerNeedsCard.prototype.getInfo = function (buyerNeed) {
        var items = [];
        if (buyerNeed.buildingType) {
            items.push(buyerNeed.buildingType.replace(/\;/g, '/'));
        }
        if (buyerNeed.minSquareFootage && buyerNeed.maxSquareFootage) {
            var numberWithCommas = new NumberWithCommas();
            var min = numberWithCommas.transform(buyerNeed.minSquareFootage);
            var max = numberWithCommas.transform(buyerNeed.maxSquareFootage);
            items.push(min + ' - ' + max + ' sq/ft');
        }
        if (buyerNeed.minPrice && buyerNeed.maxPrice) {
            var numberWithCommas = new NumberWithCommas();
            var min = numberWithCommas.transform(buyerNeed.minPrice);
            var max = numberWithCommas.transform(buyerNeed.maxPrice);
            items.push('$' + min + ' - $' + max);
        }
        return items.join(' &bull; ');
    };
    BuyerNeedsCard.prototype.getBuyerNeeds = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(this.contact && this.contact._id))
                            return [3 /*break*/, 2];
                        this.loadingMessage = 'Loading buyer\'s needs...';
                        _a = this;
                        return [4 /*yield*/, this.buyerNeedsService.byContactId(this.contact._id)];
                    case 1:
                        _a.buyerNeeds = _b.sent();
                        this.loadingMessage = null;
                        _b.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    BuyerNeedsCard.prototype.removeBuyerNeeds = function (ids) {
        this.buyerNeeds = this.buyerNeeds.filter(function (buyerNeed) {
            return ids.indexOf(buyerNeed._id) < 0;
        });
    };
    BuyerNeedsCard.prototype.upsertBuyerNeed = function (buyerNeed) {
        var index = this.buyerNeeds.findIndex(function (bn) { return bn._id === buyerNeed._id; });
        if (index < 0) {
            this.buyerNeeds.push(buyerNeed);
        }
        else {
            this.buyerNeeds[index] = buyerNeed;
        }
    };
    return BuyerNeedsCard;
}());
export { BuyerNeedsCard };
