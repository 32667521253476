<div class="call-list-group-selector">
    <ion-card-header>Select groups for your list</ion-card-header>
    <ion-card-content>
        <ion-grid>
            <ion-row>
                <ion-col col-8 class="edit-form">
                    <p class="call-list-group-selector--description-text">You can select multiple groups to add additional contacts.</p>
                    <div class="call-list-group-selector--field-error" *ngIf="showValidationWarning && !selectedGroupsArray.length">
                        At least one contact group is required
                    </div>
                    <div class="note">
                        <span class="dark-text">Note: </span>
                        <span>You can create a
                            <a *ngIf="!isUrlsInitializing; else groupUrlShimmer" href="{{urls.sfDomain}}/{{urls.contactGroupPrefix}}/o" target="_blank"> Group </a>
                            <ng-template #groupUrlShimmer>
                                <apto-shimmer class="call-list-group-selector--url-shimmer"></apto-shimmer>
                            </ng-template>
                            or
                            <a *ngIf="!isUrlsInitializing; else listViewUrlShimmer" href="{{urls.sfDomain}}/{{URLModel.CONTACT_PREFIX}}/o" target="_blank"> View </a>
                            <ng-template #listViewUrlShimmer>
                                <apto-shimmer class="call-list-group-selector--url-shimmer"></apto-shimmer>
                            </ng-template>
                            in Apto CRM, and it will show up here!
                        </span>
                        <help-button helpText="<p><strong>Apto CRM</strong> is your central database management for Contacts and Properties.</p>
                                               <p><strong>Groups</strong> are customized categories grouping your Contacts together.</p>
                                               <p><strong>Views</strong> are dynamic filters based on Contact attributes.</p>"
                                     helpArticleUrl="http://apto.force.com">
                        </help-button>
                    </div>

                    <busy-panel *ngIf="isInitializing" class="call-list-group-selector--busy-panel"></busy-panel>

                    <ion-auto-complete #searchbar
                                       [hidden]="isInitializing"
                                       [dataProvider]="contactGroupsService"
                                       [options]="{ placeholder : 'Find a Group' }"
                                       [template]="groupTemplate"
                                       alwaysShowList="true"
                                       showResultsFirst="true"
                                       (itemSelected)="toggleGroupSelection($event);"></ion-auto-complete>
                    <ng-template #groupTemplate
                                 let-attrs="attrs">
                        <ion-item class="contract-group-list-item link">
                            <ion-icon *ngIf="selectedGroups[attrs.data._id]"
                                      name="checkmark-circle"
                                      color="success"
                                      item-left></ion-icon>
                            <ion-icon *ngIf="!selectedGroups[attrs.data._id]"
                                      name="ios-radio-button-off"
                                      item-left></ion-icon>
                            <h2>
                                <span class="call-list-group-selector--name">{{ attrs.data.name }}</span>
                                <span *ngIf="isListView(attrs.data)"
                                      class="call-list-group-selector--view-label">
                                    View
                                </span>
                            </h2>
                            <p class="call-list-group-selector--info-panel">
                                <span class="call-list-group-selector--info-panel-member-count">
                                    <ion-icon name="person"></ion-icon>
                                    <span *ngIf="attrs.data.numberOfMembers; else numberOfMembersShimmer">{{ attrs.data.numberOfMembers }}</span>
                                    <ng-template #numberOfMembersShimmer>
                                        <apto-shimmer class="call-list-group-selector--member-count-shimmer"></apto-shimmer>
                                    </ng-template>
                                </span>
                                <span>
                                    <ion-icon name="calendar"></ion-icon>
                                    <span *ngIf="attrs.data.createdDate; else createdDateShimmer">Created on {{ attrs.data.createdDate | dateFormat }}</span>
                                    <ng-template #createdDateShimmer>
                                        <apto-shimmer class="call-list-group-selector--created-date-shimmer"></apto-shimmer>
                                    </ng-template>
                                </span>
                            </p>
                        </ion-item>
                    </ng-template>
                </ion-col>
                <ion-col col-4>
                    <call-list-statistics-card [groups]="selectedGroupsArray"></call-list-statistics-card>
                </ion-col>
            </ion-row>
        </ion-grid>
    </ion-card-content>
</div>
