import { __extends } from "tslib";
import { Injector } from '@angular/core';
import { ContactGroupMemberModel } from '@apto/models';
import { END_POINTS, SERVICE_NAMES } from '../constants';
import { BaseService } from '../base/base-service';
import { AlertController } from 'ionic-angular';
var ContactGroupMembersService = /** @class */ /*@__PURE__*/ (function (_super) {
    __extends(ContactGroupMembersService, _super);
    function ContactGroupMembersService(injector, alertController) {
        var _this = _super.call(this, injector, alertController) || this;
        _this.injector = injector;
        _this.alertController = alertController;
        _this.endpoint = _this.configService.serviceUrl + "/" + END_POINTS.CONTACT_GROUP_MEMBERS;
        _this.model = ContactGroupMemberModel;
        _this.serviceName = SERVICE_NAMES.CONTACT_GROUP_MEMBERS;
        return _this;
    }
    return ContactGroupMembersService;
}(BaseService));
export { ContactGroupMembersService };
