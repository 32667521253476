<ion-card>
    <ion-card-header>
        Buyer's needs
    </ion-card-header>
    <ion-card-content *ngIf="!loadingMessage"
                      no-padding>
        <ion-list>
            <ion-item *ngFor="let buyerNeed of buyerNeeds"
                      detail-push
                      (click)="showBuyerNeed(buyerNeed)">
                <ion-icon material-icons
                          name="loyalty"
                          circle
                          item-left>
                </ion-icon>

                <p><strong>{{ buyerNeed.name }}</strong></p>
                <p class="buyer-needs-card--info" [innerHTML]="getInfo(buyerNeed)"></p>
            </ion-item>

            <ion-item *ngIf="!buyerNeeds.length"
                      class="empty-card">
                <ion-icon name="checkmark"
                          circle
                          small
                          color="white"
                          class="grey-circle"
                          item-left></ion-icon>
                Add buyer's needs
            </ion-item>
        </ion-list>
        <ion-row class="card-footer-buttons">
            <ion-col text-right>
                <button ion-button
                        clear
                        color="primary-blue"
                        (click)="addBuyerNeed()">
                    Add buyer's need
                </button>
            </ion-col>
        </ion-row>
    </ion-card-content>

    <ion-card-content *ngIf="loadingMessage">
        <busy-panel [message]="loadingMessage"></busy-panel>
    </ion-card-content>
</ion-card>