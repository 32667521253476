import { FormBuilder, Validators } from '@angular/forms';
import { CallListModel } from '@apto/models';
import './add-call-list-details.scss';
var AddCallListDetails = /** @class */ /*@__PURE__*/ (function () {
    function AddCallListDetails(formBuilder) {
        var _this = this;
        this.formBuilder = formBuilder;
        this.callLists = []; // all existing call lists, used for validation
        // validation to ensure that a unique call list name is chosen
        this.isUnique = function (control) {
            var matchingCallList = _this.callLists.find(function (callList) {
                // ignore " (1 of #)"
                var baseCallListName = callList.name.split(' (')[0];
                return baseCallListName === control.value
                    && control.value !== _this.callList.name;
            });
            return (matchingCallList) ? { duplicate: true } : null;
        };
    }
    AddCallListDetails.prototype.ngOnInit = function () {
        this.setUpForm();
    };
    AddCallListDetails.prototype.save = function () {
        var _this = this;
        if (!this.callListForm.valid) {
            Object.keys(this.callListForm.controls).forEach(function (fieldKey) {
                _this.callListForm.controls[fieldKey].markAsDirty();
            });
            return null;
        }
        this.callList.name = this.callListForm.controls.name.value;
        this.callList.description = this.callListForm.controls.description.value;
        this.callList.type = CallListModel.TYPE.CONTACT_GROUP_LIST;
        this.callList.configJson.daysSinceContact = this.callListForm.controls.frequency.value;
        return this.callList;
    };
    AddCallListDetails.prototype.setUpForm = function () {
        this.callListForm = this.formBuilder.group({
            name: [this.callList.name, [Validators.required, this.isUnique]],
            description: [this.callList.description, Validators.required],
            frequency: [(this.callList.configJson.daysSinceContact || '').toString(), Validators.required]
        });
    };
    return AddCallListDetails;
}());
export { AddCallListDetails };
